import { createSlice } from '@reduxjs/toolkit'

// Toujours mettre cet etat initial du state
export const INITIAL_STATE = {
  installTotp: false,
  success: false,
}

const slice = createSlice({
  name: 'totp',
  initialState: INITIAL_STATE,
  reducers: {
    installTotp: state => ({ ...state, installTotp: true }),
    success: state => ({ ...state, success: true }),
    clean: state => INITIAL_STATE,
  }
})

export const {
  installTotp,
  success,
  clean,
} = slice.actions

export default slice.reducer

export const selectSuccess = state => state.totp.success
