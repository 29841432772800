import * as Api from '../Util/Api'
import * as Eff from 'redux-saga/effects'
import * as Totp from '../State/Totp'
import * as Login from '../State/Login'

export function* installTotp() {
  try {
    const { email } = yield Eff.call(Api.getSecretHandle)

    localStorage.setItem(email + '-hasTotp', true)

    yield Eff.put(Login.updateIsSecretHandleExpired(false))
    yield Eff.put(Totp.success())
  } catch (error) {
    console.log(error)
  }
}

const TotpSagas = function* () {
  yield Eff.takeLatest(`${Totp.installTotp}`, installTotp)
}

export default TotpSagas;
