import { createSlice } from '@reduxjs/toolkit'

export const PATIENTS = 'patients'

export const MEDECINS = 'medecins'

export const AVIS = 'avis'

export const STAFFS = 'staffs'

export const COHORTES = 'cohortes'

export const HELP = 'aide'

export const INITIAL_STATE = {
  active: PATIENTS,
}

const slice = createSlice({
  name: 'menu',
  initialState: INITIAL_STATE,
  reducers: {
    changeActive: (state, { payload: active }) => ({
      ...state,
      active,
    }),
  }
})

export default slice.reducer

export const {
  changeActive,
} = slice.actions

export const selectActive = state => state.menu.active
