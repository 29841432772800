import { createSlice } from '@reduxjs/toolkit';

export const INITIAL_STATE = {
  data: {
    firstname: { value: '', errors: [] },
    lastname: { value: '', errors: [] },
    profilePicture: { value: '', errors: [] },
    rppsNumber: { value: 'N/A', errors: [] },
    speciality: { value: '', errors: [] },
    city: { value: '', errors: [] },
    messageObject: { value: '', errors: [], validationRules: [] },
    messageBody: { value: '', errors: [], validationRules: [] },
    search: { value: '', errors: [], validationRules: [] },
    staffs: { value: [], errors: [], validationRules: [] },
    cohorts: { value: [], errors: [], validationRules: [] },
    opinions: { value: [], errors: [], validationRules: [] },
    patients: { value: [], errors: [], validationRules: [] },
    colleagues: { value: [], errors: [], validationRules: [] },
    defaultOpinion: { value: {}, errors: [], validationRules: [] },
    expertises: { value: {}, errors: [], validationRules: [] },
  },
  expertisesData: [],
  availableExpertises: [],
  pending: false,
  loading: false,
  success: false,
  deleted: false,
  error: false,
  errorMessage: null,
  isEditMode: false,
  colleagueId: null,
  currentUser: null,
  search: {
    colleagues: { value: '', loading: false },
    expertises: { value: '', loading: false },
    patients: { value: '', loading: false },
    opinions: { value: '', loading: false },
    staffs: { value: '', loading: false },
    cohorts: { value: '', loading: false },
  },
}

const slice = createSlice({
  name: 'colleague',
  initialState: INITIAL_STATE,
  reducers: {

    checkExpertiseFromList: (state, { payload: { type, id } }) => ({
      ...state,
      expertisesData: [
        ...state.expertisesData,
        state.availableExpertises.find(el => `${el.type}_${el.id}` === `${type}_${id}`)
      ]
    }),

    uncheckExpertiseFromList: (state, { payload: { type, id } }) => ({
      ...state,
      expertisesData: state.expertisesData
        .filter(el => `${el.type}_${el.id}` !== `${type}_${id}`)
    }),

    updateField: (state, { payload: { name, value, errors = [] } }) => ({
      ...state,
      data: {
        ...state.data,
        [name]: {
          ...state.data[name],
          value,
          errors,
        },
      },
      error: false,
      errorMessage: null,
    }),

    setQuery: (state, { payload: { name, value } }) => ({
      ...state,
      search: {
        ...state.search,
        [name]: {
          ...state.search[name],
          value
        }
      }
    }),

    success: (state, { payload: id }) => ({
      ...state,
      pending: false,
      error: false,
      success: true,
      colleagueId: id
    }),

    apiError: (state, action) => ({
      ...state,
      errorMessage: action.payload,
      pending: false,
      error: true,
      success: false,
    }),

    receivedAvailableExpertises: (state, { payload }) => ({
      ...state,
      availableExpertises: payload,
      loading: false,
      search: {
        ...state.search,
        expertises: {
          ...state.search.expertises,
          loading: false
        }
      }
    }),

    clean: state => INITIAL_STATE,

    fetchAvailableExpertises: state => ({
      ...state,
      search: {
        ...state.search,
        expertises: {
          ...state.search.expertises,
          loading: true
        }
      }
    }),

    fetch: (state, { payload }) => ({
      ...state,
      loading: true,
      colleagueId: payload
    }),

    received: (state, { payload }) => ({
      ...state,
      data: Object
        .entries(payload)
        .reduce((acc, [name, value]) => ({
          ...acc,
          [name]: {
            ...state.data[name],
            value,
          },
        }), state.data),
      expertisesData: Object
        .entries(payload.expertises)
        .reduce((acc, [type, entries]) =>
          [
            ...acc,
            ...entries.map(exp => ({
              ...exp,
              type,
            }))
          ], []),
      loading: false,
    }),
  },
})

export const {
  success,
  invalidate,
  updateField,
  apiError,
  expertises,
  receivedAvailableExpertises,
  updateSearchField,
  checkExpertiseFromList,
  uncheckExpertiseFromList,
  fetchAvailableExpertises,
  fetch,
  addAvailableExpertise,
  clean,
  received,
  setQuery,
} = slice.actions

export const selectData = state => state.colleague.data
export const selectSearch = state => state.colleague.search
export const selectSuccess = state => state.colleague.success
export const selectPending = state => state.colleague.pending
export const selectColleagueId = state => state.colleague.colleagueId
export const selectAvailableExpertises = state => state.colleague.availableExpertises

export default slice.reducer
