import styled, { css } from 'styled-components/macro'
import { IconDown } from '../Ui/Icon'
import React, { useReducer, useRef, useState, useEffect } from 'react'
import { ClickOutside } from '../../Util/ClickOutside'

const colorize = (props) =>
  props.$isGreen
    ? props.theme.patientGreen
    : props.theme.grey2
  ;

export const SelectContainer = styled.div`
  position: relative;
  border-radius: 8px;
  background: ${props => props.disabled
    ? '#f0f0f0'
    : props.$isFilled
      ? 'white'
      : 'none'
  };
  opacity: ${props => props.disabled
    ? '.5'
    : '1'
  };
  border: ${props => props.open
    ? '1px solid transparent'
    : `1px solid ${props.theme.grey2}`
  };
  padding: .8rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 16rem;
  box-sizing: border-box;
  min-height: 2.85rem;
  max-height: 2.85rem;
  cursor: ${props => props.disabled
    ? 'not-allowed'
    : 'pointer'
  };
  border-color: ${props => colorize(props)};

  select {
    display: none;
  }
`

export const SelectLabel = styled.label`
  font-size: .9rem;
  opacity: 1;
  cursor: pointer;
  color: ${props => props.theme.black};
  ${props => props.disabled && css`
    cursor: not-allowed;
    opacity: .5;
  `}
`

export const SelectList = styled.div`
  display: ${props => props.open ? 'block' : 'none'};
  background: white;
  border: .5px solid ${props => colorize(props)};
  box-shadow: ${props => props.theme.boxShadow.thick};
  position: absolute;
  border-radius: 8px;
  top: -3px;
  left: -1px;
  width: 100%;
  z-index: 5;
`

export const SelectItem = styled.div`
  padding: .8rem 1rem;
  font-size: .9rem;
  color: ${props => props.isLabel
    ? colorize(props)
    : props.theme.tagColors[props.color]
  };
  background-color: ${props => props.selected ? props.theme.greyLight : 'transparent'};
  cursor: ${props => props.isLabel
    ? 'default'
    : 'pointer'
  };

  ${props => !props.isLabel &&
    css`
      &:hover, &.selected {
        background: ${props => props.theme.greyLight};
      }
    `
  }
`

const SelectIconDown = styled(props => <IconDown {...props} />)`
  font-size: .55rem;
  margin-left: .5rem;
  color: ${props => props.theme.patientGreen};
`

const selectReducer = (state, action) => {

  switch (action.type) {
    case 'selectItem':
      return state
        .map(item => ({ ...item, selected: false }))
        .map(item => item.value === action.payload.value
          ? ({ ...action.payload, selected: true })
          : item
        )
    default:
      return state
  }
}


/**
 * This component display a fancy looking select box.
 * You can pass a list of items by repecting the following types:
 *
 * @type
 * type Item = {
 *  label: string,
 *  value: string,
 *  selected: boolean,
 * }
 *
 * type Items = Array<Item>
 */
export const Select = ({
  label = '',
  isGreen,
  isFilled,
  color = 'black',
  items = [{ label: '' }],
  disabled = false,
  onChange = () => null,
  ...restProps
}) => {
  const [state, dispatch] = useReducer(selectReducer, items)
  const [open, setOpen] = useState(false)
  const selectElement = useRef(null)
  useEffect(() => {
    const selected = items.find(i => i.selected)

    if (!selected) {
      return
    }

    dispatch({
      type: 'selectItem',
      payload: selected,
    })
  }, [items])

  return (
    <SelectContainer
      $isFilled={isFilled}
      onClick={e => !disabled ? setOpen(!open) : null}
      open={open}
      disabled={disabled}
    >
      <SelectLabel disabled={disabled}>
        {label ? `${label} : ` : ''}
        <span>{displaySelectedItem(state)}</span>
      </SelectLabel>
      <SelectIconDown />
      <select {...restProps} ref={selectElement} onChange={onChange}>
        {/* !!! mapping on items is safe ? mapping on state ? */}
        {items.map(item =>
          <option key={`option-${item.value}`} value={item.value}>{item.label}</option>
        )}
      </select>
      {!disabled &&
        <ClickOutside
          open={open}
          $isGreen={isGreen}
          Component={SelectList}
          onClickOutside={e => setOpen(false)}
        >
          {label &&
            <SelectItem $isGreen={isGreen} isLabel>{label}&nbsp;:</SelectItem>
          }
          {items.map(item =>
            <SelectItem
              key={item.value}
              selected={item.selected}
              color={item.color}
              onClick={e => {
                dispatch({
                  type: 'selectItem',
                  payload: item,
                })
                setOpen(false)

                selectElement.current.value = item.value

                onChange({ target: selectElement.current })
              }}
            >{item.label}</SelectItem>
          )}
        </ClickOutside>
      }
    </SelectContainer>
  )
}

const getSelectedItem = items => items
  .find(i => i.selected)
  || items[0]

const formatDisplayLabel = item => item.label && item.label.length > 20
  ? `${item.label.slice(0, 30)}${item.label.length > 30 ? '...' : ''}`
  : item.label

const displaySelectedItem = items =>
  formatDisplayLabel(getSelectedItem(items))
