import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import * as Invitation from '../State/Invitation';
import { useAlert } from 'react-alert'
import * as Ui from './Ui';

const InvitationComponent = ({
  type,
  teleExpertiseId,
  teleExpertiseName,
  data: teleExpertiseData,
  patientId,
  sentFromPatient = false,
}) => {
  const dispatch = useDispatch();
  const alert = useAlert()
  const messageSent = useSelector(Invitation.selectMessageSent)
  const {
    data,
    errorMessage
  } = useSelector(state => state.invitation)

  useEffect(() => {
    if (errorMessage)
      alert.error(errorMessage, { timeout: 5000 })
  }, [alert, errorMessage])

  useEffect(() => {
    if (messageSent)
      alert.error("Votre invitation a bien été envoyée.", { timeout: 5000 })
  }, [alert, messageSent])

  useEffect(() => {
    return () => {
      dispatch(Invitation.clean())
    }
  }, [dispatch])

  const sendInvitation = (e) => {
    e.preventDefault()
    if (data.email.value) {
      dispatch(Invitation.addRecipient({ value: data.email.value.trim() }))
    }
    dispatch(Invitation.sendInvitation({ type, teleExpertiseId, patientId, sentFromPatient }))
  }

  const updateField = name => e =>
    dispatch(Invitation.updateField({ name, value: e.target.value }))

  const updateEmailField = name => e => {
    dispatch(Invitation.updateField({ name, value: e.target.value }))
    if (e.target.value[e.target.value.length - 1] === ' ') {
      dispatch(Invitation.addRecipient({ value: e.target.value.trim() }))
    }
  }

  const blurEmailField = e => {
    if (e.target.value.length > 0) {
      dispatch(Invitation.addRecipient({ value: e.target.value.trim() }))
    }
  }

  const onUncheckRecipient = recipient => dispatch(Invitation.removeRecipient(recipient.trim()))

  const iconData = {
    type,
    ownerAndManagers: teleExpertiseData?.ownerAndManagers?.value,
    profilePicture: teleExpertiseData?.profilePicture?.value,
    thePersonal: teleExpertiseData?.thePersonal?.value,
    ownerProfilePicture: teleExpertiseData?.ownerProfilePicture?.value,
  }

  return (
    <InvitationSection padded>
      <Ui.Layout.ContentPanel>
        <Ui.Layout.ActionButtonsContainerFixed>
          <Ui.Icon.IconClose
            onClick={e => dispatch(Invitation.setIsInviting(false))}
          />
        </Ui.Layout.ActionButtonsContainerFixed>
        <Ui.Layout.Form onSubmit={sendInvitation} noValidate>
          <Ui.BasicElement.H2>
            {patientId
              ? 'Inviter un confrère à donner un avis sur ce patient'
              : 'Inviter un confrère'
            }
          </Ui.BasicElement.H2>
          <Ui.Layout.BlockContainer>
            <Ui.Form.TextInput
              htmlFor="email"
              label="Email *"
              value={data.email.value}
              onChange={updateEmailField('email')}
              onInputBlur={blurEmailField}
              error={data.email.errors[0]}
              noSubmit
            />
            {data.recipients.value.length > 0 &&
              <Ui.Layout.RadioGroupContainer>
                {data.recipients.value
                  .map((recipient, index) =>
                    <Recipient
                      key={`recipient_checkbox_${index}`}
                      value={recipient}
                      name={recipient}
                      label={recipient}
                      onChange={(e) => onUncheckRecipient(recipient)}
                      checked={true}
                    />
                  )}
              </Ui.Layout.RadioGroupContainer>
            }
            {data.recipients.errors[0] &&
              <Ui.Form.FieldErrorMessage>{data.recipients.errors[0]}</Ui.Form.FieldErrorMessage>
            }
          </Ui.Layout.BlockContainer>
          <Ui.Layout.BlockContainer>
            <Ui.Form.TextAreaInput
              key="message"
              htmlFor="message"
              label="Message"
              value={data.message.description}
              onChange={updateField('message')}
              noSubmit
            />
          </Ui.Layout.BlockContainer>
          {!patientId
            ? <Ui.Layout.BlockContainer>
              <Ui.BasicElement.H3>
                Téléexpertise partagée
              </Ui.BasicElement.H3>
              <Ui.Form.ButtonLarge
                value={teleExpertiseName}
                subLabel={teleExpertiseData?.ownerFullname?.value}
                label={teleExpertiseData?.name?.value}
              >
                <Ui.SearchBar.ExpertiseCheckboxIcon
                  value={iconData}
                />
              </Ui.Form.ButtonLarge>
            </Ui.Layout.BlockContainer>
            : null
          }
          <Ui.Layout.BlockContainer>
            <Ui.Layout.ButtonGroup>
              <Ui.Button.BasicButton
                type="submit"
                isGreen
                isFilled
                disabled={data.recipients.value.length === 0}
              >
                Envoyer
              </Ui.Button.BasicButton>
              <Ui.Button.BasicButton
                isGreen
                onClick={() => dispatch(Invitation.setIsInviting(false))}
              >
                Annuler
              </Ui.Button.BasicButton>
            </Ui.Layout.ButtonGroup>
          </Ui.Layout.BlockContainer>
        </Ui.Layout.Form>
      </Ui.Layout.ContentPanel>
    </InvitationSection >
  )
}

const InvitationSection = styled(props => <Ui.Layout.Section {...props} />)`
  ${Ui.Icon.IconClose} {
    font-size: 1.5rem;
    color: ${props => props.theme.grey};
    &:hover {
      cursor: pointer;
    }
  }
  ${Ui.BasicElement.H3} {
    color: ${props => props.theme.grey} !important;
  }
`

const Recipient = styled(props => <Ui.Form.CheckboxLarge {...props} />)`
  ${Ui.Form.CheckboxLargeContainer} {
    ${Ui.Form.Labels} {
      ${Ui.Form.DropdownItemLabel} {
        text-transform: lowercase;
      }
    }
  }
`

export default InvitationComponent;
