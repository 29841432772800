import React from 'react';
import styled from 'styled-components/macro'
import * as Ui from './Ui'
import CookiesContent from './Ui/CookiesContent';
import SEOTags from './SEOTags';

const CookiesComponent = () => {

  return (
    <>
      <SEOTags
        title="Politique de cookies - Skemeet"
      />
      <Ui.PortailLayout.PageLayout fullWidth>
        <CookiesContainer>
          <Ui.BasicElement.H1>Politique de cookies</Ui.BasicElement.H1>
          <CookiesContent />
        </CookiesContainer>
      </Ui.PortailLayout.PageLayout>
    </>
  )
}

const CookiesContainer = styled(props => <Ui.PortailLayout.PageContainer {...props} />)`
  @media only screen and (min-width: ${props => props.theme.device.desktop}) {
    margin: 0;
    margin-right: 4rem;
  }
  a {
    color: ${props => props.theme.patientGreen};
  }

  ${Ui.Form.InputContainer}:last-of-type {
    margin-bottom: .2rem;
  }

  ${Ui.BasicElement.H1} {
    margin-top: 3rem;
  }

  ${Ui.BasicElement.H2} {
    margin-top: 2rem;
    font-size: 1rem;
  }

  ${Ui.BasicElement.P} {
    margin: .5rem 0;
  }

  ${Ui.BasicElement.Link} {
    font-size: .875rem;
    font-weight: 700;
  }
`

export default CookiesComponent;
