import { createSlice } from '@reduxjs/toolkit'

// @TODO replace by a correct value from the api
export const STATUS_WAITING = 'waiting'
export const STATUS_DONE = 'done'

export const DISPLAY_ADDED = 'owned'
export const DISPLAY_SHARED = 'shared'
export const DISPLAY_MINE = 'mine'
export const DISPLAY_All = 'all'

export const ORDER_BY_LATEST = 'updatedAt'
export const ORDER_BY_LASTNAME = 'lastName'

export const MODE_RECEIVED_MORE = 'received more'
export const MODE_RECEIVED_FIRST = 'received first'

export const INITIAL_STATE = {
  data: [],
  keywordsNotFound: [],
  checked: [],
  allChecked: false,
  hasMore: true,
  loading: false,
  pending: false,
  error: false,
  success: false,
  errorMessage: null,
  filters: {
    status: [
      { value: STATUS_WAITING, checked: false },
      { value: STATUS_DONE, checked: false },
    ],
    orderBy: ORDER_BY_LATEST,
    displayBy: DISPLAY_MINE,
    search: '',
  },
  patientCountAll: 0,
  patientCount: { new: null, waiting: null, done: null },
  recentTeleExpertises: []
}

const slice = createSlice({
  name: 'patientList',
  initialState: INITIAL_STATE,
  reducers: {
    removeStatus: (state, { payload }) => ({
      ...state,
      filters: {
        ...state.filters,
        status: state
          .filters
          .status
          .map(element => element.value === payload
            ? ({ ...element, checked: false })
            : element
          ),
      },
    }),

    addStatus: (state, { payload }) => ({
      ...state,
      keywordsNotFound: [],
      filters: {
        ...state.filters,
        search: '',
        status: state
          .filters
          .status
          .map(element => element.value === payload
            ? ({ ...element, checked: true })
            : element
          ),
      },
    }),

    changeDisplayBy: (state, { payload }) => ({
      ...state,
      filters: {
        ...state.filters,
        displayBy: payload,
      },
    }),

    changeOrderBy: (state, { payload }) => ({
      ...state,
      data: state.data.slice().sort((a, b) =>
        payload === ORDER_BY_LASTNAME
          ? a.lastName.toLowerCase() < b.lastName.toLowerCase()
          : a.createdAt < b.createdAt
      ),
      filters: {
        ...state.filters,
        orderBy: payload,
      },
    }),

    changeSearch: (state, { payload }) => ({
      ...state,
      filters: {
        ...state.filters,
        displayBy: DISPLAY_All,
        status: [
          { value: STATUS_WAITING, checked: false },
          { value: STATUS_DONE, checked: false },
        ],
        search: payload,

      },
    }),

    check: (state, { payload }) => ({
      ...state,
      checked: [...state.checked, payload],
      allChecked: [...state.checked, payload].length === state.data.length,
    }),

    uncheck: (state, { payload }) => ({
      ...state,
      checked: state.checked.filter(id => id !== payload),
      allChecked: false,
    }),

    checkAll: state => ({
      ...state,
      checked: state.data.map(({ id }) => id),
      allChecked: true,
    }),

    uncheckAll: state => ({
      ...state,
      checked: [],
      allChecked: false,
    }),

    fetch: state => ({
      ...state,
      loading: true,
    }),

    received: (state, { payload }) => ({
      ...state,
      loading: false,
      data: payload.mode === MODE_RECEIVED_FIRST
        ? payload.data
        : [...state.data, ...payload.data],
      hasMore: payload.mode === MODE_RECEIVED_FIRST
        ? payload.data.length < state.patientCountAll
        : state.data.length + payload.data.length < state.patientCountAll,
      keywordsNotFound: payload.keywordsNotFound,
    }),

    receivedCountAll: (state, { payload }) => ({
      ...state,
      patientCountAll: payload
    }),

    count: state => ({
      ...state,
    }),

    getRecentTeleExpertises: state => ({
      ...state,
    }),

    receivedCount: (state, { payload }) => ({
      ...state,
      patientCount: payload
    }),

    receivedRecentTeleExpertises: (state, { payload }) => ({
      ...state,
      recentTeleExpertises: payload
    }),

    apiError: (state, action) => ({
      ...state,
      errorMessage: action.payload,
      pending: false,
      error: true,
      success: false,
    }),

    clean: state => INITIAL_STATE,
  }
})

export default slice.reducer

export const {
  removeStatus,
  addStatus,
  changeDisplayBy,
  changeOrderBy,
  changeSearch,
  fetch,
  received,
  check,
  uncheck,
  checkAll,
  uncheckAll,
  receivedCountAll,
  count,
  receivedCount,
  receivedRecentTeleExpertises,
  getRecentTeleExpertises,
  apiError,
  clean,
} = slice.actions

export const selectPatients = state => state.patientList.data

export const selectKeywordsNotFound = state => state.patientList.keywordsNotFound

export const selectFilters = state => state.patientList.filters

export const selectHasMore = state => state.patientList.hasMore

export const selectLoading = state => state.patientList.loading

export const selectChecked = state => state.patientList.checked

export const selectHasCheckedItems = state => 0 !== state.patientList.checked.length

export const selectIsAllChecked = state => state.patientList.allChecked

export const selectCountAll = state => state.patientList.patientCountAll

export const selectCount = state => state.patientList.patientCount

export const selectRecentTeleExpertises = state => state.patientList.recentTeleExpertises
