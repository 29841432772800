import React, { useEffect, useState } from 'react';
import { Redirect, useHistory } from "react-router-dom";
import styled from 'styled-components/macro'
import { useDispatch, useSelector } from 'react-redux';
import LoginForm from './Ui/LoginForm'
import * as Login from '../State/Login'
import * as Patient from '../State/Patient'
import * as Ui from './Ui'
import { apiUrl, ErrorMessage, defaultBannerBackground } from '../Util/Config'
import { mimeTypeIcon } from '../Util/MimeTypeIcons'
import { deleteCookie } from '../Util/Storage'
import { authUrl } from '../Util/Config';
import SEOTags from './SEOTags';

const PortalComponent = (props) => {
  const history = useHistory();
  const data = useSelector(Patient.selectData);
  const pending = useSelector(Patient.selectPending);
  const addPatientSuccess = useSelector(Patient.selectCreationSuccess)

  const updateField = name => e => {
    dispatch(Patient.updateField({ name, value: e.target.value }))
  }

  const keyPressFunc = (e) => {
    if (e.which === 8) {
      let val = data.birthdate.value;

      if (val.length === 3 || val.length === 6) {
        val = val.slice(0, val.length - 1);
        dispatch(Patient.updateField({ name: 'birthdate', value: val }))
      }
    }
  }

  const updateDateField = name => e => {
    let val = e.target.value;

    if (val.length === 2) {
      val += '/';
    } else if (val.length === 5) {
      val += '/';
    }
    dispatch(Patient.updateField({ name, value: val }))
  }

  const storePatient = e => {
    e.preventDefault()
    dispatch(Patient.send({ isUserNotLogged: true }))
  }

  const { match: { params: { publicUri, tab, teleExpertiseType: teType } } } = props

  const teleExpertiseType = teType || window.location.host.match(/(staff|cohorte|avis).skemeet.(io|dev)/)[1]

  const dispatch = useDispatch()

  useEffect(() => {
    deleteCookie('patient');
    deleteCookie('teleExpertise');
  }, [])

  useEffect(() => {
    dispatch(Login.fetchCustomPageData({ teleExpertiseType, publicUri }))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (addPatientSuccess) {
      window.location.href = `${authUrl}/connexion`
    }
  }, [addPatientSuccess])

  useEffect(() => {
    if (!tab) {
      history.push(`${window.location.pathname.replace(/\/$/, '')}/${Login.SECTION_CREATE_PATIENT.slug}`)
      setCurrent(Login.SECTION_CREATE_PATIENT.slug)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab])

  const [current, setCurrent] = useState(tab)
  const selectedExpertise = useSelector(state => state.login.selectedExpertise)
  const loading = useSelector(Login.selectLoading)
  const error = useSelector(Login.selectedErrorMessage)
  const bannerAlign = selectedExpertise?.portalHeaderImageAlign
  const bannerStretch = selectedExpertise?.portalHeaderImageStretch
  const bannerBackground = selectedExpertise?.portalHeaderImage

  const navLinks = [
    [<Ui.Icon.IconPatientSup />, Login.SECTION_CREATE_PATIENT, true],
    [<Ui.Icon.IconInfos />, Login.SECTION_ABOUT, selectedExpertise.portalAbout],
    [<Ui.Icon.IconDocument />, Login.SECTION_DOCUMENTS, selectedExpertise.portalDocuments.length > 0],
  ]

  if (error === ErrorMessage.NOT_FOUND) {
    return <Redirect to="/CustomPageNotFound" />
  }

  const onClickTab = (slug) => {
    history.push(slug)
    setCurrent(slug)
  }
  const titlePrefix = selectedExpertise.type === 'staff' && !/\b(staff|rcp)\b/i.test(selectedExpertise.name)
    ? 'Staff - '
    : selectedExpertise.type === 'opinion' && !/\bavis\b/i.test(selectedExpertise.name)
      ? 'Avis - '
      : selectedExpertise.type === 'cohort' && !/\bcohorte\b/i.test(selectedExpertise.name)
        ? 'Cohorte - '
        : ''

  return (
    <>
      <SEOTags
        title={selectedExpertise.name
          ? `${titlePrefix}${selectedExpertise.name} - Skemeet`
          : ''
        }
        description={selectedExpertise.description}
        keywords={[selectedExpertise.disciplines, selectedExpertise.tags]}
      />
      <LoginTeleExpertiseContainer>
        <Ui.PortailHeader />
        <Ui.PortailLayout.PortalMain $loading={loading}>
          {loading
            ? <Ui.Global.Loader />
            : <>
              <Ui.PortailLayout.PortalContainer>
                <Ui.PortailLayout.PortalContentContainer>
                  <Ui.PortailLayout.FlexPage>
                    <Ui.PortailLayout.Content>
                      <Ui.PortailLayout.Banner
                        isPortalBanner
                        backgroundSrc={bannerBackground
                          ? `${apiUrl}/public/${selectedExpertise?.type}s/portal-header-image/${bannerBackground}`
                          : `${defaultBannerBackground}`
                        }
                        isStretch={bannerStretch || !bannerBackground}
                        alignment={bannerAlign}
                      />
                      <PortalBody>
                        <Ui.HomeSideBar selectedExpertise={selectedExpertise} fromHome />
                        <Ui.Layout.FlexBox flow="column">
                          <TeleExpertiseTitle>
                            {selectedExpertise.name}
                          </TeleExpertiseTitle>
                          <Ui.Layout.NavContainer>
                            <nav>
                              {navLinks
                                .map(([Icon, { label, slug }, hasContent], idx) =>
                                  hasContent &&
                                  <Ui.Layout.NavLink
                                    key={idx}
                                    onClick={() => onClickTab(slug, label)}
                                    on={current === slug}
                                  >
                                    {Icon}{label}
                                  </Ui.Layout.NavLink>
                                )
                              }
                            </nav>
                          </Ui.Layout.NavContainer>
                          {(tab === Login.SECTION_ABOUT.slug) &&
                            <PageContainer>
                              <About flow="row">
                                <div>
                                  <Ui.BasicElement.P>
                                    {selectedExpertise.portalAbout && selectedExpertise.portalAbout}
                                  </Ui.BasicElement.P>
                                </div>
                              </About>
                            </PageContainer>
                          }
                          {tab === Login.SECTION_CREATE_PATIENT.slug &&
                            <PageContainer>
                              <Ui.Layout.FlexBox flow="column wrap">
                                <Ui.Layout.Row>
                                  <Ui.Form.TextInput
                                    htmlFor="lastname"
                                    label="Nom *"
                                    value={data.lastname.value}
                                    onChange={updateField('lastname')}
                                    autoFocus
                                    error={data.lastname.errors[0]}
                                    noSubmit
                                  />
                                  <Ui.Form.TextInput
                                    htmlFor="firstname"
                                    label="Prénom *"
                                    value={data.firstname.value}
                                    onChange={updateField('firstname')}
                                    error={data.firstname.errors[0]}
                                    noSubmit
                                  />
                                </Ui.Layout.Row>
                                <Ui.Layout.Row>
                                  <Ui.Layout.InputBoxContainer>
                                    <Ui.Form.Label>Date de naissance *</Ui.Form.Label>
                                    <Ui.Form.Input
                                      htmlFor="birthdate"
                                      label="Date de naissance"
                                      value={data.birthdate.value}
                                      onChange={updateDateField('birthdate')}
                                      onKeyDown={keyPressFunc}
                                      maxLength="10"
                                      placeholder="JJ/MM/AAAA"
                                      error={data.medicalId.errors[0]}
                                    />
                                    {data.birthdate.errors[0] &&
                                      <Ui.Form.FieldErrorMessage>{data.birthdate.errors[0]}</Ui.Form.FieldErrorMessage>
                                    }
                                  </Ui.Layout.InputBoxContainer>
                                  <Ui.Layout.InputBoxContainer>
                                    <Ui.Form.Label>Sexe *</Ui.Form.Label>
                                    <Ui.Select.Select
                                      label=""
                                      htmlFor="sex"
                                      items={[['male', 'Homme'], ['female', 'Femme'], ['undetermined', 'Indéterminé']]
                                        .map(([en, fr]) => ({
                                          label: fr,
                                          value: en,
                                          selected: fr === data.gender.value,
                                        }))}
                                      onChange={updateField('gender')}
                                    />
                                  </Ui.Layout.InputBoxContainer>
                                </Ui.Layout.Row>
                              </Ui.Layout.FlexBox>
                              <Ui.Layout.FlexBox justify="flex-end">
                                <Ui.Button.NavbarButton
                                  isFilled
                                  isGreen
                                  type="button"
                                  onClick={storePatient}
                                  disabled={
                                    pending
                                  }>
                                  {pending &&
                                    <Ui.Global.Loader />
                                  }
                                  Continuer
                                </Ui.Button.NavbarButton>
                              </Ui.Layout.FlexBox>
                            </PageContainer>
                          }
                          {tab === Login.SECTION_DOCUMENTS.slug &&
                            selectedExpertise.portalDocuments.length > 0 &&
                            <PageContainer>
                              <Ui.Layout.FlexBox>
                                <Ui.UploadFile.PreviewContainer>
                                  {selectedExpertise
                                    .portalDocuments
                                    .map(({ fileName, name, size, mimeType, title }, idx) =>
                                      <Ui.DocumentPreview.DocumentPreview
                                        key={`document_${idx}`}
                                        name={name}
                                        mimeType={mimeType}
                                        apiPath="/public/tele-expertises/portal/assets/"
                                        size={size}
                                        fileName={fileName}
                                        title={title}
                                        iconSrc={mimeTypeIcon(mimeType)}
                                      >
                                      </Ui.DocumentPreview.DocumentPreview>
                                    )}
                                </Ui.UploadFile.PreviewContainer>
                              </Ui.Layout.FlexBox>
                            </PageContainer>
                          }
                        </Ui.Layout.FlexBox>
                      </PortalBody>
                    </Ui.PortailLayout.Content>
                  </Ui.PortailLayout.FlexPage>
                </Ui.PortailLayout.PortalContentContainer>
                <Ui.PortailLayout.Footer noLogo />
              </Ui.PortailLayout.PortalContainer>
            </>
          }
        </Ui.PortailLayout.PortalMain>
      </LoginTeleExpertiseContainer>
    </>
  )
}

const PageContainer = styled(props => <Ui.PortailLayout.PageContainer {...props} />)`
  margin: 2rem auto;
  padding: 0 2rem;
  ${Ui.Button.NavbarButton} {
    position:relative;
    right: 0;
  }
  ${Ui.UploadFile.PreviewContainer} {
    flex-direction: row;
  }
  ${Ui.DocumentPreview.DocumentPreviewElement} {
    margin-right: .5rem;
  }
  @media only screen and (min-width: ${props => props.theme.device.desktop}) {
    width: 65%;
    padding: 0;
  }
`

const TeleExpertiseTitle = styled(props => <Ui.BasicElement.H1 {...props} />)`
 margin: 2.5rem 0;
 text-align: center;
 align-self: center;
 font-size: 2rem;
 width: 65%;
`

const PortalBody = styled(props => <Ui.Layout.FlexBox {...props} />)`
  flex-direction: column;
  @media only screen and (min-width: ${props => props.theme.device.desktop}) {
    flex-direction: row;
  }
`

const About = styled(props => <Ui.Layout.FlexBox {...props} />)`
  font-size: 1rem;
  color: ${props => props.theme.darkBlue};
  & > div:first-child {
    padding-right: 2rem;
    flex: 1;
  }
`

const LoginTeleExpertiseContainer = styled(props => <Ui.PortailLayout.Wrapper {...props} />)`
  ${LoginForm.LoginContainer} {
    ${Ui.Form.DropdownItemLabel} {
      max-width: none;
    }
  }
  ${Ui.PortailLayout.PortalContentContainer} {
    background: white;
    padding-left: 0;
  }
  ${Ui.PortailLayout.PortalSideBar} {
    position: relative;
    overflow: initial;
    padding-top: 0;
    height: 100%;
  }
  ${Ui.Layout.NavContainer} {
    position: static;
    background-color: transparent;
    flex: initial;
    nav {
      margin: 0;
      > div {
      @media only screen and (min-width: ${props => props.theme.device.desktop}) {
        span {
          padding-left: 7px;
          padding-right: .4rem;
        }
        & span:first-child {
          padding-left: 0;
        }
      }
    }
  }

    @media only screen and (min-width: ${props => props.theme.device.desktop}) {
      width: 65%;
      margin: 0 auto;
    }
  }
  ${Ui.Layout.NavLinkItem} {
    @media only screen and (min-width: ${props => props.theme.device.desktop}) {
      margin-right: .2rem;
    }
  }

  ${Ui.PortailLayout.Content} {
    @media only screen and (min-width: ${props => props.theme.device.desktop}) {
      margin-left: 0;
    }
  }
  ${Ui.PortailLayout.Figure} {
    margin-left: 1rem;
  }
  /* ${Ui.PortailLayout.PortalContentContainer} {
    @media only screen and (min-width: ${props => props.theme.device.desktop}) {
      min-height: calc(100vh - 175px);
    }
  } */
  ${Ui.PortailLayout.FooterContainer} {
    //margin-top: 2rem;
    background: white;
    z-index: 100;
    width: 100%;
    border-top: 1px dotted ${props => props.theme.mediumGrey};
    padding: 0;
    padding-right: 1rem;
    box-sizing: border-box;
    @media only screen and (min-width: ${props => props.theme.device.desktop}) {
      padding-left: 3rem;
    }
  }
`

export default PortalComponent;
