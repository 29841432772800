import * as MessageList from '../State/MessageList'
import * as Resources from '../State/Resources'
import * as Eff from 'redux-saga/effects'
import * as Api from '../Util/Api'
import { JJMMAAAAToDate } from '../Util/Format'

export function* fetch({
  payload: {
    page = 1,
  } = {},
} = {}) {
  try {
    const messages = yield Eff.call(Api.fetchMessagesAndNotifications)

    yield Eff.put(Resources.received({
      name: [Resources.RESOURCE_MESSAGES],
      data: messages
        .map(({ createdAt, ...message }) => ({
          ...message,
          dateString: createdAt.split(' ')[0],
          date: JJMMAAAAToDate(createdAt.split(' ')[0]),
          hour: createdAt.split(' ')[1]
        })),
    }))

    yield Eff.put(MessageList.received({
      data: {
        messages: messages.map(message => message.id),
      }
    }))

    yield Eff.call(Api.allNotificationsRead)
    yield Eff.put(MessageList.resetNotificationsCount())

  } catch (error) {
    yield Eff.put(MessageList.apiError(error.message))
  }
}

export function* fetchMessagesNum() {
  try {
    const {
      unreadMessages,
      unreadNotifications,
    } = yield Eff.call(Api.fetchMessagesNum)
    yield Eff.put(MessageList.receivedMessagesNum({ unreadMessages, unreadNotifications }))
  } catch (error) {
    yield Eff.put(MessageList.apiError(error.message))
  }
}

const MessageListSagas = function* () {
  yield Eff.takeLatest([
    `${MessageList.fetch}`,
    `${MessageList.changeDisplayBy}`,
    `${MessageList.changeFilterBy}`,
  ], fetch)
  yield Eff.takeLatest(
    `${MessageList.fetchMessagesNum}`, fetchMessagesNum)
}

export default MessageListSagas;
