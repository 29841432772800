import { createSlice } from '@reduxjs/toolkit'

export const INITIAL_STATE = {
  data: {},
  errorMessage: null,
  pending: false,
  error: false,
  success: false,
}

const slice = createSlice({
  name: 'uploadFile',
  initialState: INITIAL_STATE,
  reducers: {
    newUploader: (state, { payload: { name, endpoint, saveEndpoint, removeEndpoint, sliceToUpdate, fieldId, validationRules } }) => {

      return ({
        ...state,
        data: {
          ...state.data,
          [name]: {
            value: {},
            errors: [],
            validationRules: validationRules || [],
            endpoint,
            saveEndpoint,
            removeEndpoint,
            sliceToUpdate,
            fieldId,
          },
        },
      })
    },

    resetUploader: (state, { payload: name }) => ({
        ...state,
        data: {
          ...state.data,
          [name]: {
            ...state.data[name],
            value: {},
            errors: [],
          },
        },
      }),

    upload: (state, { payload: { uploader, file } }) => ({
      ...state,
      data: {
        ...state.data,
        [uploader]: {
          ...state.data[uploader],
          value: {
            ...state.data[uploader].value,
            [file.name]: {
              name: file.name,
              size: file.size,
              type: file.type,
              sending: file.sending,
            },
          },
          errors: [],
        },
      },
    }),

    attach: (state, { payload: { uploader, file } }) => ({
      ...state,
      data: {
        ...state.data,
        [uploader]: {
          ...state.data[uploader],
          value: {
            ...state.data[uploader].value,
            [file.name || file.fileName || file.id]: {
              name: file.name,
              size: file.size,
              type: file.type || file.mimeType,
              id: file.id,
              fileName: file.fileName,
              sending: false,
            },
          },
          errors: [],
        },
      },
    }),

    update: (state, { payload: { uploader, file, errors } }) => ({
      ...state,
      data: {
        ...state.data,
        [uploader]: {
          ...state.data[uploader],
          value: file
            ? {
              ...state.data[uploader].value,
              [file.name]: file,
            }
            : state.data[uploader].value,
            // : Object
            //   .entries(state.data[uploader].value)
            //   .map(([key, value]) => ({ [key]: value.map(v => ({ ...v, sending: false })) }))
            //   .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {}),
          errors: errors || state.data[uploader].errors,
        },
      },
    }),

    remove: (state, { payload: { uploader, name } }) => ({
      ...state,
      errorMessage: null,
      data: {
        ...state.data,
        [uploader]: {
          ...state.data[uploader],
          value: Object
            .values(state.data[uploader].value)
            .filter(file => file.name !== name)
            .reduce((acc, file) => ({
              ...acc,
              [file.name]: file,
            }), {}),
        }
      }
    }),

    successAction: state => ({ ...state, success: true }),

    clean: (state, { payload }) => ({
      ...state,
      data: Object
        .keys(state.data)
        .filter(uploader => uploader !== payload)
        .reduce((acc, key) => ({
          ...acc,
          [key]: { ...state.data[key], errors: [] },
        }), {})
      ,
    }),

    cleanList: (state, { payload }) => ({
      ...state,
      data: {
        ...Object
          .keys(state.data)
          .filter(uploader => (!/_\d$/.test(uploader)))
          .reduce((acc, key) => ({
            ...acc,
            [key]: { ...state.data[key] }
          }), {}),
        ...Object
          .keys(state.data)
          .filter(uploader => (/_\d$/.test(uploader)))
          .filter(uploader => uploader !== payload)
          .map((key, idx) => {
            const [base] = key.split('_')
            return ({ [`${base}_${idx}`]: { ...state.data[key], errors: [] } })
          })
          .reduce((acc, el) => ({
            ...acc,
            ...el
          }), [])
      }
    }),

    cleanAll: state => INITIAL_STATE,

    apiError: (state, action) => ({
      ...state,
      errorMessage: action.payload,
      pending: false,
      error: true,
      success: false,
    }),
  }
})

export const {
  newUploader,
  resetUploader,
  upload,
  attach,
  update,
  remove,
  successAction,
  clean,
  cleanList,
  cleanAll,
  apiError,
} = slice.actions

export const selectSuccess = state => state.uploadFile.success
export const selectData = state => state.uploadFile.data
export const selectImagerie = state => state.uploadFile.data.imagerie
export const selectErrorMessage = state => state.uploadFile.errorMessage
export const selectUploading = state =>
  Object.values(state.uploadFile.data)
    .some(uploadType => Object.values(uploadType.value).some(attachment => attachment.sending))

export default slice.reducer
