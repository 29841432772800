import * as Organization from '../State/Organization'
import * as Profile from '../State/Profile'
import * as Resources from '../State/Resources'
import * as Form from '../Util/Form'
import * as Api from '../Util/Api'
import { takeLatest, call, put, select, delay } from 'redux-saga/effects'
import { debounceTime } from '../Util/Date'

export function* fetch() {
  try {
    const id = yield select(Organization.selectSearchId)
    const {
      id: organizationId,
      name,
      logo,
      members,
      managers,
      staffs,
      opinions,
      cohorts,
      _canBe,
      _canBeModified,
    } = yield call(Api.fetchOrganization, id)

    yield put(Resources.received({
      name: [Resources.RESOURCE_MEMBERS],
      data: [...members, ...managers],
    }))

    yield put(Organization.receivedAvailableExpertises({
      teleExpertises: [
        ...staffs.map(e => ({ ...e, type: 'staffs' })),
        ...opinions.map(e => ({ ...e, type: 'opinions' })),
        ...cohorts.map(e => ({ ...e, type: 'cohorts' })),
      ]
    }))

    yield put(Organization.received({
      organizationId,
      name,
      logo,
      members: members.map(e => e.id),
      managers: managers.map(e => e.id),
      expertises: [
        ...staffs.map(e => ({ type: 'staffs', id: e.id })),
        ...opinions.map(e => ({ type: 'opinions', id: e.id })),
        ...cohorts.map(e => ({ type: 'cohorts', id: e.id })),
      ],
      permissions: { _canBe, _canBeModified }
    }))
  } catch (error) {
    yield put(Organization.apiError(error.message))
  }
}

export function* send() {
  try {
    const data = yield select(Organization.selectData)
    const validatedData = yield call(Form.validate, data)

    const staffs = validatedData
      .expertises
      .value
      .filter(e => e.type === 'staffs')
      .map(e => Number(e.id))
    const opinions = validatedData
      .expertises
      .value
      .filter(e => e.type === 'opinions')
      .map(e => Number(e.id))
    const cohorts = validatedData
      .expertises
      .value
      .filter(e => e.type === 'cohorts')
      .map(e => Number(e.id))

    yield call(Api.createOrganization, {
      name: data.name.value,
      members: data.members.value,
      managers: data.managers.value,
      staffs,
      opinions,
      cohorts,
    })

    yield put(Organization.success())
  } catch (error) {
    if (error instanceof Form.ValidationError) {
      for (let [name, field] of Object.entries(error.data)) {
        yield put(Organization.updateField({
          name,
          value: field.value,
          errors: field.errors,
        }))
      }
      yield put(Organization.invalidate(
        `Une information obligatoire est manquante pour valider la création de votre organisation.`
      ))
    } else {
      yield put(Organization.apiError(error.message))
    }
  }
}

export function* fetchExpertises(type, query) {
  try {
    yield put(Organization.fetchAvailableExpertises())
    const types = type === 'expertises'
      ? 'staffs,opinions,cohorts'
      : type
    const { teleExpertises, keywordsNotFound } = yield call(Api.getTeleExpertisesAllNoCf, types, query)
    yield put(Organization.receivedAvailableExpertises({ teleExpertises, keywordsNotFound }))

  } catch (error) {
    yield put(Organization.apiError(error.message))
  }
}

export function* updateResource({ payload: { organizationId, resource, resourceId, toggled } }) {
  try {
    const action = toggled ? 'add' : 'remove'
    const target = resource === 'member' ? 'members' : 'managers'
    yield call(Api.updateOrganization, organizationId, action, resource, resourceId)
    yield put(Profile.setEditMode({ target, value: false }))

  } catch (error) {
    yield put(Organization.apiError(error.message))
  }
}

export function* removeLogo({ payload: { organizationId } }) {
  try {
    yield call(Api.removeOrganizationLogo, organizationId)
  } catch (error) {
    yield put(Organization.apiError(error.message))
  }
}

export function* updateTeleExpertise({ payload: { organizationId, resource, resourceId, toggled } }) {
  try {
    const action = toggled ? 'add' : 'remove'
    yield call(Api.updateOrganization, organizationId, action, resource, resourceId)
    yield put(Profile.setEditMode({ target: 'teleExpertises', value: false }))
  } catch (error) {
    yield put(Organization.apiError(error.message))
  }
}

export function* setQuery({ payload: { name, value: query } }) {
  try {
    if (query) {
      const delayMs = debounceTime(query)

      yield delay(delayMs)

      if (name === 'disciplines') {
        yield put(Resources.load({
          filter: [Resources.RESOURCE_DISCIPLINES],
          query,
        }))
      } else if (name === 'managers' || name === 'members') {
        yield put(Resources.load({
          filter: [Resources.RESOURCE_MEMBERS],
          query,
        }))
      } else {
        yield fetchExpertises(name, query)
      }
    }

  } catch (error) {
    yield put(Organization.apiError(error.message))
  }
}

export function* saveField({ payload: { fieldName, scope, organizationId } }) {
  try {
    const data = yield select(Organization.selectData)
    const fieldData = data[fieldName]
    const validatedData = yield call(Form.validate, { [fieldName]: fieldData })
    if (!validatedData[fieldName].errors.length > 0) {
      const apiData = {
        [scope]:
          { [fieldName]: validatedData[fieldName].value }
      }
      yield call(Api.updateOrganizationName, organizationId, apiData)
      yield put(Organization.setEditField({ field: fieldName, value: false }))
      yield put(Organization.updateInitialValue({ field: fieldName, value: fieldData.value }))
    }
    yield put(Organization.success())
  } catch (error) {
    if (error instanceof Form.ValidationError) {
      for (let [name, field] of Object.entries(error.data)) {
        yield put(Organization.updateField({
          name,
          value: field.value,
          errors: field.errors,
        }))
      }
      yield put(Organization.invalidate({
        message:
          'Une information obligatoire est manquante pour valider la mise à jour de vos données.'
      }))
    } else {
      yield put(Organization.apiError(error.message))
    }
  }
}

const sagas = function* () {
  yield takeLatest(`${Organization.fetch}`, fetch);
  yield takeLatest(`${Organization.send}`, send);
  yield takeLatest(`${Organization.setQuery}`, setQuery)
  yield takeLatest(`${Organization.updateResource}`, updateResource)
  yield takeLatest(`${Organization.updateTeleExpertise}`, updateTeleExpertise)
  yield takeLatest(`${Organization.removeLogo}`, removeLogo)
  yield takeLatest(`${Organization.saveField}`, saveField)
};

export default sagas;
