import React, { useEffect } from 'react'
import { privateSitePrefix } from '../Util/Config'
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from "react-router-dom";
import { useAlert } from 'react-alert'
import * as Menu from '../State/Menu'
import * as TeleExpertise from '../State/Staff';
import * as CustomField from '../State/CustomField';
import * as Login from '../State/Login';
import * as Resources from '../State/Resources';
import * as UploadFile from '../State/UploadFile';
import * as Ui from './Ui';
import { fr } from '../Util/i18n';
import styled from 'styled-components/macro'
import Tags from './Ui/Tags';
import { staffPublicUrl } from '../Util/Config'
import LoadingBox from './Ui/LoadingBox'

const CreateStaffComponent = () => {
  const alert = useAlert()
  const history = useHistory()
  const dispatch = useDispatch()
  const teleExpertiseName = 'staff'
  const currentUser = useSelector(Login.selectUser)
  const uploading = useSelector(UploadFile.selectUploading)
  const resourceLoading = useSelector(Resources.selectLoading)
  const {
    id,
    data,
    search,
    sending,
    errorMessage,
  } = useSelector(state => state.staff)

  const {
    [Resources.RESOURCE_DISCIPLINES]: disciplines,
  } = useSelector(Resources.selectResources)

  const updateField = name => e => {
    if (name === 'hour' && e.target.value.length === 2) {
      e.target.value += ':';
    }
    dispatch(TeleExpertise.updateData({ name, value: e.target.value }))
  }

  const updateTagContent = (name, idx) => e => {
    dispatch(TeleExpertise.updateTagContent({ name, value: e.target.value, idx }))
  }

  const send = e => {
    e.preventDefault()
    dispatch(TeleExpertise.send())
  }

  useEffect(() => {
    dispatch(Menu.changeActive(Menu.STAFFS))

    return () => {
      dispatch(TeleExpertise.clean())
      dispatch(CustomField.clean())
    }
  }, [dispatch])

  useEffect(() => {
    dispatch(TeleExpertise.fetchOrganizations())
    dispatch(TeleExpertise.suggestSpecialities(currentUser.speciality))
  }, [dispatch, currentUser.speciality])

  useEffect(() => {

    dispatch(TeleExpertise.updateData({ name: 'nextSession', value: '' }))
  }, [dispatch])

  useEffect(() => {
    if (errorMessage)
      alert.error(errorMessage, { timeout: 5000 })
  }, [alert, errorMessage])

  if (id) {
    return <Redirect to={`${privateSitePrefix}/${fr[teleExpertiseName].expertises}/${id}`} />
  }

  return (
    <>
      <LoadingBox />
      <Ui.Layout.PageLayout currentUser={currentUser}>
        <TeleExpertiseContainer>
          <Ui.Layout.Form onSubmit={(e) => e.preventDefault()} noValidate>
            <Ui.Layout.PageContentWrapper>
              {/* Panel */}
              <Ui.Layout.PanelContainer>
                <Ui.Layout.Panel>
                  <Ui.Button.BackButton onClick={history.goBack} />
                  <Ui.BasicElement.H1Container type={teleExpertiseName}>
                    <IconH1 type={teleExpertiseName}>
                      {teleExpertiseName === 'staff'
                        ? <Ui.Icon.IconMessage />
                        : teleExpertiseName === 'cohort'
                          ? <Ui.Icon.IconCohorte />
                          : <Ui.Icon.IconAvis />
                      }
                    </IconH1>
                    <Ui.BasicElement.H1>
                      {fr[teleExpertiseName].newExpertise}
                    </Ui.BasicElement.H1>
                  </Ui.BasicElement.H1Container>
                  <Ui.BasicElement.P>
                    Créez un staff pour discuter avec vos confrères en
                    visioconférence et produire des comptes-rendus de
                    RCP
                  </Ui.BasicElement.P>
                </Ui.Layout.Panel>
              </Ui.Layout.PanelContainer>
              <Ui.Layout.Content hasPanel noPadding>
                <Ui.Layout.NavContainer>
                  <nav>
                    <Ui.Layout.NavLink
                      on={true}
                    >
                      <Ui.Icon.IconInfos />
                      Créer votre staff
                    </Ui.Layout.NavLink>
                  </nav>
                  <Ui.Button.NavbarButton
                    isFilled
                    isPrimary
                    type="button"
                    onClick={send}
                    disabled={
                      sending ||
                      uploading
                    }
                  >
                    {sending &&
                      <Ui.Global.Loader />
                    }
                    Valider
                  </Ui.Button.NavbarButton>
                </Ui.Layout.NavContainer>
                <Ui.Layout.PaddedContent>
                  <Section>
                    <Ui.Layout.Row>
                      <Ui.Form.TextInput
                        htmlFor="name"
                        label="Nom *"
                        value={data.name.value}
                        onChange={updateField('name')}
                        autoFocus
                        error={data.name.errors[0]}
                        noSubmit
                      />
                    </Ui.Layout.Row>
                    <Ui.Layout.BlockContainer>
                      <Ui.UploadFile.ImagesUpload
                        label="Illustration"
                        name="staff-picture"
                        id="staff-picture"
                        htmlFor="staff-picture"
                        multiple={false}
                        endpoint={`/${teleExpertiseName}s/picture/create`}
                        validationRules={["noLargeFiles", "noEmptyFiles", "acceptedImageFormat"]}
                        previewUri={`/${teleExpertiseName}s/picture`}
                        isEditMode
                      />
                    </Ui.Layout.BlockContainer>
                    <Ui.Layout.Row>
                      <Ui.Form.TextAreaInputResizable
                        htmlFor="Description"
                        label="Description"
                        value={data.description.value}
                        onChange={updateField('description')}
                        error={data.description.errors[0]}
                        noSubmit
                      />
                    </Ui.Layout.Row>
                    <Ui.Layout.BlockContainer>
                      <Tags
                        TeleExpertise={TeleExpertise}
                        teleExpertiseName={teleExpertiseName}
                        data={data}
                        type="tag"
                        label="mot-clé"
                        onAddItem={() => dispatch(TeleExpertise.addTag())}
                        onRemoveItem={idx => e => dispatch(TeleExpertise.removeTag({ idx }))}
                        onUpdateItemContent={(type, idx) => updateTagContent(type, idx)}
                        isCreationMode={true}
                      />
                    </Ui.Layout.BlockContainer>
                    <Ui.BasicElement.H3>
                      Disciplines
                    </Ui.BasicElement.H3>
                    <Ui.Layout.BlockContainer>
                      <Ui.SearchBar.SearchAndCheck
                        name="disciplines"
                        query={search.disciplines.value}
                        TeleExpertise={TeleExpertise}
                        teleExpertiseName={teleExpertiseName}
                        resultSet={disciplines}
                        list={data.disciplines.value}
                        loading={resourceLoading.disciplines}
                      />
                    </Ui.Layout.BlockContainer>
                    <Ui.BasicElement.H3>
                      URL du portail
                    </Ui.BasicElement.H3>
                    <Ui.BasicElement.P>Personnalisez puis partagez cette adresse internet pour que vos correspondants accèdent directement à votre téléexpertise.</Ui.BasicElement.P>
                    <Ui.BasicElement.P>
                      {staffPublicUrl}/
                      <Ui.Form.InlineInput
                        htmlFor="Identifiant"
                        placeholder="Identifiant"
                        value={data.publicUri.value.replace(/ +/g, '.')}
                        onChange={updateField('publicUri')}
                        error={data.publicUri.errors[0]}
                      />
                    </Ui.BasicElement.P>
                  </Section>
                </Ui.Layout.PaddedContent>
              </Ui.Layout.Content>
            </Ui.Layout.PageContentWrapper>
          </Ui.Layout.Form>
        </TeleExpertiseContainer>
      </Ui.Layout.PageLayout>
    </>
  )
}

const Section = styled.section`
  ${Ui.Layout.ButtonGroup} {
    > a:not(:last-child), > button:not(:last-child) {
    margin-right: .8rem;
    }
  };
  ${Ui.Layout.Row} {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    ${Ui.Form.InputContainer}:last-child {
      justify-content: flex-end;
    }
  }
    img[alt='Avatar'] {
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 50px;
      margin-left: .5rem;
  }
`

export const TeleExpertiseContainer = styled.div`
  h1 {
    color: ${props => props.theme.primary}
  }
  ${Ui.BasicElement.H2}:not(:first-of-type) {
    margin-top: 1rem;
  }
  ${Ui.Layout.Content} {
    @media only screen and (min-width: ${props => props.theme.device.desktop}) {
      & > :not(:first-child) {
        margin-top: 5rem;
      }
    }
    @media only screen and (max-width: ${props => props.theme.device.desktop}) {
      padding: 0;
    }
    ${Ui.Layout.ButtonGroup} {
      margin-top: .7rem;
    }
    ${Ui.Form.Input} {
      &:read-only {
        border-color: transparent;
        opacity: 1;
      }
    }
  }
  ${Ui.Form.Label} {
    ${Ui.SearchBar.SearchBarContainer} {
      margin-top: .6rem;
    }
  }
  ${Ui.BasicElement.H1Container} {
    margin-bottom: 1rem;
  }

  ${Ui.Button.Back} {
    flex: none;
  }
`

const IconH1 = styled(props => <Ui.Icon.IconRound {...props} />).attrs({ as: 'span' })`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.primary};
  border: 0;
  font-size: 1.3rem;
  margin-right: 1rem;
  flex-shrink: 0;
  background: ${props => props.theme.lightPurple};
  border-radius: 50px;
  width: 3rem;
  height: 3rem;
`

export default CreateStaffComponent;
