import React from 'react';
import styled from 'styled-components/macro'
import * as Ui from '../Ui'

const DisclaimerContentComponent = () => {

  return (
    <DisclaimerContentContainer>
      <Ui.BasicElement.P bold>PRESENTATION</Ui.BasicElement.P>
      <Ui.BasicElement.P>La plateforme SKEMEET est éditée par la société SAS SKEZI au Capital social de 10 225,00 € immatriculée au RCS de Annecy sous le numéro d’identification unique B 892 223 603 dont le siège social est au 1 Esplanade Augustin Aussedat, Cran Gevrier, 74960 Annecy.</Ui.BasicElement.P>
      <Ui.BasicElement.P><strong>Courriel :</strong> <a href="mailto:contact@skezi.eu">contact@skezi.eu</a></Ui.BasicElement.P>
      <Ui.BasicElement.P>Le directeur de la publication du site est BERTOCCHIO Jean-Philippe – <a href="mailto:jean-philippe.bertocchio@skezi.eu">jean-philippe.bertocchio@skezi.eu</a></Ui.BasicElement.P>
      <Ui.BasicElement.P>L’hébergeur du site est OVH Cloud – 2 rue Kellermann 59100 Roubaix 1007</Ui.BasicElement.P>
      <Ui.BasicElement.P bold>PROPRIETE INTELLECTUELLE</Ui.BasicElement.P>
      <Ui.BasicElement.P>La structure générale de la plateforme ainsi que les textes, les images et tous les éléments qui le composent sont la propriété exclusive de la société SKEZI.</Ui.BasicElement.P>
      <Ui.BasicElement.P>Toute représentation ou reproduction totale ou partielle de la plateforme ou des éléments qui la composent, sans autorisation expresse et préalable de la société SKEZI, est interdite.</Ui.BasicElement.P>
      <Ui.BasicElement.P bold>LIENS HYPERTEXTES</Ui.BasicElement.P>
      <Ui.BasicElement.P>Les liens hypertextes présentés sur la plateforme en direction de ressources extérieures ne sauraient engager la responsabilité de la société SKEZI.</Ui.BasicElement.P>
      <Ui.BasicElement.P>La création de liens hypertextes vers la plateforme ne peut se faire sans l’autorisation expresse et préalable de la société SKEZI.</Ui.BasicElement.P>
      <Ui.BasicElement.P bold>RESPONSABILITE</Ui.BasicElement.P>
      <Ui.BasicElement.P>La société SKEZI s’efforce de vérifier l’exactitude des informations mentionnées sur la plateforme et se réserve le droit de les mettre à jour, de les modifier ou de les supprimer à tout moment et sans préavis.</Ui.BasicElement.P>
      <Ui.BasicElement.P>La société SKEZI n’assure toutefois aucune garantie, expresse ou tacite, concernant le contenu de la plateforme.</Ui.BasicElement.P>
      <Ui.BasicElement.P>La société SKEZI pourra être amené à interrompre à tout moment l’accès à la plateforme pour des raisons techniques.</Ui.BasicElement.P>
      <Ui.BasicElement.P>La société SKEZI ne saurait voir sa responsabilité engagée au titre d’un quelconque dommage résultant directement ou indirectement de l’utilisation de la plateforme.</Ui.BasicElement.P>
      <Ui.BasicElement.P bold>CREDITS</Ui.BasicElement.P>
      <Ui.BasicElement.P>Conception et réalisation de la plateforme : SKEZI</Ui.BasicElement.P>
      <Ui.BasicElement.P>Identité graphique de la plateforme : KNP LABS</Ui.BasicElement.P>
    </DisclaimerContentContainer>
  )
}

const DisclaimerContentContainer = styled(props => <Ui.PortailLayout.PageContainer {...props} />)`

  @media only screen and (min-width: ${ props => props.theme.device.desktop}) {
    margin-right: 4rem;
  }

  a {
    color: ${props => props.theme.patientGreen};
  }

  ${Ui.BasicElement.H1} {
    margin-top: 3rem;
  }

  ${Ui.BasicElement.H2} {
    margin-top: 2rem;
    font-size: 1rem;
  }

  ${Ui.BasicElement.P} {
    margin: .5rem 0;
  }

  ${Ui.BasicElement.Link} {
    font-size: .875rem;
    font-weight: 700;
  }

`

export default DisclaimerContentComponent;
