import React, { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useAlert } from 'react-alert'
import * as Ui from '../Ui'
import styled from 'styled-components/macro'
import * as Questionnaire from '../../State/Questionnaire'
import * as CustomField from '../../State/CustomField'
import Toggle from './Toggle'
import { fr } from '../../Util/i18n';
import pluralize from '../../Util/Pluralize'
import Editable from '../../Util/Editable'

const QuestionnaireBuilderComponent = ({
  teleExpertiseId,
  data,
  permissions = {
    _canBe: {
      edited: false
    },
  },
  form = {},
  canEdit,
}) => {
  const dispatch = useDispatch()
  const alert = useAlert()
  const isEditMode = useSelector(Questionnaire.selectBuilderEditMode)
  const sending = useSelector(Questionnaire.selectSending)
  const otherStepSections = idx =>
    data.filter((_, i) => Number(idx) !== Number(i))
      .map(({ sections }) => sections.value.map(String))
      .reduce((acc, val) => acc.concat(val), [])

  const updateField = (idx, name, value) => e =>
    dispatch(Questionnaire.updateField({ idx, name, value: value || e.target.checked || e.target.value }))

  const setEditField = (idx, fieldName, value) =>
    dispatch(Questionnaire.setEditField({ idx, fieldName, value }))

  const onEnterField = (idx, fieldName) => () => {
    return canEdit
      ? setEditField(idx, fieldName, true)
      : null
  }

  const onLeaveField = (idx, fieldName, stepId) => e => {
    if (data[idx][fieldName].isFieldEdit && !e.currentTarget.contains(e.relatedTarget)) {
      if (stepId.value) {
        if (data[idx][fieldName].value !== data[idx][fieldName].initialValue)
          dispatch(Questionnaire.saveField({ idx, cohortId: teleExpertiseId, fieldName, stepId }))
        else
          dispatch(Questionnaire.setEditField({ idx, fieldName, value: false }))
      } else {
        dispatch(Questionnaire.send({ idx }))
      }
    }
  }

  const onRestoreValue = (idx, fieldName, value) => (e) => {
    dispatch(Questionnaire.restoreInitialValue({ idx, fieldName, value }))
    if (!data[idx][fieldName].errors.length > 0)
      setEditField(idx, fieldName, false)
  }

  const onEnterFieldsBlock = (idx, blockName) => () => {
    return permissions?._canBeModified?.default
      ? dispatch(Questionnaire.setEditMode({ idx, blockName, value: true }))
      : null
  }

  const onLeaveFieldsBlock = (idx, blockName, stepId, doSaveField = false) => e => {
    if (isEditMode[idx][blockName] && !e.currentTarget.contains(e.relatedTarget)) {
      dispatch(Questionnaire.setEditMode({ idx, blockName, value: false }))

      if (doSaveField)
        dispatch(Questionnaire.saveField({ idx, cohortId: teleExpertiseId, fieldName: blockName, stepId }))
    }
  }

  const updatePatientDateField = ({ idx, name }) => e =>
    dispatch(Questionnaire.updateField({ idx, name, value: e.target.checked }))

  const checkSection = ({ idx, name, value, isChecked, stepId }) => e => {
    if (isChecked) {
      dispatch(Questionnaire.uncheckSection({ idx, name, value: value || e.target.value }))
    } else {
      dispatch(Questionnaire.checkSection({ idx, name, value: value || e.target.value }))
    }

    dispatch(Questionnaire.saveField({ idx, cohortId: teleExpertiseId, fieldName: 'sections', stepId }))
  }

  const onRemoveStep = (e, idx, stepId, cohortId) => {
    if (stepId.value) {
      alert.show(`Attention, la suppression de l'étape effacera toutes les données qu'elle contient.
      Confirmez-vous la suppression de cette étape ?`, {
        onConfirm: (e) => dispatch(Questionnaire.removeStep({ idx, stepId, cohortId }))
      })
    } else {
      dispatch(Questionnaire.removeStep({ idx, stepId, cohortId }))
    }
  }

  const onUpdateVisio = (idx, stepId) => e => {
    dispatch(Questionnaire.updateField({ idx, name: 'activeVisioconferencing', value: e }))
    dispatch(Questionnaire.saveField({ idx, cohortId: teleExpertiseId, fieldName: 'activeVisioconferencing', stepId }))
  }

  const sectionsDatas = useSelector(CustomField.selectSections)

  const onDocumentRemove = ({ id }) =>
    dispatch(Questionnaire.removeDocument({ cohortId: form.id, assetId: id }))

  const sections = Object
    .values(sectionsDatas.byId)
    .reduce((acc, { id, label }) => ({ ...acc, [id]: label }), {})

  const descriptionRef = useRef();
  const dateRef = useRef();
  const refs = useRef();

  return (
    <Ui.Layout.Section>
      {!canEdit &&
        <Ui.BasicElement.P>Vous n'avez pas les droits nécessaires pour programmer des étapes</Ui.BasicElement.P>
      }
      {data
        .map((step, idx) =>
          <StepContainer key={idx}>
            <Ui.Layout.FlexBox>
              <Ui.Layout.Row>
                <Editable
                  title="Titre *"
                  name="title"
                  text={step.title.value}
                  isEditMode={step.title.isFieldEdit}
                  onClick={onEnterField(idx, 'title')}
                  onBlur={onLeaveField(idx, 'title', step.id)}
                  childRef={refs}
                  canEdit={canEdit}
                >
                  <Ui.Form.TextInput
                    name="title"
                    label="Titre *"
                    value={step.title.value}
                    onChange={updateField(idx, 'title')}
                    onBlur={onLeaveField(idx, 'title', step.id)}
                    onRestoreValue={onRestoreValue(idx, 'title', step.title.initialValue)}
                    error={step.title.errors[0]}
                    inputRef={refs}
                    tabIndex={idx + 10000}
                  />
                </Editable>
              </Ui.Layout.Row>

              {canEdit &&
                <Ui.Layout.ActionButtonsContainer>
                  <Ui.Button.ActionButton
                    type="button"
                    disabled={sending}
                    onClick={(e) => onRemoveStep(e, idx, step.id, form.id)}
                  >
                    <Ui.Icon.IconTrash />
                  </Ui.Button.ActionButton>
                </Ui.Layout.ActionButtonsContainer>
              }
            </Ui.Layout.FlexBox>

            {step.id.value &&
              <>
                <Ui.Layout.BlockContainer>
                  <Editable
                    title="Description"
                    text={step.description.value}
                    isEditMode={step.description.isFieldEdit}
                    onClick={onEnterField(idx, 'description')}
                    onBlur={onLeaveField(idx, 'description', step.id)}
                    childRef={descriptionRef}
                    altContent={canEdit
                      ? 'Ajouter une description'
                      : 'Aucune desscription n\'a été définie'}
                    canEdit={canEdit}
                  >
                    <Ui.Form.TextAreaInputResizable
                      name="description"
                      label="Description"
                      value={step.description.value}
                      onChange={updateField(idx, 'description')}
                      onBlur={onLeaveField(idx, 'description', step.id)}
                      onRestoreValue={onRestoreValue(idx, 'description', step.description.initialValue)}
                      error={step.description.errors[0]}
                      inputRef={descriptionRef}
                    />
                  </Editable>
                </Ui.Layout.BlockContainer>
                <Ui.Layout.BlockContainer>
                  <Ui.BasicElement.H3>Visioconférence</Ui.BasicElement.H3>
                  <Toggle
                    id={`visio_${idx}`}
                    name={`visio_${idx}`}
                    checked={step.activeVisioconferencing.value}
                    onChange={onUpdateVisio(idx, step.id)}
                    disabled={!canEdit}
                  />
                </Ui.Layout.BlockContainer>
                <Ui.Layout.BlockContainer>
                  <Editable
                    title="Date"
                    content={<>
                      <Ui.Layout.FlexBox italic={!step.periodQuantity.value && !step.period.value && !step.date.value}>
                        {step.manualProgrammingDate.value
                          ? 'La date de cette étape sera déterminée directement dans la fiche patient'
                          : step.periodQuantity.value > 0
                            ? `${step.periodQuantity.value} ${pluralize(`${fr.period[step.period.value].toLowerCase()}`, step.periodQuantity.value)} après l'inclusion du patient`
                            : (step.periodQuantity.value === 0 || step.periodQuantity.value === '0')
                              ? 'Le jour de l\'inclusion du patient'
                              : 'Aucune date n\'a été définie'
                        }
                      </Ui.Layout.FlexBox>
                    </>}
                    isEditMode={isEditMode[idx].date}
                    onClick={onEnterFieldsBlock(idx, 'date')}
                    onBlur={onLeaveFieldsBlock(idx, 'date', step.id, true)}
                    childRef={dateRef}
                    canEdit={canEdit}
                  >
                    <Ui.Layout.FocusDiv ref={dateRef} tabIndex={idx + 5000} >
                      <Ui.BasicElement.H3>Date</Ui.BasicElement.H3>
                      <>
                        <PatientStepDateContainer>
                          <div>
                            <Ui.Select.Select
                              htmlFor="durée"
                              isFilled
                              items={
                                [{ label: '\u00A0', value: null },
                                ...[...Array(40).keys()]
                                  .map((_, idx) => ({
                                    label: idx,
                                    value: idx,
                                    selected: step.periodQuantity.value !== '' && Number(step.periodQuantity.value) === idx
                                  }))
                                ]}
                              onChange={updateField(idx, 'periodQuantity')}
                              disabled={step.manualProgrammingDate.value}
                            />
                          </div>
                          <div>
                            <Ui.Select.Select
                              htmlFor="période"
                              isFilled
                              items={['days', 'weeks', 'months', 'years']
                                .map(item => ({
                                  label: fr.period[item],
                                  value: item,
                                  selected: item === step.period.value,
                                }))
                              }
                              onChange={updateField(idx, 'period')}
                              disabled={step.manualProgrammingDate.value}
                            />
                          </div>
                          <div> après l'inclusion du patient</div>
                        </PatientStepDateContainer>
                        <PatientDateRow alignItems="center">
                          <Ui.Form.Checkbox
                            checked={step.manualProgrammingDate.value}
                            onChange={updatePatientDateField({ idx, name: 'manualProgrammingDate' })}
                          /><span>La date de cette étape sera déterminée directement dans la fiche patient</span>
                        </PatientDateRow>
                      </>
                    </Ui.Layout.FocusDiv>
                  </Editable>
                </Ui.Layout.BlockContainer>
                <Ui.Layout.BlockContainer>
                  <Ui.BasicElement.H3>
                    Documents
                  </Ui.BasicElement.H3>
                  <Ui.UploadFile.DocumentsUpload
                    name={`patient-step-upload_${idx}`}
                    validationRules={["noLargeFiles", "noEmptyFiles", "acceptedDocumentFormat"]}
                    label=""
                    endpoint={`/cohorts/${teleExpertiseId}/addAssetPatientStep/${step.id.value}`}
                    apiPath="/cohorts/assetPatientStep/"
                    value={step.documents.value}
                    onRemove={onDocumentRemove}
                    isEditMode={true}
                    disabled={!canEdit}
                  />
                </Ui.Layout.BlockContainer>
                <Ui.Layout.BlockContainer>
                  <Ui.BasicElement.H3>
                    Questionnaire(s)
                  </Ui.BasicElement.H3>
                  {canEdit &&
                    <Ui.BasicElement.P>Vous pouvez associer à cette étape des sections créées depuis la Fiche patient.</Ui.BasicElement.P>
                  }
                  {Object
                    .entries(sections)
                    .filter(([id, label]) => label)
                    .map(([id, label]) =>
                      <div
                        key={`dropdown_${id}`}
                        disabled={otherStepSections(idx).includes(String(id))}
                      >
                        <Ui.Form.CheckboxDropdown
                          lineHeight="none"
                          icon={sectionsDatas.byId[id].isPatientSection
                            ? <Ui.Icon.IconPatientCircle disabled={otherStepSections(idx).includes(String(id))} />
                            : <Ui.Icon.IconDoctorCircle disabled={otherStepSections(idx).includes(String(id))} />
                          }
                          name={`dropdown_${id}`}
                          label={label}
                          value={id}
                          onChange={checkSection({
                            idx,
                            name: 'section',
                            value: id,
                            isChecked: step.sections.value.map(String).includes(String(id)),
                            stepId: step.id,
                          })}
                          title={otherStepSections(idx).includes(String(id))
                            ? 'Vous ne pouvez pas lier ce questionnaire car il est déjà associé à une autre étape'
                            : null
                          }
                          checked={step.sections.value.map(String).includes(String(id)) || otherStepSections(idx).includes(String(id))}
                          disabled={otherStepSections(idx).includes(String(id)) || !canEdit}
                        />
                      </div>
                    )
                  }
                </Ui.Layout.BlockContainer>
              </>
            }
          </StepContainer>
        )
      }
      <Ui.Layout.BlockContainer>
        {canEdit &&
          <Ui.Button.FormButton
            type="button"
            onClick={(e) => dispatch(Questionnaire.addStep())}
          >
            <Ui.Icon.IconDocument />
            Ajouter une étape
          </Ui.Button.FormButton>
        }

      </Ui.Layout.BlockContainer>
    </Ui.Layout.Section >
  )
}

const PatientDateRow = styled(props => <Ui.Layout.FlexBox {...props} />)`
  font-size: .875rem;
  margin: 1rem 0;
  span {
    margin-left: .5rem;
  }
`

const SelectSection = styled.div`
  ${Ui.Select.SelectList} {
    max-height: 10rem;
    width: 20rem;
    overflow: scroll;
  }
`

const StepContainer = styled.div`
  ${Ui.Form.CheckboxDropdownContainer} {
    margin: 0;
  }
  ${Ui.Layout.Row} {
    margin-bottom: 0;
  }
  ${Ui.Form.CheckboxDropdownCheck} {
    margin: 0;
  }
  ${Ui.Form.DropdownItemLabel} {
    max-width: none;
  }
  ${Ui.Form.Dropdown} {
    & > div:first-child {
      font-weight: normal;
    }
  }
  ${Ui.Form.CheckboxInput} {
    &:disabled + i::after {
      content: '';
    }
  }
  display: flex;
  border-radius: 5px;
  flex-direction: column;
  background: white;
  padding: 1.5rem;
  margin-bottom: 2.5rem;
  box-shadow: ${props => props.theme.boxShadow.thin};
  ${Ui.Layout.Row} {
    justify-content: flex-start !important;
    align-items: flex-end !important;
    ${Ui.Select.SelectContainer} {
      width: 7.5rem;
      margin-top: 1rem;
      flex: initial;
      ${Ui.Select.SelectList} {
        max-height: 16rem;
        overflow: scroll;
      }
    }
    @media only screen and (min-width: ${props => props.theme.device.desktop}) {
      > div {
        flex: initial !important;
        &:nth-child(2), &:last-child {
          align-self: flex-end;
        }
        &:last-child {
          align-self: flex-end;
          margin-bottom: .8rem;
        }
      }
    }
  }
  & > ${Ui.Select.SelectContainer} {
    width: 20rem;
  }
  ${Ui.Layout.ActionButtonsContainer} {
    margin-left: 1.5rem;
  }
  ${Ui.Form.Label} {
    &:disabled {
      font-weight: 700;
    }
  };
  ${Ui.Form.Input} {
    &:disabled {
      border-bottom: 1px dotted;
      border-color: ${props => props.theme.grey2};
    }
  };
  ${Ui.Stepper.StepLabel} {
    a {
      color: blue//${props => props.theme.darkBlue};
    }
  }
  &:last-of-type {
    ${SelectSection} {
      ${Ui.Select.SelectList} {
        max-height: 10rem;
        overflow: scroll;
        top: auto;
        bottom: -2px;
      }
    }
  }
`

const PatientStepDateContainer = styled(props => <Ui.Layout.Row {...props} />)`
  font-size: .875rem;
`

export default QuestionnaireBuilderComponent;
