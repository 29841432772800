import { createSlice } from '@reduxjs/toolkit'

export const INITIAL_STATE = {
  data: {
    comment: { value: '', errors: [], validationRules: ['notEmpty'] },
    assets: { value: [], errors: [], validationRules: [] },
    comments: { value: {}, initialValue: {}, isFieldEdit: {}, errors: [], validationRules: [] },
  },
  fileData: {
    size: { value: null, errors: [], validationRules: ['noLargeFile', 'noEmptyFile'] },
    type: { value: '', errors: [], validationRules: ['acceptedDocumentFormat'] },
    name: { value: { fileName: '', name: '' }, errors: [], validationRules: [] },
  },
  assetsLoading: false,
  comments: [],
  loading: false,
  sending: false,
  pending: false,
  error: false,
  success: false,
  errorMessage: null,
  commentBoxOpen: false,
  editedComments: [],
}

const slice = createSlice({
  name: 'comment',
  initialState: INITIAL_STATE,
  reducers: {

    send: (state) => ({
      ...state,
      sending: true,
      error: false,
      success: false,
      data: Object
        .entries(state.data)
        .reduce((acc, [name, field]) => ({
          ...acc,
          [name]: { ...field, errors: [] },
        }), state.data),
    }),

    received: (state, { payload }) => {
      const comments =
        payload.comments.map(comment => ({
          ...comment,
          creationDate: comment.createdAt.split(' ')[0],
          creationHour: comment.createdAt.split(' ')[1],
        }));

      return ({
        ...state,
        data: {
          ...state.data,
          comments: {
            ...state.data.comments,
            value: comments
              .reduce((acc, { id, content }) =>
                ({ ...acc, [id]: content })
                , {}),
            initialValue: comments
              .reduce((acc, { id, content }) =>
                ({ ...acc, [id]: content })
                , {}),
            isFieldEdit: comments
              .reduce((acc, { id }) =>
                ({ ...acc, [id]: false })
                , {}),
          }
        },
        comments,
        sending: false
      })
    },

    deleteComment: (state, { payload: commentId }) => ({
      ...state,
      comments: state.comments.filter(comment => String(comment.id) !== String(commentId)),
      data: {
        ...state.data,
        comments: {
          ...state.data.comments,
          value: Object.keys(state.data.comments.value)
            .reduce((object, key) => {
              if (Number(key) !== Number(commentId)) {
                object[key] = state.data.comments.value[key]
              }
              return object
            }, {}),
          initialValue: Object.keys(state.data.comments.initialValue)
            .reduce((object, key) => {
              if (Number(key) !== Number(commentId)) {
                object[key] = state.data.comments.value[key]
              }
              return object
            }, {}),
          isFieldEdit: Object.keys(state.data.comments.initialValue)
            .reduce((object, key) => {
              if (Number(key) !== Number(commentId)) {
                object[key] = false
              }
              return object
            }, {}),
        }
      }
    }),

    success: (state) => ({
      ...state,
      data: {
        ...state.data,
        comment: {
          ...state.data.comment,
          value: ''
        }
      },
      sending: false,
      error: false,
      success: true,
    }),

    clearCommentBox: (state) => ({
      ...state,
      data: {
        ...state.data,
        comment: {
          ...state.data.comment,
          value: ''
        }
      },
    }),

    updateField: (state, { payload: { name, value } }) => ({
      ...state,
      data: {
        ...state.data,
        [name]: {
          ...state.data[name],
          value: value
        },
      },
    }),

    updateFieldById: (state, { payload: { value, id } }) => ({
      ...state,
      data: {
        ...state.data,
        comments: {
          ...state.data.comments,
          value: {
            ...state.data.comments.value,
            [id]: value,
          },

        }
      }
    }),

    updateInitialValue: (state, { payload: { commentId, value, error = '' } }) => ({
      ...state,
      data: {
        ...state.data,
        comments: {
          ...state.data.comments,
          initialValue: {
            ...state.data.comments.initialValue,
            [commentId]: value
          }
        },
      },
      comments:
        state.comments
          .map(comment => String(comment.id) === String(commentId)
            ? { ...comment, content: value }
            : comment
          )
    }),

    saveCommentById: (state, { payload: { comment: content, id: commentId } }) => ({
      ...state,
      comments:
        state.comments
          .map(comment => comment.id === commentId
            ? { ...comment, content: content }
            : comment
          )
    }),

    fetch: (state, { payload: { patientId, teleExpertiseSessionId } }) => ({
      ...state,
      patientId,
      loading: true
    }),

    invalidate: (state, action) => ({
      ...state,
      data: action.payload,
      sending: false,
      error: true,
      success: false,
    }),

    apiError: (state, action) => ({
      ...state,
      errorMessage: action.payload,
      sending: false,
      error: true,
      success: false,
    }),

    setCommentBoxOpen: (state, { payload }) => ({
      ...state,
      commentBoxOpen: payload
    }),

    setEditComment: (state, { payload: { commentId, isEditing } }) => ({
      ...state,
      data: {
        ...state.data,
        comments: {
          ...state.data.comments,
          isFieldEdit: {
            ...state.data.comments.isFieldEdit,
            [commentId]: isEditing
          }
        }
      }
    }),

    clean: state => ({
      ...state,
      success: false
    }),
    restoreInitialValue: (state, { payload: { commentId, value } }) => ({
      ...state,
      data: {
        ...state.data,
        comments: {
          ...state.data.comments,
          value: {
            ...state.data.comments.value,
            [commentId]: value
          }
        }
      }
    })

  }
})

export const {
  send,
  updateField,
  updateFieldById,
  received,
  success,
  fetch,
  apiError,
  invalidate,
  setCommentBoxOpen,
  deleteComment,
  saveCommentById,
  setEditComment,
  clearCommentBox,
  restoreInitialValue,
  updateInitialValue,
  clean,
} = slice.actions

export const selectData = state => state.comment.data

export default slice.reducer
