import * as Registration from '../State/Registration'
import * as Resources from '../State/Resources'
import * as Form from '../Util/Form'
import * as Api from '../Util/Api'
import { takeLatest, call, put, select, delay, cancelled } from 'redux-saga/effects'
import { debounceTime } from '../Util/Date'

export function* fetchAllRppsRegistration() {
  try {
    const allRppsRegistration = yield call(Api.fetchAllRppsRegistration)

    yield put(Resources.received({
      name: [Resources.RESOURCE_REGISTERED_RPPS],
      data: allRppsRegistration,
    }))
  } catch (error) {
    yield put(Registration.apiError(error.message))
  }
}

export function* updateRppsQuery({ payload: { query } }) {
  const abortController = new AbortController();
  try {
    if (query) {
      const delayMs = debounceTime(query)
      yield delay(delayMs)
      const rppsDatas = yield call(Api.fetchRppsDatas, query, { signal: abortController.signal })
      yield put(Registration.receivedRppsDatas(rppsDatas))
    }
  } catch (error) {
    yield put(Registration.apiError(error.message))
  } finally {
    if (yield cancelled()) {
      abortController.abort();
    }
  }
}

export function* validateDiscipline() {
  try {
    const { disciplines } = yield select(Resources.selectResources)
    const { discipline } = yield select(Registration.selectData)
    if (discipline.value && !disciplines.includes(discipline.value)) {
      throw new Form.SpecialityValidationError({
        discipline: {
          value: discipline.value,
          errors: ['Aucune correspondance trouvée pour cette discipline'],
        }
      })
    }
  } catch (error) {
    // eslint-disable-next-line no-unused-vars
    for (let [_, field] of Object.entries(error.data)) {
      yield put(Registration.updateField({
        name: 'discipline',
        value: field.value,
        errors: field.errors,
      }))
    }
  }
}

export function* validatedPasswordConfirmation() {
  try {
    const data = yield select(Registration.selectData)
    yield call(Form.validatedPasswordConfirmation, data, 'plainPassword', 'plainPasswordConfirmation')
  } catch (error) {
    yield put(Registration.invalidate(error.data))
  }
}

export function* validateRpps() {
  try {
    const { rpps: rppsList } = yield select(Resources.selectResources)
    const { rpps } = yield select(Registration.selectData)
    if (rpps.value && rppsList.includes(rpps.value)) {
      throw new Form.SpecialityValidationError({
        rpps: {
          value: rpps.value,
          errors: ['Cet numéro RPPS est déjà enregistré'],
        }
      })
    }
  } catch (error) {
    // eslint-disable-next-line no-unused-vars
    for (let [_, field] of Object.entries(error.data)) {
      yield put(Registration.updateField({
        name: 'rpps',
        value: field.value,
        errors: field.errors,
      }))
    }
  }
}

export function* send({ payload: registrationCode }) {
  try {
    yield validateDiscipline()
    yield validatedPasswordConfirmation()
    yield validateRpps()
    const data = yield select(Registration.selectData)
    const validatedData = yield call(Form.validate, data)
    const apiData = Object
      .entries(validatedData)
      .reduce((acc, [name, { value }]) => ({
        ...acc,
        [name]: name === 'cellPhone'
          ? value.replace(/\s/g, '')
          : value
      }), {})
    yield call(Api.register, { ...apiData, registrationCode })
    yield put(Registration.success())
  } catch (error) {
    if (error instanceof Form.ValidationError) {
      yield put(Registration.invalidate(error.data))
    }
    else {
      yield put(Registration.apiError(error.message))
    }
  };
}

export function* liveCheck({ payload: { name } }) {
  try {
    const data = yield select(Registration.selectData)
    yield call(Form.validate, data)
  } catch (error) {
    if (error instanceof Form.ValidationError) {
      yield put(Registration.invalidateField({ data: error.data, field: name }))
    }
  }
}

const RegistrationSagas = function* () {
  yield takeLatest(`${Registration.fetchAllRppsRegistration}`, fetchAllRppsRegistration)
  yield takeLatest(`${Registration.updateRppsQuery}`, updateRppsQuery)
  yield takeLatest(`${Registration.validateDiscipline}`, validateDiscipline);
  yield takeLatest(`${Registration.validateRpps}`, validateRpps);
  yield takeLatest(`${Registration.send}`, send);
  yield takeLatest(`${Registration.liveCheck}`, liveCheck);
};

export default RegistrationSagas;
