import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import styled from 'styled-components/macro'
import { JitsiMeeting } from '@jitsi/react-sdk';
import { privateSitePrefix } from '../Util/Config'
import * as Ui from './Ui'
import * as Login from '../State/Login';

const TestRouteComponent = () => {
  const history = useHistory()

  const currentUser = useSelector(Login.selectUser)

  if (
    !currentUser.email.includes('@skezi.eu') &&
    currentUser.role !== 'ROLE_SUPER_ADMIN'
  ) {
    history.push(`${privateSitePrefix}/patients`)
  }

  return (
  <>
    <Ui.Layout.PageLayout currentUser={currentUser}>
      <TestContainer>
          <Ui.Layout.PageContentWrapper>
            <Ui.Layout.PanelContainer>
              <Ui.Layout.Panel>
                <Ui.BasicElement.P light>
                  Page résérvé aux tests de l'équipe Skezi
                </Ui.BasicElement.P>
              </Ui.Layout.Panel>
            </Ui.Layout.PanelContainer>
            <Ui.Layout.Content hasPanel>
              <JitsiMeeting
                domain = {'meet.skezi.eu'}
                roomName = 'Test'
                userInfo = {{
                  displayName: currentUser.username
                }}
                getIFrameRef = { (iframeRef) => { iframeRef.style.height = '80%'; } }
              />
            </Ui.Layout.Content>
          </Ui.Layout.PageContentWrapper>
      </TestContainer>
    </Ui.Layout.PageLayout>
  </>
  )
}

const TestContainer = styled.div`
  @media only screen and (min-width: ${props => props.theme.device.desktop}) {
    ${Ui.Layout.PanelFilterContainer} {
      padding: .6rem 1.3rem;
    }
  }
`

export default TestRouteComponent;
