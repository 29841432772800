import styled from 'styled-components/macro'
import * as Ui from '../Component/Ui'

export const ColleagueListContainer = styled.div`
  ${Ui.Form.InputContainer} {
    margin-top: 1.8rem;
    margin-bottom: .15rem;
  };
  ${Ui.SearchBar.SearchBarContainer} {
    margin-bottom: 0;
    width: 100%;
  }
  ${Ui.Search.FiltersAndSorting}:first-child {
    margin-bottom: 1rem;
  }
  a {
    flex: 0;
    text-decoration: none;
  }
  ${Ui.Layout.ContentPanel} {
    ${Ui.Layout.ActionButtonsContainerFixed} {
      top: 4.5rem;
      right: 1.45rem;
      color: ${props => props.theme.grey};
    }
    ${Ui.Icon.IconClose} {
      font-size: 1.5rem;
      cursor: pointer;
    }
    ${Ui.Layout.ButtonGroup} {
      margin-top: 1rem;
    }
  }
  ${Ui.Colleague.ColleagueContainer} {
    min-height: 7.25rem;
  }
`

export const Recipient = styled(props => <Ui.Form.CheckboxLarge {...props} />)`
  ${Ui.Form.CheckboxLargeContainer} {
    ${Ui.Form.Labels} {
      ${Ui.Form.DropdownItemLabel} {
        text-transform: lowercase;
      }
    }
  }
`
