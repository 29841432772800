import React, { useEffect, useState } from 'react';
import { privateSitePrefix } from '../Util/Config'
import { apiUrl } from '../Util/Config'
import { Redirect, useHistory, Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { useAlert } from 'react-alert'
import { mimeTypeIcon } from '../Util/MimeTypeIcons'
import * as Menu from '../State/Menu'
import * as Message from '../State/Message'
import * as MessageList from '../State/MessageList'
import * as Resources from '../State/Resources'
import * as Ui from './Ui'
import * as Styled from './Message.styled'
import LoadingBox from './Ui/LoadingBox'

const MessageComponent = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const alert = useAlert()
  const currentUser = useSelector(state => state.login.currentUser)
  const success = useSelector(Message.selectSuccess)
  const loading = useSelector(Message.selectLoading)
  const errorMessage = useSelector(state => state.message.errorMessage)
  const resourceLoading = useSelector(Resources.selectLoading)

  const {
    search,
    data: {
      content,
      subject,
      replyContent,
      patients,
      members,
      messages,
    },
  } = useSelector(state => state.message)
  const { match: { params } } = props

  const {
    [Resources.RESOURCE_PATIENTS]: patientsResource,
  } = useSelector(Resources.selectResources)

  useEffect(() => {
    dispatch(Message.fetch(params.id))
  }, [dispatch, params.id])

  useEffect(() => {
    dispatch(MessageList.fetchMessagesNum())
  }, [dispatch]);

  useEffect(() => {
    if (errorMessage)
      alert.error(errorMessage, { timeout: 5000 })
  }, [alert, errorMessage])

  useEffect(() => {
    dispatch(Menu.changeActive(null))

    return () => {
      dispatch(Message.clean())
    }
  }, [dispatch])

  const updateField = name => e => {
    dispatch(Message.updateField({ name, value: e.target.value }))
  }

  const reply = (e) => {
    e.preventDefault()
    dispatch(Message.reply({ messageId: params.id }))
  }

  const [attachmentBoxOpen, setAttachmentBoxOpen] = useState(false)

  if (success) {
    alert.success("Votre message a bien été envoyé.", { timeout: 5000 })
    return <Redirect to={`${privateSitePrefix}/messages`} />
  }

  const Attachments = ({ assets }) => assets.length > 0 &&
    <Ui.UploadFile.PreviewContainer>
      {assets.map(({ fileName, name, size, mimeType, title, messageId }, idx) =>
        <Ui.DocumentPreview.DocumentPreview
          key={`document_${idx}`}
          name={name}
          mimeType={mimeType}
          apiPath={`/messages/assets/`}
          size={size}
          fileName={fileName}
          title={title}
          iconSrc={mimeTypeIcon(mimeType)}
          showSeeAndDownloadButtons={messageId ? true : false}
        >
        </Ui.DocumentPreview.DocumentPreview>
      )}
    </Ui.UploadFile.PreviewContainer>

  return (
    <>
      <LoadingBox />
      <Ui.Layout.PageLayout currentUser={currentUser}>
        <Styled.MessageContainer>
          {!loading
            ? <Ui.Layout.PageContentWrapper>
              <Ui.Layout.PanelContainer>
                <Ui.Layout.Panel>
                  <Ui.Button.BackButton onClick={history.goBack} />
                  <Ui.Layout.TelexpertiseThumbnailContainer>
                    <Ui.Layout.TelexpertiseLogo>
                      <Ui.Icon.IconChat />
                    </Ui.Layout.TelexpertiseLogo>
                    <Ui.BasicElement.H3 noMargin>Mes messages</Ui.BasicElement.H3>
                  </Ui.Layout.TelexpertiseThumbnailContainer>
                </Ui.Layout.Panel>
              </Ui.Layout.PanelContainer>
              <Ui.Layout.Content hasPanel>
                <Ui.Search.FilterContainer>
                  <Ui.Search.FiltersAndSorting>
                    <div>
                      <Ui.Button.BasicButton
                        isFilled
                        isGreen
                        isLarge
                        as={Link}
                        to={`${privateSitePrefix}/nouveau/message`}
                      >
                        Nouveau message
                      </Ui.Button.BasicButton>
                    </div>
                  </Ui.Search.FiltersAndSorting>
                </Ui.Search.FilterContainer>
                <Ui.Card.Container>
                  <Ui.Search.MobileActionButtonGroup>
                    <Ui.Button.BasicButton
                      isFilled
                      isGreen
                      isLarge
                      as={Link}
                      to={`${privateSitePrefix}/nouveau/message`}
                    >
                      Nouveau message
                    </Ui.Button.BasicButton>
                  </Ui.Search.MobileActionButtonGroup>
                  <Styled.MessageSection>
                    <Ui.Message.Container>
                      <Ui.BasicElement.P bold>
                        Participants :
                      </Ui.BasicElement.P>
                      <Ui.Layout.FlexBox flow="row wrap">
                        {members.value
                          .map((member, index) =>
                            <Ui.Form.ButtonLarge
                              key={index}
                              value={member.name}
                              label={`${member.firstname} ${member.lastname}`}
                              subLabel={member.speciality ? `${member.speciality}` : ''}
                            >
                              <Ui.Colleague.Avatar value={member}>
                              </Ui.Colleague.Avatar>
                            </Ui.Form.ButtonLarge>
                          )}
                      </Ui.Layout.FlexBox>
                      <Ui.Message.Body>
                        <Styled.MessageSubject>
                          <Ui.BasicElement.P bold>
                            Objet du message
                          </Ui.BasicElement.P>
                          <Ui.BasicElement.P>
                            {subject.value}
                          </Ui.BasicElement.P>
                        </Styled.MessageSubject>
                        {content.value &&
                          <Ui.BasicElement.P bold>
                            Corps du message
                          </Ui.BasicElement.P>
                        }
                        <Ui.BasicElement.P>
                          {content.value}
                        </Ui.BasicElement.P>

                        {messages
                          .value
                          .map((message, idx) =>
                            <Ui.Message.Container key={`message_replies_${idx}`} on="">
                              <Ui.Message.Header>
                                <div>
                                  <Ui.Message.Avatar src={message.author.profilePicture ? `${apiUrl}/users/profile-picture/${message.author.profilePicture}` : '/Avatar_default.png'} />
                                  <Ui.Message.Title>
                                    <Ui.BasicElement.P bold dark>
                                      {message.author.title} {message.author.firstname} {message.author.lastname}
                                    </Ui.BasicElement.P>
                                    <Ui.BasicElement.P>Le {message.createdAt}</Ui.BasicElement.P>
                                  </Ui.Message.Title>
                                </div>
                              </Ui.Message.Header>
                              <Ui.Message.Body>
                                <Ui.BasicElement.P bold>
                                </Ui.BasicElement.P>
                                <Ui.BasicElement.P>
                                  {message.content}
                                </Ui.BasicElement.P>
                                <Attachments assets={message.assets} />
                                <Ui.Layout.FlexBox flow="row wrap">
                                  {message.patients.map((patient, idx) =>
                                    <Styled.ButtonLargeLink
                                      key={idx}
                                      to={`${privateSitePrefix}/patients/${patient.id}`}
                                    >
                                      <Ui.Form.ButtonLarge
                                        value={patient.fullName}
                                        label={patient.fullName}
                                      >
                                        <Ui.Colleague.DefaultAvatar />
                                      </Ui.Form.ButtonLarge>
                                    </Styled.ButtonLargeLink>
                                  )}
                                </Ui.Layout.FlexBox>
                              </Ui.Message.Body>
                            </Ui.Message.Container>
                          )}
                      </Ui.Message.Body>
                    </Ui.Message.Container>
                  </Styled.MessageSection>
                </Ui.Card.Container>

                <Ui.Layout.Form onSubmit={reply} noValidate>
                  <Ui.Form.InputContainer>
                    <Ui.Form.TextAreaInput
                      htmlFor="message"
                      label="Votre message *"
                      value={replyContent.value}
                      onChange={updateField('replyContent')}
                      error={replyContent.errors[0]}
                      noSubmit
                    />
                    <Ui.Form.InputContainer>
                      <Ui.Form.Label htmlFor="patients">
                        Patients
                        <Ui.SearchBar.SearchAndCheck
                          name="patients"
                          query={search.patients.value}
                          TeleExpertise={Message}
                          resultToLabel={patient => patient.fullName}
                          resultToValue={patient => patient.id}
                          resultSet={patientsResource}
                          list={patients.value}
                          loading={resourceLoading.patients}
                          onSearch={(patientsResource, criteria) => patientsResource
                            .filter(({ fullName }) => RegExp(criteria, 'i').test(fullName))
                            .slice(0, 10)}
                          onToggle={(id, toggled, name = 'patients') => dispatch(Message.toggle({ id, toggled, name }))}
                        />
                      </Ui.Form.Label>
                      <Ui.BasicElement.CollapsibleLink onClick={(e) => setAttachmentBoxOpen(!attachmentBoxOpen)}>
                        <span>Joindre un document </span>
                        <span>
                          {attachmentBoxOpen
                            ? <Ui.Icon.IconUp />
                            : <Ui.Icon.IconDown />
                          }
                        </span>
                      </Ui.BasicElement.CollapsibleLink>
                      <Ui.Layout.AttachmentsBox open={attachmentBoxOpen}>
                        <Ui.UploadFile.DocumentsUpload
                          name="message-upload"
                          id="message-upload"
                          validationRules={["noLargeFiles", "noEmptyFiles", "acceptedDocumentFormat"]}
                          label="Documents"
                          endpoint="/messages/new/assets"
                          apiPath="/messages/assets/"
                        >
                          Poids max : 30 Mo. <br />
                          Formats acceptés : Txt, Jpeg, Png, Gif, Tiff, Zip, Pdf, Word, Excel, PowerPoint
                        </Ui.UploadFile.DocumentsUpload>
                      </Ui.Layout.AttachmentsBox>
                    </Ui.Form.InputContainer>
                    <Ui.Button.BasicButton
                      isFilled
                      isGreen
                      type="submit"
                    >
                      Envoyer
                    </Ui.Button.BasicButton>
                  </Ui.Form.InputContainer>
                </Ui.Layout.Form>
              </Ui.Layout.Content>
            </Ui.Layout.PageContentWrapper>
            : <Ui.Layout.FlexContainer><Ui.Global.Loader /></Ui.Layout.FlexContainer>
          }
        </Styled.MessageContainer>
      </Ui.Layout.PageLayout>
    </>
  )
}

export default MessageComponent;
