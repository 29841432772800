import { createSlice } from '@reduxjs/toolkit';
import { dateToString } from '../Util/Format';
import {
  saveField as commonSaveField,
  setEditField as commonSetEditField,
  restoreInitialValue as commonRestoreInitialValue,
  updateInitialValue as commonUpdateInitialValue,
} from '../Util/InlineEditActions';

export const SECTION_PARAMETERS = 'parameters'
export const SECTION_SETTINGS = 'settings'
export const SECTION_PATIENTS = 'patients'
export const SECTION_PARTICIPANTS = 'participants'
export const SECTION_COLLEAGUE = 'colleague'
export const SECTIONS_SCHEDULING = 'scheduling'
export const RESOURCE_ADMINISTRATOR = 'managers'
export const RESOURCE_DISCIPLINES = 'disciplines'
export const RESOURCE_PATIENTS = 'patients'
export const SCHEDULING_MANUAL = 'manuelle'
export const SCHEDULING_RECURRENT = 'récurrence'
export const DOMAIN = 'staff'

export const INITIAL_STATE = {
  data: {
    ownerTitle: {
      value: '',
      initialValue: '',
      isFieldEdit: false,
      errors: [],
      validationRules: []
    },
    ownerFullname: {
      value: '',
      errors: [],
      initialValue: '',
      isFieldEdit: false,
      validationRules: []
    },
    name: {
      value: '',
      initialValue: '',
      isFieldEdit: false,
      errors: [],
      validationRules: ['notEmpty']
    },
    description: {
      value: '',
      initialValue: '',
      isFieldEdit: false,
      errors: [],
      validationRules: []
    },
    organizationMembers: {
      value: [],
      errors: [],
      validationRules: []
    },
    managers: {
      value: [],
      errors: [],
      validationRules: []
    },
    tags: {
      value: [],
      errors: [],
      validationRules: []
    },
    disciplines: {
      value: [],
      errors: [],
      validationRules: []
    },
    organizations: {
      value: [],
      errors: [],
      validationRules: []
    },
    labels: {
      value: [],
      errors: [],
      validationRules: []
    },
    colleagues: {
      value: [],
      errors: [],
      validationRules: []
    },
    participants: {
      value: [],
      errors: [],
      validationRules: []
    },
    nextSession: {
      value: '',
      initialValue: '',
      isFieldEdit: false,
      errors: [],
      validationRules: []
    },
    hour: {
      value: '',
      initialValue: '',
      isFieldEdit: false,
      errors: [],
      validationRules: ['hourMinutesFormat'],
    },
    sessionId: {
      value: null,
      errors: [],
      validationRules: []
    },
    recurrence: {
      value: 'none',
      initialValue: 'none',
      isFieldEdit: false,
      errors: [],
      validationRules: []
    },
    headingDocuments: {
      value: {},
      errors: [],
      validationRules: [],
    },
    isOutdated: {
      value: null,
      errors: [],
      validationRules: [],
    },
    startDate: {
      value: '',
      initialValue: '',
      isFieldEdit: false,
      errors: [],
      validationRules: [],
    },
    nextMeeting: {
      value: '',
      initialValue: '',
      isFieldEdit: false,
      errors: [],
      validationRules: [],
    },
    nextSessionStartDate: {
      value: '',
      initialValue: '',
      isFieldEdit: false,
      errors: [],
      validationRules: [],
    },
    sessionsUpcoming: {
      value: [{ id: null, dateString: 'date à déterminer', date: null, hour: '00:00', isFieldEdit: false }],
      errors: [],
      validationArrayRules: {
        id: [],
        date: [],
        hour: ['hourMinutesFormat']
      },
    },
    endDate: {
      value: '',
      initialValue: '',
      isFieldEdit: false,
      errors: [],
      validationRules: [],
    },
    patients: {
      value: [],
      errors: [],
      validationRules: [],
    },
    currentSessionPatientIds: {
      value: [],
      errors: [],
      validationRules: [],
    },
    ownerId: {
      value: null,
      errors: [],
      validationRules: [],
    },
    owner: {
      value: '',
      initialValue: '',
      isFieldEdit: false,
      errors: [],
      validationRules: [],
    },
    archives: {
      value: {},
      errors: [],
      validationRules: [],
    },
    publicUri: {
      value: '',
      initialValue: '',
      isFieldEdit: false,
      errors: [],
      validationRules: [],
    },
    pictureFileName: {
      value: '',
      initialValue: '',
      isFieldEdit: false,
      errors: [],
      validationRules: [],
    },
    email: {
      value: '',
      initialValue: '',
      isFieldEdit: false,
      errors: [],
      validationRules: [],
    },
    message: {
      value: '',
      initialValue: '',
      isFieldEdit: false,
      errors: [],
      validationRules: [],
    },
    recipients: {
      value: '',
      initialValue: '',
      isFieldEdit: false,
      errors: [],
      validationRules: [],
    },
    portalDocuments: {
      value: '',
      initialValue: '',
      isFieldEdit: false,
      errors: [],
      validationRules: [],
    },
    portalAbout: {
      value: '',
      initialValue: '',
      isFieldEdit: false,
      errors: [],
      validationRules: [],
    },
    reportImageFileName: { value: '', errors: [], validationRules: [] },
    currentUserAdmin: { value: '', errors: [], validationRules: [] },
    patientLimitLocked: { value: false, errors: [], validationRules: [] },
    patientLimitReached: { value: false, errors: [], validationRules: [] },
    patientsLocked: { value: [], errors: [], validationRules: [] },
    numberAddPatientAction: { value: 0, errors: [], validationRules: [] },
    profilePicture: { value: '', errors: [], validationRules: [] },
    portalHeaderImage: { value: '', errors: [], validationRules: [] },
    portalHeaderImageStretch: { value: true, errors: [], validationRules: [] },
    portalHeaderImageAlign: { value: 2, errors: [], validationRules: [] },
    publicVideoconferencingActive: { value: false, errors: [], validationRules: [] },
    publicVideoconferencingId: { value: '', errors: [], validationRules: [] },
    permissions: {
      value: {
        _canBe: {
          edited: false,
          deleted: false,
          archived: false,
          commented: false,
        },
        _canBeModified: {}
      },
      errors: [],
      validationRules: []
    },
    createdAt: { value: '', initialValue: '', isFieldEdit: false, errors: [], validationRules: [] },
    updatedAt: { value: '', initialValue: '', isFieldEdit: false, errors: [], validationRules: [] },
    archive: { value: false, errors: [], validationRules: [] },
  },
  invitationData: {
    email: { value: '', errors: [], validationRules: [] },
    expertises: { value: [], errors: [], validationRules: [] },
    message: { value: '', errors: [], validationRules: [] },
    recipients: { value: [], errors: [], validationRules: ['emailList'] },
  },
  sending: false,
  pending: false,
  loading: false,
  isInviting: false,
  success: false,
  error: false,
  isEditMode: {
    disciplines: false,
    addRemovePatient: false,
    fullName: false,
    staffPicture: false,
    labels: false,
    tags: false,
    managers: false,
  },
  errorMessage: null,
  search: {
    colleagues: { value: '', loading: false },
    managers: { value: '', loading: false },
    organizations: { value: '', loading: false },
    disciplines: { value: '', loading: false },
    patients: { value: '', loading: false },
  },
  id: null,
  closing: false,
  visioRoomId: null,
  visioLoading: false,
  savingSession: false,
  expertisesWithComments: [],
  currentSection: SECTION_PATIENTS,
}

const slice = createSlice({
  name: 'staff',
  initialState: INITIAL_STATE,
  reducers: {
    updateData: (state, { payload: { name, value, errors = [] } }) => ({
      ...state,
      data: {
        ...state.data,
        [name]: {
          ...state.data[name],
          value,
          errors,
        },
      },
      error: false,
      errorMessage: null,
    }),

    setCurrentSection: (state, { payload: section }) => ({
      ...state,
      currentSection: section,
    }),

    toggle: (state, { payload: { id, toggled, name } }) => ({
      ...state,
      data: {
        ...state.data,
        [name]: {
          ...state.data[name],
          value: toggled
            ? [...state.data[name].value, id]
            : state.data[name].value.filter(i => id !== i),
        },
      },
      search: {
        ...state.search,
        [name]: {
          ...state.search[name],
          value: '',
        }
      },
      error: false,
      errorMessage: null,
    }),

    toggleParticipant: (state, { payload: { id, toggled } }) => ({
      ...state,
      data: {
        ...state.data,
        participants: {
          ...state.data.participants,
          value: toggled
            ? [...state.data.participants.value, id]
            : state.data.participants.value.filter(i => id !== i),
        },
      },
    }),

    toggleAllParticipants: (state, { payload: { areAllParticipantsChecked } }) => ({
      ...state,
      data: {
        ...state.data,
        participants: {
          ...state.data.participants,
          value: areAllParticipantsChecked
            ? []
            : [
              ...state.data.owner.value,
              ...state.data.colleagues.value,
              ...Object.values(state.data.organizationMembers.value),
              ...state.data.managers.value,
            ].filter(function (item, pos, arr) {
              return arr.indexOf(item) === Number(pos);
            }),
        },
      },
    }),

    addDiscipline: (state, { payload }) => ({
      ...state,
      data: {
        ...state.data,
        disciplines: {
          ...state.data.disciplines,
          value: [...state.data.disciplines.value, payload],
        },
      },
    }),

    addTag: (state, { payload: value = '' }) => ({
      ...state,
      data: {
        ...state.data,
        tags: {
          ...state.data.tags,
          value: [...state.data.tags.value, value],
        },
      },
    }),

    removeTag: (state, { payload: { idx } }) => ({
      ...state,
      data: {
        ...state.data,
        tags: {
          ...state.data.tags,
          value:
            state.data.tags.value
              .filter((tag, i) => i !== idx)
        }
      }
    }),

    updateTagContent: (state, { payload: { value, idx } }) => ({
      ...state,
      data: {
        ...state.data,
        tags: {
          ...state.data.tags,
          value: state.data.tags.value
            .map((tag, i) => i === idx ? value : tag)
        }
      }
    }),

    updateOwner: (state, { payload: { colleague: { colleagueId, colleagueTitle, colleagueFullName }, formerOwner } }) => ({
      ...state,
      data: {
        ...state.data,
        ownerTitle: {
          ...state.data.ownerTitle,
          value : colleagueTitle,
          initialValue : colleagueTitle,
        },
        ownerFullname: {
          ...state.data.ownerFullname,
          value : colleagueFullName,
          initialValue : colleagueFullName,
        },
        ownerId: {
          ...state.data.ownerId,
          value: Number(colleagueId)
        },
        owner: {
          ...state.data.owner,
          value: [
            Number(colleagueId)
          ]
        },
        managers: {
          ...state.data.managers,
          value: [
            ...state.data.managers.value.filter(e => e !== colleagueId),
            formerOwner
          ]
        },
        permissions: {
          ...state.data.permissions,
          value: {
            ...state.data.permissions.value,
            _canBe: {
              ...state.data.permissions.value._canBe,
              deleted: false,
            },
          }
        }
      }
    }),

    addManager: (state, { payload: { id } }) => ({
      ...state,
    }),

    removeManager: (state, { payload: { id } }) => ({
      ...state,
    }),

    addColleague: (state, { payload: { id } }) => ({
      ...state,
      data: {
        ...state.data,
        colleagues: {
          ...state.data.colleagues,
          value: [
            ...state.data.colleagues.value,
            id
          ]
        }
      }
    }),

    removeColleague: (state, { payload: { id } }) => ({
      ...state,
      data: {
        ...state.data,
        colleagues: {
          ...state.data.colleagues,
          value:
            state.data.colleagues.value
              .filter(colleagueId => colleagueId !== id)
        }
      }
    }),

    receivedOrganizationMembers: (state, { payload }) => ({
      ...state,
      data: {
        ...state.data,
        organizationMembers: {
          ...state.data.organizationMembers,
          value: payload,
        }
      }
    }),

    receivedOrganizationMembersById: (state, { payload: { organizationId, members } }) => ({
      ...state,
      data: {
        ...state.data,
        organizationMembers: {
          ...state.data.organizationMembers,
          value: {
            ...state.data.organizationMembers.value,
            [organizationId]: members.filter(id => (![
              ...state.data.managers.value,
              ...state.data.owner.value,
              ...state.data.colleagues.value,
            ].includes(id))),
          }
        }
      }
    }),

    addOrganizationMembers: (state, { payload }) => ({
      ...state
    }),

    removeOrganizationMembers: (state, { payload: organizationId }) => ({
      ...state,
      data: {
        ...state.data,
        organizationMembers: {
          ...state.data.organizationMembers,
          value: Object
            .keys(state.data.organizationMembers.value)
            .reduce((object, key) => {
              if (Number(key) !== Number(organizationId)) {
                object[key] = state.data.organizationMembers.value[key]
              }
              return object
            }, {})
        }
      }
    }),

    fetchOrganizations: (state, { payload }) => ({
      ...state
    }),

    addOrganization: (state, { payload: { id } }) => ({
      ...state,
      data: {
        ...state.data,
        organizations: {
          ...state.data.organizations,
          value: [
            ...state.data.organizations.value,
            id
          ]
        }
      }
    }),

    removeOrganization: (state, { payload: { id } }) => ({
      ...state,
      data: {
        ...state.data,
        organizations: {
          ...state.data.organizations,
          value:
            state.data.organizations.value
              .filter(organizationId => organizationId !== id)
        },
        organizationMembers: {
          ...state.data.organizationMembers,
          value: [],
        },
      },
    }),

    setErrors: (state, { payload: { name, errors } }) => ({
      ...state,
      data: {
        ...state.data,
        [name]: {
          ...state.data[name],
          errors,
        },
      },
    }),

    invalidate: (state, { payload: { message } }) => ({
      ...state,
      sending: false,
      error: true,
      errorMessage: message,
    }),

    send: state => ({
      ...state,
      sending: true,
    }),

    update: state => ({
      ...state,
      sending: true,
    }),

    updateImage: (state, { payload: { imageType, imageFileName = '' } }) => ({
      ...state,
      data: {
        ...state.data,
        [imageType]: {
          ...state.data[imageType],
          value: imageFileName,
        },
      },
    }),

    success: (state, { payload }) => ({
      ...state,
      id: payload,
      sending: false,
      error: false,
      closing: false,
      savingSession: false,
    }),

    patientsCopied: (state, { payload }) => ({
      ...state,
      data: {
        ...state.data,
        currentSessionPatientIds: {
          ...state.data.currentSessionPatientIds,
          value: [
            ...state.data.currentSessionPatientIds.value,
            ...payload.patients,
          ]
        }
      },
      pending: false,
    }),

    fetch: (state, { payload: { teleExpertiseIdParam: id, sessionIdParam } }) => ({
      ...state,
      loading: true,
      id,
    }),

    received: (state, { payload }) => ({
      ...state,
      data: Object
        .entries(payload)
        .reduce((acc, [name, value]) => ({
          ...acc,
          [name]: typeof value === 'string'
            ? ({
              ...state.data[name],
              value,
              initialValue: value,
            })
            : ({
              ...state.data[name],
              value,
            }),
        }), state.data),
      loading: false,
    }),

    receivedOwner: (state, { payload: { owner, name } }) => ({
      ...state,
      data: {
        ...state.data,
        owner: {
          ...state.data.owner,
          value: owner.username,
        },
        ownerId: {
          ...state.data.ownerId,
          value: owner.id,
        },
        name: {
          ...state.data.name,
          value: name,
        }
      },
      loading: false,
    }),

    setEditMode: (state, { payload }) => ({
      ...state,
      isEditMode: {
        ...state.isEditMode,
        [payload.target]: payload.value
      }
    }),

    updateField: (state, { payload: { name, value, errors = [] } }) => ({
      ...state,
      data: {
        ...state.data,
        [name]: { ...state.data[name], value, errors },
      },
    }),

    updatePatientsLocked: (state, { payload: patientId }) => ({
      ...state,
      data: {
        ...state.data,
        patientsLocked: {
          ...state.data.patientsLocked,
          value: [
            ...state.data.patientsLocked.value,
            patientId
          ],
        }
      },
    }),

    validateData: (state, { payload: { name } }) => ({
      ...state,
      data: {
        ...state.data,
        [name]: {
          ...state.data[name],
          errors: []
        }
      }
    }),

    closeExpertise: (state, { payload: teleExpertiseId }) => ({
      ...state,
      data: {
        ...state.data,
        archive: {
          ...state.data.archive,
          value: true
        }
      }
    }),

    openExpertise: (state, { payload: teleExpertiseId }) => ({
      ...state,
      data: {
        ...state.data,
        archive: {
          ...state.data.archive,
          value: false
        }
      }
    }),

    updateColleagues: (state, { payload }) => ({
      ...state
    }),

    updateLabelContent: (state, { payload: { value, idx } }) => ({
      ...state,
      data: {
        ...state.data,
        labels: {
          ...state.data.labels,
          value: state.data.labels.value
            .map((el, i) => i === idx ? { ...el, content: value } : el)
        }
      }
    }),

    updateLabelColor: (state, { payload: { value, idx } }) => ({
      ...state,
      data: {
        ...state.data,
        labels: {
          ...state.data.labels,
          value: state.data.labels.value
            .map((el, i) => i === idx ? { ...el, color: value } : el)
        }
      }
    }),

    addLabel: (state, { payload: { content, color, id } }) => ({
      ...state,
      data: {
        ...state.data,
        labels: {
          ...state.data.labels,
          value: [
            ...state.data.labels.value,
            {
              content,
              color,
              id
            },
          ]
        }
      },
      sending: id && ~String(id).indexOf('TG_') ? false : true,
    }),

    removeLabel: (state, { payload: { idx, id } }) => ({
      ...state,
      data: {
        ...state.data,
        labels: {
          ...state.data.labels,
          value:
            state.data.labels.value
              .filter((_, i) => i !== idx)
        }
      },
      sending: id && ~String(id).indexOf('TG_') ? false : true,
    }),

    addPatient: (state, { payload: { id } }) => ({
      ...state,
      data: {
        ...state.data,
        patients: {
          ...state.data.patients,
          value: [
            ...state.data.patients.value,
            id
          ]
        }
      },
      sending: true,
    }),

    addPatientToCurrentSession: (state, { payload: { id } }) => ({
      ...state,
      pending: {
        ...state.pending,
        [id]: true,
      },
    }),

    removePatient: (state, { payload: { id } }) => ({
      ...state,
      data: {
        ...state.data,
        patients: {
          ...state.data.patients,
          value: state.data.patients.value.filter(patientId => Number(patientId) !== Number(id))
        }
      },
      expertisesWithComments: Object
        .entries(state.expertisesWithComments)
        .map(([key, _]) => [key, key === id])
        .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {})
    }),

    getExpertisesWithComments: (state, { payload }) => ({
      ...state,
    }),

    suggestSpecialities: (state, { payload }) => ({
      ...state,
    }),

    receivedExpertisesWithComments: (state, { payload }) => ({
      ...state,
      expertisesWithComments: payload
    }),

    linkPatient: (state) => ({
      ...state,
    }),

    setQuery: (state, { payload: { name, value } }) => ({
      ...state,
      search: {
        ...state.search,
        [name]: {
          ...state.search[name],
          value
        }
      }
    }),

    apiError: (state, action) => ({
      ...state,
      errorMessage: action.payload,
      pending: false,
      error: true,
      success: false,
      sending: false,
      savingSession: false,
    }),

    allPatientsToCurrentSession: state => ({
      ...state,
      pending: true,
    }),

    fetchPublicVideoconferencing: (state, { payload }) => ({
      ...state,
    }),

    fetchStaffFromCode: (state, { payload: staffId }) => ({
      ...state,
      id: staffId,
    }),

    setStaffUri: (state, { payload }) => ({
      ...state,
      data: {
        ...state.data,
        publicUri: {
          ...state.data.publicUri,
          value: payload,
        }
      },
      isStaffUri: true,
    }),

    addSessionDate: (state) => {
      const sessionsUpcoming = state.data.sessionsUpcoming.value;
      const previousSessionDate = new Date(sessionsUpcoming[sessionsUpcoming.length - 1]?.date)
      const previousSessionHour = sessionsUpcoming[sessionsUpcoming.length - 1]?.hour
      const recurrence = state.data.recurrence.value;
      const numDaysRecurrence = { daily: 1, weekly: 7, monthly: 30, quaterly: 90, annual: 365 }
      const newDate = ['daily', 'weekly'].includes(recurrence)
        ? previousSessionDate.setDate(previousSessionDate.getDate() + numDaysRecurrence[recurrence])
        : recurrence === 'monthly'
          ? previousSessionDate.setMonth(previousSessionDate.getMonth() + 1)
          : recurrence === 'quarterly'
            ? previousSessionDate.setMonth(previousSessionDate.getMonth() + 3)
            : recurrence === 'annual'
              ? previousSessionDate.setFullYear(previousSessionDate.getFullYear() + 1)
              : previousSessionDate.setDate(previousSessionDate.getDate() + 1)

      return ({
        ...state,
        data: {
          ...state.data,
          sessionsUpcoming: {
            ...state.data.sessionsUpcoming,
            value: [
              ...state.data.sessionsUpcoming.value,
              {
                id: null,
                date: previousSessionDate && recurrence !== 'none'
                  ? new Date(newDate)
                  : null
                ,
                hour: previousSessionHour,
                isFieldEdit: true,
              }
            ]
          }
        }
      })
    },

    removeUpcomingSession: (state, { payload: { idx } }) => ({
      ...state,
      data: {
        ...state.data,
        sessionsUpcoming: {
          ...state.data.sessionsUpcoming,
          value: state.data.sessionsUpcoming.value
            .filter((session, i) => idx !== i)
        }
      }
    }),

    updateSessionDate: (state) => ({
      ...state,
      data: {
        ...state.data,
        nextSession: {
          ...state.data.nextSession,
        }
      },
      savingSession: true
    }),

    endNextSessionLoading: (state) => ({
      ...state,
      data: {
        ...state.data,
        nextSession: {
          ...state.data.nextSession,
          loading: false,
        }
      }
    }),

    updateSessionHourField: (state, { payload: { value, idx } }) => ({
      ...state,
      data: {
        ...state.data,
        sessionsUpcoming: {
          ...state.data.sessionsUpcoming,
          value: state.data.sessionsUpcoming.value
            .map((session, i) => idx === i ? { ...session, hour: value } : session),
          errors: state.data.sessionsUpcoming.errors
            .map((errorSession, i) => idx === i ? { ...errorSession, hour: [] } : errorSession),
        }
      },
      error: false,
      errorMessage: null,
    }),

    updateSessionDateField: (state, { payload: { value, idx } }) => ({
      ...state,
      data: {
        ...state.data,
        endDate: {
          ...state.data.endDate,
          value: dateToString(value,)
        },
        sessionsUpcoming: {
          ...state.data.sessionsUpcoming,
          value: state.data.sessionsUpcoming.value
            .slice()
            .sort((a, b) => (a.id === null) - (b.id === null) || a.date - b.date)
            .map((session, i) =>
              idx === i
                ? { ...session, date: value, dateString: dateToString(value) }
                : session
            ),
          errors: state.data.sessionsUpcoming.errors
            .map((errorSession, i) => idx === i ? { ...errorSession, date: [] } : errorSession),
        }
      },
      error: false,
      errorMessage: null,
    }),

    closeCurrentSession: state => ({
      ...state,
      data: {
        ...state.data,
        patients: {
          ...state.data.patients,
          value: []
        },
        participants: {
          ...state.data.participants,
          value: []
        },
      },
      closing: true,
    }),


    setEditFieldById: (state, { payload: { sessionId, isFieldEdit } }) => ({
      ...state,
      data: {
        ...state.data,
        sessionsUpcoming: {
          ...state.data.sessionsUpcoming,
          value: [
            ...state.data.sessionsUpcoming.value.map(session =>
              session.id === sessionId
                ? {
                  ...session,
                  isFieldEdit
                }
                : session
            )
          ]
        }
      }
    }),

    setIsInviting: (state, { payload }) => ({
      ...state,
      isInviting: payload,
    }),

    updateInviteField: (state, { payload: { name, value, errors = [] } }) => ({
      ...state,
      invitationData: {
        ...state.invitationData,
        [name]: {
          ...state.invitationData[name],
          value,
          errors,
        },
      },
    }),

    addRecipient: (state, { payload: { name, value } }) => ({
      ...state,
      invitationData: {
        ...state.invitationData,
        recipients: {
          ...state.invitationData.recipients,
          value: [
            ...state.invitationData.recipients.value,
            value
          ],
        },
        email: {
          ...state.invitationData.email,
          value: '',
        }
      },
    }),

    removeRecipient: (state, { payload }) => ({
      ...state,
      invitationData: {
        ...state.invitationData,
        recipients: {
          ...state.invitationData.recipients,
          value: [
            ...state.invitationData.recipients.value.filter(recipient => recipient !== payload),
          ]
        }
      }
    }),

    removePortalDocument: (state, { payload: id }) => ({
      ...state,
      success: true,
    }),

    sendInvitation: state => ({
      ...state,
      pending: true,
      error: false,
      success: false,
      invitationData: Object
        .entries(state.invitationData)
        .reduce((acc, [name, field]) => ({
          ...acc,
          [name]: { ...field, errors: [] },
        }), state.invitationData),
    }),

    clean: state => INITIAL_STATE,
    commonSaveField,
    commonSetEditField,
    commonRestoreInitialValue,
    commonUpdateInitialValue,
  },
})

export const {
  updateData,
  setErrors,
  invalidate,
  send,
  update,
  success,
  patientsCopied,
  fetch,
  received,
  validateData,
  receivedOwner,
  deleteStaff,
  updateField,
  clean,
  updateColleagues,
  updateImage,
  setEditMode,
  closeExpertise,
  openExpertise,
  updateLabelContent,
  updateLabelColor,
  addLabel,
  removeLabel,
  setQuery,
  apiError,
  toggle,
  toggleParticipant,
  toggleAllParticipants,
  getExpertisesWithComments,
  receivedExpertisesWithComments,
  updateOwner,
  addManager,
  removeManager,
  addColleague,
  removeColleague,
  addOrganization,
  removeOrganization,
  suggestSpecialities,
  addDiscipline,
  addTag,
  removeTag,
  updateTagContent,
  updatePatientsLocked,
  linkPatient,
  addPatient,
  addPatientToCurrentSession,
  removePatient,
  fetchOrganizations,
  receivedOrganizationMembers,
  receivedOrganizationMembersById,
  addOrganizationMembers,
  removeOrganizationMembers,
  allPatientsToCurrentSession,
  fetchPublicVideoconferencing,
  fetchStaffFromCode,
  setStaffUri,
  addSessionDate,
  removeUpcomingSession,
  updateSessionHourField,
  updateSessionDateField,
  closeCurrentSession,
  setEditFieldById,
  updateSessionDate,
  endNextSessionLoading,
  setIsInviting,
  updateInviteField,
  addRecipient,
  removeRecipient,
  sendInvitation,
  removePortalDocument,
  setCurrentSection,
  commonSaveField: saveField,
  commonSetEditField: setEditField,
  commonRestoreInitialValue: restoreInitialValue,
  commonUpdateInitialValue: updateInitialValue,
} = slice.actions

export const selectData = state => state.staff.data
export const selectPatientsLocked = state => state.staff.data.patientsLocked.value
export const selectIsPatientLimitReached = state => state.staff.data.patientLimitReached.value
export const selectLoading = state => state.staff.loading
export const selectErrorMessage = state => state.staff.errorMessage
export const selectHasError = state => state.staff.error
export const selectId = state => state.staff.id
export const selectSessionId = state => state.staff.data.sessionId.value
export const selectColleagues = state => state.staff.data.colleagues.value
export const selectPatients = state => state.staff.data.patients.value
export const selectArchives = state => state.staff.data.archives.value
export const selectDataValues = state => Object.entries(state.staff.data).reduce(
  (acc, [name, { value }]) => ({
    ...acc,
    [name]: value
  }),
  []
);
export const selectArchivesByYear = state =>
  Object.entries(
    Object
      .values(selectArchives(state))
      .reduce((acc, a) => {
        const year = a.endDate.split('/')[2]
        acc[year] = [
          ...acc[year] || [],
          a
        ];
        return acc;
      }, {})
  )
    .reverse()

export default slice.reducer
