export const saveField = (state, { payload: { fieldName, scope } }) => ({
  ...state,
  pending: true,
})

export const setEditField = (state, { payload: { field, value } }) => ({
  ...state,
  data: {
    ...state.data,
    [field]: {
      ...state.data[field],
      isFieldEdit: value,
    }
  }
})

export const restoreInitialValue = (state, { payload: { fieldName, value } }) => ({
  ...state,
  data: {
    ...state.data,
    [fieldName]: {
      ...state.data[fieldName],
      value,
      errors: []
    }
  }
})

export const updateInitialValue = (state, { payload: { field, value } }) => ({
  ...state,
  data: {
    ...state.data,
    [field]: {
      ...state.data[field],
      initialValue: value,
    }
  }
})

export const enterField = (fieldName, canEdit, setEditField) => () => {
  return canEdit
    ? setEditField(fieldName, true)
    : null
}

export const enterFieldsBlock = (blockName, canEdit, setEditMode) => () => {
  return canEdit
    ? setEditMode(blockName, true)
    : null
}

export const restoreValue = (fieldName, field, initialValue, setEditField, restoreInitialValue) => () => {
  restoreInitialValue(fieldName, initialValue)
  if (!field.errors.length > 0)
    setEditField(fieldName, false)
}

export const leaveField = (fieldName, field, setEditField, saveField) => e => {
  if (field.isFieldEdit && !e.currentTarget.contains(e.relatedTarget))
    if (field.value !== field.initialValue) {
      saveField(fieldName)
    } else {
      setEditField(fieldName, false)
    }
}

export const leaveFieldsBlock = (blockName, teleExpertiseId, isEditMode, doSaveField, setEditMode, saveField) => e => {
  if (isEditMode[blockName] && !e.currentTarget.contains(e.relatedTarget)) {
    setEditMode(blockName, false)
    if (doSaveField)
      saveField(blockName, teleExpertiseId)
  }
}

export const keyPress = (e, data, saveField, setEditField, restoreInitialValue) => {
  if (e.key === 'Enter') {
    saveField(e.target.name)
  }
  if (e.key === 'Escape') {
    restoreInitialValue(e.target.name, data[e.target.name].initialValue)
    if (!data[e.target.name].errors.length > 0)
      setEditField(e.target.name, false)
  }
}
