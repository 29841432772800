import React, { useEffect } from 'react';
import { privateSitePrefix } from '../Util/Config';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from "react-router-dom";
import * as Patient from '../State/Patient';
import qs from 'qs';
import * as Ui from './Ui'
import styled from 'styled-components'

const CreatePatientFromParamsComponent = (props) => {
  console.log("llkjkmlkljmlkj")
  const creationSuccess = useSelector(Patient.selectCreationSuccess)
  const existingOwnedPatient = useSelector(Patient.selectExistingOwnedPatient)
  const patientExists = useSelector(Patient.selectPatientExists)
  const patientId = useSelector(Patient.selectPatientId)
  const dispatch = useDispatch();
  const patient = props.location.search
  const queryParams = qs.parse(patient, { ignoreQueryPrefix: true })
  const expertiseTypes = ['staffs', 'opinions', 'cohorts']
  const expertises = expertiseTypes
    .map(teleExpertise =>
      queryParams[teleExpertise]
        ? queryParams[teleExpertise].split(',').map(id => ({ type: teleExpertise, id: id.trim() }))
        : null
    ).filter(e => e)[0]

  useEffect(() => {
    const { firstname, lastname, sex: gender, birthdate } = queryParams
    dispatch(Patient.createPatient({ firstname, lastname, gender, birthdate, expertises }))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (patientExists || existingOwnedPatient) {
    return <Redirect to={`${privateSitePrefix}/nouveau/patient/?${expertises[0].type}=${expertises[0].id}`} />
  } else if (creationSuccess) {
    return <Redirect to={`${privateSitePrefix}/patients/${patientId}`} />
  }

  return <BlankPage><Ui.Global.Loader /></BlankPage>
}

const BlankPage = styled(props => <Ui.Layout.FlexContainer {...props} />)`
  background-color: ${props => props.theme.lightGrey};
`

export default CreatePatientFromParamsComponent;
