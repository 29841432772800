import * as PortalPro from '../State/PortalPro'
import * as Eff from 'redux-saga/effects'
import * as Api from '../Util/Api'
import { debounceTime } from '../Util/Date';

export function* fetchPortalPro() {
  const abortController = new AbortController();
  try {
    const query = yield Eff.select(PortalPro.selectSearch)
    const delayMs = debounceTime(query)
    yield Eff.delay(delayMs)
    const portalProHost = yield Eff.select(PortalPro.selectPortalProHost)
    const teleExpertiseOpened = yield Eff.select(PortalPro.selectTeleExpertiseOpened)
    const filters = {
      portalProHost,
      teleExpertiseOpened,
      search: query,
    }
    const portalProData = yield Eff.call(Api.fetchPublicPortalPro, filters, { signal: abortController.signal })
    const keywordsNotFound = portalProData.keywordsNotFound

    yield Eff.put(PortalPro.received({
      data: portalProData,
      keywordsNotFound,
    }))
  } catch (error) {
    yield Eff.put(PortalPro.apiError(error.message))
  } finally {
    if (yield Eff.cancelled()) {
      abortController.abort();
    }
  }
}

const PortalProSagas = function* () {
  yield Eff.takeLatest([
    `${PortalPro.fetch}`,
    `${PortalPro.chooseTeleExpertise}`,
  ], fetchPortalPro)

  yield Eff.takeLatest(`${PortalPro.changeSearch}`, fetchPortalPro)
}

export default PortalProSagas;
