import { createSlice } from '@reduxjs/toolkit'

export const OPINION = 'opinion'
export const STAFF = 'staff'
export const COHORT = 'cohort'

// Toujours mettre cet etat initial du state
export const INITIAL_STATE = {
  portalProHost: null,
  portalProId: null,
  name: '',
  logo: '',
  picture: '',
  pictureAlign: 1,
  pictureStretch: false,
  description: '',
  teleExpertiseOpened: '',
  search: '',
  loading: false,
  errorMessage: null,
  pending: false,
  error: false,
  success: false,
  staffs: [],
  cohorts: [],
  opinions: [],
  keywordsNotFound: [],
}

const slice = createSlice({
  name: 'portalPro',
  initialState: INITIAL_STATE,
  reducers: {
    fetch: (state, { payload }) => ({
      ...state,
      portalProHost: payload,
      loading: true,
    }),
    received: (state, { payload }) => ({
      ...state,
      portalProId: payload.data.portalProId ? payload.data.portalProId : state.portalProId,
      portalProHost: payload.data.portalProHost ? payload.data.portalProHost : state.portalProHost,
      name: payload.data.name,
      logo: payload.data.logo,
      picture: payload.data.picture,
      pictureAlign: payload.data.pictureAlign,
      pictureStretch: payload.data.pictureStretch,
      description: payload.data.description,
      loading: false,
      staffs: payload.data.staffs || [],
      cohorts: payload.data.cohorts || [],
      opinions: payload.data.opinions || [],
      keywordsNotFound: payload.keywordsNotFound
    }),
    chooseTeleExpertise: (state, { payload }) => ({
      ...state,
      search: '',
      loading: true,
      teleExpertiseOpened: payload,
    }),
    changeSearch: (state, { payload }) => ({
      ...state,
      loading: true,
      search: payload,
    }),
    apiError: (state, action) => ({
      ...state,
      errorMessage: action.payload,
      pending: false,
      error: true,
      success: false,
    }),
  }
})

// // L'unique reducer
export default slice.reducer

// Les actions type
export const CHOOSE_TELEEXPERTISE = String(slice.actions.chooseTeleExpertise)
export const CHANGE_SEARCH = String(slice.actions.changeSearch)

// Les actions créateur
export const {
  fetch,
  chooseTeleExpertise,
  changeSearch,
  received,
  apiError,
} = slice.actions

export const selectPortalProHost = state => state.portalPro.portalProHost

export const selectPortalProId = state => state.portalPro.portalProId

export const selectTitle = state => state.portalPro.name

export const selectLogo = state => state.portalPro.logo

export const selectPicture = state => state.portalPro.picture

export const selectPictureAlign = state => state.portalPro.pictureAlign

export const selectPictureStretch = state => state.portalPro.pictureStretch

export const selectDescription = state => state.portalPro.description

export const selectStaffs = state => state.portalPro.staffs

export const selectCohorts = state => state.portalPro.cohorts

export const selectOpinions = state => state.portalPro.opinions

export const selectTeleExpertise = state =>
  STAFF === state.portalPro.teleExpertiseOpened
  ? state.portalPro.staffs
    : COHORT === state.portalPro.teleExpertiseOpened
    ? state.portalPro.cohorts
      : OPINION === state.portalPro.teleExpertiseOpened
      ? state.portalPro.opinions
        : [...state.portalPro.staffs, ...state.portalPro.cohorts, ...state.portalPro.opinions]

export const selectSearch = state => state.portalPro.search

export const selectLoading = state => state.portalPro.loading

export const selectTeleExpertiseOpened = state => state.portalPro.teleExpertiseOpened

export const selectErrorMessage = state => state.portalPro.errorMessage

export const selectKeywordsNotFound = state => state.portalPro.keywordsNotFound
