const zeroPadDigits = num => (num < 10) ? `0${num}` : num

export const dateToString = (date, { separator, reverse } = { separator: '/', reverse: false}) => {
  if (!(date instanceof Date && !isNaN(date))) {

    return '';
  }
  const JJ = zeroPadDigits(date.getDate());
  const MM = zeroPadDigits(date.getMonth() + 1);
  const AAAA = zeroPadDigits(date.getFullYear());
  return reverse
    ? `${AAAA}${separator}${MM}${separator}${JJ}`
    : `${JJ}${separator}${MM}${separator}${AAAA}`
}

export const dateToStringShort = (date, { separator, reverse } = { separator: '/', reverse: false}) => {
  console.log("separator", separator, "reverse", reverse)
  if (!(date instanceof Date && !isNaN(date)))
    return '';
  const JJ = zeroPadDigits(date.getDate());
  const MM = zeroPadDigits(date.getMonth() + 1);
  const AA = zeroPadDigits(date.getFullYear().toString().substr(-2));
  return `${JJ}${separator}${MM}${separator}${AA}`
}

export const dateToStringNoPad = (date) => {
  if (!(date instanceof Date && !isNaN(date)))
    return '';
  const JJ = date.getDate();
  const MM = date.getMonth() + 1;
  const AAAA = date.getFullYear();
  return `${JJ}/${MM}/${AAAA}`
}

export const JJMMAAToDate = (str) => {
  if (!str)
    return '';
  const dateParts = str.split("/");
  return dateParts && new Date('20' + dateParts[2], dateParts[1] - 1, +dateParts[0]);
}

export const JJMMAAAAToDate = (str) => {
  if (!str)
    return '';
  const dateParts = str.split("/");
  return new Date(dateParts[2], dateParts[1] - 1, +dateParts[0]);
}

export const isUniqId = (id) => /^[a-z]/i.test(id)

export const normalizeUrl = (str) =>
  str
    .toLowerCase()
    .replace(/ +/g, "-")
    .replace(/[àáâãäå]/g, "a")
    .replace(/[òóôõöø]/g, "o")
    .replace(/[èéêë]/g, "e")
    .replace(/[ç]/g, "c")
    .replace(/[ìíîï]/g, "")
    .replace(/[ùúûü]/g, "u")
    .replace(/[ÿ]/g, "y")
    .replace(/[ñ]/g, "n")
    .replace(/[^a-z0-9]/g, "-")
    .replace(/-{2,}/, "-")
    // .replace(/-$/, "")
    .replace(/^-/, "")

export const normalizeName = (str) =>
  str
    .toLowerCase()
    .replace(/ +/g, "")
    .replace(/[àáâ]/g, "a")
    .replace(/[éëèêë]/g, "e")
    .replace(/[ïîíï]/g, "i")
    .replace(/[ùûû]/g, "u")
    .replace(/[ôóóô]/g, "o")
    .replace(/[ç]/g, "c")
    .replace(/[ñ]/g, "n");


export const normalizeEmail = (str) =>
  str
    .replace(/ +/g, "")
    .replace(/[àáâ]/g, "a")
    .replace(/[éëèêë]/g, "e")
    .replace(/[ïîíï]/g, "i")
    .replace(/[ùûû]/g, "u")
    .replace(/[ôóóô]/g, "o")
    .replace(/[ç]/g, "c")
    .replace(/[ñ]/g, "n");

export const encryptEmail = (email) => Buffer(email).toString('base64');

export const normalizeTeleExpertiseName = (name) => name
  .toLowerCase()
  .replace(/[- ]+/g, "-")
  .replace(/ +/g, "")
  .replace(/[àá]/g, "a")
  .replace(/[éëèê]/g, "e")
  .replace(/[ïîí]/g, "i")
  .replace(/[ùû]/g, "u")
  .replace(/[ôóó]/g, "o")
  .replace(/[ç]/g, "c")
  .replace(/[ñ]/g, "n")
  .replace(/[^-0-9a-z]/gi, '')

export const isInternationalPhoneFormat = number => number.length > 6 && !/^(?:(?:\+|00)33|0)\s{0,1}[67](?:[\s\d])*$/.test(number)

export const formatBirthdate = (e, data, updateField) => {
  if (e.which === 8) {
    let val = data.birthdate.value;

    if (val.length === 3 || val.length === 6) {
      val = val.slice(0, val.length - 1);
      updateField({ name: 'birthdate', value: val })
    }
  }
}
