import React from 'react';
import styled from 'styled-components/macro'
import * as Ui from '../Ui'
import { apiUrl } from '../../Util/Config'

const CguContentComponent = ({ isLoggedIn }) => {

  return (
    <CguContentContainer>
      <Ui.BasicElement.P><i>Dernière mise à jour le 15/03/2023</i></Ui.BasicElement.P>
      <Ui.BasicElement.P><Ui.BasicElement.Span bold>La société SKEZI</Ui.BasicElement.Span>, société SAS SKEZI au Capital social de 10 225,00 € immatriculée au RCS de Annecy sous le numéro d’identification unique B 892 223 603 dont le siège social est au 1 Esplanade Augustin Aussedat, Cran Gevrier, 74960 Annecy. (ci-après « SKEZI »), édite sous la marque « Skemeet », une plateforme accessible à l’adresse https://www.skemeet.io/ (ci-après la « Plateforme SKEMEET » ou la « Plateforme »), permettant notamment à des professionnels de santé situés en France (ci-après les « Utilisateurs ») de partager de manière sécurisée des données sur leurs patients dans le cadre de leur prise en charge médicale.</Ui.BasicElement.P>
      <Ui.BasicElement.P>SKEZI et l’Utilisateur sont ensemble désignés les « Parties ».</Ui.BasicElement.P>
      <Ui.BasicElement.P>Les présentes conditions générales d’abonnement et d’utilisation de
      la Plateforme SKEMEET (ci-après les « CGAU » ou « Contrat ») décrivent les
      modalités et les conditions d'utilisation et d’abonnement par lesquelles l’Utilisateur
    peut accéder à la Plateforme SKEMEET. </Ui.BasicElement.P>
      <Ui.BasicElement.P>Les présentes CGAU expriment l’intégralité de l’accord des Parties à
      la date à laquelle l’Utilisateur crée un compte Utilisateur (ci-après le « Compte »)
    pour accéder à la Plateforme SKEMEET. </Ui.BasicElement.P>
      <Ui.BasicElement.P>La Plateforme est proposée uniquement à des Utilisateurs agissant à
    des fins professionnelles et pour les seuls besoins de leur activité professionnelle.</Ui.BasicElement.P>
      <Ui.BasicElement.P bold>1. DEFINITIONS</Ui.BasicElement.P>
      <Ui.BasicElement.P><Ui.BasicElement.Span bold>Abonnement :</Ui.BasicElement.Span> désigne la souscription par l’Utilisateur aux Services
     pour une certaine durée.</Ui.BasicElement.P>
      <Ui.BasicElement.P><Ui.BasicElement.Span bold>Donnée personnelle :</Ui.BasicElement.Span> désigne toute information se rapportant à
    une personne physique identifiée ou identifiable.</Ui.BasicElement.P>
      <Ui.BasicElement.P><Ui.BasicElement.Span bold>Identifiants :</Ui.BasicElement.Span> désigne les codes d’accès et mot de passe d’un Utilisateur
    permettant d’accéder aux Services. </Ui.BasicElement.P>
      <Ui.BasicElement.P><Ui.BasicElement.Span bold>Services :</Ui.BasicElement.Span> désignent notamment les fonctionnalités de mise en relation des
      professionnels de santé, demande d’avis, présentation de réunions, inclusion des patients
      dans une cohorte et de manière générale toutes les fonctionnalités offertes par la Plateforme
      (les « Fonctionnalités »), ainsi que, plus généralement l’accès à la Plateforme, à son
    support et à sa maintenance.</Ui.BasicElement.P>
      <Ui.BasicElement.P bold>2. OBJET</Ui.BasicElement.P>
      <Ui.BasicElement.P>Les CGAU ont pour objet de fixer les conditions de mise à disposition et
     fourniture des Services par SKEZI.</Ui.BasicElement.P>
      <Ui.BasicElement.P>Elles sont applicables à la souscription de tous types d’Abonnement. </Ui.BasicElement.P>
      <Ui.BasicElement.P bold>3. CREATION DU COMPTE</Ui.BasicElement.P>
      <Ui.BasicElement.P>L’Utilisateur a la possibilité d’accéder à la Plateforme SKEMEET en complétant
     un formulaire d’inscription en ligne pour la création d’un Compte. </Ui.BasicElement.P>
      <Ui.BasicElement.P>Des Identifiants sont définis par l’Utilisateur dès enregistrement de la création
     de Compte.</Ui.BasicElement.P>
      <Ui.BasicElement.P>La création d’un Compte utilisateur est réservée exclusivement aux Utilisateurs
    suivants :</Ui.BasicElement.P>
      <Ui.BasicElement.Ul>
        <Ui.BasicElement.Li>Médecins inscrits à l’Ordre national des médecins et titulaire d’un numéro
    RPPS en cours de validité ;</Ui.BasicElement.Li>
        <Ui.BasicElement.Li>Autres professionnels de santé (chirurgiens-dentistes, pharmaciens,
        kinésithérapeutes, infirmiers) inscrits à leur Ordre respectif et titulaire d’un numéro RPPS
    en cours de validité ;</Ui.BasicElement.Li>
        <Ui.BasicElement.Li>Etudiants en 3ème cycle des études médicales (« internes »), sous délégation
     de leur Chef de Service ;</Ui.BasicElement.Li>
        <Ui.BasicElement.Li>Personnels de professionnel de santé soumis au secret professionnel
    et sous la responsabilité et l’encadrement du professionnel de santé.</Ui.BasicElement.Li>
      </Ui.BasicElement.Ul>
      <Ui.BasicElement.P>Pour valider son Compte et accéder aux fonctionnalités de la Plateforme,
      l’Utilisateur justifie de sa qualité après son inscription en téléchargeant un justificatif
      professionnel (copie de la Carte de Professionnel de Santé (CPS), certificat d’inscription à
      l’Université, licence de remplacement, attestation d’inscription à l’Ordre des Médecins, contrat
    de travail pour les personnels de professionnels de santé).</Ui.BasicElement.P>
      <Ui.BasicElement.P>SKEZI se réserve toutefois le droit de ne pas satisfaire une demande de
    création de Compte ou d'Abonnement qui lui serait soumise.</Ui.BasicElement.P>
      <Ui.BasicElement.P bold>4. DESCRIPTION DE LA PLATEFORME </Ui.BasicElement.P>
      <Ui.BasicElement.P>La Plateforme SKEMEET comprend :</Ui.BasicElement.P>
      <Ui.BasicElement.Ul>
        <Ui.BasicElement.Li>un droit d'accès et d’utilisation des Fonctionnalités ;</Ui.BasicElement.Li>
        <Ui.BasicElement.Li>une assistance Utilisateur dans les conditions de l'article 14.</Ui.BasicElement.Li>
      </Ui.BasicElement.Ul>
      <Ui.BasicElement.P>SKEZI se réserve le droit de remplacer une Fonctionnalité des Services par
      une autre équivalente en qualité et en contenu. L’Utilisateur en est préalablement informé
      et conserve la possibilité de demander la résiliation dans les conditions prévues à
    l’article 7.</Ui.BasicElement.P>
      <Ui.BasicElement.P>SKEZI se réserve le droit de suspendre immédiatement et sans préavis
      l’accès aux Services en cas de force majeure, tentative d’intrusion ou d’atteinte à la
    sécurité de la Plateforme.</Ui.BasicElement.P>
      <Ui.BasicElement.P>SKEZI se réserve également le droit de supprimer complètement un Service.
      Dans ce cas, l’Utilisateur en est informé et sera remboursé des éventuelles sommes versées
    correspondant à la durée restante de l’Abonnement.</Ui.BasicElement.P>
      <Ui.BasicElement.P bold>5. ACCES A LA PLATEFORME </Ui.BasicElement.P>
      <Ui.BasicElement.P>La Plateforme SKEMEET n’est accessible qu’aux seuls Utilisateurs autorisés
      par SKEZI et titulaire d’un Compte, étant précisé que l’Utilisateur ne peut autoriser
      d’autres personnes. Tout usage de l’accès de l’Utilisateur par une personne non autorisée
    engage la responsabilité de l’Utilisateur.</Ui.BasicElement.P>
      <Ui.BasicElement.P>L’Utilisateur dispose d’un accès et d’un droit d’utilisation non exclusif
      et non transmissible à la Plateforme pour la durée de l’Abonnement et conformément à ses
    termes et conditions. </Ui.BasicElement.P>
      <Ui.BasicElement.P>L'accès de l’Utilisateur à la Plateforme SKEMEET se fait, pour tout
      Utilisateur, via l'URL publique https://www.skemeet.io/ et après saisine des
    Identifiants. </Ui.BasicElement.P>
      <Ui.BasicElement.P>SKEZI s’engage à mettre en œuvre tous les moyens permettant à l’Utilisateur
    un accès fiable et rapide à la Plateforme. </Ui.BasicElement.P>
      <Ui.BasicElement.P>Chaque Identifiant est personnel, individuel, confidentiel et intransmissible.
    L’URL spécifique codée d’accès à la Plateforme est strictement confidentielle.</Ui.BasicElement.P>
      <Ui.BasicElement.P>L’Utilisateur déclare avoir accepté les coûts ainsi que les limites propres
    à toute connexion au réseau Internet. </Ui.BasicElement.P>
      <Ui.BasicElement.P>L’Utilisateur se charge d’accéder à la Plateforme dans de bonnes conditions
      techniques - matériel, logiciel, télécommunications - afin que soient notamment assurées toutes
    les mesures de sauvegarde ainsi que la protection contre d’éventuelles intrusions. </Ui.BasicElement.P>
      <Ui.BasicElement.P>Les informations contenues sur la Plateforme sont accessibles 24 h/24 h, 7 j/7 j,
      sauf cas de force majeure ou d'événements hors du contrôle de la société SKEZI,
      et sous réserve des éventuelles pannes et interventions de maintenance et de mise à jour
    nécessaires au bon fonctionnement de la Plateforme et des matériels. </Ui.BasicElement.P>
      <Ui.BasicElement.P>L’Utilisateur est informé que la Plateforme pourra être suspendue sans préavis
      tous les jours entre 22h00 et 06h00 et le dimanche pour ces opérations de maintenance et de
     mises à jour des données.</Ui.BasicElement.P>
      <Ui.BasicElement.P>Cette interruption ne donne lieu à aucune indemnité. </Ui.BasicElement.P>
      <Ui.BasicElement.P bold>6. CONDITIONS D’UTILISATION DE LA PLATEFORME </Ui.BasicElement.P>
      <Ui.BasicElement.P>L'Utilisateur s'engage :</Ui.BasicElement.P>
      <Ui.BasicElement.Ul>
        <Ui.BasicElement.Li>à n'utiliser la Plateforme que pour ses besoins propres et dans le
    respect des intérêts et des droits des patients ;</Ui.BasicElement.Li>
        <Ui.BasicElement.Li>à ne pas porter atteinte aux droits de propriété intellectuelle détenus
    par SKEZI ou par des tiers sur la Plateforme et sur la marque « Skemeet » ;</Ui.BasicElement.Li>
        <Ui.BasicElement.Li>à ne pas reconstituer ou tenter de reconstituer, au moyen de la Plateforme,
        une plateforme visant à offrir directement ou indirectement, à titre gratuit ou onéreux, le même
    service ou un service comparable, à des personnes non autorisées ;</Ui.BasicElement.Li>
        <Ui.BasicElement.Li>à ne pas utiliser les dénominations « SKEMEET » ou « Skemeet »
        ainsi que toute autre dénomination protégée plus généralement utilisée dans le cadre de la Plateforme,
    sauf avec l'autorisation préalable et expresse de la société SKEZI ;</Ui.BasicElement.Li>
        <Ui.BasicElement.Li>à mettre tout en œuvre pour conserver secrets ses Identifiants afin qu’ils
        ne soient pas divulgués sous quelque forme que ce soit à d’autres personnes. L’Utilisateur est
        entièrement responsable de l’utilisation et de la conservation des Identifiants. L’Utilisateur
        assume la responsabilité de la sécurité des postes individuels d’accès à la Plateforme.
        Le prêt, le partage, le transfert ou la vente d’Identifiants sont interdits et ne seront
    pas opposables à la société SKEZI.</Ui.BasicElement.Li>
        <Ui.BasicElement.Li>à informer sans délai SKEZI de toute perte, vol ou utilisation non
        autorisée des Identifiants afin que SKEZI puisse prendre sans délai toute mesure adaptée
    en vue d’y remédier</Ui.BasicElement.Li>
      </Ui.BasicElement.Ul>
      <Ui.BasicElement.P bold>7. ENTREE EN VIGUEUR, DUREE ET FIN DE L’ABONNEMENT</Ui.BasicElement.P>
      <Ui.BasicElement.P>L’Utilisateur souscrit un Abonnement mensuel ou annuel en acceptant les
    CGAU. </Ui.BasicElement.P>
      <Ui.BasicElement.P>Les CGAU sont mises à la disposition de l’Utilisateur lors de la souscription
      de l’Abonnement. Elles sont opposables à l’Utilisateur qui reconnaît en avoir pris connaissance
      et les avoir expressément acceptées sans réserve avant de valider la souscription à son
    Abonnement.</Ui.BasicElement.P>
      <Ui.BasicElement.P>L’acceptation des CGAU vaut acceptation du prix explicité lors de la souscription
    sur la Plateforme https://www.skemeet.io/.</Ui.BasicElement.P>
      <Ui.BasicElement.P>Les CGAU constituent avec le courriel de confirmation de la souscription d’Abonnement
      et les factures, les documents contractuels opposables à l’Utilisateur, à l’exclusion de tout autre,
      notamment de ses propres conditions générales de vente et/ou d’achat et/ou de ses propres bons
     de commandes.</Ui.BasicElement.P>
      <Ui.BasicElement.P>La date de début d’Abonnement est celle correspondant au 1er paiement de la
    souscription. </Ui.BasicElement.P>
      <Ui.BasicElement.P bold>Abonnement mensuel : </Ui.BasicElement.P>
      <Ui.BasicElement.P>L’Abonnement entre en vigueur au jour de sa souscription. L’Abonnement est d’une
      durée minimum de 3 mois. Il est ensuite tacitement reconductible par périodes successives d’un (1) mois.
      L’Utilisateur peut toutefois résilier l’Abonnement à tout moment et jusqu’à quinze (15)
      jours calendaires avant l’expiration de la période d’Abonnement en cours, par courriel adressé
      à l’adresse suivante <a href="mailto:contact@skezi.eu">contact@skezi.eu</a>. Toute demande de résiliation du Client sera
      confirmée par un courriel de la société SKEZI. La résiliation ne sera effective qu’à partir
    de la date anniversaire de l’Abonnement.</Ui.BasicElement.P>
      <Ui.BasicElement.P bold>Abonnement annuel : </Ui.BasicElement.P>
      <Ui.BasicElement.P>L’Abonnement entre en vigueur au jour de sa souscription. L’Abonnement est d’une
      durée de douze (12) mois, tacitement reconductible, par périodes successives de douze (12) mois.
      L’Utilisateur peut toutefois résilier l’Abonnement à tout moment et jusqu’à deux (2) mois calendaires
      avant l’expiration de la période d’Abonnement en cours, par courriel adressé à l’adresse suivante <a href="mailto:contact@skezi.eu">contact@skezi.eu</a>. Toute demande de résiliation du Client sera confirmée par un courriel
      de la société SKEZI. La résiliation ne sera effective qu’à partir de la date anniversaire
    de l’Abonnement.</Ui.BasicElement.P>
      <Ui.BasicElement.P>SKEZI peut mettre fin, à tout moment, à la Plateforme.</Ui.BasicElement.P>
      <Ui.BasicElement.P>En cas de non-respect des obligations définies aux présentes, SKEZI se réserve
      le droit de supprimer ou suspendre le compte de l’Utilisateur enregistré, d’empêcher sa réinscription
      et d’engager toute action et/ou recours pour faire cesser toute atteinte à ses droits et obtenir
     réparation du préjudice subi.</Ui.BasicElement.P>
      <Ui.BasicElement.P bold>8. CONDITIONS FINANCIERES</Ui.BasicElement.P>
      <Ui.BasicElement.P bold>8.1. Prix et révision</Ui.BasicElement.P>
      <Ui.BasicElement.P>Le prix que l’Utilisateur s’engage à régler à la société SKEZI en contrepartie d’une
      part, du droit d’utilisation de la Plateforme et d’autre part, de l’accès aux Services, est accepté sur
     le site lors de la souscription à l’Abonnement.</Ui.BasicElement.P>
      <Ui.BasicElement.P>Les prix sont indiqués en euros, hors taxes. Le montant de ces taxes est indiqué
      lors de la souscription à un Abonnement. Les prix toutes taxes comprises tiennent compte de la
      TVA applicable au jour de la souscription à un Abonnement. Tout changement du taux applicable
    pourra être répercuté sur le tarif de l’Abonnement.</Ui.BasicElement.P>
      <Ui.BasicElement.P>Le prix de l’Abonnement pourra être révisé annuellement et fera alors l’objet
      d’une information à l’Utilisateur. A défaut de réponse de la part de l’Utilisateur avant l’entrée
      en vigueur de la révision tarifaire, lors de l’échéance de son Abonnement, l’Utilisateur sera
      réputé l’avoir acceptée. En cas de refus exprès formulé par l’Utilisateur avant l’entrée en
      vigueur de la révision tarifaire, l’Utilisateur aura la possibilité de résilier l’Abonnement
    suivant les délais et modalités de l’article 7.</Ui.BasicElement.P>
      <Ui.BasicElement.P bold>8.2. Modalités de règlement</Ui.BasicElement.P>
      <Ui.BasicElement.P>Les factures seront adressées exclusivement sous format électronique par SKEZI
    à l’Utilisateur.</Ui.BasicElement.P>
      <Ui.BasicElement.P bold italic>Pour l’Abonnement mensuel : </Ui.BasicElement.P>
      <Ui.BasicElement.P>Toute facture sera payable par l’Utilisateur dans son intégralité au jour de sa
    souscription et à une fréquence mensuelle.</Ui.BasicElement.P>
      <Ui.BasicElement.P bold italic>Pour l’Abonnement annuel : </Ui.BasicElement.P>
      <Ui.BasicElement.P>En cas de paiement annuel, toute facture sera payable par l’Utilisateur dans son
    intégralité dans les trente (30) jours suivants son établissement.</Ui.BasicElement.P>
      <Ui.BasicElement.P>SKEZI se réserve le droit de proposer un paiement mensualisé de l’Abonnement
      annuel à l’Utilisateur. Dans ce cas, toute facture sera payable par l’Utilisateur dans son intégralité
    au jour anniversaire de sa souscription et à une fréquence mensuelle.</Ui.BasicElement.P>
      <Ui.BasicElement.P bold>8.3. Incident de paiement</Ui.BasicElement.P>
      <Ui.BasicElement.P>En cas d'incident de paiement à l'échéance convenue, l’Utilisateur (i) se verra appliquer,
      sans qu'une lettre de rappel soit nécessaire, des pénalités de retard d'un montant égal au taux
      d'intérêt appliqué par la Banque Centrale Européenne à son opération de refinancement la plus récente,
      majoré de dix (10) points de pourcentage, calculé par mensualité, (ii) sera de plein droit débiteur,
      d'une indemnité forfaitaire pour frais de recouvrement de quarante (40) euros, conformément aux
      articles L.441-6 I. et D.441-5 du Code de commerce, sauf justification de frais plus
    élevés.</Ui.BasicElement.P>
      <Ui.BasicElement.P bold>9. DISPONIBILITE ET MAINTENANCE</Ui.BasicElement.P>
      <Ui.BasicElement.P>La Plateforme est accessible 24 heures sur 24, 7 jours sur 7, sauf cas de force
      majeure ou d'événements hors du contrôle de la société SKEZI, et sous réserve des interventions
    de maintenance et de mise à jour nécessaires au bon fonctionnement de la Plateforme. </Ui.BasicElement.P>
      <Ui.BasicElement.P>L’Utilisateur est informé que les Services pourront être suspendus sans préavis
    tous les jours pour ces opérations de maintenance et de mises à jour. </Ui.BasicElement.P>
      <Ui.BasicElement.P>L’Utilisateur est cependant informé que la connexion aux Services s'effectue via
      le réseau Internet. Il est averti des aléas techniques qui peuvent affecter ce réseau et entraîner
    des ralentissements ou des indisponibilités rendant la connexion impossible. </Ui.BasicElement.P>
      <Ui.BasicElement.P>SKEZI ne peut être tenu responsable des difficultés d'accès aux Services dues à
    des perturbations du réseau internet.</Ui.BasicElement.P>
      <Ui.BasicElement.P>Pour une assistance technique, les prestations additionnelles, de formation et de
      personnalisation, ou pour des questions de facturation, l’Utilisateur pourra contacter
      SKEZI :</Ui.BasicElement.P>
      <Ui.BasicElement.Ul>
        <Ui.BasicElement.Li>par courriel à l’adresse suivante : <a href="mailto:contact@skezi.eu">contact@skezi.eu</a></Ui.BasicElement.Li>
        <Ui.BasicElement.Li>ou au moyen du formulaire de contact ou du module de messagerie instantanée
        accessibles depuis la Plateforme.</Ui.BasicElement.Li>
      </Ui.BasicElement.Ul>
      <Ui.BasicElement.P bold>10. RESPONSABILITE</Ui.BasicElement.P>
      <Ui.BasicElement.P bold>SKEZI n'accorde aucune garantie expresse ou implicite à l'égard des informations
      et des données disponibles sur la Plateforme. L’Utilisateur est seul responsable tant de l'usage et
      des interprétations qu'il fait des informations et des données disponibles sur la Plateforme, que
    des actes et conseils qu'il en déduit ou émet. </Ui.BasicElement.P>
      <Ui.BasicElement.P>L'utilisation et l'exploitation de ces informations et données par l’Utilisateur se
    font donc sous sa seule responsabilité et à ses risques et périls. </Ui.BasicElement.P>
      <Ui.BasicElement.P>À ce titre, il est précisé que SKEZI n'a pas vocation à se substituer à
      l’Utilisateur pour effectuer des recherches, des examens, des actes médicaux, administrer un
    traitement ou analyser les données obtenues.</Ui.BasicElement.P>
      <Ui.BasicElement.P>En aucun cas, SKEZI n’engagera sa responsabilité pour les dommages directs ou
      indirects qui pourraient être causés par les informations ou données publiés sur la Plateforme.
      De convention expresse entre les Parties, sont considérés comme dommages indirects tout préjudice
      moral ou physique, perte de revenus, de patientèle, perte de données et toute action dirigée contre
    l’Utilisateur par un tiers et les conséquences en résultant.</Ui.BasicElement.P>
      <Ui.BasicElement.P>S’agissant en particulier de la Plateforme, l'Utilisateur déclare accepter les
    caractéristiques et les limites d'un tel service en ligne, et en particulier reconnaître :</Ui.BasicElement.P>
      <Ui.BasicElement.Ul>
        <Ui.BasicElement.Li>qu'il a connaissance des aléas de la consultation en ligne, en particulier
    au regard des temps de réponse ;</Ui.BasicElement.Li>
        <Ui.BasicElement.Li>qu'il lui appartient de prendre toutes mesures nécessaires pour s'assurer
        que les caractéristiques techniques de son ordinateur et/ou de son réseau informatique, lui permettent
    l'accès à la Plateforme ;</Ui.BasicElement.Li>
        <Ui.BasicElement.Li>qu’il reconnaît être seul responsable de ses accès sur Internet ;</Ui.BasicElement.Li>
        <Ui.BasicElement.Li>qu'il appartient à l'Utilisateur de prendre toutes les mesures appropriées
        de façon à protéger ses propres données et/ou logiciels de la contamination par d'éventuels virus
    circulant sur Internet ou contractés par tout autre moyen électronique. </Ui.BasicElement.Li>
      </Ui.BasicElement.Ul>
      <Ui.BasicElement.P>La responsabilité de la société SKEZI ne peut être mise en cause, tant vis à vis
      des tiers que de l’Utilisateur, pour les conséquences de l’utilisation de la Plateforme par
    l’Utilisateur.</Ui.BasicElement.P>
      <Ui.BasicElement.P>En conséquence, SKEZI ne pourra être tenu, du fait d’une obligation expresse ou
      tacite, comme civilement ou pénalement responsable envers l’Utilisateur ou des tiers de quelconque
      dommage direct ou indirect découlant de l’utilisation de la Plateforme, et notamment consécutif à
      une information inexacte ou incomplète, une erreur d’indexation, un dysfonctionnement de l’une des
    Fonctionnalités, un retard ou une absence de mise en ligne.</Ui.BasicElement.P>
      <Ui.BasicElement.P>SKEZI ne pourra être tenu responsable d’un quelconque dommage, de quelque nature
      que ce soit, notamment perte d’exploitation, perte de données ou toute autre perte financière
      résultant de l’utilisation ou de l’impossibilité d’utiliser la Plateforme visés aux présentes.
      En outre, aucune assistance procurée par SKEZI dans l’utilisation de la Plateforme ne peut
    créer de garantie supplémentaire par rapport aux présentes conditions.</Ui.BasicElement.P>
      <Ui.BasicElement.P bold>11. DONNEES PERSONNELLES</Ui.BasicElement.P>
      <Ui.BasicElement.P>SKEZI sera amené dans le cadre des présentes à traiter, en qualité de responsable
     de traitement, des données à caractère personnel relatives aux Utilisateurs.</Ui.BasicElement.P>
      <Ui.BasicElement.P>Les traitements de données à caractère personnel mis en œuvre par SKEZI et les modalités d’exercice des droits des personnes concernées sont décrits dans sa <a href={isLoggedIn ? "?tab=3" : "/politique-de-confidentialite"}>politique de Confidentialité</a>.</Ui.BasicElement.P>
      <Ui.BasicElement.P>Certaines des données collectées et traitées par SKEZI sont nécessaires pour
      permettre de poursuivre les finalités décrites dans cette <a href={isLoggedIn ? "?tab=3" : "/politique-de-confidentialite"}>politique de confidentialité</a>, et
      notamment pour la fourniture des Services ou la gestion des Abonnements. Leur non-communication
      est en conséquence notamment susceptible d’empêcher la fourniture par SKEZI des Services, en
      particulier la consultation de la Plateforme. SKEZI ne pourra être tenue responsable d’un
    tel empêchement en cas de refus de communication des données par l’Utilisateur.</Ui.BasicElement.P>
      <Ui.BasicElement.P bold>12. COOKIES</Ui.BasicElement.P>
      <Ui.BasicElement.P>La <a href={isLoggedIn ? "?tab=4" : "/cookies"}>politique de cookies</a> disponible sur la Plateforme fournit aux Utilisateurs
    des informations sur la façon dont SKEZI utilise des « cookies » ou « traceurs ». </Ui.BasicElement.P>
      <Ui.BasicElement.P>Le recours à ces traceurs ou cookies est nécessaire pour permettre à l’Utilisateur
      d’accéder aux Services et à l’espace client. SKEZI ne pourra être tenue responsable en cas
      d’impossibilité d’accès aux Services ou à l’espace client précité qui serait consécutive à une
    opposition à l’utilisation de ces traceurs ou cookies par l’Utilisateur.</Ui.BasicElement.P>
      <Ui.BasicElement.P bold>13. SECRET PROFESSIONNEL</Ui.BasicElement.P>
      <Ui.BasicElement.P>Les données de santé à caractère personnel sont couvertes par le secret professionnel
      dans les conditions prévues à l'article L 1110-4 du Code de la santé publique, dont la violation
     est réprimée par l'article 226-13 du Code pénal.</Ui.BasicElement.P>
      <Ui.BasicElement.P>L’Utilisateur communique au patient dont il enregistre les données sur la Plateforme toutes les informations relatives au traitement de ses données selon les modalités de son choix, par exemple via la notice suivante :</Ui.BasicElement.P>
      <Ui.BasicElement.P>« Le professionnel de santé ou l’établissement de santé ______________________________ est
      amené à recueillir et à conserver dans votre dossier patient des informations sur votre état de
    santé.</Ui.BasicElement.P>
      <Ui.BasicElement.P>La tenue de ce dossier est nécessaire et a pour finalité d’assurer votre suivi
      médical et de garantir la prise en charge la plus adaptée à votre état de santé. Il garantit la
      continuité de la prise en charge sanitaire et répond à l’exigence de délivrer des soins
    appropriés.</Ui.BasicElement.P>
      <Ui.BasicElement.P>Afin de faciliter et d’améliorer votre prise en charge médical, votre dossier est
      enregistré sur la plateforme sécurisée Skemeet, utilisée uniquement par des professionnels
    de santé dont l’identité est vérifiée. </Ui.BasicElement.P>
      <Ui.BasicElement.P>Votre dossier est conservé en principe pendant 20 ans à compter de la date de votre
      dernière consultation, par référence aux dispositions de l’article R. 1112-7 du code de la santé
    publique applicables aux établissements de santé.</Ui.BasicElement.P>
      <Ui.BasicElement.P>La plateforme Skemeet a recours à un Hébergeur certifié de Données de Santé (OVH Cloud – 2 rue Kellermann 59100 Roubaix 1007).</Ui.BasicElement.P>
      <Ui.BasicElement.P>Seuls ont accès aux informations figurant dans votre dossier des professionnels
      de santé et, dans une certaine mesure, au regard de la nature des missions qu’il exerce,
      leur personnel. Le professionnel de santé en charge de votre suivi pourra également, après
      vous en avoir informé, transmettre à d’autres professionnels de santé des informations
    concernant votre état de santé. </Ui.BasicElement.P>
      <Ui.BasicElement.P>Vous avez le droit d’accéder aux informations figurant dans votre dossier. Vous
      disposez, par ailleurs, sous certaines conditions, d’un droit de rectification, d’effacement
    de ces informations, ou du droit de vous opposer ou de limiter leur utilisation.</Ui.BasicElement.P>
      <Ui.BasicElement.P>Pour exercer vos droits ou pour toute question relative aux traitements de vos données par la plateforme Skemeet, vous pouvez contacter le professionnel de santé assurant votre prise en charge ou la plateforme Skemeet directement par voie postale à DPO – SKEZI, Les Papeteries, 1 Esplanade Augustin Aussedat, Cran Gevrier, 74960 Annecy ou par mail <a href="mailto:dpo@skezi.eu">dpo@skezi.eu</a>.</Ui.BasicElement.P>
      <Ui.BasicElement.P>En cas de difficultés, vous pouvez également saisir la CNIL d’une
    réclamation. »</Ui.BasicElement.P>
      <DownloadPatientInformation rel="noopener noreferrer" download href={`${apiUrl}${isLoggedIn ? '' : '/public'}/users/download-patient-information`}>
          <Ui.Button.BasicButton
              isGreen
          >
              <Ui.Icon.IconDocument />
              Enregistrer la notice au format PDF
          </Ui.Button.BasicButton>
      </DownloadPatientInformation>
      <Ui.BasicElement.P bold>14. MANQUEMENT DE L’UNE DES PARTIES</Ui.BasicElement.P>
      <Ui.BasicElement.P>L’Abonnement aux Services pourra être résilié automatiquement et de plein droit
      par l’une des Parties dans l’hypothèse où l’autre Partie ne respecterait pas les obligations mises
    à sa charge.</Ui.BasicElement.P>
      <Ui.BasicElement.P>La résiliation de l’Abonnement sera effective à l’expiration d’un délai de
      trente (30) jours à compter de la notification par une Partie, par lettre recommandée avec
    demande d’avis de réception, d’un manquement de l’autre Partie à l’une de ses obligations.</Ui.BasicElement.P>
      <Ui.BasicElement.P>SKEZI peut notamment suspendre de plein droit et sans préavis l’Abonnement
    et résilier l’Abonnement en cas :</Ui.BasicElement.P>
      <Ui.BasicElement.Ul>
        <Ui.BasicElement.Li>d’absence de règlement d’une facture par l’Utilisateur; </Ui.BasicElement.Li>
        <Ui.BasicElement.Li>de violation par l’Utilisateur des conditions d’utilisation des Services ;</Ui.BasicElement.Li>
        <Ui.BasicElement.Li>de tentative d’intrusion ou d’atteinte à l’intégrité logique de la
    Plateforme.</Ui.BasicElement.Li>
      </Ui.BasicElement.Ul>
      <Ui.BasicElement.P>Il est expressément convenu entre les Parties d’exclure l’application
      de l’article 1226 du Code civil, la seule possibilité de résolution ou de résiliation
    étant donc la mise en œuvre de la clause résolutoire à l’alinéa précédent. </Ui.BasicElement.P>
      <Ui.BasicElement.P>En cas de résiliation anticipée de l’Abonnement et ce quelle que soit
      sa cause, toutes les sommes restant dues par l’Utilisateur seront immédiatement exigibles
    sans préjudice des dommages et intérêts qui pourraient être dus à la société SKEZI.</Ui.BasicElement.P>
      <Ui.BasicElement.P bold>15. DROIT APPLICABLE – ATTRIBUTION DE COMPETENCE</Ui.BasicElement.P>
      <Ui.BasicElement.P>Le Contrat est régi par le droit français. </Ui.BasicElement.P>
      <Ui.BasicElement.P>Il est rédigé en langue française. Dans le cas où il serait traduit en une ou
     plusieurs langues, seul le texte français fait foi en cas de litige.</Ui.BasicElement.P>
      <Ui.BasicElement.P>En cas de litige relatif au présent Contrat, SKEZI et l’Utilisateur s’engagent à tout mettre en œuvre pour le résoudre amiablement. L’Utilisateur peut contacter SKEZI par courrier électronique à l’adresse suivante <a href="mailto:contact@skezi.eu">contact@skezi.eu</a> ou à l’adresse postale suivante : SKEZI, 1 Esplanade Augustin Aussedat, Cran Gevrier, 74960 Annecy.</Ui.BasicElement.P>
      <Ui.BasicElement.P bold>TOUTE DIFFICULTÉ RELATIVE À LA VALIDITE, L’INTERPRETATION OU À L'EXÉCUTION
      DU CONTRAT QUI N’AURAIT PAS PU FAIRE L’OBJET D’UN ACCORD AMIABLE ENTRE LA SOCIETE ET
      L’UTILISATEUR RELEVE DE LA COMPÉTENCE DES TRIBUNAUX DE PARIS, NONOBSTANT PLURALITÉ DE
      DÉFENDEURS OU APPEL EN GARANTIE, MÊME POUR DES PROCÉDURES D'URGENCE OU DES PROCÉDURES
    CONSERVATOIRES, SUR RÉFÉRÉ OU SUR REQUÊTE.</Ui.BasicElement.P>
    </CguContentContainer >
  )
}

const CguContentContainer = styled(props => <Ui.PortailLayout.PageContainer {...props} />)`
  @media only screen and (min-width: ${props => props.theme.device.desktop}) {
    margin: 0;
    margin-right: 4rem;
  }
  a {
    color: ${props => props.theme.patientGreen};
  }

  ${Ui.Form.InputContainer}:last-of-type {
    margin-bottom: .2rem;
  }

  ${Ui.BasicElement.H1} {
    margin-top: 3rem;
  }

  ${Ui.BasicElement.H2} {
    margin-top: 2rem;
    font-size: 1rem;
  }

  ${Ui.BasicElement.P} {
    margin: .5rem 0;
  }

  ${Ui.BasicElement.Link} {
    font-size: .875rem;
    font-weight: 700;
  }
`

const DownloadPatientInformation = styled.a`
  margin-bottom: 1rem;
  text-decoration: none;
`

export default CguContentComponent;
