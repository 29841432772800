import NativeDatePicker from 'react-date-picker'
import styled from 'styled-components'

export default styled(props => <NativeDatePicker {...props} />)`
  .react-calendar {
    border: 0;
    border-radius: 8px;
    box-shadow: 0 2px 12px 0 rgba(33, 65, 82, 0.3);
    width: 25rem;
    outline: 0;
    margin-left: -1rem;
    margin-top: +.5rem;
  }
  .react-date-picker__calendar {
    z-index: 100;
  }
  .react-date-picker {
    font-family: 'Montserrat';
  }
  .react-calendar__month-view__weekdays__weekday {
    color: #86aec5;
  }
  .react-date-picker__inputGroup {
    font-size: .875rem;
  }
  .react-date-picker__inputGroup__input {
    outline: 0;
  }
  .react-date-picker__wrapper {
    width: 100%;
    border: 0;
  }
  .react-calendar abbr {
    text-decoration: none;
    font-weight: normal;
  }
  .react-calendar button {
    box-shadow:inset 0px 0px 0px 2px #fff;
  }

  .react-calendar__month-view__days {
    padding-bottom: .5rem;
  }
  .react-calendar__tile--active {
    background: #3bc3a8;
    box-shadow: 0 2px 6px 0 rgba(171, 171, 171, 0.5);
    width: 2.5rem;
    height: 1.5rem;
    color: #FFF;
    font-weight: bold;
  }
  .react-calendar__tile--active abbr {
    font-weight: bold;
  }
  .react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus {
    background-color:#3bc3a8;
  }
  .react-calendar__month-view__days__day--neighboringMonth {
    color: #42708a;
    background: #E6EEF4;
    width: 2.5rem;
    height: 1.5rem;
  }

  .react-calendar__month-view__days__day--weekend,
  .react-calendar__month-view__days__day {
    color: #42708a;
  }
  .react-calendar__navigation__prev2-button,
  .react-calendar__navigation__next2-button {
    display: none;
  }

  .react-calendar__navigation__label {
    color: #214152;
    font-size: 1rem;
  }

  .react-calendar__navigation__arrow {
    font-size: 2rem;
    color: #86aec5;
  }
  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    background-color: transparent;
  }
  .react-calendar__tile {
    padding: 0;
    width: 2rem;
    height: 1.8rem;
    &:disabled {
      background-color: transparent;
      color: #CCC;
    }
  }

  .react-calendar__navigation {
    button {
      &:disabled {
        background-color: transparent;
        opacity: .4;
      }
    }
  }

  .react-calendar__tile--active {
    color: white;
  }
  .react-date-picker__calendar-button {
    font-family: 'ShareConfrere', sans-serif;
    font-size: 1.1rem;
    color: #3bc3a8;
    outline: 0;
  }
  .react-calendar__tile--now {
    background: #bad1de;
}

.react-date-picker--disabled {
}
.react-date-picker--disabled, .react-date-picker__inputGroup__input, .react-date-picker__calendar-button {
  cursor: ${props => props.disabled
    ? 'not-allowed'
    : 'pointer'
  };
}
`
