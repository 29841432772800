import * as AccountValidation from '../State/AccountValidation'
import * as Login from '../State/Login'
import { takeLatest, call, put } from 'redux-saga/effects'
import * as Api from '../Util/Api'

export function* validateAccount({ payload: userCode }) {
  try {
    yield call(Api.validateAccount, userCode)

    try {
      yield put(AccountValidation.success())

      const currentUser = yield call(Api.fetchCurrentUser)
      yield put(Login.receivedUser(currentUser))
    } catch (error) {
      console.log(error)
      yield put(Login.failure())
    }
  } catch (error) {
    yield put(AccountValidation.apiError(error.message))
  }
}

const AccountValidationSagas = function* () {
  yield takeLatest(`${AccountValidation.validateAccount}`, validateAccount)
}

export default AccountValidationSagas;
