import React, { useState } from 'react'
import { privateSitePrefix } from '../../Util/Config'
import { useHistory } from 'react-router-dom'
import { apiUrl } from '../../Util/Config'
import styled from 'styled-components/macro'
import * as IconUI from '../Ui/Icon'
import * as CardUi from '../Ui/Card'
import * as LayoutUi from '../Ui/Layout'
import * as BasicElementUi from '../Ui/BasicElement'
import { Checkbox } from '../Ui/Form'
import { useAlert } from 'react-alert'
import WithPermissions from '../WithPermissions'

export const ColleagueContainer = styled.div`
  display: flex;
  padding-top: .5rem;
  padding-bottom: .5rem;
  box-sizing: border-box;
  color: ${props => props.theme.darkBlue};
  flex-wrap: nowrap;
  width: 100%;
  cursor: default;

  a {
    text-decoration: none;
    color: black;
  }
  @media only screen and (min-width: ${props => props.theme.device.desktop}) {
    width: 33.33333%;
  }
`

const ColleagueCard = styled.div`
  box-sizing: border-box;
  font-size: .8rem;
  background: ${props => props.theme.grey};
  display: flex;
  background-color: white;
  padding: .7rem;
  border-radius: 5px;
  box-shadow: 0 2px 5px 1px rgba(134,174,197,0.24);
  flex: 1;
  cursor: pointer;

  img {
    width: 3rem;
    height: 3rem;
    flex: initial;
    border-radius: 50px;
  }
  @media only screen and (min-width: ${props => props.theme.device.desktop}) {
    margin-right: 1.3rem;
  }
`

const CardContent = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
`

const CardFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1;
  ${CardUi.Disciplines} {
    font-size: .625rem;
  }
  ${CardUi.DisciplineLabel} {
    padding: .2rem .75rem;
    margin-top: 0;
  }
  ${CardUi.MoreDisciplineButton} {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 0;
    span {
      padding-right: .5rem;
    }
    i {
      color: ${props => props.theme.patientGreen};
      padding-top: .25rem;
      font-size: .5rem;
    }
  }
`

const Medal = styled.div`
  border-radius: 50px;
  ${IconUI.IconMedal} {
    color: ${props => props.theme.darkBlue};
    font-size: 1.3rem;
  }
`

const CardHeader = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  margin-bottom: .5rem;
  img {
    width: 2.5rem;
    height: 2.5rem;
  }
  ${IconUI.IconOptions} {
    font-size: 1.25rem;
    color: ${props => props.theme.grey2};
    left: 0.5rem;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    &:hover {
      cursor: pointer;
    }
  }
  ${BasicElementUi.AvatarPicture} {
    margin-left: 0;
  }
  ul {
    box-shadow: 0 2px 12px 0 rgba(33, 65, 82, 0.3);
    right: -11px;
  }
  li {
    color: ${props => props.theme.darkBlue};
    font-size: 0.875rem;
    padding: .8rem;
    &:hover {
      background-color: #E7EFF3;
      cursor: pointer;
    }
    &:not(:last-child) {
      border-bottom: 1px solid ${props => props.theme.lightGrey}
    }
  }
`

const CardTitle = styled.div`
  flex: 1;
  font-size: 0.75rem;
  margin-left: .8rem;
  align-self: center;
  cursor: pointer;
  min-height: 3.65rem;
  span {
    flex: 1;
  }
  & > div:first-child {
    margin-bottom: .5rem;
    font-size: 0.9375rem;
    font-weight: 700;
  }
  & > div:last-child {
    margin-top: .8rem;
  }
`

const NextSession = styled.div`
  display: flex;
  align-items: center;
  > label {
    min-width: 1.1rem;
    min-height: 1.1rem;
    margin-right: .5rem;
    > i {
      font-size: .6rem;
    }
  }
`

export const Colleague = ({
  onRemove,
  colleague,
  organization = null,
  isParticipant = false,
  id = 0,
  labels = [],
  onToggle = () => null,
  onUpdateDoctor = null,
  onUpdateOwner = null,
  closedSession = false,
  sessionId = null,
  patientId = null,
  patientDoctorId = null,
  patientCreatorId = null,
  teleExpertiseOwnerId = null,
  children,
  endDate = '',
  to = '#',
  permissions,
  hideMenu,
  currentUser,
  canEdit,
  ...props
}) => {
  const [disciplineOpen, setDisciplineOpen] = useState(false)
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const history = useHistory()
  const alert = useAlert()
  const myselfColleague = currentUser?.id === colleague.id
  const isPatientCreator = colleague.id === patientCreatorId

  return (

    <ColleagueContainer onClick={e => e.stopPropagation()}>
      <ColleagueCard onClick={e => history.push(to)}>
        <CardContent>

          <CardHeader>
            <BasicElementUi.AvatarPicture
              title={colleague.username}
              bgSrc={
                colleague.pictureFileName
                  ? `${apiUrl}/users/profile-picture/${colleague.pictureFileName}}`
                  : !colleague.profilePicture || colleague.profilePicture.includes('Avatar_default.png') ? `/Avatar_default.png` : `${apiUrl}/users/profile-picture/${colleague.profilePicture}`} />
            <CardTitle>
              <LayoutUi.FlexBox>
                <span>{colleague.username}</span>
                {isPatientCreator &&
                  <Medal>
                    <IconUI.IconMedal />
                  </Medal>
                }
              </LayoutUi.FlexBox>
              <div>{colleague.speciality}</div>
              <div>{colleague.city}</div>
            </CardTitle>
            {!hideMenu && (!myselfColleague || (onUpdateOwner && Number(teleExpertiseOwnerId) !== Number(colleague.id)))
              ? <IconUI.IconOptions onClick={e => { e.stopPropagation(); setDropdownOpen(!dropdownOpen) }} />
              : null
            }
            <CardUi.Dropdown open={dropdownOpen} onClickOutside={e => setDropdownOpen(!dropdownOpen)}>
              {!myselfColleague &&
                <>
                  <CardUi.DropdownItem onClick={e => {
                    e.stopPropagation()
                    history.push(`${privateSitePrefix}/nouveau/message/${colleague.id}`)
                  }}
                  >Envoyer un message</CardUi.DropdownItem>
                  <CardUi.DropdownItem onClick={e => {
                    e.stopPropagation()
                    setDropdownOpen(false)
                    history.push(`${privateSitePrefix}/medecins/${colleague.id}?tab=3`)
                  }}>Demander un avis</CardUi.DropdownItem>
                  <WithPermissions to={['deleted']} permissions={permissions}>
                    {onUpdateDoctor && Number(patientDoctorId) !== Number(colleague.id) &&
                      <CardUi.DropdownItem onClick={e => {
                        e.stopPropagation()
                        alert.show('Confirmez-vous le changement de responsable ?', {
                          onConfirm: (e) => {
                            onUpdateDoctor(colleague, id)
                            setDropdownOpen(false)
                          }
                        })
                      }}>
                        Désigner comme responsable du patient
                      </CardUi.DropdownItem>
                    }
                  </WithPermissions>
                </>
              }
              {onUpdateOwner && Number(teleExpertiseOwnerId) !== Number(colleague.id) &&
                <WithPermissions to={['archived']} permissions={permissions}>
                  <CardUi.DropdownItem onClick={e => {
                    e.stopPropagation()
                    alert.show('Confirmez-vous le changement de responsable ?', {
                      onConfirm: (e) => {
                        onUpdateOwner(colleague, id)
                        setDropdownOpen(false)
                      }
                    })
                  }}>
                    Désigner comme responsable
                  </CardUi.DropdownItem>
                </WithPermissions>
              }
            </CardUi.Dropdown>
          </CardHeader>
          <CardFooter>
            {labels.length > 0
              ? <CardUi.Disciplines open={disciplineOpen}>
                {labels.map((label, i) =>
                  <CardUi.DisciplineLabel key={`colleague_${i}_label`}>
                    {label.content}
                  </CardUi.DisciplineLabel>
                )}
                {labels.length > 1 && (
                  <CardUi.MoreDisciplineButton onClick={e => setDisciplineOpen(!disciplineOpen)}>
                    {disciplineOpen
                      ? <span><IconUI.IconUp /></span>
                      : <><span>+{Object.values(labels).length - 1} expertise(s)</span> <span><IconUI.IconDown /></span></>
                    }
                  </CardUi.MoreDisciplineButton>
                )}
              </CardUi.Disciplines>
              : null
            }
            {endDate &&
              <NextSession onClick={e => e.stopPropagation()}>
                <>
                  {canEdit &&
                    <Checkbox
                      checked={isParticipant}
                      onChange={(e) => { onToggle(colleague.id, !isParticipant) }}
                    />
                  }
                  {closedSession
                    ? `A participé à la session du ${endDate}`
                    : canEdit
                      ? `Participera à la session du ${endDate}`
                      : null
                  }
                </>
              </NextSession>
            }
          </CardFooter>
        </CardContent>
      </ColleagueCard>
    </ColleagueContainer >
  )
}

export const Avatar = ({
  value = {},
}) => <BasicElementUi.AvatarPicture title="Avatar" bgSrc={value.pictureFileName
  ? `${apiUrl}/users/profile-picture/${value.pictureFileName}`
  : (value?.profilePicture && !value.profilePicture.includes('Avatar_default.png')
    ? `${apiUrl}/users/profile-picture/${value.profilePicture}`
    : '/Avatar_default.png')}
  />

export const DefaultAvatar = ({
  value,
}) => <BasicElementUi.AvatarPicture title="Avatar" bgSrc="/Avatar_default.png" />
