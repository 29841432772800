import { createSlice } from '@reduxjs/toolkit'

export const INITIAL_STATE = {
  errorMessage: null,
  pending: false,
  error: false,
  success: false,
  visioLoading: false,
  seeVisioPassword: false,
  visioRoomLink: null,
  visioRoomPassword: null,
  apiErrorMessage: null,
}

const slice = createSlice({
  name: 'videoConference',
  initialState: INITIAL_STATE,
  reducers: {
    fetchVideoconferencingRoomLink: (state) => ({
      ...state,
      noMoreRoomAvailable: false,
      visioLoading: true
    }),

    receivedVideoconferencingRoomLink: (state, { payload }) => ({
      ...state,
      visioLoading: false,
      visioRoomLink: payload.zoomRoomLink ? payload.zoomRoomLink : null,
      visioRoomPassword: payload.zoomRoomPassword ? payload.zoomRoomPassword : null,
    }),

    setSeeVisioPassword: (state, { payload }) => ({
      ...state,
      visioLoading: false,
      seeVisioPassword: payload,
    }),

    receivedApiErrorMessage: (state, { payload }) => ({
      ...state,
      apiErrorMessage: payload
    }),

    clean: state => INITIAL_STATE,

    apiError: (state, action) => ({
      ...state,
      errorMessage: action.payload,
      pending: false,
      error: true,
      success: false,
    }),
  }
})

export const {
  fetchVideoconferencingRoomLink,
  receivedVideoconferencingRoomLink,
  setSeeVisioPassword,
  receivedApiErrorMessage,
  clean,
  apiError,
} = slice.actions

export const selectVideoConferencingRoomLink = state => state.videoConference.visioRoomLink

export const selectErrorMessage = state => state.videoConference.errorMessage

export const selectVisioLoading = state => state.videoConference.visioLoading

export const selectSeeVisioPassword = state => state.videoConference.seeVisioPassword

export const selectVisioRoomPassword = state => state.videoConference.visioRoomPassword

export const selectApiErrorMessage = state => state.videoConference.apiErrorMessage

export default slice.reducer
