import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from "react-router-dom";
import * as ContactUs from '../State/ContactUs'
import * as Menu from '../State/Menu'
import DisclaimerContent from './Ui/DisclaimerContent';
import PrivacyPolicyContent from './Ui/PrivacyPolicyContent';
import CookiesContent from './Ui/CookiesContent';
import CguContent from './Ui/CguContent';
import qs from 'qs'
import * as Ui from './Ui'
import styled from 'styled-components'
import LoadingBox from './Ui/LoadingBox'
import SEOTags from './SEOTags';

const LegalComponent = (props) => {
  const dispatch = useDispatch()
  const currentUser = useSelector(state => state.login.currentUser)
  const [currentSection, setCurrentSection] = useState(1)
  const history = useHistory();
  const params = props.location.search
  const { tab } = qs.parse(params, { ignoreQueryPrefix: true })

  useEffect(() => {
    dispatch(Menu.changeActive(null))

    return () => {
      dispatch(ContactUs.clean())
    }
  }, [dispatch])

  useEffect(() => {
    if (tab) {
      setCurrentSection(Number(tab))
    }
  }, [tab])

  return (
    <>
      <SEOTags
        title="A propos - Skemeet"
      />
      <LoadingBox />
      <Ui.Layout.PageLayout currentUser={currentUser}>
        <ContactUsContainer>
          <Ui.Layout.PageContentWrapper>
            <Ui.Layout.PanelContainer>
              <Ui.Layout.Panel>
                <Ui.Button.BackButton onClick={history.goBack} />
                <Ui.Layout.TelexpertiseThumbnailContainer>
                  <Ui.Layout.TelexpertiseLogo>
                    <Ui.Icon.IconInfos />
                  </Ui.Layout.TelexpertiseLogo>
                  <Ui.BasicElement.H3 noMargin>A propos</Ui.BasicElement.H3>
                </Ui.Layout.TelexpertiseThumbnailContainer>
              </Ui.Layout.Panel>
            </Ui.Layout.PanelContainer>
            <Ui.Layout.Content hasPanel noPadding>
              <Ui.Layout.NavContainer>
                <nav>
                  <Ui.Layout.NavLink
                    onClick={() => setCurrentSection(1)}
                    on={currentSection === 1}
                  >
                    <Ui.Icon.IconLegal />
                    Mentions légales
                  </Ui.Layout.NavLink>
                  <Ui.Layout.NavLink
                    onClick={() => setCurrentSection(2)}
                    on={currentSection === 2}
                  >
                    <Ui.Icon.IconLock />
                    Politique de confidentialité
                  </Ui.Layout.NavLink>
                  <Ui.Layout.NavLink
                    onClick={() => setCurrentSection(3)}
                    on={currentSection === 3}
                  >
                    <Ui.Icon.IconCookies />
                    Cookies
                  </Ui.Layout.NavLink>
                  <Ui.Layout.NavLink
                    onClick={() => setCurrentSection(4)}
                    on={currentSection === 4}
                  >
                    <Ui.Icon.IconCGU />
                    CGU
                  </Ui.Layout.NavLink>
                </nav>
              </Ui.Layout.NavContainer>
              <Ui.Layout.PaddedContent>
                {(currentSection === 1) &&
                  <>
                    <Ui.BasicElement.H2>
                      Mentions légales
                    </Ui.BasicElement.H2>
                    <DisclaimerContent />
                  </>
                }
                {(currentSection === 2) &&
                  <>
                    <Ui.BasicElement.H2>
                      Politique de confidentialité
                    </Ui.BasicElement.H2>
                    <PrivacyPolicyContent isLoggedIn />
                  </>
                }
                {(currentSection === 3) &&
                  <>
                    <Ui.BasicElement.H2>
                      Cookies
                    </Ui.BasicElement.H2>
                    <CookiesContent isLoggedIn />
                  </>
                }
                {(currentSection === 4) &&
                  <>
                    <Ui.BasicElement.H2>
                      CGU
                    </Ui.BasicElement.H2>
                    <CguContent isLoggedIn />
                  </>
                }
              </Ui.Layout.PaddedContent>
            </Ui.Layout.Content>
          </Ui.Layout.PageContentWrapper>
        </ContactUsContainer>
      </Ui.Layout.PageLayout>
    </>
  )
}

const ContactUsContainer = styled.div`
 ${Ui.BasicElement.H1} {
   margin-top: 2rem;
 }
 ${Ui.Layout.TelexpertiseThumbnailContainer} {
   margin-top: 1.5rem;
   ${Ui.BasicElement.H3} {
     margin-top: 0;
   }
 }
 ${Ui.Layout.NavLinkItem} {
  @media only screen and (min-width: ${props => props.theme.device.desktop}) {
    margin-right: .5rem;
  }
 }
  @media only screen and (max-width: ${props => props.theme.device.desktop}) {
    margin: 1rem;
  }
  ${Ui.SearchBar.SearchBarContainer} {
    margin-bottom: 0;
    width: 100%;
  }
  ${Ui.Form.Input} {
    &:read-only {
      border-color: transparent;
      opacity: 1;
    }
  }
  ${Ui.Search.FiltersAndSorting} {
    justify-content: flex-end;
    &:first-child {
      margin-bottom: 1rem;
    }
  }
  ${Ui.Layout.Content} {
    padding-bottom: 2rem;
    ${Ui.Layout.ButtonGroup} {
      margin-top: .7rem;
    }
    ${Ui.Message.Body} {
      ${Ui.BasicElement.P} {
        margin: 0;
      }
    }
    @media only screen and (min-width: ${props => props.theme.device.desktop}) {
      & > :not(:first-child) {
        margin-top: 5rem;
      }
    }
    @media only screen and (max-width: ${props => props.theme.device.desktop}) {
      padding:0;
    }
  }
  ${Ui.BasicElement.H3} {
    margin-top: 1.5rem;
  }
  ${Ui.Select.SelectContainer} {
    width: 21rem;
    @media only screen and (max-width: ${props => props.theme.device.desktop}) {
      width: 85%;
    }
  }
  ${Ui.Button.Button} {
    margin-top: 1rem;
  }
  ${Ui.Form.Label} {
    ${Ui.SearchBar.SearchBarContainer} {
      margin-top: .6rem;
    }
  }
  ${Ui.Form.InputContainer} {
    margin-top: 1.5rem;
  }
  ${Ui.Layout.TelexpertiseLogo} {
    background-color: ${props => props.theme.patientGreen};
  }
  a {
    flex: 0;
    text-decoration: none;
  }
  ${Ui.Button.Button} {
    margin-top: 1rem;
  }
`

export default LegalComponent;
