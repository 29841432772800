import React, { useEffect, useRef } from 'react'
import { useAlert } from 'react-alert'
import { Portal } from 'react-portal';
import * as Ui from '../Ui'
import * as Dicom from '../../State/Dicom'
import * as UploadFile from '../../State/UploadFile'
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro'

const LoadingBoxComponent = () => {
  const dispatch = useDispatch();
  const alert = useAlert()
  const isLoadingBoxVisible = useSelector(Dicom.selectIsLoadingBoxVisible)
  const uploadedDicom = useSelector(Dicom.selectUploaded)
  const uploadedDicomError = useSelector(Dicom.selectUploadedError)
  const totalFiles = useSelector(Dicom.selectTotalFiles)
  const isUploadComplete = useSelector(Dicom.selectIsUploadComplete)
  const isDownloadComplete = useSelector(Dicom.selectIsDownloadComplete)
  const isUploadingImage = useSelector(UploadFile.selectUploading)
  const currentDownloadId = useSelector(Dicom.selectCurrentDownloadId)
  const downloadType = useSelector(Dicom.selectDownloadType)
  let intervalRef = useRef();

  const onCloseUploadingBox = (isUploadComplete) => {
    if (!isUploadComplete) {
      alert.show('Interrompre le chargement en cours ?', {
        onConfirm: (e) => {
          if (intervalRef.current) clearInterval(intervalRef.current)
          dispatch(Dicom.abortUpload())
        }
      })
    } else {
      dispatch(Dicom.showLoadingBox({ type: Dicom.UPLOAD, isVisible: false }))
      dispatch(Dicom.success())
    }
  }

  const onCloseDownloadingBox = () => {
    if (!isDownloadComplete) {
      alert.show('Interrompre le téléchargement en cours ?', {
        onConfirm: (e) => {
          if (intervalRef.current) clearInterval(intervalRef.current)
          dispatch(Dicom.abortDownload())
        }
      })
    } else {
      dispatch(Dicom.showLoadingBox({ type: Dicom.DOWNLOAD, isVisible: false }))
      dispatch(Dicom.success())
    }
  }

  useEffect(() => {
    const id = setInterval(() => {
      if (currentDownloadId) {
        dispatch(Dicom.checkDownload(currentDownloadId));
      }
    }, 8000);
    intervalRef.current = id;
    return () => clearInterval(intervalRef.current);
  }, [currentDownloadId, dispatch]);

  return (
    isLoadingBoxVisible[Dicom.UPLOAD]
      ? <Portal>
        <Container>
          <Ui.Layout.FlexBox>
            {(isUploadingImage || !isUploadComplete) &&
              <><Ui.Global.Loader isSmall isWhite /></>
            }
            <span>Chargement {(isUploadComplete && !isUploadingImage) ? 'terminé' : 'en cours...'}{'\u00A0'}</span>
            {(isUploadComplete && !isUploadingImage)
              ? <span>{uploadedDicomError ? `${uploadedDicomError} fichier(s) en échec` : ''}</span>
              : downloadType === 'dicom'
                ? <span>{`${parseInt((uploadedDicom / totalFiles) * 100)}%`}{'\u00A0'}</span>
                : null
            }
          </Ui.Layout.FlexBox>
          <span onClick={() => onCloseUploadingBox((isUploadComplete && !isUploadingImage))}>&times;</span>
        </Container>
      </Portal>
      : isLoadingBoxVisible[Dicom.DOWNLOAD]
        ? <Portal>
          <Container>
            <Ui.Layout.FlexBox>
              {!isDownloadComplete
                ? <>
                  <Ui.Global.Loader isSmall isWhite />
                  <span>Préparation du téléchargement...</span>
                </>
                : null
              }
            </Ui.Layout.FlexBox>
            <span onClick={() => onCloseDownloadingBox()}>&times;</span>
          </Container>
        </Portal >
        : null
  )
}

const Container = styled.div`
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
  display: flex;
  flex-direction: row;
  padding: .5rem .8rem;
  align-items: center;
  border-radius: 4px;
  z-index: 100;
  & > ${Ui.Layout.FlexBox} {
    flex: 1;
    font-size: .875rem;
    ${Ui.Global.Spinner} {
      margin: 0 .4rem;
    }
    & > span:nth-of-type(1) {
      flex: 1;
      margin-left: .8rem;
    }
    & > span:nth-of-type(3) {
      margin-left: .2rem;
    }
  }
  & > span {
    font-size: 2rem;
    margin-left: .5rem;
    cursor: pointer;
    color: ${props => props.theme.lightgrey};
  }
  position: fixed;
  width: 20rem;
  height: 2rem;
  right: 1rem;
  bottom: 1rem;
  background: ${props => props.theme.darkBlue};
  color: white
`

export default LoadingBoxComponent;
