import React, { useEffect } from 'react'
import { privateSitePrefix } from '../Util/Config'
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from "react-router-dom";
import { useAlert } from 'react-alert'
import { fr } from '../Util/i18n';
import { cohortPublicUrl } from '../Util/Config'
import * as Menu from '../State/Menu'
import * as TeleExpertise from '../State/Cohort';
import * as CustomField from '../State/CustomField';
import * as Login from '../State/Login';
import * as Questionnaire from '../State/Questionnaire'
import * as Resources from '../State/Resources';
import * as Ui from './Ui'
import * as Styled from './CreateCohort.styled'
import * as UploadFile from '../State/UploadFile';
import Tags from './Ui/Tags';
import LoadingBox from './Ui/LoadingBox'

const CreateCohortComponent = () => {
  const teleExpertiseName = 'cohort'
  const alert = useAlert()
  const history = useHistory()
  const dispatch = useDispatch()

  const currentUser = useSelector(Login.selectUser)
  const resourceLoading = useSelector(Resources.selectLoading)
  const {
    data,
    search,
    sending,
    errorMessage,
    id,
  } = useSelector(state => state.cohort)

  const {
    [Resources.RESOURCE_DISCIPLINES]: disciplines,
  } = useSelector(Resources.selectResources)

  const uploading = useSelector(UploadFile.selectUploading)

  const updateField = name => e =>
    dispatch(TeleExpertise.updateData({ name, value: e.target.value }))

  const send = e => {
    e.preventDefault()
    dispatch(TeleExpertise.send())
  }

  useEffect(() => {
    dispatch(Menu.changeActive(Menu.COHORTES))

    return () => {
      dispatch(TeleExpertise.clean())
      dispatch(CustomField.clean())
      dispatch(Questionnaire.clean())
      dispatch(UploadFile.cleanAll())
    }
  }, [dispatch])

  useEffect(() => {

    dispatch(TeleExpertise.fetchOrganizations())
    dispatch(TeleExpertise.suggestSpecialities(currentUser.speciality))
  }, [dispatch, currentUser.speciality])

  const updateTagContent = (name, idx) => e => {
    dispatch(TeleExpertise.updateTagContent({ name, value: e.target.value, idx }))
  }

  useEffect(() => {
    if (errorMessage)
      alert.error(errorMessage, { timeout: 5000 })
  }, [alert, errorMessage])

  if (id) {
    return <Redirect to={`${privateSitePrefix}/${fr[teleExpertiseName].expertises}/${id}`} />
  }

  return (
    <>
      <LoadingBox />
      <Ui.Layout.PageLayout currentUser={currentUser}>
        <Styled.TeleExpertiseContainer>
          <Ui.Layout.Form onSubmit={(e) => e.preventDefault()} noValidate>
            <Ui.Layout.PageContentWrapper>
              {/* Panel */}
              <Ui.Layout.PanelContainer>
                <Ui.Layout.Panel>
                  <Ui.Button.BackButton onClick={history.goBack} />
                  <Ui.BasicElement.H1Container type={teleExpertiseName}>
                    <Styled.IconH1 type={teleExpertiseName}>
                      {teleExpertiseName === 'staff'
                        ? <Ui.Icon.IconMessage />
                        : teleExpertiseName === 'cohort'
                          ? <Ui.Icon.IconCohorte />
                          : <Ui.Icon.IconAvis />
                      }
                    </Styled.IconH1>
                    <Ui.BasicElement.H1>
                      {fr[teleExpertiseName].newExpertise}
                    </Ui.BasicElement.H1>
                  </Ui.BasicElement.H1Container>
                  <Ui.BasicElement.P>
                    Créez des cohortes, personnalisez votre eCRF, incluez les patients,
                    seul ou à plusieurs. Planifiez le suivi de vos patients à l'aide d'étapes
                    et questionnaires. Exportez vos données pour évaluer vos pratiques et
                    publier des études.
                  </Ui.BasicElement.P>
                </Ui.Layout.Panel>
              </Ui.Layout.PanelContainer>
              <Ui.Layout.Content hasPanel noPadding>
                <Ui.Layout.NavContainer>
                  <nav>
                    <Ui.Layout.NavLink
                      on={true}
                    >
                      <Ui.Icon.IconInfos />
                      Créer votre cohorte
                    </Ui.Layout.NavLink>
                  </nav>
                  <Ui.Button.NavbarButton
                    isFilled
                    isPrimary
                    type="button"
                    onClick={send}
                    disabled={
                      sending ||
                      uploading
                    }
                  >
                    {sending &&
                      <Ui.Global.Loader />
                    }
                    Valider
                  </Ui.Button.NavbarButton>
                </Ui.Layout.NavContainer>
                <Ui.Layout.PaddedContent>
                  {/* Step parameters */}
                  <section>
                    <Ui.Layout.Row>
                      <Ui.Form.TextInput
                        htmlFor="name"
                        label="Nom *"
                        value={data.name.value}
                        onChange={updateField('name')}
                        autoFocus
                        error={data.name.errors[0]}
                        noSubmit
                      />
                    </Ui.Layout.Row>
                    <Ui.Layout.BlockContainer>
                      <Ui.UploadFile.ImagesUpload
                        label="Illustration"
                        name="cohort-picture"
                        id="cohort-picture"
                        htmlFor="cohort-picture"
                        multiple={false}
                        endpoint={`/${teleExpertiseName}s/picture/create`}
                        validationRules={["noLargeFiles", "noEmptyFiles", "acceptedImageFormat"]}
                        previewUri={`/${teleExpertiseName}s/picture`}
                        isEditMode
                      />
                    </Ui.Layout.BlockContainer>
                    <Ui.Layout.Row>
                      <Ui.Form.TextAreaInputResizable
                        htmlFor="description"
                        label="Description"
                        value={data.description.value}
                        onChange={updateField('description')}
                        error={data.description.errors[0]}
                        noSubmit
                      />
                    </Ui.Layout.Row>
                    <Ui.Layout.BlockContainer>
                      <Tags
                        data={data}
                        type="tag"
                        label="mot-clé"
                        teleExpertiseName={teleExpertiseName}
                        onAddItem={() => dispatch(TeleExpertise.addTag())}
                        onRemoveItem={idx => e => dispatch(TeleExpertise.removeTag({ idx }))}
                        onUpdateItemContent={(type, idx) => updateTagContent(type, idx)}
                        isCreationMode={true}
                      />
                    </Ui.Layout.BlockContainer>
                    <Ui.BasicElement.H3>
                      Disciplines
                    </Ui.BasicElement.H3>
                    <Ui.Layout.BlockContainer>
                      <Ui.SearchBar.SearchAndCheck
                        name="disciplines"
                        query={search.disciplines.value}
                        TeleExpertise={TeleExpertise}
                        teleExpertiseName={teleExpertiseName}
                        resultSet={disciplines}
                        list={data.disciplines.value}
                        loading={resourceLoading.disciplines}
                      />
                    </Ui.Layout.BlockContainer>
                    {data.disciplines.errors[0] &&
                      <Ui.Form.FieldErrorMessage>{data.disciplines.errors[0]}</Ui.Form.FieldErrorMessage>
                    }
                    <Ui.BasicElement.H3>
                      URL du portail
                    </Ui.BasicElement.H3>
                    <Ui.Layout.BlockContainer>
                      <Ui.BasicElement.P>Personnalisez puis partagez cette adresse internet pour que vos correspondants accèdent directement à votre téléexpertise.</Ui.BasicElement.P>
                      <Ui.BasicElement.P>
                        {cohortPublicUrl}/
                        <Ui.Form.InlineInput
                          htmlFor="Identifiant"
                          placeholder="Identifiant"
                          value={data.publicUri.value.replace(/ +/g, '.')}
                          onChange={updateField('publicUri')}
                          error={data.publicUri.errors[0]}
                        />
                      </Ui.BasicElement.P>
                    </Ui.Layout.BlockContainer>
                  </section>
                </Ui.Layout.PaddedContent>
              </Ui.Layout.Content>
            </Ui.Layout.PageContentWrapper>
          </Ui.Layout.Form>
        </Styled.TeleExpertiseContainer>
      </Ui.Layout.PageLayout>
    </>
  )
}

export default CreateCohortComponent;
