import { createSlice } from '@reduxjs/toolkit';
import {
  saveField as commonSaveField,
  setEditField as commonSetEditField,
  restoreInitialValue as commonRestoreInitialValue,
  updateInitialValue as commonUpdateInitialValue,
} from '../Util/InlineEditActions';

export const INITIAL_STATE = {
  id: null,
  data: {
    name: { value: '', initialValue: '', isFieldEdit: false, errors: [], validationRules: ['notEmpty'] },
    logo: { value: '', errors: [], validationRules: [] },
    organizationId: { value: '', errors: [], validationRules: [] },
    members: { value: [], errors: [], validationRules: [] },
    managers: { value: [], errors: [], validationRules: [] },
    expertises: { value: [], errors: [], validationRules: [] },
    staffs: { value: [], errors: [], validationRules: [] },
    opinions: { value: [], errors: [], validationRules: [] },
    cohorts: { value: [], errors: [], validationRules: [] },
    permissions: {
      value: {
        _canBe: { seenWithTeleExpertiseLimitPatient: true, deleted: false },
        _canBeModified: {}
      }, errors: [], validationRules: []
    },
  },
  expertisesData: [],
  availableExpertises: [],
  keywordsNotFound: [],
  pending: false,
  loading: false,
  success: false,
  deleted: false,
  error: false,
  errorMessage: null,
  isEditMode: {
    managers: false,
  },
  currentUser: null,
  search: {
    members: { value: '', loading: false },
    managers: { value: '', loading: false },
    expertises: { value: '', loading: false },
    patients: { value: '', loading: false },
    opinions: { value: '', loading: false },
    staffs: { value: '', loading: false },
    cohorts: { value: '', loading: false },
  },
}

const slice = createSlice({
  name: 'organization',
  initialState: INITIAL_STATE,
  reducers: {
    updateField: (state, { payload: { name, value, errors = [] } }) => ({
      ...state,
      data: {
        ...state.data,
        [name]: {
          ...state.data[name],
          value,
          errors,
        },
      },
      error: false,
      errorMessage: null,
    }),

    received: (state, { payload }) => ({
      ...state,
      data: Object
        .entries(payload)
        .reduce((acc, [name, value]) => ({
          ...acc,
          [name]: {
            ...state.data[name],
            value,
            initialValue: value,
          },
        }), state.data),
      loading: false,
    }),

    toggle: (state, { payload: { value, toggled, name } }) => ({
      ...state,
      data: {
        ...state.data,
        [name]: {
          ...state.data[name],
          value: toggled
            ? [...state.data[name].value, value]
            : state.data[name].value.filter(e => value.id !== e.id),
        },
      },
      search: {
        ...state.search,
        [name]: {
          ...state.search[name],
          value: '',
        }
      },
      error: false,
      errorMessage: null,
    }),

    setEditMode: (state, { payload: { target, value } }) => ({
      ...state,
      isEditMode: {
        ...state.isEditMode,
        [target]: value,
      }
    }),

    fetchAvailableExpertises: state => ({
      ...state,
      search: {
        ...state.search,
        expertises: {
          ...state.search.expertises,
          loading: true
        }
      },
      availableExpertises: state.availableExpertises.filter(exp =>
        state.data.expertises.value.find(expertise =>
          expertise.type === exp.type && Number(expertise.id) === Number(exp.id)
        ))
    }),

    receivedAvailableExpertises: (state, { payload: { teleExpertises, keywordsNotFound } }) => ({
      ...state,
      availableExpertises: [
        ...state.availableExpertises,
        ...teleExpertises,
      ],
      keywordsNotFound,
      loading: false,
      search: {
        ...state.search,
        expertises: {
          ...state.search.expertises,
          loading: false
        }
      }
    }),

    setQuery: (state, { payload: { name, value } }) => ({
      ...state,
      search: {
        ...state.search,
        [name]: {
          ...state.search[name],
          value
        }
      }
    }),

    removeLogo: state => ({
      ...state,
      data: {
        ...state.data,
        logo: {
          ...state.data.logo,
          value: ''
        }
      }
    }),

    updateResource: (state, { payload: { resource, resourceId, toggled } }) => ({
      ...state,
      data: {
        ...state.data,
        [`${resource}s`]: {
          ...state.data[`${resource}s`],
          value: toggled
            ? [...state.data[`${resource}s`].value, resourceId]
            : state.data[`${resource}s`].value.filter(id => resourceId !== id),
        }
      },
      search: {
        ...state.search,
        [`${resource}s`]: {
          ...state.search[`${resource}s`],
          value: '',
        }
      },
    }),

    updateTeleExpertise: (state, { payload: { organizationId, resource, resourceId, toggled } }) => ({
      ...state,
      data: {
        ...state.data,
        expertises: {
          ...state.data.expertises,
          value: toggled
            ? [...state.data.expertises.value, { id: resourceId, type: `${resource}s` }]
            : state.data.expertises.value.filter(teleExpertise =>
              resourceId !== teleExpertise.id || `${resource}s` !== teleExpertise.type),
        }
      },
      search: {
        ...state.search,
        expertises: {
          ...state.search.expertises,
          value: '',
        }
      },
    }),

    update: state => ({
      ...state,
    }),

    success: (state, { payload }) => ({
      ...state,
      loading: false,
      error: false,
      success: true,
    }),

    invalidate: (state, { payload }) => ({
      ...state,
      pending: false,
      error: true,
      errorMessage: payload,
    }),

    apiError: (state, action) => ({
      ...state,
      errorMessage: action.payload,
      pending: false,
      error: true,
      success: false,
    }),

    clean: state => INITIAL_STATE,

    fetchExpertise: state => state,

    send: state => state,

    fetch: (state, { payload }) => ({
      ...state,
      id: payload,
      loading: true,
    }),
    commonSaveField,
    commonSetEditField,
    commonRestoreInitialValue,
    commonUpdateInitialValue,
  },
})

export const {
  updateField,
  toggle,
  setQuery,
  success,
  invalidate,
  apiError,
  clean,
  fetch,
  received,
  sendMessage,
  send,
  fetchAvailableExpertises,
  receivedAvailableExpertises,
  updateResource,
  updateTeleExpertise,
  update,
  setEditMode,
  removeLogo,
  commonSaveField: saveField,
  commonSetEditField: setEditField,
  commonRestoreInitialValue: restoreInitialValue,
  commonUpdateInitialValue: updateInitialValue,
} = slice.actions

export const selectSearchId = state => state.organization.id
export const selectLoading = state => state.organization.loading
export const selectData = state => state.organization.data
export const selectAvailableExpertises = state => state.organization.availableExpertises
export const selectKeywordsNotFound = state => state.organization.keywordsNotFound
export const selectId = state => state.organization.data.organizationId.value
export const selectError = state => state.organization.error
export const selectErrorMessage = state => state.organization.errorMessage

export default slice.reducer
