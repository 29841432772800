import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './AlertBox.styled';
import { BasicButton } from './Button';
import { ButtonGroup } from './Layout';
import {
  IconBell,
  IconClose,
  IconOk,
  IconChat,
  IconInfos,
} from './Icon';

const Icon = ({ name }) => {
  const icons = {
    'ok': <IconOk />,
    'bell': <IconBell />,
    'infos': <IconInfos />,
    'message': <IconChat />,
  }
  return icons[name]
}

const AlertBoxComponent = ({
  options,
  message = '',
  close,
}) => {

  const handleConfirmAction = (e) => {
    e.preventDefault();
    options.onConfirm(e);
    close()
  }

  const handleCancel = (e) => {
    e.preventDefault();
    close()
  }

  const handleAskCoOwnership = (e) => {
    e.preventDefault();
    options.onAskCoOwnership(e);
    close()
  }

  const handleForcePatientCreation = e => {
    e.preventDefault();
    options.onForceCreation();
    close()
  }

  return (
    <Styled.AlertContainer>
      <Styled.Content>
        <Styled.IconCircle>
          <Styled.Icon>
            {options.type === 'info' &&
              <Icon name={options.alertType = 'ok'} />
            }
            {options.type === 'success' &&
              <Icon name='infos' />

            }
            {options.type === 'error' &&
              <Icon name='infos' />
            }
          </Styled.Icon>
        </Styled.IconCircle>
        <Styled.Message>
          <div>
            {message}
          </div>
          {options.onConfirm
            ? <ButtonGroup>
              <BasicButton
                isGreen
                isSmall
                isFilled
                onClick={handleConfirmAction}
              >
                Confirmer
              </BasicButton>
              <BasicButton
                isGreen
                isSmall
                onClick={handleCancel}
              >
                Annuler
              </BasicButton>
            </ButtonGroup>
            : options.onSubmit
              ? <ButtonGroup>
                <BasicButton
                  isGreen
                  isSmall
                  isFilled
                  onClick={close}
                >
                  Ok
                </BasicButton>
              </ButtonGroup>
              : options.onAskCoOwnership
                ? <ButtonGroup>
                  <BasicButton
                    $isGreen
                    $isSmall
                    $isFilled
                    onClick={handleAskCoOwnership}
                  >
                    Envoyer la demande
                  </BasicButton>
                  <BasicButton
                    $isGreen
                    $isSmall
                    onClick={handleForcePatientCreation}
                  >
                    Créer la fiche
                  </BasicButton>
                </ButtonGroup>
                : null
          }
        </Styled.Message>
      </Styled.Content>
      <button onClick={close}><IconClose /></button>
    </Styled.AlertContainer >
  )
}

AlertBoxComponent.propTypes = {
  options: PropTypes.shape({
    type: PropTypes.string,
    onConfirm: PropTypes.func,
    onSubmit: PropTypes.func,
    onAskCoOwnership: PropTypes.func,
  }),
  message: PropTypes.string.isRequired,
  close: PropTypes.func,
}

export default AlertBoxComponent;
