import React from 'react'
import * as Ui from '../Component/Ui'
import styled from 'styled-components/macro'

export const KeywordsNotFoundMessage = ({ keywords = [], resourceType, align, paddingTop }) => {
  const kw = Object
    .keys(keywords)
    .reduce(function (obj, key) {
      keywords[key].forEach((val) => {
        obj[val] ? obj[val].push(key) : obj[val] = [key];
      });
      return obj;
    }, {})

    return Object.keys(kw).length > 0
    ? <Message paddingTop={paddingTop}>
      <Ui.BasicElement.P center={align === 'center'}>
        Certains termes n'ont retourné aucun résultat :
        {resourceType
          ? ` ${keywords[resourceType].join(', ')}`
          : Object
            .entries(kw)
            .map(([word, te]) =>
              ` "${word}"`
            )
            .join(', ')
        }
      </Ui.BasicElement.P>
    </Message>
    : null
}

export const Message = styled.div`
  margin-top: 0;
  animation: fadeIn 1s;
  ${Ui.BasicElement.P} {
    font-size: 1rem !important;
    padding: 0 2rem;
    padding-top: ${props => props.paddingTop ? '1rem' : '0'};
    text-align: center;
  }
  @keyframes fadeIn {
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
  }
`
