import React, { useState, useEffect, useCallback, useRef } from 'react'
import qs from 'qs'
import { Redirect, useLocation, Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { useAlert } from 'react-alert'
import { privateUrl, apiUrl, privateSitePrefix, dicomViewerUrl, dicomViewerS3Url, maxPatientsNumber, apiSymfony6Url } from '../Util/Config'
import { en } from '../Util/i18n';
import { mimeTypeIcon } from '../Util/MimeTypeIcons'
import { JJMMAAAAToDate } from '../Util/Format'
import { mesureInternetSpeed } from '../Util/InternetSpeed'
import * as Ui from './Ui'
import * as Menu from '../State/Menu'
import * as Login from '../State/Login';
import * as Patient from '../State/Patient';
import * as Dicom from '../State/Dicom';
import * as Comment from '../State/Comment';
import * as CustomField from '../State/CustomField';
import * as Cohort from '../State/Cohort';
import * as UploadFile from '../State/UploadFile';
import * as Resources from '../State/Resources';
import * as WorkList from '../State/WorkList';
import * as InvitationState from '../State/Invitation';
import * as Styled from './Patient.styled'
import Invitation from './Invitation';
import ClickOutside from '../Util/ClickOutside'
import pluralize from '../Util/Pluralize'
import CustomFieldViewer from './Ui/CustomFieldViewer'
import WithPermissions from './WithPermissions'
import LoadingBox from './Ui/LoadingBox'
import Editable from '../Util/Editable'
import UniqueDigitId from '../Util/UniqueDigitId';

const scrollToRef = (refCurrent) => window.scrollTo(0, refCurrent.offsetTop - 165)

const PatientComponent = (props) => {
  const alert = useAlert()
  const history = useHistory()
  const dispatch = useDispatch();

  const refs = useRef({});
  const location = useLocation();
  const currentUser = useSelector(Login.selectUser)
  const currentUserStatus = currentUser && currentUser.status

  const {
    data: {
      permissions: { value: permissions },
      patientStepDates: { value: patientStepDates },
      downloads,
      patientManagers,
    },
    data,
    search,
    loading,
    searching,
    currentSection,
    expertisesData: expertises,
    availableExpertises,
    requests,
    isEditMode,
    warningMessage,
    imageries: images,
    documents,
    errorMessage,
    infoMessage,
    deleted,
    pending,
    success,
    updateIdentitySuccess,
    creationSuccess,
    error: hasError,
    existingOwnedPatient,
  } = useSelector(state => state.patient)
  const isEditFields = useSelector(CustomField.selectIsViewerFieldEdit)
  const customFieldErrorMessage = useSelector(state => state.customField.errorMessage)
  const isInviting = useSelector(InvitationState.selectIsInviting)
  const {
    [Resources.RESOURCE_PATIENT_MANAGERS]: patientManagersResource,
  } = useSelector(Resources.selectResources)
  const resourceLoading = useSelector(Resources.selectLoading)
  const forms = useSelector(CustomField.selectFormFields)
  const {
    comments,
    sending: sendingComment,
    commentBoxOpen,
    data: {
      comment: commentData,
      comments: commentsData,
    },
  } = useSelector(state => state.comment)
  const hasQuestionnaire = useSelector(Cohort.selectHasQuestionnaire)

  const {
    match: {
      params: {
        id: patientId,
        expertiseId: teleExpertiseRefIdParam,
        sessionId
      }
    }
  } = props

  const currentUserOpinion = currentUser?.personalOpinion
  const queryParams = props.location.search
  const { c: fromCreatePatient, confirmCoOwnership } = qs.parse(queryParams, { ignoreQueryPrefix: true })
  const teleExpertiseIdParam = sessionId || teleExpertiseRefIdParam

  const currentRequest = requests[currentUser.id]
  let teleExpertiseId = null, teleExpertiseRefId = null, teleExpertiseType = null
  let requestState = ''

  // eslint-disable-next-line no-unused-vars
  const [_, teleExpertiseInPath] = location.pathname.match(/(staff|avis|cohorte)/) || []
  const isTeleExpertiseInPath = teleExpertiseInPath?.length > 0
  const fromOpinion = teleExpertiseInPath === 'avis';

  teleExpertiseType = isTeleExpertiseInPath
    ? en[teleExpertiseInPath]
    : Object.keys(currentRequest || {})[0]

  teleExpertiseType = teleExpertiseType === 'staffSession'
    ? 'staff'
    : teleExpertiseType

  const patientRequestExpertiseKey = teleExpertiseType === 'staff'
    ? 'staffSession'
    : teleExpertiseType

  teleExpertiseId = teleExpertiseIdParam ||
    Object.keys(((currentRequest || {})[patientRequestExpertiseKey] || {}))[0]

  const patientRequest = currentRequest?.[patientRequestExpertiseKey]?.[teleExpertiseId]
  teleExpertiseRefId = teleExpertiseRefIdParam || (patientRequest && (patientRequest.referenceTwo || patientRequest.reference))
  requestState = patientRequest?.state

  const canEdit = data?.permissions?.value?._canBeModified?.default

  const setEditField = (fieldName, value) =>
    dispatch(Patient.setEditField({ field: fieldName, value }))

  const onEnterField = (fieldName, canEdit) => () =>
    canEdit
      ? setEditField(fieldName, true)
      : null

  const onRestoreValue = (fieldName, value) => (e) => {
    dispatch(Patient.restoreInitialValue({ fieldName, value }))
    if (!data[fieldName].errors.length > 0)
      setEditField(fieldName, false)
  }

  const onLeaveField = (fieldName) => e => {
    if (data[fieldName].isFieldEdit && !e.currentTarget.contains(e.relatedTarget))
      if (data[fieldName].value !== data[fieldName].initialValue)
        dispatch(Patient.saveField({ fieldName }))
      else {
        dispatch(Patient.setEditField({ field: fieldName, value: false }))
      }
  }

  const onEnterFieldsBlock = (blockName, canEdit) => () => {
    return canEdit
      ? dispatch(Patient.setEditMode({ target: blockName, value: true }))
      : null
  }

  const onLeaveFieldsBlock = (blockName) => e => {
    if (isEditMode[blockName] && !e.currentTarget.contains(e.relatedTarget))
      dispatch(Patient.setEditMode({ target: blockName, value: false }))
  }

  const onKeyPress = e => {
    if (e.key === 'Enter') {
      dispatch(Patient.saveField({ fieldName: e.target.name }))
    }
    if (e.key === 'Escape') {
      onRestoreValue(e.target.name, data[e.target.name].initialValue)()
    }
  }

  const updateField = name => e => {
    dispatch(Patient.updateField({ name, value: e.target.value }))
  }

  const onImageRemove = ({ id }) => dispatch(Patient.removeImage(id))
  const onDocumentRemove = ({ id }) => dispatch(Patient.removeDocument(id))

  const teleExpertisesCount = `${expertises.length} ${pluralize('téléexpertise', expertises.length)}`

  useEffect(() => {
    dispatch(Dicom.checkDownloads(
      Object
        .entries(downloads.value)
        .filter(([_, isAvailable]) => !isAvailable)
        .map(([key, _]) => key)
    ));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(Menu.changeActive(Menu.PATIENTS))
  }, [dispatch])

  useEffect(() => {
    dispatch(Patient.fetch({ patientId, confirmCoOwnership }))
    dispatch(Comment.fetch({ patientId, teleExpertiseId }))

    return () => {
      dispatch(Patient.cleanAll())
      dispatch(Comment.clean())
      dispatch(CustomField.clean())
      dispatch(UploadFile.cleanAll())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, patientId, confirmCoOwnership])

  useEffect(() => {
    if (existingOwnedPatient) {
      alert.info('Le patient existe déjà.', { timeout: 5000 })
      dispatch(Patient.resetCreationFlags())
    }
    if (errorMessage)
      alert.error(errorMessage, { timeout: 5000 })
    if (infoMessage)
      alert.info(infoMessage, { timeout: 5000 })
    if (customFieldErrorMessage)
      alert.error(customFieldErrorMessage, { timeout: 5000 })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessage, infoMessage, customFieldErrorMessage, existingOwnedPatient, alert])

  useEffect(() => {
    if (warningMessage)
      alert.info(warningMessage, { timeout: 5000 })
  }, [warningMessage, alert])

  useEffect(() => {
    if (
      currentUser &&
      'NON_VERIFIED' === currentUserStatus &&
      currentUser.createdForOverAMonth
    ) {
      if (!currentUser.uploadPractitionerProof) {
        alert.show(<div>Téléchargez votre justificatif professionnel pour valider votre compte et ajouter de nouveaux patients.</div>, { timeout: 10000 })
      } else {
        alert.show('Votre justificatif professionnel est en cours de validation.', { timeout: 10000 })
      }

      history.push(`${privateSitePrefix}/profil?tab=1`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alert, currentUser, currentUserStatus, history])

  const [mandatoryFieldsWarning, setMandatoryFieldsWarning] = useState([])

  useEffect(() => {
    const formsWarnings = forms
      .map(({ name, id, fields: { byId } }) => ({
        name,
        id,
        content: Object.values(byId)
          .map(field => ({
            value: field.value,
            mandatory: field.mandatory,
            hasMotherPatientSection: field.hasMotherPatientSection
          }))
          .filter(field => field.mandatory && field?.value?.length === 0 && !field.hasMotherPatientSection)
      }))
      .filter(form => form?.content?.length)
      .map(form => ({
        message: `${form?.name} (${form?.content?.length} ${pluralize('champ', form?.content?.length)})`,
        teleExpertiseId: form.id,
      }))
    setMandatoryFieldsWarning(formsWarnings)
  }, [forms])

  const updateDateField = name => e => {
    let val = e.target.value;

    if (val.length === 2) {
      val += '/';
    } else if (val.length === 5) {
      val += '/';
    }
    dispatch(Patient.updateField({ name, value: val }))
  }

  if (deleted) {
    return <Redirect to={`${privateSitePrefix}/patients`} />
  }

  if (success && fromCreatePatient && !updateIdentitySuccess) {
    return <Redirect to={`${privateSitePrefix}/patients/${patientId}`} />
  }

  const navLinks = [
    { Icon: <Ui.Icon.IconInfos />, label: 'Fiche patient', section: Patient.SECTION_SUMMARY },
    { Icon: <Ui.Icon.IconShare />, label: teleExpertisesCount, section: Patient.SECTION_TELE_EXPERTISE },
    {
      Icon: <Ui.Icon.IconDoctor />,
      label: `${patientManagers.value.length + 1} ${pluralize('médecin', patientManagers.value.length + 1)}`, section: Patient.SECTION_COLLEAGUE
    },
    { Icon: <Ui.Icon.IconChat />, label: `${comments.length} ${pluralize('commentaire', comments.length)}`, section: Patient.SECTION_COMMENT },
  ]

  const onScroll = (id) => scrollToRef(refs.current[id])

  const patientNotificationRead = () => {
    history.push(`${privateSitePrefix}/patients`);
    dispatch(Patient.resetCreationFlags())
  }

  const showWarningBanner = currentSection === Patient.SECTION_SUMMARY &&
    (mandatoryFieldsWarning.length > 0 || creationSuccess)

  return (
    <>
      <LoadingBox />
      <Ui.Layout.PageLayout currentUser={currentUser}>
        <Styled.PatientContainer>
          <>
            {!loading || hasError
              ? !permissions._canBe.seenWithTeleExpertiseLimitPatient
                ? <Ui.Layout.FlexContainer>
                  <Styled.TeleExpertiseLimitPatientReachedText>
                    Vous avez atteint la limite du nombre de patients partagés via une téléexpertise gratuite.
                    <br /> <Link to={`{${privateSitePrefix}/profil?tab=5`}>Upgradez</Link> votre compte pour bénéficier d'un nombre illimité de patients.
                  </Styled.TeleExpertiseLimitPatientReachedText>
                </Ui.Layout.FlexContainer>
                : <Ui.Layout.PageContentWrapper>
                  <Identity
                    patientId={patientId}
                    updateField={updateField}
                    requestState={requestState}
                    data={data}
                    dispatch={dispatch}
                    expertises={expertises}
                    teleExpertisesCount={teleExpertisesCount}
                    alert={alert}
                    permissions={permissions}
                    teleExpertiseRefId={teleExpertiseRefId}
                    teleExpertiseType={teleExpertiseType}
                    history={history}
                    isTeleExpertiseInPath={isTeleExpertiseInPath}
                    isEditMode={isEditMode}
                    updateDateField={updateDateField}
                    currentSection={currentSection}
                    fromCreatePatient={fromCreatePatient}
                    onEnterField={onEnterField}
                    onRestoreValue={onRestoreValue}
                    onLeaveField={onLeaveField}
                    onEnterFieldsBlock={onEnterFieldsBlock}
                    canEdit={canEdit}
                    patientManagersResource={patientManagersResource}
                  />
                  <Ui.Layout.Content hasPanel noPadding isPatientCreated>
                    <Ui.Layout.NavContainer>
                      <nav role="navigation">
                        {fromCreatePatient
                          ? <Styled.NavLinkNoLink
                            on={currentSection === Patient.SECTION_SUMMARY}
                          >
                            <Ui.Icon.IconInfos />
                            Compléter la fiche patient
                          </Styled.NavLinkNoLink>
                          : <>
                            {navLinks
                              .map(({ Icon, label, section }, idx) =>
                                <Ui.Layout.NavLink
                                  key={idx}
                                  onClick={() => dispatch(Patient.setCurrentSection(section))}
                                  on={currentSection === section}
                                >
                                  {Icon}{label}
                                </Ui.Layout.NavLink>
                              )}
                          </>
                        }
                      </nav>
                      <WithPermissions domains={['summarySheet']} permissions={permissions}>
                        {currentSection === Patient.SECTION_COMMENT && fromOpinion
                          ? <Styled.NavbarButton
                            onClick={() => {
                              dispatch(WorkList.selectPatient({ selectedPatientId: patientId }))
                              history.push(`${privateSitePrefix}/avis/${teleExpertiseRefId}?tab=3&mode=Planning`)
                            }}>
                            <Ui.Icon.IconCalendar />
                            Programmer
                          </Styled.NavbarButton>
                          : null
                        }
                      </WithPermissions>
                    </Ui.Layout.NavContainer>
                    {showWarningBanner &&
                      <Styled.MandatoryCustomFieldMessageContainer isPatientCreated>
                        <Ui.Layout.FlexBox>
                          <Ui.Layout.FlexBox flow="row wrap">
                            {creationSuccess
                              ? <Styled.AddPatientSuccessMessage>
                                <span>Votre patient a bien été créé. Merci de compléter sa fiche.</span>
                                <div><Ui.Icon.IconOk />
                                  <Styled.WarningLink isFilled isGreen onClick={patientNotificationRead}>
                                    C'est fait ! Afficher mes patients
                                  </Styled.WarningLink>
                                </div>
                              </Styled.AddPatientSuccessMessage>
                              : mandatoryFieldsWarning.length > 0 &&
                              <div>
                                Merci de renseigner le(s) champ(s) obligatoire(s) sur :
                                {mandatoryFieldsWarning
                                  .map(({ message, teleExpertiseId }, idx) =>
                                    <React.Fragment key={`mandatory_fields_${idx}`}>
                                      <Styled.WarningLink
                                        onClick={() => {
                                          onScroll(`cf_${teleExpertiseId}`)
                                        }}
                                      >
                                        {message}
                                      </Styled.WarningLink>
                                    </React.Fragment >
                                  ).reduce((acc, elem) => {
                                    return acc ? [...acc, ',', elem] : [elem]
                                  }, null)
                                }
                              </div>
                            }
                          </Ui.Layout.FlexBox>
                        </Ui.Layout.FlexBox>
                      </Styled.MandatoryCustomFieldMessageContainer>
                    }
                    <Ui.Layout.PaddedContent>
                      <Summary
                        data={data}
                        updateField={updateField}
                        updateDateField={updateDateField}
                        dispatch={dispatch}
                        images={images}
                        documents={documents}
                        currentSection={currentSection}
                        patientId={patientId}
                        isEditMode={isEditMode}
                        onImageRemove={onImageRemove}
                        onDocumentRemove={onDocumentRemove}
                        teleExpertiseRefId={teleExpertiseRefIdParam}
                        teleExpertiseId={teleExpertiseId}
                        permissions={permissions}
                        teleExpertiseType={teleExpertiseType}
                        isTeleExpertiseInPath={isTeleExpertiseInPath}
                        requestState={requestState}
                        alert={alert}
                        forms={forms}
                        warningMessage={warningMessage}
                        hasQuestionnaire={hasQuestionnaire}
                        patientStepDates={patientStepDates}
                        fromCreatePatient={fromCreatePatient}
                        isEditFields={isEditFields}
                        expertises={expertises}
                        onEnterField={onEnterField}
                        onRestoreValue={onRestoreValue}
                        onLeaveField={onLeaveField}
                        onKeyPress={onKeyPress}
                        refs={refs}
                        canEdit={canEdit}
                      />
                      <TeleExpertises
                        updateField={updateField}
                        data={data}
                        dispatch={dispatch}
                        permissions={permissions}
                        currentSection={currentSection}
                        availableExpertises={availableExpertises}
                        expertises={expertises}
                        pending={pending}
                        search={search}
                        searching={searching}
                      />
                      <Colleagues
                        data={data}
                        dispatch={dispatch}
                        currentSection={currentSection}
                        isEditMode={isEditMode}
                        patientManagersResource={patientManagersResource}
                        expertises={expertises}
                        patientId={patientId}
                        currentUser={currentUser}
                        permissions={permissions}
                        resourceLoading={resourceLoading}
                        search={search}
                        onEnterFieldsBlock={onEnterFieldsBlock}
                        onLeaveFieldsBlock={onLeaveFieldsBlock}
                        isInviting={isInviting}
                        currentUserOpinion={currentUserOpinion}
                      />
                      <Comments
                        expertises={expertises}
                        comments={comments}
                        dispatch={dispatch}
                        commentData={commentData}
                        currentSection={currentSection}
                        commentsData={commentsData}
                        onDocumentRemove={onDocumentRemove}
                        patientId={patientId}
                        sendingComment={sendingComment}
                        commentBoxOpen={commentBoxOpen}
                        teleExpertiseId={teleExpertiseId}
                        teleExpertiseRefId={teleExpertiseRefId}
                        isTeleExpertiseInPath={isTeleExpertiseInPath}
                        alert={alert}
                        currentUser={currentUser}
                        teleExpertiseType={teleExpertiseType}
                        requestState={requestState}
                      />
                    </Ui.Layout.PaddedContent>
                  </Ui.Layout.Content>
                </Ui.Layout.PageContentWrapper>
              : <Ui.Layout.FlexContainer><Ui.Global.Loader /></Ui.Layout.FlexContainer>
            }
          </>
        </Styled.PatientContainer>
      </Ui.Layout.PageLayout>
    </>
  )
}

const Identity = ({
  data,
  dispatch,
  patientId,
  teleExpertisesCount,
  expertises,
  alert,
  permissions,
  history,
  teleExpertiseRefId,
  teleExpertiseType,
  isTeleExpertiseInPath,
  requestState,
  isEditMode,
  updateField,
  updateDateField,
  currentSection,
  fromCreatePatient,
  onEnterField,
  onRestoreValue,
  onLeaveField,
  onEnterFieldsBlock,
  canEdit,
  patientManagersResource,
}) => {
  const {
    firstname,
    lastname,
    fullname,
    fullBirthdate,
    birthdate,
    doctor: doctorId,
    gender,
    createdAt,
    updatedAt,
    diagnostic,
    patientManagers,
  } = data;

  const [showSexIcons, setShowSexIcons] = useState(false)

  const onDeletePatient = (e, patientId) => {
    alert.show('Confirmez-vous la suppression de ce patient ?', {
      onConfirm: (e) => dispatch(Patient.deletePatient(patientId))
    })
  }

  const keyPressFunc = (e) => {
    if (e.which === 8) {
      let val = birthdate.value;

      if (val.length === 3 || val.length === 6) {
        val = val.slice(0, val.length - 1);
        dispatch(Patient.updateField({ name: 'birthdate', value: val }))
      }
    }
  }

  const sendGender = e => {
    dispatch(Patient.sendGender())
    setShowSexIcons(false)
  }

  const updateSexField = ({ name, value }) => {
    dispatch(Patient.updateField({ name, value }))
  }

  const onEditIdentity = () => setShowSexIcons(true)

  const onLeaveFullnameBlock = (blockName) => e => {
    if (isEditMode[blockName] && !e.currentTarget.contains(e.relatedTarget)) {
      dispatch(Patient.saveField({ fieldName: 'firstname' }))
      dispatch(Patient.saveField({ fieldName: 'lastname' }))
      dispatch(Patient.updateField({
        name: 'fullname',
        value: `${firstname.value.charAt(0).toUpperCase() + firstname.value.slice(1).replace(/  +/g, ' ')} ${lastname.value.toUpperCase().replace(/  +/g, ' ')}`
      }))
      dispatch(Patient.setEditMode({ target: blockName, value: false }))
    }
  }

  const refs = useRef({});

  const doctor = patientManagersResource.find(manager => manager.id === doctorId.value)

  return (
    <Styled.PanelContainer>
      <Ui.Layout.Panel>
        <header>
          <Ui.Button.BackButton onClick={history.goBack} />
          <WithPermissions to={['deleted']} permissions={permissions}>
            <Ui.Button.ActionButton
              type="button"
              onClick={(e) => onDeletePatient(e, patientId)}
            >
              <Ui.Icon.IconTrash />
            </Ui.Button.ActionButton>
          </WithPermissions>
        </header>
        <Styled.LeftPanelHeader>
          <Editable
            title=""
            content={
              <Ui.BasicElement.H1>
                {fullname.value}
              </Ui.BasicElement.H1>
            }
            isEditMode={isEditMode.fullName}
            onClick={onEnterFieldsBlock('fullName', canEdit)}
            onBlur={onLeaveFullnameBlock('fullName')}
            childRef={refs}
            canEdit={canEdit}
            text
          >
            <Ui.Layout.FlexBox>
              <Styled.EditableNameInput
                htmlFor="firstname"
                value={firstname.value}
                onChange={updateField('firstname')}
                error={firstname.errors[0]}
                refs={refs}
                tabIndex="7"
                noSubmit
              />
              <Styled.EditableNameInput
                htmlFor="lastname"
                value={lastname.value}
                onChange={updateField('lastname')}
                error={lastname.errors[0]}
                tabIndex="8"
                noSubmit
              />
            </Ui.Layout.FlexBox>
          </Editable>
          <Editable
            title=""
            text={isEditMode.birthdate ? birthdate.value : fullBirthdate.value}
            isEditMode={birthdate.isFieldEdit}
            onClick={onEnterField('birthdate', canEdit)}
            onBlur={onLeaveField('birthdate')}
            childRef={refs}
            canEdit={canEdit}
          >
            <Styled.EditableDateInput
              name="birthdate"
              value={birthdate.value}
              onChange={updateDateField('birthdate', canEdit)}
              onBlur={onLeaveField('birthdate')}
              onRestoreValue={onRestoreValue('birthdate', birthdate.initialValue)}
              onKeyDown={keyPressFunc}
              maxLength="10"
              refs={refs}
              tabIndex="9872"
              error={birthdate.errors[0]}
              canEdit={canEdit}
            />
          </Editable>
        </Styled.LeftPanelHeader>
        {showSexIcons &&
          <Styled.SexIconListContainer>
            <span>Sexe :</span>
            <Styled.SexIconList>
              <Ui.SearchBar.PlainRoundIcon
                onClick={e => {
                  updateSexField({ name: 'gender', value: 'male' })
                  sendGender(e)
                  setShowSexIcons(false)
                }}
                title="Homme"
              >
                <Ui.Icon.IconMan />
              </Ui.SearchBar.PlainRoundIcon>
              <Ui.SearchBar.PlainRoundIcon
                onClick={e => {
                  updateSexField({ name: 'gender', value: 'female' })
                  sendGender(e)
                  setShowSexIcons(false)
                }}
                title="Femme"
              >
                <Ui.Icon.IconWoman />
              </Ui.SearchBar.PlainRoundIcon>
              <Ui.SearchBar.PlainRoundIcon
                onClick={e => {
                  updateSexField({ name: 'gender', value: 'undetermined' })
                  sendGender(e)
                  setShowSexIcons(false)
                }}
                title="Indéterminé"
              >
                <Ui.Icon.IconManWoman />
              </Ui.SearchBar.PlainRoundIcon>
            </Styled.SexIconList>
          </Styled.SexIconListContainer>
        }
        <Styled.IdentityEditableSectionContainer>
          <Ui.SearchBar.PlainRoundIcon onClick={onEditIdentity}>
            {gender.value === 'male'
              ? <Ui.Icon.IconMan />
              : gender.value === 'female'
                ? <Ui.Icon.IconWoman />
                : <Ui.Icon.IconManWoman />
            }
          </Ui.SearchBar.PlainRoundIcon>
          <div>
            <Ui.BasicElement.P bold dark>
              Ajouté le {createdAt.value}
            </Ui.BasicElement.P>
            <Ui.BasicElement.P small light>
              MAJ : {updatedAt.value}
            </Ui.BasicElement.P>
          </div>
        </Styled.IdentityEditableSectionContainer>
        {diagnostic.value &&
          <Styled.IdentitySectionContainer>
            <Ui.SearchBar.PlainRoundIcon>
              <Ui.Icon.IconInfos />
            </Ui.SearchBar.PlainRoundIcon>
            <div>
              <Ui.BasicElement.P bold dark>
                {diagnostic.value}
              </Ui.BasicElement.P>
            </div>
          </Styled.IdentitySectionContainer>
        }
        <Styled.IdentitySectionContainer>
          <Ui.SearchBar.PlainRoundIcon>
            <Ui.Icon.IconDoctor />
          </Ui.SearchBar.PlainRoundIcon>
          <div>
            <Ui.BasicElement.P bold medium light>
              Suivi(e) par :
            </Ui.BasicElement.P>
            <Ui.BasicElement.P bold dark>{`${doctor?.firstname} ${doctor?.lastname}`}</Ui.BasicElement.P>
            <Ui.BasicElement.P bold medium light>
              Partagé(e) avec {patientManagers.value.length} {pluralize('confrère', patientManagers.value.length)}
            </Ui.BasicElement.P>
          </div>
        </Styled.IdentitySectionContainer>
        <Styled.IdentitySectionContainer>
          <Ui.SearchBar.PlainRoundIcon>
            <Ui.Icon.IconShare />
          </Ui.SearchBar.PlainRoundIcon>
          <div>
            <Ui.BasicElement.P bold medium light>Partagé(e) via :</Ui.BasicElement.P>
            <Ui.BasicElement.P bold dark>
              {teleExpertisesCount}
            </Ui.BasicElement.P>
            <Ui.Card.TelexpertiseContainer>
              <Ui.Card.AvisBlock
                isFilled={expertises.filter(el => el.type === 'opinions').length > 0}
              >
                <Ui.Icon.IconAvis />
                <strong>{expertises.filter(el => el.type === 'opinions').length}</strong>
              </Ui.Card.AvisBlock>
              <Ui.Card.StaffBlock
                isFilled={expertises.filter(el => el.type === 'staffs').length > 0}
              >
                <Ui.Icon.IconMessage />
                <strong>{expertises.filter(el => el.type === 'staffs').length}</strong>
              </Ui.Card.StaffBlock>
              <Ui.Card.CohorteBlock
                isFilled={expertises.filter(el => el.type === 'cohorts').length > 0}
              >
                <Ui.Icon.IconCohorte />
                <strong>{expertises.filter(el => el.type === 'cohorts').length}</strong>
              </Ui.Card.CohorteBlock>
            </Ui.Card.TelexpertiseContainer>
          </div>
        </Styled.IdentitySectionContainer>
        {!fromCreatePatient &&
          teleExpertiseRefId &&
          teleExpertiseType !== 'cohort' &&
          (requestState !== 'done' || isTeleExpertiseInPath) &&
          currentSection !== Patient.SECTION_COMMENT
          ? <Styled.AnswerButton
            isFilled
            isGreen
            title={!teleExpertiseRefId || (!teleExpertiseRefId && requestState === 'done')
              ? 'Nouveau commentaire'
              : teleExpertiseType === 'staff'
                ? 'Nouvel avis de staff'
                : 'Répondre à la demande d\'avis'
            }
            type="button"
            onClick={() => {
              dispatch(Patient.setCurrentSection(Patient.SECTION_COMMENT))
              dispatch(Comment.setCommentBoxOpen('default'))
            }}
          >
            <Ui.Icon.IconChat />
            Répondre
          </Styled.AnswerButton>
          : null
        }
      </Ui.Layout.Panel>
    </Styled.PanelContainer>
  )
}

const Summary = ({
  data: {
    studies: { value: studies },
    downloads,
  },
  data,
  documents,
  forms,
  updateField,
  images,
  dispatch,
  currentSection,
  isEditMode,
  patientId,
  onImageRemove,
  permissions,
  alert,
  warningMessage,
  onDocumentRemove,
  hasQuestionnaire,
  fromCreatePatient,
  patientStepDates,
  isEditFields,
  expertises,
  teleExpertiseRefId,
  onEnterField,
  onRestoreValue,
  onLeaveField,
  onKeyPress,
  refs,
}) => {
  const { anamneses } = data;
  const fileKey = useSelector(Dicom.selectFileKey)
  const { dicomFiles } = useSelector(Dicom.selectData)
  const dicomPending = useSelector(Dicom.selectPending)
  const isUploadComplete = useSelector(Dicom.selectIsUploadComplete)
  const errorMessageSeen = useSelector(Dicom.selectErrorMessageSeen)
  const uploaded = useSelector(Dicom.selectUploaded)
  const uploadedError = useSelector(Dicom.selectUploadedError)
  const totalFiles = useSelector(Dicom.selectTotalFiles)
  const currentDownloadId = useSelector(Dicom.selectCurrentDownloadId)

  const canEdit = data?.permissions?.value?._canBeModified?.summarySheet

  useEffect(() => {
    if (fromCreatePatient) {
      dispatch(Patient.setEditMode({ target: 'patientFile', value: true }))
      dispatch(Patient.setEditMode({ target: 'customFields', value: true }))
      Object
        .entries(isEditFields)
        .map(([teleExpertiseType, values]) =>
          Object.keys(values)
            .forEach(teleExpertiseId => {
              dispatch(CustomField.setEditFields({
                teleExpertiseType,
                teleExpertiseId,
                target: 'customFields',
                value: true
              }))
              dispatch(CustomField.setEditFields({
                teleExpertiseType,
                teleExpertiseId,
                target: 'labels',
                value: true
              }))
            })
        )
    }
  })

  const [checkSlowConnection, setCheckSlowConnection] = useState(false);

  useEffect(() => {

    if (checkSlowConnection && !isUploadComplete) {
      alert.show('Votre connexion semble lente, réessayer ultérieurement l\'ajout d\'examen ?', {
        onConfirm: (e) => {
          dispatch(Dicom.abortUpload())
        }
      })

      setCheckSlowConnection(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkSlowConnection]);

  useEffect(() => {
    if ((uploaded + 1) === totalFiles) {
      dispatch(Dicom.generateDicomViewerJson({ patientId, fileKey }))
    }
  }, [uploaded]);

  useEffect(() => {
    if (
      isUploadComplete &&
      !errorMessageSeen &&
      uploadedError > ((totalFiles * 80) / 100)
    ) {
      dispatch(Dicom.setErrorMessageSeen())

      mesureInternetSpeed(function (internetSpeedResult) {
        if (internetSpeedResult.speedMbps < 10) {
          alert.show('Votre connexion est trop lente pour l\'upload de DICOM, réessayez ultérieurement')
        } else {
          alert.show('Vous avez rencontré un problème pour l\'upload de DICOM, réessayez ultérieurement')
        }
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUploadComplete]);

  function DicomDropzone({ disabled }) {
    const onDrop = useCallback((inputFiles) => {
      const dicomFilesKey = Math.floor(Math.random() * 99999)
      dispatch(Dicom.setFileKey(dicomFilesKey))

      const notAcceptedFiles = ['DICOMDIR', 'VERSION', 'LOCKFILE']
      const acceptedFiles = inputFiles
        .filter(file => !notAcceptedFiles.includes(file.name))
        .filter(({ type }) => !/image\//.test(type))

      if (acceptedFiles.length > 0) {
        alert.info('Vous pouvez continuer à naviguer pendant le chargement', { timeout: 5000 })
        dispatch(Dicom.initUpload({ downloadType: 'dicom' }))
      }

      setTimeout(() => {
        setCheckSlowConnection(true)
      }, 60000)

      acceptedFiles
        .filter(file => !notAcceptedFiles.includes(file.name))
        .filter(({ type }) => !/image\//.test(type))
        .forEach(file => {
          const reader = new FileReader()
          reader.onabort = () => console.log('file reading was aborted')
          reader.onerror = () => console.log('file reading has failed')
          reader.onload = () => {
            dispatch(Dicom.uploadAndLinkStudy({
              file: reader.result,
              fileForSymfony6: file,
              dicomFilesKey,
              patientId,
              totalFiles: acceptedFiles
                .filter(file => !notAcceptedFiles.includes(file.name))
                .filter(({ type }) => !/image\//.test(type))
                .length
                + 1 // Inclusion de la génération du fichier DICOM JSON de la nouvelle version du viewer
            }))
          }
          reader.readAsArrayBuffer(file)
        })
    }, [])
    const isLoading = dicomPending || !isUploadComplete
    const { getRootProps, getInputProps } = useDropzone({ onDrop, disabled: disabled || isLoading })

    return (
      <Styled.DropzoneSection disabled={disabled}>
        <div {...getRootProps({ className: 'dropzone disabled' })}>
          <input {...getInputProps()} directory="" webkitdirectory="" mozdirectory="" />
          {isLoading
            ? <Ui.Layout.FlexBox flow="row wrap">
              <Ui.Global.Loader />
            </Ui.Layout.FlexBox>
            : !disabled
              ? <p>Ajouter un examen<br />(DICOM)</p>
              : null
          }
        </div>
      </Styled.DropzoneSection>
    )
  }

  function ImageDropzone({ disabled }) {
    const onDrop = useCallback((inputFiles) => {
      const acceptedFiles = inputFiles
        .filter(({ type }) => /image\//.test(type))
      if (acceptedFiles.length > 0) {
        dispatch(Dicom.initUpload({ downloadType: 'image' }))
      }

      acceptedFiles
        .filter(({ type }) => /image\//.test(type))
        .forEach(file => {
          file.sending = true
          const reader = new FileReader()
          reader.onabort = () => console.log('file reading was aborted')
          reader.onerror = () => console.log('file reading has failed')
          reader.onload = () => {
            dispatch(UploadFile.upload({
              file,
              uploader: 'imagerie',
            }))
          }
          reader.readAsDataURL(file)
        })
    }, [])
    const isLoading = dicomPending || !isUploadComplete
    const { getRootProps, getInputProps } = useDropzone({ onDrop, multiple: false, disabled: disabled || isLoading })

    return (
      <Styled.DropzoneSection disabled={disabled}>
        <div {...getRootProps({ className: 'dropzone disabled' })}>
          <input {...getInputProps()} />
          {!disabled
            ? <p>Ajouter une image</p>
            : null
          }
        </div>
      </Styled.DropzoneSection>
    )
  }

  const onRemoveDicomStudy = (e, study) => {
    e.stopPropagation()
    alert.show('Confirmez-vous la suppression de cet examen ?', {
      onConfirm: (e) => {
        dispatch(Dicom.removeStudy(study))
      }
    })
  }

  const onRemoveDicomS3 = (e, patientId, fileKey) => {
    e.stopPropagation()
    alert.show('Confirmez-vous la suppression de cet examen ?', {
      onConfirm: (e) => {
        dispatch(Dicom.removeDicomS3({ patientId, fileKey }))
      }
    })
  }

  const onDisplayViewer = studyId => {
    const studyUrl = `${dicomViewerUrl}/${dicomFiles.value[studyId].studyUid}`
    dispatch(Dicom.openViewerDicom({ url: studyUrl }))
  }

  const dicomLink = studyId => `${apiUrl}/patients/download-dicom-study-zip?studies=${studyId}&zipFileName=${dicomFiles.value[studyId].fileName}.zip`

  const anamnesesRef = useRef()

  const DicomPreviewList = ({ canEdit }) => studies
    .filter(studyId => dicomFiles.value[studyId])
    .map((studyId, idx) =>
      <Ui.DicomPreview.DicomPreview
        key={`dicom_preview_${idx}`}
        onClickCard={() => onDisplayViewer(studyId)}
        fileName={dicomFiles.value[studyId].fileName}
        studyUid={dicomFiles.value[studyId].studyUid}
        onRemoveDicom={e => onRemoveDicomStudy(e, studyId)}
        currentDownloadId={currentDownloadId}
        title={dicomFiles.value[studyId].formattedStudyDate}
        subTitle={dicomFiles.value[studyId].studyDescription}
        modalities={dicomFiles.value[studyId].studyModalities}
        downloadLink={downloads.value[studyId] ? dicomLink(studyId) : null}
        canEdit={canEdit}
      />
    )

  const DicomS3PreviewList = ({ canEdit }) => data.dicomFiles.value
    .map((dicomFile, idx) => {
      return <Ui.DicomPreview.DicomS3Preview
        key={`dicom_preview_${idx}`}
        onRemoveDicom={e => onRemoveDicomS3(e, patientId, dicomFile.key)}
        onClickCard={() => window.open(`${dicomViewerS3Url}/viewer/dicomjson?url=${privateUrl.includes('localhost') ? apiSymfony6Url : dicomViewerS3Url}/patients/${patientId}/json-dicom/${dicomFile.key}`, '_blank')}
        patientId={patientId}
        fileKey={dicomFile.key}
        description={dicomFile.description}
        date={dicomFile.date}
        canEdit={canEdit}
      />
    })

  return (currentSection === Patient.SECTION_SUMMARY) && (
    <>
      <Ui.Layout.Section>
        <Ui.BasicElement.H2 id="summary">
          Résumé clinique
        </Ui.BasicElement.H2>
        <Ui.Layout.Row>
          <Ui.Form.EditableTextInput
            name="diagnostic"
            label="Diagnostic"
            data={data}
            altText="Ajouter un diagnostic"
            altTextDisabledField="Aucun diagnostic n'a été renseigné pour ce patient"
            updateField={updateField}
            onEnterField={onEnterField}
            onLeaveField={onLeaveField}
            onRestoreValue={onRestoreValue}
            onKeyPress={onKeyPress}
            refs={refs}
            tabIndex="3"
            canEdit={canEdit}
          />
        </Ui.Layout.Row>
        <Ui.Layout.Row>
          <Editable
            title="Anamnèse"
            text={anamneses.value}
            isEditMode={anamneses.isFieldEdit}
            onClick={onEnterField('anamneses', canEdit)}
            onBlur={onLeaveField('anamneses')}
            childRef={anamnesesRef}
            altTextDisabledField="Aucune anamnese n'a été renseignée pour ce patient"
            altText={canEdit
              ? `Ajouter une anamnèse`
              : `Aucune anamnèse n'a été ajoutée pour ce patient`
            }
            canEdit={canEdit}
          >
            <Ui.Form.TextAreaInputResizable
              name="anamneses"
              label="Anamnèse"
              value={anamneses.value}
              onChange={updateField('anamneses')}
              onBlur={onLeaveField('anamneses')}
              onRestoreValue={onRestoreValue('anamneses', anamneses.initialValue)}
              error={anamneses.errors[0]}
              inputRef={anamnesesRef}
            />
          </Editable>
        </Ui.Layout.Row>
        <Ui.Layout.Section>
          <Ui.BasicElement.H3>Imagerie</Ui.BasicElement.H3>
          <Styled.MediasContainer flow="row wrap">
            <WithPermissions domains={['summarySheet']} permissions={permissions}>
              <Ui.UploadFile.ImagesUpload
                hideInputButton
                name="imagerie"
                id="imagerie"
                htmlFor="imagerie"
                endpoint={`/patients/${patientId}/imageries`}
                validationRules={["noLargeFiles", "noEmptyFiles", "acceptedImageFormat"]}
                label=""
                value={images}
                disabled={!canEdit}
                onRemove={onImageRemove}
                isEditMode={canEdit}
                noLocalWarning
              />
            </WithPermissions>
            <ImageDropzone disabled={!canEdit} />
          </Styled.MediasContainer>
          <Styled.MediasContainer>
            {studies.length > 0 && studies.some(studyId => dicomFiles.value[studyId])
              ? <Ui.Layout.FlexBox flow="row wrap">
                <DicomPreviewList canEdit={canEdit} />
              </Ui.Layout.FlexBox>
              : null
            }
            {data.dicomFiles.value.length > 0
              ? <Ui.Layout.FlexBox flow="row wrap">
                <DicomS3PreviewList canEdit={canEdit} />
              </Ui.Layout.FlexBox>
              : null
            }
            <DicomDropzone disabled={!canEdit} />
          </Styled.MediasContainer>
        </Ui.Layout.Section>
      </Ui.Layout.Section >
      <ExtraInformations
        updateField={updateField}
        dispatch={dispatch}
        data={data}
        expertises={expertises}
        currentSection={currentSection}
        permissions={permissions}
        forms={forms}
        documents={documents}
        warningMessage={warningMessage}
        patientId={patientId}
        onDocumentRemove={onDocumentRemove}
        isEditFields={isEditFields}
        isEditMode={isEditMode}
        hasQuestionnaire={hasQuestionnaire}
        patientStepDates={patientStepDates}
        fromCreatePatient={fromCreatePatient}
        teleExpertiseRefId={teleExpertiseRefId}
        onEnterField={onEnterField}
        onRestoreValue={onRestoreValue}
        onLeaveField={onLeaveField}
        onKeyPress={onKeyPress}
        refs={refs}
        canEdit={canEdit} />
    </>
  )
}

const ExtraInformations = ({
  updateField,
  forms,
  data,
  documents,
  patientId,
  warningMessage,
  onDocumentRemove,
  isEditFields,
  hasQuestionnaire,
  patientStepDates,
  expertises,
  teleExpertiseRefId,
  onEnterField,
  onRestoreValue,
  onLeaveField,
  onKeyPress,
  refs,
  canEdit,
}) => {
  const {
    medicalId,
    email,
    cohortsPatientStepsByCustomFieldSectionId
  } = data;

  const sortedForms = forms
    .map(form => {
      const step = cohortsPatientStepsByCustomFieldSectionId.value
      return Object
        .entries(form)
        .reduce((acc, [key, value]) => ({
          ...acc,
          [key]: key === 'sections' &&
            Object.keys(step).length > 0
            ? {
              ...value,
              allIds: Object
                .values(value.byId)
                .sort((a, b) =>
                  JJMMAAAAToDate(step[a.id] ? step[a.id].date : '') - JJMMAAAAToDate(step[b.id] ? step[b.id].date : ''))
                .map(el => el.id)
            }
            : value,
        }), {})
    })

  return (
    <Ui.Layout.Section>
      {medicalId.value &&
        <>
          <Ui.BasicElement.H3>Identifiant</Ui.BasicElement.H3>
          <Ui.BasicElement.P>{medicalId.value}</Ui.BasicElement.P>
        </>
      }
      {documents.length > 0 &&
        <Ui.Layout.BlockContainer>
          <Ui.UploadFile.DocumentsUpload
            name="documents"
            id="documents"
            validationRules={["noLargeFiles", "noEmptyFiles", "acceptedDocumentFormat"]}
            label="Documents"
            endpoint="/attachments/new/assets"
            saveEndpoint={`/attachments/patients/${patientId}`}
            value={documents}
            disabled={true}
            isEditMode={false}
          />
        </Ui.Layout.BlockContainer>
      }
      {warningMessage && forms.length > 0 &&
        <Ui.BasicElement.P>
          {warningMessage}
        </Ui.BasicElement.P>
      }
      {hasQuestionnaire &&
        <Editable
          title="Email"
          text={!email.value && !email.isFieldEdit
            ? 'Aucun email n\'est associé à ce patient'
            : email.value || ''}
          isEditMode={email.isFieldEdit}
          onClick={onEnterField('email', canEdit)}
          onBlur={onLeaveField('email')}
          altContent={`Aucun email n'est associé à ce patient`}
          childRef={refs}
          canEdit={canEdit}
        >
          <Ui.Form.TextInput
            name="email"
            label="Email"
            value={email.value}
            onChange={updateField('email')}
            onBlur={onLeaveField('email')}
            onRestoreValue={onRestoreValue('email', email.initialValue)}
            onKeyDown={onKeyPress}
            error={email.errors[0]}
            refs={refs}
            tabIndex="8098"
          />
        </Editable>
      }
      <Styled.ExtraInformationsContainer>
        {forms.length > 0 && forms[0].id !== null
          ? <CustomFieldViewer
            patientStepDates={patientStepDates}
            isEditFields={isEditFields}
            isViewMode={false}
            data={data}
            patientId={patientId}
            forms={sortedForms}
            onDocumentRemove={onDocumentRemove}
            teleExpertiseRefId={teleExpertiseRefId}
            canEdit={data.permissions.value._canBeModified.summarySheet}
            cohortsPatientStepsByCustomFieldSectionId={cohortsPatientStepsByCustomFieldSectionId.value}
            refs={refs}
          />
          : expertises.length > 0
            ? <>
              <Ui.BasicElement.H2>
                Infos complémentaires
              </Ui.BasicElement.H2>
              <Ui.BasicElement.P>Aucune information complémentaire n'est demandée pour ce patient.</Ui.BasicElement.P>
            </>
            : null
        }
      </Styled.ExtraInformationsContainer>
    </Ui.Layout.Section>
  )
}

const TeleExpertiseSection = ({
  expertises,
  availableExpertises,
  dispatch,
  expertiseType,
  expertiseName,
  permissions,
  search,
  feminize,
  intro,
  pending,
  staffSessionsById,
}) => {
  const numExpertises = Object
    .entries({ staffs: 0, opinions: 0, cohorts: 0 })
    .reduce((acc, [expertise]) => ({
      ...acc,
      [expertise]: expertises.filter(el => el.type === expertise).length
    }), {})

  const onCheck = (e, type, id) =>
    e.target.checked
      ? dispatch(Patient.checkExpertiseFromList({ type, id }))
      : dispatch(Patient.uncheckExpertiseFromList({ type, id }))

  const onQueryChange = e => dispatch(Patient.setQuery({
    name: expertiseType,
    value: e.target.value
  }))

  const [searchBarVisible, setSearchBarVisible] = useState({
    staffs: false,
    cohorts: false,
    opinions: false
  })

  return (
    <Styled.CardsSectionContainer>
      <Styled.CardsSectionHeader>
        <Styled.CardsSectionIcon expertise={expertiseType}>
          {expertiseType === 'staffs'
            ? <Ui.Icon.IconMessage />
            : expertiseType === 'cohorts'
              ? <Ui.Icon.IconCohorte />
              : <Ui.Icon.IconAvis />
          }
        </Styled.CardsSectionIcon>
        <Styled.CardsSectionTitle>
          <p>{intro}</p>
          <p>{`${numExpertises[expertiseType]} ${pluralize(expertiseName, numExpertises[expertiseType])}`}</p>
        </Styled.CardsSectionTitle>
      </Styled.CardsSectionHeader>
      <WithPermissions domains={['shares']} permissions={permissions}>
        <Styled.ToggleSearchBar>
          <SearchTeleExpertiseBar
            open={searchBarVisible[expertiseType]}
            onClickOutside={e => setSearchBarVisible({ [expertiseType]: false })}
          >
            <Ui.SearchBar.SearchBar
              placeholder={`Rechercher un ${expertiseName} par nom, discipline ou médecin...`}
              onChange={onQueryChange}
              loading={search.expertises.loading}
              value={search[expertiseType].value}
              id={`search_${expertiseType}`}
            />
            {search[expertiseType].value &&
              <Ui.Dropdown.SearchDropdown>
                {availableExpertises.filter(el => el.type === expertiseType).length > 0
                  ? availableExpertises
                    .filter(el => el.type === expertiseType)
                    .map((expertise, i) =>
                      <Ui.Form.CheckboxDropdown
                        icon={expertise.thePersonal ? <Ui.Icon.IconDoctorCircle /> : null}
                        key={`dropdown_${expertiseType}_${i}`}
                        label={expertise.type === 'opinions' && expertise.thePersonal
                          ? `Avis ${expertise.ownerTitle} ${expertise.ownerFullname}`
                          : expertise.name
                        }
                        subLabel={expertise.type === 'opinions'
                          ? expertise.disciplines
                            ? expertise.disciplines.join(', ')
                            : null
                          : expertise.ownerFullname
                        }
                        disabled={pending}
                        onChange={e => onCheck(e, expertiseType, expertise.id)}
                        checked={!!expertises.find(el => el.id === expertise.id && el.type === expertiseType)}
                      >
                      </Ui.Form.CheckboxDropdown>
                    )
                  : !search.expertises.loading &&
                  <p>Aucun{feminize && 'e'} {expertiseName} n'a été trouvé{feminize && 'e'}.</p>
                }
              </Ui.Dropdown.SearchDropdown>
            }
          </SearchTeleExpertiseBar>
          <Styled.CardsSearchButton
            onClick={e => setSearchBarVisible({ [expertiseType]: true })}
            visible={!searchBarVisible[expertiseType]}
          >
            <span>Ajouter à un{feminize && 'e'} {expertiseName}</span>
            <Ui.Icon.IconArrowRight />
          </Styled.CardsSearchButton>
        </Styled.ToggleSearchBar>
      </WithPermissions>
      <Styled.CardsSectionBody>
        {expertises
          .filter(expertise => expertise.type === expertiseType)
          .map(expertise =>
            <Ui.Card.TelexpertiseCard
              key={`${expertiseType}_card_${expertise.id}`}
              to={`${privateSitePrefix}/${expertise.type === 'opinions'
                ? `avis/${expertise.id}`
                : expertise.type === 'staffs'
                  ? staffSessionsById[expertise.id]
                    ? `staffs/${expertise.id}/session/${staffSessionsById[expertise.id]}`
                    : `staffs/${expertise.id}`
                  : `cohortes/${expertise.id}`
                }`
              }
              showDropdown={false}
              teleExpertiseType={expertiseType}
              ownerProfilePicture={expertise.thePersonal
                ? expertise.ownerProfilePicture
                : null
              }
              loggedIn
              teleExpertise={expertise}
              fromPatientPage
              {...expertise}
            />
          )}
      </Styled.CardsSectionBody>
    </Styled.CardsSectionContainer>
  )
}

const TeleExpertises = ({
  data: {
    staffSessionsLastIdByStaffId: {
      value: staffSessionsById
    } },
  currentSection,
  availableExpertises,
  search,
  searching,
  pending,
  permissions,
  ...props
}) => {

  return (currentSection === Patient.SECTION_TELE_EXPERTISE)
    ? <Ui.Layout.Section>
      <Styled.CardsContainer>
        <TeleExpertiseSection
          expertiseName="avis"
          expertiseType="opinions"
          availableExpertises={availableExpertises}
          intro="Adressé via"
          search={search}
          pending={pending}
          permissions={permissions}
          {...props}
        />
        <TeleExpertiseSection
          expertiseName="staff"
          expertiseType="staffs"
          availableExpertises={availableExpertises}
          intro="Présenté dans"
          search={search}
          pending={pending}
          permissions={permissions}
          staffSessionsById={staffSessionsById}
          {...props}
        />
        <TeleExpertiseSection
          expertiseName="cohorte"
          expertiseType="cohorts"
          availableExpertises={availableExpertises}
          intro="Inclus dans"
          search={search}
          pending={pending}
          permissions={permissions}
          feminize
          {...props}
        />
      </Styled.CardsContainer>
    </Ui.Layout.Section>
    : null
}

const Colleagues = ({
  currentSection,
  data,
  patientManagersResource,
  patientId,
  dispatch,
  permissions,
  search,
  isEditMode,
  currentUser,
  resourceLoading,
  onEnterFieldsBlock,
  onLeaveFieldsBlock,
  isInviting,
  currentUserOpinion,
}) => {
  const {
    doctor: doctorId,
    patientManagers
  } = data
  const canEdit = data?.permissions?.value?._canBeModified?.shares

  const searchColleaguesRef = useRef()

  const invitationData = {
    type: 'opinion',
    name: { value: currentUserOpinion?.name },
    ownerAndManagers: { value: currentUserOpinion?.ownerAndManagers },
    profilePicture: { value: currentUserOpinion?.pictureFileName },
    thePersonal: { value: true },
    ownerProfilePicture: { value: currentUser?.profilePicture },
  }

  const onUpdateDoctor = (colleague) =>
    dispatch(Patient.updateDoctor({
      formerDoctor: doctorId.value,
      colleague: {
        colleagueId: colleague.id,
        fullName: colleague.lastname
      },
      patientId,
    }))

  const onTogglePatientManager = (colleagueId, toggled) => {
    if (toggled) {
      dispatch(Patient.addPatientManager({ patientId, colleagueId }))
    } else {
      dispatch(Patient.removePatientManager({ patientId, colleagueId }))
    }
    dispatch(Patient.setEditMode({ target: 'patientManaonUpdateDoctorgers', value: false }))
  }

  const onChangePatientManager = (e) => dispatch(Patient.setQuery({
    name: 'patientManagers',
    value: e.target.value
  }))

  return (
    (currentSection === Patient.SECTION_COLLEAGUE)
      ? isInviting
        ? <Invitation
          type="patient"
          teleExpertiseId={currentUserOpinion.id}
          teleExpertiseName="opinion"
          data={invitationData}
          patientId={patientId}
          sentFromPatient={true}
        />
        : <Ui.Layout.Section>
          <Editable
            content={
              <Ui.Layout.FlexBox flow="column wrap">
                <Ui.BasicElement.H2>Médecins</Ui.BasicElement.H2>
                {canEdit &&
                  <Ui.BasicElement.P>Cliquez ici pour ajouter ou supprimer un responsable</Ui.BasicElement.P>
                }
                <Ui.Layout.ColleagueCardsContainer>
                  {!isEditMode.patientManagers &&
                    ([...patientManagersResource].length > 0) &&
                    [...patientManagersResource]
                      .filter(colleague =>
                        [
                          doctorId.value,
                          ...patientManagers.value,
                        ].includes(Number(colleague.id)))
                      .map((colleague, i) =>
                        <Ui.Colleague.Colleague
                          key={`colleague_${i}`}
                          to={`${privateSitePrefix}/medecins/${colleague.id}`}
                          colleague={{ ...colleague, username: `${colleague.firstname} ${colleague.lastname}` }}
                          onUpdateDoctor={(colleague, doctorId) => onUpdateDoctor(colleague, doctorId)}
                          patientDoctorId={doctorId.value}
                          permissions={permissions}
                          currentUser={currentUser}
                          patientCreatorId={doctorId.value}
                        />
                      )}
                </Ui.Layout.ColleagueCardsContainer>
              </Ui.Layout.FlexBox>
            }
            description=""
            isEditMode={isEditMode.patientManagers}
            onClick={onEnterFieldsBlock('patientManagers', canEdit)}
            onBlur={onLeaveFieldsBlock('patientManagers')}
            altContent={`Aucun confrère n'est associé à ce patient`}
            childRef={searchColleaguesRef}
            canEdit={canEdit}
          >
            <Ui.Layout.FocusDiv ref={searchColleaguesRef} tabIndex="1654" >
              <Ui.BasicElement.H2>Médecins</Ui.BasicElement.H2>
              <Ui.Form.InputContainer>
                <Ui.SearchBar.SearchAndCheck
                  name="patientManagers"
                  query={search.patientManagers.value}
                  TeleExpertise={Patient}
                  checkboxDisabled={member => [patientManagers, doctorId.value].includes(member.id)}
                  resultSet={patientManagersResource}
                  resultToLabel={manager => `${manager.title} ${manager.firstname} ${manager.lastname}`}
                  resultToValue={manager => manager.id}
                  onToggle={(colleague, toggled) => onTogglePatientManager(colleague, toggled)}
                  onQueryChange={onChangePatientManager}
                  loading={resourceLoading.patientManagers}
                  list={[doctorId.value, ...patientManagers.value]}
                  hasInviteColleagueButton
                  onClickExtraButton={e => dispatch(InvitationState.setIsInviting(true))}
                />
              </Ui.Form.InputContainer>
            </Ui.Layout.FocusDiv>
          </Editable>
        </Ui.Layout.Section >
      : null
  )
}

const Attachments = ({ assets }) => assets.length > 0
  ? <Ui.UploadFile.PreviewContainer>
    {assets.map(({ fileName, name, size, mimeType, title }, idx) =>
      <Ui.DocumentPreview.DocumentPreview
        key={`document_${idx}`}
        name={name}
        mimeType={mimeType}
        size={size}
        apiPath={`/comments/assets/`}
        fileName={fileName}
        title={title}
        iconSrc={mimeTypeIcon(mimeType)}
      >
      </Ui.DocumentPreview.DocumentPreview>
    )}
  </Ui.UploadFile.PreviewContainer>
  : null

const Comments = ({
  currentSection,
  comments,
  commentData,
  commentsData,
  dispatch,
  sendingComment,
  commentBoxOpen,
  patientId,
  teleExpertiseId,
  onDocumentRemove,
  isTeleExpertiseInPath,
  alert,
  currentUser,
  teleExpertiseType,
  requestState,
  teleExpertiseRefId,
  expertises,
}) => {
  const expertiseData = expertises.find(te => Number(te.id) === Number(teleExpertiseRefId))
  const isTeleExpertiseLocked = expertiseData?.patientLimitLocked && expertiseData.numberAddPatientAction >= maxPatientsNumber

  const [attachmentBoxOpen, setAttachmentBoxOpen] = useState(false)
  const refs = useRef()
  const updateCommentField = name => e => {
    dispatch(Comment.updateField({ name, value: e.target.value }))
  }

  const updateCommentFieldById = (id) => e => {
    dispatch(Comment.updateFieldById({ name: 'comments', value: e.target.value, id }))
  }

  const onSendComment = (e, { teleExpertiseType, requestState, teleExpertiseId }) => {
    e.preventDefault()
    dispatch(Comment.send({ teleExpertiseType, requestState, teleExpertiseId }))
  }

  const onCancelComment = (e) => {
    dispatch(Comment.setCommentBoxOpen(false))
    dispatch(Comment.clearCommentBox())
  }

  const onRemoveComment = (e, comment) =>
    alert.show('Confirmez-vous la suppression de ce commentaire ?', {
      onConfirm: (e) => {
        dispatch(Comment.deleteComment(comment.id))
      }
    })

  const onLeaveField = id => e => {
    if (commentsData.isFieldEdit[id] && !e.currentTarget.contains(e.relatedTarget))
      if (commentsData.value[id] !== commentsData.initialValue[id])
        dispatch(Comment.saveCommentById({
          comment: commentsData.value[id],
          id
        }))
      else {
        dispatch(Comment.setEditComment({ commentId: id, isEditing: false }))
      }
  }

  const onRestoreValue = (id, value) => (e) => {
    dispatch(Comment.restoreInitialValue({ commentId: id, value: commentsData.initialValue[id] }))
    if (!commentsData.errors.length > 0)
      dispatch(Comment.setEditComment({ commentId: id, isEditing: false }))
  }

  const CommentTitle = ({
    comment: {
      author: { firstname, lastname },
      typeTwo: type,
      referenceTwoName,
    },
  }) => {

    return (
      <Ui.BasicElement.P bold dark>
        {type && (type === 'staffSessions' || type === 'opinions')
          ? type === 'staffSessions'
            ? `Avis de staff${referenceTwoName ? ` - ${referenceTwoName}` : ''}`
            : `Avis${referenceTwoName ? ` - ${referenceTwoName}` : ''}`
          : `${firstname} ${lastname}`}
      </Ui.BasicElement.P>
    )
  }

  return (currentSection === Patient.SECTION_COMMENT) && (
    <Ui.Layout.Section>
      {commentBoxOpen === false
        ? <Ui.Layout.BlockContainer>
          <Ui.Button.BasicButton
            isGreen
            onClick={
              teleExpertiseRefId && teleExpertiseType !== 'cohort' && (requestState !== 'done' || isTeleExpertiseInPath) && !isTeleExpertiseLocked
                ? () => dispatch(Comment.setCommentBoxOpen('default'))
                : () => dispatch(Comment.setCommentBoxOpen('infos'))
            }
          >
            <Ui.Icon.IconChat />
            Nouveau commentaire
          </Ui.Button.BasicButton>
        </Ui.Layout.BlockContainer>
        : teleExpertiseRefId && teleExpertiseType !== 'cohort' && (requestState !== 'done' || isTeleExpertiseInPath) && !isTeleExpertiseLocked
          ? <Ui.Layout.BlockContainer>
            <Styled.CommentSelect>
              <Ui.Select.Select
                label=""
                isFilled
                htmlFor="comment"
                items={[
                  { label: 'Répondre à la demande d\'avis', value: 'default' },
                  { label: 'Demande d\'information', value: 'infos' }]
                  // .filter(el => el.value === 'infos' || (el.value === 'default' && teleExpertiseType !== 'cohort'))
                  .map((item) => ({
                    label: item.label,
                    value: item.value,
                    selected: item.value === commentBoxOpen,
                  }))}
                onChange={(e) => dispatch(Comment.setCommentBoxOpen(e.target.value))}
              />
            </Styled.CommentSelect>
          </Ui.Layout.BlockContainer>
          : <Ui.BasicElement.H3>Votre commentaire :</Ui.BasicElement.H3>
      }
      <Styled.CommentSection>
        <Ui.Comment.Box visible={commentBoxOpen}>
          <form onSubmit={commentBoxOpen === 'default' && (requestState !== 'done' || isTeleExpertiseInPath)
            ? (e) => onSendComment(e, { teleExpertiseType, requestState, teleExpertiseId })
            : (e) => onSendComment(e, {})
          }>
            <Ui.Form.TextAreaInput
              htmlFor="commentaire"
              withLabel={false}
              value={commentData.value}
              onChange={updateCommentField('comment')}
              error={commentData.errors[0]}
              noSubmit
            />
            <Ui.Layout.ButtonGroup>
              <Ui.Button.BasicButton
                disabled={sendingComment}
                isFilled
                isGreen
                type="submit"
              >
                {sendingComment && <Ui.Global.Loader />}
                Valider
              </Ui.Button.BasicButton>
              <Ui.Button.BasicButton
                isFilled
                isGreen
                onClick={onCancelComment}
              >
                Annuler
              </Ui.Button.BasicButton>
            </Ui.Layout.ButtonGroup>
            <Ui.BasicElement.CollapsibleLink onClick={(e) => setAttachmentBoxOpen(!attachmentBoxOpen)}>
              <span>Joindre un document</span>
              <span>
                {attachmentBoxOpen
                  ? <Ui.Icon.IconUp />
                  : <Ui.Icon.IconDown />
                }
              </span>
            </Ui.BasicElement.CollapsibleLink>
            <Ui.Layout.AttachmentsBox open={attachmentBoxOpen}>
              <Ui.UploadFile.DocumentsUpload
                label=""
                name="comments-upload"
                endpoint="/comments/new/assets"
                onRemove={onDocumentRemove}
                validationRules={["noLargeFiles", "noEmptyFiles", "acceptedDocumentFormat"]}
                apiPath="/comments/assets/"
                disabled={sendingComment}
              />
            </Ui.Layout.AttachmentsBox>
          </form>
        </Ui.Comment.Box>

        {comments.length > 0 &&
          comments
            .slice()
            .sort((a, b) => b.id - a.id)
            .map((comment) => (
              <Ui.Comment.Container key={`comment_${comment.id}`} on={comment.typeTwo}>
                <Ui.Comment.Header>
                  <div>
                    <Ui.Comment.Avatar src={comment.author.profilePicture ? `${apiUrl}/users/profile-picture/${comment.author.profilePicture}` : '/Avatar_default.png'} />
                    <Ui.Comment.Title>
                      <CommentTitle comment={comment} />
                      <Ui.BasicElement.P>{`${comment.creationDate} à ${comment.creationHour}`}</Ui.BasicElement.P>
                    </Ui.Comment.Title>
                  </div>
                  <Ui.Layout.ActionButtonsContainer>
                    {comment.typeTwo &&
                      (comment?.teleExpertiseRights?.currentUserAdmin || comment?.teleExpertiseRights?.currentUserMember) &&
                      <a href={comment.typeTwo === 'opinions'
                        ? `${apiUrl}/${comment.typeTwo}/${comment.referenceTwo}/report-download/patient/${patientId}`
                        : `${apiUrl}/staffs/report-download/session/${comment.referenceTwo}/patient/${patientId}`
                      }>
                        <Ui.Button.ActionButton type="button">
                          <Ui.Icon.IconDocument />
                        </Ui.Button.ActionButton>
                      </a>
                    }
                    {currentUser.id === comment.author.id &&
                      <Ui.Button.ActionButton
                        onClick={(e) => onRemoveComment(e, comment)}>
                        <Ui.Icon.IconTrash />
                      </Ui.Button.ActionButton>
                    }
                  </Ui.Layout.ActionButtonsContainer>
                </Ui.Comment.Header>
                <Ui.Layout.BlockContainer>
                  <Editable
                    title="Votre commentaire"
                    text={comment.content}
                    isEditMode={commentsData.isFieldEdit[comment.id]}
                    onClick={() => dispatch(Comment.setEditComment({ commentId: comment.id, isEditing: true }))}
                    onBlur={onLeaveField(comment.id)}
                    childRef={refs}
                    altText="Ajouter un commentaire"
                    altTextDisabledField="Ce commentaire n'a pas été renseigné"
                    canEdit={Number(comment?.author?.id) === Number(currentUser.id)}
                  >
                    <Ui.Form.TextAreaInputResizable
                      name="comments"
                      label="Votre commentaire"
                      value={commentsData.value[comment.id]}
                      onChange={updateCommentFieldById(comment.id)}
                      onBlur={onLeaveField(comment.id)}
                      onRestoreValue={onRestoreValue(comment.id, commentsData.value[comment.id])}
                      error={commentsData.errors[0]}
                      inputRef={refs}
                      tabIndex={UniqueDigitId()}
                    />
                  </Editable>
                </Ui.Layout.BlockContainer>
                <Attachments assets={comment.assets} />
              </Ui.Comment.Container>
            ))
        }
      </Styled.CommentSection>
    </Ui.Layout.Section >
  )
}

const SearchTeleExpertiseBar = ({
  onClickOutside = () => null,
  children,
  ...props
}) =>
  <ClickOutside
    Component={Styled.SearchTeleExpertiseBarContainer}
    onClickOutside={onClickOutside}
    {...props}
  >
    {children}
  </ClickOutside>

export default PatientComponent;
