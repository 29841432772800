import React, { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import * as CustomField from '../../State/CustomField';
import * as Patient from '../../State/Patient';
import * as FormUi from './Form'
import * as IconUi from './Icon'
import * as BasicElementUi from './BasicElement'
import * as UploadFileUi from '../Ui/UploadFile'
import * as LayoutUi from './Layout'
import * as TagUi from './Tag'
import styled, { css } from 'styled-components/macro'
import { cohortPublicPatientUrl } from '../../Util/Config'
import { JJMMAAAAToDate } from '../../Util/Format'
import { uniqByIdAndType } from '../../Util/Object'
import DatePicker from '../Ui/DatePicker';
import Editable from '../../Util/Editable'
import UniqueDigitId from '../../Util/UniqueDigitId';

const Field = ({
  data,
  teleExpertiseId,
  choices,
  dispatch,
  isEditFields,
  formField,
  updateFormField,
  patientId = '',
  onDocumentRemove,
  sectionId,
  isQuestionnaire,
  patientCode,
  disabled,
  updateCustomFieldValue,
  isPatientField,
  teleExpertiseType,
  ...props
}) => {

  let value = ''
  let items = formField.choices.map(id => choices[id]) || {}
  let type = formField.typeOfField || formField.type

  if (formField.value) {
    if (type === 'text') {
      value = formField.value
    }
  }

  const setEditField = ({ teleExpertiseId, fieldId, isFieldEdit }) =>
    // TODO : set field 'isFieldEdit' value
    dispatch(CustomField.setEditFields({ teleExpertiseId, fieldId, isFieldEdit }))

  const onEnterField = ({ teleExpertiseId, fieldId }) => () => {
    setEditField({ teleExpertiseId, fieldId, isFieldEdit: true })
    //  return data?.permissions?.value?._canBeModified?.default
    //   ? setEditField(fieldName, true)
    //   : null
  }
  const onLeaveField = ({
    type,
    id,
    sectionId,
  }, formField) => e => {
    if (formField.isFieldEdit && !e.currentTarget.contains(e.relatedTarget))
      if (formField.value !== formField.initialValue || formField.value === '') {
        dispatch(CustomField.updateCustomFieldValue({ id, sectionId, type, target: 'customFields', fieldId: formField.id, isQuestionnaire, patientCode }))
      }
      else {
        setEditField({ teleExpertiseId, fieldId: formField.id, isFieldEdit: false })
      }
  }

  const onRestoreValue = ({ teleExpertiseId, errors, fieldId, value }) => (e) => {
    e.preventDefault()
    dispatch(CustomField.restoreInitialValue({ teleExpertiseId, fieldId, value }))
    if (!errors?.length > 0)
      setEditField({ teleExpertiseId, fieldId, isFieldEdit: false })
  }

  const mandatorySymbol = formField.mandatory
    ? isPatientField && !patientCode
      ? ' (ce champ est obligatoire pour les patients)'
      : ' *'
    : ''

  const refs = useRef({});

  return (
    <>
      {type === 'text'
        ? disabled
          ? <>
            <BasicElementUi.H3>
              {`${formField.label} ${mandatorySymbol}`}
            </BasicElementUi.H3>
            <BasicElementUi.P>
              {value}
            </BasicElementUi.P>
            <Separator />
          </>
          : <Editable
            title={`${formField.label} ${mandatorySymbol}`}
            isTitleWarning={formField.mandatory && formField?.value?.length === 0}
            text={formField.value}
            isEditMode={formField.isFieldEdit}
            onClick={onEnterField({
              teleExpertiseId,
              fieldId: formField.id,
            })}
            onBlur={onLeaveField({
              type,
              id: teleExpertiseId,
              sectionId,
              fieldId: formField.id
            }, formField)}
            childRef={refs}
            altText={`Renseigner ce champ${formField.mandatory ? ' obligatoire' : ''}`}
            canEdit={true}
          >
            <FormUi.TextAreaInputResizable
              name={formField.id}
              value={value}
              label={`${formField.label} ${mandatorySymbol}`}
              isTitleWarning={formField.mandatory && formField?.value?.length === 0}
              onChange={e => updateFormField({
                id: teleExpertiseId,
                teleExpertiseId,
                teleExpertiseType,
                fieldId: formField.id,
                value: e.target.value,
                type: 'text',
              })}
              onBlur={onLeaveField({
                type,
                id: teleExpertiseId,
                sectionId,
                fieldId: formField.id
              }, formField)}
              onRestoreValue={onRestoreValue({
                teleExpertiseId,
                errors: formField.error,
                fieldId: formField.id,
                value: formField.initialValue
              })}
              //error={formField.error}
              inputRef={refs}
              tabIndex={UniqueDigitId()}
            // noCancel
            />
          </Editable>
        : null
      }
      {type === 'multicheckbox' &&
        <>
          <BasicElementUi.H3 isTitleWarning={formField.mandatory && formField?.value?.length === 0}>
            {`${formField.label} ${mandatorySymbol}`}
          </BasicElementUi.H3>
          <CustomFieldsChoiceContainer>
            {items.map(checkbox =>
              <CustomFieldsChoice key={checkbox.id}>
                <FormUi.Checkbox
                  checked={formField.value && formField.value.includes(checkbox.id)}
                  onChange={(e) => {
                    updateFormField({
                      teleExpertiseId,
                      id: teleExpertiseId,
                      fieldId: formField.id,
                      choiceId: checkbox.id,
                      sectionId,
                      checked: e.target.checked,
                      type,
                      isQuestionnaire,
                      patientCode,
                      teleExpertiseType
                    })
                  }}
                  colorized
                  disabled={disabled}
                />
                {checkbox.content}
              </CustomFieldsChoice>
            )}
          </CustomFieldsChoiceContainer>
          {formField.error &&
            <FormUi.FieldErrorMessage>{formField.error}</FormUi.FieldErrorMessage>
          }
        </>
      }
      {type === 'selectbox' &&
        <>
          <BasicElementUi.H3 isTitleWarning={formField.mandatory && formField?.value?.length === 0}>
            {`${formField.label} ${mandatorySymbol}`}
          </BasicElementUi.H3>
          <LayoutUi.RadioGroupContainer>
            {items.map(checkbox =>
              <CustomFieldsChoice key={checkbox.id}>
                <FormUi.Radio
                  name={`radio_${formField.id}`}
                  defaultChecked={formField.value && formField.value.includes(checkbox.id)}
                  value={checkbox.id}
                  label={checkbox.content}
                  disabled={disabled}
                  colorized
                  onClick={(e) => updateFormField({
                    teleExpertiseId,
                    id: teleExpertiseId,
                    fieldId: formField.id,
                    sectionId,
                    choiceId: checkbox.id,
                    checked: e.target.checked,
                    type,
                    isQuestionnaire,
                    patientCode,
                    teleExpertiseType,

                  })}
                />
                {checkbox.content}
              </CustomFieldsChoice>
            )}
          </LayoutUi.RadioGroupContainer>
          {formField.error &&
            <FormUi.FieldErrorMessage>{formField.error}</FormUi.FieldErrorMessage>
          }
        </>
      }
      {type === 'document' &&
        <>
          <UploadFileUi.DocumentsUpload
            isTitleWarning={formField.mandatory && formField?.value?.length === 0}
            name={`documentsField_${formField.id}`}
            id={`documentsField_${formField.id}`}
            validationRules={["noLargeFiles", "noEmptyFiles", "acceptedDocumentFormat"]}
            label={`${formField.label} ${mandatorySymbol}`}
            endpoint={
              isQuestionnaire
                ? `/public/attachments/add/patientFromPublic/${patientCode}/customField/${formField.id}`
                : `/attachments/add/patients/${patientId}/customField/${formField.id}`
            }
            onChange={data => {
              updateFormField({
                id: teleExpertiseId,
                teleExpertiseId,
                fieldId: formField.id,
                value: data,
                type: 'document',
                isQuestionnaire,
                patientCode,
                teleExpertiseType,

              })
            }}
            value={formField.value || []}
            onRemove={onDocumentRemove}
            fieldId={formField.id}
            disabled={disabled}
            apiPath={
              isQuestionnaire
                ? `/public/patient/${patientCode}/attachments/assets/`
                : '/attachments/assets/'
            }
          >
            {isEditFields &&
              <span>
                Formats acceptés : Txt, Jpeg, Png, Gif, Tiff, Zip, Pdf, Word, Excel, PowerPoint<br />
                Poids max : 30 Mo.
              </span>
            }
          </UploadFileUi.DocumentsUpload>
        </>
      }
    </>
  )
}

const CustomFieldViewerComponent = ({
  isEditFields,
  isUseMode,
  isViewMode,
  isBuildMode,
  isQuestionnaire = false,
  patientCode = '',
  forms,
  data,
  patientId,
  onDocumentRemove,
  teleExpertiseRefId,
  patientStepDates,
  cohortsPatientStepsByCustomFieldSectionId = {},
  refs
}) => {

  const dispatch = useDispatch()
  const formsVisible = useSelector(CustomField.selectFormsVisible)
  const updateFormField = field => {
    (field.type === 'text')
      ? dispatch(CustomField.updateTextField(field))
      : field.type === 'multicheckbox'
        ? dispatch(CustomField.updateChoiceField(field))
        : field.type === 'selectbox'
          ? dispatch(CustomField.updateRadioField(field))
          : dispatch(CustomField.updateFileField(field))
  }

  const updateCustomFieldValue = ({ id, sectionId, type }, target = 'customFields', fieldId, isFieldEdit) => e => {
    if (isFieldEdit && !e.currentTarget.contains(e.relatedTarget))
      dispatch(CustomField.updateCustomFieldValue({ id, sectionId, type, target, fieldId, isQuestionnaire, patientCode }))
  }

  const isFutureDate = (date) =>
    JJMMAAAAToDate(date) > new Date().setHours(0, 0, 0, 0)

  const onChangeDate = ({ idx, formId, date, patientStepId, patientStepTitle }) =>
    dispatch(Patient.updatePatientStepDate({
      name: 'patientStepDates',
      value: {
        idx,
        formId,
        date,
        patientStepId,
        patientStepTitle,
      }
    }))

  return (
    forms
      .filter(e => e.id)
      .filter(uniqByIdAndType)
      .map(({
        id: teleExpertiseId,
        type,
        name,
        sections,
        fields,
        choices,
        labels,
      }, i) => {

        const {
          publicPatientUri = '',
          hasPatientSteps = false
        } = (Array.isArray(data?.expertises?.value?.cohorts) &&
        data.expertises.value.cohorts.find(cohort => Number(cohort.id) === Number(teleExpertiseId) && type === 'cohorts')) || ''
        const questionnaireUrl = [
          cohortPublicPatientUrl,
          publicPatientUri,
          data && data.patientCode && data.patientCode.value,
        ].filter(e => e).join('/')

        return ((sections.allIds.length > 0 && Object.values(fields.byId).some(field => field.label)) || labels.allIds.length > 0) &&
          <div key={`teleExpertise_${i}`}>
            {!isQuestionnaire &&
              <>
                <FormTitle onClick={() => {
                  dispatch(CustomField.setFormVisible({
                    ...formsVisible,
                    [teleExpertiseId]: !formsVisible[teleExpertiseId]
                  }))
                }}>
                  <BasicElementUi.H2 id="extra" ref={(node => { if (refs) refs.current[`cf_${teleExpertiseId}`] = node })}>
                    Informations complémentaires liées à : {name}
                  </BasicElementUi.H2>
                  {!formsVisible[teleExpertiseId]
                    ? <><IconBorderPlus>+</IconBorderPlus></>
                    : <><IconBorderMinus>-</IconBorderMinus></>
                  }
                </FormTitle>
                <CustomFieldSection visible={formsVisible[teleExpertiseId]}>
                  {!isUseMode &&
                    data?.patientCode?.value &&
                    publicPatientUri &&
                    hasPatientSteps &&
                    <>
                      <BasicElementUi.H3>
                        Programmation pour ce patient
                      </BasicElementUi.H3>
                      <BasicElementUi.H4>
                        Lien patient
                      </BasicElementUi.H4>
                      <BasicElementUi.P>
                        <a href={questionnaireUrl} target="_blank" rel="noreferrer">
                          {questionnaireUrl}
                        </a>
                      </BasicElementUi.P>
                      {patientStepDates[teleExpertiseId] &&
                        <>
                          <BasicElementUi.H4>
                            Dates des étapes
                          </BasicElementUi.H4>
                          <PatientStepDates>
                            <tbody>
                              {patientStepDates[teleExpertiseId]
                                .map(({ date, patientStepId, patientStepTitle }, idx) =>
                                  <tr key={`patient_step_date_${idx}`}>
                                    <td>
                                      <span>{patientStepTitle} : </span>
                                    </td>
                                    <td>
                                      <InputBox>
                                        <DatePickerBox
                                          label="Date"
                                          disabled={!data.email.value}
                                          title={!data.email.value ? 'Vous devez renseigner une adresse email avant de définir des dates d\'étape' : ''}
                                        >
                                          <DatePicker
                                            value={date}
                                            clearIcon={null}
                                            calendarIcon="&#xe907;"
                                            showLeadingZeros
                                            disabled={!data.email.value}
                                            onChange={value =>
                                              onChangeDate({ idx, formId: teleExpertiseId, date: value, patientStepId, patientStepTitle })
                                            }
                                          />
                                        </DatePickerBox>
                                      </InputBox>
                                    </td>
                                  </tr>
                                )}
                            </tbody>
                          </PatientStepDates>
                        </>
                      }
                    </>
                  }
                </CustomFieldSection>
              </>
            }
            <CustomFieldSection visible={isQuestionnaire || (!isQuestionnaire && formsVisible[teleExpertiseId])}>
              {!isBuildMode && labels.allIds.length > 0 &&
                <>
                  <BasicElementUi.H3>
                    Etiquettes
                  </BasicElementUi.H3>
                  {labels.allIds.length > 0 &&
                    <Tags type="labels">
                      {labels
                        .allIds
                        .map(id => labels.byId[id]).length > 0 &&
                        <TagUi.TagsContainer key={`labels_${i}`} noHover>
                          {labels
                            .allIds
                            .map(id => labels.byId[id])
                            .map((label, i) =>
                              <TagUi.Tag
                                key={`label_${i}`}
                                bgColor={label.color}
                                selectable
                                checked={labels.value.includes(label.id)}
                                onChange={(e) => dispatch(CustomField.updateLabels({
                                  teleExpertiseId,
                                  fieldId: label.id,
                                  checked: e.target.checked,
                                  type: 'labels',
                                }))}
                              //disabled={!isViewMode}
                              >
                                <span>{label.content}</span>
                              </TagUi.Tag>
                            )
                          }
                        </TagUi.TagsContainer>
                      }
                    </Tags>
                  }
                </>
              }
              {Object
                .values(sections.byId)
                .some(section => section.fields.length > 0) &&
                <CustomFieldsContainer isQuestionnaire={isQuestionnaire}>
                  {!isQuestionnaire &&
                    <>
                      <header>
                        <BasicElementUi.H3>
                          Champs personnalisés
                        </BasicElementUi.H3>
                      </header>
                    </>
                  }
                  {sections
                    .allIds
                    .map(sectionId =>
                      sections.byId[sectionId].fields.length > 0 &&
                      <CustomFieldsSection key={`section_${sectionId}`}>
                        {sections.byId[sectionId].label &&
                          <LayoutUi.FlexBox alignItems="center">
                            {sections.byId[sectionId].label && !patientCode && !isQuestionnaire
                              ? sections.byId[sectionId].isPatientSection
                                ? <IconUi.IconPatientCircle />
                                : <IconUi.IconDoctorCircle />
                              : null
                            }
                            <BasicElementUi.H2>
                              {sections.byId[sectionId].label}
                            </BasicElementUi.H2>
                          </LayoutUi.FlexBox>
                        }
                        {!patientCode &&
                          cohortsPatientStepsByCustomFieldSectionId[sectionId] &&
                          <BasicElementUi.P>
                            Cette section est liée à l’étape <Emphasized>{cohortsPatientStepsByCustomFieldSectionId[sectionId].patientStepTitle}</Emphasized>
                            {isFutureDate(cohortsPatientStepsByCustomFieldSectionId[sectionId].date) &&
                              `${'\u00A0'}et sera remplie par le ${sections.byId[sectionId].isPatientSection
                                ? 'patient'
                                : 'médecin'
                              } le ${cohortsPatientStepsByCustomFieldSectionId[sectionId].date}`}
                          </BasicElementUi.P>
                        }
                        {sections.byId[sectionId].fields.map(fieldId =>
                          <LayoutUi.BlockContainer key={fields.byId[fieldId].id}>
                            <Field
                              updateFormField={updateFormField}
                              data={data}
                              patientId={patientId}
                              isEditFields={isEditFields[type][teleExpertiseId]['customFields']}
                              teleExpertiseType={type}
                              formField={fields.byId[fieldId]}
                              choices={choices.byId}
                              teleExpertiseId={teleExpertiseId}
                              onDocumentRemove={onDocumentRemove}
                              isQuestionnaire={isQuestionnaire}
                              patientCode={patientCode}
                              updateCustomFieldValue={updateCustomFieldValue}
                              isPatientField={fields.byId[fieldId].hasMotherPatientSection}
                              disabled={isUseMode ||
                                (isQuestionnaire && !patientCode) ||
                                (!isViewMode &&
                                  (!isQuestionnaire ||
                                    // Disabled not patient sections
                                    !fields.byId[fieldId].isPatientField) &&
                                  (cohortsPatientStepsByCustomFieldSectionId[sectionId] &&
                                    isFutureDate(cohortsPatientStepsByCustomFieldSectionId[sectionId].date)))
                              }
                              dispatch={dispatch}
                            />
                          </LayoutUi.BlockContainer>
                        )}
                      </CustomFieldsSection>
                    )}
                </CustomFieldsContainer>
              }
            </CustomFieldSection>
          </div>
      })
  )
}

const IconBorderPlus = styled.span`
  width: 1.4rem;
  height: 1.4rem;
  display: block;
  text-align: center;
  line-height: 1.4rem;
  text-decoration: none;
  border: 1px solid;
  border-radius: 3px;
  font-size: 1.4rem;
  margin-left: 1rem;
`

const IconBorderMinus = styled.span`
  width: 1.4rem;
  height: 1.4rem;
  display: block;
  text-align: center;
  line-height: 1.25rem;
  text-decoration: none;
  border: 1px solid;
  border-radius: 3px;
  font-size: 1.4rem;
  margin-left: 1rem;
`

const FormTitle = styled(props => <LayoutUi.FlexBox {...props} />)`
  align-items: flex-start;
  cursor: pointer;
  margin-top: 1rem;
  ${BasicElementUi.H2} {
    margin-top: 0;
    flex: 1;
  }
`

const CustomFieldSection = styled.div`
  display: ${props => props.visible ? 'block' : 'none'};
`

const Emphasized = styled.span`
  font-style: italic;
`

const PatientStepDates = styled.table`
  margin-bottom: 1rem;
  tr {
    margin: 1rem 0;
    td:first-child {
      line-height: 3.5rem;
    }
  }
`

const DatePickerBox = styled.div`
  width: 100%;
  padding: 0 1rem;
  display: flex;
  > div {
    width: 100%;
  }
  background: ${props => props.disabled
    ? '#f0f0f0'
    : 'none'
  };
  opacity: ${props => props.disabled
    ? '.5'
    : '1'
  };
  cursor: ${props => props.disabled
    ? 'not-allowed'
    : 'pointer'
  };
`

const InputBox = styled.div`
  width: 12rem;
  box-shadow: 0 2px 5px 1px rgba(134, 174, 197, 0.24);
  display: flex;
  margin-top: 0;
  margin-left: .5rem;
  flex: initial;
  background-color: #FFF;
  border: 0;
  min-height: 2.8125rem;
  border-radius: 12px;
`

export const CustomFieldsSection = styled.div`
  box-shadow: ${props => props.theme.boxShadow.thin};
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  position: relative;
  > ${LayoutUi.FlexBox} {
    margin-bottom: 1.4rem;
  }
  ${BasicElementUi.H2} {
    margin-bottom: 0 !important;
  }
`

const Separator = styled.div`
  border: 0;
  border-bottom: 1px solid;
  border-color: ${props => props.theme.mediumGrey};
`

export const CustomFieldsContainer = styled.div`
  ${BasicElementUi.P} {
    &:first-of-type {
      margin-top: 0;
    }
  }
  display: flex;
  border-radius: 5px;
  flex-direction: column;
  background: white;
  ${props => !props.isQuestionnaire
    ? css`
      padding: 1.5rem;
      margin-bottom: 2.5rem;
      box-shadow: ${props => props.theme.boxShadow.thin};
    `
    : null
  }
  header {
    display: flex;
    justify-content: flex-end;
    ${BasicElementUi.H3} {
      flex: 1;
      margin-top: .5rem;
      color: ${props => props.theme.grey}
    }
  }
  ${BasicElementUi.H3}, ${FormUi.Label} {
    /* margin-top: 1rem !important; */
    //color: ${props => props.theme.darkBlue};
  }
  ${FormUi.Label} {
    /* margin-top: 1rem !important; */
    color: ${props => props.isTitleWarning ? props.theme.cohorteRed : props.theme.darkBlue};
  }
  ${LayoutUi.ActionButtonsContainer} {
    margin-bottom: 1rem;
    align-self: flex-end;
  }
  ${FormUi.Label} {
    &:disabled {
      font-weight: 700;
      opacity: .8;
    }
  };
  ${FormUi.Input} {
    &:disabled {
      border-bottom: 1px dotted;
      border-color: ${props => props.theme.grey2};
    }
  };

  ${IconUi.IconPatientCircle}, ${IconUi.IconDoctorCircle}  {
    display: flex;
    align-items: center;
    height: 1.2rem;
    width: 1.2rem;
  }
`

const CustomFieldsChoiceContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const CustomFieldsChoice = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.theme.darkBlue};
  font-size: .875rem;
  margin-bottom: .7rem;
  margin-right: 1.1rem;
  label {
    margin-right: .4rem;
  }
  label > span {
    margin-left: .4rem;
    font-weight: normal;
  };
`

const Tags = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  p {
    margin-top: 0;
  }
`

export default CustomFieldViewerComponent;
