import { createSlice } from '@reduxjs/toolkit'

export const DISPLAY_ALL = 'typeAll'
export const DISPLAY_UNREAD = 'unread'
export const DISPLAY_READ = 'read'
export const DISPLAY_NOTIFICATIONS = 'notifications'

export const INITIAL_STATE = {
  data: {
    messages: [],
  },
  checked: [],
  allChecked: false,
  hasMore: true,
  loading: false,
  pending: false,
  error: false,
  success: false,
  errorMessage: null,
  unreadMessages: 0,
  unreadNotifications: 0,
  filters: {
    [DISPLAY_UNREAD]: true,
    [DISPLAY_READ]: true,
    [DISPLAY_NOTIFICATIONS]: true,
  },
  colleagueCountAll: 0,
  colleagueCount: { new: null, waiting: null, done: null },
}

const slice = createSlice({
  name: 'messageList',
  initialState: INITIAL_STATE,
  reducers: {

    addStatus: (state, { payload }) => ({
      ...state,
      filters: {
        ...state.filters,
        [payload]: true,
      }
    }),

    removeStatus: (state, { payload }) => ({
      ...state,
      filters: {
        ...state.filters,
        [payload]: false,
      }
    }),

    allNotificationsRead: state => ({
      ...state
    }),

    changeDisplayBy: (state, { payload }) => ({
      ...state,
      filters: {
        ...state.filters,
        displayBy: payload,
      },
    }),

    changeFilterBy: (state, { payload }) => ({
      ...state,
      filters: {
        ...state.filters,
        filterBy: payload,
      },
    }),

    changeSearch: (state, { payload }) => ({
      ...state,
      filters: {
        ...state.filters,
        search: payload,
      },
    }),

    fetch: state => ({
      ...state,
      loading: true,
    }),

    fetchMessagesNum: state => ({
      ...state,
    }),

    receivedMessagesNum: (state, { payload: { unreadMessages, unreadNotifications } }) => ({
      ...state,
      unreadMessages,
      unreadNotifications,
    }),

    resetNotificationsCount: state => ({
      ...state,
      unreadNotifications: 0,
    }),

    received: (state, { payload }) => ({
      ...state,
      ...payload,
      loading: false,
    }),

    count: state => ({
      ...state,
    }),

    apiError: (state, action) => ({
      ...state,
      errorMessage: action.payload,
      pending: false,
      error: true,
      success: false,
    }),

    success: (state, { payload: id }) => ({
      ...state,
      pending: false,
      error: false,
      success: true,
      colleagueId: id
    }),

    clean: state => ({
      ...state,
      data: {
        messages: [],
      },
      checked: [],
      allChecked: false,
      hasMore: true,
      loading: false,
      pending: false,
      error: false,
      success: false,
      errorMessage: null,
      filters: {
        [DISPLAY_UNREAD]: true,
        [DISPLAY_READ]: true,
        [DISPLAY_NOTIFICATIONS]: true,
      },
      colleagueCountAll: 0,
      colleagueCount: { new: null, waiting: null, done: null },
    }),
  }
})

export default slice.reducer

export const {
  removeStatus,
  addStatus,
  allMessageRead,
  changeDisplayBy,
  changeFilterBy,
  changeSearch,
  fetch,
  received,
  count,
  apiError,
  success,
  fetchMessagesNum,
  receivedMessagesNum,
  resetNotificationsCount,
  clean,
} = slice.actions

export const selectMessages = state => state.messageList.data.messages
export const selectFilters = state => state.messageList.filters
export const selectHasMore = state => state.messageList.hasMore
export const selectLoading = state => state.messageList.loading
export const selectChecked = state => state.messageList.checked
export const selectErrorMessage = state => state.messageList.errorMessage
export const selectHasCheckedItems = state => state.messageList.checked.length !== 0
export const selectIsAllChecked = state => state.messageList.allChecked
export const selectCountAll = state => state.messageList.colleagueCountAll
export const selectNotificationsNumber = state => state.messageList.unreadNotifications
export const selectMessageNumber = state => state.messageList.unreadMessages + state.messageList.unreadNotifications
export const selectCount = state => state.messageList.colleagueCount
