import { createSlice } from '@reduxjs/toolkit';

export const INITIAL_STATE = {
  id: null,
  data: {
    email: { value: '', errors: [], validationRules: [] },
    expertises: { value: [], errors: [], validationRules: [] },
    message: { value: '', errors: [], validationRules: [] },
    recipients: { value: [], errors: [], validationRules: ['emailList'] },
  },
  isInviting: false,
  messageSent: false,
  pending: false,
  loading: false,
  success: false,
  error: false,
  errorMessage: null,
}

const slice = createSlice({
  name: 'invitation',
  initialState: INITIAL_STATE,
  reducers: {

    setIsInviting: (state, { payload }) => ({
      ...state,
      isInviting: payload,
      messageSent: false,
    }),

    updateField: (state, { payload: { name, value, errors = [] } }) => ({
      ...state,
      data: {
        ...state.data,
        [name]: {
          ...state.data[name],
          value,
          errors,
        },
      },
    }),

    addRecipient: (state, { payload: { name, value } }) => ({
      ...state,
      data: {
        ...state.data,
        recipients: {
          ...state.data.recipients,
          value: [
            ...state.data.recipients.value,
            value
          ],
        },
        email: {
          ...state.data.email,
          value: '',
        }
      },
    }),

    removeRecipient: (state, { payload }) => ({
      ...state,
      data: {
        ...state.data,
        recipients: {
          ...state.data.recipients,
          value: [
            ...state.data.recipients.value.filter(recipient => recipient !== payload),
          ]
        }
      }
    }),

    sendInvitation: state => ({
      ...state,
      pending: true,
      error: false,
      success: false,
      data: Object
        .entries(state.data)
        .reduce((acc, [name, field]) => ({
          ...acc,
          [name]: { ...field, errors: [] },
        }), state.data),
    }),


    apiError: (state, action) => ({
      ...state,
      errorMessage: action.payload,
      pending: false,
      error: true,
      success: false,
    }),

    invalidate: (state, { payload: { message } }) => ({
      ...state,
      sending: false,
      error: true,
      errorMessage: message,
    }),

    success: (state, { payload: id }) => ({
      ...state,
      pending: false,
      error: false,
      messageSent: true,
      colleagueId: id
    }),

    clean: state => INITIAL_STATE,
  },
})

export const {
  setIsInviting,
  updateField,
  addRecipient,
  removeRecipient,
  sendInvitation,
  apiError,
  clean,
  invalidate,
  success,
} = slice.actions

export const selectInvitationData = state => state.invitation.data
export const selectIsInviting = state => state.invitation.isInviting
export const selectMessageSent = state => state.invitation.messageSent

export default slice.reducer
