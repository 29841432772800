import * as Resources from '../State/Resources'
import * as Login from '../State/Login'
import * as Eff from 'redux-saga/effects'
import * as Api from '../Util/Api'

/**
 * This saga is loading every required external resources
 * for a teleexpertise creation such as : administrators, labels and more
 */
export function* load({ payload }) {
  try {
    const filter = payload && payload.filter
    const query = payload && payload.query

    if (!filter || filter.includes(Resources.RESOURCE_MEMBERS)) {
      const members = yield Eff.call(Api.fetchMembers, query)

      yield Eff.put(Resources.received({
        name: Resources.RESOURCE_MEMBERS,
        data: members,
      }))
    }

    if (!filter || filter.includes(Resources.RESOURCE_DISCIPLINES)) {
      const disciplines = yield Eff.call(Api.fetchDisciplines)

      yield Eff.put(Resources.received({
        name: Resources.RESOURCE_DISCIPLINES,
        data: disciplines,
      }))
    }

    if (!filter || filter.includes(Resources.RESOURCE_OPINIONS)) {
      const { opinions } = yield Eff.call(Api.fetchDefaultOpinions, query)

      yield Eff.put(Resources.received({
        name: Resources.RESOURCE_OPINIONS,
        data: opinions,
      }))
    }

    if (!filter || filter.includes(Resources.RESOURCE_PATIENTS)) {
      const userId = yield Eff.select(Login.selectUserId)
      const params = `&withPatientsCreatedByUser=1&addUserStaffs=1&addUserCohorts=1&addUserOpinions=1`
      const patients = userId
        ? yield Eff.call(Api.fetchPatientsByUser, userId, query, params)
        : yield Eff.call(Api.fetchPatients)

      yield Eff.put(Resources.received({
        name: Resources.RESOURCE_PATIENTS,
        data: patients,
      }))
    }

    if (!filter || filter.includes(Resources.RESOURCE_USER_PATIENTS)) {
      const userId = yield Eff.select(Login.selectUserId)
      const params = `&withPatientsCreatedByUser=1&addUserStaffs=1&addUserCohorts=1&addUserOpinions=1`
      const patients = yield Eff.call(Api.fetchPatientsByUser, userId, query, params)

      yield Eff.put(Resources.received({
        name: Resources.RESOURCE_USER_PATIENTS,
        data: patients,
      }))
    }

    if (!filter || filter.includes(Resources.RESOURCE_ORGANIZATIONS)) {
      const organizations = yield Eff.call(Api.fetchOrganizations, { search: query, onlyManagedOrMember: 0 })

      yield Eff.put(Resources.received({
        name: Resources.RESOURCE_ORGANIZATIONS,
        data: organizations,
      }))
    }

    if (!filter || filter.includes(Resources.RESOURCE_PATIENT_MANAGERS)) {
      const { colleagues: patientManagers } = yield Eff.call(Api.fetchColleaguesNoSignal, query)
      yield Eff.put(Resources.received({
        name: Resources.RESOURCE_PATIENT_MANAGERS,
        data: [...patientManagers],
      }))
    }

  } catch (error) {
    console.log(error)
    yield Eff.put(Resources.apiError(error.message))
  }
}

const ResourcesSagas = function* () {
  yield Eff.takeLatest(`${Resources.load}`, load)
}

export default ResourcesSagas;
