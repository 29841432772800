import React, { useEffect, useState } from 'react'
import * as UploadFile from '../../State/UploadFile'
import { apiUrl } from '../../Util/Config'
import styled from 'styled-components'
import * as Ui from '../Ui'
import { mimeTypeIcon } from '../../Util/MimeTypeIcons'
import { useDispatch, useSelector } from 'react-redux'
import { useAlert } from 'react-alert'

/**
 * This component allow to upload plural images to a given server.
 * In order to make it works you must specify plural props:
 *
 * name: It's the uploader name (used internaly in order to create a state
 * inside `uploadFile`)
 *
 * endpoint: If you specify an endpoint each uploaded picture will be sent
 * to that endpoint. If anything goes wrong the image is automaticaly deleted.
 *
 * validationRules: Pass here the validation rules you want on each file object.
 * You can see an example of a file validation inside `Util/Form` with
 * the `noLargeFiles` validator
 *
 * label: Used to display a title
 *
 * subLabel: A text that will be dsiplay below the label (you can use
 * children instead)
 *
 * onChange: This function is called each time a file is added or removed and
 * takes the entire file collection as the it's first argument.
 *
 * onRemove: This function is called each time a file is deleted. It takes
 * the deleted file as the first argument
 *
 * previewUri: This little props is used in order to produce preview of the
 * image during the zoom.
 *
 * disabled: If disabled adding and removing file isn't possible.
 */
export const ImagesUpload = ({
  name = "",
  endpoint = null,
  saveEndpoint = null,
  removeEndpoint = null,
  sliceToUpdate = null,
  validationRules = [],
  label = "Imagerie & documents",
  subLabel = "",
  onChange = () => null,
  onRemove = () => null,
  previewUri = "/patients/imageries",
  value = [],
  disabled = false,
  multiple = false,
  isEditMode = false,
  children,
  hideInputButton = false,
  inputRef,
  isRounded,
  noLocalWarning,
  width,
  ...restProps
}) => {
  const alert = useAlert()
  const dispatch = useDispatch()
  const allData = useSelector(UploadFile.selectData)
  const errorMessage = useSelector(UploadFile.selectErrorMessage)
  const data = allData[name]
  const [zoomIndex, setZoomIndex] = useState(null)
  const canEdit = !disabled

  useEffect(() => {
    if (errorMessage && data?.errors.length > 0)
      alert.error(errorMessage, { timeout: 7000 })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessage, data])


  const isLoading = data => Object
    .values(data.value)
    .filter(file => file.sending)
    .length > 0

  const readFiles = e => {
    const files = e.target.files

    for (let file of files) {
      file.sending = true

      const reader = new FileReader();
      reader.onload = f => {
        file.fileName = f.target.result

        dispatch(UploadFile.upload({
          uploader: name,
          file,
        }))
      }

      reader.readAsDataURL(file)
    }
  }

  const onImageRemove = file => e => {
    e.preventDefault()
    dispatch(UploadFile.remove({
      uploader: name,
      name: file.name,
    }))

    onRemove(file)
  }

  useEffect(() => {
    if (!data || (data && Object.keys(data.value).length === 0)) {
      dispatch(UploadFile.newUploader({
        name,
        saveEndpoint,
        removeEndpoint,
        sliceToUpdate,
        endpoint,
        validationRules,
      }))

      if (value?.length !== 0) {
        value
          .map(image => UploadFile.attach({
            uploader: name,
            file: {
              ...image,
              name: image.name || image.fileName,
            },
          }))
          .map(dispatch)
      }
    }

    // return () => dispatch(UploadFile.clean(name))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, value.length, name])

  useEffect(() => {
    if (!data) {
      return;
    }

    if (isLoading(data)) {
      return;
    }

    onChange(data.value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!data) {
    return null
  }

  return (
    <>
      {zoomIndex !== null && (
        <Ui.DocumentPreview.Zoom
          onClose={() => setZoomIndex(null)}
          zoomImage={zoomIndex}
          setZoomImage={setZoomIndex}
          images={Object.values(data.value)}
          previewUri={previewUri}
        />
      )}
      {(Object.values(data.value).length > 0 || isEditMode) &&
        <>
          {label && <Label>{label}</Label>}
          {subLabel && <Ui.BasicElement.P>{subLabel || children}</Ui.BasicElement.P>}
        </>
      }
      <ImagesUploadContainer flow="row wrap">
        {Object
          .values(data.value)
          .filter(f => !f.sending)
          .map((file, index) => (
            <Ui.DocumentPreview.ImagePreview
              key={`uploader_${name}_image_${index}`}
              onClick={() => setZoomIndex(index)}
              onRemove={onImageRemove(file)}
              background={file.fileName
                ? `${apiUrl}${previewUri}/${file.fileName.replace(/.*\//, '')}`
                : null}
              canEdit={canEdit}
              isRounded={isRounded}
              width={width}
            />
          ))}
        {Object.values(data.value).length === 0
          ? <Ui.Form.ImageFileUpload
            hideInputButton={hideInputButton}
            loading={data ? isLoading(data) : true}
            onChange={readFiles}
            onClick={e => e.target.value = null}
            id={restProps.id || name}
            disabled={!canEdit}
            width={width}
            {...restProps}
          />
          : isLoading(data)
            ? <LoaderPlaceholder><Ui.Global.Loader /></LoaderPlaceholder>
            : null
        }
      </ImagesUploadContainer>
      {data.errors.length > 0 && !noLocalWarning && (
        <ErrorMessageContainer>
          <Ui.Form.FieldErrorMessage>
            <div key={`uploader_${name}_error`}>{data.errors[0]}</div>
          </Ui.Form.FieldErrorMessage>
        </ErrorMessageContainer>
      )}
    </>
  )
}

/**
 * This component allow to upload plural documents to a given server.
 * In order to make it works you must specify plural props:
 *
 * name: It's the uploader name (used internaly in order to create a state
 * inside `uploadFile`)
 *
 * endpoint: If you specify an endpoint each uploaded picture will be sent
 * to that endpoint. If anything goes wrong the image is automaticaly deleted.
 *
 * validationRules: Pass here the validation rules you want on each file object.
 * You can see an example of a file validation inside `Util/Form` with
 * the `noLargeFiles` validator
 *
 * label: Used to display a title
 *
 * subLabel: A text that will be dsiplay below the label (you can use
 * children instead)
 *
 * onChange: This function is called each time a file is added or removed and
 * takes the entre file collection as the it's first argument.
 *
 * onRemove: This function is called each time a file is deleted. It takes
 * the deleted file as the first argument
 *
 * disabled: If disabled adding and removing file isn't possible.
 */
export const DocumentsUpload = ({
  name = "",
  endpoint = null,
  saveEndpoint = null,
  removeEndpoint = null,
  sliceToUpdate = null,
  fieldId = null,
  validationRules = [],
  label = "Imagerie & documents",
  buttonLabel = "Ajouter des documents",
  apiPath,
  subLabel = "",
  onChange = () => null,
  onRemove = () => null,
  value = [],
  disabled = false,
  multiple = false,
  isEditMode = false,
  isTitleWarning,
  children,
  ...restProps
}) => {

  const dispatch = useDispatch()
  const allData = useSelector(UploadFile.selectData)
  const data = allData[name]
  const canEdit = !disabled

  const isLoading = data => Object
    .values(data.value)
    .filter(file => file.sending)
    .length > 0

  const readFiles = e => {
    const files = e.target.files
    for (let file of files) {
      file.sending = true
      const reader = new FileReader();
      reader.onload = f => {
        file.fileName = f.target.result

        dispatch(UploadFile.upload({
          uploader: name,
          file,
        }))
      }

      reader.readAsDataURL(file)
    }
  }

  const onFileRemove = file => e => {
    e.preventDefault()

    dispatch(UploadFile.remove({
      uploader: name,
      name: file.name,
    }))

    onRemove(file)
  }

  useEffect(() => {
    if (!data || (data && Object.keys(data.value).length === 0)) {
      dispatch(UploadFile.newUploader({
        name,
        saveEndpoint,
        removeEndpoint,
        sliceToUpdate,
        endpoint,
        validationRules,
      }))

      if (value?.length !== 0) {
        value
          .map(file => UploadFile.attach({
            uploader: name,
            file,
          }))
          .map(dispatch)
      }
    }

    // return () => dispatch(UploadFile.clean(name))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, value.length])

  useEffect(() => {
    if (!data || isLoading(data)) {
      return;
    }

    onChange(data.value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  if (!data) {
    return null
  }

  return (
    <>
      {label && <Label isTitleWarning={isTitleWarning}>{label}</Label>}
      {(Object.values(data.value).length > 0 || isEditMode) &&
        <>
          {(subLabel || children) && <Ui.BasicElement.P>{subLabel || children}</Ui.BasicElement.P>}
        </>
      }
      {Object.values(data.value).length === 0 && !isEditMode
        ? null
        : Object
          .values(data.value)
          .filter(f => !f.sending).length > 0
          ? <Ui.Layout.FlexBox flow="row wrap">
            {Object
              .values(data.value)
              .filter(f => !f.sending)
              .map((file, index) => (
                <PreviewContainer key={`uploader_${name}_document_${index}`}>
                  <Ui.DocumentPreview.DocumentPreview
                    onRemove={!canEdit ? null : onFileRemove(file)}
                    name={file.name}
                    title='Sans titre'
                    mimeType={file.type}
                    size={file.size}
                    fileName={file.fileName}
                    apiPath={apiPath}
                    iconSrc={mimeTypeIcon(file.type)}
                    showSeeAndDownloadButtons={file.id ? true : false}
                  >
                  </Ui.DocumentPreview.DocumentPreview>
                </PreviewContainer>
              ))
            }
          </Ui.Layout.FlexBox>
          : <Ui.BasicElement.P>Aucun document</Ui.BasicElement.P>
      }
      <Ui.Form.FileUpload
        label={buttonLabel}
        loading={isLoading(data)}
        onChange={readFiles}
        onClick={(event) => {
          event.target.value = null
        }}
        id={restProps.id || name}
        disabled={!canEdit}
        {...restProps}
      />
      {data.errors.length > 0 && (
        <ErrorMessageContainer>
          <Ui.Form.FieldErrorMessage>
            <div key={`uploader_${name}_error`}>{data.errors[0]}</div>
          </Ui.Form.FieldErrorMessage>
        </ErrorMessageContainer>
      )}
    </>
  )
}

const ErrorMessageContainer = styled.div`
  margin-top : 1.2rem;
`

const Label = styled(props => <Ui.Form.Label {...props} />)`
  display: block;
  margin-bottom: .7rem;
  margin-top: 1rem;
  color: ${props => props.isTitleWarning ? props.theme.cohorteRed : props.theme.grey} !important;
`

export const PreviewContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

export const LoaderPlaceholder = styled.div`
  margin-bottom: 1rem;
  margin-right: 1rem;
  box-sizing: border-box;
  font-size: 3rem;
  width: 6.25rem;
  height: 6.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  color:  ${props => props.theme.grey};
  background: ${props => props.theme.mediumGrey};
  border: 1px dashed ${props => props.theme.grey};
`

const ImagesUploadContainer = styled(props => <Ui.Layout.FlexBox {...props} />)`
  margin-bottom: .5rem;
`
