import { createSlice } from '@reduxjs/toolkit';
import {
  saveField as commonSaveField,
} from '../Util/InlineEditActions';

export const INITIAL_STATE = {
  data: {
    cohortId: { value: '', errors: [], validationRules: [] },
    title: { value: '', errors: [], validationRules: [] },
    description: { value: '', errors: [], validationRules: [] },
    isVideoconferencingToday: { value: false, errors: [], validationRules: [] },
    questionnaireImage: { value: '', errors: [], validationRules: [] },
    patientStepsImageStretch: { value: false, errors: [], validationRules: [] },
    patientStepsImageAlign: { value: 1, errors: [], validationRules: [] },
    datesByPatientStepId: { value: {}, errors: [], validationRules: [] },
    patientSteps: { value: [] },
  },
  currentStep: null,
  activeTab: 1,
  sending: false,
  loading: false,
  success: false,
  error: false,
  isEditMode: {
    builder: [],
    viewer: [],
  },
  errorMessage: null,
}

const slice = createSlice({
  name: 'questionnaire',
  initialState: INITIAL_STATE,
  reducers: {

    addStep: state => ({
      ...state,
      data: {
        ...state.data,
        patientSteps: {
          ...state.data.patientSteps,
          value: [
            ...state.data.patientSteps.value,
            {
              id: { value: null, errors: [], validationRules: [] },
              title: { value: '', initialValue: '', isFieldEdit: true, errors: [], validationRules: ['notEmpty'] },
              description: { value: '', initialValue: '', isFieldEdit: false, errors: [], validationRules: [] },
              manualProgrammingDate: { value: false, errors: [], validationRules: [] },
              period: { value: 'day', errors: [], validationRules: [] },
              periodQuantity: { value: '', errors: [], validationRules: [] },
              date: { value: '', errors: [], validationRules: [] },
              activeVisioconferencing: { value: false, errors: [], validationRules: [] },
              documents: { value: [], errors: [], validationRules: [] },
              sections: { value: [], errors: [], validationRules: [] },
              isDeadline: { value: false, errors: [], validationRules: [] },
            },
          ]
        }
      },
      isEditMode: {
        ...state.isEditMode,
        builder: [
          ...state.isEditMode.builder,
          {
            date: false,
          }
        ]
      }
    }),

    removeStep: (state, { payload: { idx, stepId, cohortId } }) => ({
      ...state,
      data: {
        ...state.data,
        patientSteps: {
          ...state.data.patientSteps,
          value: [
            ...state.data.patientSteps.value.filter((_, i) => idx !== i),
          ]
        }
      },
    }),

    setEditField : (state, { payload: { idx, fieldName, value } }) => ({
      ...state,
      data: {
        ...state.data,
        patientSteps: {
          ...state.data.patientSteps,
          value: state.data.patientSteps.value.map((step, i) =>
            Number(i) === Number(idx)
              ? ({
                ...step,
                [fieldName]: {
                  ...step[fieldName],
                  isFieldEdit: value
                }
              })
              : ({ ...step })
          ),
        },
      }
    }),

    restoreInitialValue : (state, { payload: { idx, fieldName, value } }) => ({
      ...state,
      data: {
        ...state.data,
        patientSteps: {
          ...state.data.patientSteps,
          value: state.data.patientSteps.value.map((step, i) =>
            Number(i) === Number(idx)
              ? ({
                ...step,
                [fieldName]: {
                  ...step[fieldName],
                  value,
                  errors: []
                }
              })
              : ({ ...step })
          ),
        },
      }
    }),

    updateInitialValue : (state, { payload: { idx, fieldName, value } }) => ({
      ...state,
      data: {
        ...state.data,
        patientSteps: {
          ...state.data.patientSteps,
          value: state.data.patientSteps.value.map((step, i) =>
            Number(i) === Number(idx)
              ? ({
                ...step,
                [fieldName]: {
                  ...step[fieldName],
                  initialValue: value,
                }
              })
              : ({ ...step })
          ),
        },
      }
    }),

    setEditMode: (state, { payload: { idx, blockName, value } }) => ({
      ...state,
      isEditMode: {
        ...state.isEditMode,
        builder:
          state.isEditMode.builder.map((_, i) => idx === i && {
            ...state.isEditMode.builder[idx],
            [blockName]: value,
          })
      }
    }),

    setPatientEditMode: (state, { payload: { idx, value } }) => ({
      ...state,
      isEditMode: {
        ...state.isEditMode,
        viewer: state.isEditMode.viewer.map((el, i) => idx === i ? value : el)
      }
    }),

    updateField: (state, { payload: { idx, name, value, errors = [] } }) => ({
      ...state,
      data: {
        ...state.data,
        patientSteps: {
          ...state.data.patientSteps,
          value: state.data.patientSteps.value.map((step, i) =>
            Number(i) === Number(idx)
              ? ({
                ...step,
                [name]: {
                  ...step[name],
                  value,
                  errors
                }
              })
              : ({ ...step })
          ),
        },
      },
      error: false,
      errorMessage: null,
    }),

    updatePatientDateField: (state, { payload: { idx, name, value, errors = [] } }) => ({
      ...state,
      data: {
        ...state.data,
        patientSteps: {
          ...state.data.patientSteps,
          value: state.data.patientSteps.value.map((step, i) =>
            Number(i) === Number(idx)
              ? ({
                ...step,
                manualProgrammingDate: { ...step.manualProgrammingDate, value, errors }
              })
              : ({ ...step })
          ),
        },
      },
      error: false,
      errorMessage: null,
    }),

    checkSection: (state, { payload: { idx, value, errors = [] } }) => ({
      ...state,
      data: {
        ...state.data,
        patientSteps: {
          ...state.data.patientSteps,
          value: state.data.patientSteps.value.map((step, i) =>
            Number(i) === Number(idx)
              ? ({
                ...step,
                sections: {
                  ...step.sections,
                  value: [...step.sections.value, value],
                  errors
                }
              })
              : ({ ...step })
          ),
        },
      },
      error: false,
      errorMessage: null,
    }),

    uncheckSection: (state, { payload: { idx, value, errors = [] } }) => ({
      ...state,
      data: {
        ...state.data,
        patientSteps: {
          ...state.data.patientSteps,
          value: state.data.patientSteps.value.map((step, i) =>
            Number(i) === Number(idx)
              ? ({
                ...step,
                sections: {
                  ...step.sections,
                  value: step.sections.value.filter(section => Number(section) !== Number(value)),
                  errors
                }
              })
              : ({ ...step })
          ),
        },
      },
      error: false,
      errorMessage: null,
    }),

    apiError: (state, action) => ({
      ...state,
      errorMessage: action.payload,
      pending: false,
      sending: false,
      error: true,
      success: false,
    }),

    send: (state, { payload }) => ({
      ...state,
      sending: true,
    }),

    fetch: (state, action) => ({
      ...state,
      success: true,
    }),

    receivedDocuments: (state, { payload: { documents } }) => ({
      ...state,
      success: true,
    }),

    removeDocument: (state, { payload: { cohortId, assetId } }) => ({
      ...state,
      success: true,
    }),

    received: (state, { payload: {
      cohortId = null,
      title = '',
      description = '',
      isVideoconferencingToday = false,
      patientStepsImageAlign,
      patientStepsImageStretch,
      questionnaireImage = '',
      datesByPatientStepId,
      data,
    } }) => ({
      ...state,
      data: {
        ...state.data,
        questionnaireImage: {
          ...state.data.questionnaireImage,
          value: questionnaireImage,
        },
        patientStepsImageAlign: {
          ...state.data.patientStepsImageAlign,
          value: patientStepsImageAlign,
        },
        patientStepsImageStretch: {
          ...state.data.patientStepsImageStretch,
          value: patientStepsImageStretch,
        },
        isVideoconferencingToday: {
          ...state.data.isVideoconferencingToday,
          value: isVideoconferencingToday,
        },
        datesByPatientStepId: {
          ...state.data.datesByPatientStepId,
          value: datesByPatientStepId,
        },
        patientSteps: {
          ...state.data.patientSteps,
          value: data.map(questionnaire =>
            Object.entries(questionnaire)
              .reduce((acc, [field, value]) =>
              ({
                ...acc,
                [field]: {
                  value: value,
                  errors: [],
                  validationRules: field === 'title' ? ['notEmpty'] : [],
                  initialValue: value,
                  isFieldEdit: false,
                }
              }), {}))
        },
        title: {
          ...state.data.title,
          value: title,
          initialValue: title
        },
        cohortId: {
          ...state.data.cohortId,
          value: cohortId
        },
        description: {
          ...state.data.description,
          value: description
        },
      },
      isEditMode: {
        ...state.isEditMode,
        builder: data.map((_, idx) => {
          return {
            ...state.isEditMode.builder[idx],
            date: false
          }
        }),
        viewer: data.map(editMode => false),
      },
      loading: false,
    }),

    receivedSteps: (state, { payload: data }) => ({
      ...state,
      data: {
        ...state.data,
        patientSteps: {
          ...state.data.patientSteps,
          value: data.map(questionnaire =>
            Object.entries(questionnaire)
              .reduce((acc, [field, value]) =>
              ({
                ...acc,
                [field]: {
                  value: value,
                  errors: [],
                  validationRules: field === 'title' ? ['notEmpty'] : [],
                }
              }), {}))
        },
      },
      isEditMode: {
        ...state.isEditMode,
        builder: data.map(editMode => false)
      }
    }),

    sendQuestionnaire: (state, action) => ({
      ...state,
      sending: true,
    }),

    update: (state, action) => ({
      ...state,
      success: true,
    }),

    updateDocument: (state, { payload: { data, idx } }) => ({
      ...state,
      data: {
        ...state.data,
        patientSteps: {
          ...state.data.patientSteps,
          value: state.data.patientSteps.value
            .map((step, i) => i === idx
              ? ({
                ...step,
                documents: {
                  ...step.documents,
                  value: data
                }
              })
              : ({ ...step })
            )
        }
      },
    }),

    updateImage: (state, { payload = '' }) => ({
      ...state,
      data: {
        ...state.data,
        questionnaireImage: {
          ...state.data.questionnaireImage,
          value: payload,
        }
      },
    }),

    updateData: (state, { payload: { name, value, errors = [] } }) => ({
      ...state,
      data: {
        ...state.data,
        [name]: {
          ...state.data[name],
          value,
          errors,
        }
      },
    }),

    setCurrentStep: (state, { payload: currentStep }) => ({
      ...state,
      currentStep,
      activeTab: 1,
    }),

    setActiveTab: (state, { payload: activeTab }) => ({
      ...state,
      activeTab,
    }),

    success: (state, { payload }) => ({
      ...state,
      pending: false,
      sending: false,
      loading: false,
      error: false,
      success: true,
    }),

    invalidate: (state, { payload: { message } }) => ({
      ...state,
      pending: false,
      sending: false,
      error: true,
      errorMessage: message,
    }),

    submitField: (state, { payload }) => ({
      ...state,
    }),

    clean: state => INITIAL_STATE,

    commonSaveField,
  },
})

export const {
  addStep,
  removeStep,
  updateField,
  checkSection,
  uncheckSection,
  apiError,
  clean,
  send,
  sendQuestionnaire,
  update,
  received,
  receivedSteps,
  receivedDocuments,
  fetch,
  setCurrentStep,
  setActiveTab,
  setEditMode,
  setPatientEditMode,
  success,
  removeDocument,
  invalidate,
  submitField,
  updateDocument,
  updateImage,
  updateData,
  updatePatientDateField,
  commonSaveField: saveField,
  setEditField,
  restoreInitialValue,
  updateInitialValue,
} = slice.actions

export const selectData = state => state.questionnaire.data
export const selectLoading = state => state.questionnaire.loading
export const selectSending = state => state.questionnaire.sending
export const selectSuccess = state => state.questionnaire.success
export const selectCurrentStep = state => state.questionnaire.currentStep
export const selectActiveTab = state => state.questionnaire.activeTab
export const selectBuilderEditMode = state => state.questionnaire.isEditMode.builder
export const selectViewerEditMode = state => state.questionnaire.isEditMode.viewer
export const selectQuestionnaires = (state, id) =>
  state.questionnaire.data.patientSteps.value.filter(step => step.id === id)

export default slice.reducer
