import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from "react-router-dom";
import { useAlert } from 'react-alert'
import * as ContactUs from '../State/ContactUs'
import * as Menu from '../State/Menu'
import * as Ui from './Ui'
import styled from 'styled-components'
import LoadingBox from './Ui/LoadingBox'
import SEOTags from './SEOTags';

const ContactUsComponent = (props) => {
  const dispatch = useDispatch()
  const alert =useAlert()
  const currentUser = useSelector(state => state.login.currentUser)
  const success = useSelector(ContactUs.selectSuccess)
  const errorMessage = useSelector(state => state.contactUs.errorMessage)
  const history = useHistory();

  useEffect(() => {
    dispatch(Menu.changeActive(Menu.HELP))

    return () => {
      dispatch(ContactUs.clean())
    }
  }, [dispatch])

  const ContactForm = () => {
    const {
      pending,
      data: {
        from,
        name,
        content,
      },
    } = useSelector(state => state.contactUs)

    useEffect(() => {
      if (errorMessage)
        alert.error(errorMessage, { timeout: 5000 })
    }, [])

    useEffect(() => {
      if (currentUser) {
        dispatch(ContactUs.updateField({ name: 'from', value: currentUser.email }))
        dispatch(ContactUs.updateField({ name: 'name', value: currentUser.username }))
      }
    }, [])

    const updateField = name => e => {
      dispatch(ContactUs.updateField({ name, value: e.target.value }))
    }

    const send = (e) => {
      e.preventDefault()
      dispatch(ContactUs.send())
    }

    return success
      ? <>
        <SuccessMessage isPublicSite={!currentUser}>Votre message a bien été envoyé.</SuccessMessage>
        <SuccessMessage><Ui.Global.GreenText onClick={history.goBack}>Retour</Ui.Global.GreenText></SuccessMessage>
      </>
      : <>
        <SEOTags
          title="Assistance - Skemeet"
        />
        <Ui.Layout.Form onSubmit={send} noValidate>
          <MessageTitle>
            {currentUser
              ? <Ui.BasicElement.H2>
                Votre message à l'équipe Skemeet
              </Ui.BasicElement.H2>
              : <Ui.BasicElement.H1>
                Votre message
              </Ui.BasicElement.H1>
            }
          </MessageTitle>
          <Ui.Message.Container>
            <Ui.Layout.Row>
              <Ui.Form.TextInput
                htmlFor="from"
                label={`Votre adresse email ${currentUser ? '' : '*'}`}
                value={from.value}
                onChange={updateField('from')}
                readOnly={currentUser}
                error={from.errors[0]}
                noSubmit
              />
            </Ui.Layout.Row>
            <Ui.Form.TextInput
              htmlFor="name"
              label="Votre nom"
              value={name.value}
              onChange={updateField('name')}
              readOnly={currentUser}
              error={name.errors[0]}
              noSubmit
            />
            <Ui.Form.TextAreaInputResizable
              htmlFor="messageBody"
              label="Votre message"
              value={content.value}
              onChange={updateField('content')}
              error={content.errors[0]}
              noSubmit
            />
            <Ui.Button.BasicButton
              isFilled
              isGreen
              disabled={pending}
              type="submit"
            >
              {pending && <Ui.Global.Loader />}
              Envoyer le message
            </Ui.Button.BasicButton>
          </Ui.Message.Container>
        </Ui.Layout.Form>
      </>
  }

  return (
    <>
      <LoadingBox />
      {currentUser
        ? <Ui.Layout.PageLayout currentUser={currentUser}>
          <ContactUsContainer>
            <Ui.Layout.PageContentWrapper>
              <Ui.Layout.PanelContainer>
                <Ui.Layout.Panel>
                  <Ui.Layout.TelexpertiseThumbnailContainer>
                    <Ui.Layout.TelexpertiseLogo>
                      <Ui.Icon.IconContactUs />
                    </Ui.Layout.TelexpertiseLogo>
                    <Ui.BasicElement.H3 noMargin>Assistance Skemeet</Ui.BasicElement.H3>
                  </Ui.Layout.TelexpertiseThumbnailContainer>
                  <Ui.Layout.TelexpertiseThumbnailContainer>
                    <Ui.BasicElement.H2 noMargin>Nous sommes là pour répondre à vos questions.</Ui.BasicElement.H2>
                  </Ui.Layout.TelexpertiseThumbnailContainer>
                </Ui.Layout.Panel>
              </Ui.Layout.PanelContainer>
              <Ui.Layout.Content hasPanel noPadding>
                <Ui.Layout.PaddedContent>
                    <ContactForm />
                </Ui.Layout.PaddedContent>
              </Ui.Layout.Content>
            </Ui.Layout.PageContentWrapper>
          </ContactUsContainer>
        </Ui.Layout.PageLayout>

        : <Ui.PortailLayout.PageLayout isHelpPage>
          <ContactUsContainer isPublicSite>
            <Ui.PortailLayout.Content hasPanel noPadding>
              <Ui.Layout.NavContainer isPublicSite>
                <nav>
                  <Ui.Layout.NavLink>
                    <Ui.Icon.IconMail />
                    Assistance Skemeet
                  </Ui.Layout.NavLink>
                </nav>
              </Ui.Layout.NavContainer>
              <ContactForm />
            </Ui.PortailLayout.Content>
          </ContactUsContainer>
        </Ui.PortailLayout.PageLayout>
      }
    </>
  )
}

const SuccessMessage = styled(props => <Ui.BasicElement.P {...props} />)`
  text-align: center;
  font-size: 1.2rem;
  cursor: pointer;
  &:first-child {
    margin-top: 3rem;
  }
  margin-top: ${props => props.isPublicSite ? '2rem' : '0'};
`

const ContactUsContainer = styled.div`
 ${Ui.BasicElement.H1} {
   margin-top: 2rem;
 }
 ${Ui.BasicElement.H2} {
   margin-top: ${props => props.isPublicSite ? '2rem' : '0'};
 }
 ${Ui.Layout.Content} {
   background-color: ${props => props.isPublicSite ? 'white' : props.theme.lightGrey};
 }
 ${Ui.PortailLayout.Content} {
  @media only screen and (min-width: ${props => props.theme.device.desktop}) {
      margin-left: 0;
    }
 }
 ${Ui.PortailLayout.Figure} {
   @media only screen and (min-width: ${props => props.theme.device.desktop}) {
      margin-left: 2rem;
      height: 600px !important;
    }
  }
 ${Ui.Layout.TelexpertiseThumbnailContainer} {
   margin-top: 1.5rem;
   ${Ui.BasicElement.H3} {
     margin-top: 0;
   }
 }
 ${Ui.Layout.NavLinkItem} {
  @media only screen and (min-width: ${props => props.theme.device.desktop}) {
    margin-right: .5rem;
  }
 }
  @media only screen and (max-width: ${props => props.theme.device.desktop}) {
    margin: 1rem;
  }
  ${Ui.SearchBar.SearchBarContainer} {
    margin-bottom: 0;
    width: 100%;
  }
  ${Ui.Form.Input} {
    &:read-only {
      border-color: transparent;
      opacity: 1;
    }
  }
  ${Ui.Search.FiltersAndSorting} {
    justify-content: flex-end;
    &:first-child {
      margin-bottom: 1rem;
    }
  }
  ${Ui.Layout.Content} {
    padding-bottom: 2rem;
    ${Ui.Layout.ButtonGroup} {
      margin-top: .7rem;
    }
    ${Ui.Message.Body} {
      ${Ui.BasicElement.P} {
        margin: 0;
      }
    }
    @media only screen and (min-width: ${props => props.theme.device.desktop}) {
      & > :not(:first-child) {
        margin-top: 5rem;
      }
    }
    @media only screen and (max-width: ${props => props.theme.device.desktop}) {
      padding:0;
    }
  }
  ${Ui.BasicElement.H3} {
    margin-top: 2rem;
    line-height: 2rem;
  }
  ${Ui.Select.SelectContainer} {
    width: 21rem;
    @media only screen and (max-width: ${props => props.theme.device.desktop}) {
      width: 85%;
    }
  }
  ${Ui.Button.Button} {
    margin-top: 1rem;
  }
  ${Ui.Form.Label} {
    ${Ui.SearchBar.SearchBarContainer} {
      margin-top: .6rem;
    }
  }
  ${Ui.Layout.TelexpertiseLogo} {
    background-color: ${props => props.theme.patientGreen};
  }
  a {
    flex: 0;
    text-decoration: none;
  }
  nav {
    margin: ${props => props.isPublicSite ? '0' : '0 2rem'};
  }
  ${Ui.Button.Button} {
    margin-top: 1rem;
  }
  ${Ui.Icon.IconHelpNoBorder} {
    font-weight: 600;
  }
`

const MessageTitle = styled(props => <Ui.Layout.Row {...props} />)`
  ${Ui.BasicElement.H2} {
    margin-bottom: 0;
  }
`

export default ContactUsComponent;
