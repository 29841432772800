import React from 'react'
import * as Ui from '../Ui'
import CustomFieldViewer from '../Ui/CustomFieldViewer'
import { useDispatch } from 'react-redux';
import * as Patient from '../../State/Patient';
import styled from 'styled-components/macro'
import { mimeTypeIcon } from '../../Util/MimeTypeIcons'

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default ({
  data,
  forms,
  tabName = '',
  isEditMode = false,
  patientCode,
  isVideoconferencingToday = false,
  teleExpertiseName,
  teleExpertiseId,
}) => {

  const dispatch = useDispatch();

  const filteredForms = (ids) => forms
    .map(form =>
      Object
        .entries(form)
        .reduce((acc, [key, value]) => ({
          ...acc,
          [key]: key === 'sections'
            ? {
              ...value,
              allIds: value
                .allIds
                .filter(sectionId =>
                  ids.includes(Number(sectionId))),
            }
            : value,
        }), {})
    )

  const onDocumentRemove = ({ id }) => dispatch(Patient.removePublicDocument({ patientCode, id }))

  return (
    <Ui.Layout.Section>
      <StepContainer>
        {Object
          .values(data)
          .map(({ description, documents, sections }, idx) =>
            <div key={idx}>
              {tabName === 'description' &&
                <Ui.BasicElement.P>
                  {description.value}
                </Ui.BasicElement.P>
              }
              {tabName === 'visio' && isVideoconferencingToday &&
                <Ui.Button.BasicButton
                  $isFilled
                  $isGreen
                  type="button"
                  onClick={() => window.open(`/patientVideoconference/videoconference/${teleExpertiseName}s/${teleExpertiseId}/${patientCode}`, 'zoom')}
                >
                  Visioconférence
                </Ui.Button.BasicButton>
              }
              {tabName === 'documents'
                ? documents.value.length > 0
                  ? <>
                    <Ui.BasicElement.H3>
                      Documents
                    </Ui.BasicElement.H3>
                    <Ui.UploadFile.PreviewContainer>
                      {documents.value.map(({ fileName, name, size, mimeType, title }, idx) =>
                        <Ui.DocumentPreview.DocumentPreview
                          key={`document_${idx}`}
                          name={name}
                          mimeType={mimeType}
                          apiPath="/public/cohort-asset-patient-step/"
                          size={size}
                          fileName={fileName}
                          title={title}
                          iconSrc={mimeTypeIcon(mimeType)}
                        >
                        </Ui.DocumentPreview.DocumentPreview>
                      )}
                    </Ui.UploadFile.PreviewContainer>
                  </>
                  : <Ui.BasicElement.P>Aucun document n'est associé à cette étape.</Ui.BasicElement.P>
                : null
              }
              {tabName === 'questionnaire'
                ? <CustomFieldViewer
                  forms={filteredForms(sections.value, idx)}
                  isEditFields={{ cohorts: { [forms[0].id]: { customFields: isEditMode } } }}
                  isQuestionnaire={true}
                  onDocumentRemove={onDocumentRemove}
                  patientCode={patientCode}
                />
                : null
              }
            </div>
          )
        }
      </StepContainer>
    </Ui.Layout.Section>
  )
}

export const StepContainer = styled.div`
  display: flex;
  border-radius: 5px;
  flex-direction: column;
  &:not(:last-child) {
    margin-bottom: 2.5rem;
  }
  & > ${Ui.Select.SelectContainer} {
    width: 20rem;
  }
  ${Ui.BasicElement.H1} {
    margin-top: 1.5rem;
    font-size: 1.7rem;
  }
  ${Ui.BasicElement.H2} {
    font-size: 1.4rem;
  }
  ${Ui.UploadFile.PreviewContainer} {
    //margin-bottom: 2rem;
  }
  ${Ui.BasicElement.H3}, ${Ui.Form.Label} {
    font-size: 1rem;
    //color: ${props => props.isTitleWarning ? props.cohorteRed : props.theme.darkBlue} !important;
  }
  ${Ui.Form.Label} {
    &:disabled {
      font-weight: 700;
    }
  };
`
