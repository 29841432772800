import React from 'react';
import styled from 'styled-components/macro'
import * as Ui from './Ui'
import CguContent from './Ui/CguContent';
import SEOTags from './SEOTags';

const CguComponent = () => {

  return (
    <>
      <SEOTags
        title="Conditions générales d'abonnement et d'utilisation Plateforme Skemeet"
      />
      <Ui.PortailLayout.PageLayout fullWidth>
        <CguContainer>
          <Ui.BasicElement.H1>Conditions générales d'abonnement et d'utilisation</Ui.BasicElement.H1>
          <Ui.BasicElement.H1>Plateforme Skemeet</Ui.BasicElement.H1>
          <CguContent />
        </CguContainer>
      </Ui.PortailLayout.PageLayout>
    </>
  )
}

const CguContainer = styled(props => <Ui.PortailLayout.PageContainer {...props} />)`
  margin: 1rem;
  margin-top: 2rem;
  @media only screen and (min-width: ${props => props.theme.device.desktop}) {
    margin-top: 3rem;
  }
  ${Ui.Form.InputContainer}:last-of-type {
    margin-bottom: .2rem;
  }

  ${Ui.BasicElement.H1} {
    margin-bottom: 1rem;
  }

  ${Ui.BasicElement.H1} + ${Ui.BasicElement.H1} {
    margin-top: 0;
  }

  ${Ui.BasicElement.Link} {
    font-size: .875rem;
    font-weight: 700;
  }
`

export default CguComponent;
