import * as ColleagueList from '../State/ColleagueList'
import * as Eff from 'redux-saga/effects'
import * as Form from '../Util/Form'
import * as Api from '../Util/Api'
import * as Resources from '../State/Resources'
import { debounceTime } from '../Util/Date'

export function* fetchColleaguesOrOrganizations({
  payload: {
    page = 1,
  } = {},
} = {}) {
  const abortController = new AbortController();
  try {
    const rawFilters = yield Eff.select(ColleagueList.selectFilters)
    const query = rawFilters.search
    if (query) {
      const delayMs = debounceTime(query)
      yield Eff.delay(delayMs)
    }
    const filters = {
      ...rawFilters,

      limit: Number(process.env.REACT_APP_DEFAULT_RESULT_LIMIT),
      offset: (page - 1) * process.env.REACT_APP_DEFAULT_RESULT_LIMIT,
    }

    if (filters.displayBy === ColleagueList.DISPLAY_ORGANIZATION) {
      const organization = yield Eff.call(Api.fetchOrganizations, {
        search: filters.search,
        onlyManagedOrMember: 0
      })

      yield Eff.put(ColleagueList.receivedOrganizations(organization))
    } else {
      const { colleagues, keywordsNotFound } = yield Eff.call(Api.fetchColleagues, filters, { signal: abortController.signal })

      yield Eff.put(ColleagueList.received({
        data: colleagues,
        mode: page > 1
          ? ColleagueList.MODE_RECEIVED_MORE
          : ColleagueList.MODE_RECEIVED_FIRST,
        keywordsNotFound,
      }))
    }
  } catch (error) {
    yield Eff.put(ColleagueList.apiError(error.message))
  } finally {
    if (yield Eff.cancelled()) {
      abortController.abort();
    }
  }
}

export function* sendInvitation() {
  try {
    const data = yield Eff.select(ColleagueList.selectInvitationData)
    const validatedData = yield Eff.call(Form.validate, data)
    const staffs = validatedData
      .expertises
      .value
      .filter(e => e.type === 'staffs')
      .map(e => Number(e.id))
    const cohorts = validatedData
      .expertises
      .value
      .filter(e => e.type === 'cohorts')
      .map(e => Number(e.id))
    const opinions = validatedData
      .expertises
      .value
      .filter(e => e.type === 'opinions')
      .map(e => Number(e.id))

    const patients = validatedData.patients.value.map(id => Number(id))

    const teleExpertisesIds = {
      staffs,
      cohorts,
      opinions,
    }

    yield Eff.call(Api.sendInvitation,
      validatedData.email.value,
      validatedData.message.value,
      patients,
      validatedData.recipients.value,
      teleExpertisesIds,
    )

    yield Eff.put(ColleagueList.setIsInviting(false))
    yield Eff.put(ColleagueList.invitationSuccess())
    yield Eff.put(ColleagueList.cleanInvitation())

  } catch (error) {
    if (error instanceof Form.ValidationError) {
      for (let [name, field] of Object.entries(error.data)) {
        yield Eff.put(ColleagueList.updateField({
          name,
          value: field.value,
          errors: field.errors,
        }))
      }
      yield Eff.put(ColleagueList.invalidate(
        'Une information obligatoire est manquante pour valider la création de votre patient.'
      ))
    } else {
      yield Eff.put(ColleagueList.apiError(error.message))
    }
  }
}

export function* fetchExpertises(type, query) {
  try {
    yield Eff.put(ColleagueList.fetchAvailableExpertises())
    const types = type === 'expertises'
      ? 'staffs,opinions,cohorts'
      : type
    const { teleExpertises } = yield Eff.call(Api.getTeleExpertisesAllNoCf, types, query)
    yield Eff.put(ColleagueList.receivedAvailableExpertises(teleExpertises))
  } catch (error) {
    yield Eff.put(ColleagueList.apiError(error.message))
  }
}

export function* setQuery({ payload: { name, value: query } }) {
  try {
    if (query) {
      const delayMs = debounceTime(query)
      yield Eff.delay(delayMs)
      if (name === 'patients') {
        yield Eff.put(Resources.load({
          filter: [Resources.RESOURCE_PATIENTS],
          query,
        }))
      } else {
        yield fetchExpertises(name, query)
      }
    }

  } catch (error) {
    yield Eff.put(ColleagueList.apiError(error.message))
  }
}

const ColleagueListSagas = function* () {
  yield Eff.takeLatest([
    `${ColleagueList.fetch}`,
    `${ColleagueList.changeDisplayBy}`,
    `${ColleagueList.changeFilterBy}`,
  ], fetchColleaguesOrOrganizations)
  yield Eff.takeLatest(`${ColleagueList.changeSearch}`, fetchColleaguesOrOrganizations)
  yield Eff.takeLatest(`${ColleagueList.setQuery}`, setQuery)
  yield Eff.takeLatest(`${ColleagueList.sendInvitation}`, sendInvitation)
}

export default ColleagueListSagas;
