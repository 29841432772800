import React, { useEffect } from 'react'
import styled from 'styled-components/macro'
import { privateSitePrefix } from '../Util/Config'
import { iPadAndIPhoneAndIPodCheck } from '../Util/Browser'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import * as VideoConference from '../State/VideoConference';
import * as Ui from './Ui'
import { useAlert } from 'react-alert'

const VideoConferenceComponent = (props) => {

  const alert = useAlert()
  const dispatch = useDispatch()
  const history = useHistory()
  const { match: { params: { teleExpertiseType, teleExpertiseId, patientCode, staffVideoconferenceId } } } = props

  const errorMessage = useSelector(VideoConference.selectErrorMessage)
  const visioRoomLink = useSelector(VideoConference.selectVideoConferencingRoomLink)
  const visioLoading = useSelector(VideoConference.selectVisioLoading)
  const seeVisioPassword = useSelector(VideoConference.selectSeeVisioPassword)
  const visioRoomPassword = useSelector(VideoConference.selectVisioRoomPassword)
  const apiErrorMessage = useSelector(VideoConference.selectApiErrorMessage)

  useEffect(() => {
    if (errorMessage)
      alert.error(errorMessage, { timeout: 5000 })
  }, [alert, errorMessage])

  useEffect(() => {
    dispatch(VideoConference.fetchVideoconferencingRoomLink({ teleExpertiseType, teleExpertiseId, patientCode, staffVideoconferenceId }))

    return () => {
      dispatch(VideoConference.clean())
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, teleExpertiseType, teleExpertiseId])

  if (visioRoomLink) {
    if (iPadAndIPhoneAndIPodCheck()) {
      dispatch(VideoConference.setSeeVisioPassword(true))
    } else {
      window.location = visioRoomLink
    }
  }

  if (apiErrorMessage) {
    alert.error(apiErrorMessage, { timeout: 5000 })
    history.push(`${privateSitePrefix}/${teleExpertiseType}/${teleExpertiseId}`)
  }

  return (
    <>
      {visioLoading &&
        <>
          Loading...
        </>
      }
      {seeVisioPassword &&
        <>
          <VisioPasswordStep>
            <VisioPasswordStepDiv>Mot de passe pour se connecter à la visioconférence :</VisioPasswordStepDiv>
            <VisioPasswordStepDiv><b><Ui.Global.GreenText>{visioRoomPassword}</Ui.Global.GreenText></b></VisioPasswordStepDiv>
            <VisioPasswordStepDiv>
              <Ui.Button.BasicButton
                isGreen
                onClick={() => window.location = visioRoomLink}
              >
                Visioconférence
              </Ui.Button.BasicButton>
            </VisioPasswordStepDiv>
          </VisioPasswordStep>
        </>
      }
    </>
  )
}

const VisioPasswordStep = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const VisioPasswordStepDiv = styled.div`
  margin-top: 1rem;
`

export default VideoConferenceComponent;
