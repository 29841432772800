import React from 'react'
import { apiUrl } from '../../Util/Config'
import styled from 'styled-components'

const SmallCardContainer = styled.div`
  display: flex;
  padding: .5rem;
  box-sizing: border-box;
  color: ${props => props.theme.darkBlue};
  flex-wrap: nowrap;
  padding: 0;
  width: 100%;
  margin-bottom: 1rem;

  a {
    text-decoration: none;
    color: black;
  }
  @media only screen and (min-width: ${props => props.theme.device.desktop}) {
    width: 33.33333%;
  }
`

const Card = styled.div`
  box-sizing: border-box;
  font-size: .8rem;
  background: ${props => props.theme.grey};
  display: flex;
  background-color: white;
  padding: .7rem;
  border-radius: 5px;
  box-shadow: 0 2px 5px 1px rgba(134,174,197,0.24);
  flex: 1;
  img {
    width: 3rem;
    height: 3rem;
    flex: initial;
    border-radius: 50px;
  }
  @media only screen and (min-width: ${ props => props.theme.device.desktop}) {
    margin-right: 1.3rem;
  }
`

const SmallCardContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`

const SmallCardFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1;
`

const SmallCardHeader = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  img {
    width: 2.5rem;
    height: 2.5rem;
  }
`

const SmallCardTitle = styled.div`
  flex: 1;
  font-size: 0.75rem;
  margin-left: .8rem;
  align-self: center;
  cursor: pointer;
  & > div:first-child {
    margin-bottom: .1rem;
    font-size: 0.9375rem;
    font-weight: 700;
  }
  & > div:last-child {
    margin-top: .8rem;
  }
`

export const SmallCard = ({
  label = '',
  subLabel = '',
  picture = null,
  children,
  to = '#',
  onClick= () => null,
  ...props
}) => {

  return (
    <SmallCardContainer>
      <Card>
        <SmallCardContent>
          <SmallCardHeader>
            <img alt="Logo" src={`${apiUrl}/${picture}`} />
            <SmallCardTitle onClick={onClick}>
              <div>{label}</div>
              <div>{subLabel}</div>
            </SmallCardTitle>
          </SmallCardHeader>
          <SmallCardFooter>
          </SmallCardFooter>
        </SmallCardContent>
      </Card>
    </SmallCardContainer >
  )
}
