import * as WorkList from '../State/WorkList'
import * as Opinion from '../State/Opinion'
import { takeLatest, call, put, select } from 'redux-saga/effects'
import * as Api from '../Util/Api'
import { dateToString } from '../Util/Format'

export function* addWorkList({ payload: { teleExpertiseId } }) {
  try {
    const worklists = yield select(WorkList.selectData)
    const worklistId = Object
      .keys(worklists.byId)
      .find(worklist => worklist.indexOf('WL_') !== -1)
    const { patientList: { id: newWorklistId } } = yield call(Api.addWorkList, teleExpertiseId)
    yield put(WorkList.receivedWorkListId({ worklistId, newWorklistId }))
    yield put(WorkList.success())
  } catch (error) {
    yield put(WorkList.apiError(error.message))
  }
}

export function* removeWorkList({ payload: { teleExpertiseId, worklistId } }) {
  try {
    yield call(Api.removeWorkList, teleExpertiseId, worklistId)
  } catch (error) {
    yield put(WorkList.apiError(error.message))
  }
}

export function* updateWorkList({ payload: { teleExpertiseId, worklistId } }) {
  try {
    const worklists = yield select(WorkList.selectData)
    const title = worklists.byId[worklistId].title.value
    yield call(Api.updateWorkList, teleExpertiseId, worklistId, title)
  } catch (error) {
    yield put(WorkList.apiError(error.message))
  }
}

export function* addPatient({ payload: { teleExpertiseId, worklistId, patientId } }) {
  try {
    yield put(WorkList.updateField({ worklistId, name: 'isPatientListVisible', value: false }))
    yield put(WorkList.setAllPatientsVisible(false))
    yield call(Api.addPatientToWorkList, teleExpertiseId, worklistId, patientId)
    yield put(WorkList.success())
  } catch (error) {
    yield put(WorkList.apiError(error.message))
  }
}

export function* removePatient({ payload: { teleExpertiseId, worklistId, patientId } }) {
  try {
    yield call(Api.removePatientFromWorkList, teleExpertiseId, worklistId, patientId)
    yield put(WorkList.setAllPatientsVisible(false))
  } catch (error) {
    yield put(WorkList.apiError(error.message))
  }
}

export function* clearWorkList({ payload: { teleExpertiseId, worklistId } }) {
  try {
    yield call(Api.removeAllPatientsFromWorkList, teleExpertiseId, worklistId)
    yield put(WorkList.setAllPatientsVisible(false))
  } catch (error) {
    yield put(WorkList.apiError(error.message))
  }
}

export function* movePatient({ payload: { teleExpertiseId, worklistId, newWorklist: { patients: newPatientList }, patientId } }) {
  try {
    const patients = { [worklistId]: newPatientList.value }

    yield call(Api.reorderPatientFromWorkList, teleExpertiseId, patients, patientId)
  } catch (error) {
    yield put(WorkList.apiError(error.message))
  }
}

export function* movePatientBetweenLists({ payload: {
  teleExpertiseId,
  source,
  dest,
  patientId } }) {
  try {
    const patients = { [source.sourceId]: source.sourceList, [dest.destId]: dest.destList }

    yield call(Api.reorderPatientFromWorkList, teleExpertiseId, patients, patientId)
  } catch (error) {
    yield put(WorkList.apiError(error.message))
  }
}

export function* moveWorkList({ payload: { teleExpertiseId, newWorklists: worklists } }) {
  try {
    yield call(Api.reorderWorklists, teleExpertiseId, worklists)
  } catch (error) {
    yield put(WorkList.apiError(error.message))
  }
}

export function* schedulePatient({ payload: { teleExpertiseId, patient, planningDate, planningHour } }) {
  try {
    yield put(WorkList.setPlanningPatientListVisible(false))
    const scheduledDate = `${dateToString(planningDate)} ${planningHour}`
    yield put(WorkList.updateSelectedPatient(''))
    const { opinionPatientPlanning } = yield call(Api.schedulePatient, teleExpertiseId, patient.id, scheduledDate)
    yield put(WorkList.updatePatientList({ opinionPatientPlanning, patientId: patient.id, scheduledDate }))
    yield put(Opinion.updateExpertisesWithComments(patient.id))
    yield put(WorkList.success())
  } catch (error) {
    yield put(WorkList.apiError(error.message))
  }
}

export function* removePlanningPatient({ payload: { teleExpertiseId, patientPlanningId } }) {
  try {
    yield call(Api.removePlanningPatient, teleExpertiseId, patientPlanningId)
    yield put(WorkList.success())
  } catch (error) {
    yield put(WorkList.apiError(error.message))
  }
}

export function* sendMail({ payload: { teleExpertiseId, patientPlanningId } }) {
  try {
    yield call(Api.sendMailScheduledPatient, teleExpertiseId, patientPlanningId)
    yield put(WorkList.setMailSent(true))
  } catch (error) {
    yield put(WorkList.apiError(error.message))
  }
}

const WorkListSagas = function* () {
  yield takeLatest(`${WorkList.addWorkList}`, addWorkList);
  yield takeLatest(`${WorkList.removeWorkList}`, removeWorkList);
  yield takeLatest(`${WorkList.removePatient}`, removePatient);
  yield takeLatest(`${WorkList.clearWorkList}`, clearWorkList);
  yield takeLatest(`${WorkList.updateWorkList}`, updateWorkList);
  yield takeLatest(`${WorkList.addPatient}`, addPatient);
  yield takeLatest(`${WorkList.movePatient}`, movePatient);
  yield takeLatest(`${WorkList.movePatientBetweenLists}`, movePatientBetweenLists);
  yield takeLatest(`${WorkList.moveWorkList}`, moveWorkList);
  yield takeLatest(`${WorkList.schedulePatient}`, schedulePatient);
  yield takeLatest(`${WorkList.removePlanningPatient}`, removePlanningPatient);
  yield takeLatest(`${WorkList.sendMail}`, sendMail);
};

export default WorkListSagas;
