import React, { useEffect } from 'react'
import styled from 'styled-components/macro'
import * as Ui from '../Component/Ui'

export const EditableComponent = ({
  title,
  subTitle,
  text,
  content,
  children,
  isEditMode,
  altContent,
  altText,
  childRef,
  name,
  canEdit = false,
  isTitleWarning,
  onClick = () => { },
  onBlur = () => { },
  ...props
}) => {

  useEffect(() => {
    if (childRef && childRef.current && isEditMode) {
      if (childRef.current[name])
        childRef.current[name].focus();
      else if (childRef?.current?.focus)
        childRef.current.focus();
    }
  }, [isEditMode, childRef, name]);

  return isEditMode
    ? <EditBlock onBlur={onBlur}>
      {children}
    </EditBlock>
    : <ViewBlock onClick={canEdit ? onClick : null} {...props}>
      {title
        ? <Ui.Layout.ViewTitleContainer canEdit={canEdit}>
          <Ui.BasicElement.H3 noMargin isTitleWarning={isTitleWarning}>{title}</Ui.BasicElement.H3>
        </Ui.Layout.ViewTitleContainer>
        : subTitle
          ? <Ui.Layout.ViewTitleContainer>
            <Ui.BasicElement.H4>{subTitle}</Ui.BasicElement.H4>
          </Ui.Layout.ViewTitleContainer>
          : null
      }
      <Ui.Layout.ViewContentContainer canEdit={canEdit} isText={text || altText}>
        {content || text || altText || <span>{altContent}</span>}
      </Ui.Layout.ViewContentContainer>
    </ViewBlock >
}

export const ViewBlock = styled.div`
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  word-break: normal;
  overflow-wrap: anywhere;
`

export const EditBlock = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
`

export default EditableComponent;

