import * as Cohort from '../State/Cohort'
import * as Resources from '../State/Resources'
import * as Login from '../State/Login'
import * as Organization from '../State/Organization'
import * as CohortList from '../State/CohortList'
import * as CustomField from '../State/CustomField'
import * as CustomFieldEffect from '../Effect/CustomField'
import * as Questionnaire from '../State/Questionnaire'
import * as UploadFile from '../State/UploadFile'
import * as Eff from 'redux-saga/effects'
import * as Api from '../Util/Api'
import * as Form from '../Util/Form'
import { fr } from '../Util/i18n';
import { uniqById } from '../Util/Object'
import { debounceTime } from '../Util/Date'

const expertiseType = 'cohort'

export function* saveField({ payload: { teleExpertiseId: cohortId, fieldName } }) {
  try {
    const data = yield Eff.select(Cohort.selectData)
    const cohortId = yield Eff.select(Cohort.selectId)
    const fieldData = data[fieldName]
    const validatedData = yield Eff.call(Form.validate, { [fieldName]: fieldData })
    const apiFields = {
      portalHeaderImage: 'portalHeaderImageName',
      portalAbout: 'portalDescription'
    }

    if (!validatedData[fieldName].errors.length > 0) {
      const apiData = {
        cohort: fieldName === 'tags'
          ? {
            tags: validatedData.tags.value.filter(value => value),
          }
          : {
            [apiFields[fieldName] || fieldName]: validatedData[fieldName].value,
          }
      }

      const {
        labels,
        tags,
      } = yield Eff.call(Api.updateExpertise, apiData, cohortId, expertiseType)

      if (labels)
        yield Eff.put(Cohort.received({ labels }))
      else if (tags)
        yield Eff.put(Cohort.received({ tags }))

      yield Eff.put(Cohort.setEditField({ field: fieldName, value: false }))
      yield Eff.put(Cohort.updateInitialValue({ field: fieldName, value: fieldData.value }))
    }

    yield Eff.put(Cohort.success(cohortId))
  } catch (error) {
    console.log(error)
    if (error instanceof Form.ValidationError) {
      for (let [name, field] of Object.entries(error.data)) {
        yield Eff.put(Cohort.updateField({
          name,
          value: field.value,
          errors: field.errors,
        }))
      }
      yield Eff.put(Cohort.invalidate({
        message:
          'Une information obligatoire est manquante pour valider la mise à jour de vos données.'
      }))
    } else {
      yield Eff.put(Cohort.apiError(error.message))
    }
  }
}

export function* send() {
  try {
    const data = yield Eff.select(Cohort.selectData)
    const fileData = yield Eff.select(UploadFile.selectData)
    const cohortPicture = fileData['cohort-picture']

    yield Eff.call(Form.validate, { ...data })

    const expertise = yield Eff.call(Api.createExpertise, {
      [expertiseType]: {
        name: data.name.value,
        description: data.description.value,
        tags: data.tags.value,
        disciplines: data.disciplines.value,
        pictureFileName: Object
          .values(cohortPicture.value)
          .map(doc => (doc.fileName))
          .shift() || '',
        publicUri: data.publicUri.value,
      },
      labels: data
        .labels
        .value
        .filter(l => l.content !== ''),
    }, expertiseType)

    yield Eff.put(Cohort.success(expertise.id))
  } catch (error) {
    console.log(error)
    if (error instanceof Form.ValidationError) {
      if (error.data.validatedData) {
        const [name, field] = ['title', error.data.validatedData.title]

        yield Eff.put(Questionnaire.updateField({
          idx: error.data.idx,
          name,
          value: field.value,
          errors: field.errors,
        }))
        yield Eff.put(Cohort.invalidate({
          message: 'Une information obligatoire est manquante pour valider la création de votre étape.',
        }
        ))
      } else {
        const errorData =
          Object.entries(error.data)
            .filter(([_, data]) => data.errors.length > 0)
            .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {})

        for (let [name, field] of Object.entries(errorData).filter(([name, _]) => !/^CF_/.test(name))) {
          yield Eff.put(Cohort.updateData({
            name,
            value: field.value,
            errors: field.errors,
          }))
        }
        yield Eff.put(Cohort.invalidate({
          message: `Une information obligatoire est manquante pour valider la création de votre ${fr[expertiseType].expertise}.`,
        }
        ))
      }
    } else {
      yield Eff.put(Cohort.apiError(error.message))
    }
  }
}

export function* removePortalDocument({ payload: portalDocumentId }) {
  try {
    yield Eff.call(Api.removePortalDocument, portalDocumentId)

  } catch (error) {
    yield Eff.put(Cohort.apiError(error.message))
  }
}

export function* update({ payload: { teleExpertiseId: cohortId, teleExpertiseName: expertiseType, selectedField } }) {
  try {
    const data = yield Eff.select(Cohort.selectData)

    let fields;
    if (selectedField === 'labels') {
      fields = {
        labels: data.labels.value
      }

      const labels = yield Eff.call(Api.updateLabels, cohortId, expertiseType, fields)
      yield Eff.put(Cohort.received({ labels }))
    } else {
      const questionnaireData = yield Eff.select(Questionnaire.selectData)
      const fileData = yield Eff.select(UploadFile.selectData)
      const questionnaireImage = fileData['questionnaireImage']
      const image = questionnaireImage &&
        Object
          .values(questionnaireImage.value)
          .map(doc => (doc.fileName))
          .shift()
      const cohortPicture = fileData['cohort-picture']
      let pictureFileName = cohortPicture &&
        Object
          .values(cohortPicture.value)
          .map(doc => (doc.fileName))
          .shift()
      const portalHeaderImage = fileData['portal-header-image']
      const portalHeaderImageFileName = portalHeaderImage &&
        Object
          .values(portalHeaderImage.value)
          .map(doc => (doc.fileName))
          .shift()

      if (/ +/.test(data.publicPatientUri.value)) {
        yield Eff.put(Cohort.updateField({
          name: 'publicPatientUri',
          value: data.publicPatientUri.value.replace(/ +/g, '.')
        }))
      }

      yield Eff.put(Cohort.updateImage({ imageType: 'pictureFileName', imageFileName: pictureFileName }))

      yield Eff.put(Questionnaire.updateImage(image))

      yield Eff.put(Cohort.updateImage({ imageType: 'portalHeaderImage', imageFileName: portalHeaderImageFileName }))

      fields = {
        [expertiseType]: {
          name: data.name.value,
          description: data.description.value,
          publicPatientUri: data.publicPatientUri.value,
          tags: data.tags.value.filter(e => e),
          disciplines: data.disciplines.value,
          managers: data.managers.value,
          members: data.colleagues.value,
          organizations: data.organizations.value,
          pictureFileName: pictureFileName || '',
          publicUri: data.publicUri.value,
          patientStepsImageStretch: questionnaireData.patientStepsImageStretch.value,
          patientStepsImageAlign: questionnaireData.patientStepsImageAlign.value,
          patientStepsImageFileName: image || '',
        },
        createMultiCustomFields: {},
      }

      yield Eff.call(Api.updateExpertise, fields, cohortId, expertiseType)
      yield Eff.put(Cohort.received({ tags: data.tags.value.filter(e => e) }))
    }

    yield Eff.put(Cohort.setEditMode(false))
    yield Eff.put(Cohort.success(cohortId))
  } catch (error) {
    console.log(error)
    if (error instanceof Form.ValidationError) {
      for (let [name, field] of Object.entries(error.data)) {
        yield Eff.put(Cohort.updateData({
          name,
          value: field.value,
          errors: field.errors,
        }))
      }
      yield Eff.put(Cohort.invalidate({
        message: `Une information obligatoire est manquante pour valider la création de votre ${fr[expertiseType].expertise}.`
      }))
    } else {
      yield Eff.put(Cohort.invalidate({
        message: `Une erreur est survenue pendant la mise à jour de ${fr[expertiseType].thisExpertise}. Veuillez réessayer plus tard.`
      }))
      yield Eff.put(Cohort.apiError(error.message))
    }
  }
}

export function* fetch({ payload: { teleExpertiseIdParam } }) {
  try {
    const {
      patients,
      customFields,
      isOutdated,
      id,
      name,
      description,
      ownerFullname,
      updatedAt,
      createdAt,
      owner: {
        title: ownerTitle,
        id: ownerId,
      },
      portal,
      owner,
      managers,
      organizations,
      members: colleagues,
      tags,
      disciplines,
      labels,
      patientSteps: questionnaire,
      patientStepsImageFileName,
      patientStepsImageAlign,
      patientStepsImageStretch,
      currentUserAdmin,
      patientLimitLocked,
      patientLimitReached,
      numberAddPatientAction,
      portalHeaderImageAlign,
      portalHeaderImageStretch,
      portalHeaderImageName: portalHeaderImage,
      archive,
      isVideoconferencingToday,
      publicPatientUri,
      publicUri,
      pictureFileName = '',
      _canBe,
      _canBeModified,
    } = yield Eff.call(Api.fetchTeleExpertise, teleExpertiseIdParam, '', expertiseType)

    yield Eff.select(Login.selectUser)
    yield Eff.put(Questionnaire.received({
      questionnaireImage: patientStepsImageFileName,
      patientStepsImageAlign,
      patientStepsImageStretch,
      data:
        questionnaire
          .map(({
            id,
            title,
            description = '',
            customFieldSectionsId = [],
            activeVisioconferencing = 0,
            timeMeasure = 'day',
            timeNumber = '',
            assets = [],
            manualProgrammingDate = false,
          }) => ({
            id,
            title,
            description,
            sections: customFieldSectionsId,
            activeVisioconferencing,
            period: timeMeasure,
            periodQuantity: timeNumber,
            documents: assets,
            isDeadline: timeNumber === 0 || Boolean(timeNumber),
            manualProgrammingDate,
          }))
    }))

    const organizationMembers = Object
      .values(organizations)
      .reduce((acc, { members, managers }) => [
        ...acc,
        [...members, ...managers]
      ], [])
      .reduce((acc, x) => acc.concat(x), []);

    yield Eff.put(Resources.received({
      name: [Resources.RESOURCE_MEMBERS],
      data: [owner, ...managers, ...colleagues, ...organizationMembers].filter(uniqById),
    }))

    yield Eff.put(Resources.received({
      name: [Resources.RESOURCE_DISCIPLINES],
      data: disciplines,
    }))

    yield Eff.put(Resources.received({
      name: [Resources.RESOURCE_PATIENTS],
      data: patients,
    }))

    yield Eff.put(Resources.received({
      name: [Resources.RESOURCE_ORGANIZATIONS],
      data: organizations,
    }))

    yield Eff.put(Cohort.receivedOrganizationMembers(organizationMembers.map(e => e.id)))

    yield Eff.call(CustomFieldEffect.customFieldsFromExpertise, null, [], labels, {
      cohorts: [{
        patients,
        isOutdated,
        customFields,
        id,
        name,
        description,
        ownerFullname,
        updatedAt,
        createdAt,
        owner,
        ownerTitle,
        managers,
        members: colleagues,
        tags,
        disciplines,
        labels,
        questionnaire,
        archive,
        isVideoconferencingToday,
        publicPatientUri,
        publicUri,
        pictureFileName,
      }]
    },
      true)

    yield Eff.put(Cohort.received({
      id,
      name,
      description,
      archive,
      createdAt,
      updatedAt,
      ownerId,
      ownerTitle,
      ownerFullname,
      owner: [owner.id],
      managers: managers.map(e => e.id),
      colleagues: colleagues.map(e => e.id),
      tags,
      disciplines,
      labels,
      organizations: organizations.map(e => e.id),
      patientsLocked: patients.filter(patient => patient.pendingPatient).map(patient => patient.id),
      patients: patients.map(e => e.id),
      isOutdated,
      currentUserAdmin,
      patientLimitLocked,
      patientLimitReached,
      numberAddPatientAction,
      portalHeaderImageAlign,
      portalHeaderImageStretch,
      portalHeaderImage,
      isVideoconferencingToday,
      publicPatientUri,
      publicUri,
      pictureFileName,
      portalDocuments: portal.attachements || [],
      portalAbout: portal.description || '',
      permissions: { _canBe, _canBeModified }
    }))

    yield Eff.put(Cohort.success(teleExpertiseIdParam))

  } catch (error) {
    console.log(error)
    yield Eff.put(Cohort.apiError(error.message))
  }
}
export function* fetchQuestionnaire({ payload: { publicUri, patientCode } }) {
  try {
    const {
      isVideoconferencingToday,
      patientCustomFieldContents,
      patientCustomFieldAttachements: patientCustomFieldAttachments = [],
      cohortId,
      cohortName: title,
      cohortDescription: description,
      patientSteps: questionnaire,
      cohortPatientStepsImageFileName: questionnaireImage,
      cohortPatientStepsImageAlign: patientStepsImageAlign = 1,
      cohortPatientStepsImageStretch: patientStepsImageStretch = true,
      patientStepsProgrammingDateByPatientStepId: datesByPatientStepId = {},
    } = yield Eff.call(Api.fetchQuestionnaire, publicUri, patientCode)
    const customFields = questionnaire
      // Steps with sections only
      // .filter(step => step.customFieldSectionsId.length > 0)
      .reduce((acc, { customFieldSections }) =>
      ({
        ...acc,
        ...Object
          .values(customFieldSections
            // Steps patient type sections only
            .filter(({ isPatientSection }) => isPatientSection)
          )
          .reduce((acc, section) => ({
            ...acc,
            [section.id]: section
          }), {})
      }), {})

    yield Eff.put(Questionnaire.received(
      {
        cohortId,
        title,
        description,
        questionnaireImage,
        patientStepsImageAlign,
        patientStepsImageStretch,
        isVideoconferencingToday,
        patientCustomFieldContents,
        datesByPatientStepId,
        data:
          questionnaire
            .map(({
              id,
              title,
              description = '',
              customFieldSectionsId = [],
              customFieldSections = [],
              activeVisioconferencing = 0,
              timeMeasure = '',
              timeNumber = '',
              assets = [],
              manualProgrammingDate = true,
            }) => ({
              id,
              title,
              description,
              sections: customFieldSectionsId
                .filter(id =>
                  Object.keys(customFields).includes(String(id))
                ),
              customFieldSections,
              activeVisioconferencing,
              period: timeMeasure,
              periodQuantity: timeNumber,
              documents: assets,
              manualProgrammingDate,
            }))
      }
    ))

    yield Eff.put(Cohort.success(cohortId))

    yield Eff.call(CustomFieldEffect.customFieldsFromExpertise, null, patientCustomFieldContents, [], {
      cohorts: [{
        customFields,
        id: cohortId
      }]
    },
      true)
    const attachmentsByCustomFieldId = Object
      .values(patientCustomFieldAttachments
        .map(({ id, ...rest }) => ({ id, ...rest, assets: { ...rest.assets, id } })))
      .reduce((acc, obj) => {
        const key = obj['customFieldId'];
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(obj);
        return acc;
      }, {});
    for (let [customFieldId] of Object.entries(attachmentsByCustomFieldId)) {

      const receivedDocuments = {
        id: String(cohortId),
        fieldId: customFieldId,
        documents: attachmentsByCustomFieldId[customFieldId].map(({ assets }) => assets)
      }
      yield Eff.put(CustomField.receivedDocuments(receivedDocuments))
    }

  } catch (error) {
    console.log(error)
    yield Eff.put(Cohort.apiError(error.message))
  }
}

export function* addColleague({ payload: { id: colleagueId, noEffect = false } }) {
  try {
    if (!noEffect) {
      const teleExpertiseId = yield Eff.select(Cohort.selectId)

      yield Eff.call(Api.addUser, teleExpertiseId, colleagueId, 'cohorts')
      // TODO : need update colleague list from response ?
      // const { members: colleagues } = yield Eff.call(Api.addUser, teleExpertiseId, colleagueId, 'cohorts')
      // yield Eff.put(Cohort.updateColleagues(colleagues))
      yield Eff.put(Cohort.setEditMode({ target: 'colleagues', value: false }))
    }
  } catch (error) {
    yield Eff.put(Cohort.apiError(error.message))
  }
}

export function* removeColleague({ payload: { id: colleagueId } }) {
  try {
    const teleExpertiseId = yield Eff.select(Cohort.selectId)
    yield Eff.call(Api.removeUser, teleExpertiseId, colleagueId, 'cohorts')
    // TODO : need update colleague list from response ?
    // const { members: colleagues } = yield Eff.call(Api.removeUser, teleExpertiseId, colleagueId, 'cohorts')
    // yield Eff.put(Cohort.updateColleagues(colleagues))
    yield Eff.put(Cohort.setEditMode({ target: 'colleagues', value: false }))
  } catch (error) {
    yield Eff.put(Cohort.apiError(error.message))
  }
}

export function* updateColleagues({ payload: id }) {
  try {
    const colleagues = yield Eff.select(Cohort.selectColleagues)
    yield Eff.call(Api.updateColleagues, id, colleagues, expertiseType)
    yield Eff.put(Cohort.setEditMode(false))
  } catch (error) {
    yield Eff.put(Cohort.apiError(error.message))
  }
}

export function* addOrganization({ payload: { id: organizationId } }) {
  try {
    const teleExpertiseId = yield Eff.select(Cohort.selectId)

    const { organizations = [] } = yield Eff.call(Api.updateOrganization, organizationId, 'add', 'cohort', teleExpertiseId)

    const organizationMembers = Object
      .values(organizations)
      .reduce((acc, { members, managers }) => [
        ...acc,
        [...members, ...managers]
      ], [])
      .reduce((acc, val) => acc.concat(val), [])

    yield Eff.put(Resources.received({
      name: [Resources.RESOURCE_MEMBERS],
      data: [...organizationMembers].filter(uniqById),
    }))

    yield Eff.put(Cohort.receivedOrganizationMembers(organizationMembers.map(e => e.id)))
    yield Eff.put(Cohort.setQuery({ name: 'organizations', value: '' }))
    yield Eff.put(Cohort.setEditMode({ target: 'organizations', value: false }))
  } catch (error) {
    yield Eff.put(Cohort.apiError(error.message))
  }
}

export function* removeOrganization({ payload: { id: organizationId } }) {
  try {
    const teleExpertiseId = yield Eff.select(Cohort.selectId)

    const { organizations = [] } = yield Eff.call(Api.updateOrganization, organizationId, 'remove', 'cohort', teleExpertiseId)
    const organizationMembers = Object
      .values(organizations)
      .reduce((acc, { members, managers }) => [
        ...acc,
        [...members, ...managers]
      ], [])
      // flatMap not supported by node 10 https://github.com/kulshekhar/ts-jest/issues/1822
      .reduce((acc, val) => acc.concat(val), [])

    yield Eff.put(Cohort.received({ organizationMembers: organizationMembers.map(e => e.id) }))
    yield Eff.put(Cohort.setQuery({ name: 'organizations', value: '' }))
    yield Eff.put(Cohort.setEditMode({ target: 'organizations', value: false }))
  } catch (error) {
    yield Eff.put(Cohort.apiError(error.message))
  }
}

export function* linkPatient({ payload: expertiseId }) {
  try {
    const patients = yield Eff.select(Cohort.selectPatients)
    yield Eff.call(Api.linkPatient, expertiseId, patients[patients.length - 1], expertiseType)
    yield Eff.put(Cohort.setEditMode(false))
  } catch (error) {
    yield Eff.put(Cohort.apiError(error.message))
  }
}

export function* addOrganizationMembers({ payload: id }) {
  try {
    const { organizations } = yield Eff.select(Resources.selectResources)
    const organization = organizations.find(org => org.id === id)

    yield Eff.put(Resources.received({
      name: [Resources.RESOURCE_ORGANIZATIONS],
      data: [organization],
    }))

    const {
      id: organizationId,
      name,
      logo,
      members = [],
      managers = [],
    } = organization

    yield Eff.put(Resources.received({
      name: [Resources.RESOURCE_MEMBERS],
      data: [...members, ...managers].filter(uniqById),
    }))

    yield Eff.put(Organization.received({
      organizationId,
      name,
      logo,
      members: members.map(e => e.id),
      managers: managers.map(e => e.id),
    }))

    yield Eff.put(Cohort.receivedOrganizationMembersById({
      organizationId,
      members: [...members, ...managers].map(e => e.id)
    }))
  } catch (error) {
    console.log(error)
    yield Eff.put(Cohort.apiError(error.message))
  }
}

export function* closeExpertise({ payload: teleExpertiseId }) {
  try {
    yield Eff.call(Api.closeExpertise, teleExpertiseId, expertiseType)
  } catch (error) {
    yield Eff.put(Cohort.apiError(error.message))
  }
}

export function* openExpertise({ payload: teleExpertiseId }) {
  try {
    yield Eff.call(Api.openExpertise, teleExpertiseId, expertiseType)
  } catch (error) {
    yield Eff.put(Cohort.apiError(error.message))
  }
}

export function* removeLabel({ payload: { id } }) {
  try {
    if (id) {
      const cohortId = yield Eff.select(Cohort.selectId)

      if (cohortId)
        yield Eff.call(Api.deleteLabel, cohortId, id, expertiseType)
    }
  } catch (error) {
    yield Eff.put(Cohort.apiError(error.message))
  }
}

export function* updateOwner({ payload: { colleague: { colleagueId } } }) {
  try {
    const teleExpertiseId = yield Eff.select(Cohort.selectId)

    yield Eff.call(Api.updateOwner, colleagueId, teleExpertiseId, expertiseType)
    yield Eff.put(Cohort.success(teleExpertiseId))
  } catch (error) {
    yield Eff.put(Cohort.apiError(error.message))
  }
}

export function* addManager({ payload: { id: managerId } }) {
  try {
    const teleExpertiseId = yield Eff.select(Cohort.selectId)

    yield Eff.call(Api.addManager, managerId, teleExpertiseId, expertiseType)
    yield Eff.put(Cohort.setEditMode({ target: 'managers', value: false }))

  } catch (error) {
    yield Eff.put(Cohort.apiError(error.message))
  }
}

export function* removeManager({ payload: { id: managerId } }) {
  try {
    const teleExpertiseId = yield Eff.select(Cohort.selectId)

    yield Eff.call(Api.removeManager, managerId, teleExpertiseId, expertiseType)
    yield Eff.put(Cohort.setEditMode({ target: 'managers', value: false }))

  } catch (error) {
    yield Eff.put(Cohort.apiError(error.message))
  }
}

export function* setQuery({ payload: { name, value: query } }) {
  try {
    if (query) {
      const delayMs = debounceTime(query)

      yield Eff.delay(delayMs)

      if (name === 'disciplines') {
        yield Eff.put(Resources.load({
          filter: [Resources.RESOURCE_DISCIPLINES],
          query,
        }))
      } else if (name === 'managers' || name === 'colleagues') {
        yield Eff.put(Resources.load({
          filter: [Resources.RESOURCE_MEMBERS],
          query,
        }))
      } else if (name === 'patients') {
        yield Eff.put(Resources.load({
          filter: [Resources.RESOURCE_PATIENTS],
          query,
        }))
      } else if (name === 'organizations') {
        yield Eff.put(Resources.load({
          filter: [Resources.RESOURCE_ORGANIZATIONS],
          query,
        }))
      }
    }
  } catch (error) {
    yield Eff.put(Cohort.apiError(error.message))
  }
}

export function* suggestSpecialities() {
  try {
    const user = yield Eff.select(Login.selectUser)

    if (user.speciality) {
      yield Eff.put(Resources.load({
        filter: [Resources.RESOURCE_DISCIPLINES],
        query: user.speciality,
      }))
      yield Eff.put(Resources.received({
        name: [Resources.RESOURCE_DISCIPLINES],
        data: [user.speciality],
      }))
      yield Eff.put(Cohort.addDiscipline(user.speciality))
    }
  } catch (error) {
    yield Eff.put(Cohort.apiError(error.message))
  }
}

export function* addPatient({ payload: { teleExpertiseId: cohortId, id: patientId } }) {
  try {
    yield Eff.select(Cohort.selectPatients)
    const isPatientLimitReached = yield Eff.select(Cohort.selectIsPatientLimitReached)
    if (isPatientLimitReached) {
      yield Eff.put(Cohort.updatePatientsLocked(patientId))
    }
    const {
      patientSteps,
      numberAddPatientAction,
      patientLimitReached: isLimitReachAfterAdd,
    } = yield Eff.call(Api.linkPatient, cohortId, patientId, 'cohort')
    if (!isPatientLimitReached) {
      if (patientSteps && patientSteps.length > 0) {
        yield Eff.put(Cohort.setHasQuestionnaire(true))

        yield Eff.put(Cohort.setInfoMessage(`Un questionnaire est associé à cette cohorte.
      Veuillez renseignez l'email du patient dans ses Infos complémentaires.`))
      }
    }
    yield Eff.put(Cohort.received({
      numberAddPatientAction,
      patientLimitReached: isLimitReachAfterAdd
    }))
    yield Eff.put(Cohort.setEditMode({ target: 'addPatient', value: false }))
    yield Eff.put(Cohort.success(cohortId))
  } catch (error) {
    yield Eff.put(Cohort.apiError(error.message))
  }
}

export function* removePatient({ payload: { id, teleExpertiseId: cohortId } }) {
  try {
    yield Eff.select(Cohort.selectPatients)
    yield Eff.call(Api.unlinkPatient, cohortId, id, 'cohort')
    yield Eff.put(Cohort.setEditMode({ target: 'addPatient', value: false }))
  } catch (error) {
    yield Eff.put(Cohort.apiError(error.message))
  }
}

export function* fetchCohortFromCode({ payload: publicUri }) {
  try {
    const { cohortId } = yield Eff.call(Api.fetchTeleExpertiseFromCode, 'cohort', publicUri)
    yield Eff.put(Cohort.updateField({
      name: 'id',
      value: cohortId,
    }))
    yield Eff.put(Cohort.updateField({
      name: 'publicUri',
      value: cohortId,
    }))
    yield Eff.put(Cohort.setCohortUri({ publicUri, value: true }))

  } catch (error) {
    yield Eff.put(Cohort.apiError(error.message))
  }
}

export function* fetchPatientCohortFromCode({ payload: publicPatientUri }) {
  try {
    const { cohortId } = yield Eff.call(Api.fetchPatientTeleExpertiseFromCode, 'cohort', publicPatientUri)
    yield Eff.put(Cohort.updateField({
      name: 'id',
      value: cohortId,
    }))
    yield Eff.put(Cohort.updateField({
      name: 'patientCodeUri',
      value: cohortId,
    }))
    yield Eff.put(Cohort.setCohortUri({ publicPatientUri, value: true }))

  } catch (error) {
    yield Eff.put(Cohort.apiError(error.message))
  }
}

export function* toggle({ payload: { id, toggled, name, teleExpertiseId } }) {
  try {
    const data = yield Eff.select(Cohort.selectData)
    const teleExpertiseId = yield Eff.select(Cohort.selectId)
    if (name === "disciplines") {
      if (teleExpertiseId) {
        const fields = { cohort: { disciplines: data.disciplines.value } }
        yield Eff.call(Api.updateExpertise, fields, teleExpertiseId, 'cohort')
      }
    }
    yield Eff.put(Cohort.setEditMode({ target: 'disciplines', value: false }))

  } catch (error) {
    console.log(error)
    yield Eff.put(Cohort.apiError(error.message))
  }
}

const CohortSagas = function* () {
  yield Eff.takeLatest(`${Cohort.fetch}`, fetch)
  yield Eff.takeLatest(`${Cohort.send}`, send)
  yield Eff.takeLatest(`${Cohort.update}`, update)
  yield Eff.takeLatest(`${Cohort.toggle}`, toggle)
  yield Eff.takeLatest(`${Cohort.suggestSpecialities}`, suggestSpecialities)
  yield Eff.takeLatest(`${Cohort.updateColleagues}`, updateColleagues)
  yield Eff.takeLatest(`${Cohort.updatePatients}`, linkPatient)
  yield Eff.takeLatest([
    `${Cohort.closeExpertise}`,
    `${CohortList.closeExpertise}`,
  ], closeExpertise)
  yield Eff.takeLatest([
    `${Cohort.openExpertise}`,
    `${CohortList.openExpertise}`,
  ], openExpertise)
  yield Eff.takeLatest(`${Cohort.removeLabel}`, removeLabel)
  yield Eff.takeLatest(`${Cohort.setQuery}`, setQuery)
  yield Eff.takeLatest(`${Cohort.updateOwner}`, updateOwner)
  yield Eff.takeLatest(`${Cohort.addManager}`, addManager)
  yield Eff.takeLatest(`${Cohort.removeManager}`, removeManager)
  yield Eff.takeLatest(`${Cohort.addPatient}`, addPatient)
  yield Eff.takeLatest(`${Cohort.removePatient}`, removePatient)
  yield Eff.takeLatest(`${Cohort.addColleague}`, addColleague)
  yield Eff.takeLatest(`${Cohort.removeColleague}`, removeColleague)
  yield Eff.takeLatest(`${Cohort.addOrganization}`, addOrganization)
  yield Eff.takeLatest(`${Cohort.removeOrganization}`, removeOrganization)
  yield Eff.takeLatest(`${Cohort.addOrganizationMembers}`, addOrganizationMembers)
  yield Eff.takeLatest(`${Cohort.fetchQuestionnaire}`, fetchQuestionnaire)
  yield Eff.takeLatest(`${Cohort.fetchCohortFromCode}`, fetchCohortFromCode)
  yield Eff.takeLatest(`${Cohort.fetchPatientCohortFromCode}`, fetchPatientCohortFromCode)
  yield Eff.takeEvery(`${Cohort.saveField}`, saveField)
  yield Eff.takeEvery(`${Cohort.removePortalDocument}`, removePortalDocument)
}

export default CohortSagas;
