import React, { useRef, useEffect } from 'react'

export const ClickOutside = ({
  Component,
  children,
  onClickOutside = () => null,
  ...restProps
}) => {
  const componentRef = useRef(null)

  useEffect(() => {
    let current = componentRef.current

    if (!current) {
      return
    }

    const whenClickedOutside = () => {
      document.removeEventListener('mousedown', whenClickedOutside)
      onClickOutside()
    }

    const onMouseLeave = () => {
      document.addEventListener('mousedown', whenClickedOutside)
    }

    const onMouseEnter = () => {
      document.removeEventListener('mousedown', whenClickedOutside)
    }

    current.addEventListener('mouseleave', onMouseLeave)
    current.addEventListener('mouseenter', onMouseEnter)

    return () => {
      current.removeEventListener('mouseleave', onMouseLeave)
      current.removeEventListener('mouseenter', onMouseEnter)
    }
  }, [ componentRef, onClickOutside ])

  return (
    <Component ref={componentRef} {...restProps}>
      {children}
    </Component>
  )
}

export default ClickOutside
