import React from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import { darken } from '../../Util/Color'
import * as IconUi from '../Ui/Icon'
import { privateSitePrefix } from '../../Util/Config'

/**
 * This is a generic button for any cases in the application. You can
 * pass the following props in order to customize the appearence :
 *
 * To try: https://github.com/styled-components/styled-components/issues/439#issuecomment-296399324
 * Size: isSmall (default), isMedium, isLarge
 * Color (default to grey): isGreen, isPurple, isRed
 * props (by default buttons are outlined): isFilled
 * BorderSizeOnHover (by default border is doubled on hover): hasThinBorder
 *
 * @example
 * <Button isMedium isFilled isRed>This is a medium red button</Button>
 * // You can also display Link from react router :
 * <Button isMedium isGreen as={Link} to="/some/location">This is a linked button</Button>
 */

const colorize = (props, hover = false) =>
  props.$isGreen
    ? props.theme.patientGreen
    : props.$isPurple
      ? props.theme.staffPurple
      : props.$isRed
        ? props.theme.cohorteRed
        : props.$isGrey
          ? props.theme.grey2
          : props.$isPrimary
            ? props.theme.primary
            : hover
              ? props.theme.patientGreen
              : props.theme.grey
  ;

export const Button = styled.button`
  font-family: 'Montserrat', sans-serif;
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.05rem;
  text-decoration: none;
  transition: background-color .25s;
  border: 0;
  color: white;
  border-radius: 8px;
  outline: none;
  font-weight: 600;
  cursor: pointer;
  white-space: nowrap;

  font-size: ${props => props.$isSmall
    ? '.825rem'
    : '1rem'
  };

  padding: ${props => props.$isLarge
    ? '.821rem 1.2rem'
    : props.$isSmall
      ? '.525rem .8rem'
      : '.615rem 1.4rem'
  };

  min-height: ${props => props.$isMedium && '2.3125rem'
  };

  ${props => props.$isFilled
    ? css`
        color: white;
        background-color: ${colorize(props)};
      `
    : css`
        background-color: white;
        color: black;
        box-shadow: inset 0px 0px 0px 1px ${colorize(props, false)};
      `
  };

  &:hover {
    outline: none;
    background-color: ${props => props.$isFilled
      ? darken(colorize(props, true), 10)
      : '#EAEAEA'
    };
    box-shadow: ${props =>
    props.$isFilled
      ? 'none'
      : props.hasThinBorder
        ? `inset 0px 0px 0px 1px ${colorize(props, true)}`
        : `inset 0px 0px 0px 2px ${colorize(props, true)}`
    };
  }

  &:disabled {
    background-color: ${props => props.theme.grey2};
    color: ${props => props.theme.lightGrey};
    box-shadow: none;
    cursor: not-allowed;
  }

  i {
    & + span {
      margin-left: .5rem;
    }
  }
  div + span {
    margin-left: .6rem;
  }
`

export const BasicButton = ({
  icon = null,
  label = '',
  onClick = () => null,
  children,
  isFilled,
  isGreen,
  isPurple,
  isRed,
  isGrey,
  isPrimary,
  isSmall,
  isMedium,
  isLarge,
  type = 'button',
  as,
  to,
  ...props
}) => <Button
  as={as}
  to={to}
  role="button"
  type={type}
  onClick={onClick}
  $isFilled={isFilled}
  $isGreen={isGreen}
  $isPurple={isPurple}
  $isRed={isRed}
  $isGrey={isGrey}
  $isPrimary={isPrimary}
  $isSmall={isSmall}
  $isMedium={isMedium}
  $isLarge={isLarge}
  {...props}
>
    {React.Children.map(children, (child) =>
      typeof child === 'string'
        ? <span>{child}</span>
        : <>{child}</>
    )}
  </Button>

export const ActionButton = styled(props => <Button {...props} />)`
  width: 2.3125rem;
  height: 2.3125rem;
  padding: 1.2294rem;
  &:not(:last-child) {
    margin-right: .4rem;
  }
`

export const Back = styled.a`
  flex: 1 1 0%;
  text-decoration: none;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  font-size: .75rem;
  font-weight: normal;
  color: #8a9da8;
  text-transform: uppercase;
  i {
    font-size: .9rem;
    margin-right: .6rem;
  }
  @media only screen and (max-width: ${ props => props.theme.device.desktop}) {
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
`

export const BackButton = ({ to = null, onClick }) =>
  <Back to={to} onClick={onClick}>
    <IconUi.IconBack />
    Retour
  </Back>

const Next = styled.a`
  flex: 1 1 0%;
  text-decoration: none;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  font-size: 1rem;
  font-weight: 600;
  color: ${props => props.theme.grey};
  ${IconUi.IconArrowRight} {
    margin-left: .7rem;
    color: ${props => props.theme.grey2};
    font-size: 1.4rem;
  }
`

const Previous = styled.a``

export const NextStepButton = ({ to = null, onClick }) =>
  <Next to={to} onClick={onClick}>
    Suivant
    <IconUi.IconArrowRight />
  </Next>

export const PreviousStepButton = ({ to = null, onClick }) =>
  <Previous to={to} onClick={onClick}>
    <IconUi.IconArrowLeft />
    Précedent
  </Previous>

export const FormButton = styled(props => <BasicButton {...props} />)`
  flex-direction: row;
  display: inline-flex;
  color: ${props => props.theme.darkBlue};
  box-shadow: ${props => props.theme.boxShadow.thin};
  font-weight: normal;
  border-radius: 5px;
  padding: .615rem 1.1rem;
  background-color: white;
  span {
    padding: .3rem 0;
    font-size: .875rem;
    border: 0;
    &:not(:last-child) {
      margin-right: 1rem;
    }
  }
`

export const SearchButton = styled(props => <Button {...props} />)`
  border-radius: 1000px;
  border: none;
  box-shadow: 0 2px 5px 1px rgba(134, 174, 197, 0.24);
  color: ${props => props.theme.black};
  font-size: .7rem;
  font-weight: normal;
  font-style: normal;

  i {
    margin-left: 1rem;
    color: ${props => props.theme.patientGreen};
    font-size: 1.2rem;
  }
`

export const CloseButton = styled(props => <Button {...props} />)`
  border-radius: 1000px;
  border: none;
  color: ${props => props.theme.black};
  font-size: .7rem;
  font-weight: normal;
  font-style: normal;
  border: 1px solid ${props => props.theme.grey};

  i {
    margin-left: 1rem;
    color: ${props => props.theme.grey};
    font-size: 1rem;
  }
`

export const NavbarButton = styled(props => <BasicButton {...props} />)`
  position: absolute;
  right: 1rem;
  @media only screen and (max-width: ${props => props.theme.device.desktop}) {
    margin-bottom: 1rem;
    margin-top: .5rem;
  }
`

export const PasswordButton = styled.span`
  padding: .1rem .4rem;
  border: 1px solid ${props => props.theme.grey};
  border-radius: 5px;
  bottom: 1rem;
  &:hover {
    cursor: pointer;
  }
`

const ContactUsText = styled.span`
  margin-left: 0.5rem;
`

const ContactUs = styled.div`
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 5000px;
  width: 3rem;
  height: 3rem;
  background-color: ${props => props.theme.patientGreen};
  position: fixed;
  bottom: 1.5rem;
  right: 1.5rem;
  cursor: pointer;
  border: 5px solid white;
  ${IconUi.IconContactUs} {
    font-weight: 600;
  }
  ${ContactUsText} {
    display: none;
  }
  &:hover {
    width: 12rem;
    ${ContactUsText} {
      display: inline-block;
    }
    ${IconUi.IconContactUs} {
      margin: 1rem 0.5rem 1rem 1rem;
    }
  }
`

export const ContactUsButton = () => {
    return (
        <>
            <Link to={`${window.location.pathname.includes(privateSitePrefix + '/') ? privateSitePrefix : ''}/contactez-nous`}>
                <ContactUs title="Assistance Skemeet">
                    <IconUi.IconContactUs />
                    <ContactUsText>Assistance Skemeet</ContactUsText>
                </ContactUs>
            </Link>
        </>
    )
}
