import * as Resources from '../State/Resources'
import * as Message from '../State/Message'
import * as Form from '../Util/Form'
import * as UploadFile from '../State/UploadFile'
import * as Api from '../Util/Api'
import { takeLatest, call, put, select, delay } from 'redux-saga/effects'
import { debounceTime } from '../Util/Date'

export function* send() {
  try {
    const { subject, content, recipients, patients } = yield select(Message.selectData)
    const validatedData = yield call(Form.validate, { subject, content, recipients, patients })
    const files = yield select(UploadFile.selectData)
    const assets = Object.values(files['message-upload'].value)

    yield call(Api.sendMail, {
      subject: validatedData.subject.value,
      content: validatedData.content.value,
      recipients: validatedData.recipients.value,
      patients: validatedData.patients.value,
      assets: assets
        .map(asset => ({
          fileName: asset.fileName,
          size: asset.size,
          name: asset.name,
          mimeType: asset.type,
        }))
    })


    yield put(Message.success())
  } catch (error) {
    if (error instanceof Form.ValidationError) {
      for (let [name, field] of Object.entries(error.data)) {
        yield put(Message.updateField({
          name,
          value: field.value,
          errors: field.errors,
        }))
      }
      yield put(Message.invalidate(
        'Une information obligatoire est manquante pour valider l\'envoi de votre message.'
      ))
    } else {
      yield put(Message.apiError(error.message))
    }
  }
}

export function* reply({ payload: { messageId } }) {
  try {
    const { replyContent, assets, patients } = yield select(Message.selectData)
    const validatedData = yield call(Form.validate, { replyContent, assets, patients })
    const files = yield select(UploadFile.selectData)
    const uploadedAssets = Object.values(files['message-upload'].value)

    yield call(Api.replyMessage, {
      messageId,
      content: validatedData.replyContent.value,
      patients: validatedData.patients.value,
      assets: uploadedAssets
        .map(asset => ({
          fileName: asset.fileName,
          size: asset.size,
          name: asset.name,
          mimeType: asset.type,
        }))
    })

    yield put(Message.success())
  } catch (error) {
    console.log(error)
    if (error instanceof Form.ValidationError) {
      for (let [name, field] of Object.entries(error.data)) {
        yield put(Message.updateField({
          name,
          value: field.value,
          errors: field.errors,
        }))
      }
      yield put(Message.invalidate(
        'Une information obligatoire est manquante pour valider l\'envoi de votre message.'
      ))
    } else {
      yield put(Message.apiError(error.message))
    }
  }
}

export function* fetch({ payload: messageId }) {
  try {
    const patients = []
    const {
      createdAt,
      subject,
      members,
      messages,
      author: {
        firstname,
        lastname,
        profilePicture,
      },
      author,
      read,
    } = yield call(Api.fetchMessage, messageId)

    yield put(Message.received({
      createdAt,
      members,
      subject,
      messages,
      profilePicture,
      firstname,
      lastname,
      author,
      read,
    }))

    yield put(Resources.received({
      name: [Resources.RESOURCE_PATIENTS],
      data: [patients],
    }))

  } catch (error) {
    yield put(Message.apiError(error.message))
  }
}

export function* setRecipients({ payload: { id } }) {
  try {
    const {
      firstname,
      lastname,
      profilePicture,
      speciality,
    } = yield call(Api.fetchColleague, id)

    yield put(Resources.received({
      name: [Resources.RESOURCE_MEMBERS],
      data: [{
        id: Number(id),
        username: `${firstname} ${lastname}`,
        firstname,
        lastname,
        profilePicture,
        speciality
      }],
    }))
  } catch (error) {
    yield put(Message.apiError(error.message))
  }
}

export function* setQuery({ payload: { name, value: query } }) {
  try {
    if (query) {
      const delayMs = debounceTime(query)
      yield delay(delayMs)
      if (name === 'patients') {
        yield put(Resources.load({
          filter: [Resources.RESOURCE_PATIENTS],
          query,
        }))
      } else {
        yield put(Resources.load({
          filter: [Resources.RESOURCE_MEMBERS],
          query,
        }))
      }
    }

  } catch (error) {
    yield put(Message.apiError(error.message))
  }
}

const MessageSagas = function* () {
  yield takeLatest(`${Message.setQuery}`, setQuery)
  yield takeLatest(`${Message.send}`, send);
  yield takeLatest(`${Message.reply}`, reply);
  yield takeLatest(`${Message.fetch}`, fetch)
  yield takeLatest(`${Message.setRecipients}`, setRecipients)
};

export default MessageSagas;
