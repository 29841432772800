import * as Comment from '../State/Comment'
import * as Patient from '../State/Patient'
import * as UploadFile from '../State/UploadFile'
import * as Api from '../Util/Api'
import * as Form from '../Util/Form'
import { takeLatest, call, put, select } from 'redux-saga/effects'

export function* send({ payload: { teleExpertiseType, requestState, teleExpertiseId } }) {
  try {
    const data = yield select(Comment.selectData)
    const patientId = yield select(Patient.selectPatientId)
    const validatedData = yield call(Form.validate, data)
    const files = yield select(UploadFile.selectData)
    const assets = Object.values(files['comments-upload'].value)
    const apiComments = {
      comment: {
        content: validatedData.comment.value,
        assets: assets
          .map(asset => ({
            fileName: asset.fileName,
            size: asset.size,
            name: asset.name,
            mimeType: asset.type,
          }))
      }
    }
    let comments = []
    const type = (teleExpertiseType === 'staff')
      ? 'staffSession'
      : teleExpertiseType || null

    if (type || requestState) {
      comments = yield call(Api.sendExpertiseComment, apiComments, type, patientId, teleExpertiseId)
    } else {
      comments = yield call(Api.sendComment, apiComments, patientId)
    }

    yield put(Comment.received({ comments: comments.comments }))
    yield put(Comment.setCommentBoxOpen(false))
    yield put(UploadFile.resetUploader('comments-upload'))
    yield put(Comment.success())
  } catch (error) {
    if (error instanceof Form.ValidationError) {
      yield put(Comment.invalidate(error.data))
    }
    else {
      yield put(Comment.apiError(error.message))
    }
  }
}

export function* deleteComment({ payload: commentId }) {
  try {
    yield call(Api.deleteComment, commentId)
  } catch (error) {
    yield put(Comment.apiError(error.message))
  }
}

export function* saveCommentById({ payload: { comment, id: commentId } }) {
  try {
    yield call(Api.updateComment, commentId, comment)
    yield put(Comment.setEditComment({ commentId, isEditing: false }))
    yield put(Comment.updateInitialValue({ commentId, value: comment }))

  } catch (error) {
    yield put(Comment.apiError(error.message))
  }
}

export function* fetch({ payload: { patientId } }) {
  try {
    const comments = yield call(Api.fetchComments, patientId)
    yield put(Comment.received({ comments }))

  } catch (error) {
    yield put(Comment.apiError(error.message))
  }
}

const CommentSagas = function* () {
  yield takeLatest(`${Comment.send}`, send);
  yield takeLatest(`${Comment.fetch}`, fetch);
  yield takeLatest(`${Comment.deleteComment}`, deleteComment);
  yield takeLatest(`${Comment.saveCommentById}`, saveCommentById);
};

export default CommentSagas;
