import * as React from "react";

export const Cross = ({
    ...props
}) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <line
      x1={1.06066}
      y1={1}
      x2={14.7009}
      y2={14.6403}
      stroke="#3BC3A8"
      strokeWidth={1.5}
      strokeLinecap="round"
    />
    <line
      x1={0.75}
      y1={-0.75}
      x2={20.0402}
      y2={-0.75}
      transform="matrix(-0.707107 0.707107 0.707107 0.707107 16 1)"
      stroke="#3BC3A8"
      strokeWidth={1.5}
      strokeLinecap="round"
    />
  </svg>
);
