import { JJMMAAAAToDate } from './Format'

export const validators = {
  notEmpty: async field => {
    if (field.value.length > 0 || Object.keys(field.value).length > 0) {
      return field
    }

    return {
      ...field,
      errors: [
        ...field.errors,
        'Ce champ est requis',
      ]
    }
  },

  email: async field => {
    if (field.value && !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(field.value)) {
      return {
        ...field,
        errors: [
          ...field.errors,
          'L\'adresse email est invalide',
        ]
      }
    }

    return field
  },

  emailList: async field => {
    if (field.value.some(email => (!/\S+@\S+\.\S+/.test(email)))) {
      return {
        ...field,
        errors: [
          ...field.errors,
          'Une ou plusieurs adresses mail sont invalides.',
        ]
      }
    }
    if (field.value.length === 0) {
      return {
        ...field,
        errors: [
          ...field.errors,
          'Vous devez renseigner au moins une adresse email.',
        ]
      }
    }

    return field
  },

  cellPhone: async field => {
    if (field.value && !/^[+]{0,1}([\s\d]){6}([\s\d])*$/.test(field.value)) {
      return {
        ...field,
        errors: [
          ...field.errors,
          'Le numéro de portable est invalide',
        ]
      }
    }

    return field
  },

  cellPhoneStrict: async field => {
    if (field.value && !/^(([+]?33|0)?\s?0?(6|7|9)\s*(?:\d\s*){8}|(([+]|00)(41|32|352|39))\s*(?:\d\s*){9})$/.test(field.value)) {
      return {
        ...field,
        errors: [
          ...field.errors,
          'Le numéro de portable est invalide',
        ]
      }
    }

    return field
  },

  rpps: async field => {
    if (field.value && !/^\d{11,}$/.test(field.value)) {
      return {
        ...field,
        errors: [
          ...field.errors,
          'Ce numéro RPPS n\'est pas valide.',
        ]
      }
    }

    return field
  },

  password: async field => {
    if (!/^.{7,}$/.test(field.value)) {
      return {
        ...field,
        errors: [
          ...field.errors,
          'Le mot de passe doit contenir au moins 7 caractères.',
        ]
      }
    }

    return field
  },

  acceptedImageFormat: async field =>
    Object
      .values(field.value)
      .filter(value => value.size !== undefined && value.type)
      .map(fileField => {
        if (!/(gif|jpe?g|png)$/.test(fileField.type)) {
          return 'Ce format n\'est pas accepté.'
        }

        return null
      })
      .filter(error => error)
      .reduce((acc, error) => ({
        ...acc,
        errors: [...acc.errors, error]
      }), field),

  acceptedDocumentFormat: async field =>
    Object
      .values(field.value)
      .filter(value => value.size !== undefined && value.type)
      .map(fileField => {
        if (!/((video\/(mpeg|mp4|x-msvideo))|x-7z-compressed|plain|tiff|zip|pdf|CDFV2|msword|vnd\.ms-(excel|powerpoint)|vnd\.openxmlformats-officedocument\.(spreadsheetml\.sheet|presentationml.presentation|wordprocessingml.document)|gif|jpe?g|png)$/.test(fileField.type)) {
          return 'Ce format n\'est pas accepté.'
        }

        return null
      })
      .filter(error => error)
      .reduce((acc, error) => ({
        ...acc,
        errors: [...acc.errors, error]
      }), field),

  noLargeFiles: async field =>
    Object
      .values(field.value)
      .filter(value => value.size !== undefined && value.type)
      .map(fileField => {
        if (fileField.size > 30000000) {
          return 'Le poids du fichier ne doit pas dépasser 30Mo.'
        }

        return null
      })
      .filter(error => error)
      .reduce((acc, error) => ({
        ...acc,
        errors: [...acc.errors, error]
      }), field)
  ,

  noEmptyFiles: async field =>
    Object
      .values(field.value)
      .filter(value => value.size !== undefined && value.type)
      .map(fileField => {
        if (fileField.size === 0) {
          return 'Le fichier ne contient aucune donnée.'
        }

        return null
      })
      .filter(error => error)
      .reduce((acc, error) => ({
        ...acc,
        errors: [...acc.errors, error]
      }), field),

  dateFormat: async field => {
    if (!/(\d\d\/){2}(\d{4})$/.test(field.value)) {
      return {
        ...field,
        errors: [
          ...field.errors,
          'La date doit être au format JJ/MM/AAAA',
        ]
      }
    }

    return field
  },

  hourMinutesFormat: async field => {
    if (field.value && !/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(field.value)) {
      return {
        ...field,
        errors: [
          ...field.errors,
          'L\'heure doit être au format HH:MM',
        ]
      }
    }

    return field
  },

  isPastDate: async field => {
    if (field.value && JJMMAAAAToDate(field.value).getTime() > new Date().getTime()) {
      return {
        ...field,
        errors: [
          ...field.errors,
          'La date doit être antérieure à la date du jour',
        ]
      }
    }

    return field
  },

  dateTimeFormat: async field => {
    if (!/^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|(([1][26]|[2468][048]|[3579][26])00))))$/.test(field.value)) {
      return {
        ...field,
        errors: [
          ...field.errors,
          'La date doit être au format JJ/MM/AAAA',
        ]
      }
    }
    return field
  }
}

export const validate = async data => {
  const validatedData = await Object
    .entries(data) // Array<[ String, Field ]>
    .filter(([_, field]) => field.validationRules)
    .reduce(async (acc, [name, field]) => {
      return ({
        ...(await acc),
        // We reduces **each** validation rules ! (cause it's an array
        // of validation rules name (string))
        [name]: await field
          .validationRules
          .reduce(
            async (acc, rule) => await validators[rule](await acc),
            Promise.resolve(field),
          ),
      })
    }, Promise.resolve(data))

  const hasError = Object
    .entries(validatedData)
    .reduce((acc, [_, field]) =>
      acc || (field.errors.length > 0 && typeof field.errors[0] !== 'object')
      , false)

  if (hasError) {
    throw new ValidationError(validatedData)
  }
  return validatedData
}

export const validateArray = async (data, name) => {
  let errors = []

  for (let field in data.validationArrayRules) {
    for (let ruleIdx in data.validationArrayRules[field]) {
      Array.prototype.map.call(
        await Promise.all(data.value.map(
          async lineValue =>
            await validators[data.validationArrayRules[field][ruleIdx]]({
              value: lineValue[field],
              errors: []
            })
        )),
        (element, idx) => {
          errors[idx] = {
            ...errors[idx],
            [field]: errors[idx] && errors[idx][field]
              ? errors[idx][field].concat(element.errors)
              : element.errors
          }
        }
      )
    }
  }

  const hasError = errors.some(
    errorObject => {
      for (var fields in errorObject) {
        if (errorObject[fields].length > 0)
          return true
      }

      return false
    }
  )

  if (hasError) {
    throw new ValidationError({ name, errors })
  }
}

export const validateQuestionnaireStep = async ({ idx, data }) => {
  const validatedData = await Object
    .entries(data) // Array<[ String, Field ]>
    .filter(([_, field]) => field.validationRules)
    .reduce(async (acc, [name, field]) => {
      return ({
        ...(await acc),
        // We reduces **each** validation rules ! (cause it's an array
        // of validation rules name (string))
        [name]: await field
          .validationRules
          .reduce(
            async (acc, rule) => await validators[rule](await acc),
            Promise.resolve(field),
          ),
      })
    }, Promise.resolve(data))

  const hasError = Object
    .entries(validatedData)
    .reduce((acc, [_, field]) =>
      acc || field.errors.length > 0
      , false)

  if (hasError) {
    throw new ValidationError({ idx, validatedData })
  }
  return { idx, validatedData }
}

export const validatedCustomFieldContents = data => {
  const validatedData = Object
    .entries(data)
    .reduce((acc, [id, field]) => {

      return ({
        ...acc,
        [id]: {
          ...field,
          error: (field.mandatory && (field.content.length === 0 || Object.keys(field.content).length === 0))
            ? "Ce champ est requis."
            : null
        }
      })
    }
      , {})

  const hasError = Object
    .values(validatedData)
    .some(field => field.error)

  if (hasError) {
    throw new ValidationError(validatedData)
  }
  return validatedData
}

export const validatedEmailList = data => {
  const validatedData = {
    recipients: {
      ...data,
      error: data.value.length === 0 || data.value.some(email => !/\S+@\S+\.\S+/.test(email))
        ? "Ce champ est requis."
        : null
    }
  }

  const hasError = Object
    .values(validatedData)
    .some(field => field.error)

  if (hasError) {
    throw new ValidationError(validatedData)
  }
  return validatedData
}

export const validatedPasswordConfirmation = (data, passwordFieldName, passwordConfirmationFieldName) => {
  if (data[passwordFieldName].value !== data[passwordConfirmationFieldName].value) {
    throw new ValidationError({
        ...data,
        [passwordConfirmationFieldName]: {
          ...data[passwordConfirmationFieldName],
          errors: ['Les mots de passe ne correspondent pas'],
        },
    })
  }
}

export class ValidationError extends Error {
  constructor(data) {
    super('Validation error')
    this.data = data
  }
}

export class SpecialityValidationError extends Error {
  constructor(data) {
    super('Speciality validation error')
    this.data = data
  }
}
