import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from "react-router-dom";
import Logo from '../Asset/Image/logo-shareconfrere.svg';
import { useAlert } from 'react-alert'
import * as Ui from './Ui'
import * as TeleExpertise from '../State/Cohort'
import * as CustomField from '../State/CustomField'
import * as Questionnaire from '../State/Questionnaire'
import QuestionnaireViewer from './Ui/QuestionnaireViewer'
import styled, { css } from 'styled-components'
import { apiUrl } from '../Util/Config'
import { JJMMAAAAToDate } from '../Util/Format'

const QuestionnaireComponent = (props) => {
  const dispatch = useDispatch()
  const alert = useAlert()
  const history = useHistory();
  const data = useSelector(Questionnaire.selectData)
  const currentStep = useSelector(Questionnaire.selectCurrentStep)
  const activeTab = useSelector(Questionnaire.selectActiveTab)
  const sending = useSelector(Questionnaire.selectSending)
  const success = useSelector(Questionnaire.selectSuccess)
  const isEditMode = useSelector(Questionnaire.selectViewerEditMode)
  const loading = useSelector(TeleExpertise.selectLoading)
  const errorMessage = useSelector(CustomField.selectErrorMessage)
  const forms = useSelector(CustomField.selectFormFields)
  const teleExpertiseName = 'cohort'

  const { match: { params } } = props

  const send = (e) => {
    e.preventDefault()
    dispatch(Questionnaire
      .sendQuestionnaire({
        id: forms[0].id,
        teleExpertiseType: 'cohorts',
        patientCode: params.patientCode,
        contents: stepQuestionnaire(currentStep)
      }))
  }

  useEffect(() => {
    dispatch(TeleExpertise
      .fetchQuestionnaire({
        publicUri: params.publicUri,
        patientCode: params.patientCode,
      }))

    return () => {
      dispatch(TeleExpertise.clean())
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, params.publicPatientUri])

  useEffect(() => {
    if (errorMessage)
      alert.error(errorMessage, { timeout: 5000 })
  }, [alert, errorMessage])

  const setActiveTab = tab => () => dispatch(Questionnaire.setActiveTab(tab))

    const dateSortedPatientSteps = Object.keys(data.datesByPatientStepId.value).length > 0
    ? data.patientSteps.value
      .slice()
      .filter(step =>
        step?.customFieldSections?.value.some(value => value.isPatientSection) ||
        (step?.activeVisioconferencing.value || step?.documents.value.length > 0)
      )
      .sort((a, b) =>
        JJMMAAAAToDate(data.datesByPatientStepId.value[a.id.value]) - JJMMAAAAToDate(data.datesByPatientStepId.value[b.id.value])
      )
    : data.patientSteps.value

  const stepData = dateSortedPatientSteps
    .filter((_, idx) => idx === Number(currentStep))

  const stepQuestionnaire = idx =>
    [dateSortedPatientSteps[idx]]
      .map(({ sections: { value: sectionIds } }) =>
        Object
          .entries(forms[0])
          .reduce((acc, [key, value]) => ({
            ...acc,
            [key]: key === 'sections'
              ? {
                ...value,
                allIds: value.allIds.filter(sectionId =>
                  sectionIds.includes(Number(sectionId))),
                byId: Object
                  .values(value.byId)
                  .filter(section =>
                    sectionIds.includes(Number(section.id)))
                  .reduce((acc, section) => [
                    ...acc,
                    ...section.fields,
                  ], [])
              }
              : value,
          }), {})
      )
      .reduce((acc, { sections, fields }) =>
        [...acc,
        Object.values(fields.byId)
          .filter(field => sections.byId.includes(field.id))
        ]
        , [])
      .reduce((acc, val) => acc.concat(val), [])

  const isEmptyForm = idx =>
    stepQuestionnaire(idx)
      .every(field => field.value.length === 0)

  useEffect(() => {
    if (currentStep !== null && currentStep >= 0) {
      setPatientEditMode(currentStep, isEditMode[currentStep] || isEmptyForm(currentStep))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep])

  const setPatientEditMode = (idx, value) =>
    dispatch(Questionnaire.setPatientEditMode({ idx, value }))

  const setCurrentStep = step => () => {
    dispatch(Questionnaire.setCurrentStep(step))
  }

  const tabs = [
    {
      label: "Description",
      name: TeleExpertise.QUESTIONNAIRE_DESCRIPTION,
      key: 'description',
    },
    {
      label: "Documents",
      name: TeleExpertise.QUESTIONNAIRE_DOCUMENTS,
      key: 'documents',
    },
    {
      label: "Visioconférence",
      name: TeleExpertise.QUESTIONNAIRE_VISIO,
      key: 'activeVisioconferencing',
    },
    {
      label: "Questionnaire patient",
      name: TeleExpertise.QUESTIONNAIRE,
      key: 'sections',
    },
  ].filter(({ key }) =>
    data.patientSteps.value[currentStep] &&
    (key === 'activeVisioconferencing'
      ? dateSortedPatientSteps[currentStep].activeVisioconferencing.value &&
      data.isVideoconferencingToday.value &&
      params.patientCode
      : dateSortedPatientSteps[currentStep][key].value.length > 0))

  const hasContents = (key, name) =>
    name === TeleExpertise.QUESTIONNAIRE_VISIO
      ? dateSortedPatientSteps[currentStep][key].value
      : dateSortedPatientSteps[currentStep][key].value.length > 0

  return (
    <Wrapper>
      <PortalHeader><img src={Logo} alt="Shareconfrere" /></PortalHeader>
      {loading
        ? <Ui.Layout.FullSpaceContainer>
          <Ui.Global.Loader />
        </Ui.Layout.FullSpaceContainer>
        : <PortalMain>
          <PortalSideBar>
            {currentStep !== null &&
              <Ui.Button.BackButton
                onClick={setCurrentStep(null)}
              />
            }
            <Ui.BasicElement.H1>
              {data.title.value}
            </Ui.BasicElement.H1>
            {currentStep === null
              ? <Description>
                {data.description.value}
              </Description>
              : <>
                {data.isVideoconferencingToday.value &&
                  data.patientSteps.value.some(step => step.activeVisioconferencing.value) &&
                  <VisioButton
                    isFilled
                    isGreen
                    type="button"
                    onClick={
                      () => window.open(`/patientVideoconference/videoconference/cohorts/${data.cohortId.value}/${params.patientCode}`, 'zoom')
                    }
                  >
                    Visioconférence
                  </VisioButton>
                }
                <Ui.BasicElement.H2>
                  Programme
                </Ui.BasicElement.H2>
                <QuestionnaireSteps>
                  <Ui.Stepper.Stepper>
                    {dateSortedPatientSteps
                      .map((step, idx) =>
                        <React.Fragment key={idx}>
                          {(step.description.value.length > 0 ||
                            step.documents.value.length > 0 ||
                            step.sections.value.length > 0 ||
                            (step.activeVisioconferencing.value && data.isVideoconferencingToday.value)) &&
                            <>
                              <Ui.Stepper.Step
                                label={step.title.value}
                                name="step"
                                idx={idx}
                                currentStep={currentStep}
                                current={currentStep === idx}
                                onClick={setCurrentStep(idx)}
                              />
                              <Ui.Stepper.Divider />
                            </>
                          }
                        </React.Fragment>
                      )}
                  </Ui.Stepper.Stepper>
                </QuestionnaireSteps>
              </>
            }
          </PortalSideBar>
          <QuestionnaireContainer>
            {success && !sending
              ? <ContentContainer>
                <SuccessMessage>Votre questionnaire a bien été envoyé.</SuccessMessage>
                <SuccessMessage>
                  <Ui.Global.GreenText onClick={() => history.go(0)}>Retour</Ui.Global.GreenText>
                </SuccessMessage>
              </ContentContainer>
              : currentStep === null
                ? <>
                  {data.questionnaireImage.value &&
                    data.patientStepsImageStretch.value
                    ? <QuestionnaireImagePreviewStretched backgroundSrc={
                      data.questionnaireImage.value
                        ? `${apiUrl}/cohort-image-patient-step/${data.questionnaireImage.value}`
                        : null
                    }
                      alignment={data.patientStepsImageAlign.value}
                    />
                    : <QuestionnaireImagePreview backgroundSrc={
                      data.questionnaireImage.value
                        ? `${apiUrl}/cohort-image-patient-step/${data.questionnaireImage.value}`
                        : null
                    }
                      alignment={data.patientStepsImageAlign.value}
                    />
                  }
                  <ContentContainer>
                    {data.patientSteps.value.length > 0
                      ? dateSortedPatientSteps
                        .filter((step, _) =>
                          step.description.value.length > 0 ||
                          step.documents.value.length > 0 ||
                          step.sections.value.length > 0 ||
                          (step.activeVisioconferencing.value && data.isVideoconferencingToday.value))
                        .map((step, idx) =>
                          <React.Fragment key={idx}><SummaryStep key={idx}>
                            <Ui.BasicElement.H3>
                              {step.title.value}
                            </Ui.BasicElement.H3>
                            <Ui.Button.BasicButton
                              onClick={setCurrentStep(idx)}
                            >
                              Afficher l'étape
                            </Ui.Button.BasicButton>
                          </SummaryStep>
                          </React.Fragment>
                        )
                      : <Ui.BasicElement.P>Aucun contenu n'est disponible pour cette cohorte</Ui.BasicElement.P>
                    }
                  </ContentContainer>
                </>
                : <>
                  <Ui.Layout.StepperContainer>
                    <nav>
                      <Ui.Stepper.StepperHorizontal>
                        {tabs.length > 0 &&
                          tabs.map(({ label, name, key }, idx, arr) =>
                            <React.Fragment key={idx}>
                              {hasContents(key, name) &&
                                <Ui.Stepper.Step
                                  label={label}
                                  name={name}
                                  current={activeTab === (idx + 1)}
                                  onClick={setActiveTab(idx + 1)}
                                />
                              }
                              {arr[idx + 1]
                                ? <Ui.Stepper.ArrowSeparator />
                                : null
                              }
                            </React.Fragment>
                          )}
                      </Ui.Stepper.StepperHorizontal>
                      <ActionButton>
                        {activeTab < tabs.length
                          ? <NavbarButton
                            isFilled
                            isPrimary
                            type="button"
                            onClick={setActiveTab(activeTab + 1)}>
                            Suivant
                          </NavbarButton>
                          : (!data.datesByPatientStepId.value[stepData[0]?.id.value] ||
                            (data.datesByPatientStepId.value[stepData[0]?.id.value] &&
                              JJMMAAAAToDate(data.datesByPatientStepId.value[stepData[0].id.value]) <= new Date())) &&
                            params.patientCode &&
                            tabs.some(tab => tab.name === TeleExpertise.QUESTIONNAIRE)
                            ? null
                            : null
                        }
                      </ActionButton>
                    </nav>
                  </Ui.Layout.StepperContainer>
                  <ContentContainer>
                    <Ui.Layout.Form onSubmit={send} noValidate>
                      <QuestionnaireViewer
                        data={stepData}
                        forms={forms}
                        isVideoconferencingToday={data.isVideoconferencingToday.value}
                        tabName={tabs.length > 0 && tabs[activeTab - 1].name}
                        teleExpertiseName={teleExpertiseName}
                        teleExpertiseId={data.cohortId.value}
                        // !!! TODO handle 2 types of id : customFieldId and patientStepId
                        isEditMode={
                          params.patientCode &&
                          isEditMode[currentStep] &&
                          // No defined date
                          (!data.datesByPatientStepId.value[stepData[0]?.id.value] ||
                            // Or defined date not current or past date
                            (data.datesByPatientStepId.value[stepData[0]?.id.value] &&
                            JJMMAAAAToDate(data.datesByPatientStepId.value[stepData[0].id.value]) <= new Date()))
                        }
                        patientCode={params.patientCode}
                      />
                    </Ui.Layout.Form>
                  </ContentContainer>
                </>
            }
            {currentStep !== null &&
              <ActionButtonMobile>
                {activeTab < tabs.length
                  ? <NavbarButton
                    isFilled
                    isPrimary
                    type="button"
                    onClick={setActiveTab(activeTab + 1)}
                  >
                    Suivant
                  </NavbarButton>
                  : params.patientCode
                    ? <NavbarButton
                      isFilled
                      isPrimary
                      type="button"
                      onClick={isEditMode[currentStep] ? send : () => setPatientEditMode(currentStep, true)}
                      disabled={sending}>
                      {sending && <Ui.Global.Loader />}
                      {isEditMode[currentStep]
                        ? 'Envoyer le formulaire'
                        : 'Modifier le formulaire'
                      }
                    </NavbarButton>
                    : null
                }
              </ActionButtonMobile>
            }
          </QuestionnaireContainer>
        </PortalMain>
      }
    </Wrapper >
  )
}

const Description = styled(props => <Ui.BasicElement.P {...props} />)`
  word-break: normal;
  overflow-wrap: anywhere;`

const Wrapper = styled.div`
  position: absolute;
  overflow: scroll;
  width: 100%;
  height: 100%;
  background-color: rgba(219, 230, 237, 0.3);
`

const PortalHeader = styled.div`
  display: flex;
  min-height: 3.75rem;
  box-shadow: ${props => props.theme.boxShadow.thin};
  background: ${props => props.theme.white};
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
  z-index: 100;
  img {
    height: 1.8rem;
  }
  @media only screen and (min-width: ${props => props.theme.device.desktop}) {
    position: fixed;
  }
`

const PortalMain = styled.div`
  display: flex;
  min-height: 100vh;
  overflow: hidden;
  flex-flow: column nowrap;
`

const PortalSideBar = styled.div`
  display: flex;
  height: 100vh;
  flex-direction: column;
  z-index: 80;
  position: fixed;
  width: auto;
  flex-shrink: 0;
  box-shadow: ${props => props.theme.boxShadow.right};
  box-sizing: border-box;
  top: 0;
  background: ${props => props.theme.white};
  padding-top: 85px;
  padding-left: 1.9rem;
  padding-right: 1.9rem;
  padding-bottom: 1.9rem;
  overflow: scroll;
  @media only screen and (min-width: ${props => props.theme.device.desktop}) {
    width: 25rem;
  }
  @media only screen and (max-width: ${props => props.theme.device.desktop}) {
    padding-left: 1rem;
    position: relative;
    height: auto;
    padding-top: 0;
    padding-bottom: 1rem;
  }
  ${Ui.BasicElement.H1} {
    font-size: 2rem;
    margin: .7rem 0;
    margin-top: 3rem;
  }
  ${Ui.BasicElement.H2} {
    font-size: 1.5rem;
    line-height: 1.5rem;
  }
  ${Ui.BasicElement.P} {
    font-size: 1.1rem;
    line-height: 1.6rem;
  }
  ${Ui.Button.Back} {
    flex: initial;
  }
`

const ContentContainer = styled.div`
  margin-top: 2rem;
  padding-left: 0;
  @media only screen and (min-width: ${props => props.theme.device.desktop}) {
    padding-left: 28rem;
    padding-right: 2rem;
  }
`

const QuestionnaireContainer = styled.div`
  flex: 1;
  background-color: ${props => props.theme.lightGrey};
  padding-top: calc(3.75rem);
  box-sizing: border-box;
  @media only screen and (max-width: ${props => props.theme.device.desktop}) {
    min-height: auto;
  }
  ${Ui.Layout.StepperContainer} {
  left: calc(2rem + 359px);
  }
  ${Ui.Layout.Section} {
    padding-right: 0;
  }
  ${Ui.CustomFieldViewer.CustomFieldsContainer} {
    background: none;
  }
  ${Ui.CustomFieldViewer.CustomFieldsSection} {
    background: white;
  }
  ${QuestionnaireViewer.Stepcontainer} {
    margin-bottom: 2rem;
  }
  ${Ui.Layout.Form} {
    @media only screen and (min-width: ${props => props.theme.device.desktop}) {
      margin-top: 5rem;
    }
  }
  ${props => props.noPadding && css`
    padding-top: 48px;
    padding-left: 359px;
    padding-right: 0;
  `}
  h2 {
    font-weight: 600;
    font-size: 1.125rem;
    color: ${props => props.theme.darkBlue};
    line-height: 1.6rem;
  }
  ${Ui.BasicElement.H3} {
    font-size: 1rem;
  }
  @media only screen and (max-width: ${props => props.theme.device.desktop}) {
    padding-right: 1rem;
    padding-left: 1rem;
    padding-top: .6rem;
  }
  ${Ui.Layout.Row} {
    margin-top: 1rem;
  }
  ${Ui.BasicElement.H2} {
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }
  ${Ui.BasicElement.P} {
    font-size: 1rem;
    line-height: 1.4rem;
    min-height: .2rem;
  }
  ${Ui.BasicElement.H3}, ${Ui.Form.Label} {
    color: ${props => props.theme.darkBlue};
  }
  ${Ui.Select.SelectContainer} {
    width: 15rem;
  }
  ${Ui.Form.Label} {
    opacity: ${props => props.disabled ? '.8' : '1'};
    &:disabled {
      font-weight: 700;
    }
  };
  ${Ui.Form.Input} {
    &:disabled {
      border-bottom: 1px dotted;
      border-color: ${props => props.theme.grey2};
    }
  };
  ${Ui.Layout.ActionButtonsContainer} {
    align-self: flex-end;
  }
`

const QuestionnaireImagePreview = styled.div`
  background: url(${props => props.backgroundSrc}) no-repeat white;
  background-size: auto 90%;
  width: calc(100% - 25rem);
  position: relative;
  left: 25rem;
  background-position: center;
  height: 8rem;
  @media only screen and (min-width: ${props => props.theme.device.desktop}) {
    height: 19rem;
  }
`

const QuestionnaireImagePreviewStretched = styled.div`
  background: url(${props => props.backgroundSrc}) no-repeat;
  background-position: ${props =>
    props.alignment === 1
      ? 'top'
      : props.alignment === 2
        ? 'center'
        : 'bottom'
  };
  background-size: cover;
  width: calc(100% - 25rem);
  left: 25rem;
  position: relative;
  height: 8rem;
  @media only screen and (min-width: ${props => props.theme.device.desktop}) {
    height: 19rem;
  }
`

const QuestionnaireSteps = styled.div`
    margin-bottom: 2rem;
  ${Ui.Stepper.Stepper} {
    margin-top: 1rem;
    @media only screen and (max-width: ${props => props.theme.device.desktop}) {
      display: block;
    }
  }
  ${Ui.Stepper.StepCircle} {
    border-width: 3px;
  }
  ${Ui.Stepper.Divider} {
    &:not(:last-child) {
      height: 1.3rem;
      margin-left: .75rem;
      margin-top: .35rem;
      margin-bottom: .35rem;
      border-color: ${props => props.theme.grey2};
    }
  }
  ${Ui.Stepper.StepLabel} {
    font-size: 1.1rem;
    a {
      color: ${props => props.theme.darkBlue};
    }
  }
`

const SummaryStep = styled.div`
  margin-top: 1rem;
  margin-bottom: 1.8rem;
`

const NavbarButton = styled(props => <Ui.Button.BasicButton {...props} />)`
  align-self: flex-start;
  @media only screen and (max-width: ${props => props.theme.device.desktop}) {
    margin-bottom: 1rem;
    margin-top: .5rem;
  }
`

const ActionButton = styled.div`
  @media only screen and (max-width: ${props => props.theme.device.desktop}) {
    display: none;
  }
`

const ActionButtonMobile = styled.div`
  @media only screen and (min-width: ${props => props.theme.device.desktop}) {
    display: none;
  }
`

const SuccessMessage = styled(props => <Ui.BasicElement.P {...props} />)`
  text-align: center;
  font-size: 1.2rem;
  cursor: pointer;
  &:first-child {
    margin-top: 3rem;
  }
`

const VisioButton = styled(props => <Ui.Button.BasicButton {...props} />)`
  width: 12rem;
  margin-top: 1rem;
  margin-bottom: 1.4rem;
`

export default QuestionnaireComponent;
