const WithPermissionsComponent = ({
  to = [],
  domains = [],
  permissions = [],
  children,
}) => {
  const { _canBe = {}, _canBeModified = {} } = permissions

  return to.every(permission => _canBe[permission]) &&
    domains.every(domain => _canBeModified[domain])
    ? children
    : null
}

export default WithPermissionsComponent;
