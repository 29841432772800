import React from 'react';
import styled from 'styled-components'

const ZoomContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: .5rem;
  width: 17rem;
  position: absolute;
`

const ZoomElement = styled.div`
  font-size: .8rem;
  background: ${props => props.theme.grey};
  display: flex;
  background-color: white;
  padding: .5rem;
  flex: 1 0;
  /* min-width: 10rem; */
  border-radius: 5px;
  box-shadow: 0 2px 5px 1px rgba(134,174,197,0.24);
  img {
    width: 10rem;
    flex: initial;
  }
  span {
    flex: 1;
    padding-left: 1rem;
  }
`

export const Zoom = ({
  onClose,
  src,
  children,
  ...props
}) =>
  <ZoomContainer onClose={onClose}>
    <ZoomElement>
      <img src={src} alt="Zoomed" />
    </ZoomElement>
  </ZoomContainer>
