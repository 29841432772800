import { createSlice } from '@reduxjs/toolkit';
import {
  saveField as commonSaveField,
  setEditField as commonSetEditField,
  restoreInitialValue as commonRestoreInitialValue,
  updateInitialValue as commonUpdateInitialValue,
} from '../Util/InlineEditActions';

export const SECTION_PARAMETERS = 'parameters';
export const SECTION_PATIENTS = 'patients';
export const SECTION_COLLEAGUE = 'colleague';
export const SECTION_PARTICIPANTS = 'participants';
export const SECTION_PROGRAMMING = 'programmation';
export const QUESTIONNAIRE_DESCRIPTION = 'description';
export const QUESTIONNAIRE_DOCUMENTS = 'documents';
export const QUESTIONNAIRE_VISIO = 'visio';
export const QUESTIONNAIRE = 'questionnaire';
export const RESOURCE_ADMINISTRATOR = 'managers';
export const RESOURCE_DISCIPLINES = 'disciplines';
export const RESOURCE_PATIENTS = 'patients';

export const INITIAL_STATE = {
  data: {
    ownerFullname: { value: '', errors: [], validationRules: [] },
    ownerTitle: { value: '', errors: [], validationRules: [] },
    id: { value: '', errors: [], validationRules: [] },
    name: { value: '', initialValue: '', isFieldEdit: false, errors: [], validationRules: ['notEmpty'] },
    description: { value: '', initialValue: '', isFieldEdit: false, errors: [], validationRules: [] },
    managers: { value: [], isFieldEdit: false, errors: [], validationRules: [] },
    owner: { value: '', errors: [], validationRules: [] },
    ownerId: { value: '', errors: [], validationRules: [], },
    tags: { value: [], isFieldEdit: false, errors: [], validationRules: [] },
    disciplines: { value: [], isFieldEdit: false, errors: [], validationRules: [] },
    organizations: { value: [], errors: [], validationRules: [] },
    labels: { value: [], errors: [], validationRules: [] },
    colleagues: { value: [], isFieldEdit: false, errors: [], validationRules: [] },
    sessionId: { value: '', errors: [], validationRules: [] },
    isOutdated: { value: null, errors: [], validationRules: [], },
    headingDocuments: { value: {}, errors: [], validationRules: [], },
    patients: { value: [], errors: [], validationRules: [], },
    organizationMembers: { value: [], errors: [], validationRules: [], },
    reportImageFileName: { value: '', errors: [], validationRules: [] },
    currentUserAdmin: { value: '', errors: [], validationRules: [] },
    patientLimitLocked: { value: false, errors: [], validationRules: [] },
    patientLimitReached: { value: false, errors: [], validationRules: [] },
    patientsLocked: { value: [], errors: [], validationRules: [] },
    numberAddPatientAction: { value: 0, errors: [], validationRules: [] },
    questionnaires: { value: [], errors: [], validationRules: [] },
    publicUri: { value: '', initialValue: '', isFieldEdit: false, errors: [], validationRules: [] },
    publicPatientUri: { value: '', initialValue: '', isFieldEdit: false, errors: [], validationRules: [] },
    isVideoconferencingToday: { value: false, errors: [], validationRules: [] },
    pictureFileName: { value: '', errors: [], validationRules: [] },
    patientStepsImageFileName: { value: '', errors: [], validationRules: [] },
    portalHeaderImage: { value: '', errors: [], validationRules: [] },
    portalHeaderImageStretch: { value: true, errors: [], validationRules: [] },
    portalHeaderImageAlign: { value: 2, errors: [], validationRules: [] },
    portalDocuments: { value: [], errors: [], validationRules: [] },
    portalAbout: { value: '', errors: [], validationRules: [] },
    permissions: {
      value: {
        _canBe: {
          edited: false,
          deleted: false,
          archived: false,
          commented: false,
        },
        _canBeModified: {}
      },
      errors: [],
      validationRules: []
    },
    createdAt: { value: '', errors: [], validationRules: [] },
    updatedAt: { value: '', errors: [], validationRules: [] },
    archive: { value: false, errors: [], validationRules: [] },
  },
  owner: {},
  sending: false,
  pending: false,
  loading: false,
  success: false,
  error: false,
  deleted: false,
  isEditMode: {
    addPatient: false,
    organizations: false,
    colleagues: false,
    tags: false,
    disciplines: false,
    managers: false,
    labels: false,
  },
  errorMessage: null,
  infoMessage: null,
  hasQuestionnaire: false,
  sections: {
    [SECTION_PARAMETERS]: { isFilled: false, isValid: false },
    [SECTION_PARTICIPANTS]: { isFilled: false, isValid: false },
    [SECTION_PATIENTS]: { isFilled: false, isValid: false },
    [SECTION_PROGRAMMING]: { isFilled: false, isValid: false },
  },
  search: {
    colleagues: { value: '', loading: false },
    managers: { value: '', loading: false },
    organizations: { value: '', loading: false },
    disciplines: { value: '', loading: false },
    patients: { value: '', loading: false },
  },
  id: null,
  expertisesWithComments: [],
}

const slice = createSlice({
  name: 'cohort',
  initialState: INITIAL_STATE,
  reducers: {

    updateData: (state, { payload: { name, value, errors = [] } }) => ({
      ...state,
      data: {
        ...state.data,
        [name]: {
          ...state.data[name],
          value,
          errors,
        },
      },
      error: false,
      errorMessage: null,
    }),

    toggle: (state, { payload: { id, toggled, name } }) => ({
      ...state,
      data: {
        ...state.data,
        [name]: {
          ...state.data[name],
          value: toggled
            ? [...state.data[name].value, id]
            : state.data[name].value.filter(i => id !== i),
        },
      },
      search: {
        ...state.search,
        [name]: {
          ...state.search[name],
          value: '',
        }
      },
      error: false,
      errorMessage: null,
    }),

    setHasQuestionnaire: (state, { payload: hasQuestionnaire }) => ({
      ...state,
      hasQuestionnaire,
    }),

    removeTag: (state, { payload: { idx } }) => ({
      ...state,
      data: {
        ...state.data,
        tags: {
          ...state.data.tags,
          value:
            state.data.tags.value
              .filter((tag, i) => i !== idx)
        }
      }
    }),

    updateOwner: (state, { payload: { colleague: { colleagueId, colleagueTitle, colleagueFullName }, formerOwner } }) => ({
      ...state,
      data: {
        ...state.data,
        ownerTitle: {
          ...state.data.ownerTitle,
          value : colleagueTitle,
          initialValue : colleagueTitle,
        },
        ownerFullname: {
          ...state.data.ownerFullname,
          value : colleagueFullName,
          initialValue : colleagueFullName,
        },
        ownerId: {
          ...state.data.ownerId,
          value: Number(colleagueId)
        },
        owner: {
          ...state.data.owner,
          value: [
            Number(colleagueId)
          ]
        },
        managers: {
          ...state.data.managers,
          value: [
            ...state.data.managers.value.filter(e => e !== colleagueId),
            formerOwner
          ]
        },
        permissions: {
          ...state.data.permissions,
          value: {
            ...state.data.permissions.value,
            _canBe: {
              ...state.data.permissions.value._canBe,
              deleted: false,
            },
          }
        }
      }
    }),

    updateTagContent: (state, { payload: { value, idx } }) => ({
      ...state,
      data: {
        ...state.data,
        tags: {
          ...state.data.tags,
          value: state.data.tags.value
            .map((tag, i) => i === idx ? value : tag)
        }
      }
    }),

    addManager: (state, { payload: { id } }) => ({
      ...state,
    }),

    removeManager: (state, { payload: { id } }) => ({
      ...state,
    }),

    addColleague: (state, { payload: { id } }) => ({
      ...state,
      data: {
        ...state.data,
        colleagues: {
          ...state.data.colleagues,
          value: [
            ...state.data.colleagues.value,
            id
          ]
        }
      },
    }),

    removeColleague: (state, { payload: { id } }) => ({
      ...state,
      data: {
        ...state.data,
        colleagues: {
          ...state.data.colleagues,
          value:
            state.data.colleagues.value
              .filter(colleagueId => colleagueId !== id)
        }
      }
    }),

    addOrganization: (state, { payload: { id } }) => ({
      ...state,
      data: {
        ...state.data,
        organizations: {
          ...state.data.organizations,
          value: [
            ...state.data.organizations.value,
            id
          ]
        }
      }
    }),

    removeOrganization: (state, { payload: { id } }) => ({
      ...state,
      data: {
        ...state.data,
        organizations: {
          ...state.data.organizations,
          value:
            state.data.organizations.value
              .filter(organizationId => organizationId !== id)
        }
      },
      organizationMembers: {
        ...state.data.organizationMembers,
        value: [],
      },
    }),

    addPatient: (state, { payload: { id } }) => ({
      ...state,
      data: {
        ...state.data,
        patients: {
          ...state.data.patients,
          value: [
            ...state.data.patients.value,
            id
          ]
        }
      },
      sending: true,
    }),

    removePatient: (state, { payload: { id } }) => ({
      ...state,
      data: {
        ...state.data,
        patients: {
          ...state.data.patients,
          value:
            state.data.patients.value
              .filter(patientId => patientId !== id)
        }
      },
      expertisesWithComments: Object
        .entries(state.expertisesWithComments)
        .map(([key, _]) => [key, key === id])
        .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {})
    }),

    removePortalDocument: (state) => ({
      ...state,
      success: true,
    }),

    invalidate: (state, { payload: { message } }) => ({
      ...state,
      sending: false,
      error: true,
      errorMessage: message,
    }),

    send: state => ({
      ...state,
      sending: true,
      error: false,
      errorMessage: null,
    }),

    update: state => ({
      ...state,
      sending: true,
    }),

    success: (state, { payload }) => ({
      ...state,
      id: payload,
      sending: false,
      loading: false,
      error: false,
    }),

    deleted: state => ({
      ...state,
      pending: false,
      error: false,
      deleted: true,
    }),

    updateImage: (state, { payload: { imageType, imageFileName = '' } }) => ({
      ...state,
      data: {
        ...state.data,
        [imageType]: {
          ...state.data[imageType],
          value: imageFileName,
        },
      },
    }),

    fetch: (state, { payload: { teleExpertiseIdParam: id, sessionIdParam } }) => ({
      ...state,
      loading: true,
      id,
    }),

    fetchQuestionnaire: (state, { payload: { teleExpertiseIdParam: id, sessionIdParam } }) => ({
      ...state,
      loading: true,
      id,
    }),

    fetchOrganizations: (state, { payload }) => ({
      ...state
    }),

    receivedOrganizationMembers: (state, { payload }) => ({
      ...state,
      data: {
        ...state.data,
        organizationMembers: {
          ...state.data.organizationMembers,
          value: payload,
        }
      }
    }),

    receivedOrganizationMembersById: (state, { payload: { organizationId, members } }) => ({
      ...state,
      data: {
        ...state.data,
        organizationMembers: {
          ...state.data.organizationMembers,
          value: {
            ...state.data.organizationMembers.value,
            [organizationId]: members.filter(id => (![
              ...state.data.managers.value,
              ...state.data.owner.value,
              ...state.data.colleagues.value,
            ].includes(id))),
          }
        }
      }
    }),

    addOrganizationMembers: (state, { payload }) => ({
      ...state
    }),

    removeOrganizationMembers: (state, { payload: organizationId }) => ({
      ...state,
      data: {
        ...state.data,
        organizationMembers: {
          ...state.data.organizationMembers,
          value: Object
            .keys(state.data.organizationMembers.value)
            .reduce((object, key) => {
              if (Number(key) !== Number(organizationId)) {
                object[key] = state.data.organizationMembers.value[key]
              }
              return object
            }, {})
        }
      }
    }),

    received: (state, { payload }) => ({
      ...state,
      data: Object
        .entries(payload)
        .reduce((acc, [name, value]) => ({
          ...acc,
          [name]: typeof value === 'string'
            ? ({
              ...state.data[name],
              value,
              initialValue: value,
              isFieldEdit: false,
            })
            : ({
              ...state.data[name],
              value,
            }),
        }), state.data),
      loading: false,
    }),

    receivedOwner: (state, { payload: { owner, name } }) => ({
      ...state,
      data: {
        ...state.data,
        owner: {
          ...state.data.owner,
          value: owner.username,
        },
        ownerId: {
          ...state.data.ownerId,
          value: owner.id,
        },
        name: {
          ...state.data.name,
          value: name,
        }
      },
      loading: false,
    }),

    setEditMode: (state, { payload: { target, value } }) => ({
      ...state,
      isEditMode: {
        ...state.isEditMode,
        [target]: value,
      }
    }),

    updateField: (state, { payload: { name, value, errors = [] } }) => ({
      ...state,
      data: {
        ...state.data,
        [name]: { ...state.data[name], value, errors },
      },
    }),

    updatePatientsLocked: (state, { payload: patientId }) => ({
      ...state,
      data: {
        ...state.data,
        patientsLocked: {
          ...state.data.patientsLocked,
          value: [
            ...state.data.patientsLocked.value,
            patientId
          ],
        }
      },
    }),

    validateData: (state, { payload: { name } }) => ({
      ...state,
      data: {
        ...state.data,
        [name]: {
          ...state.data[name],
          errors: []
        }
      }
    }),

    closeExpertise: state => ({
      ...state,
      data: {
        ...state.data,
        archive: {
          ...state.data.archive,
          value: true
        }
      }
    }),

    openExpertise: (state, { payload: teleExpertiseId }) => ({
      ...state,
      data: {
        ...state.data,
        archive: {
          ...state.data.archive,
          value: false
        }
      }
    }),

    updateColleagues: (state, { payload }) => ({
      ...state
    }),

    updateLabelContent: (state, { payload: { value, idx } }) => ({
      ...state,
      data: {
        ...state.data,
        labels: {
          ...state.data.labels,
          value: state.data.labels.value
            .map((el, i) => i === idx ? { ...el, content: value } : el)
        }
      }
    }),

    updateLabelColor: (state, { payload: { value, idx } }) => ({
      ...state,
      data: {
        ...state.data,
        labels: {
          ...state.data.labels,
          value: state.data.labels.value
            .map((el, i) => i === idx ? { ...el, color: value } : el)
        }
      }
    }),

    addLabel: (state, { payload: { content, color, id } }) => ({
      ...state,
      data: {
        ...state.data,
        labels: {
          ...state.data.labels,
          value: [
            ...state.data.labels.value,
            {
              content,
              color,
              id
            },
          ]
        }
      },
      sending: id && ~String(id).indexOf('TG_') ? false : true,
    }),

    removeLabel: (state, { payload: { idx, id } }) => ({
      ...state,
      data: {
        ...state.data,
        labels: {
          ...state.data.labels,
          value:
            state.data.labels.value
              .filter((_, i) => i !== idx)
        },
      },
      sending: id && ~String(id).indexOf('TG_') ? false : true,
    }),

    updatePatients: (state, { payload }) => ({
      ...state
    }),

    setQuery: (state, { payload: { name, value } }) => ({
      ...state,
      search: {
        ...state.search,
        [name]: {
          ...state.search[name],
          value
        }
      }
    }),

    suggestSpecialities: state => ({
      ...state,
    }),

    addTag: (state, { payload: value = '' }) => ({
      ...state,
      data: {
        ...state.data,
        tags: {
          ...state.data.tags,
          value: [...state.data.tags.value, value],
        },
      },
    }),

    addDiscipline: (state, { payload }) => ({
      ...state,
      data: {
        ...state.data,
        disciplines: {
          ...state.data.disciplines,
          value: [...state.data.disciplines.value, payload],
        },
      },
    }),

    apiError: (state, action) => ({
      ...state,
      errorMessage: action.payload,
      pending: false,
      error: true,
      success: false,
      sending: false,
    }),

    setInfoMessage: (state, { payload: message }) => ({
      ...state,
      infoMessage: message,
    }),

    fetchCohortFromCode: (state, { payload: cohortId }) => ({
      ...state,
      id: cohortId,
    }),

    fetchPatientCohortFromCode: (state, { payload: cohortId }) => ({
      ...state,
      id: cohortId,
    }),

    setCohortUri: (state, { payload }) => ({
      ...state,
      data: {
        ...state.data,
        publicPatientUri: {
          ...state.data.publicPatientUri,
          value: payload,
        }
      },
    }),

    clean: state => INITIAL_STATE,

    commonSaveField,
    commonSetEditField,
    commonRestoreInitialValue,
    commonUpdateInitialValue,
  },
})

export const {
  updateData,
  invalidate,
  send,
  update,
  success,
  fetch,
  fetchOrganizations,
  receivedOrganizationMembers,
  receivedOrganizationMembersById,
  addOrganizationMembers,
  removeOrganizationMembers,
  received,
  validateData,
  receivedOwner,
  updateField,
  clean,
  updateColleagues,
  updatePatients,
  updateImage,
  setEditMode,
  closeExpertise,
  openExpertise,
  updateLabelContent,
  updateLabelColor,
  addLabel,
  removeLabel,
  addColleague,
  removeColleague,
  addOrganization,
  removeOrganization,
  setQuery,
  apiError,
  toggle,
  updateTagContent,
  updatePatientsLocked,
  removeTag,
  updateOwner,
  addManager,
  removeManager,
  deleted,
  suggestSpecialities,
  addTag,
  addDiscipline,
  addPatient,
  removePatient,
  setInfoMessage,
  setHasQuestionnaire,
  fetchQuestionnaire,
  fetchCohortFromCode,
  fetchPatientCohortFromCode,
  setCohortUri,
  removePortalDocument,
  commonSaveField: saveField,
  commonSetEditField: setEditField,
  commonRestoreInitialValue: restoreInitialValue,
  commonUpdateInitialValue: updateInitialValue,
} = slice.actions

export const selectData = state => state.cohort.data
export const selectPatientsLocked = state => state.cohort.data.patientsLocked.value
export const selectIsPatientLimitReached = state => state.cohort.data.patientLimitReached.value
export const selectLoading = state => state.cohort.loading
export const selectErrorMessage = state => state.cohort.errorMessage
export const selectId = state => state.cohort.id
export const selectColleagues = state => state.cohort.data.colleagues.value
export const selectPatients = state => state.cohort.data.patients.value
export const selectArchives = state => state.cohort.data.archives.value
export const selectHasQuestionnaire = state => state.cohort.hasQuestionnaire

export default slice.reducer
