import * as Colleague from '../State/Colleague'
import * as Login from '../State/Login'
import * as Resources from '../State/Resources'
import * as Api from '../Util/Api'
import { takeLatest, call, put, select, delay } from 'redux-saga/effects'
import { debounceTime } from '../Util/Date'

export const selectImageries = state => state.uploadFile.data.imagerie

export function* fetch({ payload: id }) {
  try {
    const {
      firstname,
      lastname,
      rppsNumber,
      speciality,
      city,
      colleagues,
      profilePicture,
      opinions,
      staffs,
      cohorts,
    } = yield call(Api.fetchColleague, id)

    yield put(Colleague.received({
      firstname,
      lastname,
      rppsNumber,
      speciality,
      city,
      colleagues,
      profilePicture,
      expertises: { staffs, cohorts, opinions },
      opinions: opinions.map(staff => ({
        ...staff,
        type: 'opinions'
      })),
      staffs: staffs.map(staff => ({
        ...staff,
        type: 'staffs'
      })),
      cohorts: cohorts.map(staff => ({
        ...staff,
        type: 'cohorts'
      })),
      defaultOpinion: opinions.find(({ thePersonal }) => thePersonal)
    }))

    // INFO : removed patient list from default opinion
  } catch (error) {
    yield put(Colleague.apiError(error.message))
  }
}

// INFO : removed addPatient/removePatient

export function* checkExpertiseFromList({ payload: { type, id } }) {
  try {
    const userId = yield select(Colleague.selectColleagueId)

    // TODO add correct endpoint to link colleague to teleexpertise
    yield call(Api.addUser, id, userId, type)

  } catch (error) {
    console.log(error)
    yield put(Colleague.apiError(error.message))
  }
}

export function* uncheckExpertiseFromList({ payload: { type, id, teleExpertise } }) {
  try {
    const userId = yield select(Colleague.selectColleagueId)

    yield call(Api.removeUser, teleExpertise.id, userId, teleExpertise.type)

  } catch (error) {
    yield put(Colleague.apiError(error.message))
  }
}

export function* fetchExpertises(type, query) {
  try {
    const { id } = yield select(Login.selectUser)
    yield put(Colleague.fetchAvailableExpertises())
    const teleExpertises = type === 'expertises'
      ? yield call(Api.getTeleExpertises, id, query)
      : yield call(Api.getTeleExpertisesByType, id, type, query)

    yield put(Colleague.receivedAvailableExpertises(teleExpertises))
  } catch (error) {
    yield put(Colleague.apiError(error.message))
  }
}

export function* setQuery({ payload: { name, value: query } }) {
  try {
    const delayMs = debounceTime(query)

    yield delay(delayMs)

    if (name === 'disciplines') {
      yield put(Resources.load({
        filter: [Resources.RESOURCE_DISCIPLINES],
        query,
      }))
    } else if (name === 'managers' || name === 'colleagues') {
      yield put(Resources.load({
        filter: [Resources.RESOURCE_MEMBERS],
        query,
      }))
    } else if (name === 'patients') {
      yield put(Resources.load({
        filter: [Resources.RESOURCE_USER_PATIENTS],
        query,
      }))
    } else {
      yield fetchExpertises(name, query)
    }

  } catch (error) {
    yield put(Colleague.apiError(error.message))
  }
}

const ColleagueSagas = function* () {
  yield takeLatest(`${Colleague.fetch}`, fetch);
  yield takeLatest(`${Colleague.checkExpertiseFromList}`, checkExpertiseFromList);
  yield takeLatest(`${Colleague.uncheckExpertiseFromList}`, uncheckExpertiseFromList);
  yield takeLatest(`${Colleague.setQuery}`, setQuery)
};

export default ColleagueSagas;
