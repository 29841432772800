import { createSlice } from '@reduxjs/toolkit'
import uniqid from 'uniqid'
import { uniqByIdAndType } from '../Util/Object';
import {
  saveField as commonSaveField,
} from '../Util/InlineEditActions';

export const INITIAL_STATE = {
  data: [{
    id: null,
    questionnaires: [],
    labels: { byId: {}, allIds: [], value: [] },
    sections: { byId: {}, allIds: [] },
    fields: { byId: {}, allIds: [] },
    choices: { byId: {}, allIds: [] }
  }],
  loading: false,
  pending: false,
  sending: false,
  error: false,
  success: false,
  isEditMode: false,
  errorMessage: null,
  locked: false,
  isViewerFieldEdit: { staffs: {}, opinions: {}, cohorts: {} },
  isBuilderFieldEdit: {},
  formsVisible: {}
}

const slice = createSlice({
  name: 'customField',
  initialState: INITIAL_STATE,
  reducers: {

    setErrors: (state, { payload: { teleExpertiseId, fieldId, error = '' } }) => ({
      ...state,
      data: state.data.map(form =>
        form.id === teleExpertiseId
          ? {
            ...form,
            fields: {
              ...form.fields,
              byId: {
                ...form.fields.byId,
                [fieldId]: {
                  ...form.fields.byId[fieldId],
                  error
                },
              }
            }
          }
          : form
      ),
    }),

    setBuilderErrors: (state, { payload: { id, type, error = '' } }) => ({
      ...state,
      data: state.data.map((form, idx) =>
        idx === 0
          ? {
            ...form,
            [type]: {
              ...form[type],
              byId: {
                ...form[type].byId,
                [id]: {
                  ...form[type].byId[id],
                  error
                }
              }
            },
          }
          : form
      ),
      error: true
    }),

    restoreInitialValue: (state, { payload: { teleExpertiseId, fieldId, value, error = '' } }) => ({
      ...state,
      data: state.data.map(form =>
        form.id === teleExpertiseId
          ? {
            ...form,
            fields: {
              ...form.fields,
              byId: {
                ...form.fields.byId,
                [fieldId]: {
                  ...form.fields.byId[fieldId],
                  value,
                  error,
                },
              }
            }
          }
          : form
      ),
      errorMessage: null
    }),

    updateTextField: (state, { payload: { teleExpertiseId, fieldId, value, error = '' } }) => ({
      ...state,
      data: state.data.map(form =>
        form.id === teleExpertiseId
          ? {
            ...form,
            fields: {
              ...form.fields,
              byId: {
                ...form.fields.byId,
                [fieldId]: {
                  ...form.fields.byId[fieldId],
                  value,
                  error,
                },
              }
            }
          }
          : form
      ),
      errorMessage: null
    }),

    updateFileField: (state, { payload: { teleExpertiseId, fieldId, value, error = '' } }) => ({
      ...state,
      data: state.data.map(form =>
        form.id === teleExpertiseId
          ? {
            ...form,
            fields: {
              ...form.fields,
              byId: {
                ...form.fields.byId,
                [fieldId]: {
                  ...form.fields.byId[fieldId],
                  value: Object
                    .values(value)
                    .map(doc => ({
                      [doc.name]: { name: doc.name }
                    })),
                  error,
                },
              }
            }
          }
          : form
      ),
      errorMessage: null
    }),

    updateChoiceField: (state, { payload: { teleExpertiseId, fieldId, choiceId, checked, teleExpertiseType, error = '' } }) => ({
      ...state,
      data: state.data.map(form =>
        (String(form.id) === String(teleExpertiseId) && form.type === teleExpertiseType)
          ? {
            ...form,
            fields: {
              ...form.fields,
              byId: {
                ...form.fields.byId,
                [fieldId]: {
                  ...form.fields.byId[fieldId],
                  value: checked
                    ? [
                      ...form.fields.byId[fieldId].value,
                      choiceId
                    ]
                    : form.fields.byId[fieldId].value
                      .filter(field => field !== choiceId)
                  ,
                  error
                }
              }
            }
          }
          : form
      ),
    }),

    updateRadioField: (state, { payload: { teleExpertiseId, fieldId, choiceId, error = '' } }) => ({
      ...state,
      data: state.data.map(form =>
        String(form.id) === String(teleExpertiseId)
          ? {
            ...form,
            fields: {
              ...form.fields,
              byId: {
                ...form.fields.byId,
                [fieldId]: {
                  ...form.fields.byId[fieldId],
                  value: [choiceId],
                  error,
                }
              }
            }
          }
          : form
      ),
    }),

    updateLabels: (state, { payload: { teleExpertiseId, fieldId, checked } }) => ({
      ...state,
      data: state.data.map(form =>
        form.id === teleExpertiseId
          ? {
            ...form,
            labels: {
              ...form.labels,
              value: checked
                ? [
                  ...form.labels.value,
                  fieldId,
                ]
                : form.labels.value.filter(labelId => Number(labelId) !== fieldId)
            }
          }
          : form
      ),
    }),

    updateBuilderFieldLabel: (state, { payload: { id, type, value } }) => ({
      ...state,
      data: state.data.map((form, idx) =>
        idx === 0
          ? {
            ...form,
            [type]: {
              ...form[type],
              byId: {
                ...form[type].byId,
                [id]: {
                  ...form[type].byId[id],
                  [type === "choices" ? 'content' : 'label']: value,
                  error: null
                }
              },
            }
          }
          : form
      )
    }),

    update: (state) => ({
      ...state,
      data: state.data.map(form =>
      ({
        ...form,
        fields: {
          ...form.fields,
          byId: Object
            .entries(form.fields.byId)
            .reduce((acc, [id, field]) => ({
              ...acc,
              [id]: {
                ...field,
                // error: ''
              }
            }), form.fields.byId),
        }
      })
      ),
      error: false,
      errorMessage: null,
      sending: true,
    }),

    invalidate: (state, { payload }) => ({
      ...state,
      pending: false,
      error: true,
      errorMessage: payload,
      sending: false,
    }),

    receivedFormFields: (state, { payload }) => ({
      ...state,
      data: [
        ...state.data.filter(form => form.id),
        ...payload,
      ].filter(uniqByIdAndType),
      formsVisible: payload
        .filter(e => e.id)
        .filter(uniqByIdAndType)
        .reduce((acc, { id }) => ({ ...acc, [id]: true }), {})
    }),

    receivedUpdatedFormFields: (state, { payload }) => ({
      ...state,
      data: state.data.map((form, idx) =>
        idx === 0
          ? {
            ...form,
            ...payload,
          }
          : form
      ),
      isBuilderFieldEdit: {
        ...state.isBuilderFieldEdit,
        ...Object
          .keys(state.data[0].choices.byId)
          .reduce((acc, id) => ({ ...acc, [id]: false }), {}),
      },
      locked: false,
    }),

    removePatientFormFields: (state, { payload: id }) => ({
      ...state,
      data: state.data.filter(teleExpertise => String(teleExpertise.id) !== String(id))
    }),

    receivedDocuments: (state, { payload: { id: teleExpertiseId, fieldId, documents } }) => ({
      ...state,
      data: state.data.map(form =>
        form.id === teleExpertiseId
          ? {
            ...form,
            fields: {
              ...form.fields,
              byId: {
                ...form.fields.byId,
                [fieldId]: {
                  ...form.fields.byId[fieldId],
                  value: documents,
                },
              }
            }
          }
          : form
      ),
    }),

    success: (state) => ({
      ...state,
      sending: false,
      error: false,
      success: true,
      errorMessage: null,
    }),

    fetch: (state, { payload: { patientId, teleExpertiseSessionId } }) => ({
      ...state,
      patientId,
      loading: true
    }),

    moveChoice: (state, { payload: { newField } }) => ({
      ...state,
      data: state.data.map((form, idx) =>
        idx === 0
          ? {
            ...form,
            fields: {
              ...form.fields,
              byId: {
                ...form.fields.byId,
                [newField.id]: newField
              }
            },
          }
          : form
      )
    }),

    moveField: (state, { payload: { newSection } }) => ({
      ...state,
      data: state.data.map((form, idx) =>
        idx === 0
          ? {
            ...form, sections: {
              ...form.sections,
              byId: {
                ...form.sections.byId,
                [newSection.id]: newSection
              }
            },
          }
          : form
      )
    }),

    moveSection: (state, { payload: { newCustomFields } }) => ({
      ...state,
      data: state.data.map((form, idx) =>
        idx === 0
          ? {
            ...form, sections: {
              ...form.sections,
              allIds: newCustomFields.sections
            },
          }
          : form
      )
    }),

    apiError: (state, action) => ({
      ...state,
      errorMessage: action.payload,
      sending: false,
      error: true,
      success: false,
    }),

    initViewerFieldEdit: (state, { payload: { type, id, value } }) => ({
      ...state,
      isViewerFieldEdit: {
        ...state.isViewerFieldEdit,
        [type]: {
          ...state.isViewerFieldEdit[type],
          [id]: {
            ...state.isViewerFieldEdit[type][id],
            labels: value,
            customFields: value,
          }
        }
      }
    }),

    resetViewerFieldEdit: (state, { payload }) => ({
      ...state,
      isViewerFieldEdit: Object
        .entries(state.isViewerFieldEdit)
        .reduce((acc, [type, value]) => ({
          ...acc,
          [type]: Object
            .entries(value)
            .reduce((acc, [id, value]) => ({
              ...acc,
              [id]: { labels: payload, customFields: payload }
            }), {})
        }), {})
    }),

    setEditFields: (state, { payload: { teleExpertiseId, fieldId, isFieldEdit } }) => ({
      ...state,
      data: state.data.map(form =>
        form.id === teleExpertiseId
          ? {
            ...form,
            fields: {
              ...form.fields,
              byId: {
                ...form.fields.byId,
                [fieldId]: {
                  ...form.fields.byId[fieldId],
                  isFieldEdit,
                },
              }
            }
          }
          : form
      ),
      errorMessage: null
    }),

    submitField: (state) => ({
      ...state,
      data: state.data.map((form, idx) =>
        idx === 0
          ? {
            ...form,
            fields: {
              ...form.fields,
              byId: Object
                .entries(form.fields.byId)
                .reduce((acc, [id, field]) => ({
                  ...acc,
                  [id]: {
                    ...field,
                    error: null
                  }
                }), form.fields.byId),
            },
            choices: {
              ...form.fields,
              byId: Object
                .entries(form.choices.byId)
                .reduce((acc, [id, field]) => ({
                  ...acc,
                  [id]: {
                    ...field,
                    error: null
                  }
                }), form.choices.byId),
            },
            errorMessage: null,
          }
          : form
      ),
      error: null,
      errorMessage: null,
    }),

    setBuilderEditFields: (state, { payload: { fieldId, editMode } }) => ({
      ...state,
      isBuilderFieldEdit: {
        ...state.isBuilderFieldEdit,
        [fieldId]: editMode
      },
      success: false,
    }),

    clearCfField: (state) => ({
      ...state,
      isBuilderFieldEdit: Object
        .entries(state.isBuilderFieldEdit)
        .map(([key, value]) => ({ [key]: false }))
        .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {})
    }),

    initBuilderFieldEdit: (state, { payload }) => ({
      ...state,
      isBuilderFieldEdit: payload
    }),

    closeAllBuilderFieldEdit: (state, { payload }) => ({
      ...state,
      isBuilderFieldEdit: Object
        .keys(state.isBuilderFieldEdit)
        .reduce((acc, key) => ({
          ...acc,
          [key]: (state.data[0].fields.byId[key] &&
            (state.data[0].fields.byId[key].error ||
              state.data[0].fields.byId[key].choices
                .some(id => state.data[0].choices.byId[id] && state.data[0].choices.byId[id].error)))
            ? true
            : false
        }), {})
    }),

    addSection: state => {
      const newId = uniqid('CF_')

      return ({
        ...state,
        data: state.data.map((form, idx) =>
          idx === 0
            ? {
              ...form,
              sections: {
                ...form.sections,
                byId: {
                  ...form.sections.byId,
                  [newId]: {
                    id: newId,
                    label: '',
                    fields: [],
                    isEditMode: true,
                  }
                },
                allIds: [...form.sections.allIds, newId]
              }
            }
            : form
        ),
        isBuilderFieldEdit: {
          ...state.isBuilderFieldEdit,
          [newId]: true
        }
      })
    },

    setEditMode: (state, { payload }) => ({
      ...state,
      isEditMode: payload,
      errorMessage: null,
    }),

    updateSection: (state, { payload: { fieldId, id } }) => ({
      ...state,
      data: state.data.map((form, idx) =>
        idx === 0
          ? {
            ...form,
            sections: {
              ...form.sections,
              byId:
                Object
                  .entries(form.sections.byId)
                  .reduce((acc, [key, value]) =>
                  ({
                    ...acc,
                    [key === fieldId ? id : key]: value
                  }), {})
            }
          }
          : form
      )
    }),

    setMandatory: (state, { payload: { fieldId, value } }) => ({
      ...state,
      data: state.data.map((form, idx) =>
        idx === 0
          ? {
            ...form,
            fields: {
              ...form.fields,
              byId: {
                ...form.fields.byId,
                [fieldId]: {
                  ...form.fields.byId[fieldId],
                  mandatory: value
                }
              }
            }
          }
          : form
      )
    }),

    addField: (state, { payload: { sectionId, typeOfField } }) => {
      const newId = uniqid('CF_')
      const newChoiceId = uniqid('CF_')

      return ({
        ...state,
        data: state.data.map((form, idx) =>
          idx === 0
            ? {
              ...form,
              sections: {
                ...form.sections,
                byId: {
                  ...form.sections.byId,
                  [sectionId]: {
                    ...form.sections.byId[sectionId],
                    fields: [
                      ...form.sections.byId[sectionId].fields,
                      newId
                    ],
                  }
                },
              },
              fields: {
                ...form.fields,
                byId: {
                  ...form.fields.byId,
                  [newId]: {
                    id: newId,
                    label: '',
                    typeOfField,
                    mandatory: false,
                    choices: typeOfField === 'text' || typeOfField === 'document'
                      ? []
                      : [newChoiceId],
                    isEditMode: true,
                  }
                },
              },
              choices: {
                ...form.choices,
                byId: (typeOfField === 'selectbox' || typeOfField === 'multicheckbox')
                  ? {
                    ...form.choices.byId,
                    [newChoiceId]: {
                      id: newChoiceId,
                      content: '',
                      isEditMode: true,
                    }
                  }
                  : { ...form.choices.byId },
              },
            }
            : form
        ),
        isBuilderFieldEdit: {
          ...state.isBuilderFieldEdit,
          [newId]: true
        }
      })
    },

    addChoice: (state, { payload: { fieldId } }) => {
      const newId = uniqid('CF_')

      return ({
        ...state,
        data: state.data.map((form, idx) =>
          idx === 0
            ? {
              ...form,
              fields: {
                ...form.fields,
                byId: {
                  ...form.fields.byId,
                  [fieldId]: {
                    ...form.fields.byId[fieldId],
                    choices: [
                      ...form.fields.byId[fieldId].choices,
                      newId
                    ]
                  }
                }
              },
              choices: {
                ...form.choices,
                byId: {
                  ...form.choices.byId,
                  [newId]: {
                    id: newId,
                    content: '',
                    isEditMode: false,
                  },
                },
              }
            }
            : form
        ),
        locked: true,
      })
    },

    removeChoice: (state, { payload: { fieldId, choiceId } }) => ({
      ...state,
      data: state.data.map((form, idx) =>
        idx === 0
          ? {
            ...form,
            fields: {
              ...form.fields,
              byId: {
                ...form.fields.byId,
                [fieldId]: {
                  ...form.fields.byId[fieldId],
                  choices: form.fields.byId[fieldId].choices
                    .filter(choice => choice !== choiceId)
                }
              }
            },
            choices: {
              ...form.choices,
              byId:
                Object
                  .entries(form.choices.byId)
                  .filter(([id, value]) => id !== String(choiceId))
                  .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {})
            }
          }
          : form
      ),
      locked: true,
    }),

    removeSection: (state, { payload: { sectionId } }) => ({
      ...state,
      data: state.data.map((form, idx) =>
        idx === 0
          ? {
            ...form,
            sections: {
              ...form.sections,
              byId: Object.entries(form.sections.byId)
                .filter(([field, _]) => field !== String(sectionId))
                .reduce((acc, [id, field]) => ({
                  ...acc,
                  [id]: field
                }), {}),
              allIds: form.sections.allIds
                .filter(id => sectionId !== id)
            },
          }
          : form
      ),
      isBuilderFieldEdit: Object
        .entries(state.isBuilderFieldEdit)
        .filter(([id, _]) => id !== String(sectionId))
        .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {})
    }),

    removeField: (state, { payload: { sectionId, fieldId } }) => ({
      ...state,
      data: state.data.map((form, idx) =>
        idx === 0
          ? {
            ...form,
            sections: {
              ...form.sections,
              byId: {
                ...form.sections.byId,
                [sectionId]: {
                  ...form.sections.byId[sectionId],
                  fields: form.sections.byId[sectionId].fields
                    .filter(field => field !== fieldId)
                }
              }
            },
            fields: {
              ...form.fields,
              byId:
                Object
                  .entries(form.fields.byId)
                  .filter(([id, value]) => id !== String(fieldId))
                  .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {})
            }
          }
          : form
      ),
      isBuilderFieldEdit: Object
        .entries(state.isBuilderFieldEdit)
        .filter(([id, _]) => id !== String(fieldId))
        .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {})
    }),

    setIsPatientSection: (state, { payload: { sectionId, value } }) => ({
      ...state,
      data: state.data.map((form, idx) =>
        idx === 0
          ? {
            ...form,
            sections: {
              ...form.sections,
              byId: {
                ...form.sections.byId,
                [sectionId]: {
                  ...form.sections.byId[sectionId],
                  isPatientSection: value
                }
              }
            },
          }
          : form
      )
    }),

    updateAllForms: (state, { payload }) => ({
      ...state,
    }),

    updateInitialValue: (state, { payload: { teleExpertiseId, fieldId, value, error = '' } }) => ({
      ...state,
      data: state.data.map(form =>
        form.id === teleExpertiseId
          ? {
            ...form,
            fields: {
              ...form.fields,
              byId: {
                ...form.fields.byId,
                [fieldId]: {
                  ...form.fields.byId[fieldId],
                  initialValue: value,
                  error,
                },
              }
            }
          }
          : form
      ),
      errorMessage: null
    }),

    updateCustomFieldValue: (state, { payload }) => ({
      ...state,
    }),

    setFormVisible: (state, { payload }) => ({
      ...state,
      formsVisible: {
        ...state.formsVisible, ...payload
      }
    }),

    clean: state => INITIAL_STATE,
    commonSaveField,
  }
})

export const {
  send,
  update,
  updateTextField,
  updateChoiceField,
  updateRadioField,
  updateFileField,
  receivedFormFields,
  receivedUpdatedFormFields,
  removePatientFormFields,
  receivedDocuments,
  success,
  fetch,
  addChoice,
  removeChoice,
  removeField,
  removeSection,
  apiError,
  invalidate,
  initViewerFieldEdit,
  resetViewerFieldEdit,
  setEditFields,
  setBuilderEditFields,
  initBuilderFieldEdit,
  closeAllBuilderFieldEdit,
  updateLabels,
  moveChoice,
  moveField,
  moveSection,
  addSection,
  addField,
  updateBuilderFieldLabel,
  setErrors,
  setMandatory,
  submitField,
  setBuilderErrors,
  updateSection,
  setEditMode,
  clearCfField,
  updateAllForms,
  clean,
  setIsPatientSection,
  setFormVisible,
  updateCustomFieldValue,
  restoreInitialValue,
  updateInitialValue,
  commonSaveField: saveField,
} = slice.actions

export const selectSections = state => state.customField.data[0].sections
export const selectFields = state => state.customField.data[0].fields
export const selectChoices = state => state.customField.data[0].choices
export const selectFormFields = state => state.customField.data
export const selectErrorMessage = state => state.customField.errorMessage
export const selectIsViewerFieldEdit = state => state.customField.isViewerFieldEdit
export const selectIsEditMode = state => state.customField.isEditMode
export const selectIsBuilderFieldEdit = state => state.customField.isBuilderFieldEdit
export const selectError = state => state.customField.error
export const selectSending = state => state.customField.sending
export const selectSuccess = state => state.customField.success
export const selectLocked = state => state.customField.locked
export const selectQuestionnaires = state => state.customField.questionnaires
export const selectFormsVisible = state => state.customField.formsVisible

export default slice.reducer
