export const en = {
  'staff': 'staff',
  'staffSession': 'staffSession',
  'avis': 'opinion',
  'cohorte': 'cohort',
}

export const fr = {
  'period': {
    day: 'Jour',
    week: 'Semaine',
    month: 'Mois',
    year: 'Année',
    days: 'Jours',
    weeks: 'Semaines',
    months: 'Mois',
    years: 'Années',
  },
  'recurrence': {
    none: 'aucune',
    daily: 'quotidienne',
    weekly: 'hebdomadaire',
    monthly: 'mensuelle',
    quarterly: 'trimestrielle',
    annual: 'annuelle'
  },
  'colors': {
    gray: 'gris',
    black: 'noir',
    green: 'vert',
    blue: 'bleu',
    purple: 'violet',
    orange: 'orange',
    red: 'rouge',
    yellow: 'jaune',
  },
  'staff': {
    a: 'un',
    new: 'nouveau',
    myExpertise: 'mon staff',
    newExpertise: 'Nouveau staff',
    expertise: 'staff',
    expertises: 'staffs',
    ofExpertises: 'de staff',
    allExpertises: 'Tous les staffs',
    theExpertise: 'le staff',
    thisExpertise: 'ce staff',
    createdExpertises: 'Staffs créés',
    joinedExpertises: 'Staffs rejoints',
    archivedExpertises: 'Staffs archivés',
  },
  'cohort': {
    a: 'une',
    new: 'nouvelle',
    myExpertise: 'ma cohorte',
    newExpertise: 'Nouvelle cohorte',
    expertise: 'cohorte',
    expertises: 'cohortes',
    ofExpertises: 'de cohorte',
    allExpertises: 'Toutes les cohortes',
    theExpertise: 'la cohorte',
    thisExpertise: 'cette cohorte',
    createdExpertises: 'Cohortes créées',
    joinedExpertises: 'Cohortes rejointes',
    archivedExpertises: 'Cohortes archivées',
  },
  'opinion': {
    a: 'un',
    new: 'nouvel',
    myExpertise: 'mon avis',
    newExpertise: 'Nouvel avis',
    expertise: 'avis',
    expertises: 'avis',
    ofExpertises: 'd\'avis',
    allExpertises: 'Tous les avis',
    theExpertise: 'l\'avis',
    thisExpertise: 'cet avis',
    createdExpertises: 'Avis créés',
    joinedExpertises: 'Avis rejoints',
    archivedExpertises: 'Avis archivés',
  },
  male: 'Homme',
  female: 'Femme',
  undetermined: 'Indéterminé',
}
