import styled from 'styled-components'

export const Icon = styled.i`
  display: inline-block;
  font-family: 'ShareConfrere';
  font-style: normal;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

export const ButtonIcon = styled.span`
  align-self: flex-${props => props.align};
  letter-spacing: 0;
  width: 2.5rem;
  height: 2.5rem;
  pointer-events: none;
  > i {
    color: ${props => props.active ? props.activeFgColor : props.fgColor};
    pointer-events: auto;
    padding: 0;
    margin: 0;
  }
  &:hover {
    box-shadow: none;
    /* color: ${props => props.theme.patientGreen}; */
  }
`

export const IconRound = styled(props => <Icon {...props} />)`
  border-radius: 50px;
`

export const IconH1 = styled(props => <IconRound {...props} />).attrs({ as: 'span' })`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.patientGreen};
  border: 0;
  font-size: 1.3rem;
  margin-right: 1rem;
  flex-shrink: 0;
  background: ${props => props.theme.patientGreen};
  border-radius: 50px;
  width: 3rem;
  height: 3rem;
`

export const IconArchive = styled(props => <Icon {...props} />)`
  &::after {
    content: '\\e900';
  }
`

export const IconArrowLeft = styled(props => <Icon {...props} />)`
  &::after {
    content: '\\e901';
  }
`

export const IconArrowRight = styled(props => <Icon {...props} />)`
  transform: rotate(180deg);
  &::after {
    content: '\\e901';
  }
`

export const IconAvis = styled(props => <Icon {...props} />)`
  &::after {
    content: '\\e903';
  }
`

export const IconBack = styled(props => <Icon {...props} />)`
  &::after {
    content: '\\e904';
  }
`

export const IconBell = styled(props => <Icon {...props} />)`
  &::after {
    content: '\\e905';
  }
`

export const IconBellOn = styled(props => <Icon {...props} />)`
  &::before {
    content: '';
      width: 8px;
      height: 8px;
      border-radius: 50%;
      position: absolute;
      right: 5px;
      box-sizing: content-box;
      background: ${props => props.theme.notification};
      z-index: 2;
      border: 2px solid #FFF;
  }
  &::after {
    content: '\\e905';
  }
`

export const IconBlocs = styled(props => <Icon {...props} />)`
  &::after {
    content: '\\e906';
  }
`

export const IconCalendar = styled(props => <Icon {...props} />)`
  &::after {
    content: '\\e907';
  }
`

export const IconChat = styled(props => <Icon {...props} />)`
  &::after {
    content: '\\e908';
  }
`

export const IconChatOn = styled(props => <Icon {...props} />)`
  &::before {
    content: '';
      width: 8px;
      height: 8px;
      border-radius: 50%;
      position: absolute;
      right: 5px;
      box-sizing: content-box;
      background: ${props => props.theme.notification};
      z-index: 2;
      border: 2px solid #FFF;
  }
  &::after {
    content: '\\e908';
  }
`

export const IconCheck = styled(props => <Icon {...props} />)`
  &::after {
    content: '\\e909';
  }
`

export const IconChoixUnique = styled(props => <Icon {...props} />)`
  &::after {
    content: '\\e90a';
  }
`

export const IconClose = styled(props => <Icon {...props} />)`
  &::after {
    content: '\\e90b';
  }
`

export const IconCohorte = styled(props => <Icon {...props} />)`
  &::after {
    content: '\\e90c';
  }
`

export const IconDate = styled(props => <Icon {...props} />)`
  &::after {
    content: '\\e90d';
  }
`

export const IconDemandeTraitee = styled(props => <Icon {...props} />)`
  &::after {
    content: '\\e90e';
  }
`

export const IconDemandeNew = styled(props => <Icon {...props} />)`
  &::after {
    content: '\\e90f';
  }
`

export const IconDoctor = styled(props => <Icon {...props} />)`
  &::after {
    content: '\\e910';
  }
`

export const IconDocument = styled(props => <Icon {...props} />)`
  &::after {
    content: '\\e911';
  }
`

export const IconDown = styled(props => <Icon {...props} />)`
  &::after {
    content: '\\e912';
  }
`

export const IconUp = styled(props => <Icon {...props} />)`
  transform: rotate(180deg);
  &::after {
    content: '\\e912';
  }
`

export const IconEdit = styled(props => <Icon {...props} />)`
  &::after {
    content: '\\e913';
  }
`

export const IconExport = styled(props => <Icon {...props} />)`
  &::after {
    content: '\\e914';
  }
`

export const IconUndo = styled(props => <Icon {...props} />)`
  transform: rotate(180deg);
  &::after {
    content: '\\e914';
  }
`

export const IconFilter = styled(props => <Icon {...props} />)`
  &::after {
    content: '\\e915';
  }
`

export const IconHospitalBuilding = styled(props => <Icon {...props} />)`
  &::after {
    content: '\\e916';
  }
`

export const IconInfos = styled(props => <Icon {...props} />)`
  &::after {
    content: '\\e917';
  }
`

export const IconJoin = styled(props => <Icon {...props} />)`
  &::after {
    content: '\\e918';
  }
`
export const IconLabel = styled(props => <Icon {...props} />)`
  &::after {
    content: '\\e91a';
  }
`

export const IconLignes = styled(props => <Icon {...props} />)`
  &::after {
    content: '\\e91b';
  }
`

export const IconManWoman = styled(props => <Icon {...props} />)`
  &::after {
    content: '\\e91c';
  }
`

export const IconMan = styled(props => <Icon {...props} />)`
  &::after {
    content: '\\e91d';
  }
`

export const IconMenu = styled(props => <Icon {...props} />)`
  &::after {
    content: '\\e91e';
  }
`

export const IconMessage = styled(props => <Icon {...props} />)`
  &::after {
    content: '\\e91f';
  }
`

export const IconMoveSplit = styled(props => <Icon {...props} />)`
  &::after {
    content: '\\e920';
  }
`

export const IconMove = styled(props => <Icon {...props} />)`
  &::after {
    content: '\\e921';
  }
`

export const IconNext = styled(props => <Icon {...props} />)`
  &::after {
    content: '\\e922';
  }
`

export const IconOk = styled(props => <Icon {...props} />)`
  &::after {
    content: '\\e923';
  }
`

export const IconOptions = styled(props => <Icon {...props} />)`
  position: relative;
  right: 0;
  padding-left: 1rem;
  &::after {
    content: '\\e924';
  }
`

export const IconPatientSup = styled(props => <Icon {...props} />)`
  &::after {
    content: '\\e925';
  }
`

export const IconPatient = styled(props => <Icon {...props} />)`
  &::after {
    content: '\\e926';
  }
`

export const IconPlus = styled(props => <Icon {...props} />)`
  &::after {
    content: '\\e927';
  }
`

export const IconMinus = styled(props => <Icon {...props} />)`
  font-size: 2.5rem;
  &::after {
    content: '-';
  }
`

export const IconProfileOn = styled(props => <Icon {...props} />)`
  &::before {
    content: '';
      width: 8px;
      height: 8px;
      border-radius: 50%;
      position: absolute;
      right: 5px;
      box-sizing: content-box;
      background: ${props => props.theme.notification};
      z-index: 2;
      border: 2px solid #FFF;
  }
  &::after {
    content: '\\e928';
  }
`

export const IconProfile = styled(props => <Icon {...props} />)`
  &::after {
    content: '\\e928';
  }
`

export const IconSave = styled(props => <Icon {...props} />)`
  &::after {
    content: '\\e929';
  }
`

export const IconSeance = styled(props => <Icon {...props} />)`
  &::after {
    content: '\\e92a';
  }
`

export const IconSearch = styled(props => <Icon {...props} />)`
  &::after {
    content: '\\e92b';
  }
`

export const IconShare = styled(props => <Icon {...props} />)`
  &::after {
    content: '\\e92c';
  }
`

export const IconText = styled(props => <Icon {...props} />)`
  &::after {
    content: '\\e92d';
  }
`

export const IconTrash = styled(props => <Icon {...props} />)`
  &::after {
    content: '\\e92e';
  }
`

export const IconTriangleForm = styled(props => <Icon {...props} />)`
  &::after {
    content: '\\e92f';
  }
`

export const IconTurOff = styled(props => <Icon {...props} />)`
  &::after {
    content: '\\e930';
  }
`

export const IconWait = styled(props => <Icon {...props} />)`
  &::after {
    content: '\\e932';
  }
`

export const IconWoman = styled(props => <Icon {...props} />)`
  &::after {
    content: '\\e933';
  }
`

export const IconKey = styled(props => <Icon {...props} />)`
  &::after {
    content: '\\e919';
  }
`

export const IconMandatory = styled(props => <Icon {...props} />)`
  &::after {
    content: '\\e934';
  }
`

export const IconParameters = styled(props => <Icon {...props} />)`
  &::after {
    content: '\\e935';
  }
`

export const IconEraser = styled(props => <Icon {...props} />)`
  &::after {
    content: '\\e936';
  }
`

export const IconLock = styled(props => <Icon {...props} />)`
  &::after {
    content: '\\eb55';
  }
`

export const IconUnlocked = styled(props => <Icon {...props} />)`
  &::after {
    content: '\\eb56';
  }
`

export const IconPlanet = styled(props => <Icon {...props} />)`
  &::after {
    content: '\\ec96';
  }
`

export const IconEye = styled(props => <Icon {...props} />)`
  &::after {
    content: '\\e9ce';
  }
`

export const IconMail = styled(props => <Icon {...props} />)`
  &::after {
    content: '\\e9df';
  }
`

export const IconPatientMinus = styled(props => <Icon {...props} />)`
  &::after {
    content: '\\e96e';
  }
`

export const IconPrint = styled(props => <Icon {...props} />)`
  &::after {
    content: '\\ea68';
  }
`

export const IconLegal = styled(props => <Icon {...props} />)`
  &::after {
    content: '\\e94a';
  }
`

export const IconCGU = styled(props => <Icon {...props} />)`
  &::after {
    content: '\\e955';
  }
`

export const IconCookies = styled(props => <Icon {...props} />)`
  &::after {
    content: '\\e9e2';
  }
`

export const IconPrivacy = styled(props => <Icon {...props} />)`
  &::after {
    content: '\\e9e1';
  }
`

export const IconCamera = styled(props => <Icon {...props} />)`
  &::after {
    content: '\\e9e7';
  }
`

export const IconPhoneIncoming = styled(props => <Icon {...props} />)`
  &::after {
    content: '\\e9e8';
  }
`

export const IconMedal = styled(props => <Icon {...props} />)`
  &::after {
    content: '\\e9e3';
  }
`

export const IconAddPatient = styled(props => <Icon {...props} />)`
  &::after {
    content: '\\e9e1';
  }
`

export const IconUncheck = styled.div`
  width: 1.5625rem;
  height: 1.5625rem;
  border-radius: 5px;
  flex: initial;
  border: 1px solid;
  border-color: ${props => props.theme.grey2};
`

export const IconUncheckRound = styled(props => <IconUncheck {...props} />)`
  border-radius: 50px;
`

export const IconPro = styled.i`
  display: flex;
  vertical-align: middle;
  border-radius: 4px;
  border: 1px solid ${props => props.theme.grey2};
  background: white;
  padding: .1rem.5rem;
  font-style: normal;
  &:before {
    content: 'Pro';
    font-weight: 600;
    font-size: .875rem;
  }
`


export const IconHelp = styled(props => <Icon {...props} />)`
  &::after {
    content: '\\e9e3';
  }
`

export const IconHelpNoBorder = styled.i`
  &::after {
    content: '?';
    font-size: 1.6rem;
  }
`

export const IconContactUs = styled(props => <Icon {...props} />)`
  &::after {
    content: '\\e9e9';
  }
`

export const IconDoctorCircle = styled(props => <IconDoctor {...props} />)`
  border: 1px solid;
  border-color:  ${props =>
    props.disabled
      ? props.theme.grey2
      : props.theme.patientGreen
  };
  padding: .3rem;
  border-radius: 50px;
  color: ${props => props.disabled
    ? props.theme.grey2
    : props.theme.patientGreen
  };
  margin-right: .5rem;
`

export const IconPatientCircle = styled(props => <IconPatientSup {...props} />)`
  border: 1px solid;
  border-color:  ${props =>
    props.disabled
      ? props.theme.grey2
      : props.theme.patientGreen
  };
  padding: .3rem;
  border-radius: 50px;
  color: ${props => props.disabled
    ? props.theme.grey2
    : props.theme.patientGreen
  };
  margin-right: .5rem;
`

export const MoveIcon = styled(props => <IconMoveSplit {...props} />)`
  display: ${props => props.hidden ? 'none' : 'block'};
`
