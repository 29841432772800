import React, { useState, useEffect } from 'react'
import { privateSitePrefix } from '../Util/Config'
import { useSelector, useDispatch } from 'react-redux';
import { useAlert } from 'react-alert'
import { apiUrl } from '../Util/Config'
import { fr } from '../Util/i18n';
import { uniqByIdAndType } from '../Util/Object'
import { useHistory } from "react-router-dom";
import * as Ui from './Ui'
import * as Organization from '../State/Organization'
import * as Resources from '../State/Resources';
import * as Styled from './Organization.styled'
import LoadingBox from './Ui/LoadingBox'

const OrganizationComponent = (props) => {
  const dispatch = useDispatch()
  const alert = useAlert()
  const history = useHistory()
  const { match: { params } } = props

  const currentUser = useSelector(state => state.login.currentUser)
  const [currentSection, setCurrentSection] = useState(1)
  const {
    [Resources.RESOURCE_MEMBERS]: members
  } = useSelector(Resources.selectResources)

  const loading = useSelector(Organization.selectLoading)
  const errored = useSelector(Organization.selectError)
  const errorMessage = useSelector(Organization.selectErrorMessage)
  const data = useSelector(Organization.selectData)
  const availableExpertises = useSelector(Organization.selectAvailableExpertises)

  const expertises = availableExpertises
    .filter(expertise =>
      data.expertises.value
        .some(exp => exp.type === expertise.type && Number(exp.id) === Number(expertise.id))
    ).filter(uniqByIdAndType)

  useEffect(() => {
    dispatch(Organization.fetch(params.id))
  }, [dispatch, params.id])

  useEffect(() => {
    if (errorMessage)
      alert.error(errorMessage, { timeout: 5000 })
  }, [alert, errorMessage])

  return (
    <>
      <LoadingBox />
      <Ui.Layout.PageLayout currentUser={currentUser}>
        <Styled.DetailsContainer>
          {!loading || errored
            ? <Ui.Layout.PageContentWrapper>
              <OrganizationSummary
                data={data}
                history={history}
              />
              <Ui.Layout.Content hasPanel noPadding>
                <Ui.Layout.NavContainer>
                  <nav>
                    <Ui.Layout.NavLink
                      onClick={() => setCurrentSection(1)}
                      on={currentSection === 1}
                    >
                      <Ui.Icon.IconInfos />
                      Informations générales
                    </Ui.Layout.NavLink>
                  </nav>
                </Ui.Layout.NavContainer>
                <Ui.Layout.PaddedContent>
                  <Summary
                    currentSection={currentSection}
                    data={data}
                    members={members}
                    expertises={expertises}
                    currentUser={currentUser}
                  />
                </Ui.Layout.PaddedContent>
              </Ui.Layout.Content>
            </Ui.Layout.PageContentWrapper>
            : <Styled.FullSpaceContainer><Ui.Global.Loader /></Styled.FullSpaceContainer>
          }
        </Styled.DetailsContainer>
      </Ui.Layout.PageLayout>
    </>
  )
}

const OrganizationSummary = ({
  data,
  history,
}) => {
  return (
    <Ui.Layout.PanelContainer>
      <Ui.Layout.Panel>
        <>
          <header>
            <Ui.Button.BackButton onClick={history.goBack} />
          </header>
          <Ui.BasicElement.H1>
            <Ui.Layout.FlexBox justifyContents="center">
              {data.logo.value
                ? <Styled.Logo
                  src={`${apiUrl}/organizations/logo/${data.logo.value}`}
                />
                : <Ui.SearchBar.CheckboxIcon isGreen>
                  <Ui.Icon.IconHospitalBuilding />
                </Ui.SearchBar.CheckboxIcon>
              }
              <Styled.OrganizationName>{data.name.value}</Styled.OrganizationName>
            </Ui.Layout.FlexBox>
          </Ui.BasicElement.H1>
        </>
      </Ui.Layout.Panel>
    </Ui.Layout.PanelContainer >
  )
}

const Summary = ({
  currentSection,
  data,
  members,
  expertises,
  currentUser,
}) => {
  const managersList = members?.filter(user => data.managers.value.includes(Number(user.id)))
  const membersList = members?.filter(user => data.members.value.includes(Number(user.id)))

  return (currentSection === 1) && (
    <>
      <Ui.Layout.Section>
        <Ui.BasicElement.H2>
          Administrateurs
        </Ui.BasicElement.H2>
        {managersList.length > 0
          ? <Ui.Layout.ColleagueCardsContainer>
            {members.length > 0 &&
              managersList.map((colleague, i) =>
                <Ui.Colleague.Colleague
                  key={`colleague_${i}`}
                  to={`${privateSitePrefix}/medecins/${colleague.id}`}
                  colleague={colleague}
                  currentUser={currentUser}
                />
              )
            }
          </Ui.Layout.ColleagueCardsContainer>
          : <Ui.BasicElement.P italic>Aucun administrateur n'est associé à cette organisation</Ui.BasicElement.P>
        }
      </Ui.Layout.Section>
      <Ui.Layout.Section>
        <Ui.BasicElement.H2>
          Médecins
        </Ui.BasicElement.H2>
        {membersList.length > 0
          ? <Ui.Layout.ColleagueCardsContainer>
            {membersList.map((colleague, i) =>
              <Ui.Colleague.Colleague
                key={`colleague_${i}`}
                to={`${privateSitePrefix}/medecins/${colleague.id}`}
                colleague={colleague}
                currentUser={currentUser}
              />
            )
            }
          </Ui.Layout.ColleagueCardsContainer>
          : <Ui.BasicElement.P italic>Aucun médecin n'est associé à cette organisation</Ui.BasicElement.P>
        }
      </Ui.Layout.Section>
      <Ui.Layout.Section>
        <Ui.BasicElement.H2>
          Téléexpertises associées à l'organisation
        </Ui.BasicElement.H2>
        {expertises.length > 0
          ? <Ui.Layout.FlexBox flow="row wrap">
            {expertises
              .map((teleExpertise, index) =>
                <Styled.ButtonLargeLink
                  key={index}
                >
                  <Ui.Form.ButtonLarge
                    to={`${privateSitePrefix}/${fr[teleExpertise.type === 'staffs'
                      ? 'staff'
                      : teleExpertise.type === 'opinions'
                        ? 'opinion'
                        : 'cohort'].expertises}/${teleExpertise.id}`}
                    key={index}
                    value={teleExpertise.name}
                    subLabel={teleExpertise.ownerFullname}
                    label={teleExpertise.name}
                    badge={!teleExpertise.patientLimitLocked}
                  >
                    <Ui.SearchBar.DefaultCheckboxIcon
                      isPurple={teleExpertise.type === 'staffs'}
                      isRed={teleExpertise.type === 'cohorts'}
                      isBlue={teleExpertise.type === 'opinions'}
                      value={teleExpertise}
                    />
                  </Ui.Form.ButtonLarge>
                </Styled.ButtonLargeLink>
              )}
          </Ui.Layout.FlexBox>
          : <Ui.BasicElement.P italic>
            Aucune téléexpertise n'est associée à cette organisation
          </Ui.BasicElement.P>
        }
      </Ui.Layout.Section>
    </>
  )
}

export default OrganizationComponent;
