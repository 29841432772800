import React from 'react';
import styled from 'styled-components/macro'
import * as Ui from '../Ui'
import { apiUrl } from '../../Util/Config'

const PatientInformationContentComponent = ({ isLoggedIn }) => {

  return (
    <CguContentContainer isLoggedIn={isLoggedIn}>
        <DownloadPatientInformation rel="noopener noreferrer" download href={`${apiUrl}/users/download-patient-information`}>
            <Ui.Button.BasicButton
                isGreen
            >
                <Ui.Icon.IconDocument />
                Enregistrer la notice au format PDF
            </Ui.Button.BasicButton>
        </DownloadPatientInformation>
        <Ui.BasicElement.P>Le professionnel de santé ou l’établissement de santé ______________________________ est
        amené à recueillir et à conserver dans votre dossier patient des informations sur votre état de
        santé.</Ui.BasicElement.P>
        <Ui.BasicElement.P>La tenue de ce dossier est nécessaire et a pour finalité d’assurer votre suivi
        médical et de garantir la prise en charge la plus adaptée à votre état de santé. Il garantit la
        continuité de la prise en charge sanitaire et répond à l’exigence de délivrer des soins
        appropriés.</Ui.BasicElement.P>
        <Ui.BasicElement.P>Afin de faciliter et d’améliorer votre prise en charge médical, votre dossier est
        enregistré sur la plateforme sécurisée Skemeet, utilisée uniquement par des professionnels
        de santé dont l’identité est vérifiée. </Ui.BasicElement.P>
        <Ui.BasicElement.P>Votre dossier est conservé en principe pendant 20 ans à compter de la date de votre
        dernière consultation, par référence aux dispositions de l’article R. 1112-7 du code de la santé
        publique applicables aux établissements de santé.</Ui.BasicElement.P>
        <Ui.BasicElement.P>La plateforme Skemeet a recours à un Hébergeur certifié de Données de Santé (OVH Cloud – 2 rue Kellermann 59100 Roubaix 1007).</Ui.BasicElement.P>
        <Ui.BasicElement.P>Seuls ont accès aux informations figurant dans votre dossier des professionnels
        de santé et, dans une certaine mesure, au regard de la nature des missions qu’il exerce,
        leur personnel. Le professionnel de santé en charge de votre suivi pourra également, après
        vous en avoir informé, transmettre à d’autres professionnels de santé des informations
        concernant votre état de santé. </Ui.BasicElement.P>
        <Ui.BasicElement.P>Vous avez le droit d’accéder aux informations figurant dans votre dossier. Vous
        disposez, par ailleurs, sous certaines conditions, d’un droit de rectification, d’effacement
        de ces informations, ou du droit de vous opposer ou de limiter leur utilisation.</Ui.BasicElement.P>
        <Ui.BasicElement.P>Pour exercer vos droits ou pour toute question relative aux traitements de vos
        données par la plateforme Skemeet, vous pouvez contacter le professionnel de santé
        assurant votre prise en charge ou la plateforme Skemeet directement à l’adresse
        électronique suivante : <a href="mailto:dpo@skezi.eu">dpo@skezi.eu</a> ou à l’adresse postale suivante :
        DPO – SKEZI, Les Papeteries, 1 Esplanade Augustin Aussedat, Cran Gevrier, 74960 Annecy.</Ui.BasicElement.P>
        <Ui.BasicElement.P>En cas de difficultés, vous pouvez également saisir la CNIL d’une
        réclamation.</Ui.BasicElement.P>
        </CguContentContainer>
  )
}

const CguContentContainer = styled(props => <Ui.PortailLayout.PageContainer {...props} />)`
  @media only screen and (min-width: ${props => props.theme.device.desktop}) {
    margin: 0;
    margin-top: ${props => props.isLoggedIn ? '0' : '1rem'};
  }
  a {
    color: ${props => props.theme.patientGreen};
  }

  ${Ui.Form.InputContainer}:last-of-type {
    margin-bottom: .2rem;
  }

  ${Ui.BasicElement.H1} {
    margin-top: 3rem;
  }

  ${Ui.BasicElement.H2} {
    margin-top: 2rem;
    font-size: 1rem;
  }

  ${Ui.BasicElement.P} {
    margin: .5rem 0;
  }

  ${Ui.BasicElement.Link} {
    font-size: .875rem;
    font-weight: 700;
  }
`

const DownloadPatientInformation = styled.a`
  margin-bottom: 2rem;
  width: min-content;
`

export default PatientInformationContentComponent;
