import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'
import * as Ui from '../Component/Ui'
import { Message } from '../Util/Message'

export const RecentTeleExpertises = styled.div`
  margin: 1rem 0;
  ${Ui.Form.CheckboxLargeContainer} {
    margin-right: 0;
    max-width: none;
  }
  ${Ui.Form.Labels} {
    width: 10.6rem;
  }
`
export const PatientListContainer = styled.div`
  ${Ui.SearchBar.SearchBarContainer} {
    margin-bottom: 0;
    width: 100%;
  }

  ${Ui.Search.FiltersAndSorting}:first-child {
    margin-bottom: 1rem;
  }
  ${Message} {
    margin-top: 1rem;
  }
`

export const UploadPractitionerProofLink = styled(props => <Link {...props} />)`
  color: white;
`
