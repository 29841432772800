import { createSlice } from '@reduxjs/toolkit'

export const INITIAL_STATE = {
  errorMessage: null,
  pending: false,
  error: false,
  success: false,
  validationLoading: false,
}

const slice = createSlice({
  name: 'accountValidation',
  initialState: INITIAL_STATE,
  reducers: {
    validateAccount: (state) => ({
      ...state,
      validationLoading: true
    }),

    success: (state) => ({
      ...state,
      success: true,
    }),

    clean: state => INITIAL_STATE,

    apiError: (state, action) => ({
      ...state,
      errorMessage: action.payload,
      pending: false,
      error: true,
    }),
  }
})

export const {
  validateAccount,
  success,
  clean,
  apiError,
} = slice.actions

export const selectErrorMessage = state => state.accountValidation.errorMessage
export const selectValidationLoading = state => state.accountValidation.validationLoading
export const selectSuccess = state => state.accountValidation.success

export default slice.reducer
