import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { useAlert } from 'react-alert'
import { apiUrl, expiredEstablishmentsPortalPro, defaultBannerBackground } from '../Util/Config'
import * as Ui from './Ui'
import uniqid from 'uniqid'
import styled from 'styled-components/macro'
import * as Login from '../State/Login'
import * as PortalPro from '../State/PortalPro'

const PortalProHomeComponent = ({ selectedExpertise = {}, ...props }) => {
  const alert = useAlert()
  const errorMessage = useSelector(PortalPro.selectErrorMessage)
  const dispatch = useDispatch()
  const { match: { params } } = props

  const { href, host } = window.location
  const subdomain = host.split('.')[0]

  const expiredPortalProUrl = expiredEstablishmentsPortalPro[href] ? true : false
  const portalProHeaderImage = useSelector(PortalPro.selectPicture)
  const bannerAlign = useSelector(PortalPro.selectPictureAlign)
  const bannerStretch = useSelector(PortalPro.selectPictureStretch)
  const bannerBackground = portalProHeaderImage ? `${apiUrl}/public/portal-pro/picture/${portalProHeaderImage}` : null
  const portalProLogo = useSelector(PortalPro.selectLogo)
  const portalProLogoUrl = portalProLogo ? `${apiUrl}/public/portal-pro/logo/${portalProLogo}` : null
  const teleExpertises = useSelector(PortalPro.selectTeleExpertise)
  const description = useSelector(PortalPro.selectDescription)
  const loading = useSelector(PortalPro.selectLoading)
  const query = useSelector(PortalPro.selectSearch)
  const teleExpertiseOpened = useSelector(PortalPro.selectTeleExpertiseOpened)

  useEffect(() => {
    if (!expiredPortalProUrl) {
      dispatch(PortalPro.fetch(!Number.isNaN(params.portalProHost) ? subdomain : params.portalProHost))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, params.portalProHost])

  useEffect(() => {
    if (errorMessage)
      alert.error(errorMessage, { timeout: 5000 })
  }, [alert, errorMessage])

  return (
    <>
      {expiredPortalProUrl
        ? <PageWrapper>
          <Ui.PortailLayout.PageLayout fullWidth>
            <ExpiredPortalProUrlContainer>
              <Ui.BasicElement.H1>Désolé, cette adresse n'est plus valide</Ui.BasicElement.H1>
              <div>{expiredEstablishmentsPortalPro[href].message}<a href={expiredEstablishmentsPortalPro[href].newUrl}>{expiredEstablishmentsPortalPro[href].newUrl}</a></div>
            </ExpiredPortalProUrlContainer>
          </Ui.PortailLayout.PageLayout>
        </PageWrapper>
        : <PortalProTeleExpertiseContainer>
          <Ui.PortailHeader />
          <Ui.PortailLayout.PortalMain $loading={loading}>
            <Ui.PortailLayout.PortalContainer>
              <Ui.PortailLayout.PortalContentContainer>
                <Ui.PortailLayout.FlexPage>
                  <Ui.PortailLayout.Content>
                    <Ui.PortailLayout.Banner
                      isPortalBanner
                      backgroundSrc={bannerBackground || defaultBannerBackground}
                      isStretch={bannerStretch || !bannerBackground}
                      alignment={bannerAlign || !bannerBackground}
                    />
                    <PortalBody>
                      <Ui.HomeSideBar
                        selectedExpertise={selectedExpertise}
                        fromPortalProHome
                        teleExpertiseOpened={teleExpertiseOpened}
                        portalProLogo={portalProLogo}
                        portalProPictureUrl={portalProLogoUrl}
                        portalProPictureLoading={loading}
                        homeType={PortalPro}
                        description={description}
                      />
                      <TeleExpertiseListContainer>
                        <Ui.Layout.FlexBox alignItems={!loading && teleExpertises.length > 0 ? 'flex-start' : 'center'}>
                          {loading
                            ? <Ui.Global.Loader />
                            : <TeleExpertiseListBlock query={query} />
                          }
                        </Ui.Layout.FlexBox>
                      </TeleExpertiseListContainer>
                    </PortalBody>
                  </Ui.PortailLayout.Content>
                </Ui.PortailLayout.FlexPage>
              </Ui.PortailLayout.PortalContentContainer>
              <Ui.PortailLayout.Footer noLogo />
            </Ui.PortailLayout.PortalContainer>
          </Ui.PortailLayout.PortalMain>
        </PortalProTeleExpertiseContainer>
      }
    </>
  )
}
const PortalProTeleExpertiseContainer = styled(props => <Ui.PortailLayout.Wrapper {...props} />)`
  ${Ui.PortailLayout.PortalContainer} {
    background-color: white;
  }
  ${Ui.PortailLayout.PortalSideBar} {
    position: relative;
    overflow: initial;
    padding-top: 0;
    height: 100%;
  }
  ${Ui.PortailLayout.Content} {
    @media only screen and (min-width: ${props => props.theme.device.desktop}) {
      margin-left: 0;
    }
  }
`

const PageWrapper = styled.div`
  height: 100vh;
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  ${Ui.PortailLayout.FlexPage} {
    @media only screen and (min-width: ${props => props.theme.device.desktop}) {
      flex: 1;
      align-items: center;
      ${Ui.PortailLayout.Content} {
        margin-left: 0;
      }
    }
  }
`

const PortalBody = styled(props => <Ui.Layout.FlexBox {...props} />)`
  flex-direction: column;
  @media only screen and (min-width: ${props => props.theme.device.desktop}) {
    flex-direction: row;
  }
`

const ExpiredPortalProUrlContainer = styled(props => <Ui.PortailLayout.PageContainer {...props} />)`
  margin: 1rem;
  margin-top: 2rem;
  flex: 1;
  text-align: center;
  a {
    color: ${props => props.theme.patientGreen};
  }
  @media only screen and (min-width: ${props => props.theme.device.desktop}) {
    margin-top: 3rem;
  }
  ${Ui.BasicElement.H1} {
    margin-bottom: 1rem;
  }

  ${Ui.BasicElement.Link} {
    font-size: .875rem;
    font-weight: 700;
  }
`

const TeleExpertiseListBlock = ({ query }) => {
  const dispatch = useDispatch()
  const teleExpertiseOpened = useSelector(PortalPro.selectTeleExpertiseOpened)
  const teleExpertises = useSelector(PortalPro.selectTeleExpertise)
  const portalProHost = useSelector(PortalPro.selectPortalProHost)
  const portalProId = useSelector(PortalPro.selectPortalProId)
  const portalProLogo = useSelector(PortalPro.selectLogo)
  const portalProPicture = useSelector(PortalPro.selectPicture)
  const portalProTitle = useSelector(PortalPro.selectTitle)

  return (
    <>
      <Ui.Search.CardContainer key={`card_line_n${uniqid()}`}>
        {
          teleExpertises.length === 0 && !query
            ? <NoResultText>Aucune téléexpertise n'a été trouvée.</NoResultText>
            : null
        }
        {teleExpertises.map(teleExpertise =>
          <Ui.Card.TelexpertiseCard
            key={`tele_expertise_card_${uniqid()}`}
            showDropdown={false}
            showBottomLink={true}
            teleExpertise={teleExpertise}
            nextSessionStartDate={teleExpertise?.nextSessionDate}
            teleExpertiseType={teleExpertiseOpened || teleExpertise.type}
            ownerSpeciality={teleExpertiseOpened === PortalPro.OPINION && teleExpertise.thePersonal ? teleExpertise.ownerSpeciality : null}
            changeHost={true}
            onSelectTeleExpertise={() => dispatch(Login.selectTeleExpertise({
              teleExpertiseId: teleExpertise.id,
              teleExpertiseName: teleExpertise.name,
              teleExpertiseDescription: teleExpertise.description,
              teleExpertiseType: teleExpertiseOpened,
              teleExpertisePicture: teleExpertise.pictureFileName,
              teleExpertiseTags: teleExpertise.tags,
              teleExpertiseDisciplines: teleExpertise.disciplines,
              teleExpertiseOwner: teleExpertise.ownerFullname,
              teleExpertiseOwnerSpeciality: teleExpertise.ownerSpeciality,
              teleExpertiseOwnerCity: teleExpertise.ownerCity,
              thePersonal: teleExpertise.thePersonal,
            }))}
            onSelectVisioconference={() => dispatch(Login.selectVisioconference({
              teleExpertiseId: teleExpertise.id,
              teleExpertiseName: teleExpertise.name,
              teleExpertiseType: teleExpertiseOpened,
            }))}
            to={`${process.env.REACT_APP_AUTH_URL}/connexion?portalId=${portalProId}&portalHost=${portalProHost}&profilePicture=${teleExpertise.pictureFileName || (Number(teleExpertise.thePersonal) && teleExpertise.ownerProfilePicture) || ''}&tags=${teleExpertise.tags}&disciplines=${teleExpertise.disciplines}&owner=${teleExpertise.ownerFullname}&ownerSpeciality=${teleExpertise.ownerSpeciality}&ownerCity=${teleExpertise.ownerCity}&thePersonal=${teleExpertise.thePersonal ? '1' : '0'}&portalLogo=${portalProLogo}&portalPicture=${portalProPicture}&portalTitle=${portalProTitle.replace('&', '')}&teleExpertiseId=${teleExpertise.id}&teleExpertiseDescription=${teleExpertise.description ? teleExpertise.description.replace('&', '') : ''}&teleExpertiseName=${teleExpertise.name.replace('&', '')}&teleExpertiseType=${teleExpertiseOpened || teleExpertise.type}`}
            isHome
            {...teleExpertise}
          />
        )}
      </Ui.Search.CardContainer>
    </>
  )
}

const NoResultText = styled(props => <Ui.Global.BlueText {...props} />)`
  display: block;
  font-size: 1rem;
  margin: 0 auto;
`

const TeleExpertiseListContainer = styled.div`
  padding-top: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  flex: 1;
  flex-direction: column;
  ${Ui.SearchBar.SearchBarContainer} {
    flex: 1;
  }
  ${Ui.Layout.FlexBox} {
    flex: 1;
  }
  ${Ui.Card.Header} {
    flex: initial;
  }
  ${Ui.Search.FilterContainer} {
    width: 100%;
    ${Ui.SearchBar.Input} {
      border-color: ${props => props.theme.primary};
      box-shadow: 0 2px 10px 1px rgba(134,174,197,0.4);
    }
    @media only screen and (max-width: ${props => props.theme.device.tablet}) {
      display: block;
    }
  }
  @media only screen and (min-width: ${props => props.theme.device.desktop}) {
    padding-left: 4rem;
    padding-right: 4rem;
  }
`

export default PortalProHomeComponent;
