import styled from 'styled-components/macro'
import * as Ui from '../Component/Ui'
import Editable from '../Util/Editable'

export const NavLinkNoLink = styled(props => <Ui.Layout.NavLink {...props} />)``

export const SexIconList = styled.div`
  ${Ui.SearchBar.PlainRoundIcon} {
    cursor: pointer;
    pointer-events: auto;
    width: 2rem;
    height: 2rem;
    padding: 0;
  }
`

export const SexIconListContainer = styled(props => <Ui.Layout.FlexBox {...props} />)`
  align-items: center;
  font-size: .875rem;
  margin-bottom: 1rem;
`

export const PanelContainer = styled(props => <Ui.Layout.PanelContainer {...props} />)`
  ${Editable.ViewBlock} {
    margin-bottom: 0;
  }
  ${Ui.Layout.Panel} {
    flex: initial;
  }
  ${Ui.BasicElement.H1Container} {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 2rem;
  }
`

export const EditableNameInput = styled(props => <Ui.Form.TextInput {...props} />)`
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  margin: 0;
  word-break: normal;
  overflow-wrap: anywhere;
  font-size: 1.5rem;
  color: black;
  hyphens: auto;
  font-weight: bold;
  &:focus-visible {
    outline: none;
  }
  &:focus {
    border-color: ${props => props.theme.mediumGrey};
  }
`

export const LeftPanelHeader = styled(props => <Ui.BasicElement.H1Container {...props} />)`
  ${Ui.Form.InputContainer} {
    padding-top: 0;
  }
  ${Ui.Form.Input} {
    margin-top: 0;
  }
`

export const EditableDateInput = styled(props => <Ui.Form.TextInput {...props} />)`
  input {
    font-size: .875rem;
    line-height: 2rem;
  }
`

export const CommentSelect = styled.div`

  ${Ui.Select.SelectContainer} {
    width: 18rem;
  }
`

export const PatientContainer = styled.div`
  nav {
   & > :last-child {
     span {
       margin-right: 0;
      }
    }
    @media only screen and (min-width: ${props => props.theme.device.desktop}) {
      ${Ui.Layout.NavLinkItem} {
        margin-right: 1.7rem;
      }
      justify-content: flex-start;
    }
  }

  ${NavLinkNoLink} {
    cursor: auto;
    &:hover {
      color: ${props => props.theme.patientGreen};
    }
  }

  ${Ui.BasicElement.H1} {
    font-size: 1.5rem;
    color: black;
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
  }
  ${Ui.Layout.Content} {
    @media only screen and (min-width: ${props => props.theme.device.desktop}) {
      & > :nth-child(2) {
        margin-top: 5rem;
      }
      & > :nth-child(3) {
        margin-top: ${props => props.isPatientCreated ? '12rem' : '8rem'};
      }
    }
    @media only screen and (max-width: ${props => props.theme.device.desktop}) {
      padding: 0;
    }
    ${Ui.Layout.ButtonGroup} {
      margin-top: .7rem;
    }
    ${Ui.Form.Input} {
      &:read-only {
        border-color: transparent;
        opacity: 1;
      }
    }
  }
  ${Ui.Layout.ColleagueCardsContainer} {
    ${Ui.Card.BottomLink} {
      color: white;
    }
  }
`

export const ExtraInformationsContainer = styled.div`
 ${Ui.BasicElement.H2} {
   margin-bottom: 1rem;
 }
 ${Ui.BasicElement.P} {
   border-bottom: 0;
   min-height: .2rem;
 }
 ${Ui.Tag.TagsContainer} {
   margin-bottom: 1rem;
 }
`

export const IdentitySectionContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  p {
    margin: 0;
    line-height: 1.2rem;
  }
  ${Ui.SearchBar.PlainRoundIcon} {
    &:first-child {
      pointer-events: auto;
      margin-left: 0;
    }
    margin-right: 1rem;
    margin-left: 0;
    & + span {
      padding-left: 0;
    }
  }
  ${Ui.Card.TelexpertiseContainer} {
    margin: .5rem 0;
  }
  @media only screen and (max-width: ${props => props.theme.device.desktop}) {
    display: none;
  }
`

export const IdentityEditableSectionContainer = styled(props => <IdentitySectionContainer {...props} />)`
  margin-top: 0;
  ${Ui.SearchBar.PlainRoundIcon} {
    cursor: pointer;
  }
`

export const CommentSection = styled.div`
  ${Ui.Form.TextArea} {
    box-sizing: border-box;
    margin-bottom: .5rem;
  }
`

export const CardsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  ${Ui.Card.BottomLink} {
    color: white;
  }
  @media only screen and (max-width: ${props => props.theme.device.desktop}) {
    flex-direction: column;
  }
`

export const CardsSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 32% 0;
`

export const CardsSectionTitle = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: .8rem;
  font-size: 0.875rem;
  & p {
    color: ${props => props.theme.darkBlue};
    margin: 0;
    font-weight: bold;
  }
  & p:first-child {
    color: ${props => props.theme.grey};
    line-height: 1.3rem;
    font-weight: normal;
  }
`

export const CardsSectionIcon = styled(props => <Ui.Icon.ButtonIcon {...props} />)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: ${props => props.theme.lightGrey};
  border: 0;
  border-radius: 50px;
  width: 2.5rem;
  height: 2.5rem;
  margin-left: .5rem;
  flex-shrink: 0;
  color: white;
  cursor: auto;
  + span {
    padding-left: 1rem;
  }
  font-size: 1.4rem;
  background: ${props => props.expertise === 'staffs'
    ? props.theme.staffPurple
    : props.expertise === 'opinions'
      ? props.theme.avisBlue
      : props.theme.cohorteRed
  };
  &:hover {
    color: white;
  }
`

export const CardsSectionHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 0;
  margin-bottom: 1.2rem;
`

export const CardsSectionBody = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  ${Ui.Card.CardContainer} {
    margin-top: 0;
    width: 100%;
    padding: 0;
  };
  ${Ui.Card.Card} {
    margin-bottom: 1rem;
    box-shadow: 0 2px 5px 1px rgba(134, 174, 197, 0.24);
  };
`

export const CardsSearchButton = styled.div`
  display: flex;
  box-sizing: border-box;
  background-color : #E2EBF0;
  border: 1px dashed ${props => props.theme.grey};
  height: 2.5rem;
  font-size: 0.75rem;
  align-items: center;
  padding: 0 1rem;
  color: ${props => props.theme.grey};
  justify-content: center;
  flex: initial;
  margin-bottom: 1rem;
  font-weight: bold;
  display: ${props => props.visible ? 'flex' : 'none'};
  cursor: pointer;
  span {
    flex: 1;
  };
  &:hover {
    color: ${props => props.theme.patientGreen};
    border-color: ${props => props.theme.patientGreen};
  }
`

export const SearchTeleExpertiseBarContainer = styled.div`
  display: ${props => props.open ? 'block' : 'none'};
  position: relative;
  ${Ui.Form.DropdownItemLabel} {
    max-width: none;
    white-space: normal;
    overflow: auto;
  };
  ${Ui.Form.DropdownItemSubLabel} {
    white-space: normal;
  };
  ${Ui.SearchBar.SearchBarContainer} {
    z-index: 4;
    margin-bottom: 0;
  };
 p {
      text-align: center;
    }
`

export const ToggleSearchBar = styled.div`
  height: 3.8rem;
`

export const TeleExpertiseLimitPatientReachedText = styled.div`
  text-align: center;
  font-weight: bold;
`

export const AnswerButton = styled(props => <Ui.Button.BasicButton {...props} />)`
  margin-left: 2rem;
  margin-right: 2rem;
  margin-top: 1.5rem;
  &:last-of-type {
    margin-top: 1rem;
    margin-bottom: 1.5rem;
  }
`

export const NavbarButton = styled(props => <Ui.Button.BasicButton {...props} />)`
  align-self: center;
  margin-right: 1rem;
  @media only screen and (max-width: ${props => props.theme.device.desktop}) {
    margin-bottom: 1rem;
    margin-top: .5rem;
  }
`

export const MandatoryCustomFieldMessageContainer = styled(props => <Ui.Layout.FlexBox {...props} />)`
  margin-bottom: 1rem;
  font-size: .875rem;
  background-color: ${props => props.theme.darkBlue};
  box-sizing: border-box;
  display: block;
  padding: 1.1rem;
  color: white;
  line-height: 1.2rem;
  padding-left: 2.4rem;
  position: fixed;
  left: calc(4rem + 359px);
  right: 0;
  flex: 1;
  justify-content: flex-start;
  z-index: 51;
  right: 0;
  width: auto;
  a {
    color: white;
  }
  > ${Ui.Layout.FlexBox} {
    align-items: center;
    > div {
      flex: 1;
    }
  }
  ${Ui.Icon.IconOk} {
    margin-left: 1rem;
    font-size: 1rem;
  }
  @media only screen and (min-width: ${props => props.theme.device.desktop}) {
    margin-top: 3.8rem !important;
  }
`

export const WarningLink = styled.span`
  text-decoration: underline;
  cursor: pointer;
  margin-left: .5rem;
`

export const AddPatientSuccessMessage = styled(props => <Ui.Layout.FlexBox {...props} />)`
  > span {
    flex: 1;
  }
`

export const MediasContainer = styled(props => <Ui.Layout.FlexBox {...props} />)`
  margin-bottom: .5rem;
  > ${Ui.Layout.FlexBox} {
    width: auto;
  }
`

export const DropzoneSection = styled.div`
  flex: 1;
  margin-bottom: 1rem;
  margin-top: 0;
  &:first-child {
    margin-right: .5rem;
  }

  > div {
    box-sizing: border-box;
    cursor: pointer;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    width: 6.25rem;
    height: 6.25rem;
    background-color: #ECF2F6;
    color: ${props => props.theme.blueGrey};
    outline: none;
    transition: border .24s ease-in-out;
    border: 2px dashed ${props => props.theme.grey};
    &:hover {
      cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
    }
    > p {
      padding: 0 1rem;
      margin: 0;
    }
  }
`
