import * as Invitation from '../State/Invitation'
import * as Staff from '../State/Staff'
import * as Opinion from '../State/Opinion'
import * as Cohort from '../State/Cohort'
import * as Patient from '../State/Patient'
import * as Resources from '../State/Resources'
import * as Eff from 'redux-saga/effects'
import * as Form from '../Util/Form'
import * as Api from '../Util/Api'


export function* sendInvitation({ payload: { type, teleExpertiseId, patientId, sentFromPatient } }) {
  try {
    const data = yield Eff.select(Invitation.selectInvitationData)
    const validatedData = yield Eff.call(Form.validate, data)

    const staffs = teleExpertiseId
      ? [teleExpertiseId]
      : validatedData
        .expertises
        .value
        .filter(e => e.type === 'staffs')
        .map(e => Number(e.id))

    const cohorts = teleExpertiseId
      ? [teleExpertiseId]
      : validatedData
        .expertises
        .value
        .filter(e => e.type === 'cohorts')
        .map(e => Number(e.id))


    const opinions = teleExpertiseId
      ? [teleExpertiseId]
      : validatedData
        .expertises
        .value
        .filter(e => e.type === 'opinions')
        .map(e => Number(e.id))

    const patients = patientId ? [patientId] : [];

    const teleExpertisesIds = type === 'staff'
      ? { staffs: [teleExpertiseId] }
      : type === 'cohort'
        ? { cohorts: [teleExpertiseId] }
        : type === 'opinion'
          ? { opinions: [teleExpertiseId] }
          : type === 'patient'
            ? { staffs: [], cohorts: [], opinions: [] }
            : {
              staffs,
              cohorts,
              opinions,
            }

    const { usersAlreadyExistDatas: registeredUsers } = yield Eff.call(Api.sendInvitation,
      validatedData.email.value,
      validatedData.message.value,
      patients,
      validatedData.recipients.value,
      teleExpertisesIds,
      sentFromPatient,
    )

    for (let user of registeredUsers) {
      yield Eff.put(Resources.received({
        name: [sentFromPatient
          ? Resources.RESOURCE_OPINIONS
          : Resources.RESOURCE_MEMBERS],
        data: [user],
      }))
      
      if (type === 'staff') {
        yield Eff.put(Staff.addColleague({ id: user.id, teleExpertiseId, noEffect: true }))
      } else if (type === 'opinion') {
        yield Eff.put(Opinion.addColleague({ id: user.id, teleExpertiseId, noEffect: true }))
      } else if (type === 'cohort') {
        yield Eff.put(Cohort.addColleague({ id: user.id, teleExpertiseId, noEffect: true }))
      } else if (type === 'patient') {
        yield Eff.put(Patient.addDefaultOpinion({ id: user.id, noEffect: true }))
      }
    }

    yield Eff.put(Invitation.success())
    yield Eff.put(Invitation.setIsInviting(false))
  } catch (error) {
    if (error instanceof Form.ValidationError) {
      for (let [name, field] of Object.entries(error.data)) {
        yield Eff.put(Invitation.updateField({
          name,
          value: field.value,
          errors: field.errors,
        }))
      }
      yield Eff.put(Invitation.invalidate(
        'Une information obligatoire est manquante.'
      ))
    } else {
      yield Eff.put(Invitation.apiError(error.message))
    }
  }
}

const InvitationSagas = function* () {
  yield Eff.takeLatest(`${Invitation.sendInvitation}`, sendInvitation)
}

export default InvitationSagas;
