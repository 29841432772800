import styled from 'styled-components/macro'
import * as Ui from '../Component/Ui'

export const LabelButtonContainer = styled.div`
  display: flex;
  margin-top: 1rem;
`

export const LeftPanelContainer = styled(props => <Ui.Layout.PanelContainer {...props} />)`
  ${Ui.BasicElement.ExpertisePicture}, ${Ui.BasicElement.AvatarPicture} {
    margin-top: 1.5rem;
  }
`

export const TagsContainer = styled.section`
  ${Ui.BasicElement.P} {
    margin-bottom: .2rem;
    margin-top: .8rem;  }
`

export const DetailsContainer = styled.div`
  ${Ui.Layout.Panel} {
    min-height: 5rem;
    ${TagsContainer} {
      margin-bottom: 1rem;
    }
  }
  ${Ui.Layout.Panel} {
      min-height: 5rem;
  }
  ${Ui.BasicElement.H1} {
    margin-top: 2rem;
    @media only screen and (max-width: ${props => props.theme.device.desktop}) {
      font-size: 1.125rem !important;
      margin-top: 1rem;
    }
  }
  ${Ui.Layout.Content} {
    @media only screen and (min-width: ${props => props.theme.device.desktop}) {
      & > :nth-child(2) {
        margin-top: 5rem;
      }
    }
    @media only screen and (max-width: ${props => props.theme.device.desktop}) {
        padding:0;
      }
  }
  ${Ui.Form.Input} {
    &:read-only {
      border-color: transparent;
      opacity: 1;
    }
  }
`

export const FullSpaceContainer = styled(props => <Ui.Layout.FlexBox {...props} />)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
`

export const SubTitlesContainer = styled.div`
  word-wrap: break-word;
  margin-top: .3rem;
  p {
    margin: 0;
    line-height: 1.4rem;
  }
`

export const AddPatientButtons = styled(props => <Ui.Layout.ButtonGroup {...props} />)`
  margin-bottom: 1.4rem;
  ${Ui.Select.SelectContainer} {
    margin-right: .8rem;
  }
  a {
    display: flex;
  }
`

export const SpecialityCity = styled(props => <Ui.BasicElement.P {...props} />)`
  margin-bottom: 0;
`

export const ShowPortalButton = styled.div`
  margin-top: .5rem;
  margin-bottom: 1rem;
`

export const QrCodeTitle = styled(props => <Ui.BasicElement.P {...props} />)`
  margin-bottom: .6rem;
`
