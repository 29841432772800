import React, { useState, useEffect, useRef } from 'react'
import qs from 'qs'
import QRCode from 'qrcode.react';
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { useAlert } from 'react-alert'
import { privateSitePrefix } from '../Util/Config'
import { apiUrl } from '../Util/Config'
import { fr } from '../Util/i18n';
import { dateToString, normalizeUrl } from '../Util/Format';
import { todayAtMidnight } from '../Util/Date';
import { uniq, _ } from '../Util/Object'
import { staffPublicUrl } from '../Util/Config'
import { Redirect } from "react-router-dom";
import * as UploadFile from '../State/UploadFile';
import * as TeleExpertise from '../State/Staff';
import * as InvitationState from '../State/Invitation';
import * as CustomField from '../State/CustomField';
import * as Resources from '../State/Resources';
import * as Login from '../State/Login';
import * as Menu from '../State/Menu'
import * as Ui from './Ui'
import * as Styled from './Staff.styled'
import DatePicker from './Ui/DatePicker';
import Tags from './Ui/Tags';
import Editable from '../Util/Editable'
import WarningBanner from './Ui/WarningBanner'
import LoadingBox from './Ui/LoadingBox'
import ImageTool from './Ui/ImageTool';
import DownloadableQrCode from './Ui/DowloadableQRCode';
import WithPermissions from './WithPermissions'
import Invitation from './Invitation';
import pluralize from '../Util/Pluralize';
import { getManagersDisciplines } from '../Util/TeleExpertisesHelpers';
import {
  enterField,
  leaveField,
  enterFieldsBlock,
  leaveFieldsBlock,
  keyPress,
  restoreValue,
} from '../Util/InlineEditActions';

const teleExpertiseName = 'staff'

const StaffComponent = (props) => {
  const alert = useAlert()
  const { match: { params: { id: teleExpertiseIdParam, sessionId: sessionIdParam } } } = props
  const dispatch = useDispatch();
  const [current, setCurrent] = useState(1)
  const currentUser = useSelector(Login.selectUser)
  const teleExpertiseId = useSelector(TeleExpertise.selectId)
  const sessionId = useSelector(TeleExpertise.selectSessionId)
  const resourceLoading = useSelector(Resources.selectLoading)
  const forms = useSelector(CustomField.selectFormFields)
  const isEditFields = useSelector(CustomField.selectIsViewerFieldEdit)
  const isBuilderFieldEdit = useSelector(CustomField.selectIsBuilderFieldEdit)
  const {
    data: {
      permissions: { value: permissions },
      owner,
      managers,
      sessionsUpcoming,
      colleagues,
      patients: patientsIds,
      organizationMembers,
      publicUri,
    },
    data,
    search,
    expertisesWithComments,
    savingSession,
    errored,
    errorMessage,
    loading,
    sending,
    pending,
    isEditMode,
    closing,
  } = useSelector(state => state.staff)
  const isInviting = useSelector(InvitationState.selectIsInviting)
  const {
    [Resources.RESOURCE_MEMBERS]: members,
    [Resources.RESOURCE_DISCIPLINES]: disciplines,
    [Resources.RESOURCE_PATIENTS]: patients,
    [Resources.RESOURCE_ORGANIZATIONS]: organizations,
  } = useSelector(Resources.selectResources)
  const defaultCanEdit = permissions?._canBeModified?.default
  const archivesByYear = useSelector(TeleExpertise.selectArchivesByYear)
  const {
    sections,
    fields,
    choices,
    error,
    errorMessage: customFieldErrorMessage,
  } = useSelector(state => state.customField)

  const managersDisciplines = getManagersDisciplines(members, managers)

  const updateField = name => e => {
    if (name === 'publicUri') {
      dispatch(TeleExpertise.updateField({ name, value: normalizeUrl(e.target.value) }))
    } else {
      dispatch(TeleExpertise.updateField({ name, value: e.target.value }))
    }
    dispatch(TeleExpertise.validateData({ name }))
  }

  const updateHourField = name => e => {
    let val = e.target.value;

    if (val.length === 2) {
      val += ':';
    }
    dispatch(TeleExpertise.updateField({ name, value: val }))
  }

  const updateSessionHourField = (e, idx) => {
    let val = e.target.value;

    if (val.length === 2) {
      val += ':';
    }
    dispatch(TeleExpertise.updateSessionHourField({ value: val, idx }))
  }

  const send = (e, selectedField) => {
    e.preventDefault()
    if (!sending) {
      dispatch(TeleExpertise.update({ teleExpertiseId, teleExpertiseName, selectedField }))
    }
  }

  useEffect(() => {
    const selectTab = props.location.search
    const activeTab = qs.parse(selectTab, { ignoreQueryPrefix: true }).tab
    if (activeTab) {
      setCurrent(Number(activeTab))
    }
  }, [props.location])

  useEffect(() => {
    dispatch(TeleExpertise.fetch({ teleExpertiseIdParam, sessionIdParam }))
    dispatch(Menu.changeActive(Menu.STAFFS))

    return () => {
      dispatch(TeleExpertise.clean())
      dispatch(CustomField.clean())
      dispatch(UploadFile.cleanAll())
    }
  }, [teleExpertiseIdParam, sessionIdParam, closing, dispatch])

  useEffect(() => {
    if (errorMessage)
      alert.error(errorMessage, { timeout: 5000 })
  }, [alert, errorMessage])

  const nextSession = sessionsUpcoming.value[0] || {}
  const nextSessionDateAtMidnight = nextSession.date instanceof Date
    ? nextSession.date.getTime()
    : null

  const membersCount = [
    ...owner.value,
    ...colleagues.value,
    ...managers.value,
    ...organizationMembers.value,
  ].filter((item, idx, arr) => arr.indexOf(item) === idx).length

  const setEditField = (fieldName, value) =>
    dispatch(TeleExpertise.setEditField({ field: fieldName, value }))

  const setEditMode = (blockName, value) =>
    dispatch(TeleExpertise.setEditMode({ target: blockName, value }))

  const restoreInitialValue = (fieldName, value) =>
    dispatch(TeleExpertise.restoreInitialValue({ fieldName, value }))

  const saveField = (fieldName) =>
    dispatch(TeleExpertise.saveField({ fieldName }))

  const onEnterField = (fieldName, canEdit = defaultCanEdit) =>
    enterField(fieldName, canEdit, setEditField)

  const onEnterFieldsBlock = (blockName, canEdit = defaultCanEdit) =>
    enterFieldsBlock(blockName, canEdit, setEditMode)

  const onRestoreValue = (fieldName, initialValue) =>
    restoreValue(fieldName, data[fieldName], initialValue, setEditField, restoreInitialValue)

  const onLeaveField = (fieldName) =>
    leaveField(fieldName, data[fieldName], setEditField, saveField)

  const onLeaveFieldsBlock = (blockName, doSaveField = false) =>
    leaveFieldsBlock(blockName, teleExpertiseId, isEditMode, doSaveField, setEditMode, saveField)

  const onKeyPress = e =>
    keyPress(e, data, saveField, setEditField, restoreInitialValue)

  const navItems = [
    [<Ui.Icon.IconPatientSup />, `${patientsIds.value.length} ${pluralize('Patient', patientsIds.value.length)}`],
    [<Ui.Icon.IconDoctor />, `${membersCount} ${pluralize('Médecins', membersCount)}`],
    [<Ui.Icon.IconCalendar />, 'Programmation'],
    [<Ui.Icon.IconDocument />, 'Fiche patient'],
    [<Ui.Icon.IconPlanet />, 'Portail'],
    [<Ui.Icon.IconInfos />, 'Paramètres'],
  ]

  const publicUrl = [staffPublicUrl, publicUri?.value].join('/')
  const canEdit = permissions?._canBeModified?.default
  const refs = useRef({});

  if (closing && sessionIdParam) {
    return <Redirect to={`${privateSitePrefix}/staffs/${teleExpertiseId}`} />
  }

  return (
    <>
      <LoadingBox />
      <Ui.Layout.PageLayout currentUser={currentUser}>
        <Styled.DetailsContainer>
          {loading && !errored
            ? (
              <Styled.FullSpaceContainer>
                <Ui.Global.Loader />
              </Styled.FullSpaceContainer>
            )
            : (
              <Ui.Layout.PageContentWrapper>
                <TeleExpertiseSummary
                  teleExpertiseId={teleExpertiseId}
                  data={data}
                  loading={loading}
                  errored={errored}
                  managersDisciplines={managersDisciplines}
                  nextSession={nextSession}
                  nextSessionDateAtMidnight={nextSessionDateAtMidnight}
                  publicUrl={publicUrl}
                />
                <Ui.Layout.Content hasPanel noPadding>
                  {!loading &&
                    <Ui.Layout.NavLinks
                      navItems={navItems}
                      current={current}
                      setCurrent={setCurrent}
                    />
                  }
                  {current === 1 &&
                    <WarningBanner
                      data={data}
                      currentUser={currentUser}
                      teleExpertiseId={teleExpertiseId}
                      teleExpertiseType='staffs'
                      slice={TeleExpertise}
                    />
                  }
                  <Ui.Layout.PaddedContent>
                    {!loading || errored
                      ? <Patients
                        data={data}
                        pending={pending}
                        teleExpertiseId={teleExpertiseId}
                        dispatch={dispatch}
                        current={current}
                        permissions={permissions}
                        isEditMode={isEditMode}
                        patients={patients}
                        search={search}
                        resourceLoading={resourceLoading}
                        sessionId={sessionId}
                        expertisesWithComments={expertisesWithComments}
                        onEnterFieldsBlock={onEnterFieldsBlock}
                        onLeaveFieldsBlock={onLeaveFieldsBlock}
                      />
                      :
                      <Styled.FullSpaceContainer>
                        <Ui.Global.Loader />
                      </Styled.FullSpaceContainer>
                    }
                    <Colleagues
                      data={data}
                      dispatch={dispatch}
                      current={current}
                      members={members}
                      organizations={organizations}
                      sessionId={sessionId}
                      sessionIdParam={sessionIdParam}
                      isEditMode={isEditMode}
                      search={search}
                      teleExpertiseId={teleExpertiseId}
                      resourceLoading={resourceLoading}
                      onEnterFieldsBlock={onEnterFieldsBlock}
                      onLeaveFieldsBlock={onLeaveFieldsBlock}
                      currentUser={currentUser}
                      isInviting={isInviting}
                      nextSessionDate={nextSession.dateString}
                      membersCount={membersCount}
                    />
                    <Scheduling
                      alert={alert}
                      current={current}
                      data={data}
                      isEditMode={isEditMode}
                      send={send}
                      dispatch={dispatch}
                      permissions={permissions}
                      DatePicker={DatePicker}
                      teleExpertiseId={teleExpertiseId}
                      sessionIdParam={sessionIdParam}
                      archivesByYear={archivesByYear}
                      savingSession={savingSession}
                      updateHourField={updateHourField}
                      updateSessionHourField={updateSessionHourField}
                      canEdit={canEdit}
                    />
                    <PatientFile
                      dispatch={dispatch}
                      teleExpertiseId={teleExpertiseId}
                      current={current}
                      isEditMode={isEditMode}
                      sections={sections}
                      fields={fields}
                      choices={choices}
                      errorMessage={customFieldErrorMessage}
                      hasError={error}
                      data={data}
                      forms={forms}
                      isEditFields={isEditFields}
                      isBuilderFieldEdit={isBuilderFieldEdit}
                      send={send}
                      onEnterFieldsBlock={onEnterFieldsBlock}
                      onLeaveFieldsBlock={onLeaveFieldsBlock}
                      canEdit={canEdit}
                    />
                    <Portal
                      data={data}
                      current={current}
                      dispatch={dispatch}
                      refs={refs}
                      onEnterField={onEnterField}
                      onRestoreValue={onRestoreValue}
                      onLeaveField={onLeaveField}
                      onKeyPress={onKeyPress}
                      canEdit={canEdit}
                      updateField={updateField}
                      permissions={permissions}
                      teleExpertiseId={teleExpertiseId}
                      send={send}
                      alert={alert}
                      publicUrl={publicUrl}
                    />
                    <Settings
                      data={data}
                      teleExpertiseId={teleExpertiseId}
                      dispatch={dispatch}
                      current={current}
                      isEditMode={isEditMode}
                      updateField={updateField}
                      send={send}
                      disciplines={disciplines}
                      managersDisciplines={managersDisciplines}
                      members={members}
                      permissions={permissions}
                      search={search}
                      resourceLoading={resourceLoading}
                      currentUser={currentUser}
                      refs={refs}
                      onEnterField={onEnterField}
                      onRestoreValue={onRestoreValue}
                      onLeaveField={onLeaveField}
                      onEnterFieldsBlock={onEnterFieldsBlock}
                      onLeaveFieldsBlock={onLeaveFieldsBlock}
                      onKeyPress={onKeyPress}
                      canEdit={canEdit}
                    />
                  </Ui.Layout.PaddedContent>
                </Ui.Layout.Content>
              </Ui.Layout.PageContentWrapper>
            )
          }
        </Styled.DetailsContainer>
      </Ui.Layout.PageLayout>
    </>
  )
}

const TeleExpertiseSummary = ({
  data,
  loading,
  teleExpertiseId,
  errored,
  managersDisciplines,
  nextSession,
  nextSessionDateAtMidnight,
  publicUrl,
}) => {
  const {
    name,
    description,
    pictureFileName,
    archive,
    endDate,
    isOutdated,
    recurrence,
    tags,
    disciplines,
    ownerTitle,
    ownerFullname,
    patientLimitLocked,
  } = data;

  const onVisioButtonClick = () => {
    nextSessionDateAtMidnight === todayAtMidnight
      ? window.open(`${privateSitePrefix}/videoconference/${teleExpertiseName}s/${teleExpertiseId}`, 'linkello')
      : alert.show(`Aucune visioconférence n'est programmée aujourd'hui. Prochaine session : ${nextSession.dateString === 'date à déterminer' ? 'date à déterminer' : ` le ${nextSession.dateString}`}`, { timeout: 5000 })
  }

  const staffSessionStatus = archive.value
    ? `${fr[teleExpertiseName].expertise} archivé`
    : isOutdated.value
      ? `Séance du ${endDate.value} (archivée)`
      : nextSessionDateAtMidnight === todayAtMidnight
        ? `Aujourd'hui ${nextSession.hour !== '00:00' ? `à ${nextSession.hour} (ouverte)` : ''}`
        : Object.values(nextSession).length === 0 || nextSession.dateString === 'date à déterminer' || !nextSession.id
          ? 'Date à déterminer'
          : `Le ${nextSession.dateString} ${nextSession.hour && nextSession.hour !== '00:00' ? `à ${nextSession.hour} (ouverte)` : ''}`

  const tagList = tags.value.map((tags, i) =>
    <span key={`tags_${i}`}>{tags}</span>
  )

  const disciplineList = [...managersDisciplines, ...disciplines.value]
    .filter(uniq)
    .map((discipline, i) =>
      <span key={`discipline_${i}`}>{discipline}</span>
    )

  return (
    <Styled.LeftPanelContainer>
      <Ui.Layout.Panel>
        {!loading || errored
          ? <>
            <Ui.BasicElement.TeleExpertisePicture
              value={{
                teleExpertiseId,
                type: teleExpertiseName,
                pictureFileName: pictureFileName.value,
              }}
            />
            <Ui.BasicElement.H1>{name.value}</Ui.BasicElement.H1>
            <Styled.SubTitlesContainer>
              <Ui.BasicElement.P dark>{description.value}</Ui.BasicElement.P>
            </Styled.SubTitlesContainer>
            <Styled.NextSessionContainer>
              <Styled.NextSessionContent>
                {!isOutdated.value &&
                  <Ui.BasicElement.P bold medium light>
                    {archive.value ? null : 'Prochaine séance :'}
                  </Ui.BasicElement.P>
                }
                <Ui.BasicElement.P bold dark>{staffSessionStatus}</Ui.BasicElement.P>
                {!isOutdated.value
                  ? <>
                    <Ui.BasicElement.P light>
                      Récurrence : {recurrence.value ? fr.recurrence[recurrence.value] : 'Aucune'}
                    </Ui.BasicElement.P>
                    {(nextSessionDateAtMidnight === todayAtMidnight) && !nextSession.isFieldEdit
                      ? <Styled.PanelButtons>
                        <Ui.Button.BasicButton
                          onClick={onVisioButtonClick}
                          isFilled
                          isPrimary
                          isSmall
                        >
                          Visioconférence
                        </Ui.Button.BasicButton>
                      </Styled.PanelButtons>
                      :
                      <Styled.VisioAnnouncement>
                        La visioconférence sera ouverte le jour de la prochaine séance
                      </Styled.VisioAnnouncement>
                    }
                  </>
                  : null
                }
              </Styled.NextSessionContent>
            </Styled.NextSessionContainer>
            <Styled.TagsContainer>
              {tags.value.length > 0 &&
                <>
                  <Ui.BasicElement.P bold medium light>Mots-clés</Ui.BasicElement.P>
                  <Ui.Badge>{tagList}</Ui.Badge>
                </>
              }
              {disciplines.value.length > 0 &&
                <>
                  <Ui.BasicElement.P bold medium light>Disciplines associées</Ui.BasicElement.P>
                  <Ui.Badge>{disciplineList}</Ui.Badge>
                </>
              }
            </Styled.TagsContainer>
            <Styled.QrCodeTitle bold medium light>Portail</Styled.QrCodeTitle>
            <Ui.Layout.BlockContainer>
              <a href={publicUrl} rel="noreferrer" target="_blank">
                <DownloadableQrCode teleExpertiseName={name.value}>
                  <QRCode
                    value={publicUrl}
                    size={90}
                    bgColor={"#ffffff"}
                    fgColor={"#000000"}
                    level={"L"}
                    includeMargin={false}
                    id="qrCodeCanvas"
                  />
                </DownloadableQrCode>
              </a>
            </Ui.Layout.BlockContainer>
            <Ui.BasicElement.P small light>
              Responsable : {ownerTitle.value} {ownerFullname.value}
            </Ui.BasicElement.P>
          </>
          : <Styled.FullSpaceContainer>
            <Ui.Global.Loader />
          </Styled.FullSpaceContainer>
        }
      </Ui.Layout.Panel>
      <Ui.Layout.UpgradeMessage isTeleExpertiseLocked={patientLimitLocked.value} />
    </Styled.LeftPanelContainer>
  )
}

const Portal = ({
  data,
  send,
  current,
  dispatch,
  alert,
  refs,
  onEnterField,
  onRestoreValue,
  onLeaveField,
  onKeyPress,
  canEdit,
  updateField,
  teleExpertiseId,
  publicUrl,
}) => {
  const {
    publicUri,
    portalHeaderImage,
    portalHeaderImageStretch,
    portalHeaderImageAlign,
    portalAbout,
    portalDocuments,
  } = data;

  const publicUriRef = useRef();
  const portalPictureRef = useRef();
  const portalAboutRef = useRef();
  const uploadData = useSelector(state => state.uploadFile.data)
  const uploadFilePortalHeaderImage = uploadData &&
    uploadData['portal-header-image'] &&
    uploadData['portal-header-image'].value
    ? Object.values(uploadData['portal-header-image'].value)[0]
    : {}
  const uploadFilePortalHeaderImageFileName = uploadFilePortalHeaderImage?.fileName

  const onRemovePortalHeaderImage = (e) => {
    dispatch(TeleExpertise.updateField({ name: 'portalHeaderImage', value: '' }))
    dispatch(TeleExpertise.saveField({ teleExpertiseId, fieldName: 'portalHeaderImage' }))
  }

  const onDocumentRemove = ({ id }) =>
    dispatch(TeleExpertise.removePortalDocument(id));

  const onAlertBeforeUrlUpdate = () =>
    alert.show(`Vous vous apprêtez à modifier l'URL. Vos correspondants ne pourront plus se connecter sur l'ancienne adresse`, {
      onConfirm: onEnterField('publicUri', canEdit)
    })

  return (current === 5) && (
    <Ui.Layout.Section>
      <Ui.Layout.Form onSubmit={send} noValidate>
        <Ui.BasicElement.H2>
          Connexion
        </Ui.BasicElement.H2>
        <Ui.BasicElement.H3>
          Url du portail
        </Ui.BasicElement.H3>
        <Ui.BasicElement.P>
          Personnalisez puis partagez cette adresse internet pour que vos correspondants accèdent directement à votre téléexpertise.
        </Ui.BasicElement.P>
        <Editable
          text={publicUrl}
          isEditMode={publicUri.isFieldEdit}
          onClick={onAlertBeforeUrlUpdate}
          onBlur={onLeaveField('publicUri')}
          childRef={publicUriRef}
          canEdit={canEdit}
        >
          <Ui.Layout.PublicUriContainer flow="column wrap">
            <Ui.Layout.FlexBox flow="row wrap">
              <Ui.BasicElement.P>{`${staffPublicUrl}/`}</Ui.BasicElement.P>
              <div>
                <Ui.Form.TextInput
                  name="publicUri"
                  value={normalizeUrl(publicUri.value)}
                  onChange={updateField('publicUri')}
                  onBlur={onLeaveField('publicUri')}
                  onRestoreValue={onRestoreValue('publicUri', publicUri.initialValue)}
                  onKeyDown={onKeyPress}
                  error={publicUri.errors[0]}
                  refs={refs}
                  tabIndex="4"
                />
              </div>
            </Ui.Layout.FlexBox>
          </Ui.Layout.PublicUriContainer>
        </Editable>
        <Styled.ShowPortalButton>
          <a href={publicUrl} onClick={e => e.stopPropagation()} target="_blank" rel="noreferrer">
            <Ui.Button.BasicButton isSmall isFilled isGreen>
              Voir le portail
            </Ui.Button.BasicButton>
          </a>
        </Styled.ShowPortalButton>
        <Ui.BasicElement.H2>
          Personnalisation
        </Ui.BasicElement.H2>
        <Ui.Layout.FlexBox>
          <Ui.Layout.BlockContainer>
            <Ui.BasicElement.ViewTitleContainer>
              <Ui.BasicElement.H3>Image d'en-tête</Ui.BasicElement.H3>
            </Ui.BasicElement.ViewTitleContainer>
            <Ui.UploadFile.ImagesUpload
              label=""
              name="portal-header-image"
              id="portal-header-image"
              htmlFor="portal-header-image"
              multiple={false}
              endpoint={`/${teleExpertiseName}s/${teleExpertiseId}/add-portal-header-image`}
              removeEndpoint={`/${teleExpertiseName}s/${teleExpertiseId}/remove-portal-header-image`}
              onRemove={onRemovePortalHeaderImage}
              inputRef={portalPictureRef}
              value={portalHeaderImage.value ? [{ fileName: portalHeaderImage.value }] : []}
              validationRules={["noLargeFiles", "noEmptyFiles", "acceptedImageFormat"]}
              previewUri={`/${teleExpertiseName}s/portal-header-image`}
              disabled={!canEdit}
            />
          </Ui.Layout.BlockContainer>
          <Ui.Layout.BlockContainer>
            {(uploadFilePortalHeaderImageFileName || portalHeaderImage.value) &&
              <>
                <ImageTool
                  alignKey='portalHeaderImageAlign'
                  stretchKey='portalHeaderImageStretch'
                  data={data}
                  slice={TeleExpertise}
                  isStretched={portalHeaderImageStretch.value}
                  alignment={portalHeaderImageAlign.value}
                  image={uploadFilePortalHeaderImageFileName || portalHeaderImage.value}
                  isEditMode={true}
                  teleExpertiseName={teleExpertiseName}
                  teleExpertiseId={teleExpertiseId}
                  endpointPath='portal-header-image'
                  canEdit={canEdit}
                />
              </>
            }
          </Ui.Layout.BlockContainer>
        </Ui.Layout.FlexBox>
        <Ui.BasicElement.H2>
          Contenus
        </Ui.BasicElement.H2>
        <Ui.BasicElement.P>
        </Ui.BasicElement.P>
        <Ui.Layout.BlockContainer>
          <Editable
            title="A propos"
            text={portalAbout.value}
            isEditMode={portalAbout.isFieldEdit}
            onClick={onEnterField('portalAbout', canEdit)}
            onBlur={onLeaveField('portalAbout')}
            childRef={portalAboutRef}
            altText={`${canEdit ? 'Ajouter un a propos' : 'Aucun à propos n\'a été défini'} pour ${fr[teleExpertiseName].thisExpertise}`
            }
            canEdit={canEdit}
          >
            <Ui.Form.TextAreaInputResizable
              name="portalAbout"
              label="A propos"
              value={portalAbout.value}
              onChange={updateField('portalAbout')}
              onBlur={onLeaveField('portalAbout')}
              onRestoreValue={onRestoreValue('portalAbout', portalAbout.initialValue)}
              error={portalAbout.errors[0]}
              inputRef={portalAboutRef}
            />
          </Editable>
        </Ui.Layout.BlockContainer>
        <Ui.Layout.BlockContainer>
          <Ui.BasicElement.H3>
            Documents
          </Ui.BasicElement.H3>
          <Ui.UploadFile.DocumentsUpload
            name={`portal-documents`}
            validationRules={["noLargeFiles", "noEmptyFiles", "acceptedDocumentFormat"]}
            label=""
            endpoint={`/tele-expertises/portal/add/${teleExpertiseName}/${teleExpertiseId}`}
            apiPath="/tele-expertises/portal/assets/"
            value={portalDocuments.value}
            onRemove={onDocumentRemove}
            isEditMode={true}
            disabled={!canEdit}
          />
        </Ui.Layout.BlockContainer>
      </Ui.Layout.Form>
    </Ui.Layout.Section>
  )
}

const Settings = ({
  data,
  teleExpertiseId,
  current,
  send,
  updateField,
  isEditMode,
  members,
  dispatch,
  disciplines: disciplinesResource,
  managersDisciplines,
  permissions,
  search,
  resourceLoading,
  refs,
  onEnterField,
  onRestoreValue,
  onLeaveField,
  onEnterFieldsBlock,
  onLeaveFieldsBlock,
  onKeyPress,
  canEdit,
  currentUser,
}) => {
  const {
    ownerId,
    owner,
    managers,
    pictureFileName,
    description,
    archive,
    disciplines,
    reportImageFileName,
  } = data;

  const alert = useAlert();
  const searchDisciplinesRef = useRef();
  const staffPictureRef = useRef();
  const reportImageRef = useRef();
  const descriptionRef = useRef();
  const tagRef = useRef();
  const searchManagersRef = useRef();

  const ownerData = members.find(member => member.id === Number(ownerId.value))

  const managersData = members
    .filter(member =>
      managers.value.includes(member.id) &&
      member.id !== Number(ownerId.value)
    )

  const onUpdateOwner = (colleague) =>
    dispatch(TeleExpertise.updateOwner({
      formerOwner: ownerId.value,
      colleague: {
        colleagueId: colleague.id,
        colleagueTitle: colleague.title,
        colleagueFullName: `${colleague.firstname} ${colleague.lastname}`,
      },
    }))

  const updateTagContent = (name, idx) => e => {
    dispatch(TeleExpertise.updateTagContent({ name, value: e.target.value, idx }))
  }

  const onRemovePicture = () => {
    dispatch(TeleExpertise.updateField({ name: 'pictureFileName', value: '' }))
    dispatch(TeleExpertise.saveField({ teleExpertiseId, fieldName: 'pictureFileName' }))
  }

  const onRemoveReportImage = () => {
    dispatch(TeleExpertise.updateField({ name: 'reportImageFileName', value: '' }))
    dispatch(TeleExpertise.saveField({ teleExpertiseId, fieldName: 'reportImageFileName' }))
  }

  const onCloseTeleExpertise = (e, teleExpertiseId) => {
    alert.show(`Confirmez-vous l'archivage de ${fr[teleExpertiseName].thisExpertise} ?`, {
      onConfirm: (e) => dispatch(TeleExpertise.closeExpertise(teleExpertiseId))
    })
  }
  const onOpenTeleExpertise = (e, teleExpertiseId) => {
    alert.show(`Confirmez-vous la ré-ouverture de ${fr[teleExpertiseName].thisExpertise} ?`, {
      onConfirm: (e) => dispatch(TeleExpertise.openExpertise(teleExpertiseId))
    })
  }

  const onAddTag = () => {
    dispatch(TeleExpertise.addTag())
    tagRef?.current?.focus()
  }

  const onRemoveTag = idx => e => {
    dispatch(TeleExpertise.removeTag({ idx }))
    tagRef?.current?.focus()
  }

  const onToggleDisciplines = (id, toggled, name = 'disciplines', teleExpertiseId) => {
    dispatch(TeleExpertise.toggle({ id, toggled, name, teleExpertiseId }))
  }

  const onToggleManagers = (id, toggled, name = 'managers') => {
    dispatch(TeleExpertise.toggle({ id, toggled, name }))
    toggled
      ? dispatch(TeleExpertise.addManager({ id }))
      : dispatch(TeleExpertise.removeManager({ id }))
  }

  return (current === 6) && (
    <Ui.Layout.Section>
      <Ui.Layout.Form onSubmit={send} noValidate>
        <Ui.Layout.Row>
          <Ui.Form.EditableTextInput
            name="name"
            label={`Nom${isEditMode ? ' *' : ''}`}
            data={data}
            updateField={updateField}
            onEnterField={onEnterField}
            onLeaveField={onLeaveField}
            onRestoreValue={onRestoreValue}
            onKeyPress={onKeyPress}
            refs={refs}
            canEdit={canEdit}
          />
        </Ui.Layout.Row>
        <Ui.BasicElement.ViewTitleContainer>
          <Ui.BasicElement.H3>Illustration</Ui.BasicElement.H3>
        </Ui.BasicElement.ViewTitleContainer>
        <Ui.Layout.BlockContainer>
          <Ui.UploadFile.ImagesUpload
            name="staff-picture"
            id="staff-picture"
            label=""
            endpoint={`/${teleExpertiseName}s/${teleExpertiseId}/add-picture`}
            removeEndpoint={`/${teleExpertiseName}s/${teleExpertiseId}/remove-picture`}
            value={pictureFileName.value ? [{ fileName: pictureFileName.value }] : []}
            validationRules={["noLargeFiles", "noEmptyFiles", "acceptedImageFormat"]}
            previewUri={`/${teleExpertiseName}s/picture`}
            inputRef={staffPictureRef}
            onRemove={onRemovePicture}
            sliceToUpdate={TeleExpertise}
            disabled={!canEdit}
          />
        </Ui.Layout.BlockContainer>
        <Ui.Layout.Row>
          <Editable
            title="Description"
            text={description.value}
            isEditMode={description.isFieldEdit}
            onClick={onEnterField('description', canEdit)}
            onBlur={onLeaveField('description')}
            childRef={descriptionRef}
            altText={`${canEdit ? 'Ajouter une description' : 'Aucune description n\'a été défini'} pour ${fr[teleExpertiseName].thisExpertise}`}
            canEdit={canEdit}
          >
            <Ui.Form.TextAreaInputResizable
              name="description"
              label="Description"
              value={description.value}
              onChange={updateField('description')}
              onBlur={onLeaveField('description')}
              onRestoreValue={onRestoreValue('description', description.initialValue)}
              error={description.errors[0]}
              inputRef={descriptionRef}
            />
          </Editable>
        </Ui.Layout.Row>
        <Ui.Layout.BlockContainer>
          <Tags
            TeleExpertise={TeleExpertise}
            teleExpertiseName={teleExpertiseName}
            data={data}
            type="tag"
            label="mot-clé"
            isEditMode={isEditMode.tags}
            onAddItem={onAddTag}
            onRemoveItem={(idx) => onRemoveTag(idx)}
            onUpdateItemContent={(type, idx) => updateTagContent(type, idx)}
            onEnterFieldsBlock={onEnterFieldsBlock('tags', canEdit)}
            onLeaveFieldsBlock={onLeaveFieldsBlock('tags', true)}
            childRef={tagRef}
            canEdit={canEdit}
          />
        </Ui.Layout.BlockContainer>
        <Ui.Layout.BlockContainer>
          <Editable
            title=""
            content={
              [...managersDisciplines, ...disciplines.value].filter(uniq).length > 0 &&
              <Ui.Layout.FlexBox flow="column wrap">
                <Ui.BasicElement.H3>Disciplines</Ui.BasicElement.H3>
                <Ui.Layout.ColleagueCardsContainer>
                  {[...managersDisciplines, ...disciplines.value]
                    .filter(uniq)
                    .map((discipline, i) =>
                      <Ui.Form.CheckboxLarge
                        key={`discipline_${i}`}
                        name="discipline"
                        label={discipline}
                        disabled={!canEdit}
                      >
                        <Ui.SearchBar.DefaultCheckboxIcon
                        />
                      </Ui.Form.CheckboxLarge>
                    )}
                </Ui.Layout.ColleagueCardsContainer>
              </Ui.Layout.FlexBox>
            }
            isEditMode={isEditMode.disciplines}
            onClick={onEnterFieldsBlock('disciplines', canEdit)}
            onBlur={onLeaveFieldsBlock('disciplines')}
            altContent={
              <>
                <Ui.BasicElement.H3>Disciplines</Ui.BasicElement.H3>
                {`${canEdit ? 'Ajouter une discipline' : 'Aucune discipline n\'est associée'} à ${fr[teleExpertiseName].thisExpertise}`}
              </>
            }
            childRef={searchDisciplinesRef}
            canEdit={canEdit}
          >
            <Ui.Layout.FocusDiv ref={searchDisciplinesRef} tabIndex="1" >
              <Ui.BasicElement.H3>Disciplines</Ui.BasicElement.H3>
              <Ui.Form.InputContainer>
                <Ui.SearchBar.SearchAndCheck
                  name="disciplines"
                  query={search.disciplines.value}
                  TeleExpertise={TeleExpertise}
                  teleExpertiseName={teleExpertiseName}
                  resultSet={[...disciplinesResource, ...managersDisciplines]}
                  list={[...managersDisciplines, ...disciplines.value].filter(uniq)}
                  loading={resourceLoading.disciplines}
                  checkboxDisabled={discipline => managersDisciplines.includes(discipline)}
                  onToggle={onToggleDisciplines}
                />
              </Ui.Form.InputContainer>
            </Ui.Layout.FocusDiv>
          </Editable>
        </Ui.Layout.BlockContainer>
        <Ui.Layout.BlockContainer>
          <Ui.BasicElement.H3>
            Responsable
          </Ui.BasicElement.H3>
          <Ui.Colleague.Colleague
            to={`${privateSitePrefix}/medecins/${ownerId.value}`}
            colleague={ownerData}
            currentUser={currentUser}
          />
        </Ui.Layout.BlockContainer>
        <Ui.Layout.BlockContainer>
          <Editable
            content={
              managers.value.length > 0
                ? <Ui.Layout.FlexBox flow="column wrap">
                  <Ui.BasicElement.H3>Administrateurs</Ui.BasicElement.H3>
                  <Ui.Layout.ColleagueCardsContainer>
                    {managersData
                      .map((colleague, i) =>
                        <Ui.Colleague.Colleague
                          key={`colleague_${i}`}
                          to={`${privateSitePrefix}/medecins/${colleague.id}`}
                          colleague={colleague}
                          currentUser={currentUser}
                          onUpdateOwner={(colleague, ownerId) => onUpdateOwner(colleague, ownerId)}
                          teleExpertiseOwnerId={ownerId.value}
                          permissions={permissions}
                        />
                      )
                    }
                  </Ui.Layout.ColleagueCardsContainer>
                </Ui.Layout.FlexBox>
                : null
            }
            isEditMode={isEditMode.managers}
            onClick={onEnterFieldsBlock('managers', canEdit)}
            onBlur={onLeaveFieldsBlock('managers')}
            altContent={
              <>
                <Ui.BasicElement.H3>Administrateurs</Ui.BasicElement.H3>
                {canEdit
                  ? 'Ajouter un administrateur'
                  : `Aucun administrateur n'est associé à ${fr[teleExpertiseName].thisExpertise}`
                }
              </>
            }
            childRef={searchManagersRef}
            canEdit={canEdit}
          >
            <Ui.Layout.FocusDiv ref={searchManagersRef} tabIndex="16554" >
              <Ui.BasicElement.H3>Administrateurs</Ui.BasicElement.H3>
              <Ui.Form.InputContainer>
                <Ui.SearchBar.SearchAndCheck
                  name="managers"
                  query={search.managers.value}
                  TeleExpertise={TeleExpertise}
                  resultSet={members}
                  list={[...owner.value, ...managers.value].filter(uniq)}
                  loading={resourceLoading.managers}
                  checkboxDisabled={manager => manager.id === owner.value[0]}
                  onToggle={onToggleManagers}
                />
                {managers.errors[0] &&
                  <Ui.Form.FieldErrorMessage>{managers.errors[0]}</Ui.Form.FieldErrorMessage>
                }
              </Ui.Form.InputContainer>
            </Ui.Layout.FocusDiv>
          </Editable>
        </Ui.Layout.BlockContainer>
        <Ui.BasicElement.ViewTitleContainer>
          <Ui.BasicElement.H3>En-tête de compte-rendu</Ui.BasicElement.H3>
        </Ui.BasicElement.ViewTitleContainer>
        <Ui.Layout.BlockContainer>
          <Ui.UploadFile.ImagesUpload
            label=""
            name="heading-documents"
            id="heading-documents"
            htmlFor="heading-documents"
            multiple={false}
            endpoint={`/${teleExpertiseName}s/${teleExpertiseId}/reportImage`}
            removeEndpoint={`/${teleExpertiseName}s/${teleExpertiseId}/reportImage`}
            value={reportImageFileName.value ? [{ fileName: reportImageFileName.value }] : []}
            validationRules={["noLargeFiles", "noEmptyFiles", "acceptedImageFormat"]}
            previewUri={`/${teleExpertiseName}s/reportImage`}
            inputRef={reportImageRef}
            onRemove={onRemoveReportImage}
            isEditMode={isEditMode.headinDocuments}
            disabled={!canEdit}
          />
        </Ui.Layout.BlockContainer>
        <Ui.BasicElement.H2>
          Statut de {fr[teleExpertiseName].thisExpertise} :{'\u00A0'}
          {archive.value ? ' inactif' : 'actif'}
        </Ui.BasicElement.H2>
        <Ui.Layout.BlockContainer>
          <WithPermissions to={['archived']} permissions={permissions}>
            {archive.value
              ? <Ui.Button.BasicButton
                onClick={(e) => onOpenTeleExpertise(e, teleExpertiseId)}
              >
                Ré-ouvrir{'\u00A0'}{fr[teleExpertiseName].thisExpertise}
              </Ui.Button.BasicButton>
              : <Ui.Button.BasicButton
                onClick={(e) => onCloseTeleExpertise(e, teleExpertiseId)}
              >
                Archiver{'\u00A0'}{fr[teleExpertiseName].thisExpertise}
              </Ui.Button.BasicButton>
            }
          </WithPermissions>
        </Ui.Layout.BlockContainer>
      </Ui.Layout.Form>
    </Ui.Layout.Section >
  )
}

const Patients = ({
  current,
  data,
  pending,
  patients: patientsResource,
  permissions,
  dispatch,
  search,
  isEditMode,
  resourceLoading,
  teleExpertiseId,
  sessionId,
  expertisesWithComments,
  onEnterFieldsBlock,
  onLeaveFieldsBlock,
}) => {
  const {
    labels,
    patients,
    isOutdated,
    archive,
    patientsLocked,
    currentSessionPatientIds,
  } = data;

  const canEdit = permissions?._canBeModified?.patients && !isOutdated.value && !archive.value
  const patientSelection = patientsResource.filter(patient => patients.value.includes(patient.id))
  const searchPatientsRef = useRef()

  const onCopyAllPatients = () =>
    dispatch(TeleExpertise.allPatientsToCurrentSession({ teleExpertiseId, sessionId, patients: patients.value }))

  const addToCurrentSession = patient => e =>
    isOutdated.value
      ? dispatch(TeleExpertise.addPatientToCurrentSession({ id: patient.id, teleExpertiseId }))
      : null

  const isInCurrentSession = id => currentSessionPatientIds.value.includes(id)

  const hasPatientsToReport = !patientSelection.every(patient => isInCurrentSession(patient.id))

  const patientLink = patientId => `${privateSitePrefix}/patients/${patientId}/${teleExpertiseName}/${teleExpertiseId}/staffSession/${sessionId}`

  const patientList = patientSelection
    .slice()
    .sort((a, b) => a.pendingPatient - b.pendingPatient)
    .map((patient, i) =>
      <Ui.Card.PatientCard
        key={`patient_card_${i}`}
        to={patientLink(patient.id)}
        addToCurrentSession={addToCurrentSession(patient)}
        canAddToCurrentSession={!archive.value && isOutdated.value && !isInCurrentSession(patient.id)}
        hasExpertiseComment={
          Object
            .keys(expertisesWithComments)
            .includes(String(patient.id)) &&
          expertisesWithComments[patient.id]
        }
        isLocked={patientsLocked.value.includes(patient.id)}
        {...patient}
        labels={patient.labels?.length > 0 && patient.labels.filter(patientLabel => labels.value.map((label) => label.content).includes(patientLabel.content))}
      />
    )

  return (current === 1)
    ? <Ui.Layout.Section>
      <WithPermissions domains={['patients']} permissions={permissions}>
        <Styled.HeaderButtons>
          {isOutdated.value &&
            <Ui.Button.BasicButton
              isGreen
              onClick={onCopyAllPatients}
              disabled={pending || patientSelection.length === 0 || !hasPatientsToReport || archive.value}
            >
              {pending === true
                ? <Ui.Global.Loader />
                : <Ui.Icon.IconPatientSup />
              }
              Reporter tous les patients
            </Ui.Button.BasicButton>
          }
          <a
            rel="noopener noreferrer"
            download
            href={`${apiUrl}/${teleExpertiseName}s/report-download/${teleExpertiseId}/session/${sessionId}`}>
            <Ui.Button.BasicButton
              isGreen
              disabled={!Object.values(expertisesWithComments).some(el => el)}
            >
              <Ui.Icon.IconDocument />
              Compte-rendu
            </Ui.Button.BasicButton>
          </a>
          <a
            rel="noopener noreferrer"
            download
            href={`${apiUrl}/${teleExpertiseName}s/export-patients/${teleExpertiseId}/session/${sessionId}`}>
            <Ui.Button.BasicButton
              isGreen
              disabled={patientSelection.length === 0}
            >
              <Ui.Icon.IconDocument />
              Export des patients
            </Ui.Button.BasicButton>
          </a>
        </Styled.HeaderButtons>
      </WithPermissions>

      <Ui.Layout.Section>
        <Editable
          content={
            !isEditMode.addPatient &&
            patientSelection.length > 0 &&
            <Ui.Layout.FlexBox flow="column wrap">
              <Ui.BasicElement.H2>Patients</Ui.BasicElement.H2>
              {canEdit &&
                <Ui.BasicElement.P>
                  Cliquez pour ajouter ou supprimer un patient
                </Ui.BasicElement.P>
              }
              <Ui.Layout.ColleagueCardsContainer>
                {patientList}
              </Ui.Layout.ColleagueCardsContainer>
            </Ui.Layout.FlexBox>
          }
          description=""
          isEditMode={isEditMode.addRemovePatient}
          onClick={onEnterFieldsBlock('addRemovePatient', canEdit)}
          onBlur={onLeaveFieldsBlock('addRemovePatient')}
          altContent={
            <>
              <Ui.BasicElement.H2>Patients</Ui.BasicElement.H2>
              <Ui.BasicElement.P>
                {canEdit
                  ? 'Cliquez pour ajouter ou supprimer un patient'
                  : `Aucun de vos patients n'est associé à ${fr[teleExpertiseName].thisExpertise}`
                }
              </Ui.BasicElement.P>
            </>
          }
          childRef={searchPatientsRef}
          canEdit={canEdit}
        >
          <Ui.Layout.FocusDiv ref={searchPatientsRef} tabIndex="1654" >
            <Ui.BasicElement.H2>Patients</Ui.BasicElement.H2>
            <Ui.Form.InputContainer>
              <Ui.SearchBar.SearchAndCheck
                name="patients"
                data={data}
                query={search.patients.value}
                TeleExpertise={TeleExpertise}
                teleExpertiseName={teleExpertiseName}
                teleExpertiseId={teleExpertiseId}
                sessionId={sessionId}
                resultSet={patientsResource}
                list={patients.value}
                loading={resourceLoading.patients}
                hasNewPatientButton
              />
            </Ui.Form.InputContainer>
          </Ui.Layout.FocusDiv>
        </Editable>
      </Ui.Layout.Section>

    </Ui.Layout.Section >
    : null
}

const Colleagues = ({
  current,
  data,
  teleExpertiseId,
  sessionIdParam,
  sessionId,
  dispatch,
  organizations: organizationsResource,
  members,
  search,
  resourceLoading,
  nextSessionDate,
  isEditMode,
  onEnterFieldsBlock,
  onLeaveFieldsBlock,
  currentUser,
  isInviting,
  membersCount,
}) => {
  const {
    colleagues,
    owner,
    managers,
    participants,
    organizations,
    organizationMembers,
    endDate,
  } = data;

  const canEdit = data?.permissions?.value?._canBeModified?.members
  const areAllParticipantsChecked = (participants.value.length > 0) && (membersCount === participants.value.length)

  const onAllParticipantsToggle = (areAllParticipantsChecked) => () =>
    dispatch(TeleExpertise.toggleAllParticipants({ areAllParticipantsChecked }))

  const onToggleParticipant = (id, toggled) =>
    dispatch(TeleExpertise.toggleParticipant({ id, toggled }))

  const teleExpertiseOrganizations = organizationsResource
    .filter(organization => organizations.value.includes(organization.id))

  const uniqueColleagues = members => members
    .filter(colleague =>
      [...organizationMembers.value,].includes(colleague.id))
    .filter(colleague =>
      ![...owner.value, ...managers.value, ...colleagues.value].includes(colleague.id))

  const membersByOrganization = Object
    .values(organizationsResource
      .filter(organization => organizations.value.includes(Number(organization.id))))
    .reduce((acc, { id, name, members, managers }) => ({
      ...acc,
      [id]: { name, members: [...members, ...managers] }
    }), {})

  const MembersByOrganizationList = () => Object
    .entries(membersByOrganization)
    .map(([_, { name = 'Médecins', members }], i) =>
      members.length > 0 &&
      uniqueColleagues(members).length > 0 &&
      <Ui.Layout.BlockContainer key={`organization_${i}`}>
        <Ui.BasicElement.H3>Médecins liés à {name}</Ui.BasicElement.H3>
        <Ui.Layout.ColleagueCardsContainer>
          {uniqueColleagues(members)
            .map((colleague, i) =>
              <Ui.Colleague.Colleague
                key={`colleague_${i}`}
                to={`${privateSitePrefix}/medecins/${colleague.id}`}
                colleague={colleague}
                endDate={endDate.value}
                closedSession={sessionIdParam}
                isParticipant={participants.value.includes(colleague.id)}
                onToggle={onToggleParticipant}
                currenUser={currentUser}
                canEdit={canEdit}
              />
            )}
        </Ui.Layout.ColleagueCardsContainer>
      </Ui.Layout.BlockContainer>
    )

  const onToggleOrganization = (id, toggled) =>
    toggled
      ? dispatch(TeleExpertise.addOrganization({ id, teleExpertiseId }))
      : dispatch(TeleExpertise.removeOrganization({ id, teleExpertiseId }))

  const onToggleColleague = (id, toggled) => {
    if (toggled) {
      dispatch(TeleExpertise.addColleague({ id }))
    } else {
      dispatch(TeleExpertise.removeColleague({ id }))
    }
    dispatch(TeleExpertise.setQuery({ name: 'colleagues', value: '' }))
    dispatch(TeleExpertise.setEditMode(!isEditMode))
  }

  const onSearchOrganization = (organizations, criteria) => organizations
    .filter(({ name }) => RegExp(criteria, 'i').test(name))
    .slice(0, 5)

  const searchColleaguesRef = useRef();
  const searchOrganizationsRef = useRef();

  return (
    current === 2
      ? isInviting
        ? <Invitation
          type={teleExpertiseName}
          teleExpertiseId={teleExpertiseId}
          teleExpertiseName={teleExpertiseName}
          data={data}
        />
        : <>
          <Ui.Layout.BlockContainer>
            <Editable
              content={
                teleExpertiseOrganizations.length > 0 &&
                <Ui.Layout.FlexBox flow="column wrap">
                  <Ui.BasicElement.H2>Organisations</Ui.BasicElement.H2>
                  <Ui.Layout.ColleagueCardsContainer>
                    <Ui.Layout.FlexBox flow="row wrap">
                      {teleExpertiseOrganizations.map((organization, i) =>
                        <Ui.Card.OrganizationCard
                          key={i}
                          id={organization.id}
                          name={organization.name}
                          logo={organization.logo}
                          currentUserManagerOrMember={organization.currentUserManagerOrMember}
                        />
                      )}</Ui.Layout.FlexBox>
                  </Ui.Layout.ColleagueCardsContainer>
                </Ui.Layout.FlexBox>
              }
              description=""
              isEditMode={isEditMode.organizations}
              onClick={onEnterFieldsBlock('organizations', canEdit)}
              onBlur={onLeaveFieldsBlock('organizations', canEdit)}
              altContent={
                <>
                  <Ui.BasicElement.H2>Organisations</Ui.BasicElement.H2>
                  {canEdit ? `Associer une organisation ` : `Aucune organisation n'est associée `}
                  à {fr[teleExpertiseName].thisExpertise}
                </>
              }
              childRef={searchOrganizationsRef}
              canEdit={canEdit}
            >
              <Ui.Layout.FocusDiv ref={searchOrganizationsRef} tabIndex="166554" >
                <Ui.BasicElement.H2>Organisations</Ui.BasicElement.H2>
                <Ui.Form.InputContainer>
                  <Ui.SearchBar.SearchAndCheck
                    name="organisations"
                    placeholder="Rechercher une organisation..."
                    query={search.organizations.value}
                    TeleExpertise={TeleExpertise}
                    resultToLabel={organization => organization.name}
                    resultToValue={organization => organization.id}
                    resultSet={organizationsResource}
                    list={organizations.value}
                    loading={resourceLoading.organizations}
                    onQueryChange={(e) => dispatch(TeleExpertise.setQuery({
                      name: 'organizations',
                      value: e.target.value
                    }))}
                    onToggle={onToggleOrganization}
                    onSearch={onSearchOrganization}
                    CheckboxIconComponent={Ui.BasicElement.OrganizationPicture}
                  />
                </Ui.Form.InputContainer>
              </Ui.Layout.FocusDiv>
            </Editable>
          </Ui.Layout.BlockContainer>
          <Styled.ColleaguesBlockHeader>
            <Ui.BasicElement.H2>Médecins</Ui.BasicElement.H2>
            {canEdit && endDate.value &&
              <Ui.Layout.FlexBox>
                <Ui.Form.Checkbox
                  checked={areAllParticipantsChecked}
                  onChange={onAllParticipantsToggle(areAllParticipantsChecked)}
                />
                <div>Sélectionner tout</div>
              </Ui.Layout.FlexBox>
            }
          </Styled.ColleaguesBlockHeader>
          <Ui.Layout.BlockContainer>
            <Editable
              content={
                members.length > 0 &&
                <Ui.Layout.FlexBox flow="column wrap">
                  {canEdit &&
                    <Ui.BasicElement.P>
                      Cliquez pour ajouter ou supprimer un confrère
                    </Ui.BasicElement.P>
                  }
                  <Ui.Layout.ColleagueCardsContainer>
                    {members
                      .filter(colleague =>
                        [...owner.value, ...managers.value, ...colleagues.value].includes(colleague.id))
                      .map((colleague, i) =>
                        <Ui.Colleague.Colleague
                          key={`colleague_${i}`}
                          to={`${privateSitePrefix}/medecins/${colleague.id}`}
                          colleague={colleague}
                          nextMeeting={nextSessionDate}
                          endDate={endDate.value}
                          closedSession={sessionIdParam}
                          sessionId={sessionId}
                          isParticipant={participants.value.includes(colleague.id)}
                          onToggle={onToggleParticipant}
                          currentUser={currentUser}
                          canEdit={canEdit}
                        />
                      )}
                  </Ui.Layout.ColleagueCardsContainer>
                </Ui.Layout.FlexBox>
              }
              description=""
              isEditMode={isEditMode.colleagues}
              onClick={onEnterFieldsBlock('colleagues', canEdit)}
              onBlur={onLeaveFieldsBlock('colleagues', canEdit)}
              altContent={
                <>
                  <Ui.BasicElement.H2>Médecins</Ui.BasicElement.H2>
                  {canEdit
                    ? 'Cliquez ici pour Ajouter ou supprimer un confrère'
                    : `Aucun confrère n'est associé à ${fr[teleExpertiseName].thisExpertise}`
                  }
                </>
              }
              childRef={searchColleaguesRef}
              canEdit={canEdit}
            >
              <Ui.Layout.FocusDiv ref={searchColleaguesRef} tabIndex="166554" >
                <Ui.Form.InputContainer>
                  <Ui.SearchBar.SearchAndCheck
                    name="confreres"
                    query={search.colleagues.value}
                    TeleExpertise={TeleExpertise}
                    teleExpertiseName={teleExpertiseName}
                    checkboxDisabled={colleague => [
                      ...owner.value,
                      ...managers.value,
                      ...organizationMembers.value
                    ].includes(colleague.id)}
                    resultSet={members}
                    list={[
                      ...owner.value,
                      ...managers.value,
                      ...organizationMembers.value,
                      ...colleagues.value
                    ].filter((value, index, self) =>
                      self.indexOf(value) === index)
                    }
                    onToggle={onToggleColleague}
                    loading={resourceLoading.managers}
                    hasInviteColleagueButton
                    onClickExtraButton={e => dispatch(InvitationState.setIsInviting(true))}
                  />
                </Ui.Form.InputContainer>
              </Ui.Layout.FocusDiv>
            </Editable>
          </Ui.Layout.BlockContainer>
          <MembersByOrganizationList />
        </>
      : null
  )
}

const PatientFile = ({
  dispatch,
  current,
  data,
  forms,
  isEditFields,
  isEditMode,
  isBuilderFieldEdit,
  sections,
  fields,
  choices,
  errorMessage,
  hasError,
  teleExpertiseId,
  send,
  onEnterFieldsBlock,
  onLeaveFieldsBlock,
  canEdit,
}) => {
  const {
    labels,
    currentUserAdmin: isCurrentUserAdmin,
  } = data;
  const updateLabelContent = (name, idx) => e => {
    dispatch(TeleExpertise.updateLabelContent({ name, value: e.target.value, idx }))
  }

  const updateLabelColor = (name, idx) => e => {
    dispatch(TeleExpertise.updateLabelColor({ name, value: e.target.value, idx }))
  }

  const labelColors = (label) => ['gray', 'black', 'green', 'blue', 'purple', 'orange', 'red', 'yellow']
    .map(item => ({
      label: fr.colors[item]
        .replace(/^./, m => m.toUpperCase()),
      value: item,
      selected: item === label.color,
      color: item
    }))

  const labelRef = useRef();

  return (current === 4) &&
    <Ui.Layout.Section>
      <Ui.Layout.Form onSubmit={e => send(e, 'labels')} noValidate>
        <Ui.BasicElement.H2>
          Etiquettes
        </Ui.BasicElement.H2>
        <Ui.Layout.BlockContainer>
          <Editable
            content={labels.value.length > 0
              ? <Ui.Tag.TagsContainer>
                {labels.value.map((label, i) =>
                  <Ui.Tag.Tag
                    key={`label_${i}`}
                    bgColor={label.color}
                    disabled={!canEdit}
                  >
                    <span>{label.content}</span>
                  </Ui.Tag.Tag>
                )}
              </Ui.Tag.TagsContainer>
              : canEdit
                ? <Ui.Layout.FlexBox>Ajouter des étiquettes à {fr[teleExpertiseName].thisExpertise}</Ui.Layout.FlexBox>
                : <Ui.BasicElement.P>Aucune étiquette n'est associée à {fr[teleExpertiseName].thisExpertise}</Ui.BasicElement.P>
            }
            isEditMode={isEditMode.labels}
            onClick={onEnterFieldsBlock('labels', canEdit)}
            onBlur={labels.value.length > 0 ? onLeaveFieldsBlock('labels', true) : onLeaveFieldsBlock('labels')}
            childRef={labelRef}
            canEdit={canEdit}
          >
            <Ui.Layout.FocusDiv ref={labelRef} tabIndex="1785">
              <Styled.LabelsSection>
                {labels.value.map((label, idx, labels) =>
                  <Ui.Form.LabelsContainer key={`label_${idx}`}>
                    <Ui.Form.LabelContainer>
                      <Ui.Form.TextInput
                        htmlFor={`label_${idx}`}
                        value={label.content}
                        onChange={updateLabelContent('label', idx)}
                        noSubmit
                      />
                      <Ui.Select.Select
                        label="Couleur"
                        htmlFor="Couleur"
                        items={labelColors(label)}
                        onChange={updateLabelColor('label', idx)}
                      />
                    </Ui.Form.LabelContainer>
                    <Ui.Layout.ActionButtonsContainer>
                      <Ui.Button.ActionButton
                        $isGreen
                        onClick={(e) => {
                          dispatch(TeleExpertise.removeLabel({ idx, id: label.id, fieldName: 'labels' }))
                          labelRef?.current?.focus()
                        }}
                      >
                        <Ui.Icon.IconTrash />
                      </Ui.Button.ActionButton>
                    </Ui.Layout.ActionButtonsContainer>
                  </Ui.Form.LabelsContainer>
                )}
                <Styled.LabelButtonContainer>
                  <Ui.Button.ActionButton
                    $isGreen
                    onClick={(e) => {
                      dispatch(TeleExpertise.addLabel({ content: '', color: 'gray' }))
                      labelRef?.current?.focus()
                    }}
                    disabled={
                      labels.value.length !== 0 &&
                      _.lastElement(labels.value).content.length === 0}
                  >
                    <Ui.Icon.IconPlus />
                  </Ui.Button.ActionButton>
                  {labels.value.length > 0 &&
                    <Ui.Button.BasicButton
                      isGreen
                      isFilled
                      onClick={onLeaveFieldsBlock('labels', true)}
                    >
                      Valider
                    </Ui.Button.BasicButton>
                  }
                </Styled.LabelButtonContainer>
              </Styled.LabelsSection>
            </Ui.Layout.FocusDiv>
          </Editable>
        </Ui.Layout.BlockContainer>
      </Ui.Layout.Form>
      {isCurrentUserAdmin.value
        ? <Ui.CustomFieldBuilder
          form={forms[0]}
          teleExpertiseType='staffs'
          teleExpertiseId={teleExpertiseId}
          errorMessage={errorMessage}
          hasError={hasError}
          isBuildMode={true}
          isBuilderFieldEdit={isBuilderFieldEdit}
        />
        : <Ui.CustomFieldViewer
          data={{ sections, fields, choices }}
          forms={forms}
          isEditFields={isEditFields}
          isUseMode={true}
          isBuildMode={true}
        />
      }
    </Ui.Layout.Section >
}

const Scheduling = ({
  alert,
  current,
  data,
  teleExpertiseId,
  sessionIdParam,
  archivesByYear,
  send,
  permissions,
  DatePicker,
  savingSession,
  dispatch,
  isEditMode,
  updateSessionHourField,
  canEdit,
}) => {
  const {
    sessionsUpcoming,
    nextSession,
    archive,
    archives,
    recurrence,
  } = data;

  const [showVisioPublicLink, setShowVisioPublicLink] = useState(false)

  useEffect(() => {
    if (
      current === 3 &&
      sessionsUpcoming.value.length === 0
    ) {
      dispatch(TeleExpertise.setEditMode(true))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current, sessionsUpcoming.value])

  const onChangeSessionDate = (date, idx) => {
    dispatch(TeleExpertise.updateSessionDateField({ value: date, idx }))
  }
  const closeCurrentSession = () => {
    alert.show(`Voulez vous clôturer la séance en cours ?`, {
      onConfirm: (e) => {
        dispatch(TeleExpertise.closeCurrentSession({ teleExpertiseId }))
        e.stopPropagation()
      }
    })
  }
  const nextSessionDate = nextSession.value && dateToString(nextSession.value)

  const removeUpcomingSession = (idx, sessionUpcomingId) => e => {
    dispatch(TeleExpertise.removeUpcomingSession({ idx, sessionUpcomingId, teleExpertiseId }))
  }

  const onAddSession = () => {
    leaveOtherFieldsBlock()

    dispatch(TeleExpertise.addSessionDate())
  }

  const onEnterFieldsBlock = (sessionId, canEdit) => () => {
    leaveOtherFieldsBlock()

    return permissions?._canBeModified?.default || canEdit
      ? setEditFieldById(sessionId, true)
      : null
  }

  const leaveOtherFieldsBlock = () => {
    for (const idx in sessionsUpcoming.value) {
      if (sessionsUpcoming.value[idx].isFieldEdit) {
        if (sessionsUpcoming.value[idx].id)
          dispatch(TeleExpertise.updateSessionDate({ sessionUpcomingId: sessionsUpcoming.value[idx].id, idx }))
        else
          removeUpcomingSession(idx, sessionsUpcoming.value[idx].id)()
      }
    }
  }

  const openedSessionDateFields = sessionsUpcoming.value.some(session => session.isFieldEdit)

  const setEditFieldById = (sessionId, value) =>
    dispatch(TeleExpertise.setEditFieldById({ sessionId, isFieldEdit: value }))

  const refs = useRef();

  return (current === 3) && (
    <Styled.ProgramingSection>
      <Ui.Layout.Form onSubmit={e => send(e, 'staffSessionFields')} noValidate>
        {(data.publicVideoconferencingActive.value || data.publicVideoconferencingId.value) &&
          <Ui.Layout.BlockContainer>
            {!showVisioPublicLink
              ? <Ui.Button.BasicButton
                  onClick={() =>
                    alert.show(`Ce lien est public et ne doit être partagé qu'à des personnes de confiance. En passant par ce lien, le participant ne sera pas automatiquement indiqué comme étant présent dans le compte rendu. Voulez vous obtenir ce lien ?`, {
                      onConfirm: (e) => {
                        if (!data.publicVideoconferencingId.value) {
                          dispatch(TeleExpertise.fetchPublicVideoconferencing())
                        }
                        setShowVisioPublicLink(true)
                      }
                    })
                  }
                >
                  Obtenir le lien visioconférence
                </Ui.Button.BasicButton>
              : <>
                  <Ui.BasicElement.H2>
                    Lien visioconférence
                  </Ui.BasicElement.H2>
                  <Ui.Layout.FlexBox>
                    {`${window.location.origin}/videoconference/staff/${data.publicVideoconferencingId.value}`}
                  </Ui.Layout.FlexBox>
                </>
            }
          </Ui.Layout.BlockContainer>
        }
        {archive.value
          ? null
          : <>
            <Ui.BasicElement.H2>
              {['Prochaine', 'séance', 'programmée']
                .map(word => pluralize(word, sessionsUpcoming.value.length))
                .join(' ')}
            </Ui.BasicElement.H2>
            {canEdit
              ? <Ui.Select.Select
                label="Récurrence"
                htmlFor="recurrence"
                items={
                  ['none', 'daily', 'weekly', 'monthly', 'quarterly', 'annual']
                    .map(item => ({
                      label: fr.recurrence[item]
                        .replace(/^./, m => m.toUpperCase()),
                      value: item,
                      selected: item === recurrence.value,
                    }))
                }
                onChange={e => {
                  dispatch(TeleExpertise.updateData({
                    name: 'recurrence',
                    value: e.target.value,
                  }))
                  dispatch(TeleExpertise.saveField({ fieldName: 'recurrence' }))
                }}
              />
              : <>
                <Ui.BasicElement.P>
                  Récurrence : {recurrence.value ? fr.recurrence[recurrence.value] : 'Aucune'}
                </Ui.BasicElement.P>
              </>
            }
            <Styled.SessionDatesContainer>
              {sessionsUpcoming.value.length > 0
                ? sessionsUpcoming.value
                  .slice()
                  .sort((a, b) => (a.id === null) - (b.id === null) || a.date - b.date)
                  .map(({ id, dateString, date: nextSessionDate, hour: nextSessionHour, isFieldEdit }, idx) =>
                    <Editable
                      key={id || '0'}
                      title=""
                      content={
                        <Ui.Layout.FlexBox key={`date_${idx}`}>
                          {`Le ${dateString}${nextSessionHour && nextSessionHour !== '00:00' ? ` à ${nextSessionHour}` : ''}${idx === 0 ? ` (ouverte)` : ''}`}
                          {nextSessionDate && nextSessionDate.setHours(0, 0, 0, 0) === todayAtMidnight &&
                            <WithPermissions domains={['default']} permissions={permissions}>
                              <> - <Styled.CloseSessionLink onClick={e => { e.stopPropagation(); closeCurrentSession() }}>Clôturer</Styled.CloseSessionLink></>
                            </WithPermissions>
                          }
                        </Ui.Layout.FlexBox>
                      }
                      isEditMode={isFieldEdit}
                      onClick={openedSessionDateFields ? null : onEnterFieldsBlock(id)}
                      childRef={refs}
                      altContent="Aucune information n'a été renseignée."
                      canEdit={canEdit && !sessionsUpcoming.value.some(session => session.isFieldEdit)}
                      text
                    >
                      <Ui.Layout.FocusDiv ref={refs} tabIndex={idx + 35000}>
                        <WithPermissions domains={['default']} permissions={permissions}>
                          <React.Fragment key={`staff_session_${idx}`}>
                            <Styled.RowManualStaff>
                              <Ui.Layout.Column>
                                <Ui.BasicElement.WhiteBox onClick={e => e.stopPropagation()}>
                                  <Styled.DatePickerBox>
                                    <DatePicker
                                      value={(nextSessionDate >= todayAtMidnight)
                                        ? nextSessionDate
                                        : null
                                      }
                                      clearIcon={null}
                                      calendarIcon="&#xe907;"
                                      minDate={new Date()}
                                      format="dd/MM/yyyy"
                                      locale="fr-FR"
                                      showLeadingZeros
                                      onClick={e => e.stopPropagation()}
                                      onChange={date => onChangeSessionDate(date, idx)}
                                    />
                                  </Styled.DatePickerBox>
                                </Ui.BasicElement.WhiteBox>
                              </Ui.Layout.Column>
                              <Ui.Layout.Column>
                                <Styled.HourInput
                                  inputRef={refs}
                                  htmlFor="hour"
                                  value={nextSessionDate >= todayAtMidnight &&
                                    nextSessionHour !== '00:00'
                                    ? nextSessionHour
                                    : ''
                                  }
                                  onChange={e => updateSessionHourField(e, idx)}
                                  placeholder="HH:MM"
                                  disabled={
                                    !nextSessionDate || nextSessionDate < todayAtMidnight
                                  }
                                  readOnly={!isEditMode}
                                  maxLength="5"
                                  error={sessionsUpcoming.errors[idx] ? sessionsUpcoming.errors[idx].hour.join(' ; ') : ''}
                                  noSubmit
                                />
                              </Ui.Layout.Column>
                              <Ui.Layout.Column>
                                <Ui.Layout.ActionButtonsContainer>
                                  {idx !== 0
                                    ? <Ui.Button.ActionButton
                                      type="button"
                                      onClick={removeUpcomingSession(idx, id)}
                                    >
                                      <Ui.Icon.IconTrash />
                                    </Ui.Button.ActionButton>
                                    : null
                                  }
                                  {sessionsUpcoming.value.length !== 0 && sessionsUpcoming.value[0].date &&
                                    <Ui.Button.ActionButton
                                      type="button"
                                      $isFilled
                                      $isGreen
                                      disabled={savingSession}
                                      onClick={(e) => {
                                        e.preventDefault()
                                        dispatch(TeleExpertise.updateSessionDate({ sessionUpcomingId: id }))
                                      }}
                                    >
                                      <Ui.Icon.IconOk />
                                    </Ui.Button.ActionButton>
                                  }
                                </Ui.Layout.ActionButtonsContainer>
                              </Ui.Layout.Column>
                            </Styled.RowManualStaff>
                          </React.Fragment>
                        </WithPermissions>
                      </Ui.Layout.FocusDiv>
                    </Editable>
                  )
                : <Ui.BasicElement.P>Date à déterminer</Ui.BasicElement.P>
              }
            </Styled.SessionDatesContainer>
            <Ui.Layout.BlockContainer>
              {canEdit &&
                <Ui.Button.BasicButton
                  onClick={onAddSession}
                  disabled={
                    sessionsUpcoming.value.some(session => session.id === null || session.isFieldEdit)
                  }
                >
                  Ajouter une séance
                </Ui.Button.BasicButton>
              }
            </Ui.Layout.BlockContainer>
            <Ui.Layout.BlockContainer>
              {sessionIdParam &&
                <Styled.CurrentSessionLink to={`${privateSitePrefix}/${fr[teleExpertiseName].expertises}/${teleExpertiseId}?tab=1`}>
                  Séance {new Date(nextSession.value).getTime() >= todayAtMidnight
                    ? `du ${nextSessionDate}`
                    : 'à définir'
                  }
                </Styled.CurrentSessionLink>
              }
            </Ui.Layout.BlockContainer>
          </>
        }
        <Ui.BasicElement.H2>Archives</Ui.BasicElement.H2>
        <Styled.ArchiveList>
          {Object.keys(archives.value).length > 0
            ? archivesByYear
              .map(([year, archive], i) =>
                <div key={`archive_${year}_${i}`}>
                  <Ui.BasicElement.H2>
                    {year}
                  </Ui.BasicElement.H2>
                  {archive
                    .map((archive, j) => (
                      <li key={`archive_${i}${j}`}>
                        <Link to={`${privateSitePrefix}/${fr[teleExpertiseName].expertises}/${teleExpertiseId}/session/${archive.id}?tab=1`}>
                          Séance du {archive.endDate}
                        </Link>
                      </li>
                    ))
                    .reverse()}
                </div>
              )
            : <Ui.BasicElement.P>Aucune archive n'a été trouvée pour ce staff.</Ui.BasicElement.P>
          }
        </Styled.ArchiveList>
      </Ui.Layout.Form>
    </Styled.ProgramingSection>
  )
}

export default StaffComponent;
