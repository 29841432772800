import * as ContactUs from '../State/ContactUs'
import * as Form from '../Util/Form'
import * as Api from '../Util/Api'
import { takeLatest, call, put, select } from 'redux-saga/effects'

export function* send() {
  try {
    const data = yield select(ContactUs.selectData)
    const validatedData = yield call(Form.validate, data)

    yield call(Api.sendContactMessage, {
      from: validatedData.from.value,
      name: validatedData.name.value,
      subject: validatedData.subject.value,
      content: validatedData.content.value,
    })

    yield put(ContactUs.success())
  } catch (error) {
    if (error instanceof Form.ValidationError) {
      for (let [name, field] of Object.entries(error.data)) {
        yield put(ContactUs.updateField({
          name,
          value: field.value,
          errors: field.errors,
        }))
      }
      yield put(ContactUs.invalidate(
        'Une information obligatoire est manquante pour valider l\'envoi de votre message.'
      ))
    } else {
      yield put(ContactUs.apiError(error.message))
    }
  }
}

const ContactUsSagas = function* () {
  yield takeLatest(`${ContactUs.send}`, send);
};

export default ContactUsSagas;
