import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro'
import * as Ui from './Ui'

const CustomPageNotFoundComponent = () => {

  return (
    <PageWrapper>
      <Ui.PortailLayout.PageLayout fullWidth>
        <PageNotFoundContainer>
          <Ui.BasicElement.H1>Désolé, cette URL dédiée n'existe pas</Ui.BasicElement.H1>
          <Link to="/">
            Retourner vers la page d'accueil
          </Link>
        </PageNotFoundContainer>
      </Ui.PortailLayout.PageLayout>
    </PageWrapper>
  )
}

const PageWrapper = styled.div`
  height: 100vh;
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  ${Ui.PortailLayout.FlexPage} {
    @media only screen and (min-width: ${props => props.theme.device.desktop}) {
      flex: 1;
      align-items: center;
      ${Ui.PortailLayout.Content} {
        margin-left: 0;
      }
    }
  }
`

const PageNotFoundContainer = styled(props => <Ui.PortailLayout.PageContainer {...props} />)`
  margin: 1rem;
  margin-top: 2rem;
  flex: 1;
  text-align: center;
  a {
    color: ${props => props.theme.patientGreen};
  }
  @media only screen and (min-width: ${props => props.theme.device.desktop}) {
    margin-top: 3rem;
  }
  ${Ui.BasicElement.H1} {
    margin-bottom: 1rem;
  }

  ${Ui.BasicElement.Link} {
    font-size: .875rem;
    font-weight: 700;
  }
`

export default CustomPageNotFoundComponent;
