import { createSlice } from '@reduxjs/toolkit';
import uniqid from 'uniqid'
import { dateToString } from '../Util/Format'
import {
  saveField as commonSaveField,
  setEditField as commonSetEditField,
  updateInitialValue as commonUpdateInitialValue,
} from '../Util/InlineEditActions';
export const FREE_MODE = 'Libre'
export const PLANNING_MODE = 'Planning'

export const INITIAL_STATE = {
  data: {
    byId: {},
    allIds: [],
  },
  scheduledPatients: [

  ],
  planningDate: new Date(),
  planningHour: '07:00',
  planningPatientListVisible: false,
  selectedPatientId: null,
  sending: false,
  loading: false,
  pending: false,
  success: false,
  error: false,
  isEditMode: {
    workList: false,
  },
  errorMessage: null,
  allPatientsVisible: {
    [FREE_MODE]: false,
    [PLANNING_MODE]: false,
  },
  programingMode: PLANNING_MODE,
  mailSent: false,
}

const slice = createSlice({
  name: 'workList',
  initialState: INITIAL_STATE,
  reducers: {

    setAllPatientsVisible: (state, { payload: { worklistType, isVisible } }) => ({
      ...state,
      allPatientsVisible: {
        ...state.allPatientsVisible,
        [worklistType]: isVisible
      }
    }),

    updateField: (state, { payload: { worklistId, name, value, errors = [] } }) => ({
      ...state,
      data: {
        ...state.data,
        byId: {
          ...state.data.byId,
          [worklistId]: {
            ...state.data.byId[worklistId],
            [name]: {
              ...state.data.byId[worklistId][name],
              value
            }
          }
        }
      },
      error: false,
      errorMessage: null,
    }),

    setProgramingMode: (state, { payload }) => ({
      ...state,
      programingMode: payload
    }),

    updatePlanningDate: (state, { payload }) => ({
      ...state,
      planningDate: payload
    }),

    updatePatientList: (state, { payload: { opinionPatientPlanning, patientId, scheduledDate } }) => ({
      ...state,
      scheduledPatients: opinionPatientPlanning
    }),

    updateHour: (state, { payload }) => ({
      ...state,
      planningHour: payload
    }),

    updateSelectedPatient: (state, { payload: patientId }) => ({
      ...state,
      selectedPatientId: patientId
    }),

    updateIsVisibleField: (state, { payload: { worklistId, value } }) => ({
      ...state,
      data: {
        ...state.data,
        byId: {
          ...state.data.byId,
          [worklistId]: {
            ...state.data.byId[worklistId],
            isPatientListVisible: {
              ...state.data.byId[worklistId].isPatientListVisible,
              value
            }
          }
        },
      },
      error: false,
      errorMessage: null,
    }),

    addPatient: (state, { payload: { worklistId, value, patientId } }) => ({
      ...state,
      data: {
        ...state.data,
        byId: {
          ...state.data.byId,
          [worklistId]: {
            ...state.data.byId[worklistId],
            patients: {
              ...state.data.byId[worklistId].patients,
              value: [
                ...state.data.byId[worklistId].patients.value,
                patientId
              ]
            }
          }
        },
      },
      pending: true,
    }),

    selectPatient: (state, { payload: { selectedPatientId, mode } }) => ({
      ...state,
      selectedPatientId,
      mode
    }),

    schedulePatient: (state, { payload: { patient, planningDate, planningHour } }) => {
      planningDate.setHours(...planningHour.split(':'), 0)
      return ({
        ...state,
        scheduledPatients: [
          ...state.scheduledPatients,
          {
            patientId: patient.id,
            patientSex: patient.sex,
            patientFullname: patient.fullName,
            dateScheduled: `${dateToString(planningDate)} ${planningHour}`
          }
        ],
        pending: true
      })
    },

    sendMail: (state, { payload: { teleExpertiseId, patientPlanningId } }) => ({
      ...state,
      mailSent: false,
    }),

    setMailSent: (state, { payload }) => ({
      ...state,
      mailSent: payload,
    }),

    removePlanningPatient: (state, { payload: { patientPlanningId } }) => ({
      ...state,
      scheduledPatients: state.scheduledPatients
        .filter(patient => String(patient.patientPlanningId) !== String(patientPlanningId)),
      pending: true
    }),

    removePatient: (state, { payload: { worklistId, patientId } }) => ({
      ...state,
      data: {
        ...state.data,
        byId: {
          ...state.data.byId,
          [worklistId]: {
            ...state.data.byId[worklistId],
            patients: {
              ...state.data.byId[worklistId].patients,
              value: state.data.byId[worklistId].patients.value.filter(id => id !== patientId)
            }
          }
        },
      },
    }),

    addWorkList: (state, { payload: { teleExpertiseId } }) => {
      const newId = uniqid('WL_')

      return ({
        ...state,
        data: {
          ...state.data,
          byId: {
            ...state.data.byId,
            [newId]: {
              id: newId,
              title: { value: '', errors: [], validationRules: [] },
              patients: { value: [], errors: [], validationRules: [] },
              isPatientListVisible: { value: false, errors: [], validationRules: [] },
            },
          },
          allIds: [...state.data.allIds, newId]
        },
        pending: true
      })
    },

    removeWorkList: (state, { payload: { worklistId } }) => ({
      ...state,
      data: {
        ...state.data,
        allIds: state.data.allIds.filter(id => String(id) !== String(worklistId)),
        byId: Object.entries(state.data.byId)
          .filter(([id, _]) => id !== String(worklistId))
          .reduce((acc, [id, field]) => ({
            ...acc,
            [id]: field
          }), {}),
      }
    }),

    clearWorkList: (state, { payload: { worklistId } }) => ({
      ...state,
      data: {
        ...state.data,
        byId: {
          ...state.data.byId,
          [worklistId]: {
            ...state.data.byId[worklistId],
            patients: {
              ...state.data.byId[worklistId].patients,
              value: []
            }
          }
        }
      }
    }),

    updateWorkList: (state, { payload: { worklistId } }) => ({
      ...state,
    }),

    receivedWorkListId: (state, { payload: { worklistId, newWorklistId } }) => ({
      ...state,
      data: {
        ...state.data,
        allIds: state.data.allIds.map(id => String(worklistId) === String(id)
          ? newWorklistId
          : id
        ),
        byId: Object.entries(state.data.byId)
          .reduce((acc, [key, value]) =>
            key === worklistId
              ? { ...acc, [newWorklistId]: { ...value, id: newWorklistId } }
              : { ...acc, [key]: { ...value } }
            , {})
      }
    }),

    moveWorkList: (state, { payload: { newWorklists } }) => ({
      ...state,
      data: {
        ...state.data,
        allIds: newWorklists
      }
    }),

    movePatient: (state, { payload: { newWorklist }, patientId }) => ({
      ...state,
      data: {
        ...state.data,
        byId: {
          ...state.data.byId,
          [newWorklist.id]: newWorklist
        }
      }
    }),

    movePatientBetweenLists: (state, { payload: {
      source: { sourceId, sourceList },
      dest: { destId, destList }
    } }) => ({
      ...state,
      data: {
        ...state.data,
        byId: {
          ...state.data.byId,
          [sourceId]: {
            ...state.data.byId[sourceId],
            patients: {
              ...state.data.byId[sourceId].patients,
              value: sourceList
            }
          },
          [destId]: {
            ...state.data.byId[destId],
            patients: {
              ...state.data.byId[destId].patients,
              value: destList
            }
          }
        },
      }
    }),

    setEditWorkList: (state, { payload: { worklistId, isEditing } }) => ({
      ...state,
      data: {
        ...state.data,
        byId: {
          ...state.data.byId,
          [worklistId]: {
            ...state.data.byId[worklistId],
            title: {
              ...state.data.byId[worklistId].title,
              isFieldEdit: isEditing
            }
          }
        }
      }
    }),

    received: (state, { payload }) => ({
      ...state,
      data: payload,
      loading: false,
    }),

    receivedPatientPlannings: (state, { payload }) => ({
      ...state,
      scheduledPatients: payload,
    }),

    send: (state, { payload }) => ({
      ...state,
      sending: true,
    }),

    fetch: (state, action) => ({
      ...state,
      success: true,
    }),

    update: (state, action) => ({
      ...state,
      success: true,
    }),

    setPlanningPatientListVisible: (state, { payload }) => ({
      ...state,
      planningPatientListVisible: payload,
    }),

    success: (state, { payload }) => ({
      ...state,
      pending: false,
      sending: false,
      loading: false,
      error: false,
      success: true,
    }),

    invalidate: (state, { payload: { message } }) => ({
      ...state,
      pending: false,
      sending: false,
      error: true,
      errorMessage: message,
    }),

    submitField: (state, { payload }) => ({
      ...state,
    }),


    setEditMode: (state, { payload: { target, value } }) => ({
      ...state,
      isEditMode: {
        ...state.isEditMode,
        [target]: value,
      }
    }),

    restoreInitialValue: (state, { payload: { worklistId, value } }) => ({
      ...state,
      data: {
        ...state.data,
        byId: {
          ...state.data.byId,
          [worklistId]: {
            ...state.data.byId[worklistId],
            title: {
              ...state.data.byId[worklistId].title,
              value
            }
          }
        }
      }
    }),

    apiError: (state, action) => ({
      ...state,
      errorMessage: action.payload,
      pending: false,
      error: true,
      success: false,
      sending: false,
    }),

    clean: state => INITIAL_STATE,
    commonSaveField,
    commonSetEditField,
    commonUpdateInitialValue,
  },
})

export const {
  updateField,
  updateIsVisibleField,
  setAllPatientsVisible,
  addPatient,
  selectPatient,
  schedulePatient,
  removePatient,
  movePatient,
  movePatientBetweenLists,
  addWorkList,
  removeWorkList,
  clearWorkList,
  updateWorkList,
  moveWorkList,
  receivedWorkListId,
  clean,
  update,
  received,
  send,
  fetch,
  success,
  invalidate,
  apiError,
  updatePlanningDate,
  updateSelectedPatient,
  setProgramingMode,
  removePlanningPatient,
  setPlanningPatientListVisible,
  updateHour,
  receivedPatientPlannings,
  updatePatientList,
  sendMail,
  setMailSent,
  setEditMode,
  setEditWorkList,
  restoreInitialValue,
  commonSaveField: saveField,
  commonSetEditField: setEditField,
  commonUpdateInitialValue: updateInitialValue,
} = slice.actions

export const selectData = state => state.workList.data
export const selectLoading = state => state.workList.loading
export const selectSending = state => state.workList.sending
export const selectPending = state => state.workList.pending
export const selectSuccess = state => state.workList.success
export const selectAllPatientsVisible = state => state.workList.allPatientsVisible
export const selectScheduledPatients = state => state.workList.scheduledPatients
export const selectPlanningDate = state => state.workList.planningDate
export const selectPlanningHour = state => state.workList.planningHour
export const selectSelectedPatientId = state => state.workList.selectedPatientId
export const selectPatientEditMode = state => state.workList.mode === 'edit'
export const selectProgramingMode = state => state.workList.programingMode
export const selectPlanningPatientListVisible = state => state.workList.planningPatientListVisible
export const selectMailSent = state => state.workList.mailSent

export default slice.reducer
