import React from 'react';
import * as ButtonUi from './Button'
import * as IconUi from './Icon'

export const EditButtons = ({
  onEdit = () => { },
  onEditClose = () => { },
  onSubmit = null,
  onClose = null,
  disabled = false,
  disabledEdit = false,
  isEditMode = false,
  children,
  type = 'button',
  ...props
}) => {

  return <>
    {isEditMode && (onSubmit || type === 'submit') &&
      <ButtonUi.ActionButton
        $isFilled
        $isGreen
        type={type}
        onClick={onSubmit}
        disabled={disabled}
      >
        <IconUi.IconOk />
      </ButtonUi.ActionButton>
    }
    {isEditMode
      ? onClose &&
      <ButtonUi.ActionButton
        $isFilled
        $isGreen
        type="button"
        onClick={onClose}
        disabled={disabled}
      >
        <IconUi.IconClose />
      </ButtonUi.ActionButton>
      : <ButtonUi.ActionButton
        $isFilled
        $isGreen
        type="button"
        onClick={onEdit}
        disabled={disabled || disabledEdit}
      >
        <IconUi.IconEdit />
      </ButtonUi.ActionButton>
    }
  </>
}
