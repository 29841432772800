import { createSlice } from '@reduxjs/toolkit'

// @TODO replace by a correct value frodoctor.lastNamem the api
export const STATUS_ARCHIVED = 'archived'

export const DISPLAY_ALL = 'all'
export const DISPLAY_MINE = 'mine'
export const DISPLAY_ARCHIVED = 'archived'

export const STATUS_NEW_DEMAND = 'new demand'
export const STATUS_WAITING = 'waiting'
export const STATUS_DONE = 'done'

export const ORDER_BY_LATEST = 'updatedAt'
export const ORDER_BY_TITLE = 'name'

export const MODE_RECEIVED_MORE = 'received more'
export const MODE_RECEIVED_FIRST = 'received first'

export const INITIAL_STATE = {
  data: [],
  keywordsNotFound: [],
  hasMore: true,
  loading: false,
  fetching: false,
  errorMessage: null,
  pending: false,
  error: false,
  success: false,
  filters: {
    status: [
      { value: STATUS_NEW_DEMAND, checked: false },
      { value: STATUS_WAITING, checked: false },
      { value: STATUS_DONE, checked: false },
    ],
    orderBy: ORDER_BY_LATEST,
    displayBy: DISPLAY_MINE,
    search: '',
  },
  cohortCountAll: 0,
  cohortCount: { created: null, joined: null, archived: null },
}

const slice = createSlice({
  name: 'cohortList',
  initialState: INITIAL_STATE,
  reducers: {
    removeStatus: (state, { payload }) => ({
      ...state,
      filters: {
        ...state.filters,
        status: state
          .filters
          .status
          .map(element => element.value === payload
            ? ({ ...element, checked: false })
            : element
          ),
      },
    }),

    addStatus: (state, { payload }) => ({
      ...state,
      filters: {
        ...state.filters,
        status: state
          .filters
          .status
          .map(element => element.value === payload
            ? ({ ...element, checked: true })
            : element
          ),
      },
    }),

    changeDisplayBy: (state, { payload }) => ({
      ...state,
      filters: {
        ...state.filters,
        displayBy: payload,
        search: '',
      },
    }),

    changeOrderBy: (state, { payload }) => ({
      ...state,
      filters: {
        ...state.filters,
        orderBy: payload,
        search: '',
      },
    }),

    changeSearch: (state, { payload }) => ({
      ...state,
      filters: {
        ...state.filters,
        displayBy: DISPLAY_ALL,
        search: payload,
      },
    }),

    fetch: (state, { payload }) => ({
      ...state,
      fetching: true,
    }),

    received: (state, { payload }) => ({
      ...state,
      fetching: false,
      data: payload.mode === MODE_RECEIVED_FIRST
        ? payload.data
        : [...state.data, ...payload.data],
      hasMore: payload.mode === MODE_RECEIVED_FIRST
        ? payload.data.length < state.cohortCountAll
        : state.data.length + payload.data.length < state.cohortCountAll,
      keywordsNotFound: payload.keywordsNotFound,
    }),

    count: (state, { payload }) => ({
      ...state,
    }),

    receivedCount: (state, { payload }) => ({
      ...state,
      staffCount: payload
    }),

    receivedCountAll: (state, { payload }) => ({
      ...state,
      cohortCountAll: payload
    }),

    closeExpertise: (state, { payload: staffId }) => ({
      ...state,
      data: state.data
        .map(staff => staff.id === staffId ? { ...staff, archive: true } : staff),
    }),

    openExpertise: (state, { payload: staffId }) => ({
      ...state,
      data: state.data
        .map(staff => staff.id === staffId ? { ...staff, archive: false } : staff),
    }),

    apiError: (state, action) => ({
      ...state,
      errorMessage: action.payload,
      pending: false,
      error: true,
      success: false,
    }),

    clean: state => INITIAL_STATE,
  }
})

export default slice.reducer

export const {
  removeStatus,
  addStatus,
  changeDisplayBy,
  changeOrderBy,
  changeSearch,
  fetch,
  received,
  receivedCountAll,
  count,
  receivedCount,
  closeExpertise,
  openExpertise,
  apiError,
  clean,
} = slice.actions

export const selectExpertises = state => state.cohortList.data

export const selectKeywordsNotFound = state => state.cohortList.keywordsNotFound

export const selectFilters = state => state.cohortList.filters

export const selectHasMore = state => state.cohortList.hasMore

export const selectLoading = state => state.cohortList.loading

export const selectFetching = state => state.cohortList.fetching

export const selectCount = state => state.cohortList.staffCount

export const selectCountAll = state => state.cohortList.cohortCountAll
