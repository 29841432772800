import React from 'react';
import styled from 'styled-components/macro'
import * as Ui from '../Ui'

const CookiesContentComponent = ({ isLoggedIn }) => {

  return (
    <CookiesContentContainer>
      <Ui.BasicElement.P><i>Dernière mise à jour le 15/03/2023</i></Ui.BasicElement.P>
      <Ui.BasicElement.P>Des cookies sont utilisés sur la plateforme https://www.skemeet.io/ édité par la société SAS SKEZI au Capital social de 10 225,00 € immatriculée au RCS de Annecy sous le numéro d’identification unique B 892 223 603 dont le siège social est au 1 Esplanade Augustin Aussedat, Cran Gevrier, 74960 Annecy.</Ui.BasicElement.P>
      <Ui.BasicElement.P>Les cookies sont de petits fichiers placés sur vos équipements (ordinateur, smartphone ou tablette) lorsque vous naviguez sur Internet. Ils peuvent ensuite être lus par les émetteurs de cookies pour recueillir des informations stockées sur vos équipements.</Ui.BasicElement.P>
      <Ui.BasicElement.P>Les informations recueillies varient en fonction des finalités poursuivies lors de l’utilisation de ces cookies. Elles peuvent concerner vos équipements (par exemple, systèmes d’exploitation, versions de logiciels utilisés, types d’équipement, etc.) ou votre activité sur ces équipements (historique de navigation, actions réalisées sur Internet, etc.). </Ui.BasicElement.P>
      <Ui.BasicElement.P bold>1. Pourquoi des cookies sont utilisés sur la plateforme SKEMEET et quelles sont les informations recueillies ?</Ui.BasicElement.P>
      <Ui.BasicElement.P>Nous utilisons des cookies afin :</Ui.BasicElement.P>
      <Ui.BasicElement.Ul>
        <Ui.BasicElement.Li>de vous permettre de rester authentifié sur la plateforme ;</Ui.BasicElement.Li>
        <Ui.BasicElement.Li>d’empêcher tout utilisateur non autorisé à accéder à l’espace utilisateur de la plateforme ;</Ui.BasicElement.Li>
        <Ui.BasicElement.Li>de nous aider à détecter les comportements inappropriés (par exemple les tentatives répétés d’accès à des comptes) ;</Ui.BasicElement.Li>
        <Ui.BasicElement.Li>de détecter et prévenir les fraudes</Ui.BasicElement.Li>
        <Ui.BasicElement.Li>de garantir le fonctionnement du service de paiement des abonnements.</Ui.BasicElement.Li>
      </Ui.BasicElement.Ul>
      <Ui.BasicElement.P>Le traitement de ces informations est réalisé pour répondre à nos intérêts légitimes au sens de la réglementation sur la protection des données personnelles. Ces intérêts légitimes sont les suivants : nous permettre d’assurer le fonctionnement et la sécurité de notre plateforme. </Ui.BasicElement.P>
      <Ui.BasicElement.P bold>1. Quels sont les cookies utilisés, qui les gère et qui traite les informations qu’ils collectent ?</Ui.BasicElement.P>
      <Ui.BasicElement.P>Les cookies utilisés sont décrits de manière plus détaillée dans le tableau figurant ci-après. </Ui.BasicElement.P>
      <Ui.BasicElement.P bold>2. Où sont traitées les informations personnelles recueillies via ces cookies ?</Ui.BasicElement.P>
      <Ui.BasicElement.P>Elles sont traitées sur le territoire de l’Union européenne.</Ui.BasicElement.P>
      <Ui.BasicElement.P bold>3. Ces cookies et cette collecte d’informations sont-ils obligatoires ?</Ui.BasicElement.P>
      <Ui.BasicElement.P>Sans les cookies utilisés sur notre plateforme et les informations qu’ils collectent, certains de nos services et certaines fonctionnalités ne peuvent pas fonctionner normalement ou ne peuvent pas être fournis.</Ui.BasicElement.P>
      <Ui.BasicElement.P bold>4. Quelles sont mes options concernant l’utilisation de ces cookies ?</Ui.BasicElement.P>
      <Ui.BasicElement.P>Les cookies utilisés sur notre plateforme ont pour finalité exclusive de permettre ou faciliter la navigation sur notre plateforme ou sont strictement nécessaires à la fourniture de services sur votre demande expresse. </Ui.BasicElement.P>
      <Ui.BasicElement.P>L’utilisation de ces cookies est alors obligatoire et n’est pas soumise à votre consentement.</Ui.BasicElement.P>
      <Ui.BasicElement.P bold>5. Quels sont mes autres droits ?</Ui.BasicElement.P>
      <Ui.BasicElement.P>Si les conditions posées par la réglementation sont remplies, vous pouvez demander l’accès à vos informations personnelles, leur rectification ou leur effacement, demander la limitation du traitement de ces informations et définir des directives concernant le sort de vos informations personnelles après votre décès. Vous disposez aussi du droit de vous opposer à tout moment à certains de nos traitements. </Ui.BasicElement.P>
      <Ui.BasicElement.P>Sous réserve de respecter les conditions posées par la réglementation, vous pouvez exercer vos droits en écrivant à notre délégué à la protection des données à l’adresse postale suivante : DPO – SKEZI, Les Papeteries, 1 Esplanade Augustin Aussedat, Cran Gevrier, 74960 Annecy ou par mail <a href="mailto:dpo@skezi.eu">dpo@skezi.eu</a>.</Ui.BasicElement.P>
      <Ui.BasicElement.P>Enfin, vous disposez également du droit de saisir la CNIL.</Ui.BasicElement.P>
      <Ui.BasicElement.P>Toutefois, nous vous informons que nous ne serons pas nécessairement en mesure d’identifier toutes les informations vous concernant dans la mesure où nous ne collectons pas d’informations nominatives à l’aide des cookies. Dans ce cas, la réglementation nous autorise à répondre négativement à votre demande si vous ne pouvez pas nous fournir l’identifiant cookie correspondant à votre équipement.</Ui.BasicElement.P>
      <Ui.BasicElement.P bold>6. Pendant combien de temps les cookies seront installés ?</Ui.BasicElement.P>
      <Ui.BasicElement.P>La durée de vie de chaque cookie est précisée dans le tableau récapitulatif figurant ci-après. </Ui.BasicElement.P>
      <Ui.BasicElement.P>La durée de conservation des informations recueillies à l’aide de ces cookies peut être plus courte ou plus longue. Cette durée est précisée dans la <a href={isLoggedIn ? "?tab=3" : "/politique-de-confidentialite"}>politique de confidentialité</a>. </Ui.BasicElement.P>
      <Ui.BasicElement.P bold>7. Récapitulatif des cookies utilisés sur la plateforme</Ui.BasicElement.P>
      <Ui.BasicElement.Table>
        <tbody>
          <tr>
            <td><p>
              <i><b>Type de cookies</b></i></p>
            </td>
            <td><p>
              <i><b>Nom du cookie</b></i></p>
            </td>
            <td><p>
              <i><b>Emetteur du cookie</b></i></p>
            </td>
            <td><p>
              <i><b>Finalités</b></i></p>
            </td>
            <td><p>
              <span><i><b>Durée	de vie du cookie</b></i></span></p>
            </td>
            <td><p>
              <i><b>Moyens d’opposition	aux cookies</b></i></p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                Cookie de connexion
              </p>
            </td>
            <td><p>
              <i>Authorization</i></p>
            </td>
            <td><p>
              <i>Skemeet</i></p>
            </td>
            <td><p>
              <span>Pour la connexion sécurisée</span></p>
            </td>
            <td><p>
              <i>4 heures</i></p>
            </td>
            <td><p>
              <i>Paramétrage du	navigateur</i></p>
            </td>
          </tr>
          <tr>
            <td><p>Cookie de connexion</p>
            </td>
            <td>
              <p>secretHandle</p>
            </td>
            <td>
              <p><i>Skemeet</i></p>
            </td>
            <td>
              <p><span>Sert pour la connexion simplifiée sur un poste sécurisé</span></p>
            </td>
            <td><p><i>7 jours</i></p>
            </td>
            <td>
              <p><i>Paramétrage du navigateur</i></p>
            </td>
          </tr>
          <tr>
            <td><p>
              Cookie de session</p>
            </td>
            <td>
              <p>PHPSESSID</p>
            </td>
            <td>
              <p><br /></p>
              <p><i>Skemeet</i></p>
            </td>
            <td><p>
              <span>ID de session PHP pour l’authentification</span></p>
            </td>
            <td><p>
              <i>Session</i></p>
            </td>
            <td><p>
              <i>Paramétrage du	navigateur</i></p>
            </td>
          </tr>
          <tr>
            <td><p>Information</p>
            </td>
            <td><p>webrtcTest</p>
            </td>
            <td>
              <p><i>Skemeet</i></p>
            </td>
            <td><p>
              <span>Savoir si le navigateur de l’utilisateur est compatible WebRTC</span></p>
            </td>
            <td><p>
              <i>2 ans</i></p>
            </td>
            <td><p><i>Paramétrage du navigateur</i></p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Information</p>
            </td>
            <td>
              <p>i18next</p>
            </td>
            <td>
              <p><i>Skemeet</i></p>
            </td>
            <td>
              <p><span>Sert pour la selection de la langue</span></p>
            </td>
            <td><p><i>Session</i></p>
            </td>
            <td><p>
              <i>Paramétrage du navigateur</i></p>
            </td>
          </tr>
        </tbody>
      </Ui.BasicElement.Table>
      <Ui.BasicElement.P bold>8. Pour en savoir plus de façon générale sur les cookies et traceurs en ligne, sur les moyens de vous opposer à leur utilisation et sur vos droits.</Ui.BasicElement.P>
      <Ui.BasicElement.P>Nous vous invitons à consulter le site de la Commission Nationale de l’Informatique et des Libertés (CNIL), et notamment la rubrique intitulée « Maîtriser mes données » (<a href="https://www.cnil.fr/fr/maitriser-mes-donnees" rel="noopener noreferrer" target="_blank">https://www.cnil.fr/fr/maitriser-mes-donnees</a>) et celle consacrée aux cookies (<a href="https://www.cnil.fr/fr/cookies-et-autres-traceurs" rel="noopener noreferrer" target="_blank">https://www.cnil.fr/fr/cookies-et-autres-traceurs</a>). </Ui.BasicElement.P>
    </CookiesContentContainer>
  )
}

const CookiesContentContainer = styled(props => <Ui.PortailLayout.PageContainer {...props} />)`
  @media only screen and (min-width: ${props => props.theme.device.desktop}) {
    margin: 0;
    margin-right: 4rem;
  }
  a {
    color: ${props => props.theme.patientGreen};
  }

  ${Ui.Form.InputContainer}:last-of-type {
    margin-bottom: .2rem;
  }

  ${Ui.BasicElement.H1} {
    margin-top: 3rem;
  }

  ${Ui.BasicElement.H2} {
    margin-top: 2rem;
    font-size: 1rem;
  }

  ${Ui.BasicElement.P} {
    margin: .5rem 0;
  }

  ${Ui.BasicElement.Link} {
   font-size: .875rem;
    font-weight: 700;
  }
`

export default CookiesContentComponent;
