import * as Opinion from '../State/Opinion'
import * as Resources from '../State/Resources'
import * as Login from '../State/Login'
import * as Organization from '../State/Organization'
import * as OpinionList from '../State/OpinionList'
import * as WorkList from '../State/WorkList'
import * as CustomFieldEffect from '../Effect/CustomField'
import * as Eff from 'redux-saga/effects'
import * as Api from '../Util/Api'
import * as UploadFile from '../State/UploadFile'
import * as Form from '../Util/Form'
import { fr } from '../Util/i18n';
import { uniqById } from '../Util/Object'
import { debounceTime } from '../Util/Date'

const expertiseType = 'opinion'

export function* saveField({ payload: { fieldName, scope } }) {
  try {
    const data = yield Eff.select(Opinion.selectData)
    const opinionId = yield Eff.select(Opinion.selectId)
    const fieldData = data[fieldName]
    const validatedData = yield Eff.call(Form.validate, { [fieldName]: fieldData })
    const apiFields = {
      portalHeaderImage: 'portalHeaderImageName',
      portalAbout: 'portalDescription'
    }

    if (!validatedData[fieldName].errors.length > 0) {
      const apiData = {
        opinion: fieldName === 'tags'
          ? {
            tags: validatedData.tags.value.filter(value => value),
          }
          : {
            [apiFields[fieldName] || fieldName]: validatedData[fieldName].value,
          }
      }

      const {
        labels,
        tags,
      } = yield Eff.call(Api.updateExpertise, apiData, opinionId, expertiseType)

      if (labels)
        yield Eff.put(Opinion.received({ labels }))
      else if (tags)
        yield Eff.put(Opinion.received({ tags }))

      yield Eff.put(Opinion.setEditField({ field: fieldName, value: false }))
      yield Eff.put(Opinion.updateInitialValue({ field: fieldName, value: fieldData.value }))
    }
    yield Eff.put(Opinion.success(opinionId))
  } catch (error) {
    console.log(error)
    if (error instanceof Form.ValidationError) {
      for (let [name, field] of Object.entries(error.data)) {
        yield Eff.put(Opinion.updateField({
          name,
          value: field.value,
          errors: field.errors,
        }))
      }
      yield Eff.put(Opinion.invalidate({
        message:
          'Une information obligatoire est manquante pour valider la mise à jour de vos données.'
      }
      ))
    } else {
      yield Eff.put(Opinion.apiError(error.message))
    }
  }
}

export function* send() {
  try {
    const data = yield Eff.select(Opinion.selectData)
    const fileData = yield Eff.select(UploadFile.selectData)
    const opinionPicture = fileData['opinion-picture']

    yield Eff.call(Form.validate, { ...data })

    const expertise = yield Eff.call(Api.createExpertise, {
      [expertiseType]: {
        name: data.name.value,
        description: data.description.value,
        tags: data.tags.value,
        disciplines: data.disciplines.value,
        pictureFileName: Object
          .values(opinionPicture.value)
          .map(doc => (doc.fileName))
          .shift() || '',
        publicUri: data.publicUri.value,
      },
      labels: data
        .labels
        .value
        .filter(l => l.content !== ''),
    }, expertiseType)

    yield Eff.put(Opinion.success(expertise.id))
  } catch (error) {
    console.log(error)
    if (error instanceof Form.ValidationError) {
      if (Array.isArray(error.data.errors)) {
        yield Eff.put(Opinion.invalidate({
          message: 'Format d\'un champs invalide pour l\'enregistrement des données.'
        }))
        // yield Eff.put(Opinion.setErrors({
        //   name: error.data.name,
        //   errors: error.data.errors,
        // }))
      } else {
        const errorData =
          Object.entries(error.data)
            .filter(([_, data]) => data.errors.length > 0)
            .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {})

        for (let [name, field] of Object.entries(errorData)) {
          yield Eff.put(Opinion.updateData({
            name,
            value: field.value,
            errors: field.errors,
          }))
        }
        yield Eff.put(Opinion.invalidate({
          message: `Une information obligatoire est manquante pour valider la création de votre ${fr[expertiseType].expertise}.`
        }
        ))
      }
    } else {
      yield Eff.put(Opinion.apiError(error.message))
    }
  }
}

export function* removePortalDocument({ payload: portalDocumentId }) {
  try {
    yield Eff.call(Api.removePortalDocument, portalDocumentId)

  } catch (error) {
    yield Eff.put(Opinion.apiError(error.message))
  }
}

export function* update({ payload: { teleExpertiseId: opinionId, teleExpertiseName: expertiseType, selectedField } }) {
  try {
    const data = yield Eff.select(Opinion.selectData)

    let fields;
    if (selectedField === 'labels') {
      fields = {
        labels: data.labels.value
      }

      const labels = yield Eff.call(Api.updateLabels, opinionId, expertiseType, fields)
      yield Eff.put(Opinion.received({ labels }))
    } else {
      const fileData = yield Eff.select(UploadFile.selectData)
      const headingDocuments = fileData['heading-documents']
      const reportImageFileName = headingDocuments &&
        Object
          .values(headingDocuments.value)
          .map(doc => (doc.fileName))
          .shift()
      const opinionPicture = fileData['opinion-picture']
      const pictureFileName = opinionPicture &&
        Object
          .values(opinionPicture.value)
          .map(doc => (doc.fileName))
          .shift()
      const portalHeaderImage = fileData['portal-header-image']
      const portalHeaderImageFileName = portalHeaderImage &&
        Object
          .values(portalHeaderImage.value)
          .map(doc => (doc.fileName))
          .shift()

      yield Eff.call(Form.validate, data)

      yield Eff.put(Opinion.updateImage({ imageType: 'pictureFileName', imageFileName: pictureFileName }))
      if (reportImageFileName) {
        yield Eff.put(Opinion.updateImage({ imageType: 'reportImageFileName', imageFileName: reportImageFileName }))
      }

      yield Eff.put(Opinion.updateImage({ imageType: 'portalHeaderImage', imageFileName: portalHeaderImageFileName }))

      fields = {
        [expertiseType]: {
          name: data.name.value,
          description: data.description.value,
          tags: data.tags.value.filter(e => e),
          disciplines: data.disciplines.value,
          managers: data.managers.value,
          members: data.colleagues.value,
          pictureFileName: pictureFileName || '',
          publicUri: data.publicUri.value,
        },
        organizations: data.organizations.value,
        createMultiCustomFields: {},
      }

      yield Eff.call(Api.updateExpertise, fields, opinionId, expertiseType)
      yield Eff.put(Opinion.received({ tags: data.tags.value.filter(e => e) }))
    }

    yield Eff.put(Opinion.setEditMode(false))
    yield Eff.put(Opinion.success(opinionId))
  } catch (error) {
    if (error instanceof Form.ValidationError) {
      const errorData =
        Object.entries(error.data)
          .filter(([key, data]) => data.errors.length > 0)
          .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {})

      for (let [name, field] of Object.entries(errorData)) {
        yield Eff.put(Opinion.updateData({
          name,
          value: field.value,
          errors: field.errors,
        }))
      }
      yield Eff.put(Opinion.invalidate({
        message: `Une information obligatoire est manquante pour valider la création de votre ${fr[expertiseType].expertise}.`
      }
      ))
    } else {
      yield Eff.put(Opinion.apiError(error.message))
    }
  }
}

export function* setWorkLists(patientListsBySort) {
  const worklists = Object.values(patientListsBySort)
    .reduce((acc, { id, patientsBySort: patients, title }) => ({
      ...acc,
      byId: {
        ...acc.byId,
        [id]: {
          id,
          title: { value: title, initialValue: title, isFieldEdit: false, errors: [], validationRules: [] },
          patients: { value: Object.values(patients), errors: [], validationRules: [] },
          isPatientListVisible: { value: false, errors: [], validationRules: [] },
        }
      },
      allIds: [...acc.allIds, id]
    }), { byId: {}, allIds: [] })

  yield Eff.put(WorkList.received(worklists))
}

export function* fetch({ payload: { teleExpertiseIdParam, sessionIdParam } }) {
  try {
    const {
      patients,
      patientPlannings = [],
      customFields,
      isOutdated,
      id: opinionId,
      name,
      description,
      ownerFullname,
      updatedAt,
      createdAt,
      owner: {
        title: ownerTitle,
        speciality: ownerSpeciality,
        city: ownerCity,
        id: ownerId,
      },
      portal,
      owner,
      managers,
      organizations,
      members: colleagues,
      reportImageFileName = '',
      pictureFileName = '',
      publicUri = '',
      tags,
      disciplines,
      labels,
      archive,
      thePersonal,
      currentUserAdmin,
      currentUserMember,
      patientLimitLocked,
      patientLimitReached,
      numberAddPatientAction,
      portalHeaderImageAlign,
      portalHeaderImageStretch,
      portalHeaderImageName: portalHeaderImage,
      patientListsBySort = {},
      _canBe,
      _canBeModified,
    } = yield Eff.call(Api.fetchTeleExpertise, teleExpertiseIdParam, sessionIdParam, expertiseType)
    const organizationMembers = Object
      .values(organizations)
      .reduce((acc, { members, managers }) => [
        ...acc,
        [...members, ...managers]
      ], [])
      .reduce((acc, x) => acc.concat(x), []);

    yield Eff.put(Resources.received({
      name: [Resources.RESOURCE_MEMBERS],
      data: [owner, ...managers, ...colleagues, ...organizationMembers].filter(uniqById),
    }))

    yield Eff.put(Resources.received({
      name: [Resources.RESOURCE_DISCIPLINES],
      data: disciplines,
    }))

    yield Eff.put(Resources.received({
      name: [Resources.RESOURCE_PATIENTS],
      data: patients,
    }))

    yield Eff.put(Resources.received({
      name: [Resources.RESOURCE_ORGANIZATIONS],
      data: organizations,
    }))

    yield Eff.put(Opinion.receivedOrganizationMembers(organizationMembers.map(e => e.id)))

    yield getExpertisesWithComments(opinionId, expertiseType)

    yield Eff.call(CustomFieldEffect.customFieldsFromExpertise, null, [], labels, {
      opinions: [{
        patients,
        customFields,
        isOutdated,
        id: opinionId,
        name,
        description,
        ownerFullname,
        ownerTitle,
        ownerSpeciality,
        ownerCity,
        updatedAt,
        createdAt,
        owner,
        managers,
        members: colleagues,
        tags,
        disciplines,
        labels,
        archive,
      }]
    },
      true)

    yield Eff.put(Opinion.received({
      name,
      description,
      ownerFullname,
      archive,
      thePersonal,
      createdAt,
      updatedAt,
      profilePicture: owner.profilePicture,
      ownerTitle,
      ownerSpeciality,
      ownerCity,
      owner: [owner.id],
      managers: managers.map(e => e.id),
      colleagues: colleagues.map(e => e.id),
      tags,
      ownerId,
      disciplines,
      reportImageFileName,
      pictureFileName,
      publicUri,
      labels,
      organizations: organizations.map(e => e.id),
      patientsLocked: patients.filter(patient => patient.pendingPatient).map(patient => patient.id),
      patients: patients.map(e => e.id),
      isOutdated,
      currentUserAdmin,
      currentUserMember,
      patientLimitLocked,
      patientLimitReached,
      numberAddPatientAction,
      portalHeaderImageAlign,
      portalHeaderImageStretch,
      portalHeaderImage,
      portalDocuments: portal.attachements || [],
      portalAbout: portal.description || '',
      permissions: { _canBe, _canBeModified }
    }))

    yield setWorkLists(patientListsBySort)
    yield Eff.put(WorkList.receivedPatientPlannings(Object.values(patientPlannings)))

  } catch (error) {
    console.log(error)
    yield Eff.put(Opinion.apiError(error.message))
  }
}

export function* addColleague({ payload: { id: colleagueId, teleExpertiseId: teleExpertiseParamId, noEffect = false } }) {
  try {
    if (!noEffect) {
      const teleExpertiseId = teleExpertiseParamId
        ? teleExpertiseParamId
        : yield Eff.select(Opinion.selectId)

      yield Eff.call(Api.addUser, teleExpertiseId, colleagueId, 'opinions')
      // TODO : need update colleague list from response ?
      // const { members: colleagues } = yield Eff.call(Api.addUser, teleExpertiseId, colleagueId, 'opinions')
      // yield Eff.put(Opinion.updateColleagues(colleagues))
      yield Eff.put(Opinion.setEditMode({ target: 'colleagues', value: false }))
    }
  } catch (error) {
    yield Eff.put(Opinion.apiError(error.message))
  }
}

export function* removeColleague({ payload: { id: colleagueId } }) {
  try {
    const teleExpertiseId = yield Eff.select(Opinion.selectId)
    yield Eff.call(Api.removeUser, teleExpertiseId, colleagueId, 'opinions')
    // TODO : need update colleague list from response ?
    // const { members: colleagues } = yield Eff.call(Api.removeUser, teleExpertiseId, colleagueId, 'opinions')
    // yield Eff.put(Opinion.updateColleagues(colleagues))
    yield Eff.put(Opinion.setEditMode(false))
  } catch (error) {
    yield Eff.put(Opinion.apiError(error.message))
  }
}

export function* addOrganization({ payload: { id: organizationId, teleExpertiseId: teleExpertiseParamId } }) {
  try {
    const teleExpertiseId = yield Eff.select(Opinion.selectId)

    const { organizations } = yield Eff.call(Api.updateOrganization, organizationId, 'add', 'opinion', teleExpertiseId)

    const organizationMembers = Object
      .values(organizations)
      .reduce((acc, { members, managers }) => [
        ...acc,
        [...members, ...managers]
      ], [])
      .reduce((acc, val) => acc.concat(val), [])

    yield Eff.put(Resources.received({
      name: [Resources.RESOURCE_MEMBERS],
      data: [...organizationMembers].filter(uniqById),
    }))

    yield Eff.put(Opinion.setQuery({ name: 'organizations', value: '' }))
    yield Eff.put(Opinion.receivedOrganizationMembers(organizationMembers.map(e => e.id)))
    yield Eff.put(Opinion.setEditMode({ target: 'organizations', value: false }))
  } catch (error) {
    yield Eff.put(Opinion.apiError(error.message))
  }
}

export function* removeOrganization({ payload: { id: organizationId, teleExpertiseId: teleExpertiseParamId } }) {
  try {
    const teleExpertiseId = yield Eff.select(Opinion.selectId)

    const { organizations } = yield Eff.call(Api.updateOrganization, organizationId, 'remove', 'opinion', teleExpertiseId)
    const organizationMembers = Object
      .values(organizations)
      .reduce((acc, { members, managers }) => [
        ...acc,
        [...members, ...managers]
      ], [])
      .reduce((acc, val) => acc.concat(val), [])

    yield Eff.put(Opinion.setQuery({ name: 'organizations', value: '' }))
    yield Eff.put(Opinion.received({ organizationMembers: organizationMembers.map(e => e.id) }))
    yield Eff.put(Opinion.setEditMode(false))
  } catch (error) {
    yield Eff.put(Opinion.apiError(error.message))
  }
}

export function* addOrganizationMembers({ payload: id }) {
  try {
    const { organizations } = yield Eff.select(Resources.selectResources)
    const organization = organizations.find(org => org.id === id)

    yield Eff.put(Resources.received({
      name: [Resources.RESOURCE_ORGANIZATIONS],
      data: [organization],
    }))

    const {
      id: organizationId,
      name,
      logo,
      members = [],
      managers = [],
    } = organization

    yield Eff.put(Resources.received({
      name: [Resources.RESOURCE_MEMBERS],
      data: [...members, ...managers].filter(uniqById),
    }))

    yield Eff.put(Organization.received({
      organizationId,
      name,
      logo,
      members: members.map(e => e.id),
      managers: managers.map(e => e.id),
    }))

    yield Eff.put(Opinion.receivedOrganizationMembersById({
      organizationId,
      members: [...members, ...managers].map(e => e.id)
    }))
  } catch (error) {
    console.log(error)
    yield Eff.put(Opinion.apiError(error.message))
  }
}

export function* linkPatient({ payload: expertiseId }) {
  try {
    const patients = yield Eff.select(Opinion.selectPatients)
    yield Eff.call(Api.linkPatient, expertiseId, patients[patients.length - 1], expertiseType)
    yield Eff.put(Opinion.setEditMode(false))
  } catch (error) {
    yield Eff.put(Opinion.apiError(error.message))
  }
}

export function* addPatient({ payload: { id: patientId, teleExpertiseId } }) {
  try {
    const isPatientLimitReached = yield Eff.select(Opinion.selectIsPatientLimitReached)
    if (isPatientLimitReached) {
      yield Eff.put(Opinion.updatePatientsLocked(patientId))
    }
    const {
      numberAddPatientAction,
      patientLimitReached: isLimitReachAfterAdd
    } = yield Eff.call(Api.linkPatient, teleExpertiseId, patientId, expertiseType)
    yield getExpertisesWithComments(teleExpertiseId, expertiseType)
    yield Eff.put(Opinion.setEditMode({ target: 'addPatient', value: false }))
    yield Eff.put(Opinion.received({
      numberAddPatientAction,
      patientLimitReached: isLimitReachAfterAdd
    }))
    yield Eff.put(Opinion.success(teleExpertiseId))
  } catch (error) {
    yield Eff.put(Opinion.apiError(error.message))
  }
}

export function* removePatient({ payload: { id, teleExpertiseId } }) {
  try {
    const { patientListsBySort } = yield Eff.call(Api.unlinkPatient, teleExpertiseId, id, expertiseType)
    yield getExpertisesWithComments(teleExpertiseId, expertiseType)
    yield setWorkLists(patientListsBySort)
    yield Eff.put(Opinion.setEditMode({ target: 'addPatient', value: false }))
    yield Eff.put(Opinion.success(teleExpertiseId))
  } catch (error) {
    yield Eff.put(Opinion.apiError(error.message))
  }
}

export function* closeExpertise({ payload: teleExpertiseId }) {
  try {
    yield Eff.call(Api.closeExpertise, teleExpertiseId, expertiseType)
  } catch (error) {
    yield Eff.put(Opinion.apiError(error.message))
  }
}

export function* openExpertise({ payload: teleExpertiseId }) {
  try {
    yield Eff.call(Api.openExpertise, teleExpertiseId, expertiseType)
  } catch (error) {
    yield Eff.put(Opinion.apiError(error.message))
  }
}

export function* removeLabel({ payload: { id } }) {
  try {
    if (id) {
      const opinionId = yield Eff.select(Opinion.selectId)
      if (opinionId) {
        yield Eff.call(Api.deleteLabel, opinionId, id, expertiseType)
      }
    }
  } catch (error) {
    yield Eff.put(Opinion.apiError(error.message))
  }
}

export function* getExpertisesWithComments(sessionId) {
  try {
    const expertisesWithComments = yield Eff.call(Api.getExpertisesWithComments, sessionId, expertiseType)

    yield Eff.put(Opinion.receivedExpertisesWithComments(expertisesWithComments))
  } catch (error) {
    yield Eff.put(Opinion.apiError(error.message))
  }
}

export function* updateOwner({ payload: { colleague: { colleagueId } } }) {
  try {
    const teleExpertiseId = yield Eff.select(Opinion.selectId)

    yield Eff.call(Api.updateOwner, colleagueId, teleExpertiseId, expertiseType)
    yield Eff.put(Opinion.success(teleExpertiseId))
  } catch (error) {
    yield Eff.put(Opinion.apiError(error.message))
  }
}

export function* addManager({ payload: { id: managerId } }) {
  try {
    const teleExpertiseId = yield Eff.select(Opinion.selectId)

    yield Eff.call(Api.addManager, managerId, teleExpertiseId, expertiseType)
    yield Eff.put(Opinion.setEditMode({ target: 'managers', value: false }))

  } catch (error) {
    yield Eff.put(Opinion.apiError(error.message))
  }
}

export function* removeManager({ payload: { id: managerId } }) {
  try {
    const teleExpertiseId = yield Eff.select(Opinion.selectId)

    yield Eff.call(Api.removeManager, managerId, teleExpertiseId, expertiseType)
    yield Eff.put(Opinion.setEditMode({ target: 'managers', value: false }))

  } catch (error) {
    yield Eff.put(Opinion.apiError(error.message))
  }
}

export function* setQuery({ payload: { name, value: query } }) {
  try {
    if (query) {
      const delayMs = debounceTime(query)

      yield Eff.delay(delayMs)

      if (name === 'disciplines') {
        yield Eff.put(Resources.load({
          filter: [Resources.RESOURCE_DISCIPLINES],
          query,
        }))
      } else if (name === 'managers' || name === 'colleagues') {
        yield Eff.put(Resources.load({
          filter: [Resources.RESOURCE_MEMBERS],
          query,
        }))
      } else if (name === 'patients') {
        yield Eff.put(Resources.load({
          filter: [Resources.RESOURCE_PATIENTS],
          query,
        }))
      } else if (name === 'organizations') {
        yield Eff.put(Resources.load({
          filter: [Resources.RESOURCE_ORGANIZATIONS],
          query,
        }))
      }
    }
  } catch (error) {
    yield Eff.put(Opinion.apiError(error.message))
  }
}

export function* suggestSpecialities() {
  try {
    const user = yield Eff.select(Login.selectUser)

    if (user.speciality) {
      yield Eff.put(Resources.load({
        filter: [Resources.RESOURCE_DISCIPLINES],
        query: user.speciality,
      }))
      yield Eff.put(Resources.received({
        name: [Resources.RESOURCE_DISCIPLINES],
        data: [user.speciality],
      }))
      yield Eff.put(Opinion.addDiscipline(user.speciality))
    }
  } catch (error) {
    console.log(error)
    yield Eff.put(Opinion.apiError(error.message))
  }
}

export function* fetchOpinionFromCode({ payload: publicUri }) {
  try {
    const { opinionId } = yield Eff.call(Api.fetchTeleExpertiseFromCode, 'opinion', publicUri)
    yield Eff.put(Opinion.updateField({
      name: 'id',
      value: opinionId,
    }))
    yield Eff.put(Opinion.updateField({
      name: 'patientCodeUri',
      value: opinionId,
    }))
    yield Eff.put(Opinion.setOpinionUri({ publicUri, value: true }))

  } catch (error) {
    yield Eff.put(Opinion.apiError(error.message))
  }
}

export function* toggle({ payload: { id, toggled, name } }) {
  try {
    const data = yield Eff.select(Opinion.selectData)
    const teleExpertiseId = yield Eff.select(Opinion.selectId)
    if (name === "disciplines") {
      if (teleExpertiseId) {
        const fields = { opinion: { disciplines: data.disciplines.value } }
        yield Eff.call(Api.updateExpertise, fields, teleExpertiseId, 'opinion')
      }
    }
    yield Eff.put(Opinion.setEditMode({ target: 'disciplines', value: false }))

  } catch (error) {
    console.log(error)
    yield Eff.put(Opinion.apiError(error.message))
  }
}

const OpinionSagas = function* () {
  yield Eff.takeLatest(`${Opinion.fetch}`, fetch)
  yield Eff.takeLatest(`${Opinion.send}`, send)
  yield Eff.takeLatest(`${Opinion.update}`, update)
  yield Eff.takeLatest(`${Opinion.toggle}`, toggle)
  yield Eff.takeLatest(`${Opinion.suggestSpecialities}`, suggestSpecialities)
  yield Eff.takeLatest(`${Opinion.updatePatients}`, linkPatient)
  yield Eff.takeLatest([
    `${Opinion.closeExpertise}`,
    `${OpinionList.closeExpertise}`,
  ], closeExpertise)
  yield Eff.takeLatest([
    `${Opinion.openExpertise}`,
    `${OpinionList.openExpertise}`,
  ], openExpertise)
  yield Eff.takeLatest(`${Opinion.addPatient}`, addPatient)
  yield Eff.takeLatest(`${Opinion.removePatient}`, removePatient)
  yield Eff.takeLatest(`${Opinion.removeLabel}`, removeLabel)
  yield Eff.takeLatest(`${Opinion.setQuery}`, setQuery)
  yield Eff.takeLatest(`${Opinion.updateOwner}`, updateOwner)
  yield Eff.takeLatest(`${Opinion.addManager}`, addManager)
  yield Eff.takeLatest(`${Opinion.removeManager}`, removeManager)
  yield Eff.takeLatest(`${Opinion.addColleague}`, addColleague)
  yield Eff.takeLatest(`${Opinion.removeColleague}`, removeColleague)
  yield Eff.takeLatest(`${Opinion.addOrganization}`, addOrganization)
  yield Eff.takeLatest(`${Opinion.removeOrganization}`, removeOrganization)
  yield Eff.takeLatest(`${Opinion.addOrganizationMembers}`, addOrganizationMembers)
  yield Eff.takeLatest(`${Opinion.fetchOpinionFromCode}`, fetchOpinionFromCode)
  yield Eff.takeEvery(`${Opinion.saveField}`, saveField)
  yield Eff.takeEvery(`${Opinion.removePortalDocument}`, removePortalDocument)
}

export default OpinionSagas;
