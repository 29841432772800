import React, { useState } from 'react'
import styled, { css } from 'styled-components/macro'
import * as Ui from '.'

const SearchSelectComponent = ({
  label = '',
  query = '',
  loading = false,
  datas = [],
  error = '',
  pending,
  itemLabel = () => { },
  itemSubLabel = null,
  onQueryChange = () => null,
  onSelectData = () => null,
  notFoundMessage = '',
  placeholder = '',
  onBlur = () => {},
  isPlain = false,
  onRestoreValue,
  onKeyDown,
  noSubmit,
  inputRef,
}) => {

  const [isOpened, setIsOpened] = useState(false)
  const selectData = data => e => {
    e.stopPropagation()
    onSelectData(data)
    onBlur(e)
    setIsOpened(false)
  }

  const onSetQuery = e => {
    onQueryChange(e.target.value)
  }

  const setOpen = () => {
    setIsOpened(true)
  }

  return (
    <SearchSelectContainer
      isPlain={isPlain}
      hasFocus={isOpened && query}
    >
      <Ui.Form.TextInput
        noSubmit={noSubmit}
        name="speciality"
        label={label}
        value={query}
        onChange={onSetQuery}
        onFocus={e => setOpen(true)}
        onKeyDown={onKeyDown}
        isPlain={isPlain}
        textInputType="selectAndSelect"
        placeholder={placeholder}
        hasFocus={isOpened}
        $loading={loading}
        onRestoreValue={onRestoreValue}
        onBlur={e => onBlur(e)}
        inputRef={inputRef}
        tabIndex="790870987"
        error={error}
      />
      {query
        ? <SearchDropdownContainer
          isPlain={isPlain}
          visible={isOpened}
          onClick={e => e.preventDefault()}
          $loading={loading}>
          {loading
            ? <Ui.Global.Loader />
            : datas.length > 0
              ? datas
                .map((data, i) =>
                  <DropdownItem
                    key={`dropdownItem_${i}`}
                    onClick={selectData(data)}
                    isBoldLabel={itemSubLabel}
                  >
                    <div>{itemLabel(data)}</div>
                    <div>{itemSubLabel && itemSubLabel(data)}</div>
                  </DropdownItem>
                )
              : <p>{notFoundMessage}</p>
          }
        </SearchDropdownContainer>
        : null
      }
    </SearchSelectContainer>
  )
}

export const SearchSelectContainer = styled.div`
  position: relative;
  ${Ui.Form.Input} {
    position: relative;
    z-index: 5;
    ${props => props.isPlain
    ? css`
          &::placeholder {
            color: ${props => props.theme.grey};
            letter-spacing: .03rem;
            font-style: italic;
            opacity: 1;
          }
          border: 0;
          box-shadow: 0 2px 5px 1px rgba(134, 174, 197, 0.24);
          border: 1px solid transparent;
          height: 2.8125rem;
          background: white;
          border-radius: 50px;
          padding-left: 1.5rem;
          padding-right: 3rem;
        `
    : props.hasFocus && css`
        border-color: transparent;
      `
  }
  }
`

export const DropdownContainer = styled.div`
  display: inline-flex;
  box-sizing: border-box;
  outline: none;
  z-index: 5;
  > div {
    display: block;
  }
`

export const DropdownItem = styled.div`
  padding: .4rem .8rem;
  display: flex;
  font-size: .875rem;
  align-items: flex-start;
  flex-direction: column;
  color: ${props => props.theme.darkBlue};
  z-index: 1;
  :hover {
    background-color: #E7EFF3;
    cursor: pointer;
  }
  > i {
    padding: .3rem 1.1rem;
    &::after {
      color: ${props => props.theme.grey};
      font-size: 1.3rem;
    }
  }
  & div:first-child {
    font-weight: ${props => props.isBoldLabel ? 'bold' : 'normal'};
    margin-bottom: .2rem;
  }
`

export const SearchDropdownContainer = styled.div`
  display: ${props =>
    props.visible
      ? props.$loading
        ? 'flex'
        : 'block'
      : 'none'
  };
  align-items: ${props =>
    props.visible
      ? props.$loading
        ? 'center'
        : 'flex-start'
      : 'default'
  };
  max-height: 14rem;
  overflow: scroll;
  z-index: 4;
  box-shadow: 0 2px 12px 0 rgba(33, 65, 82, 0.3);
  position: absolute;
  width: 100%;
  background: white;
  min-height: 4rem;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  color: ${props => props.theme.darkBlue};
  overflow-y: scroll;
  ${props => props.isPlain
    ? css`
      padding-top: 2.5rem;
      top: 50%;
    `
    : css`
      padding-top: 1rem;
      top: auto;
    `
  }
  p {
    text-align: center;
  }
`

export default SearchSelectComponent;
