import React from 'react';
import { call, delay, put, select, takeLatest, takeEvery } from 'redux-saga/effects'
import { Link } from "react-router-dom";
import { privateSitePrefix } from '../Util/Config'
import * as CustomFieldEffect from '../Effect/CustomField'
import * as Cohort from '../State/Cohort'
import * as CustomField from '../State/CustomField'
import * as Login from '../State/Login'
import * as Patient from '../State/Patient'
import * as Resources from '../State/Resources'
import * as UploadFile from '../State/UploadFile'
import * as Dicom from '../State/Dicom'
import * as Api from '../Util/Api'
import * as Form from '../Util/Form'
import { dateToString, JJMMAAAAToDate } from '../Util/Format'
import { uniq, uniqByIdAndType } from '../Util/Object'
import { setCookie, deleteCookie } from '../Util/Storage'
import { debounceTime } from '../Util/Date';

export const selectImageries = state => state.uploadFile.data.imagerie
export const selectDocuments = state => state.uploadFile.data.documents
export const selectAttachments = state => state.uploadFile.data

export function* saveField({ payload: { fieldName } }) {
  try {
    const patientId = yield select(Patient.selectPatientId)
    const data = yield select(Patient.selectData)
    const fieldData = data[fieldName]
    const validatedData = yield call(Form.validate, { [fieldName]: fieldData })
    const apiFields = {
      anamneses: 'anamnesis',
      diagnostic: 'freeDiagnostic',
      email: 'email',
      birthdate: 'birthdate',
      firstname: 'firstName',
      lastname: 'lastName',
    }
    let updatedData = null;
    if (!validatedData[fieldName].errors.length > 0) {
      const apiData = {
        patient: {
          [apiFields[fieldName]]: fieldName === 'firstname' || fieldName === 'lastname'
            ? validatedData[fieldName].value.replace(/  +/g, ' ')
            : validatedData[fieldName].value
        }
      }
      if (fieldName === 'firstname' || fieldName === 'lastname') {
        yield put(Patient.updateField({ name: fieldName, value: fieldData.value.replace(/  +/g, ' ') }))
      }
      if (fieldName === 'email' && validatedData.email.value) {
        yield call(sendEmail, patientId)
      } else {
        updatedData = yield call(Api.updatePatient, patientId, apiData)
      }
      if (fieldName === 'birthdate') {
        const fullBirthdate = updatedData.fullBirthdate

        yield put(Patient.updateField({ name: 'fullBirthdate', value: fullBirthdate }))
      }
      yield put(Patient.setEditField({ field: fieldName, value: false }))
      yield put(Patient.updateInitialValue({ field: fieldName, value: fieldData.value }))
    }
    yield put(Patient.success(patientId))
  } catch (error) {
    if (error instanceof Form.ValidationError) {
      for (let [name, field] of Object.entries(error.data)) {
        yield put(Patient.updateField({
          name,
          value: field.value,
          errors: field.errors,
        }))
      }
      yield put(Patient.invalidate(
        `Une information obligatoire est manquante pour valider la mise à jour de vos données.`
      ))
    } else {
      yield put(Patient.apiError(error.message))
    }
  }
}

export function* send({ payload }) {
  try {

    const isUserNotLogged = payload?.isUserNotLogged
    const data = yield select(Patient.selectData)
    const hasLinkedTeleExpertise = yield select(Patient.selectHasTeleExpertiseLinked)
    const validatedData = yield call(Form.validate, { ...data })
    const forceCreation = payload?.forceCreation

    if (isUserNotLogged) {
      const patient = {
        firstname: data.firstname.value.replace(/  +/g, ' '),
        lastname: data.lastname.value.replace(/  +/g, ' '),
        birthdate: data.birthdate.value,
        sex: data.gender.value,
      }
      const teleExpertise = data.expertises.value

      setCookie('teleExpertise', JSON.stringify(teleExpertise), 1)
      setCookie('patient', JSON.stringify(patient), 1)

      yield put(Patient.creationSuccess())
    } else {
      const staffs = validatedData
        .expertises
        .value
        .filter(e => e.type === 'staffs')
        .map(e => Number(e.id))
      const opinions = validatedData
        .expertises
        .value
        .filter(e => e.type === 'opinions')
        .map(e => Number(e.id))
      const cohorts = validatedData
        .expertises
        .value
        .filter(e => e.type === 'cohorts')
        .map(e => Number(e.id))

      const apiData = {
        patient: {
          private: false,
          sex: validatedData.gender.value,
          lastName: validatedData.lastname.value.replace(/  +/g, ' '),
          firstName: validatedData.firstname.value.replace(/  +/g, ' '),
          birthdate: validatedData.birthdate.value,
        },
        creationForce: forceCreation || false,
        teleExpertises: {
          staffs,
          cohorts,
          opinions
        },
      }
      if (!hasLinkedTeleExpertise) {
        if (!payload?.confirmSave && !payload?.forceCreation) {
          yield put(Patient.updateField({ name: 'noLinkedTeleExpertise', value: true }))
          throw new Error('')
        }
      }
      const { id: patientId, alreadyExist: existingOwnedPatient } = yield call(Api.addPatient, apiData)
      if (!existingOwnedPatient) {
        yield put(Patient.creationSuccess(patientId))
      } else {
        yield put(Patient.existingOwnedPatient(patientId))
      }
    }

  } catch (error) {
    if (error.patientId) {
      yield put(Patient.setPatientExists({
        existing: true,
        patientId: error.patientId,
        requestedDoctor: error.doctor
      }))
    }
    else if (error instanceof Form.ValidationError) {
      for (let [name, field] of Object.entries(error.data)) {
        yield put(Patient.updateField({
          name,
          value: field.value,
          errors: field.errors,
        }))
      }
      yield put(Patient.invalidate({
        message: 'Une information obligatoire est manquante pour valider la création de votre patient.',
      }
      ))
    } else {
      yield put(Patient.apiError(error.message))
    }
  }
}

export function* sendGender() {
  try {
    const data = yield select(Patient.selectData)
    const patientId = yield select(Patient.selectPatientId)
    const validatedData = yield call(Form.validate, { ...data })

    const apiData = {
      patient: {
        private: false,
        sex: validatedData.gender.value,
        lastName: validatedData.lastname.value,
        firstName: validatedData.firstname.value,
        birthdate: validatedData.birthdate.value,
      },
    }

    const { fullBirthdate } = yield call(Api.updatePatient, patientId, apiData)

    yield put(Patient.updateField({ name: 'fullBirthdate', value: fullBirthdate }))
    yield put(Patient.updateIdentitySuccess(patientId))

  } catch (error) {
    if (error instanceof Form.ValidationError) {
      for (let [name, field] of Object.entries(error.data)) {
        yield put(Patient.updateField({
          name,
          value: field.value,
          errors: field.errors,
        }))
      }
      yield put(Patient.invalidate({
        message: 'Une information obligatoire est manquante pour valider la création de votre patient.',
      }
      ))
    } else {
      yield put(Patient.apiError(error.message))
    }
  }
}

export function* updatePatientStepDate({ payload: { value: { idx, formId } } }) {
  try {
    const patientId = yield select(Patient.selectPatientId)
    const data = yield select(Patient.selectData)
    const apiData = data.patientStepDates.value[formId]
      .map(({ patientStepId, date }) => ({
        cohortPatientStepId: patientStepId,
        programmingDate: dateToString(date)
      }))[idx]

    yield call(Api.updatePatientStepDate, patientId, apiData)
    yield put(Patient.success(patientId))

  } catch (error) {
    yield put(Patient.apiError(error.message))
  }
}

export function* deletePatient() {
  try {
    const patientId = yield select(Patient.selectPatientId)
    yield call(Api.deletePatient, patientId)
    yield put(Patient.deleted())
  } catch (error) {
    yield put(Patient.apiError(error.message))
  }
}

export function* updateAll() {
  try {
    const customFieldsDatas = yield select(CustomField.selectFormFields)
    let customFields = {}
    // Force fields update on each forms for validation
    if (customFieldsDatas[0] && customFieldsDatas[0].id) {
      for (let form of customFieldsDatas) {
        const { id, type } = form
        for (let sectionId of form.sections.allIds) {
          if (!form.sections.byId[sectionId].isPatientSection) {
            yield put(CustomField.update({ id, type, sectionId, target: 'customFields', noSave: true }))
          }
        }
      }
    }

    // Create new object with validation keys
    customFields = customFieldsDatas
      .map(({ fields, choices }) =>
        Object.values(fields.byId)
          // .filter(({ hasMotherPatientSection }) => !hasMotherPatientSection)
          .map(({ id, value, validationRules, hasMotherPatientSection }) => {
            return ({
              [id]: {
                value:
                  Array.isArray(value) &&
                    value.length > 0 &&
                    Object.keys(choices.byId)
                      .some(choiceId => value.includes(Number(choiceId)))
                    ? value
                      .map(choiceId =>
                        choices.byId[choiceId])
                      .reduce((acc, { id, content }) => ({
                        ...acc,
                        [id]: content
                      }), {})
                    : Object.entries(value.length) === 0
                      ? []
                      : value,
                errors: [],
                validationRules: hasMotherPatientSection ? [] : validationRules,
              }
            })
          })
          .reduce((acc, field) => ({
            ...acc, ...field
          }), {})
      ).reduce((acc, field) => ({
        ...acc, ...field
      }), {}) || {}

    const data = yield select(Patient.selectData)
    const hasCustomFieldsError = yield select(CustomField.selectError)
    const patientId = yield select(Patient.selectPatientId)
    const validatedData = yield call(Form.validate, { ...data, ...customFields })

    const apiData = {
      patient: {
        freeDiagnostic: validatedData.diagnostic.value,
        anamnesis: validatedData.anamneses.value,
      },
      customFieldContents:
        Object
          .entries(customFields)
          .filter(([_, values]) =>
            !Array.isArray(values.value)
          )
          .reduce((acc, [id, { value }]) =>
            ({ ...acc, [id]: { content: value } }), {}),
    }

    if (!hasCustomFieldsError) {
      if (validatedData.email.value) {
        yield call(sendEmail, patientId)
      }
      yield call(Api.updatePatient, patientId, apiData)
      yield put(Patient.setEditMode({ target: 'patientFile', value: false }))
      yield put(Patient.setEditMode({ target: 'customFields', value: false }))
      yield put(CustomField.resetViewerFieldEdit(false))
      yield put(Patient.success(patientId))
    }
  } catch (error) {
    if (error instanceof Form.ValidationError) {
      const customFieldsDatas = yield select(CustomField.selectFormFields)
      yield put(CustomField.setFormVisible(customFieldsDatas
        .filter(e => e.id)
        .filter(uniqByIdAndType)
        .reduce((acc, { id }) => ({ ...acc, [id]: true }), {})))

      for (let [name, field] of Object.entries(error.data)) {
        yield put(Patient.updateField({
          name,
          value: field.value,
          errors: field.errors,
        }))
      }
      yield put(Patient.invalidate({
        message: 'Une information obligatoire est manquante pour valider la création de votre patient.',
      }))
    } else {
      yield put(Patient.apiError(error.message))
    }
  }
}

export function* sendEmail(idParam) {
  try {
    const data = yield select(Patient.selectData)
    const email = data.email
    const id = yield select(Patient.selectPatientId)
    const validatedData = yield call(Form.validate, { email })
    const validatedEmail = validatedData.email.value
    const patientId = id || idParam
    if (email) {
      const { encodeEmail: patientCode } = yield call(Api.updateEmail, patientId, validatedEmail)
      yield put(Patient.updateField({
        name: 'patientCode',
        value: patientCode,
      }))
    }

    yield put(Patient.setEditMode({ target: 'email', value: false }))
  } catch (error) {
    if (error instanceof Form.ValidationError) {
      for (let [name, field] of Object.entries(error.data)) {
        yield put(Patient.updateField({
          name,
          value: field.value,
          errors: field.errors,
        }))
      }
      yield put(Patient.invalidate({
        message: 'Une erreur s\'est produite lors de l\'enregistrement de l\'adresse mail.',
      }))
    } else {
      yield put(Patient.apiError(error.message))
    }
  }
}

export function*  removeImage({ payload: id }) {
  try {
    const patientId = yield select(Patient.selectPatientId)

    if (id) {
      yield call(Api.removePatientImage, id, patientId)
    }
  } catch (error) {
    yield put(UploadFile.apiError(error.message))
  }
}

export function* removeDocument({ payload: id }) {
  try {
    if (id) {
      yield call(Api.removeAttachment, id)
    }
  } catch (error) {
    yield put(UploadFile.apiError(error.message))
  }
}

export function* removePublicDocument({ payload: { id, patientCode } }) {
  try {
    yield call(Api.removePublicAttachment, id, patientCode)
  } catch (error) {
    yield put(UploadFile.apiError(error.message))
  }
}

export function* fetch({ payload: {patientId, confirmCoOwnership} }) {
  try {
    if (confirmCoOwnership) {
      yield call(addPatientManager, { payload: {patientId, colleagueId: confirmCoOwnership }})
    }

    const {
      email,
      birthdate,
      fullBirthdate,
      doctor,
      medicalId,
      labels,
      createdAt,
      updatedAt,
      requests,
      staffSessionsLastIdByStaffId,
      staffs,
      cohorts,
      opinions,
      managers: patientManagers = [],
      keywordsNotFound,
      ownerTitle,
      dicomStudiesIds: studies = [],
      dicomFiles,
      _canBe,
      _canBeModified,
      firstName: firstname,
      lastName: lastname,
      fullName: fullname,
      sex: gender,
      encodeEmail: patientCode,
      imageries: image,
      customFieldContents: fieldValues,
      freeDiagnostic: diagnostic,
      anamnesis: anamneses,
      cohortPatientSteps: {
        byCohortId: patientStepDates,
        byCustomFieldSectionId: cohortsPatientStepsByCustomFieldSectionId,
      },
    } = yield call(Api.fetchPatient, patientId)

    const apiDocuments = yield call(Api.fetchAttachments, patientId)
    const docs =
      Object
        .entries(apiDocuments)
        .reduce((acc, [_, value]) => ([
          ...acc,
          ...value.assets
            .map(asset => ({ ...asset, id: asset.ownerId })),
        ]), [])

    const doctorDetails = yield call(Api.fetchColleague, doctor.id)
    const doctorOpinion = doctorDetails.opinions.find(opinion => opinion.thePersonal) || {}
    const hasQuestionnaires = cohorts.some(cohort => cohort.hasPatientSteps)

    if (hasQuestionnaires) {
      yield put(Cohort.setHasQuestionnaire(true))
    }

    yield put(Resources.received({
      name: [Resources.RESOURCE_PATIENT_MANAGERS],
      data: [doctor, ...patientManagers],
    }))

    if ([...staffs, ...opinions, ...cohorts].length > 0) {
      yield call(CustomFieldEffect.customFieldsFromExpertise, patientId, fieldValues, labels, { staffs, opinions, cohorts })
    }

    yield put(Patient.receivedRequests(requests))
    yield put(Patient.receivedImageries(image))
    yield put(Patient.receivedDocuments(docs))
    yield put(Patient.received({
      gender,
      firstname,
      lastname,
      fullname,
      birthdate,
      fullBirthdate,
      doctor: doctor.id,
      diagnostic,
      createdAt,
      updatedAt,
      ownerTitle,
      anamneses,
      staffSessionsLastIdByStaffId,
      email: email || '',
      patientCode: patientCode || '',
      medicalId: medicalId || '',
      studies: Object.values(studies) || [],
      dicomFiles: Object.values(dicomFiles) || [],
      cohortsPatientStepsByCustomFieldSectionId: cohortsPatientStepsByCustomFieldSectionId || {},
      patientStepDates: Object
        .entries(patientStepDates)
        .reduce((acc, [key, value]) => ({
          ...acc,
          [key]: value.map(({ date, ...rest }) =>
          ({
            date: date
              ? JJMMAAAAToDate(date)
              : ''
            , ...rest
          }))
        }), {}),
      keywordsNotFound: keywordsNotFound || [],
      defaultOpinions: [doctorOpinion, ...opinions]
        .filter(e => e.thePersonal)
        .map(e => e.id)
        .filter(uniq),
      patientManagers: patientManagers.map(e => e.id),
      expertises: { staffs, cohorts, opinions },
      permissions: { _canBe, _canBeModified: { ..._canBeModified, attachments: true } }
    }))

    yield put(Dicom.setPatientId(patientId))

    if (studies) {
      yield put(Dicom.retrieveStudies({ studies: Object.values(studies) }))
    } else {
      yield put(Dicom.getJWTToken())
    }

  } catch (error) {
    yield put(Patient.apiError(error.message))
  }
}

export function* addOpinion({ payload: { id: opinionId } }) {
  try {
    const expertise = yield call(Api.getTeleExpertise, 'opinions', opinionId)
    yield put(Patient.addAvailableExpertise(expertise))
    yield put(Patient.checkExpertiseFromList({ type: 'opinions', id: opinionId }))
  } catch (error) {
    yield put(Patient.apiError(error.message))
  }
}

export function* removeOpinion({ payload: { id: opinionId } }) {
  try {
    yield put(Patient.uncheckExpertiseFromList({ type: 'opinions', id: opinionId }))
  } catch (error) {
    yield put(Patient.apiError(error.message))
  }
}

export function* addPatientManager({ payload: { patientId, colleagueId } }) {
  try {
    const {manager} = yield call(Api.addPatientManager, patientId, colleagueId)
    yield put(Resources.received({
      name: [Resources.RESOURCE_PATIENT_MANAGERS],
      data: [manager],
    }))
    yield put(Patient.setEditMode({ target: 'patientManagers', value: false }))
    yield put(Patient.setCurrentSection(Patient.SECTION_COLLEAGUE))
    return manager
  }
  catch (error) {
    yield put(Patient.apiError(error.message))
  }
}

export function* removePatientManager({ payload: { patientId, colleagueId } }) {
  try {
    yield call(Api.removePatientManager, patientId, colleagueId)
    yield put(Patient.setEditMode({ target: 'patientManagers', value: false }))
  }
  catch (error) {
    yield put(Patient.apiError(error.message))
  }
}

export function* setSubscriptionMessage({
  currentUserAdmin,
  patientLimitReached,
  teleExpertiseId,
}) {
  const currentUser = yield select(Login.selectUser)
  let freeTeleExpertiseAlert, subscriptionLinkTo, warningMessage;
  if (currentUserAdmin && patientLimitReached) {
    freeTeleExpertiseAlert = 'Cette téléexpertise gratuite a atteint la limite de 10 patients. '

    const subscriptionLinkTo = currentUser.activeSubscription &&
      currentUser.activeSubscription.unlockTeleExpertiseRemaining > 0
      ? `${privateSitePrefix}/profil?tab=5&action=unlockTeleExpertise&teleExpertiseType=cohort&teleExpertiseId=${teleExpertiseId}`
      : `${privateSitePrefix}/profil?tab=5`

    const subscriptionMessage = currentUser.activeSubscription
      ? 'Débloquez-la avec ShareConfrère PRO'
      : 'Passez à ShareConfrère PRO'

    warningMessage = <>
      {freeTeleExpertiseAlert}
      <Link to={subscriptionLinkTo}>{subscriptionMessage}</Link>
    </>
  }
  return { freeTeleExpertiseAlert, subscriptionLinkTo, warningMessage }
}

export function* checkExpertiseFromList({ payload: { type, id } }) {
  try {
    const patientId = yield select(Patient.selectPatientId)
    yield put(CustomField.resetViewerFieldEdit(false))
    const endpointReturn = yield call(Api.linkExpertise, type, id, patientId, 'add')

    if (endpointReturn?.cohortPatientSteps) {
      const {
        byCohortId: patientStepDates,
        byCustomFieldSectionId: cohortsPatientStepsByCustomFieldSectionId,
      } = endpointReturn.cohortPatientSteps
      const {
        staffs,
        opinions,
        cohorts,
      } = endpointReturn
      yield put(Patient.receivedQuestionnaireDates(Object
        .entries(patientStepDates)
        .reduce((acc, [key, value]) => ({
          ...acc,
          [key]: value.map(({ date, ...rest }) =>
          ({
            date: date
              ? JJMMAAAAToDate(date)
              : ''
            , ...rest
          }))
        }), {}),
      ))
      yield put(Patient.receivedCohortsPatientStepsByCustomFieldSectionId(cohortsPatientStepsByCustomFieldSectionId))
      yield put(Patient.receivedExpertises({
        staffs: staffs.map(staff => ({ ...staff, type: 'staffs' })),
        cohorts: cohorts.map(cohorts => ({ ...cohorts, type: 'cohorts' })),
        opinions: opinions.map(opinions => ({ ...opinions, type: 'opinions' })),
      }))
    }
    yield put(Patient.addDefaultOpinion({ id }))

    const availableExpertises = yield select(Patient.selectAvailableExpertises)
    const expertise = availableExpertises
      .find(expertise => type === expertise.type && String(id) === String(expertise.id))
    const { warningMessage } = yield setSubscriptionMessage({
      currentUserAdmin: expertise.currentUserAdmin,
      patientLimitReached: expertise.patientLimitReached,
      teleExpertiseId: expertise.id,
    })
    const linkedQuestionnaires = expertise.hasPatientSteps

    if (linkedQuestionnaires) {
      yield put(Cohort.setHasQuestionnaire(true))
      yield put(Patient.setInfoMessage(`Un questionnaire est associé à cette cohorte.
      Veuillez renseignez l'email du patient dans l'onglet Infos complémentaires.`))
    }

    if (expertise.patientLimitReached) {
      yield put(Patient.setInfoMessage(warningMessage))
    }
    yield put(Patient.setInfoMessage(null))
    yield put(Resources.received({
      name: [Resources.RESOURCE_OPINIONS],
      data: [expertise],
    }))

    yield call(CustomFieldEffect.customFieldsFromExpertise, null, [], [], { [type]: [expertise] }, false)

    if (expertise && Object.values(expertise.customFields).length > 0) {
      yield put(Patient.setCurrentSection(Patient.SECTION_SUMMARY))
      yield put(Patient.setWarningMessage('Merci de renseigner les informations complémentaires requises pour ce patient.'))
      yield put(Patient.setWarningMessage(null))
    }
    yield put(Patient.setPending(false))
    yield put(Patient.setEditMode({ target: 'colleagues', value: false }))
  } catch (error) {
    yield put(Patient.apiError(error.message))
  }
}

export function* uncheckExpertiseFromList({ payload: { type, id } }) {
  try {
    const patientId = yield select(Patient.selectPatientId)

    yield call(Api.linkExpertise, type, id, patientId, 'remove')
    yield put(CustomField.removePatientFormFields(id))
    yield put(Patient.setPending(false))

  } catch (error) {
    yield put(Patient.apiError(error.message))
  }
}

export function* fetchExpertise({ type, id }) {
  try {
    const expertise = yield call(Api.getTeleExpertise, type, id)
    const availableExpertises = yield select(Patient.selectAvailableExpertises)
    const keywordsNotFound = yield select(Patient.selectKeywordsNotFound)
    const exists = 0 !== availableExpertises
      .filter(e => e.type === expertise.type && Number(e.id) === Number(expertise.id))
      .length

    if (!exists) {
      yield put(Patient.receivedAvailableExpertises({
        teleExpertises: [
          ...availableExpertises,
          expertise,
        ],
        keywordsNotFound
      }
      ))
    }
  } catch (error) {
    yield put(Patient.apiError(error.message))
  }
}

export function* fetchExpertises(type, query, limit) {
  try {
    yield put(Patient.fetchAvailableExpertises())
    const types = type === 'expertises'
      ? 'staffs,opinions,cohorts'
      : type
    const { teleExpertises, keywordsNotFound } = yield call(Api.getTeleExpertisesAll, types, query, limit)
    yield put(Patient.receivedAvailableExpertises({ teleExpertises, keywordsNotFound }))
  } catch (error) {
    yield put(Patient.apiError(error.message))
  }
}

export function* updateDoctor({ payload: { colleague: { colleagueId }, patientId } }) {
  try {
    yield call(Api.updateDoctor, colleagueId, patientId)
    yield put(Patient.success(patientId))
  } catch (error) {
    yield put(Patient.apiError(error.message))
  }
}

export function* getExpertiseData({ payload: { value, toggled = true, preselected = false } }) {
  try {
    const { type, id } = value
    if (preselected) {
      yield fetchExpertise(value)
    }
    if (toggled) {
      const availableExpertises = yield select(Patient.selectAvailableExpertises)
      const expertise = availableExpertises
        .find(expertise => type === expertise.type && String(id) === String(expertise.id))

      if (expertise && expertise.id) {
        if (expertise.hasPatientSteps) {
          yield put(Cohort.setHasQuestionnaire(true))
        }
        yield call(CustomFieldEffect.customFieldsFromExpertise, null, {}, {}, { [type]: [expertise] })
      }
    } else {
      yield put(Cohort.setHasQuestionnaire(false))
      yield put(CustomField.removePatientFormFields(id))
    }

  } catch (error) {
    yield put(Patient.apiError(error.message))
  }
}

export function* setQuery({ payload: { name, value: query } }) {
  try {
    const delayMs = debounceTime(query)

    yield delay(delayMs)

    if (name === 'patientManagers') {
      yield put(Resources.load({
        filter: [Resources.RESOURCE_PATIENT_MANAGERS],
        query
      }))
    } else {
      yield fetchExpertises(name, query, 30)
    }

  } catch (error) {
    yield put(Patient.apiError(error.message))
  }
}

export function* linkStudy({ payload: studies }) {
  try {
    const { studies } = yield select(Patient.selectData)
    const patientId = yield select(Dicom.selectPatientId)
    yield call(Api.saveStudies, patientId, studies.value)
  } catch (error) {
    yield put(Patient.apiError(error.message))
  }
}

export function* resetCreationFlags() {
  try {
    yield deleteCookie('patient');
    yield deleteCookie('teleExpertise');
  } catch (error) {
    yield put(Patient.apiError(error.message))
  }
}

export function* askPatientCoOwnership({ payload: patientId }) {
  try {
    yield call(Api.askPatientCoOwnership, patientId)
    yield put(Patient.setPatientOwnershipSent())
  } catch (error) {
    yield put(Patient.apiError(error.message))
  }
}

export function* createPatient({ payload }) {
  try {
    yield put(Patient.updateField({ name: 'fake', value: 'true' }))
    yield put(Patient.updateField({ name: 'firstname', value: payload.firstname }))
    yield put(Patient.updateField({ name: 'lastname', value: payload.lastname }))
    yield put(Patient.updateField({ name: 'birthdate', value: payload.birthdate }))
    yield put(Patient.updateField({ name: 'gender', value: payload.gender }))
    yield put(Patient.updateField({ name: 'expertises', value: payload.expertises }))
    yield put(Patient.send());
    yield resetCreationFlags();
  } catch (error) {
    console.log(error)
    yield put(Patient.apiError(error.message))
  }
}

const PatientEffects = function* () {
  yield takeLatest(`${Patient.send}`, send);
  yield takeLatest(`${Patient.updateAll}`, updateAll);
  yield takeLatest([
    `${Patient.selectExpertise}`,
    `${Patient.toggle}`], getExpertiseData)
  yield takeLatest(`${Patient.fetch}`, fetch);
  yield takeLatest(`${Patient.checkExpertiseFromList}`, checkExpertiseFromList);
  yield takeLatest(`${Patient.uncheckExpertiseFromList}`, uncheckExpertiseFromList);
  yield takeLatest(`${Patient.deletePatient}`, deletePatient);
  yield takeLatest(`${Patient.removeImage}`, removeImage);
  yield takeLatest(`${Patient.removeDocument}`, removeDocument);
  yield takeLatest(`${Patient.removePublicDocument}`, removePublicDocument);
  yield takeLatest(`${Patient.addPatientManager}`, addPatientManager)
  yield takeLatest(`${Patient.removePatientManager}`, removePatientManager)
  yield takeLatest(`${Patient.removeOpinion}`, removeOpinion)
  yield takeLatest(`${Patient.setQuery}`, setQuery)
  yield takeLatest(`${Patient.sendEmail}`, sendEmail)
  yield takeLatest(`${Patient.updateDoctor}`, updateDoctor)
  yield takeLatest(`${Patient.updatePatientStepDate}`, updatePatientStepDate)
  yield takeLatest(`${Patient.linkStudy}`, linkStudy)
  yield takeLatest(`${Patient.sendGender}`, sendGender)
  yield takeLatest(`${Patient.askPatientCoOwnership}`, askPatientCoOwnership)
  yield takeEvery(`${Patient.saveField}`, saveField)
  yield takeEvery(`${Patient.createPatient}`, createPatient)
};

export default PatientEffects;
