import * as StaffList from '../State/StaffList'
import * as Login from '../State/Login'
import * as Eff from 'redux-saga/effects'
import * as Api from '../Util/Api'
import { debounceTime } from '../Util/Date'

const teleExpertiseType = 'staff'

export function* fetch({
  payload: {
    page = 1,
  } = {},
} = {}) {
  const abortController = new AbortController();
  try {
    const rawFilters = yield Eff.select(StaffList.selectFilters)
    const query = rawFilters.search
    if (query) {
      const delayMs = debounceTime(query)
      yield Eff.delay(delayMs)
    }
    const user = yield Eff.select(Login.selectUser)
    const filters = {
      ...rawFilters,
      statuses: rawFilters
        .status
        .filter(status => status.checked)
        .map(status => status.value),
      limit: Number(process.env.REACT_APP_DEFAULT_RESULT_LIMIT),
      offset: (page - 1) * process.env.REACT_APP_DEFAULT_RESULT_LIMIT,
      userId: user.id,
    }
    const { staffs, requests, keywordsNotFound, count } = yield Eff.call(Api.fetchTeleExpertises, filters, teleExpertiseType, { signal: abortController.signal })
    const staffsWithRequests = staffs.map(staff =>
    ({
      ...staff,
      requests: requests[staff.id] || null,
    })
    )

    if (count !== 'none') {
      yield Eff.put(StaffList.receivedCountAll(count))
    }

    yield Eff.put(StaffList.received({
      data: staffsWithRequests,
      mode: page > 1
        ? StaffList.MODE_RECEIVED_MORE
        : StaffList.MODE_RECEIVED_FIRST,
      keywordsNotFound,
    }))
  } catch (error) {
    yield Eff.put(StaffList.apiError(error.message))
  } finally {
    if (yield Eff.cancelled()) {
      abortController.abort();
    }
  }
}

export function* count() {
  try {
    const teleExpertiseCount = yield Eff.call(Api.teleExpertiseCount, 'staffs')

    yield Eff.put(StaffList.receivedCount(teleExpertiseCount))
  } catch (error) {
    // @TODO handle errors correclty
  }
}

const StaffListSagas = function* () {
  yield Eff.takeLatest([
    `${StaffList.fetch}`,
    `${StaffList.removeStatus}`,
    `${StaffList.addStatus}`,
    `${StaffList.changeDisplayBy}`,
    `${StaffList.changeOrderBy}`,
  ], fetch)

  yield Eff.takeLatest(`${StaffList.changeSearch}`, fetch)
  yield Eff.takeLatest(`${StaffList.count}`, count)
}

export default StaffListSagas;
