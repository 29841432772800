import React from 'react'
import { urlRedirectionWordsToRoute } from '../Util/Url'
import { useSelector } from 'react-redux';
import { useAlert } from 'react-alert'
import { useHistory } from "react-router-dom";
import * as Ui from './Ui'

const TotpComponent = (props) => {
  const history = useHistory()
  const alert = useAlert()

  const currentUser = useSelector(state => state.login.currentUser)
  const { match: { params } } = props

  if (localStorage.getItem(currentUser.email + '-hasTotp')) {
    alert.success('Votre clé permanente a bien été installée: vous n\'aurez plus besoin de saisir de code d\'authentification sur cet ordinateur durant 1 mois !')
  } else {
    alert.error('Une erreur est survenu dans l\'installation de votre clé.')
  }

  const routeToRedirect = urlRedirectionWordsToRoute(params.routeToRedirect)
  history.push(routeToRedirect)

  return <Ui.Layout.PageLayout currentUser={currentUser}></Ui.Layout.PageLayout>
}

export default TotpComponent;
