import { createSlice } from '@reduxjs/toolkit'

export const OPINION = 'opinion'
export const STAFF = 'staff'
export const COHORT = 'cohort'

const CATEGORY_ORDER = [OPINION, STAFF, COHORT]

// Toujours mettre cet etat initial du state
export const INITIAL_STATE = {
  data: {
    homeImage: {
      value: '',
      errors: [], validationRules: []
    },
    homeImageStretch: { value: false, errors: [], validationRules: [] },
    homeImageAlign: { value: 1, errors: [], validationRules: [] },
  },
  teleExpertiseOpened: '',
  search: '',
  errorMessage: '',
  loading: false,
  isOtherTeleExpertise: false,
  teleExpertises: [],
  keywordsNotFound: [],
  linedTeleExpertises: [],
  slideSelected: CATEGORY_ORDER[0],
  categoryOrder: CATEGORY_ORDER,
}

const slice = createSlice({
  name: 'homepage',
  initialState: INITIAL_STATE,
  reducers: {
    chooseTeleExpertise: (state, { payload }) => ({
      ...state,
      teleExpertiseOpened: payload,
      search: '',
      teleExpertises: [],
    }),
    changeSearch: (state, { payload }) => ({
      ...state,
      search: payload,
      loading: true,
    }),
    received: (state, { payload: { isOtherTeleExpertise = false, teleExpertises = [], keywordsNotFound = [] } }) => ({
      ...state,
      loading: false,
      isOtherTeleExpertise: isOtherTeleExpertise,
      teleExpertises,
      keywordsNotFound,
    }),
    setLoading: (state, { payload: loading }) => ({
      ...state,
      loading,
    }),
    chooseSlide: (state, { payload }) => ({
      ...state,
      slideSelected: payload,
    }),
    nextSlide: (state) => ({
      ...state,
      slideSelected: !state.slideSelected
        ? state.categoryOrder[0]
        : state.slideSelected === COHORT
          ? state.categoryOrder[0]
          : state.categoryOrder[state.categoryOrder.findIndex((element) => element === state.slideSelected) + 1]
      ,
    }),
    previousSlide: (state) => ({
      ...state,
      slideSelected: state.slideSelected ? (state.slideSelected === OPINION ? state.categoryOrder[2] : state.categoryOrder[state.categoryOrder.findIndex((element) => element === state.slideSelected) - 1]) : state.categoryOrder[0],
    }),
    setQuery: (state, { payload: { teleExpertiseType, value } }) => ({
      ...state,
      search: value,
      loading: true,
      teleExpertises: []
    }),

    fetchExpertises: (state, payload) => ({
      ...state,
      search: payload
    }),
    receivedExpertises: (state, { payload: { teleExpertises } }) => ({
      ...state,
      teleExpertises: [...teleExpertises],
      loading: false,
    }),
    apiError: (state, action) => ({
      ...state,
      errorMessage: action.payload,
      pending: false,
      error: true,
      success: false,
    }),
  }
})

// // L'unique reducer
export default slice.reducer

// Les actions type
export const CHOOSE_TELEEXPERTISE = String(slice.actions.chooseTeleExpertise)
export const CHANGE_SEARCH = String(slice.actions.changeSearch)
export const CHOOSE_SLIDE = String(slice.actions.chooseSlide)
export const NEXT_SLIDE = String(slice.actions.nextSlide)
export const PREVIOUS_SLIDE = String(slice.actions.previousSlide)

// Les actions créateur
export const {
  chooseTeleExpertise,
  changeSearch,
  received,
  chooseSlide,
  nextSlide,
  previousSlide,
  selectTeleExpertise,
  setLoading,
  setQuery,
  fetchExpertises,
  receivedExpertises,
  apiError,
} = slice.actions

export const selectSearch = state => state.homepage.search

export const selectLoading = state => state.homepage.loading

export const selectKeywordsNotFound = state => state.homepage.keywordsNotFound

export const selectTeleExpertiseOpened = state => state.homepage.teleExpertiseOpened

export const selectIsOtherTeleExpertise = state => state.homepage.isOtherTeleExpertise

export const selectLinedTeleExpertise = state => state.homepage.linedTeleExpertises

export const selectTeleExpertises = state => state.homepage.teleExpertises

export const selectErrorMessage = state => state.homepage.errorMessage

export const selectData = state => state.homepage.data
