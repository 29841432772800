import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { useAlert } from 'react-alert'
import styled from 'styled-components'
import * as Dicom from '../../State/Dicom'
import * as IconUI from './Icon'
import * as GlobalUi from './Global'
import IconDicom from '../../Asset/Image/icon-doc-dicom.svg'
import { apiSymfony6Url } from '../../Util/Config'

const DicomPreviewContainer = styled.div`
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  margin-bottom: .5rem;

  a {
    text-decoration: none;
    color: black;
  }
`

const DicomPreviewElement = styled.div`
  font-size: .8rem;
  box-sizing: border-box;
  margin-right: 1rem;
  display: flex;
  height: 6.25rem;
  background-color: white;
  padding: .5rem;
  flex: 1;
  width: 17rem;
  border-radius: 5px;
  box-shadow: 0 2px 5px 1px rgba(134,174,197,0.24);
  div:nth-child(2) {
    overflow-wrap: break-word;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  span {
    flex: 1;
    padding-left: 1rem;
  }
`

const DicomPreviewContent = styled.div`
  cursor: pointer;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-left: .8rem;
`

const DicomIcon = styled.div`
  cursor: pointer;
  background: url(${props => props.background}) no-repeat center;
  background-size: cover;
  width: 3rem;
  height: 3rem;
  flex: initial;
  flex-shrink: 0;
`

const ActionButtonGroup = styled.div`
  display: flex;
  align-self: flex-end;
  margin-right: .4rem;
  ${IconUI.Icon} {
    font-size: 1.1rem;
    color: ${props => props.theme.grey2};
    margin-left: .4rem;
    &:hover {
      color: ${props => props.theme.mediumBlue};
    }
  };
`

const TrashDocButton = styled(props => <IconUI.IconTrash {...props} />)`
  cursor: pointer;
`

const SaveDocButton = styled(props => <IconUI.IconSave {...props} />)`
  align-self: flex-end;
  cursor: pointer;
`

const DocTitle = styled.div`
  font-weight: 600;
`

const Modalities = styled.div`
  display: flex;
  margin-top: .5rem;
  span {
    flex: initial;
    border: 1px solid ${props => props.theme.grey2};
    align-items: flex-start;
    padding: 0 .2rem;
    border-radius: 4px;
    font-size: .675rem;
    margin-right: .3rem;
  }
`

export const DicomPreview = ({
  onRemoveDicom,
  onClickCard,
  fileName,
  studyUid,
  title = '',
  subTitle = '',
  modalities = [],
  currentDownloadId = null,
  downloadLink,
  children,
  canEdit,
  ...props
}) => {
  const dispatch = useDispatch();
  const alert = useAlert();

  const onDownloadDicom = (e, fileName, studyUid) => {
    e.stopPropagation()
    dispatch(Dicom.initDownload())
    dispatch(Dicom.showLoadingBox({ type: Dicom.DOWNLOAD, isVisible: true, fileName }))
    dispatch(Dicom.downloadDicom({ fileName, studyUid, isAvailable: false }))
    alert.info('Vous pouvez continuer à naviguer pendant le téléchargement', { timeout: 5000 })
  }

  const [actionButtons, setActionButtons] = useState(false)

  return (
    <DicomPreviewContainer
      onMouseEnter={() => setActionButtons(true)}
      onMouseLeave={() => setActionButtons(false)}
    >
      <DicomPreviewElement>
        <DicomIcon background={IconDicom} onClick={onClickCard} title={`Voir ${subTitle}`} />
        <DicomPreviewContent onClick={onClickCard} title={`Voir ${subTitle}`}>
          <DocTitle>{title}</DocTitle>
          <div>{subTitle}</div>
          <Modalities>
            {modalities.map(modality =>
              <span key={modality}>{modality}</span>
            )}
          </Modalities>
        </DicomPreviewContent>
        <ActionButtonGroup>
          {currentDownloadId === studyUid
            ? <GlobalUi.Loader isSmall />
            : !currentDownloadId && canEdit
              ? <SaveDocButton
                title={`Télécharger ${subTitle}`}
                onClick={e => downloadLink
                  ? window.location.href = downloadLink
                  : onDownloadDicom(e, fileName, studyUid)}
              />
              : null
          }
          {onRemoveDicom && actionButtons &&
            <TrashDocButton
              title={`Supprimer ${subTitle}`}
              onClick={onRemoveDicom}
            />
          }
        </ActionButtonGroup>

      </DicomPreviewElement>
    </DicomPreviewContainer >
  )
}

export const DicomS3Preview = ({
  onRemoveDicom,
  onClickCard,
  patientId,
  fileKey,
  description,
  date,
  currentDownloadId = null,
  downloadLink,
  canEdit,
  ...props
}) => {
  const [actionButtons, setActionButtons] = useState(false)

  return (
    <DicomPreviewContainer
      onMouseEnter={() => setActionButtons(true)}
      onMouseLeave={() => setActionButtons(false)}
    >
      <DicomPreviewElement>
        <DicomIcon background={IconDicom} onClick={onClickCard} title={`Voir ${description}`} />
        <DicomPreviewContent onClick={onClickCard} title={`Voir ${description}`}>
          <DocTitle>{date}</DocTitle>
          <div>{description}</div>
        </DicomPreviewContent>
        <ActionButtonGroup>
          {canEdit
            ? <SaveDocButton
              title={`Télécharger ${description}`}
              onClick={e => window.open(`${apiSymfony6Url}/patients/${patientId}/download-dicom/${fileKey}`, '_blank')}
            />
            : null
          }
          {canEdit && onRemoveDicom && actionButtons &&
            <TrashDocButton
              title={`Supprimer ${description}`}
              onClick={onRemoveDicom}
            />
          }
        </ActionButtonGroup>

      </DicomPreviewElement>
    </DicomPreviewContainer >
  )
}
