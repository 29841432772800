import { createSlice } from '@reduxjs/toolkit'

export const UPLOAD = 'upload'
export const DOWNLOAD = 'download'
export const TYPE_IMAGE = 'image'
export const TYPE_DICOM = 'dicom'

export const INITIAL_STATE = {
  data: {
    dicomFiles: { value: {}, errors: [], validationRules: [], },
  },
  errorMessage: null,
  errorMessageSeen: false,
  pending: false,
  currentDownload: null,
  error: false,
  success: false,
  JWTToken: null,
  tokenExpirationDate: '',
  uploaded: 0,
  uploadedError: 0,
  totalFiles: 0,
  isLoadingBoxVisible: {
    [UPLOAD]: false,
    [DOWNLOAD]: false,
  },
  patientId: null,
  fileKey: null,
  downloadingZipName: '',
  studyStatus: {},
  downloadType: '',
}

const slice = createSlice({
  name: 'dicom',
  initialState: INITIAL_STATE,
  reducers: {
    addStudy: (state, { payload: study }) => ({
      ...state,
      data: {
        ...state.data,
        dicomFiles: {
          ...state.data.dicomFiles,
          value: {
            ...state.data.dicomFiles.value,
            ...study
          }
        }
      },
    }),
    uploadAndLinkStudy: (state, { payload: { file, totalFiles } }) => ({
      ...state,
      totalFiles,
      dicomBinary: file,
      pending: true,
    }),
    retrieveStudies: state => ({
      ...state,
    }),
    removeStudy: (state, { payload: studyId }) => ({
      ...state,
    }),
    removeDicomS3: (state, { payload: { patientId, fileKey } }) => ({
      ...state,
    }),
    openViewerDicom: state => {
      return ({
        ...state,
      })
    },
    getJWTToken: state => {
      return ({
        ...state,
      })
    },
    setJWTToken: (state, { payload: { token, token_expiration_date } }) => ({
      ...state,
      JWTToken: token,
      tokenExpirationDate: token_expiration_date
    }),

    incrementUploaded: (state) => ({
      ...state,
      uploaded: state.uploaded + 1
    }),

    incrementUploadedError: (state) => ({
      ...state,
      uploaded: state.uploaded + 1,
      uploadedError: state.uploadedError + 1
    }),

    showLoadingBox: (state, { payload: { type, isVisible, downloadType } }) => ({
      ...state,
      isLoadingBoxVisible: { [type]: isVisible },
      downloadType,
    }),

    initUpload: (state) => ({
      ...state,
      uploaded: 0,
      totalFiles: 0,
      uploadedError: 0,
      errorMessageSeen: false,
    }),

    initDownload: (state) => ({
      ...state,
    }),

    checkDownload: (state) => ({
      ...state,
    }),

    checkDownloads: (state) => ({
      ...state,
    }),
    setDownloadingZipName: (state, { payload: fileName }) => ({
      ...state,
      downloadingZipName: fileName
    }),

    setStudyAvailability: (state, { payload: { studyId, isAvailable = false } }) => ({
      ...state,
      studyStatus: {
        ...state.studyStatus,
        [studyId]: isAvailable,
      }
    }),

    generateDicomViewerJson: (state) => ({
      ...state,
    }),

    success: state => ({
      ...state,
      success: true,
      pending: false,
    }),

    fail: state => ({
      ...state,
      success: false,
      pending: false,
    }),

    setPatientId: (state, { payload }) => ({
      ...state,
      patientId: payload,
    }),

    setFileKey: (state, { payload }) => ({
      ...state,
      fileKey: payload,
    }),

    downloadDicom: (state, { payload: { fileName, studyUid, isAvailable } }) => ({
      ...state,
      currentDownload:
        { [studyUid]: isAvailable }
    }),

    stopDownloading: (state, { payload: studyUid }) => ({
      ...state,
      currentDownload: null,
      isLoadingBoxVisible: { [DOWNLOAD]: false },
    }),

    abortUpload: (state, { payload }) => ({
      ...state,
    }),

    abortDownload: (state, { payload }) => ({
      ...state,
      currentDownload: null,
    }),
    setDownload: (state, { payload: { studyId, isLinkAvailable } }) => ({
      ...state,
      currentDownload: {
        [studyId]: isLinkAvailable
      }
    }),
    clean: state => INITIAL_STATE,

    apiError: (state, action) => ({
      ...state,
      errorMessage: action.payload,
      error: true,
      success: false,
      pending: false,
    }),

    setErrorMessageSeen: (state) => ({
      ...state,
      errorMessageSeen: true,
    }),
  }
})

export const {
  updateField,
  upload,
  update,
  openViewerDicom,
  getJWTToken,
  setJWTToken,
  retrieveStudies,
  addStudy,
  uploadAndLinkStudy,
  removeStudy,
  removeDicomS3,
  incrementUploaded,
  incrementUploadedError,
  showLoadingBox,
  setPatientId,
  setFileKey,
  initUpload,
  initDownload,
  abortUpload,
  abortDownload,
  downloadDicom,
  stopDownloading,
  checkDownload,
  checkDownloads,
  setStudyAvailability,
  setDownloadingZipName,
  setDownload,
  generateDicomViewerJson,
  success,
  fail,
  clean,
  apiError,
  setErrorMessageSeen,
} = slice.actions

export const selectSuccess = state => state.dicom.success
export const selectData = state => state.dicom.data
export const selectPending = state => state.dicom.pending
export const selectJWTToken = state => state.dicom.JWTToken
export const selectTokenExpirationDate = state => state.dicom.tokenExpirationDate
export const selectUploaded = state => state.dicom.uploaded
export const selectUploadedError = state => state.dicom.uploadedError
export const selectErrorMessageSeen = state => state.dicom.errorMessageSeen
export const selectTotalFiles = state => state.dicom.totalFiles
export const selectIsLoadingBoxVisible = state => state.dicom.isLoadingBoxVisible
export const selectPatientId = state => state.dicom.patientId
export const selectFileKey = state => state.dicom.fileKey
export const selectCurrentDownloadId = ({ dicom }) =>
  dicom.currentDownload && Object.keys(dicom.currentDownload)[0]
export const selectStudyStatus = state => state.dicom.studyStatus
export const selectDownloadType = state => state.dicom.downloadType
export const selectDownloadingZipName = state => state.dicom.downloadingZipName
export const selectIsUploadComplete = ({ dicom }) =>
  dicom.totalFiles ? dicom.uploaded === dicom.totalFiles : true
export const selectIsDownloadComplete = ({ dicom }) => !dicom.currentDownload

export default slice.reducer
