import * as PatientList from '../State/PatientList'
import * as Eff from 'redux-saga/effects'
import * as Api from '../Util/Api'
import { debounceTime } from '../Util/Date';

export function* fetchPatients({
  payload: {
    page = 1,
  } = {},
} = {}) {
  const abortController = new AbortController();
  try {
    const rawFilters = yield Eff.select(PatientList.selectFilters)
    const query = rawFilters.search
    if (query) {
      const delayMs = debounceTime(query)
      yield Eff.delay(delayMs)
    }
    const filters = {
      ...rawFilters,
      statuses: rawFilters
        .status
        .filter(status => status.checked)
        .map(status => status.value),
      limit: Number(process.env.REACT_APP_DEFAULT_RESULT_LIMIT),
      offset: (page - 1) * process.env.REACT_APP_DEFAULT_RESULT_LIMIT,
    }
    const {
      patients,
      count,
      keywordsNotFound
    } = yield Eff.call(Api.fetchPatients, filters, { signal: abortController.signal })

    if (count !== 'none') {
      yield Eff.put(PatientList.receivedCountAll(count))
    }

    yield Eff.put(PatientList.received({
      data: patients,
      mode: page > 1
        ? PatientList.MODE_RECEIVED_MORE
        : PatientList.MODE_RECEIVED_FIRST,
      keywordsNotFound,
    }))
  } catch (error) {
    yield Eff.put(PatientList.apiError(error.message))
  } finally {
    if (yield Eff.cancelled()) {
      abortController.abort();
    }
  }
}

export function* count() {
  try {
    const patientCount = yield Eff.call(Api.patientCount)

    yield Eff.put(PatientList.receivedCount(patientCount))
  } catch (error) {
    yield Eff.put(PatientList.apiError(error.message))
  }
}

export function* getRecentTeleExpertises() {
  try {
    const recentTeleExpertises = yield Eff.call(Api.getRecentTeleExpertises)

    yield Eff.put(PatientList.receivedRecentTeleExpertises(recentTeleExpertises))
  } catch (error) {
    yield Eff.put(PatientList.apiError(error.message))
  }
}

const PatientListSagas = function* () {
  yield Eff.takeLatest([
    `${PatientList.fetch}`,
    `${PatientList.removeStatus}`,
    `${PatientList.addStatus}`,
    `${PatientList.changeDisplayBy}`,
    `${PatientList.changeOrderBy}`,
  ], fetchPatients)
  yield Eff.takeLatest(`${PatientList.changeSearch}`, fetchPatients)
  yield Eff.takeLatest(`${PatientList.count}`, count)
  yield Eff.takeLatest(`${PatientList.getRecentTeleExpertises}`, getRecentTeleExpertises)
}

export default PatientListSagas;
