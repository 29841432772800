import * as Eff from 'redux-saga/effects'
import * as Staff from '../State/Staff'
import * as Resources from '../State/Resources'
import * as Login from '../State/Login'
import * as Organization from '../State/Organization'
import * as StaffList from '../State/StaffList'
import * as CustomFieldEffect from '../Effect/CustomField'
import * as UploadFile from '../State/UploadFile'
import * as Api from '../Util/Api'
import * as Form from '../Util/Form'
import { JJMMAAAAToDate, dateToString } from '../Util/Format'
import { fr } from '../Util/i18n';
import { uniqById } from '../Util/Object'
import { debounceTime } from '../Util/Date'

const expertiseType = 'staff'

const sortedUpcomingSessions = (sessions =>
  Object
    .values(sessions)
    .map(({ id, upcomingDate }) => ({
      id,
      dateString: upcomingDate.split(' ')[0],
      date: upcomingDate === 'date à déterminer'
        ? 'date à déterminer'
        : JJMMAAAAToDate(upcomingDate.split(' ')[0]),
      hour: upcomingDate === 'date à déterminer'
        ? ''
        : upcomingDate.split(' ')[1]
    }))
    .sort((a, b) => a.date - b.date)
)

export function* saveField({ payload: { fieldName } }) {
  try {
    const data = yield Eff.select(Staff.selectData)
    const staffId = yield Eff.select(Staff.selectId)
    const fieldData = data[fieldName]
    const validatedData = yield Eff.call(Form.validate, { [fieldName]: fieldData })
    const apiFields = {
      colleagues: 'members',
      portalHeaderImage: 'portalHeaderImageName',
      portalAbout: 'portalDescription',
    }

    if (!validatedData[fieldName].errors.length > 0) {
      const apiData = {
        staff: fieldName === 'tags'
          ? {
            tags: validatedData.tags.value.filter(value => value),
          }
          : {
            [apiFields[fieldName] || fieldName]: validatedData[fieldName].value,
          }
      }

      const {
        labels,
        tags,
      } = yield Eff.call(Api.updateExpertise, apiData, staffId, expertiseType)

      if (labels)
        yield Eff.put(Staff.received({ labels }))
      else if (tags)
        yield Eff.put(Staff.received({ tags }))

      yield Eff.put(Staff.setEditField({ field: fieldName, value: false }))
      yield Eff.put(Staff.updateInitialValue({ field: fieldName, value: fieldData.value }))
    }
    yield Eff.put(Staff.success(staffId))
  } catch (error) {
    if (error instanceof Form.ValidationError) {
      for (let [name, field] of Object.entries(error.data)) {
        yield Eff.put(Staff.updateField({
          name,
          value: field.value,
          errors: field.errors,
        }))
      }
      yield Eff.put(Staff.invalidate({
        message:
          'Une information obligatoire est manquante pour valider la mise à jour de vos données.'
      }))
    } else {
      yield Eff.put(Staff.apiError(error.message))
    }
  }
}

export function* send() {
  try {
    const data = yield Eff.select(Staff.selectData)
    const fileData = yield Eff.select(UploadFile.selectData)
    const staffPicture = fileData['staff-picture']

    if (data.publicUri.value !== '') {
      yield Eff.call(Api.checkExpertisePublicUriExist, expertiseType, data.publicUri.value)
    }

    yield Eff.call(Form.validate, { ...data })

    const staff = yield Eff.call(Api.createExpertise, {
      [expertiseType]: {
        name: data.name.value,
        description: data.description.value,
        tags: data.tags.value,
        disciplines: data.disciplines.value,
        // TODO : uncomment when /staff endpoint will accept organization ids list
        pictureFileName: Object
          .values(staffPicture.value)
          .map(doc => (doc.fileName))
          .shift() || '',
        publicUri: data.publicUri.value,
        recurrence: 'none',
      },
      labels: data
        .labels
        .value
        .filter(l => l.content !== ''),
    }, expertiseType)

    yield Eff.put(Staff.success(staff.id))
  } catch (error) {
    console.log(error)
    if (error instanceof Form.ValidationError) {
      if (Array.isArray(error.data.errors)) {
        yield Eff.put(Staff.invalidate({
          message: 'Format d\'un champs invalide pour l\'enregistrement des données.'
        }))
        yield Eff.put(Staff.setErrors({
          name: error.data.name,
          errors: error.data.errors,
        }))
      } else {
        const errorData =
          Object.entries(error.data)
            .filter(([_, data]) => data.errors.length > 0)
            .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {})

        for (let [name, field] of Object.entries(errorData)) {
          yield Eff.put(Staff.updateData({
            name,
            value: field.value,
            errors: field.errors,
          }))
        }
        yield Eff.put(Staff.invalidate({
          message: `Une information obligatoire est manquante pour valider la création de votre ${fr[expertiseType].expertise}.`
        }
        ))
      }
    } else {
      yield Eff.put(Staff.apiError(error.message))
    }
  }
}

export function* toggleParticipant({ payload: { id: memberId, toggled } }) {
  try {
    const sessionId = yield Eff.select(Staff.selectSessionId)
    if (toggled) {
      yield Eff.call(Api.addParticipant, sessionId, memberId)
    } else {
      yield Eff.call(Api.removeParticipant, sessionId, memberId)
    }
  } catch (error) {
    yield Eff.put(Staff.apiError(error.message))
  }
}

export function* toggleAllParticipants({ payload: { areAllParticipantsChecked } }) {
  try {
    const sessionId = yield Eff.select(Staff.selectSessionId)
    if (areAllParticipantsChecked) {
      yield Eff.call(Api.removeAllParticipants, sessionId)
    } else {
      yield Eff.call(Api.addAllParticipants, sessionId)
    }
  } catch (error) {
    yield Eff.put(Staff.apiError(error.message))
  }
}

export function* updateOwner({ payload: { colleague: { colleagueId } } }) {
  try {
    const teleExpertiseId = yield Eff.select(Staff.selectId)

    yield Eff.call(Api.updateOwner, colleagueId, teleExpertiseId, expertiseType)
    yield Eff.put(Staff.success(teleExpertiseId))
  } catch (error) {
    yield Eff.put(Staff.apiError(error.message))
  }
}

export function* addManager({ payload: { id: managerId } }) {
  try {
    const teleExpertiseId = yield Eff.select(Staff.selectId)

    yield Eff.call(Api.addManager, managerId, teleExpertiseId, expertiseType)
    yield Eff.put(Staff.setEditMode({ target: 'managers', value: false }))

  } catch (error) {
    yield Eff.put(Staff.apiError(error.message))
  }
}

export function* removeManager({ payload: { id: managerId } }) {
  try {
    const teleExpertiseId = yield Eff.select(Staff.selectId)

    yield Eff.call(Api.removeManager, managerId, teleExpertiseId, expertiseType)
    yield Eff.put(Staff.setEditMode({ target: 'managers', value: false }))

  } catch (error) {
    yield Eff.put(Staff.apiError(error.message))
  }
}

export function* removePortalDocument({ payload: portalDocumentId }) {
  try {
    yield Eff.call(Api.removePortalDocument, portalDocumentId)

  } catch (error) {
    yield Eff.put(Staff.apiError(error.message))
  }
}

export function* update({ payload: { teleExpertiseId: staffId, teleExpertiseName: expertiseType, selectedField } }) {
  try {
    const data = yield Eff.select(Staff.selectData)

    let fields;
    if (selectedField === 'labels') {
      fields = {
        labels: data.labels.value
      }

      const labels = yield Eff.call(Api.updateLabels, staffId, expertiseType, fields)
      yield Eff.put(Staff.received({ labels }))

    } else if (selectedField === 'staffSessionFields') {
      yield Eff.call(Form.validateArray, data.sessionsUpcoming, 'sessionsUpcoming')

      fields = {
        [expertiseType]: {
          recurrence: data.recurrence.value,
        },
        sessionsUpcoming: data.sessionsUpcoming.value
          .filter(sessionUpcoming => sessionUpcoming.date)
          .map(
            sessionUpcoming => {
              return {
                id: sessionUpcoming.id,
                date: dateToString(sessionUpcoming.date),
                hour: sessionUpcoming.hour === '' ? '00:00' : sessionUpcoming.hour,
              }
            }
          ),
      }

      const {
        sessionsUpcoming,
      } = yield Eff.call(Api.updateExpertise, fields, staffId, expertiseType)

      const sessionsUpcomingData = sortedUpcomingSessions(sessionsUpcoming)

      yield Eff.put(Staff.received({
        nextSession: sessionsUpcomingData.length > 0 ? sessionsUpcomingData[0].date : '',
        sessionsUpcoming: sessionsUpcomingData,
      }))
    } else {
      const fileData = yield Eff.select(UploadFile.selectData)
      const headingDocuments = fileData['heading-documents']
      const reportImageFileName = headingDocuments &&
        Object
          .values(headingDocuments.value)
          .map(doc => (doc.fileName))
          .shift()
      const staffPicture = fileData['staff-picture']
      const pictureFileName = staffPicture &&
        Object
          .values(staffPicture.value)
          .map(doc => (doc.fileName))
          .shift()
      const portalHeaderImage = fileData['portal-header-image']
      const portalHeaderImageFileName = portalHeaderImage &&
        Object
          .values(portalHeaderImage.value)
          .map(doc => (doc.fileName))
          .shift()

      yield Eff.call(Form.validate, data)

      yield Eff.put(Staff.updateImage({ imageType: 'pictureFileName', imageFileName: pictureFileName }))
      if (reportImageFileName) {
        yield Eff.put(Staff.updateImage({ imageType: 'reportImageFileName', imageFileName: reportImageFileName }))
      }

      yield Eff.put(Staff.updateImage({ imageType: 'portalHeaderImage', imageFileName: portalHeaderImageFileName }))

      fields = {
        [expertiseType]: {
          name: data.name.value,
          description: data.description.value,
          tags: data.tags.value.filter(e => e),
          disciplines: data.disciplines.value,
          managers: data.managers.value,
          members: data.colleagues.value,
          pictureFileName: pictureFileName || '',
          publicUri: data.publicUri.value,
          organizations: data.organizations.value,
        },
        createMultiCustomFields: {},
      }

      yield Eff.call(Api.updateExpertise, fields, staffId, expertiseType)
      yield Eff.put(Staff.received({ tags: data.tags.value.filter(e => e) }))
    }

    yield Eff.put(Staff.setEditMode({ target: 'addRemovePatient', value: false }))
    yield Eff.put(Staff.success(staffId))
  } catch (error) {
    // TODO handle errors correctly
    if (error instanceof Form.ValidationError) {
      if (Array.isArray(error.data.errors)) {
        yield Eff.put(Staff.invalidate({
          message: 'Format d\'un champs invalide pour l\'enregistrement des données.'
        }))
        yield Eff.put(Staff.setErrors({
          name: error.data.name,
          errors: error.data.errors,
        }))
      } else {
        for (let [name, field] of Object.entries(error.data)) {
          yield Eff.put(Staff.updateData({
            name,
            value: field.value,
            errors: field.errors,
          }))
        }
      }
    } else {
      yield Eff.put(Staff.apiError(error.message))
    }
  }
}

function getArchives(otherSessions) {
  const today = new Date()
  return Object
    .entries(otherSessions)
    .filter(([key, archive]) => archive.endDate && JJMMAAAAToDate(archive.endDate) < today)
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {})
}

export function* fetch({ payload: { teleExpertiseIdParam, sessionIdParam } }) {
  try {
    const {
      patients,
      isOutdated,
      startDate,
      endDate,
      id: sessionId,
      participants,
      staff,
      portal,
      staff: {
        name,
        description,
        ownerFullname,
        updatedAt,
        createdAt,
        recurrence,
        owner: {
          title: ownerTitle,
          id: ownerId,
          profilePicture,
        },
        owner,
        managers,
        organizations,
        members: colleagues,
        tags,
        sessionsUpcoming,
        nextMeeting,
        nextSessionStartDate,
        currentSessionPatientIds = [],
        disciplines,
        labels,
        reportImageFileName = '',
        pictureFileName = '',
        publicUri = '',
        otherSessions,
        archive,
        currentUserAdmin,
        patientLimitLocked,
        patientLimitReached,
        numberAddPatientAction,
        portalHeaderImageAlign,
        portalHeaderImageStretch,
        portalHeaderImageName: portalHeaderImage,
        publicVideoconferencingActive,
        publicVideoconferencingId,
        _canBe,
        _canBeModified,
      },
    } = yield Eff.call(Api.fetchTeleExpertise, teleExpertiseIdParam, sessionIdParam, expertiseType)

    const sessionsUpcomingData = sortedUpcomingSessions(sessionsUpcoming)

    const organizationMembers = Object
      .values(organizations)
      .reduce((acc, { members, managers }) => [
        ...acc,
        [...members, ...managers]
      ], [])
      .reduce((acc, x) => acc.concat(x), []);

    yield Eff.put(Resources.received({
      name: [Resources.RESOURCE_MEMBERS],
      data: [owner, ...managers, ...colleagues, ...organizationMembers].filter(uniqById)
    }))

    yield Eff.put(Resources.received({
      name: [Resources.RESOURCE_DISCIPLINES],
      data: disciplines,
    }))

    yield Eff.put(Resources.received({
      name: [Resources.RESOURCE_PATIENTS],
      data: patients,
    }))

    yield Eff.put(Resources.received({
      name: [Resources.RESOURCE_ORGANIZATIONS],
      data: organizations,
    }))

    yield Eff.put(Staff.receivedOrganizationMembers(organizationMembers.map(e => e.id)))

    yield getExpertisesWithComments(sessionId)

    if (Object.keys(staff.customFields).length > 0) {
      yield Eff.call(CustomFieldEffect.customFieldsFromExpertise, null, [], labels, { staffs: [staff] }, true)
    }

    const archives = yield getArchives(otherSessions)

    yield Eff.put(Staff.received({
      name,
      sessionId,
      description,
      ownerFullname,
      createdAt,
      updatedAt,
      recurrence,
      nextMeeting,
      currentSessionPatientIds,
      ownerTitle,
      ownerId,
      owner: [owner.id],
      managers: managers.map(e => e.id),
      colleagues: colleagues.map(e => e.id),
      tags,
      participants: participants.map(e => e.id),
      disciplines,
      labels: Object.values(labels),
      startDate,
      endDate,
      organizations: organizations.map(e => e.id),
      patientsLocked: patients.filter(patient => patient.pendingPatient).map(patient => patient.id),
      patients: patients.map(e => e.id),
      nextSession: sessionsUpcomingData.length > 0 ? sessionsUpcomingData[0].date : '',
      nextSessionStartDate,
      sessionsUpcoming: sessionsUpcomingData.map(session => ({ ...session, isFieldEdit: false })),
      reportImageFileName,
      pictureFileName,
      profilePicture,
      publicUri,
      isOutdated,
      archives,
      archive,
      currentUserAdmin,
      patientLimitLocked,
      patientLimitReached,
      numberAddPatientAction,
      portalHeaderImageAlign,
      portalHeaderImageStretch,
      portalHeaderImage,
      portalDocuments: portal.attachements || [],
      portalAbout: portal.description || '',
      publicVideoconferencingActive,
      publicVideoconferencingId,
      permissions: { _canBe, _canBeModified }
    }))
    yield Eff.put(Staff.success(teleExpertiseIdParam))

  } catch (error) {
    yield Eff.put(Staff.apiError(error.message))
  }
}

export function* getExpertisesWithComments(sessionId) {
  try {
    const expertisesWithComments = yield Eff.call(Api.getExpertisesWithComments, sessionId, expertiseType)

    yield Eff.put(Staff.receivedExpertisesWithComments(expertisesWithComments))
  } catch (error) {
    yield Eff.put(Staff.apiError(error.message))
  }
}

export function* updateColleagues({ payload: id }) {
  try {
    const colleagues = yield Eff.select(Staff.selectColleagues)
    yield Eff.call(Api.updateColleagues, id, colleagues, expertiseType)
    yield Eff.put(Staff.setEditMode(false))
  } catch (error) {
    yield Eff.put(Staff.apiError(error.message))
  }
}

export function* addColleague({ payload: { id: colleagueId, teleExpertiseId: teleExpertiseParamId, noEffect = false } }) {
  try {
    if (!noEffect) {
      const teleExpertiseId = teleExpertiseParamId
        ? teleExpertiseParamId
        : yield Eff.select(Staff.selectId)

      yield Eff.call(Api.addUser, teleExpertiseId, colleagueId, 'staffs')
      // TODO : need update colleague list from response ?
      yield Eff.put(Staff.setEditMode({ target: 'colleagues', value: false }))
    }
  } catch (error) {
    yield Eff.put(Staff.apiError(error.message))
  }
}

export function* removeColleague({ payload: { id: colleagueId } }) {
  try {
    const teleExpertiseId = yield Eff.select(Staff.selectId)
    yield Eff.call(Api.removeUser, teleExpertiseId, colleagueId, 'staffs')
    // TODO : need update colleague list from response ?
    yield Eff.put(Staff.setEditMode({ target: 'colleagues', value: false }))
  } catch (error) {
    yield Eff.put(Staff.apiError(error.message))
  }
}

export function* addOrganization({ payload: { id: organizationId, teleExpertiseId: teleExpertiseParamId } }) {
  try {
    const teleExpertiseId = teleExpertiseParamId
      ? teleExpertiseParamId
      : yield Eff.select(Staff.selectId)

    const { organizations } = yield Eff.call(Api.updateOrganization, organizationId, 'add', 'staff', teleExpertiseId)

    const organizationMembers = Object
      .values(organizations)
      .reduce((acc, { members, managers }) => [
        ...acc,
        [...members, ...managers]
      ], [])
      .reduce((acc, val) => acc.concat(val), [])

    yield Eff.put(Resources.received({
      name: [Resources.RESOURCE_MEMBERS],
      data: [...organizationMembers].filter(uniqById),
    }))

    yield Eff.put(Staff.receivedOrganizationMembers(organizationMembers.map(e => e.id)))
    yield Eff.put(Staff.setQuery({ name: 'organizations', value: '' }))
    yield Eff.put(Staff.setEditMode({ target: 'organizations', value: false }))
  } catch (error) {
    yield Eff.put(Staff.apiError(error.message))
  }
}

export function* removeOrganization({ payload: { id: organizationId, teleExpertiseId: teleExpertiseParamId } }) {
  try {
    const teleExpertiseId = teleExpertiseParamId
      ? teleExpertiseParamId
      : yield Eff.select(Staff.selectId)

    const { organizations } = yield Eff.call(Api.updateOrganization, organizationId, 'remove', 'staff', teleExpertiseId)
    const organizationMembers = Object
      .values(organizations)
      .reduce((acc, { members, managers }) => [
        ...acc,
        [...members, ...managers]
      ], [])
      .reduce((acc, val) => acc.concat(val), [])

    yield Eff.put(Staff.setQuery({ name: 'organizations', value: '' }))
    yield Eff.put(Staff.received({ organizationMembers: organizationMembers.map(e => e.id) }))
    yield Eff.put(Staff.setEditMode(false))
  } catch (error) {
    yield Eff.put(Staff.apiError(error.message))
  }
}

export function* addPatient({ payload: { id: patientId, teleExpertiseId, sessionId } }) {
  try {
    const isPatientLimitReached = yield Eff.select(Staff.selectIsPatientLimitReached)
    if (isPatientLimitReached) {
      yield Eff.put(Staff.updatePatientsLocked(patientId))
    }
    const {
      name,
      ownerFullname,
      numberAddPatientAction,
      patientLimitReached: isLimitReachAfterAdd,
    } = yield Eff.call(Api.linkPatient, teleExpertiseId, patientId, 'staff')
    if (sessionId) {
      yield getExpertisesWithComments(sessionId)
    }
    yield Eff.put(Staff.received({
      name,
      ownerFullname,
      numberAddPatientAction,
      patientLimitReached: isLimitReachAfterAdd
    }))
    yield Eff.put(Staff.setEditMode({ target: 'addRemovePatient', value: false }))

    yield Eff.put(Staff.patientsCopied({ patients: [patientId] }))
    yield Eff.put(Staff.success(teleExpertiseId))

  } catch (error) {
    console.log(error)
    yield Eff.put(Staff.apiError(error.message))
  }
}

export function* removePatient({ payload: { id, teleExpertiseId, sessionId } }) {
  try {
    yield Eff.call(Api.unlinkPatient, teleExpertiseId, id, 'staff')
    if (sessionId) {
      yield getExpertisesWithComments(sessionId)
    }
    yield Eff.put(Staff.setEditMode({ target: 'addRemovePatient', value: false }))
  } catch (error) {
    yield Eff.put(Staff.apiError(error.message))
  }
}

export function* addOrganizationMembers({ payload: id }) {
  try {
    const { organizations } = yield Eff.select(Resources.selectResources)
    const organization = organizations.find(org => org.id === id)

    yield Eff.put(Resources.received({
      name: [Resources.RESOURCE_ORGANIZATIONS],
      data: [organization],
    }))

    const {
      id: organizationId,
      name,
      logo,
      members = [],
      managers = [],
    } = organization

    yield Eff.put(Resources.received({
      name: [Resources.RESOURCE_MEMBERS],
      data: [...members, ...managers].filter(uniqById),
    }))

    yield Eff.put(Organization.received({
      organizationId,
      name,
      logo,
      members: members.map(e => e.id),
      managers: managers.map(e => e.id),
    }))

    yield Eff.put(Staff.receivedOrganizationMembersById({
      organizationId,
      members: [...members, ...managers].map(e => e.id)
    }))
  } catch (error) {
    console.log(error)
    yield Eff.put(Staff.apiError(error.message))
  }
}

export function* fetchOrganizations({ payload }) {
  try {
    const filters = {
      search: '',
      onlyManagedOrMember: 1
    }
    const organizations = yield Eff.call(Api.fetchOrganizations, filters)

    yield Eff.put(Resources.received({
      name: [Resources.RESOURCE_ORGANIZATIONS],
      data: organizations,
    }))

  } catch (error) {
    yield Eff.put(Staff.apiError(error.message))
  }
}

export function* closeExpertise({ payload: teleExpertiseId }) {
  try {
    yield Eff.call(Api.closeExpertise, teleExpertiseId, expertiseType)
  } catch (error) {
    yield Eff.put(Staff.apiError(error.message))
  }
}

export function* openExpertise({ payload: teleExpertiseId }) {
  try {
    yield Eff.call(Api.openExpertise, teleExpertiseId, expertiseType)
  } catch (error) {
    yield Eff.put(Staff.apiError(error.message))
  }
}

export function* removeLabel({ payload: { id } }) {
  try {
    if (id) {
      const staffId = yield Eff.select(Staff.selectId)
      if (staffId) {
        yield Eff.call(Api.deleteLabel, staffId, id, expertiseType)
      }
    }
  } catch (error) {
    yield Eff.put(Staff.apiError(error.message))
  }
}

export function* setQuery({ payload: { name, value: query } }) {
  try {
    if (query) {
      const delayMs = debounceTime(query)
      yield Eff.delay(delayMs)
      if (name === 'disciplines') {
        yield Eff.put(Resources.load({
          filter: [Resources.RESOURCE_DISCIPLINES],
          query: query,
        }))
      } else if (name === 'managers' || name === 'colleagues') {
        yield Eff.put(Resources.load({
          filter: [Resources.RESOURCE_MEMBERS],
          query: query,
        }))
      } else if (name === 'patients') {
        yield Eff.put(Resources.load({
          filter: [Resources.RESOURCE_PATIENTS],
          query: query,
        }))
      } else if (name === 'organizations') {
        yield Eff.put(Resources.load({
          filter: [Resources.RESOURCE_ORGANIZATIONS],
          query: query,
        }))
      }
    }

  } catch (error) {
    yield Eff.put(Staff.apiError(error.message))
  }
}

export function* allPatientsToCurrentSession({ payload: { teleExpertiseId, sessionId, patients } }) {
  try {
    yield Eff.call(Api.allPatientsToCurrentSession, teleExpertiseId, sessionId)
    yield Eff.put(Staff.patientsCopied({ patients }))
  } catch (error) {
    yield Eff.put(Staff.apiError(error.message))
  }
}

export function* suggestSpecialities() {
  try {
    const user = yield Eff.select(Login.selectUser)

    if (user.speciality) {
      yield Eff.put(Resources.load({
        filter: [Resources.RESOURCE_DISCIPLINES],
        query: user.speciality,
      }))
      yield Eff.put(Resources.received({
        name: [Resources.RESOURCE_DISCIPLINES],
        data: [user.speciality],
      }))
      yield Eff.put(Staff.addDiscipline(user.speciality))
    }
  } catch (error) {
    yield Eff.put(Staff.apiError(error.message))
  }
}

export function* fetchStaffFromCode({ payload: publicUri }) {
  try {
    const { staffId } = yield Eff.call(Api.fetchTeleExpertiseFromCode, 'staff', publicUri)
    yield Eff.put(Staff.updateField({
      name: 'id',
      value: staffId,
    }))
    yield Eff.put(Staff.updateField({
      name: 'patientCodeUri',
      value: staffId,
    }))
    yield Eff.put(Staff.setStaffUri({ publicUri, value: true }))

  } catch (error) {
    yield Eff.put(Staff.apiError(error.message))
  }
}

export function* fetchPublicVideoconferencing({ payload }) {
  try {
    const teleExpertiseId = yield Eff.select(Staff.selectId)

    const { publicVideoconferencingId } = yield Eff.call(Api.fetchStaffPublicVideoconferencing, teleExpertiseId)

    yield Eff.put(Staff.updateField({
      name: 'publicVideoconferencingId',
      value: publicVideoconferencingId,
    }))
  } catch (error) {
    yield Eff.put(Staff.apiError(error.message))
  }
}

export function* closeCurrentSession({ payload: { teleExpertiseId } }) {
  try {
    const {
      otherSessions,
      sessionsUpcoming,
    } = yield Eff.call(Api.closeStaffCurrentSession, teleExpertiseId)

    const archives = yield getArchives(otherSessions)

    const sessionsUpcomingData = sortedUpcomingSessions(sessionsUpcoming)

    yield Eff.put(Staff.received({
      archives,
      nextSession: sessionsUpcomingData.length > 0 ? sessionsUpcomingData[0].date : '',
      endDate: sessionsUpcomingData.length > 0 ? dateToString(sessionsUpcomingData[0].date) : '',
      sessionsUpcoming: sessionsUpcomingData,
    }))
    yield Eff.put(Staff.success(teleExpertiseId))
  } catch (error) {
    yield Eff.put(Staff.apiError(error.message))
  }
}

export function* removeUpcomingSession({ payload: { sessionUpcomingId, teleExpertiseId } }) {
  try {
    if (sessionUpcomingId) {
      yield Eff.select(Staff.selectId)
      yield Eff.call(Api.deleteSessionsUpcoming, teleExpertiseId, sessionUpcomingId)
    }
  } catch (error) {
    yield Eff.put(Staff.apiError(error.message))
  }
}

export function* toggle({ payload: { id, toggled, name, teleExpertiseId } }) {
  try {
    const data = yield Eff.select(Staff.selectData)
    const teleExpertiseId = yield Eff.select(Staff.selectId)
    if (name === "disciplines") {
      if (teleExpertiseId) {
        const fields = { staff: { disciplines: data.disciplines.value } }
        yield Eff.call(Api.updateExpertise, fields, teleExpertiseId, 'staff')
      }
    }
    yield Eff.put(Staff.setEditMode({ target: 'disciplines', value: false }))

  } catch (error) {
    console.log(error)
    yield Eff.put(Staff.apiError(error.message))
  }
}

export function* updateSessionDate({ payload: { sessionUpcomingId } }) {
  try {
    const teleExpertiseId = yield Eff.select(Staff.selectId)
    const data = yield Eff.select(Staff.selectData)
    const sessionsUpcoming = data.sessionsUpcoming.value
    const sessionDateData = data.sessionsUpcoming.value.find(session => session.id === sessionUpcomingId)
    let receivedSessionsUpcoming;
    if (!sessionDateData.date) {
      throw new Error('Vous devez renseigner une date de session')
    }

    yield Eff.call(Form.validateArray, data.sessionsUpcoming, 'sessionsUpcoming')
    if (sessionUpcomingId) {

      receivedSessionsUpcoming = yield Eff.call(Api.updateSessionsUpcoming, teleExpertiseId, sessionDateData)

      const sessionsUpcomingData = Object
        .values(sessionsUpcoming)
        .sort((a, b) => a.date - b.date)

      yield Eff.put(Staff.received({
        nextSession: sessionsUpcomingData.length > 0 ? sessionsUpcomingData[0].date : '',
        sessionsUpcoming: sessionsUpcomingData,
      }))
    } else {
      receivedSessionsUpcoming = yield Eff.call(Api.addSessionsUpcoming, teleExpertiseId, sessionDateData)
      const sessionsUpcomingData = sortedUpcomingSessions(receivedSessionsUpcoming)

      yield Eff.put(Staff.received({
        nextSession: sessionsUpcomingData.length > 0 ? sessionsUpcomingData[0].date : '',
        sessionsUpcoming: sessionsUpcomingData,
      }))
    }
    yield Eff.put(Staff.setEditFieldById({ sessionId: sessionUpcomingId, isFieldEdit: false }))

    yield Eff.put(Staff.success(teleExpertiseId))
  } catch (error) {
    console.log(error)
    if (error instanceof Form.ValidationError) {
      if (Array.isArray(error.data.errors)) {
        yield Eff.put(Staff.invalidate({
          message: 'Format d\'un champs invalide pour l\'enregistrement des données.'
        }))
        yield Eff.put(Staff.setErrors({
          name: error.data.name,
          errors: error.data.errors,
        }))
      } else {
        for (let [name, field] of Object.entries(error.data)) {
          yield Eff.put(Staff.updateData({
            name,
            value: field.value,
            errors: field.errors,
          }))
        }
      }
    } else {
      yield Eff.put(Staff.apiError(error.message))
    }
  }
}

const StaffSagas = function* () {
  yield Eff.takeLatest(`${Staff.fetch}`, fetch)
  yield Eff.takeLatest(`${Staff.send}`, send)
  yield Eff.takeLatest(`${Staff.update}`, update)
  yield Eff.takeLatest(`${Staff.toggle}`, toggle)
  yield Eff.takeLatest(`${Staff.suggestSpecialities}`, suggestSpecialities)
  yield Eff.takeLatest(`${Staff.updateColleagues}`, updateColleagues)
  yield Eff.takeLatest([
    `${Staff.closeExpertise}`,
    `${StaffList.closeExpertise}`,
  ], closeExpertise)
  yield Eff.takeLatest([
    `${Staff.openExpertise}`,
    `${StaffList.openExpertise}`,
  ], openExpertise)
  yield Eff.takeLatest(`${Staff.updateOwner}`, updateOwner)
  yield Eff.takeLatest(`${Staff.addManager}`, addManager)
  yield Eff.takeLatest(`${Staff.removeManager}`, removeManager)
  yield Eff.takeLatest(`${Staff.fetchOrganizations}`, fetchOrganizations)
  yield Eff.takeLatest(`${Staff.allPatientsToCurrentSession}`, allPatientsToCurrentSession)
  yield Eff.takeLatest(`${Staff.removeLabel}`, removeLabel)
  yield Eff.takeLatest(`${Staff.setQuery}`, setQuery)
  yield Eff.takeLatest(`${Staff.toggleParticipant}`, toggleParticipant)
  yield Eff.takeLatest(`${Staff.toggleAllParticipants}`, toggleAllParticipants)
  yield Eff.takeLatest(`${Staff.addColleague}`, addColleague)
  yield Eff.takeLatest(`${Staff.removeColleague}`, removeColleague)
  yield Eff.takeLatest(`${Staff.addOrganization}`, addOrganization)
  yield Eff.takeLatest(`${Staff.removeOrganization}`, removeOrganization)
  yield Eff.takeLatest(`${Staff.addOrganizationMembers}`, addOrganizationMembers)
  yield Eff.takeLatest([
    `${Staff.addPatient}`,
    `${Staff.addPatientToCurrentSession}`
  ], addPatient)
  yield Eff.takeLatest(`${Staff.removePatient}`, removePatient)
  yield Eff.takeLatest(`${Staff.fetchStaffFromCode}`, fetchStaffFromCode)
  yield Eff.takeLatest(`${Staff.fetchPublicVideoconferencing}`, fetchPublicVideoconferencing)
  yield Eff.takeLatest(`${Staff.closeCurrentSession}`, closeCurrentSession)
  yield Eff.takeLatest(`${Staff.removeUpcomingSession}`, removeUpcomingSession)
  yield Eff.takeEvery(`${Staff.saveField}`, saveField)
  yield Eff.takeEvery(`${Staff.updateSessionDate}`, updateSessionDate)
  yield Eff.takeEvery(`${Staff.removePortalDocument}`, removePortalDocument)
  // yield Eff.takeEvery(`${Staff.addSessionDate}`, addSessionDate)
}

export default StaffSagas;
