import * as UploadFile from '../State/UploadFile'
import * as Dicom from '../State/Dicom'
import * as Login from '../State/Login'
import * as Eff from 'redux-saga/effects'
import * as Api from '../Util/Api'
import * as Form from '../Util/Form'

export function* uploadFile({ payload: { uploader, file } }) {
  try {
    yield Eff.put(Dicom.initUpload())
    yield Eff.put(Dicom.showLoadingBox({ type: Dicom.UPLOAD, downloadType: 'image', isVisible: true }))
    yield Eff.put(UploadFile.clean())

    const { documents, ...allData } = yield Eff.select(UploadFile.selectData)
    const data = allData[uploader]

    yield Eff.call(Form.validate, { ...allData })

    const endpoint = data.endpoint
    const saveEndpoint = data.saveEndpoint
    const sliceToUpdate = data.sliceToUpdate

    if (endpoint) {
      const formData = yield Eff.call(createFormData, 'file', file)
      const apiData = yield Eff.call(Api.post, endpoint, {
        body: formData,
      })
      if (typeof apiData === 'string') {
        yield Eff.put(UploadFile.update({
          uploader,
          file: {
            name: file.name,
            size: file.size,
            type: file.type,
            id: undefined,
            fileName: apiData,
            sending: false,
          },
        }))
      } else {
        yield Eff.put(UploadFile.update({
          uploader,
          file: {
            name: file.name,
            size: file.size,
            type: file.type,
            id: apiData.id || apiData.attachment?.id,
            fileName: apiData?.attachment?.assets[0].fileName || apiData.fileName || apiData.profilePicture || apiData.filename,
            sending: false,
          },
        }))
        if (uploader === 'profilePicture') {
          yield Eff.put(Login.updateProfilePicture(apiData.profilePicture))
        }

        if (saveEndpoint) {
          const apiSavedData = yield Eff.call(Api.linkAssetsToUser, {
            assets: [{
              fileName: apiData.fileName,
              size: file.size,
              name: file.name,
              mimeType: file.type,
            }],
            saveEndpoint
          })

          const {
            ownerId: id,
            name,
            fileName,
            size,
            mimeType
          } = apiSavedData
            .attachments[0]
            .assets[0]

          yield Eff.put(UploadFile.update({
            uploader,
            file: {
              id,
              name,
              fileName,
              size,
              type: mimeType,
            },
          }))
        }
        if (sliceToUpdate) {
          yield Eff.put(sliceToUpdate.updateImage({ imageType: 'pictureFileName', imageFileName: apiData.fileName || apiData.profilePicture || apiData.filename }))
        }
      }
    } else {
      yield Eff.put(UploadFile.update({
        uploader,
        file: {
          name: file.name,
          size: file.size,
          type: file.type,
          id: undefined,
          fileName: file.fileName,
          sending: false,
        },
      }))
    }

    yield Eff.put(UploadFile.successAction())
  } catch (e) {
    if (e instanceof Form.ValidationError) {
      yield Eff.put(UploadFile.update({
        uploader,
        errors: e.data[uploader].errors,
      }))
      yield Eff.put(UploadFile.apiError(e.data[uploader].errors[0]))
    } else {
      yield Eff.put(UploadFile.update({
        uploader,
        errors: [
          'Une erreur est survenue, veuillez réessayer plus tard.'
        ]
      }))
    }
    yield Eff.put(UploadFile.remove({
      uploader,
      name: file.name,
    }))
    yield Eff.put(Dicom.showLoadingBox({ type: Dicom.UPLOAD, downloadType: 'image', isVisible: false }))
  }
}

export const createFormData = (key, data) => {
  const form = new FormData()
  form.append(key, data)

  return form
}

export function* remove({ payload: { uploader, name } }) {
  const allData = yield Eff.select(UploadFile.selectData)
  const data = allData[uploader]

  yield Eff.call(Form.validate, allData)

  const removeEndpoint = data.removeEndpoint

  try {
    yield Eff.call(Api.post, removeEndpoint, {
      body: JSON.stringify({ pictureFileName: '' }),
    })

  } catch (e) {
    console.log(e)
  }
}

const UploadFileSagas = function* () {
  yield Eff.takeLatest(`${UploadFile.upload}`, uploadFile)
  // yield Eff.takeLatest(`${UploadFile.remove}`, remove)
}


export default UploadFileSagas;
