import styled from 'styled-components/macro'
import * as Ui from '../Component/Ui'
import { DropdownItem } from './Ui/SearchSelect'

export const ActiveSubscriptionDetailContainer = styled.div`
  margin-bottom: 1rem;
`

export const UpdateTeleExpertiseButton = styled(props => <Ui.Button.BasicButton {...props} />)`
  margin-bottom: 1.5rem;
`

export const GreenPlainButton = styled(props => <Ui.Button.BasicButton {...props} />)`
  margin-top: .5rem;
  margin-bottom: 2rem;
`

export const Price = styled.p`
  color: ${props => props.theme.patientGreen};
  font-size: 2.2rem;
  text-align: center;
  font-weight: 700;
  margin-bottom: 0;
  margin-top: 2rem;
  @media only screen and (max-width: ${props => props.theme.device.desktop}) {
    font-size: 1.7rem;
  }
`

export const SubPrice = styled(props => <Price {...props} />)`
  font-size: 1.5rem;
  margin-top: .5rem;
`

export const SubscriptionPackageBody = styled.div`
  flex: 1;
`

export const SubscriptionPackageFooter = styled.div`
  display: flex;
  button {
    flex: 1;
  }
`

export const SubscriptionPackage = styled.div`
  box-sizing: border-box;
  flex: 24rem;
  flex-shrink: 1;
  flex-grow: 0;
  background: ${props => props.highlight ? 'white' : 'none'};;
  box-shadow: ${props => props.highlight ? props.theme.boxShadow.thick : 'none'};
  text-align: ${props => props.highlight ? 'left' : 'center'};
  border-radius: 5px;
  border: 2px solid ${props => props.offerSelected ? props.theme.patientGreen : 'none'};
  padding: 2.5rem;
  margin: 1rem;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: ${props => props.theme.device.desktop}) {
    padding: 1.8rem;
  }
  ${Ui.Icon.IconH1} {
    background-color: ${props => props.highlight ? props.theme.primaryLight : 'white'};
    color: ${props => props.highlight ? props.theme.primary : props.theme.patientGreen};
    margin-right: 1.5rem;
    @media only screen and (max-width: ${props => props.theme.device.desktop}) {
      margin-right: 1rem;
    }
  }
`

export const SubscriptionContainer = styled.div`
  display: flex;
  justify-content: center;
  @media only screen and (max-width: ${props => props.theme.device.desktop}) {
    flex-direction: column;
    ${SubscriptionPackage} {
      ${SubscriptionPackageFooter} {
        button {
          font-size: .800rem;
        }
      }
    }
  }
`

export const SubscriptionPackageRow = styled.div`
  display: flex;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  ${Ui.BasicElement.P} {
    margin: 0;
  }
  &:not(:last-child) {
    border-bottom: 1px solid ${props => props.theme.grey2};
  }
`

  export const Intro = styled.p`
  font-size: 1.3rem;
  line-height: 1.7rem;
  margin: 2.5rem 4rem;
  text-align: center;
  display: ${props => props.offerSelected ? 'none' : 'auto'};
`

export const UpdateSubscription = styled(props => <Ui.Button.BasicButton {...props} />)`
  margin-top: 0 !important;
  margin-bottom: 1rem;
  margin-right: .5rem;
`

export const PaymentCardContainer = styled.div`
  max-width: 22rem;
  min-height: 10rem;
  ${Ui.Select.SelectContainer} {
    background-color: white;
  }
`
export const PaymentCardContainerSelect = styled.div`
  width: 4rem;
`

export const UpdateSubscriptionButton = styled(props => <Ui.Button.BasicButton {...props} />)`
  margin-top: 1rem;
  margin-right: .5rem;
`

export const PaymentCardLabelContainer = styled.div`
  margin: 1rem 0;
`

export const CardValidButton = styled(props => <Ui.Button.BasicButton {...props} />)`
  margin-top: 1rem;
`

export const CardError = styled.div`
  margin: .5rem 0;
  color: red;
`

export const ProfileContainer = styled.div`
  ${Ui.Layout.Panel} {
    display: flex;
    flex-direction: column;
  }
  ${Ui.Layout.FlexBox} {
    @media only screen and (max-width: ${props => props.theme.device.desktop}) {
      ${Ui.Form.CheckboxLargeContainer} {
        width: 100%;
      }
      flex-direction: column;
    }
  }
  ${SubscriptionPackage} {
    ${Ui.BasicElement.P} {
      line-height: 1.3rem;
    }
    ${Ui.BasicElement.H2} {
      white-space: nowrap;
      font-weight: normal;
      font-size: 1.875rem;
      margin-top: 0;
      line-height: 1rem;
      margin-bottom: 2rem;
      @media only screen and (max-width: ${props => props.theme.device.desktop}) {
        margin-top: 1rem;
        font-size: 1.5rem;
      }
    }
  }
  ${Ui.BasicElement.H1} {
    color: black;
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
    margin-top: 1.5rem;
  }
  ${Ui.BasicElement.H2} {
    //margin-bottom: 1.1rem;
  }
  ${Ui.Layout.Section} ${Ui.Layout.Section}:first-of-type {
    margin-top: 1rem;
  }
  ${Ui.Form.CheckboxLargeContainer} {
    cursor: pointer;
  }
  ${Ui.Layout.Content} {
    @media only screen and (min-width: ${props => props.theme.device.desktop}) {
      & > :not(:first-child) {
        margin-top: 5rem;
      }
    }
    @media only screen and (max-width: ${props => props.theme.device.desktop}) {
      padding: 0;
    }
    ${Ui.Layout.ButtonGroup} {
      margin-top: .7rem;
    }
    ${Ui.Form.Input} {
      &:read-only {
        border-color: transparent;
        opacity: 1;
      }
    }
  }
  ${Ui.Select.SelectContainer} {
    flex: 1;
    width: auto;
    margin-top: 1.1rem;
  }
  ${Ui.Select.SelectList} {
    max-height: 14rem;
    overflow: scroll;
  }
  ${Ui.Layout.ActionButtonsContainer} {
    justify-content: flex-end;
  }
`

export const ValidationInProgress = styled.div`
  margin-top: 2rem;
`

export const ContactUs = styled.a`
  text-decoration: underline !important;
  font-weight: bold;
`

export const MyProfileRow = styled(props => <Ui.Layout.Row {...props} />)`
  min-height: 5.5rem;
  ${Ui.Select.SelectContainer} {
    margin-top: 0.5rem;
    padding: 0.2rem 1rem;
  }
  ${Ui.SearchBar.SearchBarContainer} {
    margin-bottom: 0;
  }
  ${Ui.SearchBar.Input} {
    border-radius: 5px;
  }
  ${DropdownItem} {
    & div:first-child {
      font-weight: normal;
    }
  }
  & > div:first-child {
    flex: 0 8rem;
    margin: 0 2rem;
    @media only screen and (max-width: ${props => props.theme.device.desktop}) {
      margin: 1rem auto;
    }
    ${Ui.Form.FileContainer}, ${Ui.UploadFile.LoaderPlaceholder} {
      border-radius: 50px;
    }
    ${Ui.DocumentPreview.ImageThumbnail} {
      border-radius: 50px;
    }
  }
`

export const ButtonLargeLink = styled.div`
  display: flex;
  @media only screen and (max-width: ${props => props.theme.device.desktop}) {
    width: 100%;
  }
`

export const LightGrey = styled.span`
  color: ${props => props.theme.mediumGrey};
`

export const PasswordContainer = styled.div`
  margin-top: 1.1rem;
  outline: none;
`

export const SearchSelectContainer = styled.div`
  outline: none;
`

export const OrganizationsContainer = styled(props => <Ui.Layout.Section {...props} />)`
  ${Ui.Button.BasicButton} {
    margin-bottom: 1.4rem;
  }
`

export const HeaderButtons = styled.div`
  ${Ui.Button.Button} {
    margin-right: .5rem;
  }
  @media only screen and (max-width: ${props => props.theme.device.desktop}) {
    ${Ui.Button.Button} {
      margin-bottom: 1rem;
    }
  }
`

export const UrlBlockContainer = styled.div`
 textarea {
    margin-top: 0 !important;
  }
`

export const ShowPortalButton = styled.div`
  margin-top: .5rem;
  margin-bottom: 1.4rem;
`
