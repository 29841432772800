import * as TeleExpertise from '../State/Cohort'
import * as Questionnaire from '../State/Questionnaire'
import { takeLatest, takeEvery, call, put, select } from 'redux-saga/effects'
import * as UploadFile from '../State/UploadFile'
import * as CustomField from '../State/CustomField'
import * as Form from '../Util/Form'
import * as Api from '../Util/Api'

export function* saveField({ payload: { idx, cohortId, fieldName, stepId }}) {
  try {
    const data = yield select(Questionnaire.selectData)

    if (fieldName !== 'patientStepsImageStretch' && fieldName !== 'patientStepsImageAlign')
      yield call(Form.validateQuestionnaireStep, { idx, data: data.patientSteps.value[idx] })

    const apiData = fieldName === 'patientStepsImageStretch' || fieldName === 'patientStepsImageAlign'
      ? {
        cohort: {
          [fieldName]: data[fieldName].value
        }
      }
      : {
        cohort_patient_step: fieldName === 'date'
        ? {
          manualProgrammingDate: data.patientSteps.value[idx].manualProgrammingDate.value,
          timeMeasure: data.patientSteps.value[idx].period.value,
          timeNumber: data.patientSteps.value[idx].periodQuantity.value,
        }
        : fieldName === 'sections'
          ? {
            customFieldSections: data.patientSteps.value[idx].sections.value
          }
          : {
            [fieldName]: data.patientSteps.value[idx][fieldName].value
          }
      }

    if (fieldName !== 'patientStepsImageStretch' && fieldName !== 'patientStepsImageAlign')
      yield call(Api.updateStep, stepId.value, cohortId, apiData)
    else
      yield call(Api.updateExpertise, apiData, cohortId, 'cohort')

    if (fieldName !== 'patientStepsImageStretch' && fieldName !== 'patientStepsImageAlign' && fieldName in data.patientSteps.value[idx]) {
      yield put(Questionnaire.setEditField({ idx, fieldName, value: false }))
      yield put(Questionnaire.updateInitialValue({ idx, fieldName, value: data.patientSteps.value[idx][fieldName].value }))
    }

    yield put(Questionnaire.success())
  } catch (error) {
    console.log(error)
    if (error instanceof Form.ValidationError) {
      const [name, field] = ['title', error.data.validatedData.title]

      yield put(Questionnaire.updateField({
        idx: error.data.idx,
        name,
        value: field.value,
        errors: field.errors,
      }))

      yield put(Questionnaire.invalidate({
        message: 'Une information obligatoire est manquante pour valider la création de votre étape.',
      }
      ))
    } else {
      yield put(Questionnaire.apiError(error.message))
    }
  }
}

export function* send({ payload: { idx } }) {
  try {
    const cohortId = yield select(TeleExpertise.selectId)
    const data = yield select(Questionnaire.selectData)
    const title = data.patientSteps.value[idx].title.value

    if (title) {
      yield call(Form.validateQuestionnaireStep, { idx, data: data.patientSteps.value[idx] })

      const apiData = {
        cohort_patient_step: {
          title,
        },
      }

      if (cohortId) {
        const { patientStep: { id } } = yield call(Api.addStep, cohortId, apiData)
        yield put(Questionnaire.updateField({ idx, name: 'id', value: id }))
      }
    }

    yield put(Questionnaire.success())
  } catch (error) {
    if (error instanceof Form.ValidationError) {
      const [name, field] = ['title', error.data.validatedData.title]

      yield put(Questionnaire.updateField({
        idx: error.data.idx,
        name,
        value: field.value,
        errors: field.errors,
      }))

      yield put(Questionnaire.invalidate({
        message: 'Une information obligatoire est manquante pour valider la création de votre étape.',
      }
      ))
    } else {
      yield put(Questionnaire.apiError(error.message))
    }
  }
}

export function* removeDocument({ payload: { assetId } }) {
  try {
    const cohortId = yield select(TeleExpertise.selectId)

    if (assetId) {
      yield call(Api.removeDocument, cohortId, assetId)
    }
  } catch (error) {
    yield put(Questionnaire.apiError(error.message))
  }
}

export function* removeStep({ payload: { idx, stepId } }) {
  try {
    const cohortId = yield select(TeleExpertise.selectId)

    if (stepId && stepId.value)
      yield call(Api.removeStep, cohortId, stepId.value)
    yield put(UploadFile.cleanList(`patient-step-upload_${idx}`))

  } catch (error) {
    yield put(Questionnaire.apiError(error.message))
  }
}

export function* sendQuestionnaire({
  payload: { id:
    teleExpertiseId, type:
    teleExpertiseType = 'cohorts',
    target,
    patientCode,
    contents,
  } }) {
  try {
    const forms = yield select(CustomField.selectFormFields)
    const customFieldContents =
      Object.values(
        Object.values(
          contents
        )
      )
        .reduce((acc, {
          id,
          value,
          label,
          mandatory,
          typeOfField,
        }) => ({
          ...acc,
          [id]: {
            content: typeof value === 'string' || typeOfField === 'document'
              ? value
              : value
                .reduce((acc, id) => ({
                  ...acc,
                  [id]: forms.find(teleExpertise => teleExpertise.choices.byId[id]).choices.byId[id]
                }), {}),
            label,
            mandatory,
            typeOfField
          }
        }), {})

    const validatedCustomFieldContents = yield call(Form.validatedCustomFieldContents, customFieldContents)

    const contentsWithoutDocuments = Object
      .entries(validatedCustomFieldContents)
      .filter(([_, field]) => field.typeOfField !== 'document')
      .reduce((acc, [id, { error, ...values }]) => ({ ...acc, [id]: values }), {})

    yield call(Api.sendQuestionnaire, patientCode, contentsWithoutDocuments)
    yield put(CustomField.setEditFields({ teleExpertiseType, teleExpertiseId, target, value: false }))
    yield put(Questionnaire.success())

  } catch (error) {
    console.log(error)
    if (error instanceof Form.ValidationError) {
      for (let [id, field] of Object.entries(error.data)) {
        if (field.error)
          yield put(CustomField.setErrors({
            teleExpertiseId,
            fieldId: id,
            value: field.content,
            error: field.error,
          }))
      }
      yield put(Questionnaire.invalidate({
        message: 'Une information obligatoire est manquante pour valider les champs personnalisés du patient'
      }))
    } else {
      yield put(Questionnaire.apiError(error.message))
    }
  }
}

const QuestionnaireSagas = function* () {
  yield takeLatest(`${Questionnaire.removeStep}`, removeStep);
  yield takeLatest(`${Questionnaire.send}`, send);
  yield takeLatest(`${Questionnaire.sendQuestionnaire}`, sendQuestionnaire);
  yield takeLatest(`${Questionnaire.removeDocument}`, removeDocument);
  yield takeEvery(`${Questionnaire.saveField}`, saveField)
};

export default QuestionnaireSagas;
