import { createSlice } from '@reduxjs/toolkit'

const today = new Date()

export const INITIAL_STATE = {
  firstRange: {
    startDate: today,
    endDate: today,
    data: {
      registeredUserCount: 0,
      accountValidated: 0,
      connectedUserCount: 0,
      proPlusSubscription: 0,
      createdPatientCount: 0,
      createdOpinionCount: 0,
      createdStaffCount: 0,
      createdCohortCount: 0,
      staffSessionCount: 0,
      teleExpertiseCommentCount: 0,
    },
  },
  secondRange: {
    startDate: today,
    endDate: today,
    data: {
      registeredUserCount: 0,
      accountValidated: 0,
      connectedUserCount: 0,
      proPlusSubscription: 0,
      createdPatientCount: 0,
      createdOpinionCount: 0,
      createdStaffCount: 0,
      createdCohortCount: 0,
      staffSessionCount: 0,
      teleExpertiseCommentCount: 0,
    },
  },
  loading: false,
  error: false,
  errorMessage: null,
}

const slice = createSlice({
  name: 'dashboardAdmin',
  initialState: INITIAL_STATE,
  reducers: {

    fetch: (state, { payload: { tableName, dateType, value } }) => ({
      ...state,
      [tableName]: {
        ...state[tableName],
        [dateType]: value,
      },
      loading: true,
    }),

    received: (state, { payload: { tableName, data } }) => ({
      ...state,
      [tableName]: {
        ...state[tableName],
        data,
      },
      loading: false,
    }),

    apiError: (state, action) => ({
      ...state,
      error: true,
      errorMessage: action.payload,
      loading: false,
    }),

    clean: state => INITIAL_STATE,
  }
})

export const {
  updateDateField,
  fetch,
  received,
  apiError,
  clean,
} = slice.actions

export default slice.reducer
