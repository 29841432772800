import { apiUrl } from '../Util/Config'

export const mesureInternetSpeed = (callback) => {
  let startTime, endTime;
  let imageAddr = `${apiUrl}/bundles/app/images/picture_internet_speed.png`;
  let downloadSize = 1459093;

  let download = new Image();

  download.onload = function () {
    endTime = (new Date()).getTime();
    let duration = (endTime - startTime) / 1000;
    let bitsLoaded = downloadSize * 8;
    let speedBps = (bitsLoaded / duration).toFixed(2);
    let speedKbps = (speedBps / 1024).toFixed(2);
    let speedMbps = (speedKbps / 1024).toFixed(2);

    callback({
      speedBps: speedBps,
      speedKbps: speedKbps,
      speedMbps: speedMbps
    })
  }

  download.onerror = function (err, msg) {
    console.log("Invalid image, or error downloading")
  }

  startTime = (new Date()).getTime();
  download.src = imageAddr + '?r=' + startTime;
}
