import styled from 'styled-components'

export default styled.div`
  flex-direction: row;
  flex-wrap: wrap;
  display: inline-flex;
  color: ${props => props.theme.darkBlue};
  span {
    padding: .2rem 1rem;
    margin-top: .5rem;
    font-size: .75rem;
    font-weight: 600;
    border: 1px solid ${props => props.theme.grey2};
    border-radius: 50px;
    &:not(:last-child) {
      margin-right: .5rem;
    }
  }
`
