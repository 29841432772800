import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom'
import { useAlert } from 'react-alert'
import styled from 'styled-components'
import * as PortalPro from '../../State/PortalPro'
import * as Profile from '../../State/Profile'
import * as Registration from '../../State/Registration';
import * as Resources from '../../State/Resources';
import * as Ui from '../Ui'
import { authUrl } from '../../Util/Config'
import { normalizeName, isInternationalPhoneFormat } from '../../Util/Format'
import qs from 'qs'

const RegistrationFormComponent = ({ fromSelectPage, hasSelectedTeleExpertise, ...props }) => {
  const email = qs.parse(props.location.search, { ignoreQueryPrefix: true }).email
  const registrationCode = qs.parse(props.location.search, { ignoreQueryPrefix: true }).registrationCode
  const alert = useAlert()
  const dispatch = useDispatch()
  const success = useSelector(state => state.registration.success)
  const step = useSelector(state => state.registration.step)
  const searchRpps = useSelector(state => state.registration.searchRpps)
  const rppsData = useSelector(state => state.registration.rppsData)
  const pending = useSelector(state => state.registration.pending)
  const data = useSelector(state => state.registration.data);
  const errorMessage = useSelector(state => state.registration.errorMessage)
  const portalProId = useSelector(PortalPro.selectPortalProId)
  const portalProLogo = useSelector(PortalPro.selectLogo)
  const portalProPicture = useSelector(PortalPro.selectPicture)
  const portalProTitle = useSelector(PortalPro.selectTitle)
  const {
    [Resources.RESOURCE_DISCIPLINES]: disciplines,
  } = useSelector(Resources.selectResources)
  const {
    [Resources.RESOURCE_REGISTERED_RPPS]: allRppsRegistration,
  } = useSelector(Resources.selectResources)

  const isRegistered = step === Registration.STEP_REGISTER &&
    data.rpps.value !== '' &&
    allRppsRegistration.includes(data.rpps.value)

  useEffect(() => {
    dispatch(Registration.fetchAllRppsRegistration())
  }, [dispatch])

  useEffect(() => {
    if (email) {
      dispatch(Registration.updateField({
        name: 'email',
        value: email
      }))
    }
  }, [dispatch, email])

  useEffect(() => {
    dispatch(Profile.fetchDisciplines())
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (errorMessage)
      alert.show(errorMessage, { timeout: 5000 })
  }, [alert, errorMessage])

  const patientRef = useRef();
  const teleExpertiseRef = useRef();

  useEffect(() => {
    patientRef.current = JSON.parse(sessionStorage.getItem('patient'));
    teleExpertiseRef.current = JSON.parse(sessionStorage.getItem('teleExpertise'));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (success) {
    return <Redirect
      to={portalProTitle && !window.location.href.match(authUrl)
        ? `${authUrl}/connexion?portalId=${portalProId}&portalLogo=${portalProLogo}&portalPicture=${portalProPicture}&portalTitle=${portalProTitle.replace('&', '')}`
        : '/connexion'
      } />
  }

  return (
    <RegistrationContainer>
      <Ui.Form.FormContainer>
        <RegistrationForm
          dispatch={dispatch}
          step={step}
          searchRpps={searchRpps}
          rppsData={rppsData}
          data={data}
          pending={pending}
          disciplines={disciplines}
          isRegistered={isRegistered}
          registrationCode={registrationCode}
          patient={patientRef.current}
          teleExpertise={teleExpertiseRef.current}
        />
      </Ui.Form.FormContainer>
    </RegistrationContainer>
  )
}

export const RegistrationForm = ({
  dispatch,
  step,
  searchRpps,
  rppsData,
  data,
  pending,
  disciplines = [],
  isRegistered,
  registrationCode,
  patient,
  teleExpertise
}) => {

  const updateRppsQuery = (query) => {
    dispatch(Registration.updateRppsQuery({ query }))
  }

  const updateFields = (fields) => {
    const discipline = disciplines.find(item => fields.discipline.toLowerCase() === item.toLowerCase()) || ''
    const normalizedFields = { ...fields, discipline }

    dispatch(Registration.updateFields(normalizedFields))
    dispatch(Registration.updateStep(Registration.STEP_REGISTER))
  }

  const updateField = name => e => {
    if (
      data.plainPassword.errors[0] === 'Les mots de passe ne correspondent pas' &&
      (
        name === 'plainPassword' ||
        name === 'plainPasswordConfirmation'
      )
    ) {
      dispatch(Registration.cleanPasswordErrors())
    }

    dispatch(Registration.updateField({ name, value: e.target.value }))
  }

  const [isInternationalPhone, setIsInternationalPhone] = useState(false)

  const updatePhoneField = name => e => {
    if (isInternationalPhoneFormat(e.target.value)) {
      setIsInternationalPhone(true)
    } else {
      setIsInternationalPhone(false)
    }
    dispatch(Registration.updateField({ name, value: e.target.value }))
  }

  const liveCheck = name => e => {
    dispatch(Registration.liveCheck({ name }))
  }

  const send = registrationCode => e => {
    e.preventDefault()
    dispatch(Registration.send(registrationCode))
  }

  const [showInfo, setShowInfo] = useState(false)

  const onQueryChange = (value) => {
    dispatch(Registration.updateField({ name: 'discipline', value }))
  }

  return (
    <>
      <Ui.BasicElement.H1>Inscrivez-vous</Ui.BasicElement.H1>
      {step === Registration.STEP_RPPS || (step === Registration.STEP_REGISTER && isRegistered)
        ? <RegistrationChoice>
          {isRegistered &&
            <>
              <Ui.BasicElement.P>
                <strong>Un compte existe déjà à ce nom :</strong>
              </Ui.BasicElement.P>
              <Ui.BasicElement.P>
                {data.firstname.value} {data.lastname.value}<br />
                {[data.discipline.value,
                data.city.value,
                `RPPS: ${data.rpps.value}`].filter(e => e).join(', ')}
                <br />
              </Ui.BasicElement.P>
              <Ui.BasicElement.P>
                Si ce compte est le vôtre, <Ui.BasicElement.Link to="/connexion">cliquez ici</Ui.BasicElement.Link> pour vous connecter.
              </Ui.BasicElement.P>
              <Ui.BasicElement.Hr />
            </>
          }
          <Ui.BasicElement.H3>S'inscrire en utilisant l'Annuaire Santé :</Ui.BasicElement.H3>
          <Ui.SearchSelect
            isPlain
            query={searchRpps.value}
            loading={searchRpps.loading}
            datas={rppsData}
            itemLabel={doctor => `${doctor.firstname} ${doctor.lastname}`}
            itemSubLabel={doctor => [
              doctor.discipline,
              doctor.city.join(' | '),
              `RPPS : ${doctor.rpps}`,
            ].filter(e => e).join(' - ')}
            onQueryChange={updateRppsQuery}
            onSelectData={updateFields}
            notFoundMessage="Aucun médecin n'a été trouvé."
            placeholder="Rechercher votre nom, prénom ou numéro RPPS"
            noSubmit
          />
          <ChooseRppsOr>Ou</ChooseRppsOr>
          <ManuallyRegistration>
            <Ui.Button.BasicButton
              isGreen
              isFilled
              disabled={searchRpps.loading && searchRpps.value}
              onClick={() => isRegistered
                ? (dispatch(Registration.clean()),
                  dispatch(Registration.updateStep(Registration.STEP_REGISTER)),
                  dispatch(Registration.updateRppsQuery({ query: '' }))
                )
                : dispatch(Registration.updateStep(Registration.STEP_REGISTER))
              }
            >
              S'inscrire manuellement
            </Ui.Button.BasicButton>
          </ManuallyRegistration>
        </RegistrationChoice>
        : <>
          <form onSubmit={send(registrationCode)} noValidate>
            <Ui.Layout.FlexBox flow="column nowrap">
              <FormContainer>
                <Ui.Layout.Row>
                  <Ui.Form.TextInput
                    htmlFor="firstname"
                    label="Prénom *"
                    value={data.firstname.value}
                    onChange={updateField('firstname')}
                    autoFocus
                    error={data.firstname.errors[0]}
                    onBlur={liveCheck('firstname')}
                    noSubmit
                  />
                  <Ui.Form.TextInput
                    htmlFor="lastname"
                    label="Nom *"
                    value={data.lastname.value}
                    onChange={updateField('lastname')}
                    error={data.lastname.errors[0]}
                    onBlur={liveCheck('lastname')}
                    noSubmit
                  />
                </Ui.Layout.Row>
                <Ui.Layout.Row>
                  <Ui.SearchSelect
                    label="Discipline"
                    query={data.discipline.value}
                    loading={false}
                    datas={disciplines.filter(item => {
                      const re = new RegExp(normalizeName(data.discipline.value), 'i')
                      return re.test(normalizeName(item))
                    })}
                    itemLabel={discipline => discipline}
                    onQueryChange={onQueryChange}
                    onSelectData={query => onQueryChange(query)}
                    notFoundMessage="Aucune discipline n'a été trouvée."
                    placeholder="Rechercher votre discipline"
                    onBlur={() => dispatch(Registration.validateDiscipline())}
                    error={data.discipline.errors[0]}
                  />
                  <Ui.Form.TextInput
                    htmlFor="city"
                    label="Ville"
                    value={data.city.value}
                    onChange={updateField('city')}
                    error={data.city.errors[0]}
                    onBlur={liveCheck('city')}
                    noSubmit
                  />
                </Ui.Layout.Row>
                <Ui.Layout.Row>
                  <div>
                    <Ui.Form.TextInput
                      htmlFor="email"
                      label="Email *"
                      type="email"
                      value={data.email.value}
                      onChange={updateField('email')}
                      error={data.email.errors[0]}
                      onBlur={liveCheck('email')}
                      noSubmit
                    />
                    <FieldWarning>Utilisez votre email professionnel pour valider automatiquement votre compte</FieldWarning>
                  </div>
                  <div>
                    <Ui.Form.TextInput
                      htmlFor="cellPhone"
                      label="Téléphone portable *"
                      type="text"
                      value={data.cellPhone.value}
                      onChange={updatePhoneField('cellPhone')}
                      error={data.cellPhone.errors[0]}
                      onBlur={liveCheck('cellPhone')}
                      noSubmit
                    />
                    {isInternationalPhone
                      ? <FieldWarning>Ce n'est pas un numéro de mobile français</FieldWarning>
                      : null
                    }
                  </div>
                </Ui.Layout.Row>
                <Ui.Layout.Row>
                  <Ui.Form.TextInput
                    htmlFor="plainPassword"
                    label="Mot de passe *"
                    type="password"
                    value={data.plainPassword.value}
                    onChange={updateField('plainPassword')}
                    error={data.plainPassword.errors[0]}
                    onBlur={liveCheck('plainPassword')}
                    noSubmit
                  />
                  <Ui.Form.TextInput
                    htmlFor="plainPasswordConfirmation"
                    label="Confirmez votre mot de passe *"
                    type="password"
                    value={data.plainPasswordConfirmation.value}
                    onChange={updateField('plainPasswordConfirmation')}
                    error={data.plainPasswordConfirmation.errors[0]}
                    onBlur={liveCheck('plainPasswordConfirmation')}
                    noSubmit
                  />
                </Ui.Layout.Row>
                <Ui.Layout.Row>
                  <Ui.Form.TextInput
                    htmlFor="rpps"
                    label="RPPS"
                    type="text"
                    value={data.rpps.value}
                    onChange={updateField('rpps')}
                    error={data.rpps.errors[0]}
                    onBlur={() => dispatch(Registration.validateRpps())}
                    noSubmit
                  />
                </Ui.Layout.Row>
              </FormContainer>
              <p>Votre code de connexion sera envoyé par {isInternationalPhone ? 'email' : 'SMS'}</p>

              <SubmitButton
                disabled={pending}
                isFilled
                isGreen
                isMedium
                type="submit"
              >
                S'inscrire
              </SubmitButton>
            </Ui.Layout.FlexBox>
          </form>
          <Ui.PortailLayout.Informations>
            <Ui.BasicElement.P medium><i>Les informations marquées d'un (*) sont obligatoires pour pouvoir vous inscrire.
              Les données personnelles collectées <ShowHideLink onClick={() => setShowInfo(true)} visible={showInfo}>...voir plus</ShowHideLink><ShowHide visible={showInfo}> via ce formulaire (combinées avec les données obtenues via les cookies ou autres traceurs) seront traitées par la société SKEZI, responsable des traitements de données réalisés, pour créer et gérer votre compte utilisateur sur la plateforme.
                Les données sont hébergées chez un Hébergeur de Données de Santé certifié conforme à la norme NF ISO/CEI 27001.
                Selon les finalités de chaque traitement de données et sous certaines conditions, vous disposez de différents droits (droit d'accès à vos données, droit de rectification et d'effacement de vos données, droit de définir le sort de vos données après votre décès). Pour exercer ces droits, vous pouvez écrire à dpo@skezi.eu ou à l’adresse postale suivante : DPO – SKEZI, Les Papeteries, 1 Esplanade Augustin Aussedat, Cran Gevrier, 74960 Annecy.
                Pour plus de détails sur le traitement de vos données, voir notre politique de confidentialité.
                <br /><br />Enfin, vous disposez également du droit de saisir la CNIL si vous estimez, après nous avoir contacté, que vos droits ne sont pas respectés.</ShowHide></i></Ui.BasicElement.P>
          </Ui.PortailLayout.Informations>
        </>
      }
      <HasAccount>
        Vous avez déjà un compte ? <Ui.BasicElement.Link to="connexion">Connectez-vous</Ui.BasicElement.Link>
      </HasAccount>
    </>
  )
}

const HasAccount = styled.p`
  margin-top: 2rem;
  font-size: 1.1rem;
  line-height: 1.6rem;
  ${Ui.BasicElement.Link} {
    font-size: 1.1rem;
  }
`

const FormContainer = styled(props => <Ui.Form.FormGroup {...props} />)`
  ${Ui.Layout.Row} {
    /* min-height: 5.5rem; */
    @media only screen and (max-width: ${props => props.theme.device.desktop}) {
      flex-direction: column;
      ${Ui.Form.Label} {
        margin-top: 1rem;
      }
    }
  }
`

const ShowHide = styled.span`
  display: ${props => props.visible ? 'inline' : 'none'};
`

const ShowHideLink = styled.span`
  display: ${props => !props.visible ? 'inline' : 'none'};
  color: ${props => props.theme.patientGreen};
  font-weight: 700;
  cursor: pointer;
`

const FieldWarning = styled(props => <Ui.BasicElement.P {...props} />)``

const SubmitButton = styled(props => <Ui.Button.BasicButton {...props} />)`
  margin-right: 1.1rem;
  margin: 0 auto;
  align-self: center;
  width: 16rem;
  @media only screen and (max-width: ${props => props.theme.device.desktop}) {
    display: block;
  }
`

const RegistrationContainer = styled(props => <Ui.PortailLayout.PageContainer {...props} />)`
  ${Ui.BasicElement.H1} {
    margin-bottom: 1.4rem;
    font-size: 2rem;
    line-height: 2.5rem;
  }
  ${Ui.Button.Back} {
    margin-bottom: 2rem;
  }
  ${Ui.Form.InputContainer} {
    margin-bottom: .15rem;
  }
  ${Ui.PortailLayout.Informations} {
    ${Ui.BasicElement.P} {
      font-size: .65rem;
      line-height: 1rem;
    }
  }
  ${Ui.Form.FormContainer} {
    padding: 1.8rem;
    background: white;
    box-shadow: 0 2px 5px 1px rgba(134, 174, 197, 0.24);
    border-radius: 8px;
    @media only screen and (min-width: ${props => props.theme.device.desktop}) {
      width: 600px;
      margin: 3rem auto;
    }
  }
  ${Ui.Form.FormGroup} {
    margin: 0;
    ${Ui.BasicElement.P} {
      margin-top: 2.2rem;
      margin-bottom: 0;
      font-size: .875rem;
    }
    ${FieldWarning} {
      margin-top: .5rem;
    }
  }
  ${Ui.PortailLayout.Content} {
    flex: 50%;
  }
  ${Ui.PortailLayout.Figure} {
    flex: 50%;
    margin-left: 2rem;
  }
`

export const RegistrationChoice = styled.div`
  margin-top: 0;
  ${Ui.BasicElement.Hr} {
    margin: 1.5rem 0;
  }
`

const ChooseRppsOr = styled.div`
  text-align: center;
  margin: 1.5rem 0;
`

const ManuallyRegistration = styled.div`
  display: flex;
  justify-content: center;
`

export default RegistrationFormComponent;
