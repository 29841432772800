import styled from 'styled-components'

export const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  margin-top: 1rem;
  margin-bottom: 1.4rem;
  padding: 0;
  flex: 1;
  @media only screen and (max-width: ${props => props.theme.device.desktop}) {
    flex-flow: row wrap;
    align-items: stretch;
    justify-content: center;
  }
`

export const FilterContainer = styled.div`
  @media only screen and (max-width: ${props => props.theme.device.mobile}) {
    display: none;
  }
`

export const FiltersAndSorting = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const FilterSection = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  > i {
    display: block;
    font-size: 1.5rem;
    color: ${props => props.theme.grey};
    cursor: pointer;
    &:hover {
      color: ${props => props.theme.blueGrey};
    }
  }

  > div:not(:last-child), > i:not(:last-child) {
    margin-right: 1rem;
  }
`

export const MobileActionButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;

  @media only screen and (min-width: ${props => props.theme.device.mobile}) {
    display: none;
  }
`

export const LoadMoreButtonContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;

  @media only screen and (max-width: ${props => props.theme.device.mobile}) {
    align-items: stretch;
  }
`

export const BottomBarContainer = styled.div`
  position: fixed;
  bottom: 0;
  display: flex;
  transition: all .5s;
  justify-content: space-between;
  align-items: center;
  flex-flow: row nowrap;
  padding: 1rem 5.5rem 1rem 2rem;
  transform: translateY(${props => props.isOpen ? '0' : '120px'});
  box-shadow: ${props => props.theme.boxShadow.thin};
  background: ${props => props.theme.white};
  width: 100vw;
  box-sizing: border-box;

  @media only screen and (max-width: ${props => props.theme.device.mobile}) {
    padding-left: .6rem;
    padding-right: .6rem;

    p:first-child {
      display: none;
    }
  }

  @media only screen
    and (min-width: ${props => props.theme.device.mobile})
    and (max-width: ${props => props.theme.device.desktop}) {
    padding-left: calc(68px + 2rem);
  }

  @media only screen and (min-width: ${props => props.theme.device.desktop}) {
    padding-left: calc(68px + 359px + 2rem);
  }

  div:last-child {
    display: inline-flex;
    align-items: center;

    > label {
      margin-right: .6rem;
    }
  }
`

export const NoResult = styled.div`
  text-align: center;
  margin-top: 2rem;
`
