import { all, fork } from 'redux-saga/effects'
import homepage from './Homepage'
import registration from './Registration'
import PortalPro from './PortalPro'
import login from './Login'
import totp from './Totp'
import patient from './Patient'
import colleague from './Colleague'
import profile from './Profile'
import comment from './Comment'
import patientList from './PatientList'
import staffList from './StaffList'
import OpinionList from './OpinionList'
import cohortList from './CohortList'
import colleagueList from './ColleagueList'
import messageList from './MessageList'
import message from './Message'
import staff from './Staff'
import opinion from './Opinion'
import cohort from './Cohort'
import uploadFile from './UploadFile'
import resources from './Resources'
import customField from './CustomField'
import organization from './Organization'
import videoConference from './VideoConference'
import contactUs from './ContactUs'
import questionnaire from './Questionnaire'
import workList from './WorkList'
import accountValidation from './AccountValidation'
import dicom from './Dicom'
import invitation from './Invitation'
import dashboardAdmin from './DashboardAdmin'

const Sagas = function* () {
  yield all([
    fork(homepage),
    fork(registration),
    fork(PortalPro),
    fork(login),
    fork(totp),
    fork(patient),
    fork(colleague),
    fork(profile),
    fork(comment),
    fork(patientList),
    fork(OpinionList),
    fork(cohortList),
    fork(staffList),
    fork(colleagueList),
    fork(messageList),
    fork(message),
    fork(staff),
    fork(opinion),
    fork(cohort),
    fork(uploadFile),
    fork(resources),
    fork(customField),
    fork(organization),
    fork(videoConference),
    fork(contactUs),
    fork(questionnaire),
    fork(workList),
    fork(accountValidation),
    fork(dicom),
    fork(invitation),
    fork(dashboardAdmin),
  ]);
}

export default Sagas;
