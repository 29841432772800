import Pdf from '../Asset/Image/icon-doc-pdf.svg'
import Xls from '../Asset/Image/icon-doc-xls.svg'
import Word from '../Asset/Image/icon-doc-word.svg'
import Ppt from '../Asset/Image/icon-doc-ppt.svg'
import Zip from '../Asset/Image/icon-doc-zip.svg'
import Txt from '../Asset/Image/icon-doc-txt.svg'
import Jpg from '../Asset/Image/icon-doc-jpg.svg'
import Gif from '../Asset/Image/icon-doc-gif.svg'
import Tiff from '../Asset/Image/icon-doc-tiff.svg'
import Png from '../Asset/Image/icon-doc-png.svg'
import Mpg from '../Asset/Image/icon-doc-mpg.svg'
import Mp4 from '../Asset/Image/icon-doc-mp4.svg'

export const mimeTypeIcon = (mimeType) => {
  const fileType = mimeType.split('/')[1]

  switch (fileType) {
    case 'pdf':
      return Pdf
    case 'vnd.ms-excel':
    case 'vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return Xls
    case 'msword':
    case 'vnd.openxmlformats-officedocument.wordprocessingml.document':
      return Word
    case 'vnd.ms-powerpoint':
    case 'vnd.openxmlformats-officedocument.presentationml.presentation':
    case 'pptx':
      return Ppt
    case 'zip':
    case 'x-7z-compressed':
      return Zip
    case 'jpg':
    case 'jpeg':
      return Jpg
    case 'plain':
      return Txt
    case 'gif':
      return Gif
    case 'tiff':
      return Tiff
    case 'mpeg':
    case 'x-msvideo':
      return Mpg
    case 'mp4':
      return Mp4
    case 'png':
      return Png
    default:
      return Word
  }
}
