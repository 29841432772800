import qs from 'qs'
import * as ColleagueList from '../State/ColleagueList'
import * as PatientList from '../State/PatientList'
import basicFetch from '../Util/BasicFetch'
import { fr } from '../Util/i18n'
import {
  apiUrl,
  apiSymfony6Url,
  SearchTeleExpertise,
  projectsName,
  projectsLocations,
  projectsDatasets,
  projectsDicomStores,
  privateSitePrefix
} from './Config'
import pluralize from '../Util/Pluralize'
import { dateToString } from './Format'

const TELEEXPERTISE_STAFF = 'staffs'
const TELEEXPERTISE_COHORT = 'cohorts'
const TELEEXPERTISE_OPINION = 'opinions'

/**************/
/* Public Front */
/**************/

export async function fetchHomepageTeleExpertises(filters, { signal }) {
  try {
    const response = await fetch(`${apiUrl}/public/teleexpertise-to-send-a-patient/${filters.teleexpertiseType}?search=${filters.search}`,
      {
        method: 'GET',
        signal,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      },
    )
    if (!response.ok) {
      throw Error('')
    }

    const { keywordsNotFound, staffs = [], opinions = [], cohorts = [] } = await response.json()

    return {
      keywordsNotFound,
      teleExpertises: [
        ...staffs.map(staff => ({
          ...staff,
          type: TELEEXPERTISE_STAFF
        })),
        ...cohorts.map(cohort => ({
          ...cohort,
          type: TELEEXPERTISE_COHORT
        })),
        ...opinions.map(opinion => ({
          ...opinion,
          type: TELEEXPERTISE_OPINION
        })),
      ],
    }
  } catch (e) {
    throw new Error('Une erreur est survenue pendant la récupération des téléexpertises. Veuillez réessayer plus tard.')
  }
}

export async function fetchPublicPortalPro(filters, { signal }) {
  try {
    const response = await fetch(`${apiUrl}/public/portal-pro/${filters.portalProHost}?teleexpertiseType=${filters.teleExpertiseOpened}&teleexpertiseSearch=${filters.search}`,
      {
        method: 'GET',
        signal,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      },
    )
    if (!response.ok) {
      throw Error('')
    }

    const json = await response.json()
    return json
  } catch (e) {
    throw new Error('Une erreur est survenue pendant la récupération des établissements, veuillez réessayer plus tard.')
  }
}

export const fetchAllRppsRegistration = () => basicFetch.get({
  path: '/public/all-rpps-registration',
  errorMessage: 'Une erreur est survenue. Veuillez réessayer plus tard.'
})

export async function fetchRppsDatas(query, { signal }) {
  try {
    const response = await fetch(`${apiUrl}${window.location.pathname.includes(`${privateSitePrefix}/`) ? '' : '/public'}/find-rpps-datas?q=${query}&limit=15`,
      {
        method: 'GET',
        signal,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      },
    )

    const json = await response.json()
    return json
  } catch (e) {
    throw new Error('Une erreur est survenue pendant la récupération des données RPPS, veuillez réessayer plus tard.')
  }
}

export async function register(data) {
  try {
    const response = await fetch(
      `${apiUrl}${window.location.pathname.includes(`${privateSitePrefix}/`) ? '' : '/public'}/users/register`,
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          registration: {
            'email': data.email,
            'firstname': data.firstname,
            'lastname': data.lastname,
            'plainPassword': data.plainPassword,
            'cellPhone': data.cellPhone,
            'speciality': data.discipline,
            'city': data.city,
          },
          rpps: data.rpps,
          registrationCode: data.registrationCode,
        }),
        credentials: 'include',
      },
    )

    const json = await response.json()

    if (json.errors) {
      const error = Error()
      error.errors = json.errors

      throw error
    }

    return json
  } catch (e) {
    if (e.errors && e.errors['registration[email]']) {
      throw Error('Un compte existe déjà avec cette adresse email.')
    }

    throw new Error('Une erreur est survenue pendant l\'inscription. Veuillez réessayer plus tard.')
  }
}

export const authenticate = async ({
  email = '',
  password = '',
}) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/public/check-password`,
    {
      method: 'POST',
      body: JSON.stringify({
        email,
        password,
      }),
      credentials: 'include',
    }
  )

  if (!response.ok) {
    throw Error('Une erreur est survenue. Veuillez nous excuser et réessayer plus tard.')
  }

  const data = await response.json()

  if (data.status === 2) {
    throw Error('Cet email n\'existe pas')
  }

  if (data.status === 3) {
    throw Error(`Le mot de passe est incorrect. Veuillez réessayer ou réinitialisez votre mot de passe. ${data.trialRemain === 1 ? 'C\'est votre dernier essai.' : ''}`)
  }

  if (data.status === 4) {
    throw Error(`Votre accès a été suspendu suite à 3 erreurs de mot de passe.
      Veuillez attendre ${data.minuteRemainBlockedAccount === 0
        ? 'moins d\'une minute'
        : `${data.minuteRemainBlockedAccount} ${pluralize('minute', data.minuteRemainBlockedAccount)} avant de réessayer.`
      }`
    )
  }

  if (data.status !== 1) {
    throw Error('Une erreur est survenue. Veuillez nous excuser et réessayer plus tard.')
  }

  return {
    mode: data.mode,
    directLogin: data.accessToken ? true : false,
  }
}

export async function sendUserBlockedToAdmin() {
  const response = await fetch(`${apiUrl}/public/users/informed-blocked`,
    {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    },
  )

  const json = await response.json()
  return json
}

/*:: { mode: string, sessionids: * } => Promise<Void, Error> */
export const sendOTPDemand = async ({
  email = '',
  mode = '',
}) => {
  // if (process.env.REACT_APP_MOCK_AUTHENTICATION && process.env.REACT_APP_MOCK_AUTHENTICATION !== "false") {
  //   return
  // }

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/public/send-one-time-password`,
    {
      method: 'POST',
      body: JSON.stringify({
        email,
        mode,
      }),
    }
  )

  if (!response.ok) {
    throw Error('Une erreur est survenue. Veuillez nous excuser et réessayer plus tard.')
  }

  const data = await response.json()

  return data
}

export const checkOtp = async ({
  otp = '',
  sessionids = ''
}) => {
  if (process.env.REACT_APP_MOCK_AUTHENTICATION && "false" !== process.env.REACT_APP_MOCK_AUTHENTICATION) {
    return
  }

  const response = await fetch(
    `${process.env.REACT_APP_AUTH_URL}/authenticationids/externalwebservices/restotpcheck.php`,
    {
      method: 'POST',
      body: JSON.stringify({
        otp,
        sessionids,
      }),
      credentials: 'include',
    },
  )

  if (!response.ok) {
    throw Error('Une erreur est survenue. Veuillez nous excuser et réessayer plus tard.')
  }

  const data = await response.json()

  if (data.status !== 0) {
    throw Error('Une erreur est survenue. Veuillez nous excuser et réessayer plus tard.')
  }

  return data
}

export const sendOTPCode = async ({
  code = '',
  email = '',
}) => {
  // if (process.env.REACT_APP_MOCK_AUTHENTICATION && "false" !== process.env.REACT_APP_MOCK_AUTHENTICATION) {
  //   return
  // }

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/public/check-one-time-password`,
    {
      method: 'POST',
      body: JSON.stringify({
        OTP: code,
        email,
      }),
      credentials: 'include',
    }
  )

  if (!response.ok) {
    throw Error('Une erreur est survenue. Veuillez nous excuser et réessayer plus tard.')
  }

  const data = await response.json()

  if (data.status === 6) {
    throw Error('Le code est invalide. Veuillez réessayer ou demander un nouveau code.')
  } else if (data.status === 7) {
    throw Error(`Votre accès a été suspendu suite à 3 erreurs de mot de passe.
      Veuillez attendre ${data.minuteRemainBlockedAccount === 0
        ? 'moins d\'une minute'
        : `${data.minuteRemainBlockedAccount} ${pluralize('minute', data.minuteRemainBlockedAccount)} avant de réessayer.`
      }`
    )
  }
}

export const getSecretHandle = () => basicFetch.get({
  path: `/users/get-secret-handle`,
  errorMessage: 'Une erreur est survenue pendant l\'enregistrement sécurisé de votre poste. Veuillez réessayer plus tard.'
})

export async function resetPassword(email) {
  try {
    const formData = new FormData()
    formData.append('reset_password[email]', email)
    const response = await fetch(`${apiUrl}/public/users/reset-password`,
      {
        method: 'POST',
        body: formData,
        credentials: 'include',
      },
    )
    const json = await response.json()

    if (json.message.indexOf('Email inconnu') !== -1) {
      throw Error('Email inconnu')
    }

    return json
  } catch (e) {
    if (e.message === 'Email inconnu') {
      throw Error('Aucun compte n\'a été trouvé avec cette adresse email.')
    }

    throw Error('Une erreur est survenue durant la réinitialisation du mot de passe. Veuillez réessayer plus tard.')
  }
}

/***************/
/* Private Front */
/***************/

export const logOut = () => basicFetch.get({
  path: `/logout`,
  errorMessage: 'Une erreur est survenue pendant la déconnexion.'
})

export const isLogged = async function () {
  if (window.location.pathname.includes(`${privateSitePrefix}/`)) {
    const response = await fetch(`${apiUrl}/users/is-logged`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('accessToken')
      },
      credentials: 'include',
    })

    if (403 === response.status) {
      window.location = process.env.REACT_APP_AUTH_URL
    }

    const json = await response.json()
    return json
  }
}

export const fetchLastDeploymentDatetime = () => basicFetch.get({
  path: `/public/checkLastDeploymentDatetime?lastDeploymentDatetime=${localStorage.getItem('lastDeploymentDatetime')}`,
  errorMessage: 'Une erreur est survenue pendant la récupération de la dernière mis à jour du site. Veuillez réessayer plus tard.'
})

export const checkAccessTokenFromSkezi = (accessTokenFromSkezi = null) => basicFetch.post({
  path: `/public/check-access-token-from-skezi`,
  body: JSON.stringify({ accessTokenFromSkezi }),
  errorMessage: 'Une erreur est survenue. Veuillez réessayer plus tard.',
})

// TODO : generalize basicFetch call
export const fetchCurrentUser = () => basicFetch.get({
  path: `/users/current`,
  errorMessage: 'Une erreur est survenue pendant la récupération de l\'utilisateur courant. Veuillez réessayer plus tard.'
})

export const fetchCurrentUserSecretHandle = () => basicFetch.get({
  path: `/totp`,
  errorMessage: 'Une erreur est survenue. Veuillez réessayer plus tard.'
})

export const validateAccount = ({ userCode }) => basicFetch.patch({
  path: `/users/validate-account`,
  body: JSON.stringify({ userCode: userCode }),
  errorMessage: 'Une erreur est survenue pendant la validation de votre compte. Veuillez réessayer plus tard.'
})

// TODO : handle api addPatient errors
export async function addPatient(data) {
  let errorMessage = 'Une erreur est survenue pendant la création du patient. Veuillez réessayer plus tard.'
  try {
    const response = await fetch(
      `${apiUrl}/patients/create`,
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...data,
        }),
        credentials: 'include',
      },
    )

    if (response.status === 403) {
      isLogged()
    }

    const json = await response.json()

    if (response.status === 409) {
      const error = Error()
      error.message = 'Patient exists'
      error.patientId = json?.duplicateDatas?.id
      error.doctor = json?.duplicateDatas?.otherDoctor
      throw error
    }

    if (!response.ok) {
      throw Error('')
    }

    return json
  } catch (error) {
    if (error.patientId)
      throw error
    else
      throw new Error(errorMessage)
  }
}

export async function sendInvitation(email, message, patients, recipients, teleExpertisesIds, sentFromPatient = false) {
  try {
    const response = await fetch(
      `${apiUrl}/invitations/create`,
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          noMail: false,
          sentFromPatient,
          invitation: {
            emails: recipients,
            message,
            patients,
            ...teleExpertisesIds,
          }
        }),
        credentials: 'include',
      },
    )

    if (400 === response.status) {
      throw Error('Cette adresse email est déjà associée à un compte ShareConfrère. Recherchez le nom du médecin.')
    } else if (403 === response.status) {
      throw Error('Vous ne possèdez pas les droits nécessaires à cette action.')
    }

    if (!response.ok) {
      throw Error('')
    }

    const json = await response.json()

    return json
  } catch (e) {
    if (e.message)
      throw new Error(e.message)
    else
      throw new Error('Une erreur est survenue pendant l\'envoi de l\'invitation. Veuillez réessayer plus tard.')
  }
}

export const fetchAttachments = (id) => basicFetch.get({
  path: `/attachments/patients/${id}`,
  errorMessage: 'Une erreur est survenue pendant la récupération des documents liés. Veuillez réessayer plus tard.'
})

export const updatePatientCohort = (cohortId, patients) => basicFetch.patch({
  path: `/cohorts/${cohortId}`,
  body: JSON.stringify({ cohort: { patients } }),
  errorMessage: 'Une erreur est survenue pendant la mise à jour des patients. Veuillez réessayer plus tard.'
})

export const linkAssetsToUser = ({ assets, saveEndpoint }) => {

  return basicFetch.post({
    path: `${saveEndpoint}`,
    body: JSON.stringify({ attachment: { title: 'Sans titre', assets } }),
    errorMessage: 'Une erreur est survenue. Veuillez réessayer plus tard.',
  })
}

export const fetchPatientTeleexpertise = (id, type, query) => {
  const params = '&order=desc&sort=updatedAt&offset=0&onlyArchived=0&addManaged=1&addIfMember=1&onlyMine=1&new_request=0&waiting_request=0&done_request=0&addPersonal=0'

  return basicFetch.get({
    path: query
      ? `/${type}/all?q=${query}${params}`
      : `/${type}/all?q=${params}`
    ,
    errorMessage: 'Une erreur est survenue pendant la récupération d\'une téléexpertise. Veuillez réessayer plus tard.'
  })
}

export async function getTeleExpertises(id, query) {
  try {
    const [
      staffs,
      cohorts,
      opinions
    ] = await Promise.all([
      fetchPatientTeleexpertise(id, TELEEXPERTISE_STAFF, query),
      fetchPatientTeleexpertise(id, TELEEXPERTISE_COHORT, query),
      fetchPatientTeleexpertise(id, TELEEXPERTISE_OPINION, query)
    ]);

    return [
      ...staffs.staffs.map(staff => ({
        ...staff,
        type: TELEEXPERTISE_STAFF
      })),
      ...cohorts.cohorts.map(cohort => ({
        ...cohort,
        type: TELEEXPERTISE_COHORT
      })),
      ...opinions.opinions.map(opinion => ({
        ...opinion,
        type: TELEEXPERTISE_OPINION
      })),
    ]
  } catch (e) {
    console.log(e)
    throw new Error('Une erreur est survenue pendant la récupération des téléexpertises. Veuillez réessayer plus tard.')
  }
}

export async function getTeleExpertisesByType(id, type, query) {
  try {
    const expertise = await fetchPatientTeleexpertise(id, type, query)
    return [
      ...expertise[type].map(expertiseType => ({
        ...expertiseType,
        type
      }))]

  } catch (e) {
    throw new Error('Une erreur est survenue pendant la récupération des téléexpertises. Veuillez réessayer plus tard.')
  }
}

export const fetchPatientTeleexpertiseAll = (id, type, query) => basicFetch.get({
  path: query ? `/users/${id}/${type}?q=${query}` : `/users/${id}/${type}`,
  errorMessage: 'Une erreur est survenue pendant la récupération d\'une téléexpertise. Veuillez réessayer plus tard.'
})

export async function getTeleExpertisesAll(types, query, limit) {
  try {
    const response = await fetch(`${apiUrl}/users/current/tele-expertises-with-complementary-information?q=${query}&teleExpertiseTypes=${types}&onlyMine=0&addManaged=1&addIfMember=1&onlyArchived=0&limit=${limit}`,
      {
        method: 'GET',
        credentials: 'include',
      },
    )

    if (403 === response.status) {
      isLogged()
    }

    const { staffs, opinions, cohorts, keywordsNotFound = [] } = await response.json()
    return {
      teleExpertises: [
        ...staffs.map(staff => ({
          ...staff,
          type: TELEEXPERTISE_STAFF
        })),
        ...cohorts.map(cohort => ({
          ...cohort,
          type: TELEEXPERTISE_COHORT
        })),
        ...opinions.map(opinion => ({
          ...opinion,
          type: TELEEXPERTISE_OPINION
        })),
      ],
      keywordsNotFound
    }
  } catch (e) {
    throw new Error('Une erreur est survenue pendant la récupération des téléexpertises. Veuillez réessayer plus tard.')
  }
}

export async function getTeleExpertisesAllNoCf(types, query, onlyLocked = 0) {
  try {
    const response = await fetch(`${apiUrl}/users/current/tele-expertises?q=${query}&limit=25&teleExpertiseTypes=${types}&onlyMine=1&addManaged=1&onlyLocked=${onlyLocked}`,
      {
        method: 'GET',
        credentials: 'include',
      },
    )

    if (403 === response.status) {
      isLogged()
    }

    const { staffs, opinions, cohorts, keywordsNotFound = [] } = await response.json()
    return {
      teleExpertises: [
        ...staffs.map(staff => ({
          ...staff,
          type: TELEEXPERTISE_STAFF
        })),
        ...cohorts.map(cohort => ({
          ...cohort,
          type: TELEEXPERTISE_COHORT
        })),
        ...opinions.map(opinion => ({
          ...opinion,
          type: TELEEXPERTISE_OPINION
        })),
      ],
      keywordsNotFound
    }
  } catch (e) {
    throw new Error('Une erreur est survenue pendant la récupération des téléexpertises. Veuillez réessayer plus tard.')
  }
}

export async function getTeleExpertisesByTypeAll(id, type, query) {
  try {
    const expertise = await fetchPatientTeleexpertiseAll(id, type, query)
    return (type === 'opinions')
      ? [
        ...expertise.map(expertiseType => ({
          ...expertiseType,
          type
        }))]
      : [
        ...expertise[type].map(expertiseType => ({
          ...expertiseType,
          type
        }))]

  } catch (e) {
    throw new Error('Une erreur est survenue pendant la récupération des téléexpertises. Veuillez réessayer plus tard.')
  }
}

export async function getTeleExpertise(expertiseType, id) {
  try {
    const singularType = expertiseType.replace(/s$/, '')
    const response = await fetch(`${apiUrl}/users/teleexpertise-${singularType}/${id}`,
      {
        credentials: 'include'
      })

    if (403 === response.status) {
      isLogged()
    }

    if (!response.ok) {
      throw Error('')
    }

    const json = await response.json()

    return {
      ...json[singularType],
      owner: { ...json[`${singularType}Owner`] },
      type: expertiseType,
    }
  } catch (e) {
    throw new Error('Une erreur est survenue lors de la récupération de la téléexpertise. Veuillez réessayer plus tard.')
  }
}

export const fetchPatients = ({
  statuses = [
    PatientList.STATUS_WAITING,
    PatientList.STATUS_DONE,
  ],
  orderBy = PatientList.ORDER_BY_LATEST,
  displayBy = PatientList.DISPLAY_MINE,
  search = '',
  limit = 25,
  offset = 0,
} = {},
  { signal }) => {
  const queries = qs.stringify({
    sex: 'both',
    q: search,
    sort: orderBy,
    limit,
    offset,
    onlyOwned: 'true',
    ownedOrShared: displayBy === PatientList.DISPLAY_SHARED
      ? 'shared'
      : displayBy === PatientList.DISPLAY_MINE
        ? 'owned'
        : 'all',
    addWaitingRequest: displayBy === PatientList.DISPLAY_MINE ? '1' : '0',
    onlyWaitingRequest: statuses.includes(PatientList.STATUS_WAITING) ? '1' : '0',
    onlyDoneRequest: statuses.includes(PatientList.STATUS_DONE) ? '1' : '0',
  })
  return basicFetch.get({
    path: `/patients/all?${queries}`,
    signal,
    errorMessage: 'Impossible de charger la liste des patients. Veuillez réessayer plus tard.'
  })
}

export const fetchColleagues = ({
  displayBy = ColleagueList.DISPLAY_DOCTOR,
  filterBy = ColleagueList.DISPLAY_ALL_COLLEAGUES,
  search = '',
  limit = 25,
  offset = 0,
} = {},
  { signal }) => {
  const queries = qs.stringify({
    type: displayBy === ColleagueList.DISPLAY_DOCTOR
      ? 'all'
      : displayBy === ColleagueList.DISPLAY_DOCTOR
        ? 'doctors'
        : 'institutions',
    q: search,
    onlyMine: filterBy === ColleagueList.DISPLAY_ALL_COLLEAGUES ? '0' : '1',
    limit,
    offset,
  })

  return basicFetch.get({
    path: `/users/current/colleagues?${queries}`,
    signal,
    errorMessage: 'Impossible de charger la liste des médecins. Veuillez réessayer plus tard.'
  })
}

export const fetchColleaguesNoSignal = (query) => basicFetch.get({
  path: `/users/current/colleagues?type=all&q=${query}&onlyMine=0&limit=18&offset=0`,
  errorMessage: 'Impossible de charger la liste des médecins. Veuillez réessayer plus tard.'
})

export const fetchTeleExpertises = ({
  statuses = [
    SearchTeleExpertise.STATUS_WAITING,
    SearchTeleExpertise.STATUS_DONE,
  ],
  displayBy = SearchTeleExpertise.DISPLAY_ALL,
  orderBy = SearchTeleExpertise.ORDER_BY_TITLE,
  search = '',
  limit = 25,
  offset = 0,
} = {},
  expertiseName,
  { signal }) => {
  const queries = qs.stringify({
    q: search,
    order: orderBy === SearchTeleExpertise.ORDER_BY_TITLE
      ? 'asc'
      : 'desc',
    sort: orderBy === SearchTeleExpertise.ORDER_BY_TITLE
      ? 'name'
      : 'updatedAt',
    limit,
    offset,
    onlyMine: (displayBy === SearchTeleExpertise.DISPLAY_ALL &&
      displayBy !== SearchTeleExpertise.DISPLAY_ARCHIVED) ? '0' : '1',
    addManaged: displayBy === SearchTeleExpertise.DISPLAY_ARCHIVED ? '0' : '1',
    addIfMember: displayBy === SearchTeleExpertise.DISPLAY_ARCHIVED ? '0' : '1',
    onlyArchived: displayBy === SearchTeleExpertise.DISPLAY_ARCHIVED ? '1' : '0',
    waiting_request: statuses.includes(PatientList.STATUS_WAITING) ? '1' : '0',
    done_request: statuses.includes(PatientList.STATUS_DONE) ? '1' : '0',
  })
  return basicFetch.get({
    path: `/${expertiseName}s/all?${queries}`,
    signal,
    errorMessage: `Une erreur est survenue durant la récupération des ${fr[expertiseName].expertises}. Veuillez nous excuser et réessayer plus tard.`
  })
}

export const fetchColleague = (id) => basicFetch.get({
  path: `/users/colleague/${id}`,
  errorMessage: 'Une erreur est survenue lors de la récupération du médecin. Veuillez réessayer plus tard.'
})

export const fetchPatient = (id) => basicFetch.get({
  path: `/patients/${id}`,
  errorMessage: 'Une erreur est survenue lors de la récupération du patient. Veuillez réessayer plus tard.'
})

export const fetchPatientsByUser = (userId, query, params) => basicFetch.get({
  path: `/users/${userId}/patients?q=${query}${params}`,
  errorMessage: 'Impossible de charger les patients disponibles pour ce staff. Merci de réessayer plus tard.'
})

export const deletePatient = (patientId) => basicFetch.delete({
  path: `/patients/${patientId}`,
  errorMessage: 'Une erreur est survenue lors de la suppression du patient. Veuillez réessayer plus tard.'
})

export const updatePatient = (patientId, data) => basicFetch.patch({
  path: `/patients/${patientId}`,
  body: JSON.stringify({ ...data }),
  errorMessage: 'Une erreur est survenue lors de la mise à jour du patient. Veuillez réessayer plus tard.',
})

export const fetchComments = (id) => basicFetch.get({
  path: `/patients/${id}/comments`,
  errorMessage: 'Une erreur est survenue lors de la récupération des commentaires. Veuillez réessayer plus tard.'
})

export const deleteComment = (commentId) => basicFetch.delete({
  path: `/comments/${commentId}`,
  errorMessage: 'Une erreur est survenue lors de la suppression du commentaire. Veuillez réessayer plus tard.',
})

export const updateComment = (commentId, comment) => basicFetch.patch({
  path: `/comments/${commentId}`,
  body: JSON.stringify({ content: comment }),
  errorMessage: 'Une erreur est survenue lors de la mise à jour du commentaire. Veuillez réessayer plus tard.',
})

export const sendComment = ({ comment }, patientId) => basicFetch.post({
  path: `/patients/${patientId}/comments`,
  body: JSON.stringify({ comment }),
  errorMessage: 'Une erreur est survenue lors de l\'envoi du commentaire. Veuillez réessayer plus tard.',
})

export const sendExpertiseComment = ({ comment }, type, patientId, teleExpertiseId) => basicFetch.post({
  path: `/patients/${patientId}/${type}s/${teleExpertiseId}/comments`,
  body: JSON.stringify({ comment }),
  errorMessage: 'Une erreur est survenue lors de l\'envoi du commentaire. Veuillez réessayer plus tard.',
})

export const removePatientImage = (id, patientId) => basicFetch.delete({
  path: `/patients/${patientId}/imageries/${id}`,
  errorMessage: 'Une erreur est survenue lors de la suppression du fichier. Veuillez réessayer plus tard.',
})

export const removeAttachment = (fileId) => basicFetch.delete({
  path: `/attachments/${fileId}`,
  errorMessage: 'Une erreur est survenue lors de la suppression du fichier. Veuillez réessayer plus tard.',
})

export const removePublicAttachment = (id, patientCode) => basicFetch.delete({
  path: `/public/patient/${patientCode}/attachments/${id}/remove`,
  errorMessage: 'Une erreur est survenue lors de la suppression du fichier. Veuillez réessayer plus tard.',
})

export const linkExpertise = (type, id, patientId, verb) => {
  const singularType = type.replace(/^(.)(.*)s$/, (m, a, b) => `${a.toUpperCase()}${b}`)

  return basicFetch.patch({
    path: `/patients/${patientId}/${verb}${singularType}/${id}`,
    errorMessage: 'Une erreur est survenue lors de la mise à jour de la téléexpertise. Veuillez réessayer plus tard.',
  })
}

export const patientCount = () => basicFetch.get({
  path: `/patients/count`,
  errorMessage: 'Une erreur est survenue lors de la récupération des statistiques patients.'
})

export const fetchTeleExpertise = (teleExpertiseIdParam, sessionIdParam, expertiseType) => {
  const path = sessionIdParam
    ? `/${expertiseType}s/${teleExpertiseIdParam}/session/${sessionIdParam}`
    : `/${expertiseType}s/${teleExpertiseIdParam}`

  return basicFetch.get({
    path,
    errorMessage: 'Une erreur est survenue pendant la récupération de la télé-expertise. Veuillez nous excuser et réessayer plus tard.'
  })
}

export const fetchTeleExpertiseOwner = (id, expertiseType) => basicFetch.get({
  path: `/${expertiseType}s/${id}/owner`,
  errorMessage: 'Une erreur est survenue durant la récupération du responsable de la téléexpertise. Veuillez nous excuser et réessayer plus tard.'
})

export const addParticipant = (sessionId, memberId) => basicFetch.post({
  path: `/staff-sessions/${sessionId}/add-participant/${memberId}`,
  errorMessage: 'Une erreur est survenue lors de l\'ajout du participant. Veuillez réessayer plus tard.'
})

export const removeParticipant = (sessionId, memberId) => basicFetch.delete({
  path: `/staff-sessions/${sessionId}/remove-participant/${memberId}`,
  errorMessage: 'Une erreur est survenue lors de la désélection du participant. Veuillez réessayer plus tard.'
})

export const addAllParticipants = (sessionId) => basicFetch.post({
  path: `/staff-sessions/${sessionId}/add-all-participants`,
  errorMessage: 'Une erreur est survenue lors de l\'ajout des participants. Veuillez réessayer plus tard.'
})

export const removeAllParticipants = (sessionId) => basicFetch.post({
  path: `/staff-sessions/${sessionId}/remove-all-participants`,
  errorMessage: 'Une erreur est survenue lors de la suppression des participants. Veuillez réessayer plus tard.'
})

export const fetchStaffPublicVideoconferencing = (teleExpertiseId) => basicFetch.get({
  path: `/staffs/${teleExpertiseId}/public-videoconferencing-id`,
  errorMessage: 'Une erreur est survenue. Veuillez réessayer plus tard.'
})

export const closeStaffCurrentSession = (teleExpertiseId) => basicFetch.patch({
  path: `/staffs/${teleExpertiseId}/close-current-session`,
  errorMessage: `Une erreur est survenue lors de la cloture de la séance. Veuillez réessayer plus tard.`,
})

export const deleteSessionsUpcoming = (teleExpertiseId, sessionUpcomingId) => basicFetch.delete({
  path: `/staffs/${teleExpertiseId}/remove-session-upcoming/${sessionUpcomingId}`,
  errorMessage: `Une erreur est survenue lors de la suppression de la séance. Veuillez réessayer plus tard.`,
})

export async function updateSessionsUpcoming(teleExpertiseId, sessionDateData) {
  let errorMessage = 'Une erreur est survenue lors de la mise à jour de la de la date de séance. Veuillez réessayer plus tard.'
  try {
    const response = await fetch(
      `${apiUrl}/staffs/${teleExpertiseId}/update-session-upcoming/${sessionDateData.id}`,
      {
        method: 'PATCH',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          sessionUpcomingDatas: {
            date: dateToString(sessionDateData.date),
            hour: sessionDateData.hour === '' ? '00:00' : sessionDateData.hour
          }
        }),
        credentials: 'include',
      },
    )

    if (response.status === 403) {
      isLogged()
    }

    const json = await response.json()

    if (response.status === 409) {
      errorMessage = 'Une autre séance est déjà programmée à cette date, veuillez en choisir une autre.'
    }

    if (!response.ok) {
      throw Error('')
    }

    return json
  } catch (error) {
    throw new Error(errorMessage)
  }
}

export async function addSessionsUpcoming(teleExpertiseId, sessionDateData) {
  let errorMessage = 'Une erreur est survenue lors de la mise à jour de la de la date de séance. Veuillez réessayer plus tard.'
  try {
    const response = await fetch(
      `${apiUrl}/staffs/${teleExpertiseId}/add-session-upcoming`,
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          sessionUpcomingDatas: {
            date: dateToString(sessionDateData.date),
            hour: sessionDateData.hour === '' ? '00:00' : sessionDateData.hour
          }
        }),
        credentials: 'include',
      },
    )

    if (response.status === 403) {
      isLogged()
    }

    const json = await response.json()

    if (response.status === 409) {
      errorMessage = 'Une autre séance est déjà programmée à cette date, veuillez en choisir une autre.'
    }

    if (!response.ok) {
      throw Error('')
    }

    return json
  } catch (error) {
    throw new Error(errorMessage)
  }
}

export const closeExpertise = (teleExpertiseId, expertiseType) => basicFetch.patch({
  path: `/${expertiseType}s/${teleExpertiseId}/archive`,
  errorMessage: `Une erreur est survenue lors de l'archivage de ${fr[expertiseType].thisExpertise}. Veuillez réessayer plus tard.`,
})

export const deleteExpertise = (teleExpertiseId, expertiseType) => basicFetch.delete({
  path: `/cohorts/${teleExpertiseId}`,
  errorMessage: `Une erreur est survenue lors de la suppression de ${fr[expertiseType].thisExpertise}. Veuillez réessayer plus tard.`,
})

export const openExpertise = (teleExpertiseId, expertiseType) => basicFetch.patch({
  path: `/${expertiseType}s/${teleExpertiseId}/unarchive`,
  errorMessage: `Une erreur est survenue lors de la ré-ouverture de ${fr[expertiseType].thisExpertise}. Veuillez réessayer plus tard.`,
})

export const sendMessage = (teleExpertiseId, subject, content, recipient, expertiseType) => basicFetch.post({
  path: '/messages/create',
  body: JSON.stringify({
    new_message: {
      subject, content,
      recipients: [Number(recipient)],
    },
    [expertiseType]: teleExpertiseId,
  }),
  errorMessage: 'Une erreur est survenue durant l\'envoi du message. Veuillez nous excuser et réessayer plus tard.',
})

export const updateColleagues = (id, colleagues, expertiseType) => basicFetch.patch({
  path: expertiseType === 'cohort'
    ? `/${expertiseType}s/${id}`
    : `/${expertiseType}s/${id}/addMember/${colleagues}`,
  body: expertiseType === 'cohort'
    ? JSON.stringify({ [expertiseType]: { members: colleagues }, })
    : null,
  errorMessage: 'Une erreur est survenue durant l\'ajout ou la suppression d\'un confrère. Veuillez nous excuser et réessayer plus tard.',
})

export const addUser = (id, userId, linkTo = 'patients') => basicFetch.patch({
  path: `/${linkTo}/${id}/addMember/${userId}`,
  errorMessage: 'Une erreur est survenue durant l\'ajout d\'un confrère. Veuillez nous excuser et réessayer plus tard.',
})

export const removeUser = (id, colleagueId, linkTo = 'patients') => basicFetch.patch({
  path: `/${linkTo}/${id}/removeMember/${colleagueId}`,
  errorMessage: 'Une erreur est survenue durant la suppression d\'un confrère. Veuillez nous excuser et réessayer plus tard.',
})

export const updateOwner = (colleagueId, teleExpertiseId, expertiseType) => basicFetch.patch({
  path: `/${expertiseType}s/${teleExpertiseId}/owner/${colleagueId}`,
  errorMessage: 'Une erreur est survenue lors de la mise à jour du médecin responsable. Veuillez réessayer plus tard.'
})

export const addManager = (managerId, teleExpertiseId, expertiseType) => basicFetch.patch({
  path: `/${expertiseType}s/${teleExpertiseId}/addManager/${managerId}`,
  errorMessage: 'Une erreur est survenue durant l\'ajout de l\'administrateur. Veuillez nous excuser et réessayer plus tard.',
})

export const removeManager = (managerId, teleExpertiseId, expertiseType) => basicFetch.patch({
  path: `/${expertiseType}s/${teleExpertiseId}/removeManager/${managerId}`,
  errorMessage: 'Une erreur est survenue durant la suppression de l\'administrateur. Veuillez nous excuser et réessayer plus tard.',
})

export const removePortalDocument = (portalDocumentId) => basicFetch.delete({
  path: `/tele-expertises/portal/${portalDocumentId}`,
  errorMessage: 'Une erreur est survenue durant la suppression du document. Veuillez nous excuser et réessayer plus tard.',
})

export const updatePatients = (sessionId, patients) => basicFetch.patch({
  path: `/staff-sessions/${sessionId}`,
  body: JSON.stringify({ staff_session: { patients } }),
  errorMessage: 'Une erreur est survenue pendant l\'ajout / la suppression du patient. Veuillez nous excuser et réessayer plus tard.',
})

export const linkPatient = (expertiseId, patientId, expertiseType = 'opinion') => basicFetch.patch({
  path: `/${expertiseType}s/${expertiseId}/addPatient/${patientId}`,
  errorMessage: 'Une erreur est survenue pendant l\'ajout du patient. Veuillez nous excuser et réessayer plus tard.',
})

export const unlinkPatient = (expertiseId, patientId, expertiseType = 'opinion') => basicFetch.patch({
  path: `/${expertiseType}s/${expertiseId}/removePatient/${patientId}`,
  errorMessage: 'Une erreur est survenue pendant la suppression du patient. Veuillez nous excuser et réessayer plus tard.',
})

export const fetchMembers = (query) => {
  const path = query
    ? `/users/available?q=${query}`
    : `/users/available`

  return basicFetch.get({
    path,
    errorMessage: 'Impossible de charger les utilisateurs disponibles pour ce staff. Merci de réessayer plus tard.'
  })
}

export const fetchVideoconferencingRoomLink = ({ teleExpertiseId = '', teleExpertiseType = '', patientCode = '', staffVideoconferenceId = '' }) => basicFetch.get({
  path: `${(patientCode || staffVideoconferenceId) && '/public'}${(staffVideoconferenceId) && '/staffs'}/videoconferencing/zoom-get-room-id/${staffVideoconferenceId
    ? staffVideoconferenceId
    : `${teleExpertiseType}/${teleExpertiseId}`}${patientCode && `?patientCode=${patientCode}`}
  `,
  errorMessage: 'Impossible de charger la visioconférence. Merci de réessayer plus tard.'
})

export const fetchDisciplines = () => basicFetch.get({
  path: `${window.location.pathname.includes(`${privateSitePrefix}/`) ? '' : '/public'}/disciplines/specialities`,
  errorMessage: 'Impossible de charger les disciplines disponibles pour cette téléexpertise. Merci de réessayer plus tard.'
})

export const fetchDefaultOpinions = (query) => basicFetch.get({
  path: `/opinions/all?q=${query}&order=desc&sort=updatedAt&limit=18&offset=0&onlyMine=0&addManaged=1&addIfMember=1&onlyArchived=0&new_request=0&waiting_request=0&done_request=0&onlyPersonal=1`,
  errorMessage: 'Impossible de charger les avis. Merci de réessayer plus tard.'
})

export const teleExpertiseCount = (teleExpertiseType) => basicFetch.get({
  path: `/${teleExpertiseType}/count`,
  errorMessage: 'Une erreur est survenue. Veuillez réessayer plus tard.'
})

export const getExpertisesWithComments = (id, expertiseType) => basicFetch.get({
  path: `/${expertiseType}s/${id}/check-patients-view`,
  errorMessage: 'Une erreur est survenue. Veuillez réessayer plus tard.'
})

export const addLabel = (expertiseId, label, expertiseType) => basicFetch.patch({
  path: `/labels/add/${expertiseType}/${expertiseId}`,
  body: JSON.stringify({ ...label, }),
  errorMessage: 'Une erreur est survenue lors de l\'ajout de l\'étiquette. Veuillez réessayer plus tard.'
})

export const deleteLabel = (expertiseId, labelId, expertiseType) => basicFetch.patch({
  path: `/labels/${labelId}/remove/${expertiseType}/${expertiseId}`,
  errorMessage: 'Une erreur est survenue lors la suppression de l\'étiquette. Veuillez réessayer plus tard.'
})

export const updateLabels = (expertiseId, expertiseType, labels) => basicFetch.patch({
  path: `/labels/update/${expertiseType}/${expertiseId}`,
  body: JSON.stringify({ ...labels, }),
  errorMessage: 'Une erreur est survenue lors la modification des étiquettes. Veuillez réessayer plus tard.'
})

export const allPatientsToCurrentSession = (teleExpertiseId, sessionId) => basicFetch.post({
  path: `/staffs/${teleExpertiseId}/session/${sessionId}/copy-patients`,
  errorMessage: 'Une erreur est survenue lors du report des patients. Veuillez réessayer plus tard.'
})

export const customFieldFiles = (patientId, fieldId) => basicFetch.get({
  path: `/attachments/patients/${patientId}/customField/${fieldId}`,
  errorMessage: 'Une erreur est survenue lors la récupération des fichiers joints. Veuillez réessayer plus tard.'
})

export const updateCustomFields = (patientId, customFieldContents) => basicFetch.patch({
  path: `/patients/${patientId}/updateCustomFields`,
  body: JSON.stringify({ customFieldContents }),
  errorMessage: 'Une erreur est survenue lors la mise à jour des champs personnalisés. Veuillez réessayer plus tard.'
})

export const addLabelToPatient = (patientId, fieldId) => basicFetch.patch({
  path: `/patients/${patientId}/addLabel/${fieldId}`,
  errorMessage: 'Une erreur est survenue lors l\'ajout de l\'étiquette. Veuillez réessayer plus tard.'
})

export const removeLabelFromPatient = (patientId, fieldId) => basicFetch.patch({
  path: `/patients/${patientId}/removeLabel/${fieldId}`,
  errorMessage: 'Une erreur est survenue lors de la suppression de l\'étiquette. Veuillez réessayer plus tard.'
})

export async function post(endpoint, options, isSymfony6 = false) {
  try {
    const response = await fetch(`${isSymfony6 ? apiSymfony6Url : apiUrl}${endpoint}`,
      {
        ...options,
        method: 'POST',
        credentials: 'include',
      },
    )

    if (403 === response.status) {
      isLogged()
    }

    if (!response.ok) {
      throw Error('');
    }

    return response.json()
  } catch (e) {
    throw Error(
      `Une erreur d'api est survenue pendant la requète POST ${endpoint}`
    )
  }
}

export async function patch(endpoint, options) {
  try {
    const response = await fetch(`${apiUrl}${endpoint}`,
      {
        ...options,
        method: 'PATCH',
        credentials: 'include',
      },
    )

    if (403 === response.status) {
      isLogged()
    }

    if (!response.ok) {
      throw Error('');
    }

    return response.json()
  } catch (e) {
    throw Error(
      `Une erreur d'api est survenue pendant la requète PATCH ${endpoint}`
    )
  }
}

export const checkExpertisePublicUriExist = (expertiseType, publicUriToCheck) => basicFetch.get({
  path: `/${expertiseType}s/check-public-uri-exist?publicUriToCheck=${publicUriToCheck}`,
  errorMessage: `Cette URL est déjà utilisé, merci d'en choisir une autre.`
})

export const checkCohortPublicPatientUriExist = (publicPatientUriToCheck) => basicFetch.get({
  path: `/cohorts/check-public-patient-uri-exist?publicPatientUriToCheck=${publicPatientUriToCheck}`,
  errorMessage: `Cette URL du site patient est déjà utilisé, merci d'en choisir une autre.`
})

export async function createExpertise(data, expertiseType) {
  try {
    const response = await fetch(
      `${apiUrl}/${expertiseType}s/create`,
      {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      })

    if (403 === response.status) {
      isLogged()
    }

    if (!response.ok) {
      const data = await response.json()

      throw Error(data)
    }

    return response.json()
  } catch (error) {
    throw Error(`Une erreur est survenue durant la création de ${fr[expertiseType].thisExpertise}. Merci de réessayer plus tard.`)
  }
}

export async function updateExpertise(data, teleExpertiseId, expertiseType) {
  try {
    const endpoint = expertiseType === 'opinion'
      ? `${apiUrl}/${expertiseType}s/${teleExpertiseId}/update`
      : `${apiUrl}/${expertiseType}s/${teleExpertiseId}`
    const response = await fetch(endpoint, {
      method: 'PATCH',
      body: JSON.stringify(data),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    })

    if (403 === response.status) {
      isLogged()
    }

    const json = await response.json()

    if (!response.ok) {
      const error = Error()
      error.errors = json.message

      throw error
    }

    return json
  } catch (error) {
    if (error.errors && error.errors === 'Erreur, cette URI existe deja.') {
      throw Error('Une téléexpertise existe déjà avec cet URL.')
    }
    throw Error(`Une erreur est survenue durant la mise à jour de ${fr[expertiseType].thisExpertise}. Merci de réessayer plus tard.`)
  }
}

export const fetchPortalPro = (id) => basicFetch.get({
  path: `/portal/${id}`,
  errorMessage: 'Une erreur est survenue lors de la récupération du profil. Veuillez réessayer plus tard.'
})

export const fetchOrganization = (id) => {
  return basicFetch.get({
    path: `/organizations/${id}`,
    errorMessage: 'Une erreur est survenue lors de la récupération de l\'organisation. Veuillez réessayer plus tard.'
  })
}

export const fetchOrganizations = ({
  search = '',
  onlyManagedOrMember = 0
}) => {
  const queries = qs.stringify({
    q: search,
    onlyManagedOrMember: onlyManagedOrMember,
  })

  return basicFetch.get({
    path: `/organizations/all?${queries}`,
    errorMessage: 'Une erreur est survenue lors de la récupération des organisations. Veuillez réessayer plus tard.'
  })
}

export const removeOrganizationLogo = (id) => basicFetch.delete({
  path: `/organizations/${id}/logo/remove`,
  errorMessage: 'Une erreur est survenue lors de la suppression de l\'image. Veuillez réessayer plus tard.'
})

export const urlUpdateRequest = (message) => basicFetch.post({
  path: `/portal/send-update-url-mail`,
  body: JSON.stringify(message),
  errorMessage: 'Une erreur est survenue lors de l\'envoi du message. Veuillez réessayer plus tard.'
})

export const sendEstimateRequest = (offerName, message) => basicFetch.post({
  path: `/subscription/send-estimate-ask-mail`,
  body: JSON.stringify({ offerName, message }),
  errorMessage: 'Une erreur est survenue lors de l\'envoi du message. Veuillez réessayer plus tard.'
})

export const payTeleExpertise = (quantityTeleExpertiseSubscrib, paymentCardToken, paymentCardData) => basicFetch.post({
  path: `/subscription/pay-tele-expertise`,
  body: JSON.stringify({
    quantityTeleExpertiseSubscrib: quantityTeleExpertiseSubscrib,
    paymentCardToken: paymentCardToken,
    paymentCardData: {
      brand: paymentCardData.brand,
      exp_month: paymentCardData.exp_month,
      exp_year: paymentCardData.exp_year,
      last4: paymentCardData.last4,
    },
  }),
  errorMessage: 'Une erreur est survenue lors du paiement. Veuillez réessayer plus tard.'
})

export const updateSubscription = (quantityTeleExpertiseSubscrib) => basicFetch.patch({
  path: `/subscription/update`,
  body: JSON.stringify({
    quantityTeleExpertiseSubscrib: quantityTeleExpertiseSubscrib,
  }),
  errorMessage: 'Une erreur est survenue lors du paiement. Veuillez réessayer plus tard.'
})

export const savePortalPro = (fieldName) => basicFetch.patch({
  path: `/portal/update`,
  body: JSON.stringify(fieldName),
  errorMessage: 'Une erreur est survenue lors de l\'enregistrement des données du portail. Veuillez réessayer plus tard.'
})

export async function savePassword(my_account_password) {
  try {
    const response = await fetch(
      `${apiUrl}/users/current/password`,
      {
        method: 'PUT',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(my_account_password),
        credentials: 'include',
      },
    )

    const json = await response.json()

    if (json.errors) {
      const error = Error()
      error.errors = json.errors

      throw error
    }

    return json
  } catch (e) {
    if (e.errors && e.errors['my_account_password[previousPassword]']) {
      throw Error('Le mot de passe actuel est incorrect.')
    }

    throw new Error('Une erreur est survenue lors de la mise à jour de votre mot de passe. Veuillez réessayer plus tard.')
  }
}

export const createOrganization = (organization) => basicFetch.post({
  path: `/organizations/create`,
  body: JSON.stringify({ organization }),
  errorMessage: 'Une erreur est survenue lors de l\'enregistrement de l\'organisation. Veuillez réessayer plus tard.'
})

export const updateOrganizationName = (organizationId, name) => basicFetch.patch({
  path: `/organizations/${organizationId}/update`,
  body: JSON.stringify(name),
  errorMessage: 'Une erreur est survenue lors de la mise à jour de l\'organisation. Veuillez réessayer plus tard.'
})

export const saveProfile = (my_account) => basicFetch.patch({
  path: `/users/current`,
  body: JSON.stringify(my_account),
  errorMessage: 'Une erreur est survenue lors de l\'enregistrement du profil. Veuillez réessayer plus tard.'
})

export const addOrganization = (organization) => basicFetch.post({
  path: `/organizations/`,
  body: JSON.stringify({ organization }),
  errorMessage: 'Une erreur est survenue lors de l\'ajout de l\'organization. Veuillez réessayer plus tard.'
})

export const addPatientManager = (patientId, colleagueId) => basicFetch.patch({
  path: `/patients/${patientId}/add-manager/${colleagueId}`,
  errorMessage: 'Une erreur est survenue lors de l\'ajout du médecin. Veuillez réessayer plus tard.'
})

export const removePatientManager = (patientId, colleagueId) => basicFetch.patch({
  path: `/patients/${patientId}/remove-manager/${colleagueId}`,
  errorMessage: 'Une erreur est survenue lors de la supression du médecin. Veuillez réessayer plus tard.'
})

export const addPortalProExpertise = (id, type) => basicFetch.patch({
  path: `/portal/add-tele-expertise/${type}/${id}`,
  errorMessage: 'Une erreur est survenue lors de l\'ajout de la téléexpertise. Veuillez réessayer plus tard.'
})

export const removePortalProExpertise = (id, type) => basicFetch.patch({
  path: `/portal/remove-tele-expertise/${type}/${id}`,
  errorMessage: 'Une erreur est survenue lors de la suppression de la téléexpertise. Veuillez réessayer plus tard.'
})

export const saveCustomFields = (fieldId, teleExpertiseType, teleExpertiseId, customField) => {
  const type = teleExpertiseType === 'staffs'
    ? 'staff'
    : teleExpertiseType === 'opinions'
      ? 'opinion'
      : 'cohort'
  return basicFetch.patch({
    path: `/custom_fields/${type}/${teleExpertiseId}`,
    body: JSON.stringify({ customField, id: fieldId }),
    errorMessage: 'Une erreur est survenue lors de l\'enregistrement du champ personnalisé. Veuillez réessayer plus tard.'
  })
}

export const reorderCustomFields = (fieldId, teleExpertiseType, teleExpertiseId, customFields) => {
  const type = teleExpertiseType === 'staffs'
    ? 'staff'
    : teleExpertiseType === 'opinions'
      ? 'opinion'
      : 'cohort'
  return basicFetch.patch({
    path: `/custom_fields/${type}/${teleExpertiseId}/update-sort`,
    body: JSON.stringify({ customFields }),
    errorMessage: 'Une erreur est survenue lors du réordonnancement des champ personnalisé. Veuillez réessayer plus tard.'
  })
}

export const addSection = (teleExpertiseType, teleExpertiseId, customField) => {
  const type = teleExpertiseType === 'staffs'
    ? 'staff'
    : teleExpertiseType === 'opinions'
      ? 'opinion'
      : 'cohort'
  return basicFetch.post({
    path: `/custom_fields/${type}/${teleExpertiseId}`,
    body: JSON.stringify({ customField }),
    errorMessage: 'Une erreur est survenue lors de l\'ajout de la nouvelle section. Veuillez réessayer plus tard.'
  })
}

export const addField = (teleExpertiseType, teleExpertiseId, customField) => {
  const type = teleExpertiseType === 'staffs'
    ? 'staff'
    : teleExpertiseType === 'opinions'
      ? 'opinion'
      : 'cohort'
  return basicFetch.post({
    path: `/custom_fields/${type}/${teleExpertiseId}`,
    body: JSON.stringify({ customField }),
    errorMessage: 'Une erreur est survenue lors de l\'ajout du nouveau champ. Veuillez réessayer plus tard.'
  })
}

export const removeField = (fieldId, teleExpertiseType, teleExpertiseId) => {
  const type = teleExpertiseType === 'staffs'
    ? 'staff'
    : teleExpertiseType === 'opinions'
      ? 'opinion'
      : 'cohort'
  return basicFetch.delete({
    path: `/custom_fields/${type}/${teleExpertiseId}/${fieldId}`,
    errorMessage: 'Une erreur est survenue lors de la suppression du champ. Veuillez réessayer plus tard.'
  })
}

export const addChoice = (teleExpertiseType, teleExpertiseId, customField) => {
  const type = teleExpertiseType === 'staffs'
    ? 'staff'
    : teleExpertiseType === 'opinions'
      ? 'opinion'
      : 'cohort'
  return basicFetch.post({
    path: `/custom_fields/add-choice/${type}/${teleExpertiseId}`,
    body: JSON.stringify(customField),
    errorMessage: 'Une erreur est survenue lors de l\'ajout de l\'option. Veuillez réessayer plus tard.'
  })
}

export const removeChoice = (teleExpertiseType, choiceId, teleExpertiseId) => {
  const type = teleExpertiseType === 'staffs'
    ? 'staff'
    : teleExpertiseType === 'opinions'
      ? 'opinion'
      : 'cohort'
  return basicFetch.delete({
    path: `/custom_fields/remove-choice/${type}/${teleExpertiseId}/${choiceId}`,
    errorMessage: 'Une erreur est survenue lors de la supression de l\'option. Veuillez réessayer plus tard.'
  })
}

export const addOrganizationMember = (organizationId, memberId) => basicFetch.patch({
  path: `/organizations/${organizationId}/add-member/${memberId}`,
  errorMessage: 'Une erreur est survenue lors de l\'ajout du confrère. Veuillez réessayer plus tard.'
})

export const removeOrganizationMember = (organizationId, memberId) => basicFetch.patch({
  path: `/organizations/${organizationId}/remove-member/${memberId}`,
  errorMessage: 'Une erreur est survenue lors de la suppression du confrère. Veuillez réessayer plus tard.'
})

export const updateOrganization = (organizationId, action, resource, resourceId) => basicFetch.patch({
  path: `/organizations/${organizationId}/${action}-${resource}/${resourceId}`,
  errorMessage: 'Une erreur est survenue lors de la mise à jour de l\'organisation. Veuillez réessayer plus tard.'
})

export const updateLocked = (teleExpertiseType, id, toggle) => {
  const action = toggle ? 'unlock-tele-expertise' : 'lock-tele-expertise'
  const type = teleExpertiseType === 'staffs'
    ? 'staff'
    : teleExpertiseType === 'opinions'
      ? 'opinion'
      : 'cohort'
  return basicFetch.patch({
    path: `/pro-offer/${action}/${type}/${id}`,
    errorMessage: 'Une erreur est survenue lors de la mise à jour de la téléexpertise. Veuillez réessayer plus tard.'
  })
}

export const removeOrganization = (organizationId) => basicFetch.delete({
  path: `/organizations/${organizationId}/remove`,
  errorMessage: 'Une erreur est survenue lors de la suppression de l\'organisation. Veuillez réessayer plus tard.'
})

export const updateSubscribed = (organizationId, action, resource, resourceId) => basicFetch.patch({
  path: `/organizations/${organizationId}/${action}-${resource}/${resourceId}`,
  errorMessage: 'Une erreur est survenue lors de la mise à jour de l\'organisation. Veuillez réessayer plus tard.'
})

export const fetchMessages = () => basicFetch.get({
  path: `/messages/all`,
  errorMessage: 'Une erreur est survenue lors de la récupération des messages. Veuillez réessayer plus tard.'
})

export const fetchNotifications = () => basicFetch.get({
  path: `/notifications/all?limit=10&offset=0`,
  errorMessage: 'Une erreur est survenue lors de la récupération des notifications. Veuillez réessayer plus tard.'
})

export const allNotificationsRead = () => basicFetch.patch({
  path: '/notifications/read-all',
  errorMessage: 'Une erreur est survenue lors de la mise à jour des statistiques de lecture. Veuillez réessayer plus tard.'
})

export const fetchMessagesAndNotifications = () => basicFetch.get({
  path: `/messages/messages-and-notifications`,
  errorMessage: 'Une erreur est survenue lors de la récupération des messages. Veuillez réessayer plus tard.'
})

export const fetchMessage = (id) => basicFetch.get({
  path: `/messages/${id}`,
  errorMessage: 'Une erreur est survenue lors de la récupération du message. Veuillez réessayer plus tard.'
})

export const createMessage = (message) => basicFetch.post({
  path: `/messages/create`,
  body: JSON.stringify({ message }),
  errorMessage: 'Une erreur est survenue lors de l\'envoi du message. Veuillez réessayer plus tard.'
})

export const replyMessage = ({ messageId, assets, author, content, createdAt, patients }) => basicFetch.post({
  path: `/messages/${messageId}/replies`,
  body: JSON.stringify({ reply: { assets, author, content, createdAt, patients } }),
  errorMessage: 'Une erreur est survenue lors de l\'envoi du message. Veuillez réessayer plus tard.'
})

export const sendMail = ({ assets, subject, content, patients, recipients }) => basicFetch.post({
  path: `/messages/create`,
  body: JSON.stringify({ new_message: { assets, subject, content, patients, recipients } }),
  errorMessage: 'Une erreur est survenue lors de l\'envoi du message. Veuillez réessayer plus tard.'
})

export const sendContactMessage = ({ from: email, name, subject, content: message }) => basicFetch.post({
  path: `/public/contact-us`,
  body: JSON.stringify({ sender: { email, name }, subject, message }),
  errorMessage: 'Une erreur est survenue lors de l\'envoi du message. Veuillez réessayer plus tard.'
})

export const fetchMessagesNum = () => basicFetch.get({
  path: `/users/current/count-unread-messages-and-notifications`,
  errorMessage: 'Une erreur est survenue lors de la récupération des statistiques de messages. Veuillez réessayer plus tard.'
})

export const updateDoctor = (colleagueId, patientId) => basicFetch.patch({
  path: `/patients/${patientId}/doctor/${colleagueId}`,
  errorMessage: 'Une erreur est survenue lors de la mise à jour du médecin responsable. Veuillez réessayer plus tard.'
})

export const fetchQuestionnaire = (publicUri, patientCode) => basicFetch.get({
  path: `/public/cohort-patient-steps/${publicUri}${patientCode ? `?patientCode=${patientCode}` : ''}`,
  errorMessage: 'Une erreur est survenue lors de la récupération des étapes. Veuillez réessayer plus tard.'
})

export const addStep = (cohortId, data) => basicFetch.patch({
  path: `/cohorts/${cohortId}/addPatientStep`,
  body: JSON.stringify(data),
  errorMessage: 'Une erreur est survenue lors de l\'ajout de l\'étape. Veuillez réessayer plus tard.'
})

export const removeStep = (cohortId, stepId) => basicFetch.patch({
  path: `/cohorts/${cohortId}/removePatientStep/${stepId}`,
  errorMessage: 'Une erreur est survenue lors de la suppression de l\'étape. Veuillez réessayer plus tard.'
})

export const updateStep = (stepId, cohortId, data) => basicFetch.patch({
  path: `/cohorts/${cohortId}/updatePatientStep/${stepId}`,
  body: JSON.stringify(data),
  errorMessage: 'Une erreur est survenue lors de la mise à jour de l\'étape. Veuillez réessayer plus tard.'
})

export const removeDocument = (cohortId, assetId) => basicFetch.delete({
  path: `/cohorts/${cohortId}/removeAssetPatientStep/${assetId}`,
  errorMessage: 'Une erreur est survenue lors de la mise à jour de l\'étape. Veuillez réessayer plus tard.'
})

export const sendQuestionnaire = (patientCode, customFieldContents) => basicFetch.post({
  path: `/public/patient/${patientCode}/updateCustomFields`,
  body: JSON.stringify({ customFieldContents }),
  errorMessage: 'Une erreur est survenue lors l\'envoi du formulaire. Veuillez réessayer plus tard.'
})

export const fetchTeleExpertiseFromCode = (teleExpertiseType, publicUri = '') => basicFetch.get({
  path: `/public/${teleExpertiseType}-by-uri/${publicUri}`,
  errorMessage: 'Une erreur est survenue. Veuillez réessayer plus tard.'
})

export const fetchPatientTeleExpertiseFromCode = (publicUri = '') => basicFetch.get({
  path: `/public/cohort-by-patient-uri/${publicUri}`,
  errorMessage: 'Une erreur est survenue. Veuillez réessayer plus tard.'
})

export const updatePatientStepDate = (patientId, data = []) => basicFetch.patch({
  path: `/patients/${patientId}/updateCohortPatientStepProgrammingDate`,
  body: JSON.stringify({ ...data }),
  errorMessage: 'Une erreur est survenue lors de la mise à jour de la date. Veuillez réessayer plus tard.'
})

export async function updateEmail(patientId, email) {
  try {
    const response = await fetch(
      `${apiUrl}/patients/${patientId}/email`,
      {
        method: 'PATCH',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
        credentials: 'include',
      },
    )

    const json = await response.json()

    if (!json.success && json.message) {
      const error = Error()
      error.errors = json.message

      throw error
    }

    return json
  } catch (error) {
    if (error.errors && error.errors === 'Cette email est deja renseigné sur une autre fiche patient') {
      throw Error('Cet email est deja renseigné sur une autre fiche patient.')
    }

    throw new Error('Une erreur est survenue lors de la mise à jour de l\'adresse email. Veuillez réessayer plus tard.')
  }
}

export const getDicomViewerJson = (patientId, dicomFilesKey) => basicFetch.get({
  isSymfony6: true,
  path: `/patients/${patientId}/json-dicom/${dicomFilesKey}?returnJson=0`,
  errorMessage: 'Une erreur est survenue. Veuillez réessayer plus tard.'
})

export async function getStudy(JWTToken, studyId) {
  try {
    const response = await fetch(
      `https://healthcare.googleapis.com/v1/projects/${projectsName}/locations/${projectsLocations}/datasets/${projectsDatasets}/dicomStores/${projectsDicomStores}/dicomWeb/studies?StudyInstanceUID=${studyId}&includefield=00081030`,
      {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${JWTToken}`,
        },
      },
    )

    if (!response.ok) {
      throw Error('')
    }

    const json = await response.json()

    return json
  } catch (error) {
    console.log(error)
    throw new Error('Une erreur est survenue lors de la récupération de l\'examen. Veuillez réessayer plus tard.')
  }
}

export async function sendDicom(JWTToken, file, { signal }) {
  try {
    const response = await fetch(
      `https://healthcare.googleapis.com/v1/projects/${projectsName}/locations/${projectsLocations}/datasets/${projectsDatasets}/dicomStores/${projectsDicomStores}/dicomWeb/studies`,
      {
        method: 'POST',
        signal,
        headers: {
          'Authorization': `Bearer ${JWTToken}`,
          'Content-Type': 'application/dicom'
        },
        body: file,
      },
    )

    if (!response.ok) {
      throw Error('')
    }

    const strXml = await response.text()

    return strXml
  } catch (error) {
    throw new Error('Une erreur est survenue lors de l\'envoi du dicom. Veuillez réessayer plus tard.')
  }
}

export async function removeRemoteStudy(JWTToken, studyId) {
  try {
    const response = await fetch(
      `https://healthcare.googleapis.com/v1/projects/${projectsName}/locations/${projectsLocations}/datasets/${projectsDatasets}/dicomStores/${projectsDicomStores}/dicomWeb/studies/${studyId}`,
      {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${JWTToken}`,
          'Content-Type': 'application/dicom'
        },
      },
    )

    const json = await response.json()

    return json
  } catch (error) {
    throw new Error('Une erreur est survenue lors de la suppression de l\'instance dicom. Veuillez réessayer plus tard.')
  }
}

export const removeStudy = (patientId, studyId) => basicFetch.delete({
  path: `/patients/${patientId}/remove-dicom-study/${studyId}`,
  errorMessage: 'Une erreur est survenue lors de la suppression de l\'examen. Veuillez réessayer plus tard.'
})

export const removeDicomS3 = (patientId, dicomFilesKey) => basicFetch.delete({
  isSymfony6: true,
  path: `/patients/${patientId}/remove-dicom/${dicomFilesKey}`,
  errorMessage: 'Une erreur est survenue lors de la suppression de l\'examen. Veuillez réessayer plus tard.'
})

export const saveStudies = (patientId, studies) => basicFetch.patch({
  path: `/patients/${patientId}`,
  body: JSON.stringify({ patient: { dicomStudiesIds: studies } }),
  errorMessage: 'Une erreur est survenue lors de l\'enregistrement des examens pour ce patient. Veuillez réessayer plus tard.'
})

export const getJWTToken = () => basicFetch.get({
  path: '/patients/google-healthcare-access-token',
  errorMessage: 'Une erreur est survenue lors de la création de la création du token d\'authentification. Veuillez réessayer plus tard.'
})

export async function downloadDicom(JWTToken, fileName, studyUid, { signal }) {
  try {
    const response = await fetch(
      `${apiUrl}/patients/generate-dicom-study-zip?googleAccessToken=${JWTToken}&projects=${projectsName}&locations=${projectsLocations}&datasets=${projectsDatasets}&dicomStores=${projectsDicomStores}&studies=${studyUid}&dicomFileName=${fileName}&action=generateZipFile`,
      {
        method: 'GET',
        signal,
        credentials: 'include',
      },
    )

    if (!response.ok) {
      throw Error('')
    }

    const json = await response.json()
    return json

  } catch (error) {
    throw new Error('Une erreur est survenue lors du téléchargement des fichiers. Veuillez réessayer plus tard.')
  }
}

export const checkDownload = (studyId) => basicFetch.get({
  path: `/patients/generate-dicom-study-zip?studies=${studyId}&action=checkZipFileGenerated`,
  errorMessage: 'Une erreur est survenue lors de la récupération des fichiers Dicom. Veuillez réessayer plus tard.'
})

export const addWorkList = (teleExpertiseId, title = '') => basicFetch.patch({
  path: `/opinions/${teleExpertiseId}/addPatientList`,
  body: JSON.stringify({ opinion_patient_list: { title } }),
  errorMessage: 'Une erreur est survenue lors de la création de la liste de travail. Veuillez réessayer plus tard.'
})

export const updateWorkList = (teleExpertiseId, workListId, title = '') => basicFetch.patch({
  path: `/opinions/${teleExpertiseId}/updatePatientList/${workListId}`,
  body: JSON.stringify({ opinion_patient_list: { title } }),
  errorMessage: 'Une erreur est survenue lors de la mise à jour de la liste de travail. Veuillez réessayer plus tard.'
})

export const removeWorkList = (teleExpertiseId, workListId, title) => basicFetch.delete({
  path: `/opinions/${teleExpertiseId}/removePatientList/${workListId}`,
  body: JSON.stringify({ opinion_patient_list: { title } }),
  errorMessage: 'Une erreur est survenue lors de la suppression de la liste de travail. Veuillez réessayer plus tard.'
})

export const addPatientToWorkList = (teleExpertiseId, workListId, patientId) => basicFetch.patch({
  path: `/opinions/${teleExpertiseId}/patientList/${workListId}/addPatient/${patientId}`,
  errorMessage: 'Une erreur est survenue lors de l\'ajout du patient dans la liste de travail. Veuillez réessayer plus tard.'
})

export const removePatientFromWorkList = (teleExpertiseId, workListId, patientId) => basicFetch.delete({
  path: `/opinions/${teleExpertiseId}/patientList/${workListId}/removePatient/${patientId}`,
  errorMessage: 'Une erreur est survenue lors de la suppression du patient dans la liste de travail. Veuillez réessayer plus tard.'
})

export const removeAllPatientsFromWorkList = (teleExpertiseId, workListId) => basicFetch.delete({
  path: `/opinions/${teleExpertiseId}/patientList/${workListId}/removeAllPatients`,
  errorMessage: 'Une erreur est survenue lors de la suppression des patients de la liste de travail. Veuillez réessayer plus tard.'
})

export const reorderPatientFromWorkList = (teleExpertiseId, patients, patientId) => basicFetch.patch({
  path: `/opinions/${teleExpertiseId}/patientList/updatePatientSort/${patientId}`,
  body: JSON.stringify({ newPatientsSort: patients }),
  errorMessage: 'Une erreur est survenue lors de la suppression du patient dans la liste de travail. Veuillez réessayer plus tard.'
})

export const reorderWorklists = (teleExpertiseId, worklists) => basicFetch.patch({
  path: `/opinions/${teleExpertiseId}/updatePatientListsSort`,
  body: JSON.stringify({ newPatientListsSort: worklists }),
  errorMessage: 'Une erreur est survenue lors du déplacement de la liste de travail. Veuillez réessayer plus tard.'
})

export const removePlanningPatient = (teleExpertiseId, patientId) => basicFetch.patch({
  path: `/opinions/${teleExpertiseId}/removePatientPlanning/${patientId}`,
  errorMessage: 'Une erreur est survenue lors de la suppression du patient. Veuillez réessayer plus tard.'
})

export const schedulePatient = (teleExpertiseId, patientId, scheduledDate) => basicFetch.patch({
  path: `/opinions/${teleExpertiseId}/addPatientPlanning/${patientId}`,
  body: JSON.stringify({ datePlanned: scheduledDate }),
  errorMessage: 'Une erreur est survenue lors de la programmation du patient. Veuillez réessayer plus tard.'
})

export const sendMailScheduledPatient = (teleExpertiseId, patientPlanningId) => basicFetch.post({
  path: `/opinions/${teleExpertiseId}/sendMailToPatientPlannedOwner/${patientPlanningId}`,
  errorMessage: 'Une erreur est survenue lors de l\'envoi du mail. Veuillez réessayer plus tard.'
})

export const saveField = (fieldName) => basicFetch.post({
  path: `/profile/saveField/${fieldName}`,
  errorMessage: 'Une erreur est survenue lors de l\'enregistrement du champ. Veuillez réessayer plus tard.'
})

export const removeProfilePicture = () => basicFetch.delete({
  path: '/users/current/profile-picture/remove',
  errorMessage: 'Une erreur est survenue lors de la suppression de l\'image. Veuillez réessayer plus tard.'
})

export const removePortalProLogo = () => basicFetch.delete({
  path: '/portal/logo/remove',
  errorMessage: 'Une erreur est survenue lors de la suppression de l\'image. Veuillez réessayer plus tard.'
})

export const removePortalProPicture = () => basicFetch.delete({
  path: '/portal/picture/remove',
  errorMessage: 'Une erreur est survenue lors de la suppression de l\'image. Veuillez réessayer plus tard.'
})

export const getRecentTeleExpertises = () => basicFetch.get({
  path: '/users/current/tele-expertise-logs',
  errorMessage: 'Une erreur est survenue lors de la récupération des téléexpertises récentes.'
})

export const beRecalled = ({ fullname: name, phone: cellPhone }) => basicFetch.post({
  path: `/users/be-recall`,
  body: JSON.stringify({ name, cellPhone }),
  errorMessage: 'Une erreur est survenue lors de l\'envoi de votre demande.'
})

export const beRecalledPublic = ({ fullname: name, phone: cellPhone }) => basicFetch.post({
  path: `/public/be-recall`,
  body: JSON.stringify({ name, cellPhone }),
  errorMessage: 'Une erreur est survenue lors de l\'envoi de votre demande.'
})

export const askPatientCoOwnership = (patientId) => basicFetch.post({
  path: `/patients/${patientId}/ask-be-manager`,
  errorMessage: 'Une erreur est survenue lors de l\'envoi de votre demande.'
})

export const fetchDashboardAdminKPI = (startDate, endDate) => basicFetch.get({
  path: `/admin/dashboard/kpi?startDate=${dateToString(startDate, { separator: '-', reverse: true })}&endDate=${dateToString(endDate, { separator: '-', reverse: true })}`,
  errorMessage: 'Une erreur est survenue lors de la récupération des KPI.'
})
