import React from 'react';
import styled from 'styled-components/macro'
import * as Ui from './Ui'
import DisclaimerContent from './Ui/DisclaimerContent';
import SEOTags from './SEOTags';

const DisclaimerComponent = () => {

  return (
    <>
      <SEOTags
        title="Mentions légales - Skemeet"
      />
      <Ui.PortailLayout.PageLayout fullWidth>
        <DisclaimerContainer>
          <Ui.BasicElement.H1>Mentions légales</Ui.BasicElement.H1>
          <DisclaimerContent />
        </DisclaimerContainer>
      </Ui.PortailLayout.PageLayout>
    </>
  )
}

const DisclaimerContainer = styled(props => <Ui.PortailLayout.PageContainer {...props} />)`
  margin: 2rem 1rem;
  @media only screen and (min-width: ${props => props.theme.device.desktop}) {
    margin-top: 3rem;
  }
  ${Ui.Form.InputContainer}:last-of-type {
    margin-bottom: .2rem;
  }

  ${Ui.BasicElement.H1} {
    margin-bottom: 1rem;
  }

  ${Ui.BasicElement.Link} {
    font-size: .875rem;
    font-weight: 700;
  }

`

export default DisclaimerComponent;
