import * as SearchExpertise from '../State/CohortList'
import * as Login from '../State/Login'
import * as Eff from 'redux-saga/effects'
import * as Api from '../Util/Api'
import { debounceTime } from '../Util/Date'

const expertiseType = 'cohort'

export function* fetch({
  payload: {
    page = 1,
  } = {},
} = {}) {
  const abortController = new AbortController();
  try {
    const rawFilters = yield Eff.select(SearchExpertise.selectFilters)
    const query = rawFilters.search
    if (query) {
      const delayMs = debounceTime(query)
      yield Eff.delay(delayMs)
    }
    const user = yield Eff.select(Login.selectUser)
    const filters = {
      ...rawFilters,
      statuses: rawFilters
        .status
        .filter(status => status.checked)
        .map(status => status.value),
      limit: Number(process.env.REACT_APP_DEFAULT_RESULT_LIMIT),
      offset: (page - 1) * process.env.REACT_APP_DEFAULT_RESULT_LIMIT,
      userId: user.id,
    }
    const { cohorts, keywordsNotFound, count } = yield Eff.call(Api.fetchTeleExpertises, filters, expertiseType, { signal: abortController.signal })

    if (count !== 'none') {
      yield Eff.put(SearchExpertise.receivedCountAll(count))
    }

    yield Eff.put(SearchExpertise.received({
      data: cohorts,
      mode: page > 1
        ? SearchExpertise.MODE_RECEIVED_MORE
        : SearchExpertise.MODE_RECEIVED_FIRST,
        keywordsNotFound,
    }))
  } catch (error) {
    yield Eff.put(SearchExpertise.apiError(error.message))
  } finally {
    if (yield Eff.cancelled()) {
      abortController.abort();
    }
  }
}

const CohortListSagas = function* () {
  yield Eff.takeLatest([
    `${SearchExpertise.fetch}`,
    `${SearchExpertise.removeStatus}`,
    `${SearchExpertise.addStatus}`,
    `${SearchExpertise.changeDisplayBy}`,
    `${SearchExpertise.changeOrderBy}`,
  ], fetch)

  yield Eff.takeLatest(`${SearchExpertise.changeSearch}`, fetch)
}

export default CohortListSagas;
