export const uniqById = (obj, index, self) =>
  index === self.findIndex((t) => (
    Number(t.id) === Number(obj.id)
  ))

export const uniqByIdAndType = (obj, index, self) =>
  index === self.findIndex((t) => (
    Number(t.id) === Number(obj.id) && t.type === obj.type
  ))

export const uniq = (obj, index, self) =>
  self.indexOf(obj) === index

export const _ = {
  lastElement: (arr) => arr[arr.length - 1],
  idKeys: (obj, initial = {}) =>
    Object
      .values(obj)
      .reduce((acc, { id, ...value }) => ({
        ...acc,
        [id]: { id, ...value }
      }), initial),
}
