import React, { useState } from 'react';
import styled from 'styled-components/macro'
import * as Ui from '../Ui'

const AccordionItem = ({ title, content }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <AccordionItemContainer>
      <Ui.Layout.FlexBox onClick={() => setIsActive(!isActive)}>
        <Title data-testid="title">{title}</Title>
        <Sign>{isActive ? '-' : '+'}</Sign>
      </Ui.Layout.FlexBox>
      {isActive && <Content data-testid="content">{content}</Content>}
    </AccordionItemContainer>
  );
};

const AccordionItemContainer = styled.div`
  ${Ui.Layout.FlexBox} {
    cursor: pointer;
  }
`

const Title = styled.div`
  color: ${props => props.theme.primary};
  margin-bottom: 1rem;
  text-decoration: none;
  font-weight: 700;
  flex:1;
`

const Sign = styled.div`
  color: ${props => props.theme.patientGreen};
  font-weight: bold;
`

const Content = styled.div `
  margin-bottom: 1rem;
`

export default AccordionItem;
