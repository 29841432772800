import React from 'react'
import styled from 'styled-components'
import * as Ui from '../Ui'
import Editable from '../../Util/Editable'
import { _ } from '../../Util/Object';
import { fr } from '../../Util/i18n';

export const TagsComponent = ({
  onAddItem = () => { },
  onRemoveItem = (idx) => { },
  onUpdateItemContent = (type, idx) => { },
  onEnterFieldsBlock = (blockName) => { },
  onLeaveFieldsBlock = (blockName) => { },
  type,
  data,
  teleExpertiseName,
  isEditMode,
  childRef,
  tabIndex,
  isCreationMode,
  canEdit,
}) => {

  return (
    <Editable
      title="Mots-clés"
      content={data.tags.value.length > 0 &&
        <Ui.Layout.FlexBox flow="row wrap">
          {data[`${type}s`].value.map((item, i) =>
            <Ui.Tag.Tag
              key={`tag_${i}`}
              bgColor='gray'
            >
              <span>{item}</span>
            </Ui.Tag.Tag>
          )
          }
        </Ui.Layout.FlexBox>
      }
      altContent="Ajouter des mots-clés"
      altContentFieldDisabled="Aucun mot-clé n'a été défini pour {fr[teleExpertiseName].thisExpertise}"
      altText={canEdit
        ? "Ajouter des mots-clés"
        : `Aucun mot-clé n'a été défini pour ${fr[teleExpertiseName].thisExpertise}`
      }
      isEditMode={isEditMode || isCreationMode}
      onClick={onEnterFieldsBlock}
      onBlur={onLeaveFieldsBlock}
      childRef={childRef}
      canEdit={canEdit}
    >
      <>
        <Ui.Layout.FocusDiv ref={childRef} tabIndex="32443" >
          <H3>Mots-clés</H3>
          <TagList>
            {data[`${type}s`].value.map((item, idx) =>
              <div key={`${type}_${idx}`}>
                <Ui.Form.LabelsContainer>
                  <Ui.Form.LabelContainer>
                    <Ui.Form.TextInput
                      htmlFor={`${type}_input_${idx}`}
                      value={item}
                      onChange={onUpdateItemContent(type, idx)}
                      noSubmit
                    />
                  </Ui.Form.LabelContainer>
                  <Ui.Layout.ButtonGroup>
                    <ActionButton
                      isGreen
                      type="button"
                      onClick={onRemoveItem(idx)}
                    >
                      <Ui.Icon.IconTrash />
                    </ActionButton>
                  </Ui.Layout.ButtonGroup>
                </Ui.Form.LabelsContainer>
              </div>
            )}
            <LabelButtonContainer>
              <ActionButton
                isGreen
                type="button"
                onClick={onAddItem}
                disabled={data[`${type}s`].value.length &&
                  _.lastElement(data[`${type}s`].value) === ''
                }
              >
                <Ui.Icon.IconPlus />
              </ActionButton>
              {data[`${type}s`].value.length > 0 && !isCreationMode &&
                <Ui.Button.BasicButton
                  isGreen
                  isFilled
                  onClick={onLeaveFieldsBlock}
                >
                  Valider
                </Ui.Button.BasicButton>
              }
            </LabelButtonContainer>
          </TagList>
        </Ui.Layout.FocusDiv>
      </>
    </Editable>
  )
}

const LabelButtonContainer = styled.div`
  margin-top: 1rem;
  display: flex;
`

const ActionButton = styled(props => <Ui.Button.BasicButton {...props} />)`
  width: 2.3125rem;
  height: 2.3125rem;
  padding: 1.2294rem;
  &:not(:last-child) {
    margin-right: .4rem;
  }
`

const TagList = styled.div`
  margin-bottom: 1.4rem;
  > button {
    margin-top: .8rem;
  }
  ${Ui.Form.InputContainer} {
    margin-top: 0 !important;
  }
`

const H3 = styled(props => <Ui.BasicElement.H3 {...props} />)`
  margin-top: 0;
`

export default TagsComponent;
