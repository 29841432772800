import styled from 'styled-components/macro'
import * as Ui from '../Component/Ui'

export const MessageListContainer = styled.div`
  ${Ui.SearchBar.SearchBarContainer} {
    margin-bottom: 0;
    width: 100%;
  }
  ${Ui.Layout.PanelFilterContainer} {
    ${Ui.BasicElement.P} {
      border-bottom: 1px solid ${props => props.theme.lightGrey};
    }
  }
  ${Ui.Search.FiltersAndSorting} {
    justify-content: flex-end;
    &:first-child {
      margin-bottom: 1rem;
    }
  }
  ${Ui.Layout.PanelFilterRow} {
    padding: .6rem 0;
    &:not(:last-of-type) {
      border-bottom: none;
    }
  }
  ${Ui.Layout.PanelFilterLabel} {
    font-size: 1rem;
    color: ${props => props.theme.grey};
    span {
      font-weight: bold;
    }
  }
  a {
    flex: 0;
    text-decoration: none;
  }
`

export const MessageSection = styled.div`
  margin-top: 2rem;
  ${Ui.Form.TextArea} {
    margin-bottom: .5rem;
  }
  ${Ui.Message.Body} {
    ${Ui.BasicElement.P} {
      margin: 0;
    }
  }
  a {
    color: ${props => props.theme.patientGreen};
    font-weight: 600;
  }
`
