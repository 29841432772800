import { call, delay, put, select, takeLatest, cancelled } from 'redux-saga/effects'
import * as Homepage from '../State/Homepage'
import * as Api from '../Util/Api'
import { debounceTime } from '../Util/Date';

export function* fetchExpertises(type, query) {
  const abortController = new AbortController();

  try {
    const filters = {
      teleexpertiseType: type,
      search: query,
    }
    const { teleExpertises, keywordsNotFound } = yield call(Api.fetchHomepageTeleExpertises, filters, { signal: abortController.signal })
    yield put(Homepage.received({
      teleExpertises,
      keywordsNotFound,
    }))
  } catch (error) {
    console.log(error)
    yield put(Homepage.apiError(error.message))
  }
  finally {
    const search = yield select(Homepage.selectSearch)
    if (yield cancelled()) {
      abortController.abort();
      if (search.length === 0)
        yield put(Homepage.received({ loading: false }))
    }
  }
}

export function* setQuery({ payload: { teleExpertiseType, value: query } }) {
  try {
    const delayMs = debounceTime(query)

    yield delay(delayMs)

    if (query.length > 2) {
      yield put(Homepage.setLoading(true))
      yield fetchExpertises(teleExpertiseType, query, 30)
    }

  } catch (error) {
    console.log(error)
    yield put(Homepage.apiError(error.message))
  }
}

const HomepageSagas = function* () {
  yield takeLatest(`${Homepage.setQuery}`, setQuery)
}

export default HomepageSagas;
