import React, { useState, useEffect } from 'react';
import { useAlert } from 'react-alert'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'
import * as Registration from '../../State/Registration'
import * as Login from '../../State/Login'
import * as PortalPro from '../../State/PortalPro'
import * as Ui from '../Ui'
import qs from 'qs'
import pluralize from '../../Util/Pluralize'
import { getCookie } from '../../Util/Storage'
import { useLocation } from "react-router-dom";

const LoginFormComponent = () => {
  const location = useLocation();
  const locationQueryStrings = qs.parse(location.search, { ignoreQueryPrefix: true })
  const dispatch = useDispatch()
  const alert = useAlert()
  const credentials = useSelector(Login.selectCredentials)
  const code = useSelector(Login.selectOtpCode)
  const registrationSuccess = useSelector(Registration.selectSuccess)
  const email = useSelector(Registration.selectEmail) || locationQueryStrings.email
  const password = useSelector(Registration.selectPassword)
  const mode = useSelector(Login.selectOtpMode)
  const canInstallTotp = useSelector(Login.selectCanInstallTotp)
  const installTotp = useSelector(Login.selectInstallTotp)
  const selectedVisioconference = useSelector(state => state.login.selectedVisioconference)
  const errorMessage = useSelector(Login.selectedErrorMessage)
  const codeSent = useSelector(state => state.login.codeSent)
  const codeResent = useSelector(state => state.login.codeResent)
  const [savedPatient, setSavedPatient] = useState({})
  const [savedTeleExpertise, setSavedTeleExpertise] = useState('')
  const selectOneMoreTry = useSelector(Login.selectOneMoreTry)

  useEffect(() => {
    // const patient = JSON.parse(sessionStorage.getItem('patient'));
    // const teleExpertise = JSON.parse(sessionStorage.getItem('teleExpertise'));
    const patient = getCookie('patient');
    const teleExpertise = getCookie('teleExpertise');
    setSavedPatient(patient);
    setSavedTeleExpertise(teleExpertise);
  }, [])

  const { sending, codeFilling } = useSelector(state => ({
    sending: state.login.sending,
    codeFilling: state.login.codeFilling
  }))

  useEffect(() => {
    if (email) {
      dispatch(Login.updateCredential({ name: 'email', value: email }))
    }
    if (password) {
      dispatch(Login.updateCredential({ name: 'password', value: password }))
    }
  }, [dispatch, email, password])

  useEffect(() => {
    if (locationQueryStrings.portalTitle) {
      dispatch(PortalPro.received({
        data: {
          portalProId: locationQueryStrings.portalId,
          portalProHost: locationQueryStrings.portalHost,
          title: locationQueryStrings.portalTitle,
          logo: locationQueryStrings.portalLogo,
          picture: locationQueryStrings.portalPicture,
          teleExpertisePicture: locationQueryStrings.profilePicture,
          teleExpertiseTags: locationQueryStrings.teleExpertiseTags,
          teleExpertiseDisciplines: locationQueryStrings.disciplines,
          teleExpertiseOwner: locationQueryStrings.owner,
          teleExpertiseDescription: locationQueryStrings.teleExpertiseDescription,
          teleExpertiseOwnerSpeciality: locationQueryStrings.ownerSpeciality,
          teleExpertiseOwnerCity: locationQueryStrings.ownerCity,
          thePersonal: locationQueryStrings.thePersonal,
        }
      }))
      if (locationQueryStrings.visio) {
        dispatch(Login.selectVisioconference({
          teleExpertiseId: locationQueryStrings.teleExpertiseId,
          teleExpertiseName: locationQueryStrings.teleExpertiseName,
          teleExpertiseType: locationQueryStrings.teleExpertiseType,
        }))
      } else {
        dispatch(Login.selectTeleExpertise({
          teleExpertiseId: locationQueryStrings.teleExpertiseId,
          teleExpertiseName: locationQueryStrings.teleExpertiseName,
          teleExpertiseType: locationQueryStrings.teleExpertiseType,
          teleExpertisePicture: locationQueryStrings.profilePicture,
          teleExpertiseTags: locationQueryStrings.tags,
          teleExpertiseDisciplines: locationQueryStrings.disciplines,
          teleExpertiseOwner: locationQueryStrings.owner,
          teleExpertiseDescription: locationQueryStrings.teleExpertiseDescription,
          teleExpertiseOwnerSpeciality: locationQueryStrings.ownerSpeciality,
          teleExpertiseOwnerCity: locationQueryStrings.ownerCity,
          thePersonal: locationQueryStrings.thePersonal,
        }))
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    locationQueryStrings.portalId,
    locationQueryStrings.portalHost,
    locationQueryStrings.portalTitle,
    locationQueryStrings.portalLogo,
    locationQueryStrings.portalPicture,
    locationQueryStrings.teleExpertiseId,
    locationQueryStrings.teleExpertiseName,
    locationQueryStrings.teleExpertiseType,
    locationQueryStrings.profilePicture,
    locationQueryStrings.tags,
    locationQueryStrings.owner,
    locationQueryStrings.ownerSpeciality,
    locationQueryStrings.ownerCity,
    locationQueryStrings.disciplines,
    locationQueryStrings.teleExpertiseDescription,
    locationQueryStrings.thePersonal,
  ], [locationQueryStrings])

  const submit = codeFilling => e => {
    e.preventDefault()

    dispatch(codeFilling ? Login.checkCode() : Login.sendCredentials())
  }

  const handleCredentialChange = name => e =>
    dispatch(Login.updateCredential({
      name,
      value: e.target.value,
    }))

  const handleCodeChange = e =>
    dispatch(Login.updateOtpCode(e.target.value))

  const resendCode = mode => e => {
    e.preventDefault()
    dispatch(Login.updateOtpMode(mode))
    dispatch(Login.resendCode())
  }

  const connexionForVisioconf = selectedVisioconference.type === 'staff'

  useEffect(() => {
    if (errorMessage)
      alert.show(errorMessage, { timeout: 5000 })
  }, [alert, errorMessage])

  const [pending, setPending] = useState(true)
  const [secondsLeft, setSecondsLeft] = useState(null)

  useEffect(() => {
    if ((codeSent || codeResent) && mode) {
      alert.show(`Un ${codeResent ? 'nouveau' : ''} code vous a été envoyé par ${mode}`, { timeout: 5000 })
      setSecondsLeft(10)
    }
  }, [alert, codeSent, codeResent, mode])

  useEffect(() => {
    if (!secondsLeft) {
      setPending(false)
      return
    }
    setPending(true)
    const timeOutId = setTimeout(() => setSecondsLeft(secondsLeft - 1), 1000)
    return () => clearTimeout(timeOutId);
  }, [secondsLeft])

  return (
    <LoginContainer>
      <Ui.Form.FormContainer>
        {registrationSuccess
          ? <>
            <Ui.BasicElement.H1>Bienvenue sur Skemeet</Ui.BasicElement.H1>
            <Ui.BasicElement.P big>Connectez-vous avec vos identifiants pour valider votre inscription
              {savedPatient
                ? ` et présenter ${savedPatient.firstname} ${savedPatient.lastname} à ${savedTeleExpertise.name}`
                : ''}.</Ui.BasicElement.P>
          </>
          : savedPatient && savedPatient.lastname
            ? <><Ui.BasicElement.H1>
              Connectez-vous
            </Ui.BasicElement.H1>
              <Ui.BasicElement.P>
                Connectez-vous pour présenter {savedPatient.firstname} {savedPatient.lastname} à {savedTeleExpertise.name}
              </Ui.BasicElement.P>
            </>
            : <>
              <Ui.BasicElement.H1>Connectez-vous</Ui.BasicElement.H1>
            </>
        }
        {selectOneMoreTry && <OneMoreTry>Il vous reste un dernier essai avant le blocage de votre compte pendant 15 minutes. Si vous n'êtes pas sûr de votre mot de passe, vous pouvez le changer depuis <a href="/mot-de-passe-oublie">cette page</a>.</OneMoreTry>}
        {connexionForVisioconf
          ? <Ui.BasicElement.P>Participez à la visioconférence <Ui.Global.GreenText>{selectedVisioconference.name}</Ui.Global.GreenText></Ui.BasicElement.P>
          : null
        }
        <form onSubmit={submit(codeFilling)} noValidate>
          <Ui.Layout.FlexBox flow="column nowrap">
            <Ui.Form.FormGroup>
              <Ui.Layout.BlockContainer>
                <Ui.Form.TextInput
                  htmlFor="email"
                  name="email"
                  label="Email *"
                  value={credentials.email.value}
                  onChange={handleCredentialChange('email')}
                  error={credentials.email.errors[0]}
                  autoFocus={!email}
                  noSubmit
                />
              </Ui.Layout.BlockContainer>
              <Ui.Layout.BlockContainer>
                <Ui.Form.TextInput
                  htmlFor="password"
                  name="password"
                  label="Mot de passe *"
                  value={credentials.password.value}
                  onChange={handleCredentialChange('password')}
                  error={credentials.password.errors[0]}
                  type="password"
                  autoFocus={email}
                  noSubmit
                />
                <Ui.BasicElement.Link to="/mot-de-passe-oublie">Mot de passe oublié ?</Ui.BasicElement.Link>
              </Ui.Layout.BlockContainer>
            </Ui.Form.FormGroup>
            {codeFilling &&
              <Ui.Form.FormGroup>
                <Ui.Form.TextInput
                  htmlFor="code"
                  name="code"
                  label={`Code reçu par ${mode}`}
                  value={code.value}
                  onChange={handleCodeChange}
                  error={code.errors[0]}
                  autoFocus
                  noSubmit
                />
                <InstallTotp canInstallTotp={canInstallTotp}>
                  <InstallTotpLabel>
                    Connexion sécurisée ?
                  </InstallTotpLabel>
                  <InstallTotpCheckbox>
                    <Ui.Form.Checkbox
                      checked={installTotp}
                      onChange={e => dispatch(Login.setInstallTotp(e.target.checked))}
                    />Ne plus demander de code durant 1 mois
                  </InstallTotpCheckbox>
                </InstallTotp>
              </Ui.Form.FormGroup>
            }
            <SubmitButton
              type="submit"
              isGreen
              isFilled
              disabled={sending}
            >
              Se connecter
            </SubmitButton>
            {codeFilling &&
              <Ui.Form.FormGroup>
                <NoCode>
                  Vous n'avez pas reçu de code ?
                </NoCode>
                <Ui.BasicElement.P>
                  {secondsLeft
                    ? <>Vous pourrez demander un nouveau code dans {secondsLeft} {pluralize('seconde', secondsLeft)}</>
                    : 'Recevoir un nouveau code par :'
                  }
                </Ui.BasicElement.P>
                <Ui.Button.BasicButton
                  as="button"
                  isSmall
                  disabled={sending || pending}
                  onClick={resendCode('mail')}>
                  Email
                </Ui.Button.BasicButton>
                {mode === 'sms' &&
                  <Ui.Button.BasicButton
                    as="button"
                    isSmall
                    disabled={sending || pending}
                    onClick={resendCode('sms')}>
                    SMS
                  </Ui.Button.BasicButton>
                }
              </Ui.Form.FormGroup>
            }
            {!registrationSuccess && !codeFilling &&
              <NotRegisteredParagraph>
                Vous n'avez pas encore de compte ?<br />
                <Ui.BasicElement.Link to="inscription">Inscrivez-vous</Ui.BasicElement.Link>
              </NotRegisteredParagraph>
            }
          </Ui.Layout.FlexBox>
        </form>
        <Ui.PortailLayout.Informations>
          <Ui.BasicElement.P medium><i>Pour plus de détails sur le traitement de vos données, voir notre <GreenInfosLink to="/politique-de-confidentialite">politique de confidentialité</GreenInfosLink>.</i></Ui.BasicElement.P>
        </Ui.PortailLayout.Informations>
      </Ui.Form.FormContainer>
    </LoginContainer>
  )
}

export const LoginContainer = styled(props => <Ui.PortailLayout.PageContainer {...props} />)`
  ${Ui.BasicElement.H1} {
    margin-bottom: 1.4rem;
    font-size: 2rem;
    line-height: 2.5rem;
  }
  ${Ui.BasicElement.H2} {
    line-height: 1.8rem;
  }
  ${Ui.Form.InputContainer}:last-of-type {
    margin-bottom: .2rem;
  }
  ${Ui.PortailLayout.Content} {
    flex: 50%;
  }
  ${Ui.PortailLayout.Informations} {
    ${Ui.BasicElement.P} {
      font-size: .65rem;
    }
  }
  ${Ui.Form.FormContainer} {
    padding: 1.8rem;
    background: white;
    box-shadow: 0 2px 5px 1px rgba(134, 174, 197, 0.24);
    border-radius: 8px;
    @media only screen and (min-width: ${props => props.theme.device.desktop}) {
      width: 400px;
      margin: 3rem auto;
    }
  }
  ${Ui.BasicElement.Link} {
    font-weight: 700;
  }
  ${Ui.Form.FormGroup} {
    margin-bottom: 1rem;
    > button {
      margin-right: .5rem;
    }
  }
`

const OneMoreTry = styled.div`
  font-size: .875rem;
  line-height: 1.2rem;
  a {
    color: ${props => props.theme.patientGreen};
  }
`

const GreenInfosLink = styled(props => <Link {...props} />)`
  font-size: .6rem;
  color: ${props => props.theme.patientGreen};
  text-decoration: none;
`

const InstallTotp = styled.div`
  display: ${props => props.canInstallTotp ? 'block' : 'none'};
`

const InstallTotpLabel = styled(props => <Ui.BasicElement.P {...props} />)`
  margin-block-end: .5rem;
`

const InstallTotpCheckbox = styled.div`
  display: flex;
  align-items: center;
  font-size: .8rem;
  color: ${props => props.theme.blueGrey};
  > label {
    min-width: 1.1rem;
    min-height: 1.1rem;
    margin-right: .5rem;
    > i {
      font-size: .6rem;
    }
  }
`

const SubmitButton = styled(props => <Ui.Button.BasicButton {...props} />)`
  align-self: center;
  width: 16rem;
`

const RegisterParagraph = styled.p`
  font-size: 1.1rem;
  line-height: 1.6rem;
  ${Ui.BasicElement.Link} {
    font-size: 1.1rem;
  }
`

const NotRegisteredParagraph = styled(props => <RegisterParagraph {...props} />)`
  margin-top: 2rem;
`

const NoCode = styled.p`
  font-weight: 600;
  margin-top: 2rem;
`

export default LoginFormComponent;
