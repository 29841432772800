import styled from 'styled-components/macro'
import * as Ui from '../Component/Ui'

export const OpinionListContainer = styled.div`
@media only screen and (min-width: ${props => props.theme.device.desktop}) {
  ${Ui.Layout.PanelFilterContainer} {
    padding: .6rem 1.3rem;
  }
}
${Ui.SearchBar.SearchBarContainer} {
    margin-bottom: 0;
    width: 100%;
  }
${Ui.Search.FiltersAndSorting}:first-child {
    margin-bottom: 1rem;
}
`
