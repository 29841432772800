import { createSlice } from '@reduxjs/toolkit';
import { uniqById } from '../Util/Object';
import {
  saveField as commonSaveField,
  setEditField as commonSetEditField,
  restoreInitialValue as commonRestoreInitialValue,
  updateInitialValue as commonUpdateInitialValue,
} from '../Util/InlineEditActions';
import { normalizeEmail } from '../Util/Format';

export const SECTION_PATIENTS = 'patients'
export const SECTION_COLLEAGUES = 'médecins'
export const SECTION_SCHEDULING = 'programmation'
export const SECTION_CUSTOM_FIELDS = 'fiche patient'
export const SECTION_PORTAL = 'portail'
export const SECTION_PARAMETERS = 'paramètres'

export const SCOPE = {
  profile: 'my_account',
  portalPro: 'portal',
};

export const INITIAL_STATE = {
  data: {
    title: { value: '', isFieldEdit: false, errors: [], validationRules: ['notEmpty'] },
    firstname: { value: '', initialValue: '', isFieldEdit: false, errors: [], validationRules: ['notEmpty'] },
    lastname: { value: '', initialValue: '', isFieldEdit: false, errors: [], validationRules: ['notEmpty'] },
    email: { value: '', initialValue: '', isFieldEdit: false, errors: [], validationRules: ['email', 'notEmpty'] },
    name: { value: '', initialValue: '', isFieldEdit: false, errors: [], validationRules: ['notEmpty'] },
    description: { value: '', initialValue: '', isFieldEdit: false, errors: [], validationRules: ['notEmpty'] },
    city: { value: '', initialValue: '', isFieldEdit: false, errors: [], validationRules: [] },
    status: { value: '', errors: [], validationRules: [] },
    rpps: { value: '', initialValue: '', isFieldEdit: false, errors: [], validationRules: ['rpps'] },
    rppsInitial: { value: '', errors: [], validationRules: [] },
    uploadPractitionerProof: { value: '', errors: [], validationRules: [] },
    cellPhone: { value: '', initialValue: '', isFieldEdit: false, errors: [], validationRules: [] },
    oldPassword: { value: '', errors: [], validationRules: ['password'] },
    newPassword: { value: '', errors: [], validationRules: ['password'] },
    newPasswordConfirmation: { value: '', errors: [], validationRules: ['password'] },
    profilePicture: { value: '', errors: [], validationRules: [] },
    portalProLogo: { value: '', errors: [], validationRules: [] },
    portalProPicture: { value: '', errors: [], validationRules: [] },
    portalProPictureAlign: { value: 2, errors: [], validationRules: [] },
    portalProPictureStretch: { value: true, errors: [], validationRules: [] },
    speciality: { value: '', initialValue: '', isFieldEdit: false, errors: [], validationRules: [] },
    url: { value: '', errors: [], validationRules: [] },
    subject: { value: '', errors: [], validationRules: [] },
    message: { value: '', initialValue: '', isFieldEdit: false, errors: [], validationRules: [] },
    estimateMessage: { value: 'Bonjour, Je suis intéressé par un devis', errors: [], validationRules: [] },
    establishmentName: { value: '', errors: [], validationRules: [] },
    expertises: { value: [], errors: [], validationRules: [] },
    subscriptionExpertises: { value: [], errors: [], validationRules: [] },
    organizationName: { value: [], initialValue: '', isFieldEdit: false, errors: [], validationRules: [] },
    organizations: { value: [], errors: [], validationRules: [] },
    permissions: {
      value: {
        _canBe: {
          edited: true,
          seenWithTeleExpertiseLimitPatient: true,
          deleted: false
        },
        _canBeModified: {}
      },
      errors: [],
      validationRules: []
    },
  },
  availableExpertises: [],
  userExpertises: [],
  keywordsNotFound: [],
  pending: false,
  loading: false,
  success: false,
  deleted: false,
  error: false,
  errorMessage: null,
  isEditMode: {
    profile: false,
    profilePicture: false,
    password: false,
    speciality: false,
    organization: false,
    teleExpertises: false,
    members: false,
    message: false,
    subscription: false,
    portalPro: false,
    portalProLogo: false,
    organizationLogo: false,
  },
  updateUrlMessageBoxOpen: false,
  updateUrlMessageSent: false,
  estimateRequestMessageBoxOpen: false,
  estimateRequestMessageSent: false,
  currentUser: null,
  search: {
    members: { value: '', loading: false },
    expertises: { value: '', loading: false },
    subscriptionExpertises: { value: '', loading: false },
    patients: { value: '', loading: false },
    opinions: { value: '', loading: false },
    staffs: { value: '', loading: false },
    cohorts: { value: '', loading: false },
  },
  subscriptionExpertisesLoading: true,
  offerSelected: null,
  quantityTeleExpertiseSubscrib: 1,
  paymentCardToken: null,
  loadingSubscription: false,
  successSubscription: false,
  successUnlockTeleExpertise: false,
  teleExpertiseDowngradeNames: null,
}

const slice = createSlice({
  name: 'profile',
  initialState: INITIAL_STATE,
  reducers: {

    updateField: (state, { payload: { name, value, errors = [] } }) => ({
      ...state,
      data: {
        ...state.data,
        [name]: {
          ...state.data[name],
          value: name === 'email' ? normalizeEmail(value) : value,
          errors,
        },
      },
      error: false,
      errorMessage: null,
    }),

    cleanPasswordErrors: (state) => ({
      ...state,
      data: {
        ...state.data,
        newPasswordConfirmation: {
          ...state.data.newPasswordConfirmation,
          errors: [],
        },
      },
    }),

    updateData: (state, { payload: { name, value, errors = [] } }) => ({
      ...state,
      data: {
        ...state.data,
        [name]: {
          ...state.data[name],
          value,
          errors,
        },
      },
      error: false,
      errorMessage: null,
    }),

    getUserData: (state) => ({
      ...state,
      loading: true,
    }),

    setEditedOrganization: (state, { payload }) => ({
      ...state,
      data: {
        ...state.data,
        name: {}
      }
    }),

    editOrganization: (state) => ({
      ...state,
    }),

    removeOrganization: (state, { payload }) => ({
      ...state,
      data: {
        ...state.data,
        organizations: {
          ...state.data.organizations,
          value: state.data.organizations.value.filter(organizationId => Number(organizationId) !== Number(payload))
        }
      }
    }),

    received: (state, { payload }) => ({
      ...state,
      data: Object
        .entries(payload)
        .reduce((acc, [name, value]) => ({
          ...acc,
          [name]: {
            ...state.data[name],
            value,
            initialValue: value,
          },
        }), state.data),
    }),

    toggle: (state, { payload: { value, toggled, name } }) => ({
      ...state,
      data: {
        ...state.data,
        [name]: {
          ...state.data[name],
          value: toggled
            ? [...state.data[name].value, value]
            : state.data[name].value.filter(e => value.id !== e.id),
        },
      },
      search: {
        ...state.search,
        [name]: {
          ...state.search[name],
          value: '',
        }
      },
      error: false,
      errorMessage: null,
    }),

    updateSubscribed: (state, { payload: { resource, resourceId, toggled } }) => ({
      ...state,
      data: {
        ...state.data,
        subscriptionExpertises: {
          ...state.data.subscriptionExpertises,
          value: toggled
            ? [...state.data.subscriptionExpertises.value, resourceId]
            : state.data.subscriptionExpertises.value.filter(id => resourceId !== id),
        }
      }
    }),

    addExpertise: (state, { payload: { type, id } }) => ({
      ...state,
      data: {
        ...state.data,
        expertises: {
          ...state.data.expertises,
          value: [
            ...state.data.expertises.value,
            { type, id }
          ]
        }
      },
      search: {
        ...state.search,
        expertises: {
          ...state.search.expertises,
          value: '',
        }
      },
    }),

    removeExpertise: (state, { payload: { type, id } }) => ({
      ...state,
      data: {
        ...state.data,
        expertises: {
          ...state.data.expertises,
          value:
            state.data.expertises.value
              .filter(teleExpertise => teleExpertise.type !== type || teleExpertise.id !== id)
        }
      },
      search: {
        ...state.search,
        expertises: {
          ...state.search.expertises,
          value: '',
        }
      },
    }),

    setQuery: (state, { payload: { name, value, onlyLocked = 0 } }) => ({
      ...state,
      search: {
        ...state.search,
        [name]: {
          ...state.search[name],
          value
        }
      }
    }),

    setEditMode: (state, { payload: { target, value } }) => ({
      ...state,
      isEditMode: {
        ...state.isEditMode,
        [target]: value,
      }
    }),

    success: (state, { payload: id }) => ({
      ...state,
      loading: false,
      pending: false,
      error: false,
      success: true,
      colleagueId: id
    }),

    successUploadPractitionerProof: state => ({
      ...state,
      data: {
        ...state.data,
        uploadPractitionerProof: {
          ...state.data.uploadPractitionerProof,
          value: true
        }
      }
    }),

    invalidate: (state, { payload }) => ({
      ...state,
      pending: false,
      error: true,
      errorMessage: payload,
    }),

    apiError: (state, action) => ({
      ...state,
      errorMessage: action.payload,
      pending: false,
      error: true,
      success: false,
    }),

    receivedAvailableExpertises: (state, { payload: { teleExpertises, keywordsNotFound } }) => ({
      ...state,
      availableExpertises: [
        ...state.availableExpertises,
        ...teleExpertises
      ],
      keywordsNotFound,
      loading: false,
      search: {
        ...state.search,
        expertises: {
          ...state.search.expertises,
          loading: false
        }
      }
    }),

    receivedUserExpertises: (state, { payload }) => ({
      ...state,
      userExpertises: [
        ...state.userExpertises,
        ...payload,
      ]
    }),

    receivedSubscriptionExpertises: (state, { payload }) => ({
      ...state,
      data: {
        ...state.data,
        subscriptionExpertises: {
          value: [
            ...state.data.subscriptionExpertises.value,
            ...payload
          ]
        }
      },
      subscriptionExpertisesLoading: false
    }),

    clean: state => INITIAL_STATE,

    sendMessage: (state, { payload }) => ({
      ...state
    }),

    sendEstimateRequest: (state, { payload }) => ({
      ...state,
      pending: true,
    }),

    fetchExpertises: (state, { payload }) => ({
      ...state
    }),

    fetchUserExpertises: (state, { payload }) => ({
      ...state
    }),

    fetchSubscriptionExpertises: (state, { payload }) => ({
      ...state,
      subscriptionExpertisesLoading: true,
    }),

    fetchDisciplines: (state, { payload }) => ({
      ...state
    }),

    fetchAvailableExpertises: (state, { payload }) => ({
      ...state,
      search: {
        ...state.search,
        expertises: {
          ...state.search.expertises,
          loading: true
        }
      },
      availableExpertises: state
        .availableExpertises
        .filter(exp =>
          state.data[payload].value
            .find(expertise =>
              expertise.type === exp.type && Number(expertise.id) === Number(exp.id)
            ))
    }),

    fetchExpertise: state => state,

    fetchOrganizations: (state, { payload }) => ({
      ...state
    }),

    getTeleExpertise: (state, { payload: { expertiseId, expertiseType } }) => ({
      ...state,
      loading: true
    }),

    selectExpertise: (state, { payload: { value, errors = [] } }) => ({
      ...state,
      data: {
        ...state.data,
        subscriptionExpertises: {
          ...state.data.subscriptionExpertises,
          value: [
            ...state.data.subscriptionExpertises.value,
            value
          ].filter(uniqById),
          errors,
        },
      },
      error: false,
      errorMessage: null,
      subscriptionExpertisesLoading: false,
    }),

    fetchOrganization: (state, { payload }) => ({
      ...state,
    }),

    fetchPortalPro: (state, { payload }) => ({
      ...state,
      loading: true,
    }),

    savePortalPro: (state, { payload }) => ({
      ...state,
    }),

    setUrlMessageBox: (state, { payload }) => ({
      ...state,
      updateUrlMessageBoxOpen: payload,
    }),

    setUpdateUrlMessageSent: (state, { payload }) => ({
      ...state,
      updateUrlMessageSent: true,
    }),

    setEstimateRequestMessageBox: (state, { payload }) => ({
      ...state,
      estimateRequestMessageBoxOpen: payload,
    }),

    setEstimateRequestMessageSent: (state, { payload }) => ({
      ...state,
      estimateRequestMessageSent: true,
      pending: false,
    }),

    saveProfile: (state, { payload }) => ({
      ...state,
    }),

    savePassword: (state, { payload }) => ({
      ...state,
    }),

    updateLocked: (state, { payload }) => ({
      ...state,
    }),

    unlockExpertise: (state, { payload }) => ({
      ...state,
    }),

    removeImage: (state, { payload }) => ({
      ...state,
      imageries: state
        .imageries
        .filter(({ id }) => id !== payload),
    }),

    removeProfilePicture: state => ({
      ...state,
      data: {
        ...state.data,
        profilePicture: {
          ...state.data.profilePicture,
          value: ''
        }
      }
    }),

    removePortalProPicture: state => ({
      ...state,
      data: {
        ...state.data,
        portalProPicture: {
          ...state.data.portalProPicture,
          value: ''
        }
      }
    }),

    removePortalProLogo: state => ({
      ...state,
      data: {
        ...state.data,
        portalProLogo: {
          ...state.data.portalProLogo,
          value: ''
        }
      }
    }),

    updateImage: (state, { payload: { type, fileName } }) => ({
      ...state,
      data: {
        ...state.data,
        [type]: {
          ...state.data[type],
          value: fileName
        }
      },
    }),

    selectOffer: (state, { payload }) => ({
      ...state,
      offerSelected: payload,
    }),

    updateQuantityTeleExpertiseSubscrib: (state, { payload }) => ({
      ...state,
      quantityTeleExpertiseSubscrib: payload,
    }),

    receivedPaymentCardData: (state, { payload: { paymentCardToken } }) => ({
      ...state,
      paymentCardToken: paymentCardToken,
      loadingSubscription: true,
      successSubscription: false,
      teleExpertiseDowngradeNames: null,
    }),

    saveSubscription: (state) => ({
      ...state,
      loadingSubscription: true,
      successSubscription: false,
      teleExpertiseDowngradeNames: null,
    }),

    successSubscription: (state, { payload: { teleExpertiseDowngradeNames } }) => ({
      ...state,
      offerSelected: null,
      loadingSubscription: false,
      successSubscription: true,
      teleExpertiseDowngradeNames: teleExpertiseDowngradeNames ? teleExpertiseDowngradeNames : null,
    }),

    setSuccessUnlockTeleExpertise: (state, { payload: { successUnlockTeleExpertise } }) => ({
      ...state,
      successUnlockTeleExpertise
    }),

    errorPayment: (state) => ({
      ...state,
      loadingSubscription: false,
    }),

    validateDiscipline: (state) => ({
      ...state,
    }),

    validateRpps: (state) => ({
      pending: true,
      ...state,
    }),

    fetchAllRppsRegistration: (state) => ({
      ...state,
    }),

    receivedAllRppsRegistration: (state, { payload }) => ({
      ...state,
      allRppsRegistration: payload,
    }),
    commonSaveField,
    commonSetEditField,
    commonRestoreInitialValue,
    commonUpdateInitialValue,
  },
})

export const {
  updateField,
  cleanPasswordErrors,
  toggle,
  updateSubscribed,
  setQuery,
  setEditMode,
  success,
  successUploadPractitionerProof,
  invalidate,
  apiError,
  fetchAvailableExpertises,
  receivedAvailableExpertises,
  fetchUserExpertises,
  receivedUserExpertises,
  clean,
  removeOrganization,
  fetchExpertises,
  fetchExpertise,
  getTeleExpertise,
  selectExpertises,
  fetchOrganization,
  fetchPortalPro,
  received,
  receivedPortalPro,
  removeImage,
  getUserData,
  receivedUser,
  fetchOrganizations,
  fetchDisciplines,
  sendMessage,
  sendEstimateRequest,
  savePortalPro,
  saveProfile,
  savePassword,
  addExpertise,
  removeExpertise,
  updateImage,
  editOrganization,
  setEditedOrganization,
  setUrlMessageBox,
  setUpdateUrlMessageSent,
  setEstimateRequestMessageBox,
  setEstimateRequestMessageSent,
  updateLocked,
  unlockExpertise,
  selectExpertise,
  fetchSubscriptionExpertises,
  receivedSubscriptionExpertises,
  selectOffer,
  updateQuantityTeleExpertiseSubscrib,
  receivedPaymentCardData,
  saveSubscription,
  successSubscription,
  setSuccessUnlockTeleExpertise,
  errorPayment,
  updateTeleExpertiseDowngradeNames,
  validateDiscipline,
  validateRpps,
  fetchAllRppsRegistration,
  receivedAllRppsRegistration,
  removeProfilePicture,
  removePortalProLogo,
  removePortalProPicture,
  updateData,
  commonSaveField: saveField,
  commonSetEditField: setEditField,
  commonRestoreInitialValue: restoreInitialValue,
  commonUpdateInitialValue: updateInitialValue,
} = slice.actions

export const selectData = state => state.profile.data
export const selectKeywordsNotFound = state => state.profile.data.selectKeywordsNotFound
export const selectAvailableExpertises = state => state.profile.availableExpertises
export const selectRegisteredRpps = state => state.profile.allRppsRegistration
export const selectSuccessUnlockTeleExpertise = state => state.profile.successUnlockTeleExpertise

export default slice.reducer
