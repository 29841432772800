import styled from 'styled-components/macro'
import * as Ui from '../Component/Ui'
import Editable from '../Util/Editable'
import { Link } from "react-router-dom";

export const ColleaguesBlockHeader = styled(props => <Ui.Layout.FlexBox {...props} />)`
  flex-direction: column;
  font-size: .875rem;
  ${Ui.Form.CheckboxContainer} {
    border-color: ${props => props.theme.grey};
  }
  ${Ui.Layout.FlexBox} {
    align-items: center;
    margin-right: 1.3rem;
    margin-bottom: 1rem;
    & > div {
      margin-left: .5rem;
    }
    @media only screen and (min-width: ${props => props.theme.device.desktop}) {
      flex-direction: row-reverse;
      & > div {
        margin-right: .5rem;
      }
    }
  }
  @media only screen and (min-width: ${props => props.theme.device.desktop}) {
    flex-direction: row;
  }
`

export const LabelButtonContainer = styled.div`
  margin-top: 1rem;
  display: flex;
`

export const CloseSessionLink = styled.span`
  color: ${props => props.theme.patientGreen} !important;
  font-weight: 700 !important;
  cursor: pointer;
`

export const LeftPanelContainer = styled(props => <Ui.Layout.PanelContainer {...props} />)`
  ${Ui.BasicElement.ExpertisePicture} {
    margin-top: 1.5rem;
  }
`

export const LabelsSection = styled.div`
  ${Ui.Select.SelectContainer} {
    min-width: 12rem;
  }
  > button {
    margin-top: .8rem;
  }
`

export const TagsContainer = styled.section`
  ${Ui.BasicElement.P} {
    margin-bottom: .2rem;
    margin-top: .8rem;
  }
`

export const DetailsContainer = styled.div`
  nav {
   & > :last-child {
     span {
       margin-right: 0;
      }
    }
    @media only screen and (min-width: ${props => props.theme.device.desktop}) {
      ${Ui.Layout.NavLinkItem} {
        margin-right: 1.7rem;
      }
      justify-content: flex-start;
    }
  }
  ${Ui.Layout.Panel} {
      min-height: 5rem;
      ${TagsContainer} {
        margin-bottom: 1rem;
      }
  }
  ${Ui.BasicElement.H1} {
    margin-top: 2rem;
    @media only screen and (max-width: ${props => props.theme.device.desktop}) {
      font-size: 1.125rem !important;
      margin-top: 1rem;
    }
  }
  ${Ui.Layout.Content} {
    @media only screen and (min-width: ${props => props.theme.device.desktop}) {
      & > :nth-child(2) {
        margin-top: 5rem;
      }
    }
    @media only screen and (max-width: ${props => props.theme.device.desktop}) {
        padding:0;
      }
  }
  ${Ui.Form.Input} {
    &:read-only {
      border-color: transparent;
      opacity: 1;
    }
  }
  ${Ui.Layout.Column} {
    margin-top: 0;
  }
`

export const FullSpaceContainer = styled(props => <Ui.Layout.FlexBox {...props} />)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
`

export const PanelButtons = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
`

export const SubTitlesContainer = styled.div`
  word-wrap: break-word;
  margin-top: .3rem;
  p {
    margin: 0;
    line-height: 1.4rem;
  }
`

export const NextSessionContainer = styled.div`
  margin: 1rem 0;
  display: flex;
  ${Ui.Button.Button} {
    min-width: 10rem;
    margin-bottom: .5rem;
  }
  p {
    margin: 0;
    line-height: 1.4rem;
  }
  a {
    text-decoration: none;
  }
  @media only screen and (max-width: ${props => props.theme.device.desktop}) {
      /* display: none; */
  }
`

export const CurrentSessionLink = styled(props => <Link {...props} />)`
  font-weight: 600;
`

export const DatePickerBox = styled.div`
  width: 100%;
  padding: 0 1rem;
  display: flex;
  > div {
      width: 100%;
  }
`

export const ArchiveList = styled.ul`
  ${Ui.BasicElement.H2} {
    color: ${props => props.theme.grey}
  }
  list-style: none;
  padding: 0;
  li {
    font-weight: 600;
    line-height: 2rem;
  }
`

export const HourInput = styled(props => <Ui.Form.TextInput {...props} />)`

`
export const NextSessionContent = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
`

export const RowManualStaff = styled(props => <Ui.Layout.Row {...props} />)`
  align-items: center;
  margin-top: .3rem;
  margin-bottom: .3rem;

  ${Ui.Form.InputContainer} {
    margin-top: 0 !important;
  }
  ${Ui.Layout.Column}:nth-of-type(1) {
    max-width: 12rem;
  }
  ${Ui.Layout.Column}:nth-of-type(2) {
    max-width: 8rem;
  }
  @media only screen and (max-width: ${props => props.theme.device.desktop}) {
    .react-date-picker__inputGroup {
      min-width: 6rem;
    }
  }
`

export const ProgramingSection = styled(props => <Ui.Layout.Section {...props} />)`
  ${Ui.Select.SelectContainer} {
    width: 17rem;
    margin-bottom: 1rem;
  }
`

export const HeaderButtons = styled(props => <Ui.Layout.ButtonGroup {...props} />)`
  margin-bottom: 1.4rem;
`

export const SessionDatesContainer = styled.div`
  margin-bottom: 1rem;
  ${Editable.ViewBlock} {
    margin-bottom: 0;
  }
  ${Ui.BasicElement.P} {
    margin-bottom: 0;
  }
`

export const VisioAnnouncement = styled(props => <Ui.BasicElement.P {...props} />)`
  color: ${props => props.theme.staffPurple};
  margin-top: 1rem !important;
`

export const ShowPortalButton = styled.div`
  margin-top: .5rem;
  margin-bottom: 1rem;
`
export const QrCodeTitle = styled(props => <Ui.BasicElement.P {...props} />)`
  margin-bottom: .6rem;
`
