import React from 'react';
import { Helmet } from "react-helmet";

const SEOTagsComponent = ({ title, description, keywords }) => {

  return (
    <Helmet>
      <title>
        {title || "Skemeet"}
      </title>
      <meta name="description" content={description || "Skemeet est un portail de téléexpertise médicale permettant aux médecins de partager de façon simple et sécurisée leurs patients."} />
      {keywords &&
        <meta name="keywords" content={keywords} />
      }
    </Helmet>
  )
}

export default SEOTagsComponent;
