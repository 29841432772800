import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import * as Ui from './Ui'
import styled from 'styled-components/macro'
import * as Homepage from '../State/Homepage'
import * as ContactUs from '../State/ContactUs';
import { fr } from '../Util/i18n';
import Neurosphinx from '../Asset/Image/logo-neurosphinx.png';
import Oscar from '../Asset/Image/logo-oscar.png';
import Tetecou from '../Asset/Image/logo-tetecou.png';
import Sensgene from '../Asset/Image/logo-sensgene.png';
import Anddirares from '../Asset/Image/logo-anddirares.png';
import Fai2r from '../Asset/Image/logo-fai2r.png';
import Fimarad from '../Asset/Image/logo-fimarad.png';
import FeatureCohorte from '../Asset/Image/feature-cohorte.jpg';
import FeatureFichePatient from '../Asset/Image/feature-fiche-patient.jpg';
import FeaturePortail from '../Asset/Image/feature-portail.jpg';
import FeatureDicom from '../Asset/Image/feature-dicom.jpg';
import ScreenZoom from '../Asset/Image/feature-screen-zoom.jpg';
import ArrowCarousel from '../Asset/Image/ArrowCarousel.png';
import CookieConsent from "react-cookie-consent";
import ReactGA from 'react-ga';
import Carousel from "react-simply-carousel";
import { Link } from "react-router-dom";
import {
  staffPublicUrl,
  cohortPublicUrl,
  opinionPublicUrl,
  serverEnv,
} from '../Util/Config'
import { useCombobox } from 'downshift';
import { KeywordsNotFoundMessage } from '../Util/Message'

const HomePageComponent = () => {

  const initReactGA = () => {
    if (serverEnv === 'prod') {
      ReactGA.initialize('UA-179754963-1');
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }

  return (
    <>
      <HomepageLayout>
        <Ui.PortailHeader />
        <HomepageContainer >
          <Ui.PortailLayout.FlexPage>
            <Ui.PortailLayout.Content>
              <HeroContainer />
            </Ui.PortailLayout.Content>
            <Ui.PortailLayout.Figure />
          </Ui.PortailLayout.FlexPage>
        </HomepageContainer>
        <PresentationContainer>
          <Target />
          <Solutions />
          <Features />
          <Offer />
        </PresentationContainer>
        <PartnerContainer />
        <Ui.PortailLayout.Footer />
      </HomepageLayout>
      <CookieConsentContainer>
        <CookieConsent
          style={{ backgroundColor: "#3bc3a8" }}
          buttonStyle={{
            color: "#FFF",
            backgroundColor: "#3bc3a8",
            border: '1px solid white',
            margin: '5px',
            padding: '.8rem',
            marginRight: '1rem'
          }}
          declineButtonStyle={{
            color: "#FFF",
            backgroundColor: "#3bc3a8",
            border: '1px solid white',
            padding: '.8rem',
          }}
          onAccept={initReactGA}
          enableDeclineButton
          buttonText="Accepter"
          declineButtonText="Refuser"
        >
          <div style={{ lineHeight: '1.5rem' }}>
            Nous utilisons des cookies qui permettent le bon fonctionnement de notre site web et ses services. Voir notre <a href="/cookies" target="_blank">politique de cookies</a>.
          </div>
        </CookieConsent>
      </CookieConsentContainer>
    </>
  )
}

const CookieConsentContainer = styled.div`
  a {
    color: white;
    text-decoration: none;
    font-weight: 700;
    &:hover {
      text-decoration: underline;
    }
  }
  button {
    &:hover {
      background-color: blue;
    }
  }
`

const HomepageLayout = styled.div`
  max-height: auto;
  @media only screen and (max-width: ${props => props.theme.device.tablet}) {
    max-height: auto;
  }

  ${Ui.PortailLayout.FooterContainer} {
    display: flex;
  }
`

const HomepageContainer = styled(props => <Ui.PortailLayout.PageContainer {...props} />)`
  ${Ui.Global.GreenText} {
    font-size: inherit;
  }

  ${Ui.PortailLayout.Content} {
    flex: 1.1;
    min-height: auto;
    margin-left: 6rem;

    @media only screen and (min-width: ${props => props.theme.device.desktop}) {
      height: 600px;
    }
    @media only screen and (max-width: ${props => props.theme.device.tablet}) {
      margin-left: .5rem;
    }
  }

  ${Ui.PortailLayout.Figure} {
    z-index: -1;
    margin-right: 5rem;
    margin-left: 1rem;

    @media only screen and (max-width: ${props => props.theme.device.tablet}) {
      margin-right: 0;
      flex: 1 0 20rem;
    }
  }

  ${Ui.BasicElement.H1} {
    font-size: 2.3rem;
    line-height: 2.5rem;
    margin-top: 3rem;
    margin-bottom: 1rem;

    @media only screen and (max-width: ${props => props.theme.device.tablet}) {
      font-size: 2rem;
    }
  }
`
const SearchBarContainer = styled.div`
  margin-right: 1rem;
  flex: 1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  ${Ui.Select.SelectContainer} {
    display: none;
    background-color: white;
    z-index: 10;
    right: 1.2rem;
    top: .4rem;
    min-height: 2.3rem;
    max-height: 2.3rem;
    position: absolute;
    width: 10.5rem;
    ${Ui.Select.SelectLabel} {
      color: ${props => props.theme.grey};
      span {
        color: ${props => props.theme.darkBlue};
      }
    }
    @media only screen and (min-width: ${props => props.theme.device.desktop}) {
      display: flex;
    }
  }
  ${Ui.Dropdown.SearchDropdownContainer} {
    z-index: 2;
    padding-bottom: 1.5rem;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
  ${Ui.Form.DropdownItemLabel} {
    max-width: none;
    white-space: normal;
    overflow: auto;
  };
  ${Ui.Form.DropdownItemSubLabel} {
    white-space: normal;
  };
  ${Ui.SearchBar.SearchBarContainer} {
    z-index: 4;
    margin-bottom: 0;
  };
  ${Ui.SearchBar.Input} {
    z-index: 4;
  };
  p {
    text-align: center;
  }
`

const ResultTitle = styled.div`
  padding-right: 5rem;
`

const ResultSubTitle = styled.div`
  padding-right: 5rem;
`

const ListItemContainer = styled.div`
  border-bottom: 1px solid ${props => props.theme.lightGrey2};
  ${Ui.SearchBar.PlainRoundIcon}, ${Ui.BasicElement.AvatarPicture} {
    margin-left: 1rem;
  }
  ${Ui.Layout.FlexBox} {
    // background: ${props => props.highlighted ? props.theme.lightGrey : 'none'};
    ${Ui.Badge} {
      margin-top: 0;
      color: ${props => props.theme.darkBlue};
      line-height: 1rem;
    }
    position: relative;
    &:first-child {
      padding: .7rem 0;
    }
    cursor: pointer;
    & > div {
      &:first-child {
        font-weight: bold;
        margin-bottom: .3rem;
      }
      font-size: 1rem;
    }
    &:hover {
      background: ${props => props.theme.lightGrey};
    }
    justify-content: flex-start;
  }
`

const TeleExpertiseBadge = styled.span`
  position: absolute;
  right: 1rem;
  top: 0;
  border: 1px solid ${props => props.theme.grey};
  border-radius : 5px;
  font-size: .875rem;
  padding: .2rem .4rem;
`

const ListItem = ({ name, highlighted, ...props }) => {
  const teleExpertiseDomain = props.teleExpertiseType === 'staff'
    ? staffPublicUrl
    : props.teleExpertiseType === 'opinion'
      ? opinionPublicUrl
      : cohortPublicUrl

  const portalUrl = [teleExpertiseDomain, props.publicUri, 'nouveau-patient'].join('/')

  return (
    <ListItemContainer onClick={() => window.location = portalUrl} highlighted={highlighted}>
      <Ui.Layout.FlexBox>
        <Ui.SearchBar.ExpertiseCheckboxIcon isHome value={props} />
        <ResultContent flow="column nowrap">
          <ResultTitle>{name}</ResultTitle>
          <ResultSubTitle>
            {props.thePersonal
              ? [props.ownerSpeciality?.toLowerCase(), props.ownerCity].filter(e => e).join(', ')
              : [props.ownerFullname, props.ownerSpeciality?.toLowerCase(), props.ownerCity].filter(e => e).join(', ')}
          </ResultSubTitle>
          {props.tags.length > 0 &&
            <Ui.Badge>
              {props.tags.map((tags, i) =>
                <span key={`tags_${i}`}>{tags}</span>
              )}
            </Ui.Badge>
          }
          <TeleExpertiseBadge>{fr[props.teleExpertiseType].expertise}</TeleExpertiseBadge>
        </ResultContent>
      </Ui.Layout.FlexBox>
    </ListItemContainer>
  )
}

const ResultContent = styled(props => <Ui.Layout.FlexBox {...props} />)`
  margin-left: 1rem;
`

const DropdownContainer = styled.div`
  ${Ui.BasicElement.P} {
    font-size: 1rem !important;
  }
  display: ${props => props.isOpen ? 'block' : 'none'};
  max-height: 14rem;
  overflow: auto;
  padding-top: 3rem;
  z-index: 3;
  box-shadow: 0 2px 12px 0 rgba(33, 65, 82, 0.3);
  position: absolute;
  top: 50%;
  width: 100%;
  background: white;
  min-height: 3rem;
  border-radius: 8px;
  color: ${props => props.theme.darkBlue};
`

function DropdownCombobox({ value, onChange, items, disabled }) {
  const dispatch = useDispatch()
  const [expertiseType, setExpertiseType] = useState('all')
  const loading = useSelector(Homepage.selectLoading)
  const keywordsNotFound = useSelector(Homepage.selectKeywordsNotFound)
  const onChangeTelexpertise = (e) => {
    setExpertiseType(e.target.value)
    dispatch(Homepage.setQuery({ teleExpertiseType: e.target.value, value }))
  }
  const {
    isOpen,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    getItemProps,
    highlightedIndex,
  } = useCombobox({
    items,
    selectedItem: items.find(item => item.name === value),
    onInputValueChange: ({ inputValue }) => {
      dispatch(Homepage.setQuery({ teleExpertiseType: expertiseType, value: inputValue }))
    },
    onSelectedItemChange: item => {
      onChange(item.name);
    },
    itemToString: item => item.name
  });

  const singularType = {
    staffs: 'staff',
    opinions: 'opinion',
    cohorts: 'cohort'
  }

  return (
    <>
      <SearchBarContainer {...getComboboxProps()}>
        <Select
          label="Filtre"
          id="teleExpertiseNumber"
          items={[
            { label: 'Aucun', value: 'all' },
            { label: 'Avis', value: 'opinions' },
            { label: 'Staff', value: 'staffs' },
            { label: 'Cohorte', value: 'cohorts' },
          ]
            .map(item => ({
              label: item.label,
              value: item.value,
              selected: item.value === expertiseType,
            }))
          }
          onChange={onChangeTelexpertise}
        />
        <Ui.SearchBar.Input
          {...getInputProps()}
          disabled={disabled}
          placeholder="Ex : Dr Dupont, parkinson, neurologue, Paris"
          hiddenSearchIcon
        />
        <DropdownContainer isOpen={isOpen && value.length > 2} {...getMenuProps()}>
          {loading || items.length === 0
            ? null
            : <KeywordsNotFoundMessage
              keywords={keywordsNotFound}
              resourceType={expertiseType === 'all' ? null : singularType[expertiseType]}
            />
          }
          {!isOpen
            ? null
            : items.filter(el => expertiseType === 'all' || el.type === expertiseType).length > 0
              ? items
                .map((item, index) =>
                  <div
                    key={`${item.id}${index}`}
                    {...getItemProps({ item, index })}
                  >
                    <ListItem
                      name={item.type === 'opinions' && item.thePersonal
                        ? `Avis ${item.ownerTitle} ${item.ownerFullname}`
                        : item.name
                      }
                      highlighted={highlightedIndex === index}
                      {...item}
                    />
                  </div>
                )
              : loading
                ? <Ui.Global.Loader />
                : <Ui.BasicElement.P>Aucun résultat trouvé</Ui.BasicElement.P>
          }
        </DropdownContainer>
      </SearchBarContainer>
    </>
  );
}

const SearchTeleExpertiseComboBox = () => {
  const search = useSelector(Homepage.selectSearch)
  const teleExpertisesFound = useSelector(Homepage.selectTeleExpertises)

  return (
    <>
      <div>
        <DropdownCombobox
          value={search}
          onChange={() => { }}//onQueryChange}
          items={teleExpertisesFound}
        />
      </div>
    </>
  );
};

export const HeroContainer = () =>
(
  <Hero>
    <Ui.BasicElement.H1>
      Solution de <br />
      <Ui.Global.GreenText>téléexpertise, e-RCP</Ui.Global.GreenText>
    </Ui.BasicElement.H1>
    <Teaser>
      Skemeet permet aux médecins de partager de façon simple et sécurisée leurs
      patients pour un parcours de soins efficient.
    </Teaser>
    <Search>
      <Ui.BasicElement.P bold>Adresser un patient :</Ui.BasicElement.P>
      <SearchTeleExpertiseComboBox />
    </Search>
  </Hero>
)

const Select = styled(props => <Ui.Select.Select {...props} />)`
  ${Ui.Select.SelectContainer} {
    display: none !important;
    @media only screen and (min-width: ${props => props.theme.device.desktop}) {
      margin-top: 7rem;
      ${Ui.PortailLayout.TeleExpertiseBlock} {
        width: 25rem;
      }
    }
  }
`

const Hero = styled.div`
  @media only screen and (min-width: ${props => props.theme.device.desktop}) {
    margin-top: 7rem;
    ${Ui.PortailLayout.TeleExpertiseBlock} {
      width: 25rem;
    }
  }
  ${Ui.BasicElement.H1} {
    margin-bottom: 1.5rem;
    font-size: 3rem;
    line-height: 3rem;
  }
`

const Teaser = styled.div`
  font-size: 1.2rem;
  line-height: 1.5rem;
  width: 80%;

  @media only screen and (max-width: ${props => props.theme.device.tablet}) {
    font-size: 1rem;
  }
`

const Search = styled.div`
  margin-top: 3rem;
  margin-bottom: 2rem;
  font-size: 1.4rem;
  ${Ui.BasicElement.P} {
    font-size: 1.1rem;
  }
`

const PresentationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`

const Target = () =>
  <>
    <Headline>
      Notre objectif est d’optimiser <Ui.Global.GreenText>le parcours du patient
      </Ui.Global.GreenText> pour des <Ui.Global.GreenText>soins pertinents</Ui.Global.GreenText>
    </Headline>
    <ArgumentsContainer>
      <Argument>
        <CircleIcon>
          <IconDoctor />
        </CircleIcon>
        <Ui.BasicElement.H2>Médecins</Ui.BasicElement.H2>
        <div>Valorisez votre expertise auprès de vos correspondants</div>
      </Argument>
      <Argument>
        <CircleIcon>
          <IconPatientSup />
        </CircleIcon>
        <Ui.BasicElement.H2>Patients</Ui.BasicElement.H2>
        <div>Un parcours efficient pour des soins pertinents</div>
      </Argument>
      <Argument>
        <CircleIcon>
          <IconHospitalBuilding />
        </CircleIcon>
        <Ui.BasicElement.H2>Etablissements</Ui.BasicElement.H2>
        <div>Orientez le développement de votre activité</div>
      </Argument>
    </ArgumentsContainer>
  </>

const Headline = styled.div`
  width: 90%;
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 2rem;
  font-weight: 600;
  line-height: 2.4rem;
  font-weight: 700;
  padding: 0 1rem;

  @media only screen and (min-width: ${props => props.theme.device.desktop}) {
    width: 45rem;
  }
`

const ArgumentsContainer = styled.div`
  margin-top: 3rem;
  margin-bottom: 5rem;
  display: flex;
  justify-content: space-between;
  width: 50%;
  font-size: 1.2rem;

  @media only screen and (max-width: ${props => props.theme.device.tablet}) {
    flex-direction: column;
    text-align: left;
  }
`

const Argument = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 12rem;
  ${Ui.BasicElement.H2} {
    margin-bottom: .5rem;
  }
  > div {
    line-height: 1.4rem;
  }

  @media only screen and (max-width: ${props => props.theme.device.tablet}) {
    width: 100%;
    margin-bottom: 1.7rem;
    text-align: center;
  }
`

const CircleIcon = styled.div`
  height: 6rem;
  width: 6rem;
  background: ${props => props.theme.primaryLight};
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;

  @media only screen and (max-width: ${props => props.theme.device.tablet}) {
    margin-bottom: 1rem;
  }
`

const IconHospitalBuilding = styled(props => <Ui.Icon.IconHospitalBuilding {...props} />)`
  color: ${props => props.theme.patientGreen};
  font-size: 3rem;
`

const IconDoctor = styled(props => <Ui.Icon.IconDoctor {...props} />)`
  color: ${props => props.theme.patientGreen};
  font-size: 3rem;
`

const IconPatientSup = styled(props => <Ui.Icon.IconPatientSup {...props} />)`
  color: ${props => props.theme.patientGreen};
  font-size: 3rem;
`

const Solutions = () => (
  <Row>
    <GreyBackground>
      <Ui.BasicElement.H2>Nos solutions</Ui.BasicElement.H2>
      <SolutionsBlock>
        <SolutionCard>
          <SlideCardIcon isOpinion><Ui.Icon.IconAvis /></SlideCardIcon>
          <SlideCardTitle isOpinion>Avis</SlideCardTitle>
          <SlideCardDescription>Répondez et organisez vos demandes d’avis en temps réel</SlideCardDescription>
        </SolutionCard>
        <SolutionCard>
          <SlideCardIcon isStaff><Ui.Icon.IconMessage /></SlideCardIcon>
          <SlideCardTitle isStaff>Staffs</SlideCardTitle>
          <SlideCardDescription>Organisez des eRCP avec visioconférence et compte-rendu automatisé</SlideCardDescription>
        </SolutionCard>
        <SolutionCard>
          <SlideCardIcon isCohort><Ui.Icon.IconCohorte /></SlideCardIcon>
          <SlideCardTitle isCohort>Cohortes</SlideCardTitle>
          <SlideCardDescription>Créez des cohortes multicentriques avec eCRF personnalisé</SlideCardDescription>
        </SolutionCard>
      </SolutionsBlock>
    </GreyBackground>
  </Row>
)

const SolutionsBlock = styled.div`
  margin-top: 3rem;
  display: flex;
  justify-content: center;
  color: #FFF;
  flex-direction: row;
  align-items: center;

  @media only screen and (max-width: ${props => props.theme.device.desktop}) {
    flex-direction: column;
  };
`

const SolutionCard = styled.div`
  color: #FFF;
  height: 180px;
  width: 285px;
  margin: 0 1.5rem;
  padding: 1rem;
  box-shadow: ${props => props.theme.boxShadow.thick};
  background: ${props => props.theme.white};
  border-radius: 6px;
  border: 1px solid #BAD1DE;
  text-align: center;
  position: relative;
  margin-bottom: 1rem;

  @media only screen and (min-width: ${props => props.theme.device.desktop}) {
    margin-bottom: 0;
  }
`

const SlideCardIcon = styled.div`
  color: ${props => props.isStaff
    ? props.theme.staffPurple
    : props.isOpinion
      ? props.theme.avisBlue
      : props.theme.cohorteRed
  };
  font-size: 2.2rem;
  position: absolute;
  right: 1rem;
`

const SlideCardTitle = styled(props => <Ui.BasicElement.H3 {...props} />)`
  color: ${props => props.isStaff
    ? props.theme.staffPurple
    : props.isOpinion
      ? props.theme.avisBlue
      : props.theme.cohorteRed
  };
  margin-top: 1.5rem;
  font-size: 1.875rem;
`

const SlideCardDescription = styled.div`
  color: #000;
  font-size: 1.2rem;
  line-height: 1.6rem;
  padding: 0 .8rem;
  color: ${props => props.selected ? props.theme.white : props.theme.inherit};
`

const Features = () => {
  return (
    <Row>
      <FeatureRow>
        <FeatureDescription>
          <OnTitle>Portails</OnTitle>
          <h2>Adressez vos patients <br />en un clic</h2>
          <Ui.BasicElement.P>
            Nous créons automatiquement un site personnalisable avec une URL dédiée et un QR code pour faciliter l'adressage de vos patients.
          </Ui.BasicElement.P>
        </FeatureDescription>
        <FeaturePicture>
          <img src={FeaturePortail} alt="Adressez vos patients en un clic" />
        </FeaturePicture>
      </FeatureRow>
      <FeatureRow>
        <FeaturePicture>
          <img src={FeatureFichePatient} alt="Fiche patient" />
        </FeaturePicture>
        <FeatureDescription>
          <OnTitle>Fiche patient</OnTitle>
          <h2>Personnalisez vos formulaires</h2>
          <Ui.BasicElement.P>
            Obtenez d'emblée les informations nécessaires pour une prise en charge efficiente en personnalisant les fiches patients de vos téléexpertises.
          </Ui.BasicElement.P>
        </FeatureDescription>
      </FeatureRow>
      <FeatureRow>
        <FeatureDescription>
          <OnTitle>Viewer Dicom</OnTitle>
          <h2>Visualisez vos examens <br />en ligne</h2>
          <Ui.BasicElement.P>
            Transférez et stockez les examens d'imagerie (scanner, IRM, etc.) pour répondre de façon pertinente et sécurisée aux demandes d'avis.
          </Ui.BasicElement.P>
        </FeatureDescription>
        <FeaturePicture>
          <img src={FeatureDicom} alt="Dicom" />
        </FeaturePicture>
      </FeatureRow>
      <FeatureRow>
        <FeaturePicture>
          <img src={ScreenZoom} alt="Visioconférence" />
        </FeaturePicture>
        <FeatureDescription>
          <OnTitle>Visioconférence</OnTitle>
          <h2>Organisez des RCP à distance</h2>
          <Ui.BasicElement.P>
            Programmez des staffs en invitant vos confrères, participez en visioconférence et générez des comptes-rendus automatisés.
          </Ui.BasicElement.P>
        </FeatureDescription>
      </FeatureRow>
      <FeatureRow>
        <FeatureDescription>
          <OnTitle>Cohortes</OnTitle>
          <h2>Suivez vos patients</h2>
          <Ui.BasicElement.P>
            Créez des cohortes seul ou à plusieurs dans le cadre d'études cliniques ou de programmes d'éducation thérapeutique avec suivi personnalisé par auto-questionnaire.
          </Ui.BasicElement.P>
        </FeatureDescription>
        <FeaturePicture>
          <img src={FeatureCohorte} alt="Suivez vos patients" />
        </FeaturePicture>
      </FeatureRow>
    </Row>
  )
}

const FeatureRow = styled.div`
  display: flex;
  flex-direction: column;
  flex-direction: reverse;
  padding: 2rem;
  margin-bottom: 0;
  &:nth-child(2n) {
    flex-direction: column-reverse;
  }
  @media only screen and (min-width: ${props => props.theme.device.desktop}) {
    flex-direction: row;
    &:nth-child(2n) {
      flex-direction: row;
    }
    width: 70%;
  }
`

const FeaturePicture = styled.div`
  width: 100%;
  img {
    width: 100%;
  }
  @media only screen and (min-width: ${props => props.theme.device.desktop}) {
    width: 55%;
  }
`

const FeatureDescription = styled.div`
  font-size: 2.5rem;
  width: 100%;
  text-align: left;
  width: 100%;
  padding: 0;
  h2 {
    font-size: 2.3rem;
    line-height: 2.8rem;
  }
  ${Ui.BasicElement.P} {
    font-size: 1.2rem;
    line-height: 2rem;
  }
  @media only screen and (min-width: ${props => props.theme.device.desktop}) {
    width: 45%;
    padding: 0 2rem;
  }
`

const OnTitle = styled.div`
  font-size: 1.4rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-weight: 700;
  color: ${props => props.theme.patientGreen};
  text-transform: uppercase;
`

const Offer = () => {
  const dispatch = useDispatch()

  return (
    <GreyBackground>
      <OfferTitle>Notre offre</OfferTitle>
      <SubscriptionContainer>
        <SubscriptionPackage highlight>
          <SubscriptionPackageBody>
            <Ui.BasicElement.H2>
              Skemeet{'\u00A0'}
              <Ui.BasicElement.Span bold>FREE</Ui.BasicElement.Span>
            </Ui.BasicElement.H2>
            <Ui.BasicElement.P>
              Partagez gratuitement vos patients avec vos confrères
            </Ui.BasicElement.P>
            <SubscriptionPackageRow>
              <Ui.Icon.IconH1>
                <Ui.Icon.IconShare />
              </Ui.Icon.IconH1>
              <Ui.BasicElement.P big bold>Adressez{'\u00A0'}
                <Ui.BasicElement.Span isPrimary bold>
                  gratuitement{'\u00A0'}
                </Ui.BasicElement.Span>
                vos patients
              </Ui.BasicElement.P>
            </SubscriptionPackageRow>
            <SubscriptionPackageRow>
              <Ui.Icon.IconH1>
                <Ui.Icon.IconPatientSup />
              </Ui.Icon.IconH1>
              <Ui.BasicElement.P big bold>Recevez jusqu'à{'\u00A0'}
                <Ui.BasicElement.Span isPrimary bold>
                  10 demandes d'avis
                </Ui.BasicElement.Span>
              </Ui.BasicElement.P>
            </SubscriptionPackageRow>
            <Price free>GRATUIT</Price>
            <SubPrice>Inscription gratuite : adressez votre patient ou créez votre téléexpertise immédiatement</SubPrice>
          </SubscriptionPackageBody>
        </SubscriptionPackage>
        <SubscriptionPackage highlight>
          <SubscriptionPackageBody>
            <Ui.BasicElement.H2>
              Skemeet{'\u00A0'}
              <Ui.BasicElement.Span bold>PRO</Ui.BasicElement.Span>
            </Ui.BasicElement.H2>
            <Ui.BasicElement.P>
              Un site internet personnalisé regroupant
              les téléexpertises de votre organisation.
            </Ui.BasicElement.P>
            <SubscriptionPackageRow>
              <Ui.Icon.IconH1>
                <Ui.Icon.IconPatientSup />
              </Ui.Icon.IconH1>
              <Ui.BasicElement.P big bold>Patients et demandes d'avis{'\u00A0'}
                <Ui.BasicElement.Span isPrimary bold>
                  illimités
                </Ui.BasicElement.Span>
              </Ui.BasicElement.P>
            </SubscriptionPackageRow>
            <SubscriptionPackageRow>
              <Ui.Icon.IconH1>
                <Ui.Icon.IconShare />
              </Ui.Icon.IconH1>
              <Ui.BasicElement.P big bold>Créez votre{'\u00A0'}
                <Ui.BasicElement.Span isPrimary bold>
                  réseau de soins
                </Ui.BasicElement.Span>
              </Ui.BasicElement.P>
            </SubscriptionPackageRow>
            <SubscriptionPackageRow>
              <Ui.Icon.IconH1>
                <Ui.Icon.IconPlanet />
              </Ui.Icon.IconH1>
              <Ui.BasicElement.P big bold
              ><Ui.BasicElement.Span isPrimary bold>Portail{'\u00A0'}</Ui.BasicElement.Span>
                <Ui.BasicElement.Span bold>
                  dédié et personnalisable
                </Ui.BasicElement.Span>
              </Ui.BasicElement.P>
            </SubscriptionPackageRow>
            {/* <Price>A partir de 100€<Sup>TTC</Sup>/mois</Price>
            <SubPriceGreen>par téléexpertise</SubPriceGreen> */}
          </SubscriptionPackageBody>
          <SubscriptionPackageFooter>
            <Link to="/contactez-nous">
              <Ui.Button.BasicButton
                isGreen
                isFilled
                onClick={() => dispatch(ContactUs.updateField({ name: 'subject', value: 'Demande de devis depuis Skemeet' }))}
              >
                Demander un devis
              </Ui.Button.BasicButton>
            </Link>
          </SubscriptionPackageFooter>
        </SubscriptionPackage>
      </SubscriptionContainer>
    </GreyBackground>
  )
}

const Sup = styled.sup`
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
  font-size: 1rem;
  top: -.5rem;
  margin-left: .2rem;
  margin-right: .2rem;
`

const GreyBackground = styled.div`
  width: 100%;
  padding-bottom: 4.5rem;
  background: ${props => props.theme.lightGrey};
  ${Ui.BasicElement.H2} {
    display: flex;
    padding-top: 3.5rem;
    justify-content: center;
    color: ${props => props.theme.darkBlue};
    font-size: 2.3rem;
    margin-top: 0;
  }
`

const OfferTitle = styled(props => <Ui.BasicElement.H2 {...props} />)`
  color: ${props => props.theme.darkBlue};
  margin-top: 0;
  font-size: 2.3rem;
 // margin-top: 2rem !important;
`

const Price = styled.div`
  color: ${props => props.theme.patientGreen};
  font-size: 2.2rem;
  text-align: center;
  font-weight: 700;
  margin-top: ${props => props.free ? '7.2rem' : '2rem'};
  margin-bottom: .5rem;
  line-height: 2.5rem;
  @media only screen and (max-width: ${props => props.theme.device.desktop}) {
    font-size: 1.7rem;
  }
`

const SubPrice = styled(props => <Price {...props} />)`
  color: ${props => props.theme.darkBlue};
  font-size: 1.125rem;
  margin-top: .5rem;
  line-height: 1.4rem;
  margin-bottom: 1.5rem;
`

const SubPriceGreen = styled(props => <SubPrice {...props} />)`
  color: ${props => props.theme.patientGreen};
  font-size: 1.5rem;
`

const SubscriptionPackageBody = styled.div`
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  ${Ui.BasicElement.P} {
    font-size: 1rem;
  }
  ${Ui.BasicElement.H2} {
    align-self: center;
    font-weight: normal;
    font-size: 2rem;
  }
  flex: 1;
`

const SubscriptionPackageFooter = styled.div`
  display: flex;
  justify-content: center;
  button {
    width: 100%;
  }
  a {
    width: 100%;
    text-decoration: none;
  }
`

const SubscriptionPackage = styled.div`
  box-sizing: border-box;
  flex: 24rem;
  flex-shrink: 1;
  flex-grow: 0;
  background: ${props => props.highlight ? 'white' : 'none'};;
  box-shadow: ${props => props.highlight ? props.theme.boxShadow.thick : 'none'};
  text-align: ${props => props.highlight ? 'left' : 'center'};
  border-radius: 5px;
  border: 2px solid ${props => props.offerSelected ? props.theme.patientGreen : 'none'};
  padding: 2.5rem;
  margin: 1rem;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: ${props => props.theme.device.desktop}) {
    padding: 1.8rem;
  }
  ${Ui.Icon.IconH1} {
    background-color: ${props => props.highlight ? props.theme.primaryLight : 'white'};
    color: ${props => props.highlight ? props.theme.primary : props.theme.patientGreen};
    margin-right: 1.5rem;
    @media only screen and (max-width: ${props => props.theme.device.desktop}) {
      margin-right: 1rem;
    }
  }
`

const SubscriptionContainer = styled.div`
  display: flex;
  justify-content: center;
  @media only screen and (max-width: ${props => props.theme.device.desktop}) {
    flex-direction: column;
    ${SubscriptionPackage} {
      ${SubscriptionPackageFooter} {
        button {
          font-size: .800rem;
        }
      }
    }
  }
`

const SubscriptionPackageRow = styled.div`
  display: flex;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: 1rem;
  ${Ui.BasicElement.P} {
    margin: 0;
    font-weight: 700
  }
  &:not(:last-child) {
    border-bottom: 1px solid ${props => props.theme.grey2};
  }
`

const PartnerContainer = () => {
  const [activeSlide, setActiveSlide] = useState(0);

  return (
    <Row>
      <TheyTrustUs>
        <div>Ils nous font <Ui.Global.GreenText>confiance</Ui.Global.GreenText></div>
      </TheyTrustUs>
      <PartnerBlock>
        <Carousel
          activeSlideIndex={activeSlide}
          onRequestChange={setActiveSlide}
          itemsToShow={0}
          itemsToScroll={3}
          containerProps={{
            style: {
              width: "720px",
              justifyContent: "space-between",
            }
          }}
          backwardBtnProps={{
            children: <ArrowLeft><img src={ArrowCarousel} alt="Précédent" /></ArrowLeft>,
            style: {
              alignSelf: "center",
              background: "none",
              border: "none",
              cursor: "pointer",
            }
          }}
          forwardBtnProps={{
            children: <ArrowRight><img src={ArrowCarousel} alt="Suivant" /></ArrowRight>,
            style: {
              alignSelf: "center",
              background: "none",
              border: "none",
              cursor: "pointer",
            }
          }}
          speed={400}
        >
          <Partner src={Neurosphinx} />
          <Partner src={Oscar} />
          <Partner src={Tetecou} />
          <Partner src={Sensgene} />
          <Partner src={Anddirares} />
          <Partner src={Fai2r} />
          <Partner src={Fimarad} />
        </Carousel>
      </PartnerBlock>
      <PartnerBlockMobile>
        <Partner src={Neurosphinx} />
        <Partner src={Oscar} />
        <Partner src={Tetecou} />
        <Partner src={Sensgene} />
        <Partner src={Anddirares} />
        <Partner src={Fai2r} />
        <Partner src={Fimarad} />
      </PartnerBlockMobile>
    </Row>
  )
}


const ArrowRight = styled.div`
  margin-left: 1rem;
  transform: rotate(180deg);
  margin-left: 3rem;
`

const ArrowLeft = styled.div`
  margin-right: 1rem;
  margin-right: 3rem;
`

const TheyTrustUs = styled.div`
  margin-top: 4rem;
  margin-bottom: 2.5rem;
  font-size: 2.5rem;
  text-align: center;
  font-weight: 700;
`

const PartnerBlock = styled.div`
  text-align: center;
  margin-bottom: 2rem;
  @media only screen and (max-width: ${props => props.theme.device.desktop}) {
    display: none;
  }
`

const PartnerBlockMobile = styled.div`
  text-align: center;
  @media only screen and (min-width: ${props => props.theme.device.desktop}) {
    display: none;
  }
`

const Partner = styled.img`
  width: 200px;
  height: auto;
`

const Row = styled.div`
  margin-bottom: 2rem;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`

export default HomePageComponent;
