import styled from 'styled-components/macro'
import * as Ui from '../Component/Ui'

export const FullSpaceContainer = styled(props => <Ui.Layout.FlexBox {...props} />)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
`

export const ButtonLargeLink = styled.div`
  display: flex;
  @media only screen and (max-width: ${props => props.theme.device.desktop}) {
    width: 100%;
  }
`

export const Logo = styled(props => <Ui.BasicElement.Figure {...props} />)`
  border-radius: 50px;
  width: 2.5rem;
  height: 2.5rem;
`

export const OrganizationName = styled.div`
  padding-left: 1rem;
`

export const DetailsContainer = styled.div`
  ${Ui.Layout.Panel} {
      min-height: 5rem;
  }
  ${Ui.BasicElement.H1Container} {
    span {
      background-color: none;
    }
  }
  ${Ui.BasicElement.H1} {
    margin-top: 1.5rem;
    @media only screen and (max-width: ${props => props.theme.device.desktop}) {
      font-size: 1.125rem !important;
      margin-top: 1rem;
    }
  }
  ${Ui.Layout.Content} {
    @media only screen and (min-width: ${props => props.theme.device.desktop}) {
      & > :not(:first-child) {
        margin-top: 5rem;
      }
    }
    @media only screen and (max-width: ${props => props.theme.device.desktop}) {
        padding:0;
      }
  }
  ${Ui.Form.Input} {
    &:read-only {
      border-color: transparent;
      opacity: 1;
    }
  }
`
