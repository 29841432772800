import { createSlice } from '@reduxjs/toolkit';

export const RESOURCE_MEMBERS = 'managers'

export const RESOURCE_PATIENT_MANAGERS = 'patientManagers'

export const RESOURCE_OPINIONS = 'opinions'

export const RESOURCE_DISCIPLINES = 'disciplines'

export const RESOURCE_MESSAGES = 'messages'

export const RESOURCE_NOTIFICATIONS = 'notifications'

export const RESOURCE_PATIENTS = 'patients'

export const RESOURCE_USER_PATIENTS = 'userPatients'

export const RESOURCE_ORGANIZATIONS = 'organizations'

export const RESOURCE_REGISTERED_RPPS = 'rpps'

export const INITIAL_STATE = {
  pending: false,
  loading: {
    [RESOURCE_MEMBERS]: false,
    [RESOURCE_OPINIONS]: false,
    [RESOURCE_DISCIPLINES]: false,
    [RESOURCE_MESSAGES]: false,
    [RESOURCE_NOTIFICATIONS]: false,
    [RESOURCE_PATIENTS]: false,
    [RESOURCE_USER_PATIENTS]: false,
    [RESOURCE_ORGANIZATIONS]: false,
    [RESOURCE_REGISTERED_RPPS]: false,
    [RESOURCE_PATIENT_MANAGERS]: false,
  },
  success: false,
  error: false,
  errorMessage: null,
  externalResources: {
    [RESOURCE_MEMBERS]: [],
    [RESOURCE_OPINIONS]: [],
    [RESOURCE_DISCIPLINES]: [],
    [RESOURCE_MESSAGES]: [],
    [RESOURCE_NOTIFICATIONS]: [],
    [RESOURCE_PATIENTS]: [],
    [RESOURCE_USER_PATIENTS]: [],
    [RESOURCE_ORGANIZATIONS]: [],
    [RESOURCE_REGISTERED_RPPS]: [],
    [RESOURCE_PATIENT_MANAGERS]: [],
  },
}

const slice = createSlice({
  name: 'resources',
  initialState: INITIAL_STATE,
  reducers: {

    load: (state, { payload: { filter } }) => ({
      ...state,
      loading: Object.entries(state.loading).reduce(
        (acc, [name, value]) => ({
          ...acc,
          [name]: filter.includes(name)
        }), state.loading)
    }),

    remove: (state, { payload: { name, id } }) => ({
      ...state,
      externalResources: {
        ...state.externalResources,
        [name]: state.externalResources[name].filter(resource => Number(resource.id) !== Number(id))
      }
    }),

    received: (state, { payload: { name, data } }) => {
      return ({
        ...state,
        externalResources: {
          ...state.externalResources,
          [name]: [
            ...state.externalResources[name],
            ...data
              .filter(incoming => state.externalResources[name]
                .every(existing => name === 'disciplines'
                  ? existing !== incoming
                  : incoming.id !== existing.id
                ))
          ]
        },
        loading: {
          ...state.loading,
          [name]: false,
        }
      })
    },

    clean: state => ({
      ...state,
      externalResources: INITIAL_STATE.externalResources,
    }),

    apiError: (state, action) => ({
      ...state,
      errorMessage: action.payload,
      pending: false,
      error: true,
      success: false,
    }),
  },
})

export const {
  load,
  clean,
  received,
  remove,
  apiError,
} = slice.actions

export const selectFilledSections = state => state.resources.filledSections
export const selectPending = state => state.resources.pending
export const selectLoading = state => state.resources.loading
export const selectErrorMessage = state => state.resources.errorMessage
export const selectResources = state => state.resources.externalResources

export default slice.reducer
