import React from "react";
import styled from "styled-components";

const ToggleComponent = ({
  id,
  onChange,
  checked,
  isOnOn,
  disabled,
}) => {
  function handleKeyPress(e) {
    if (e.keyCode !== 32) return;

    e.preventDefault();
    onChange(!checked);
  }

  return <CheckBoxWrapper>
    <CheckBox
      id={id}
      type="checkbox"
      onChange={(e) => onChange(e.target.checked)}
      checked={checked}
      disabled={disabled}
    />
    <CheckBoxLabel
      htmlFor={id}
      onKeyDown={(e) => handleKeyPress(e)}
      isOnOn={isOnOn}
      disabled={disabled}
    />
  </CheckBoxWrapper>
}

export const CheckBoxWrapper = styled.div`
  position: relative;
`;

const CheckBoxLabel = styled.label`
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 42px;
  height: 25px;
  border-radius: 15px;
  background: ${props => props.isOnOn
    ? props.theme.patientGreen
    : '#bebebe'
  };
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    margin: 4px;
    background: #ffffff;
    transition: 0.2s;
  }
`;

const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 42px;
  height: 25px;
  margin: 0 3px;
  &:checked + ${CheckBoxLabel} {
    background: ${props => props.theme.patientGreen};
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 18px;
      height: 18px;
      margin-left: 21px;
      transition: 0.2s;
    }
  }
`;

export default ToggleComponent;
