import styled from 'styled-components/macro'
import * as Ui from '../Component/Ui'

export const TeleExpertiseContainer = styled.div`
  h1 {
    color: ${props => props.theme.primary}
  }
  ${Ui.BasicElement.H2}:not(:first-of-type) {
    margin-top: 1rem;
  }
  ${Ui.Layout.Content} {
    @media only screen and (min-width: ${props => props.theme.device.desktop}) {
      & > :not(:first-child) {
        margin-top: 5rem;
      }
    }
    @media only screen and (max-width: ${props => props.theme.device.desktop}) {
      padding: 0;
    }
    ${Ui.Layout.ButtonGroup} {
      margin-top: .7rem;
    }
    ${Ui.Form.Input} {
      &:read-only {
        border-color: transparent;
        opacity: 1;
      }
    }
  }
  ${Ui.Form.Label} {
    ${Ui.SearchBar.SearchBarContainer} {
      margin-top: .6rem;
    }
  }
  ${Ui.BasicElement.H1Container} {
    margin-bottom: 1rem;
  }
  ${Ui.Layout.Row} {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    ${Ui.Form.InputContainer}:last-child {
      justify-content: flex-end;
    }
  }
    img[alt='Avatar'] {
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 50px;
      margin-left: .5rem;
  }
  ${Ui.BasicElement.CollapsibleLink} {
    margin-top: 2rem;
    margin-bottom: 0;
  }
  ${Ui.Button.Back} {
    flex: none;
  }
`

export const IconH1 = styled(props => <Ui.Icon.IconRound {...props} />).attrs({ as: 'span' })`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.primary};
  border: 0;
  font-size: 1.3rem;
  margin-right: 1rem;
  flex-shrink: 0;
  background: ${props => props.theme.lightPurple};
  border-radius: 50px;
  width: 3rem;
  height: 3rem;
`
