import React from 'react'
import styled from 'styled-components'
import * as IconUi from './Icon';

export const StepContainer = styled.span`
  display: flex;
  align-items: center;
`

export const StepCircle = styled.span`
  background-color: ${props => !props.current
    ? 'transparent'
    : props.theme.primary
  };
  border-width: 1px;
  border-style: ${props => props.optional ? 'dashed' : 'solid'};
  border-color: ${props => !props.current
    ? props.theme.grey2
    : props.theme.primary
  };
  border-radius: 50px;
  width: 1.25rem;
  height: 1.25rem;
  flex-shrink: 0;
  text-align: center;
  line-height: 2rem;
  margin-right: 1rem;
  align-items: center;
  justify-content: center;
  display: flex;

  &::before {
    font-family: 'ShareConfrere';
    color: ${props => props.current
    ? 'white'
    : props.theme.primary
  };
    font-size: .5rem;
    content: '${props => props.checked && '\\e923'}';
    line-height: 1.25rem;
  }
`

export const StepLabel = styled.span`
  font-size: .825rem;
  font-weight: ${props => props.current && '700'};

  a {
    color: ${props => !props.current
    ? props.theme.grey
    : props.theme.primary
  };
    text-decoration: none;
  }
`

export const Stepper = styled.div`
  margin-top: 2rem;

  @media only screen and (max-width: ${props => props.theme.device.desktop}) {
    display: none;
  }
`

export const StepperHorizontal = styled(props => <Stepper {...props} />)`
  display: flex;
  flex-direction: column;
  flex: 1 !important;
  margin-top: 0;
  align-items: flex-start !important;
  border: none !important;
  i {
    display: none;
  }
  span {
    margin: .25rem !important;
  }

  @media only screen and (max-width: ${props => props.theme.device.desktop}) {
    display: none;
  }
  @media only screen and (min-width: ${props => props.theme.device.desktop}) {
    flex-direction: row;
    align-items: center !important;
    span {
      margin: 0;
    }
    i {
      display: inline-block;
    }
  }
`

export const SideNote = styled.span`
  color: ${props => props.theme.grey2};
  font-style: italic;
  font-size: .75rem;
  margin-left: 1rem;
`

export const Divider = styled.div`
  &:not(:last-child) {
    width: 1px;
    height: 2rem;
    border-left: solid 1px;
    border-color: ${props => props.theme.grey2};
    margin-left: .625rem;
  }
`

export const Separator = styled(props => <Divider {...props} />)`
  border: 0;
`

export const ArrowSeparator = styled(props => <IconUi.IconBack {...props} />)`
  color: ${props => props.theme.grey2};
  transform: rotate(180deg);
  border: 0;
  padding: 0 1.3rem;
`

export const Step = ({
  isRed = false,
  isPurple = false,
  isGreen = false,
  current,
  optional,
  label,
  name,
  checked,
  children,
  ...props
}) => {

  return (
    <>
      <StepContainer
        current={current}
        checked={checked}
        {...props}
      >
        <StepCircle
          optional={optional}
          current={current}
          checked={checked}
          isPurple={isPurple}
          isGreen={isGreen}
          isRed={isRed}
        />
        <StepLabel
          current={current}
          isPurple={isPurple}
          isGreen={isGreen}
          isRed={isRed}
        >
          <a href={`#${name}`}>
            {label}
          </a>
        </StepLabel>
        {children}
      </StepContainer>
    </>
  )
}
