import React, { useEffect, useState } from 'react';
import { privateSitePrefix } from '../Util/Config'
import { useAlert } from 'react-alert'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import * as Menu from '../State/Menu'
import * as Ui from './Ui'
import * as StaffList from '../State/StaffList'
import pluralize from '../Util/Pluralize'
import * as Styled from './StaffList.styled'
import { fr } from '../Util/i18n';
import { KeywordsNotFoundMessage } from '../Util/Message'
import LoadingBox from './Ui/LoadingBox'
import TeleExpertiseIcon from './Ui/TeleExpertiseIcon'

const StaffListComponent = () => {
  const teleExpertiseName = 'staff'
  const dispatch = useDispatch()
  const alert = useAlert()
  const [filterOpen, setFilterOpen] = useState(false)
  const currentUser = useSelector(state => state.login.currentUser)
  const expertises = useSelector(StaffList.selectStaffs)
  const filters = useSelector(StaffList.selectFilters)
  const staffCount = useSelector(StaffList.selectCount)
  const errorMessage = useSelector(state => state.staffList.errorMessage)
  const hasError = useSelector(state => state.staffList.error)
  const hasMoreResults = useSelector(StaffList.selectHasMore)
  const loading = useSelector(StaffList.selectLoading)
  const fetching = useSelector(StaffList.selectFetching)
  const keywordsNotFound = useSelector(StaffList.selectKeywordsNotFound)
  const nextPage = Math.floor(expertises.length / process.env.REACT_APP_DEFAULT_RESULT_LIMIT) + 1

  useEffect(() => {
    dispatch(StaffList.fetch())
    dispatch(Menu.changeActive(Menu.STAFFS))
    dispatch(StaffList.count())

    return () => dispatch(StaffList.clean())
  }, [dispatch])

  useEffect(() => {
    if (errorMessage)
      alert.error(errorMessage, { timeout: 5000 })
  }, [alert, errorMessage])

  const RequestStatusCheckbox = ({ statusType }) => {
    const statusIndex = statusType === StaffList.STATUS_DONE ? 1 : 0

    return (
      <Ui.Form.Checkbox
        checked={filters.status[statusIndex].checked}
        onChange={e => e.target.checked
          ? dispatch(StaffList.addStatus(statusType))
          : dispatch(StaffList.removeStatus(statusType))
        }
      />
    )
  }

  return (
    <>
      <LoadingBox />
      <Ui.Layout.PageLayout currentUser={currentUser}>
        <Styled.StaffListContainer>
          {!loading || hasError
            ? <Ui.Layout.PageContentWrapper>
              <Ui.Layout.PanelContainer>
                <Ui.Layout.Panel>
                  <Ui.Layout.TelexpertiseThumbnailContainer>
                    <Ui.Layout.TelexpertiseLogo>
                      <TeleExpertiseIcon teleExpertiseType={teleExpertiseName} />
                    </Ui.Layout.TelexpertiseLogo>
                    <Ui.BasicElement.H3 noMargin>
                      {fr[teleExpertiseName].expertises.charAt(0).toUpperCase()}
                      {fr[teleExpertiseName].expertises.slice(1)}
                    </Ui.BasicElement.H3>
                  </Ui.Layout.TelexpertiseThumbnailContainer>
                  <Ui.BasicElement.P light>
                    Créez ou rejoignez un staff pour discuter de vos patients avec vos confrères.
                    Organisez vos réunions, en présentiel ou par visioconférence.
                    Générez des comptes rendus automatisés pour consigner les décisions du staff.
                  </Ui.BasicElement.P>
                  <Ui.Layout.PanelFilterContainer noPadding>
                    <Ui.Layout.PanelFilterMobileLiner>
                      {!filterOpen
                        ? (
                          <Ui.Button.SearchButton
                            onClick={e => setFilterOpen(!filterOpen)}
                          >
                            <span>Rechercher</span>
                            <Ui.Icon.IconSearch />
                          </Ui.Button.SearchButton>
                        )
                        : (
                          <Ui.Button.CloseButton
                            onClick={e => setFilterOpen(!filterOpen)}
                          >
                            <span>Fermer</span>
                            <Ui.Icon.IconClose />
                          </Ui.Button.CloseButton>
                        )
                      }
                    </Ui.Layout.PanelFilterMobileLiner>
                    <Ui.Layout.PanelFilterBox open={filterOpen}>
                      <Ui.Layout.PanelFilterRow>
                        <Ui.Icon.IconWait />
                        <Ui.Layout.PanelFilterCounter>
                          {staffCount.waiting}
                        </Ui.Layout.PanelFilterCounter>
                        <Ui.Layout.PanelFilterLabel>
                          {pluralize('Demande', staffCount.waiting)} <br />
                          <strong>En attente</strong>
                        </Ui.Layout.PanelFilterLabel>
                        <RequestStatusCheckbox statusType={StaffList.STATUS_WAITING} />
                      </Ui.Layout.PanelFilterRow>
                      <Ui.Layout.PanelFilterRow>
                        <Ui.Icon.IconDemandeTraitee />
                        <Ui.Layout.PanelFilterCounter>
                          {staffCount.done}
                        </Ui.Layout.PanelFilterCounter>
                        <Ui.Layout.PanelFilterLabel>
                          {pluralize('Demande', staffCount.done)} <br />
                          <strong>{pluralize('Répondue', staffCount.done)}</strong>
                        </Ui.Layout.PanelFilterLabel>
                        <RequestStatusCheckbox statusType={StaffList.STATUS_DONE} />
                      </Ui.Layout.PanelFilterRow>
                      <Ui.Layout.PanelFilterRowMobile>
                        <Ui.Select.Select
                          label="Afficher"
                          items={[
                            {
                              label: `Mes ${teleExpertiseName}`,
                              value: StaffList.DISPLAY_MINE,
                              selected: StaffList.DISPLAY_MINE === filters.displayBy,
                            },
                            {
                              label: fr[teleExpertiseName].allExpertises,
                              value: StaffList.DISPLAY_ALL,
                              selected: StaffList.DISPLAY_ALL === filters.displayBy,
                            },
                            {
                              label: fr[teleExpertiseName].archivedExpertises,
                              value: StaffList.DISPLAY_ARCHIVED,
                              selected: StaffList.DISPLAY_ARCHIVED === filters.displayBy,
                            },
                          ]}
                          value={filters.displayBy}
                          onChange={e => dispatch(StaffList.changeDisplayBy(e.target.value))}
                        />
                      </Ui.Layout.PanelFilterRowMobile>
                      <Ui.Layout.PanelFilterRowMobile>
                        <Ui.Select.Select
                          label="Trier par"
                          items={[
                            { label: 'Mise à jour', value: StaffList.ORDER_BY_LATEST, selected: false },
                            { label: 'Nom', value: StaffList.ORDER_BY_TITLE, selected: false },
                          ]}
                          value={filters.orderBy}
                          onChange={e => dispatch(StaffList.changeOrderBy(e.target.value))}
                        />
                      </Ui.Layout.PanelFilterRowMobile>
                      <Ui.Layout.PanelFilterRowMobile>
                        <Ui.SearchBar.SearchBar
                          placeholder={`Rechercher un intitulé ${fr[teleExpertiseName].ofExpertises} ou une discipline`}
                          value={filters.search}
                          onChange={e => dispatch(StaffList.changeSearch(e.target.value))}
                          disabled={hasError}
                        />
                        <KeywordsNotFoundMessage
                          paddingTop
                          keywords={keywordsNotFound}
                          resourceType="staff" />
                      </Ui.Layout.PanelFilterRowMobile>
                      <Ui.Layout.PanelFilterRowMobile>
                        <Ui.Icon.IconUp onClick={e => setFilterOpen(!filterOpen)} />
                      </Ui.Layout.PanelFilterRowMobile>
                    </Ui.Layout.PanelFilterBox>
                  </Ui.Layout.PanelFilterContainer>
                </Ui.Layout.Panel>
              </Ui.Layout.PanelContainer>
              <Ui.Layout.Content hasPanel>
                <Ui.Search.FilterContainer>
                  <Ui.Search.FiltersAndSorting>
                    <Ui.Search.FilterSection>
                      <Ui.Select.Select
                        label="Afficher"
                        items={[
                          {
                            label: `Mes ${fr[teleExpertiseName].expertises}`,
                            value: StaffList.DISPLAY_MINE,
                            selected: StaffList.DISPLAY_MINE === filters.displayBy,

                          },
                          {
                            label: fr[teleExpertiseName].allExpertises,
                            value: StaffList.DISPLAY_ALL,
                            selected: StaffList.DISPLAY_ALL === filters.displayBy,
                          },
                          {
                            label: fr[teleExpertiseName].archivedExpertises,
                            value: StaffList.DISPLAY_ARCHIVED,
                            selected: StaffList.DISPLAY_ARCHIVED === filters.displayBy,
                          },
                        ]}
                        onChange={e => dispatch(StaffList.changeDisplayBy(e.target.value))} />
                      <Ui.Select.Select
                        label="Trier par"
                        items={[
                          { label: 'Mise à jour', value: StaffList.ORDER_BY_LATEST, selected: false },
                          { label: 'Nom', value: StaffList.ORDER_BY_TITLE, selected: false },
                        ]}
                        onChange={e => dispatch(StaffList.changeOrderBy(e.target.value))}
                      />
                    </Ui.Search.FilterSection>
                    <div>
                      <Ui.Button.BasicButton
                        isPrimary
                        isFilled
                        isLarge
                        as={Link}
                        to={`${privateSitePrefix}/nouveau/${fr[teleExpertiseName].expertise}`}
                      >
                        {fr[teleExpertiseName].newExpertise}
                      </Ui.Button.BasicButton>
                    </div>
                  </Ui.Search.FiltersAndSorting>
                  <Ui.Search.FiltersAndSorting>
                    <Ui.SearchBar.SearchBar
                      placeholder={`Rechercher un intitulé ${fr[teleExpertiseName].ofExpertises} ou une discipline`}
                      onChange={e => dispatch(StaffList.changeSearch(e.target.value))}
                    />
                  </Ui.Search.FiltersAndSorting>
                  <KeywordsNotFoundMessage
                    paddingTop
                    keywords={keywordsNotFound}
                    resourceType="staff" />
                </Ui.Search.FilterContainer>
                <Ui.Card.Container>
                  <Ui.Search.MobileActionButtonGroup>
                    <Ui.Button.BasicButton
                      isFilled
                      isPrimary
                      isLarge
                      as={Link}
                      to={`${privateSitePrefix}/nouveau/${teleExpertiseName}`}
                    >
                      Nouveau Staff
                    </Ui.Button.BasicButton>
                  </Ui.Search.MobileActionButtonGroup>
                  {(expertises.length > 0
                    ?
                    <Ui.Search.CardContainer>
                      {expertises.map((expertise, i) =>
                        <Ui.Card.TelexpertiseCard
                          key={`${teleExpertiseName}_card_${i}`}
                          to={`${privateSitePrefix}/${fr[teleExpertiseName].expertises}/${expertise.id}`}
                          teleExpertiseType={teleExpertiseName}
                          isArchived={expertise.archive}
                          requests={expertise.requests}
                          currentUserAdmin={expertise.currentUserAdmin}
                          patientLimitLocked={expertise.patientLimitLocked}
                          numberAddPatientAction={expertise.numberAddPatientAction}
                          permissions={{ _canBe: expertise._canBe, _canBeModified: expertise._canBeModified }}
                          teleExpertise={expertise}
                          {...expertise}
                        />
                      )}
                    </Ui.Search.CardContainer>
                    : fetching
                      ? null
                      : <Ui.Search.NoResult>
                        Vous n'êtes pas encore membre d'un staff, <Ui.BasicElement.Link $isPrimary to={`${privateSitePrefix}/nouveau/staff`}>créer un nouveau</Ui.BasicElement.Link> ou <Ui.BasicElement.Span isPrimary clickable bold onClick={() => dispatch(StaffList.changeDisplayBy(StaffList.DISPLAY_ALL))}>afficher tous les staffs</Ui.BasicElement.Span>
                      </Ui.Search.NoResult>
                  )
                  }
                </Ui.Card.Container>
                {hasMoreResults && expertises.length > 0 && (
                  <Ui.Search.LoadMoreButtonContainer>
                    <Ui.Button.BasicButton
                      isFilled
                      isPrimary
                      isLarge
                      onClick={() => dispatch(StaffList.fetch({ page: nextPage }))}
                      disabled={fetching}
                    >
                      {fetching &&
                        <Ui.Global.Loader />
                      }
                      Voir plus de résultats
                    </Ui.Button.BasicButton>
                  </Ui.Search.LoadMoreButtonContainer>
                )}
              </Ui.Layout.Content>
            </Ui.Layout.PageContentWrapper>
            : <Ui.Layout.FlexContainer><Ui.Global.Loader /></Ui.Layout.FlexContainer>
          }
        </Styled.StaffListContainer>
      </Ui.Layout.PageLayout>
    </>
  )
}

export default StaffListComponent;
