import * as Api from '../Util/Api'
import { call, select, put, takeEvery } from 'redux-saga/effects'
import * as DashboardAdmin from '../State/DashboardAdmin'

export function* fetch({ payload: { tableName } }) {
    try {
        const dashboardAdminState = yield select(state => state.dashboardAdmin)
        const {
            numberRegisteredUser: registeredUserCount,
            numberAccountValidated: accountValidated,
            numberConnectedUser: connectedUserCount,
            numberProPlusSubscription: proPlusSubscription,
            numberCreatedPatient: createdPatientCount,
            numberCreatedOpinion: createdOpinionCount,
            numberCreatedStaff: createdStaffCount,
            numberCreatedCohort: createdCohortCount,
            numberStaffSession: staffSessionCount,
            numberTeleExpertiseComment: teleExpertiseCommentCount,
        } = yield call(
            Api.fetchDashboardAdminKPI,
            dashboardAdminState[tableName].startDate,
            dashboardAdminState[tableName].endDate,
        )

        yield put(DashboardAdmin.received({
            tableName,
            data: {
              registeredUserCount,
              accountValidated,
              connectedUserCount,
              proPlusSubscription,
              createdPatientCount,
              createdOpinionCount,
              createdStaffCount,
              createdCohortCount,
              staffSessionCount,
              teleExpertiseCommentCount,
            }
        }))
    } catch (error) {
        yield put(DashboardAdmin.apiError(error.message))
    }
}

const DashboardAdminSagas = function* () {
  yield takeEvery(`${DashboardAdmin.fetch}`, fetch)
}

export default DashboardAdminSagas;
