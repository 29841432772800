import * as CustomField from '../State/CustomField'
import * as Patient from '../State/Patient'
import * as Form from '../Util/Form'
import * as Api from '../Util/Api'
import { takeLatest, takeEvery, call, put, select } from 'redux-saga/effects'
import { isUniqId } from '../Util/Format'
import { _ } from '../Util/Object'

const addTypeAndLabels = ([name, value]) =>
  value.map(teleExpertise => ({
    ...teleExpertise,
    labels: teleExpertise.labels || [],
    type: name
  }))

const setValueFromType = (values, field) =>
  values[field.id]
    ? field.typeOfField === 'text'
      ? values[field.id].content
      : field.typeOfField === 'selectbox' ||
        field.typeOfField === 'multicheckbox'
        ? Object.values(values[field.id].contentChoices)
          .reduce((acc, { id }) =>
            ([...acc, id]), [])
        : []
    : ''

const isEmpty = obj => obj && Object.keys(obj).length === 0

export function* customFieldsFromExpertise(patientId, fieldValues = {}, labelTags, expertises, isUseMode, mode, typeOfField) {
  try {
    const typedLabeledExpertises = Object
      .entries(expertises)
      .filter(([_, value]) => value.length > 0)
      .map(addTypeAndLabels)
      .reduce((acc, field) => [...acc, ...field])

    const formFields = typedLabeledExpertises
      .filter(({ customFields, labels }) => !isEmpty(customFields) || labels.length > 0)
      .map(({ name, id, type, labels, customFields }) => ({
        name,
        id,
        type,
        labels: {
          byId: _.idKeys(labels),
          value: Object.keys(labelTags)
            .map(label => Number(label))
            .filter(label => labels.map(label => label.id).map(Number).includes(label)),
          allIds: labels
            .map(label => label.id)
        },
        sections: {
          byId: Object
            .values(customFields)
            .reduce((acc, { id, label, sortNumber, isPatientSection, children }) =>
            ({
              ...acc,
              [id]: {
                id,
                label,
                sortNumber,
                isPatientSection,
                fields: Object
                  .values(children)
                  .sort((a, b) => a.sortNumber > b.sortNumber)
                  .map(e => e.id),
              }
            }), {}),
          allIds: Object
            .values(customFields)
            .sort((a, b) => a.sortNumber > b.sortNumber)
            .map(el => el.id)
        },
        fields: {
          byId: Object
            .values(customFields)
            .reduce((acc, field) => ({
              ...acc,
              ...Object.values(field.children)
                .reduce((acc, { choices, ...field }) => ({
                  ...acc,
                  [field.id]: {
                    ...field,
                    choices: Object
                      .values(choices)
                      .reduce((acc, { id }) =>
                        [...acc, id], []),
                    validationRules: field.mandatory
                      ? ['notEmpty']
                      : [],
                    value: setValueFromType(fieldValues, field),
                    initialValue: setValueFromType(fieldValues, field),
                    isFieldEdit: false,
                  }
                }), {})
            }), {}),
          // TODO : add allIds to allow field reordering between sections
          allIds: []
        },
        choices: {
          byId: Object
            .values(customFields)
            .reduce((acc, field) => ({
              ...acc,
              ...Object.values(field.children)
                .reduce((acc, { choices }) => ({
                  ...acc,
                  ..._.idKeys(choices),
                }), {})
            }), {})
        }
      }))

    // Initial edit fields flag set to false
    if (mode !== 'update') {
      // Don't initBuilder in viewer mode
      if (isUseMode) {
        yield initBuilderFieldEdit(formFields)
      }

      yield initViewerFieldEdit(formFields, patientId, isUseMode)
    }

    if (formFields.length > 0)
      yield updateForm(formFields, mode)
    if (patientId) {
      yield getAttachments(formFields, patientId)
    }

  } catch (error) {
    console.log(error)
    yield put(CustomField.apiError(error.message))
  }
}

export function* initBuilderFieldEdit(formFields) {
  try {
    const builderEditFields = formFields.length > 0 &&
      Object.keys({
        ...formFields[0].sections.byId,
        ...formFields[0].fields.byId,
        ...formFields[0].choices.byId,
      })
        .reduce((acc, id) =>
          ({ ...acc, [id]: false }), {})
    yield put(CustomField.initBuilderFieldEdit(builderEditFields))
  } catch (error) {
    console.log(error)
    yield put(CustomField.apiError(error.message))
  }
}

export function* getAttachments(formFields, patientId) {
  const formFieldDocuments = formFields
    .map(teleExpertise => ({
      id: teleExpertise.id,
      docsId: Object
        .values(teleExpertise.fields.byId)
        .filter(field => field.typeOfField === 'document')
        .reduce((acc, { id }) => [...acc, id], [])
    }))
    .filter(teleExpertise => teleExpertise.docsId !== '')

  let receivedDocuments = []
  for (let docs of formFieldDocuments) {
    for (const docsId of docs.docsId) {
      const documents = yield call(Api.customFieldFiles, patientId, docsId)
      receivedDocuments = {
        id: String(docs.id),
        fieldId: docsId,
        documents: documents
          .map(document => document.assets
            .reduce((acc, value) => ({ ...acc, value })))
          .map(doc => ({ ...doc, id: doc.ownerId })),
      }
      yield put(CustomField.receivedDocuments(receivedDocuments))
    }
  }
}

export function* updateForm(form, mode) {
  if (mode === 'update') {
    // TODO don't update empty form
    const { sections = {}, fields, choices } = form[0]
    yield put(CustomField.receivedUpdatedFormFields({ sections, fields, choices }))
  } else {
    yield put(CustomField.receivedFormFields(form.map(({ id, ...rest }) => ({ id: String(id), ...rest }))))
  }
}

export function* initViewerFieldEdit(form, patientId, isUseMode) {
  for (let teleExpertise of form) {
    yield put(CustomField.initViewerFieldEdit({
      type: teleExpertise.type,
      id: teleExpertise.id,
      value: patientId || isUseMode ? false : true
    }))
  }
}

export function* update({ payload: { id: teleExpertiseId, sectionId, type: teleExpertiseType, target } }) {
  try {
    const forms = yield select(CustomField.selectFormFields)
    yield select(Patient.selectPatientId)
    const teleExpertise = forms.find(teleExpertise => teleExpertise.id === teleExpertiseId)

    const customFieldContents =
      !sectionId
        ? Object.values(
          Object.values(forms.find(teleExpertise => teleExpertise.id === teleExpertiseId).fields.byId)
        )
          .reduce((acc, {
            id,
            value,
            label,
            mandatory,
            isPatientSection,
            typeOfField,
          }) => ({
            ...acc,
            [id]: {
              content: typeof value === 'string' || typeOfField === 'document'
                ? value
                : value
                  .reduce((acc, id) => ({
                    ...acc,
                    [id]: forms.find(teleExpertise => teleExpertise.choices.byId[id]).choices.byId[id]
                  }), {}),
              label,
              mandatory,
              isPatientSection,
              typeOfField
            }
          }), {})

        : [teleExpertise.sections.allIds.filter(id => sectionId === id)]
          .map(sectionIds =>
            Object
              .entries(teleExpertise)
              .reduce((acc, [key, value]) => ({
                ...acc,
                [key]: key === 'sections'
                  ? {
                    ...value,
                    allIds: value.allIds.filter(sectionId =>
                      sectionIds.includes(Number(sectionId))),
                    byId: Object
                      .values(value.byId)
                      .filter(field =>
                        sectionIds.includes(Number(field.id)))
                      .reduce((acc, section) => [
                        ...acc,
                        ...section.fields,
                      ], [])
                  }
                  : value,
              }), {})
          )
          .reduce((acc, { sections, fields }) =>
            [...acc,
            Object.values(fields.byId)
              .filter(field => sections.byId.includes(field.id))
            ]
            , [])
          .reduce((acc, val) => acc.concat(val), [])
          .reduce((acc, {
            id,
            value,
            label,
            mandatory,
            isPatientSection,
            typeOfField,
          }) => ({
            ...acc,
            [id]: {
              content: typeof value === 'string' || typeOfField === 'document'
                ? value
                : value
                  .reduce((acc, id) => ({
                    ...acc,
                    [id]: forms.find(teleExpertise => teleExpertise.choices.byId[id]).choices.byId[id]
                  }), {}),
              label,
              mandatory,
              isPatientSection,
              typeOfField
            }
          }), {})

    yield call(Form.validatedCustomFieldContents, customFieldContents)
    // TODO : filter to exclude validation on scheduled doctor questionnaire sections with mandatory fields
    // if (patientId) {
    //   const contentsWithoutDocuments = Object
    //     .entries(validatedCustomFieldContents)
    //     .filter(([_, field]) => field.typeOfField !== 'document')
    //     .reduce((acc, [id, { error, ...values }]) => ({ ...acc, [id]: values }), {})
    //   yield call(Api.updateCustomFields, patientId, contentsWithoutDocuments)
    //   yield put(CustomField.setEditFields({ teleExpertiseType, sectionId, teleExpertiseId, target, value: false }))
    //   yield put(CustomField.success())
    // }

  } catch (error) {
    if (error instanceof Form.ValidationError) {
      for (let [id, field] of Object.entries(error.data)) {
        if (field.error)
          yield put(CustomField.setErrors({
            teleExpertiseId,
            fieldId: id,
            value: field.content,
            error: field.error,
          }))
      }
    } else {
      console.log(error)
      yield put(CustomField.apiError(error.message))
    }
  }
}



function* apiFormattedData(data, list = []) {
  try {
    const { id, label, mandatory, isPatientSection, sortNumber, typeOfField, choices = [], fields: children = [] } = data
    return Object
      .entries({ id, label, mandatory, isPatientSection, sortNumber, typeOfField, choices, children })
      .reduce((acc, [key, value]) =>
      ({
        ...acc,
        [key]:
          key === 'choices'
            ? value
              .map((id, idx) => ({ ...list.byId[id], sortNumber: idx + 1 }))
              .reduce((acc, { id, color, content, sortNumber }) => {
                return ({
                  ...acc,
                  [isUniqId(id) ? sortNumber : id]: { id, color, content, sortNumber }
                })
              }, {})
            : key === 'children'
              ? value
                .map((id, idx) => ({ ...list.byId[id], sortNumber: idx + 1 }))
                .reduce((acc, { id, label, sortNumber }) => {
                  return ({
                    ...acc,
                    [id]: { id, label, sortNumber }
                  })
                }, {})
              : value
      }), {})
  } catch (error) {
    console.log(error)
    yield put(CustomField.apiError(error.message))
  }
}

function* apiSortedFieldsData(data, list = []) {
  try {
    return data
      .fields
      .map((id, idx) => ({
        ...list.byId[id],
        sortNumber: idx + 1
      }))

  } catch (error) {
    console.log(error)
    yield put(CustomField.apiError(error.message))
  }
}

function* apiSortedSectionsData(data, list = []) {
  try {
    return data
      .sections
      .map((id, idx) => ({
        ...list.byId[id],
        sortNumber: idx + 1
      }))

  } catch (error) {
    console.log(error)
    yield put(CustomField.apiError(error.message))
  }
}

export function* moveChoice({ payload: { newField, teleExpertiseType, teleExpertiseId } }) {
  try {
    if (!isUniqId(newField.id)) {
      const choiceList = yield select(CustomField.selectChoices)
      const customField = yield apiFormattedData(newField, choiceList)

      yield call(Api.saveCustomFields, newField.id, teleExpertiseType, teleExpertiseId, customField)
    }
  } catch (error) {
    console.log(error)
    yield put(CustomField.apiError(error.message))
  }
}

export function* moveField({ payload: { newSection, teleExpertiseType, teleExpertiseId } }) {
  try {
    if (!isUniqId(newSection.id)) {
      const fieldList = yield select(CustomField.selectFields)
      const customFields = yield apiSortedFieldsData(newSection, fieldList)
      yield call(Api.reorderCustomFields, newSection.id, teleExpertiseType, teleExpertiseId, customFields)
    }
  } catch (error) {
    console.log(error)
    yield put(CustomField.apiError(error.message))
  }
}
export function* moveSection({ payload: { newCustomFields, teleExpertiseType, teleExpertiseId } }) {
  try {
    if (!isUniqId(newCustomFields.id)) {
      const fieldList = yield select(CustomField.selectSections)
      const customFields = yield apiSortedSectionsData(newCustomFields, fieldList)
      yield call(Api.reorderCustomFields, newCustomFields.id, teleExpertiseType, teleExpertiseId, customFields)
    }
  } catch (error) {
    console.log(error)
    yield put(CustomField.apiError(error.message))
  }
}

export function* validateFields(fieldList, choiceList, typeOfField) {
  try {
    for (let field in fieldList.byId) {
      const validField = fieldList.byId[field].label !== ''
      const validChoice = fieldList?.byId[field]?.choices &&
        fieldList.byId[field].choices
          .every(choiceId => choiceList.byId[choiceId].content !== '')
      if (!validField || !validChoice) {
        if (!validField)
          yield put(CustomField.setBuilderErrors({
            type: 'fields',
            id: field,
            error: 'Ce champ est requis.',
          }))
        if (fieldList?.byId[field]?.choices && !validChoice)
          for (let choiceId of fieldList.byId[field].choices) {
            if (choiceList.byId[choiceId].content === '')
              yield put(CustomField.setBuilderErrors({
                type: 'choices',
                id: choiceId,
                error: 'Ce champ est requis.',
              }))
          }
      } else {
        if (!isUniqId(field) && typeOfField)
          yield put(CustomField.setBuilderEditFields({
            fieldId: field,
            editMode: false
          }))
      }
    }
  } catch (error) {
    console.log(error)
    yield put(CustomField.apiError(error.message))
  }
}

export function* submitField({ payload: { type, fieldId, sectionId, teleExpertiseType, teleExpertiseId, typeOfField, noValidation = false } }) {
  try {
    const isBuilderEditMode = teleExpertiseId
    let customField = {}
    // Type is fields
    // All fields
    const fieldList = yield select(CustomField.selectFields)
    const choiceList = yield select(CustomField.selectChoices)
    let hasError = false

    if (!noValidation) {
      yield validateFields(fieldList, choiceList, typeOfField)
      hasError = yield select(CustomField.selectError)
      if (hasError) {
        throw new Error("Une information obligatoire est manquante pour valider les champs personnalisés du patient.")
      }
    }

    if (type !== 'sections') {

      customField = yield apiFormattedData(fieldList.byId[fieldId], choiceList)
      // Existing field
      if (isBuilderEditMode && !isUniqId(fieldId) && !hasError) {
        const fields = yield apiFormattedData(fieldList.byId[fieldId], choiceList)
        yield call(Api.saveCustomFields, fieldId, teleExpertiseType, teleExpertiseId, fields)
        // New field
      } else if (isBuilderEditMode && isUniqId(fieldId) && !hasError) {
        const fields = yield apiFormattedData(fieldList.byId[fieldId], choiceList)
        const { customFields } = yield call(Api.addField,
          teleExpertiseType,
          teleExpertiseId,
          {
            ...fields,
            typeOfField: fieldList.byId[fieldId].typeOfField,
            type: fieldList.byId[fieldId].typeOfField,
            motherSection: sectionId
          }
        )
        yield call(customFieldsFromExpertise, null, [], [], { [teleExpertiseType]: [{ customFields }] }, true, 'update')
      }

    } else if (type === 'sections' && isBuilderEditMode) {
      const sectionList = yield select(CustomField.selectSections)
      const fieldList = yield select(CustomField.selectFields)

      // Type is section
      if (!isUniqId(fieldId)) {
        // Existing section
        customField = yield apiFormattedData(sectionList.byId[fieldId], fieldList)
        yield call(Api.saveCustomFields, fieldId, teleExpertiseType, teleExpertiseId, customField)
        yield put(CustomField.setBuilderEditFields({ fieldId, editMode: false }))
      } else {
        // New section
        const {
          label,
          typeOfField = 'section',
          type = 'section',
          mandatory = false,
          isPatientSection = false,
        } = sectionList.byId[fieldId]
        const { customFields } = yield call(Api.addSection,
          teleExpertiseType,
          teleExpertiseId,
          {
            label,
            typeOfField,
            type,
            mandatory,
            isPatientSection,
          })

        yield call(customFieldsFromExpertise, null, [], [], { [teleExpertiseType]: [{ customFields }] }, true, 'update')
       // yield put(CustomField.setEditFields({ teleExpertiseType, teleExpertiseId, target: 'customFields', value: false }))
      }
    }
    yield put(CustomField.success())

  } catch (error) {
    yield put(CustomField.apiError(error.message))
  }
}

export function* updateLabels({ payload: { fieldId, checked } }) {
  try {
    const patientId = yield select(Patient.selectPatientId)

    if (patientId) {
      if (checked) {
        yield call(Api.addLabelToPatient, patientId, fieldId)
      } else {
        yield call(Api.removeLabelFromPatient, patientId, fieldId)
      }
    }
  } catch (error) {
    console.log(error)
    yield put(CustomField.apiError(error.message))
  }
}

export function* addChoice({ payload: { fieldId, typeOfField, teleExpertiseType, teleExpertiseId } }) {
  try {
    if (!isUniqId(fieldId)) {
      yield put(CustomField.submitField({
        type: 'choices',
        fieldId,
        noClose: true,
        noValidation: true,
      }))
      const customFields = yield call(Api.addChoice, teleExpertiseType, teleExpertiseId, {
        content: '',
        customFieldId: fieldId,
        customFieldType: typeOfField,
      })
      yield call(customFieldsFromExpertise, null, [], [], { [teleExpertiseType]: [{ customFields }] }, true, 'update', 'choice')
    }
  } catch (error) {
    console.log(error)
    yield put(CustomField.apiError(error.message))
  }
}

export function* removeChoice({ payload: { fieldId, choiceId, teleExpertiseType, teleExpertiseId } }) {
  try {
    if (!isUniqId(fieldId)) {
      yield put(CustomField.submitField({
        type: 'choices',
        fieldId,
        noClose: true,
        noValidation: true,
      }))
      const customFields = yield call(Api.removeChoice, teleExpertiseType, choiceId, teleExpertiseId)
      yield call(customFieldsFromExpertise, null, [], [], { [teleExpertiseType]: [{ customFields }] }, true, 'update', 'choice')
    }
  } catch (error) {
    console.log(error)
    yield put(CustomField.apiError(error.message))
  }
}

export function* removeField({ payload: { fieldId, teleExpertiseType, teleExpertiseId } }) {
  try {
    if (!isUniqId(fieldId)) {
      yield call(Api.removeField, fieldId, teleExpertiseType, teleExpertiseId)
    }
  } catch (error) {
    yield put(CustomField.apiError(error.message))
  }
}

export function* removeSection({ payload: { sectionId: fieldId, teleExpertiseType, teleExpertiseId } }) {
  try {
    if (!isUniqId(fieldId)) {
      yield call(Api.removeField, fieldId, teleExpertiseType, teleExpertiseId)
    }
  } catch (error) {
    yield put(CustomField.apiError(error.message))
  }
}

export function* setIsPatientSection({ payload: { sectionId: fieldId, teleExpertiseType, teleExpertiseId } }) {
  try {
    if (!isUniqId(fieldId)) {
      // yield call(Api.removeField, fieldId, teleExpertiseType, teleExpertiseId)
    }
  } catch (error) {
    yield put(CustomField.apiError(error.message))
  }
}

export function* addSection({ payload: { teleExpertiseType, teleExpertiseId } }) {
  try {
    const sections = yield select(CustomField.selectSections)
    const fieldId = Object
      .keys(sections.byId)
      .find(section => section.indexOf('CF_') !== -1)
    yield put(CustomField.submitField({ type: 'sections', typeOfField: 'sections', fieldId, teleExpertiseType, teleExpertiseId }))
  } catch (error) {
    yield put(CustomField.apiError(error.message))
  }
}

export function customFieldsWithValidationKeys(customFields, patientId) {
  // Create new object with validation keys
  return customFields
    .map(({ fields, choices }) =>
      Object.values(fields.byId)
        // .filter(({ hasMotherPatientSection }) => !hasMotherPatientSection)
        .map(({ id, value, validationRules, hasMotherPatientSection }) => {
          return ({
            [id]: {
              value:
                Array.isArray(value) &&
                  value.length > 0 &&
                  Object.keys(choices.byId)
                    .some(choiceId => value.includes(Number(choiceId)))
                  ? value
                    .map(choiceId =>
                      choices.byId[choiceId])
                    .reduce((acc, { id, content }) => ({
                      ...acc,
                      [id]: content
                    }), {})
                  : Object.entries(value.length) === 0
                    ? []
                    : value,
              errors: [],
              validationRules: (!patientId && hasMotherPatientSection) || (patientId && !hasMotherPatientSection)
                ? validationRules
                : []
            }
          })
        })
        .reduce((acc, field) => ({
          ...acc, ...field
        }), {})
    ).reduce((acc, field) => ({
      ...acc, ...field
    }), {}) || {}
}

export function* updateCustomField({ payload }) {
  try {
    const patientId = yield select(Patient.selectPatientId)
    const customFieldsDatas = yield select(CustomField.selectFormFields)

    // Force fields update on each forms for validation
    const form = customFieldsDatas.find(teleExpertise => teleExpertise.id === payload.id)

    const customFields = customFieldsWithValidationKeys([form], patientId)

    const apiData = Object
      .entries(customFields)
      .filter(([key]) =>
        Number(key) === Number(payload.fieldId)
      )
      .reduce((acc, [id, { value }]) =>
      ({
        ...acc,
        [id]: {
          content: Array.isArray(value)
            ? ''
            : value
        }
      }), {})

    // !!! don't close edit if data are not valid
    let customFieldContents
    if (payload.isQuestionnaire) {
      ({ patientCustomFieldContents: customFieldContents } = yield call(Api.sendQuestionnaire, payload.patientCode, apiData))
    } else {
      ({ customFieldContents } = yield call(Api.updateCustomFields, patientId, apiData))
      const value = customFieldContents[payload.fieldId]?.content

      yield put(CustomField.updateInitialValue({
        teleExpertiseId: payload.id,
        fieldId: payload.fieldId,
        value
      }))
    }

    yield put(CustomField.setEditFields({
      teleExpertiseId: payload.id,
      fieldId: payload.fieldId,
      isFieldEdit: false
    }))

  } catch (error) {
    if (error instanceof Form.ValidationError) {
      // eslint-disable-next-line no-unused-vars
      for (let [_, field] of Object.entries(error.data)) {
        if (field.error)
          yield put(CustomField.setErrors({
            teleExpertiseId: payload.id,
            fieldId: payload.fieldId,
            value: field.content,
            error: field.error,
          }))
      }
    } else {
      console.log(error)
      yield put(CustomField.apiError(error.message))
    }
  }
}

const CustomFieldEffects = function* () {
  yield takeLatest(`${CustomField.update}`, update);
  // yield takeLatest(`${CustomField.updateAllForms}`, updateAllForms);
  yield takeLatest(`${CustomField.fetch}`, fetch);
  yield takeLatest(`${CustomField.updateLabels}`, updateLabels);
  yield takeLatest(`${CustomField.submitField}`, submitField);
  yield takeLatest(`${CustomField.addChoice}`, addChoice);
  yield takeLatest(`${CustomField.removeChoice}`, removeChoice);
  yield takeLatest(`${CustomField.removeField}`, removeField);
  yield takeLatest(`${CustomField.removeSection}`, removeSection);
  yield takeLatest(`${CustomField.moveChoice}`, moveChoice);
  yield takeLatest(`${CustomField.moveField}`, moveField);
  yield takeLatest(`${CustomField.moveSection}`, moveSection);
  yield takeLatest(`${CustomField.addSection}`, addSection);
  yield takeLatest(`${CustomField.setIsPatientSection}`, setIsPatientSection);
  yield takeEvery([
    `${CustomField.updateChoiceField}`,
    `${CustomField.updateRadioField}`,
    `${CustomField.updateCustomFieldValue}`,
  ], updateCustomField);
};

export default CustomFieldEffects;
