export const timeLeft = (duration) => {
  const now = new Date().getTime();
  let suspendedAccessTime = localStorage.getItem('suspendedAccessTime')
  const minutesLeft = (now, suspendedTime) => Math.floor((now - suspendedTime) / 60000)

  if (
    !suspendedAccessTime ||
    minutesLeft(now, suspendedAccessTime) > duration
  ) {
    localStorage.setItem('suspendedAccessTime', now);
    suspendedAccessTime = now
  }
  return duration - minutesLeft(now, suspendedAccessTime)
}

export const todayAtMidnight = new Date().setHours(0, 0, 0, 0)

export const debounceTime = query => query.length < 2
  ? 800
  : query.length < 3
    ? 500
    : 200
