import styled from 'styled-components'
import * as FormUi from './Form'
import * as BasicElementUi from './BasicElement'
import * as LayoutUi from './Layout'

export const Container = styled.div`
  border: 1px solid;
  border-color: ${props => props.on ? props.theme.patientGreen : 'transparent'};
  /* margin-bottom: 2rem; */
  padding: 1.2rem 1.5rem;
  background: white;
  border-radius: 5px;
  margin-right: 1rem;
  box-shadow: 0 2px 5px 1px rgba(134, 174, 197, 0.24);
`

export const Header = styled.div`
  > div:first-child {
    display: flex;
    flex: 1;
  }
  display: flex;
  align-items: flex-start;
  margin-bottom: 1rem;
  flex-direction: row;
  @media only screen and (max-width: ${ props => props.theme.device.desktop}) {
    flex-direction: column-reverse;
  }
`

export const Body = styled.div`
  display: flex;
  flex-direction: column;
`

export const Avatar = styled.img`
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
`

export const Title = styled.div`
  margin-left: .8rem;
  & ${BasicElementUi.P} {
    color: ${props => props.theme.grey};
    margin: 0;
    line-height: normal;
    &:first-child {
      line-height: 1.5rem;
      font-size: 0.9375rem;
      font-weight: bold;
      color: ${props => props.theme.darkBlue};
    }
  }
`

export const MessageActionButtons = styled(props => <LayoutUi.ActionButtonsContainer {...props} />)`
  align-self: flex-end;
  margin-bottom: .8rem;
  @media only screen and (min-width: ${ props => props.theme.device.desktop}) {
    margin-bottom: 0;
  }
`

export const Box = styled.div`
  display: ${props => props.visible ? 'block' : 'none'};
  /* margin-bottom: 2rem; */
  ${FormUi.TextArea} {
    min-height: 8rem;
  }
  ${LayoutUi.ButtonGroup} {
    margin-top: .8rem;
    > a:not(:last-child), label,> button:not(:last-child) {
      margin-right: .5rem;
    }
    @media only screen and (max-width: ${ props => props.theme.device.desktop}) {
      display: flex;
      flex-direction: column;
    }
  }
  ${FormUi.DocumentFileContainer} {
    margin-top: 0;
    margin-bottom: 0;
    label {
      width: auto;
    }
  }
`
