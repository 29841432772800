import { createSlice } from '@reduxjs/toolkit';

export const INITIAL_STATE = {
  id: null,
  data: {
    author: { value: {}, errors: [], validationRules: [], },
    firstname: { value: '', errors: [], validationRules: [], },
    lastname: { value: '', errors: [], validationRules: [], },
    profilePicture: { value: '', errors: [], validationRules: [], },
    subject: { value: '', errors: [], validationRules: ['notEmpty'], },
    content: { value: '', errors: [], validationRules: ['notEmpty'], },
    replySubject: { value: '', errors: [], validationRules: [], },
    replyContent: { value: '', errors: [], validationRules: ['notEmpty'], },
    assets: { value: [], errors: [], validationRules: [], },
    recipients: { value: [], errors: [], validationRules: ['notEmpty'], },
    patients: { value: [], errors: [], validationRules: [], },
    members: { value: [], errors: [], validationRules: [], },
    messages: { value: [], errors: [], validationRules: [], },
    read: { value: false, errors: [], validationRules: [], },
    messageObject: { value: '', errors: [], validationRules: [], },
    createdAt: { value: '', errors: [], validationRules: [], },
    permissions: { value: { _canBe: { seenWithTeleExpertiseLimitPatient: true, deleted: false }, _canBeModified: {} }, errors: [], validationRules: [] },
  },
  search: {
    patients: { value: '' },
    recipients: { value: '' },
  },
  messagesNum: 0,
  notificationsNum: 0,
  pending: false,
  loading: false,
  success: false,
  error: false,
  errorMessage: null,
  isEditMode: false,
  currentUser: null,
}

const slice = createSlice({
  name: 'message',
  initialState: INITIAL_STATE,
  reducers: {

    updateField: (state, { payload: { name, value, errors = [] } }) => ({
      ...state,
      data: {
        ...state.data,
        [name]: {
          ...state.data[name],
          value,
          errors,
        },
      },
      error: false,
      errorMessage: null,
    }),

    received: (state, { payload }) => ({
      ...state,
      data: Object
        .entries(payload)
        .reduce((acc, [name, value]) => ({
          ...acc,
          [name]: {
            ...state.data[name],
            value,
          },
        }), state.data),
      loading: false,
      error: false,
    }),

    setQuery: (state, { payload: { name, value } }) => ({
      ...state,
      search: {
        ...state.search,
        [name]: {
          ...state.search[name],
          value
        }
      }
    }),

    setEditMode: (state, { payload: { value, target } }) => ({
      ...state,
      isEditMode: value,
      search: {
        ...state.search,
        [target]: {
          ...state.search[target],
          value: '',
        }
      },
    }),

    update: (state, { payload: { resource, resourceId, toggled } }) => ({
      ...state,
      data: {
        ...state.data,
        [`${resource}s`]: {
          ...state.data[`${resource}s`],
          value: toggled
            ? [...state.data[`${resource}s`].value, resourceId]
            : state.data[`${resource}s`].value.filter(id => resourceId !== id),
        }
      }
    }),

    toggle: (state, { payload: { id, toggled, name } }) => ({
      ...state,
      data: {
        ...state.data,
        [name]: {
          ...state.data[name],
          value: toggled
            ? [...state.data[name].value, id]
            : state.data[name].value.filter(i => id !== i),
        },
      },
      search: {
        ...state.search,
        [name]: {
          ...state.search[name],
          value: '',
        }
      },
      error: false,
      errorMessage: null,
    }),

    setRecipients: (state, { payload: { id } }) => ({
      ...state,
      data: {
        ...state.data,
        recipients: {
          ...state.data.recipients,
          value: [Number(id)]
        }
      }
    }),

    success: (state, { payload: id }) => ({
      ...state,
      loading: false,
      pending: false,
      error: false,
      success: true,
      colleagueId: id
    }),

    invalidate: (state, { payload }) => ({
      ...state,
      pending: false,
      error: true,
      errorMessage: payload,
    }),

    apiError: (state, action) => ({
      ...state,
      errorMessage: action.payload,
      pending: false,
      error: true,
      success: false,
    }),

    send: (state, action) => ({
      ...state,
      pending: true,
      error: false,
      success: false,
      data: Object
        .entries(state.data)
        .reduce((acc, [name, field]) => ({
          ...acc,
          [name]: { ...field, errors: [] },
        }), state.data),
    }),

    reply: (state, action) => ({
      ...state,
      pending: true,
      error: false,
      success: false,
    }),

    fetch: (state, { payload }) => ({
      ...state,
      loading: true,
    }),

    clean: state => INITIAL_STATE,

    addPatient: state => INITIAL_STATE,

    removePatient: state => INITIAL_STATE,

    addRecipient: state => INITIAL_STATE,

    removeRecipient: state => INITIAL_STATE,

    remove: state => state,
  },
})

export const {
  updateField,
  toggle,
  setQuery,
  setEditMode,
  success,
  invalidate,
  apiError,
  clean,
  fetch,
  received,
  sendMessage,
  send,
  reply,
  remove,
  update,
  addPatient,
  removePatient,
  addRecipient,
  removeRecipient,
  setRecipients,
} = slice.actions

export const selectAuthor = state => state.message.data.author.value

export const selectData = state => state.message.data

export const selectSuccess = state => state.message.success

export const selectLoading = state => state.message.loading

export default slice.reducer
