import React, { useState, useEffect } from 'react'
import { privateSitePrefix } from '../Util/Config'
import { useDispatch, useSelector } from 'react-redux';
import { useAlert } from 'react-alert'
import { Redirect, useHistory } from "react-router-dom";
import * as Menu from '../State/Menu'
import * as Login from '../State/Login';
import * as Resources from '../State/Resources';
import * as Organization from '../State/Organization';
import * as Ui from './Ui'
import styled from 'styled-components/macro'
import { uniqById, uniqByIdAndType } from '../Util/Object'
import LoadingBox from './Ui/LoadingBox'

const CreateOrganizationComponent = (props) => {
  const alert = useAlert()
  const history = useHistory()
  const dispatch = useDispatch();
  const currentUser = useSelector(Login.selectUser)

  const {
    data,
    search,
    pending,
    isEditMode,
    errorMessage,
    success,
    availableExpertises: expertises,
  } = useSelector(state => state.organization)
  const resourceLoading = useSelector(Resources.selectLoading)
  const [currentSection, setCurrentSection] = useState(1)
  const {
    [Resources.RESOURCE_MEMBERS]: members,
  } = useSelector(Resources.selectResources)

  useEffect(() => {
    if (errorMessage)
      alert.error(errorMessage, { timeout: 5000 })
  }, [alert, errorMessage])

  useEffect(() => {
    dispatch(Menu.changeActive(null))

    return () => {
      dispatch(Organization.clean())
    }
  }, [dispatch])

  const updateField = name => e => {
    dispatch(Organization.updateField({ name, value: e.target.value }))
  }

  const onSend = (e) => {
    e.preventDefault()
    dispatch(Organization.send({ name: data.name.value }))
  }

  if (success) {
    return <Redirect to={`${privateSitePrefix}/profil?tab=3`} />
  }

  return (
    <>
      <LoadingBox />
      <Ui.Layout.PageLayout currentUser={currentUser}>
        <OrganizationContainer>
          <Ui.Layout.Form onSubmit={onSend} noValidate>
            <Ui.Layout.PageContentWrapper>
              <Description
                data={data}
                pending={pending}
                dispatch={dispatch}
                setCurrentSection={setCurrentSection}
                history={history}
              />
              <Ui.Layout.Content hasPanel noPadding>
                <Ui.Layout.NavContainer>
                  <nav>
                    <Ui.Layout.NavLink
                      onClick={() => setCurrentSection(1)}
                      on={currentSection === 1}
                    >
                      <Ui.Icon.IconInfos />
                      Informations générales
                    </Ui.Layout.NavLink>
                  </nav>
                </Ui.Layout.NavContainer>
                <Ui.Layout.PaddedContent>
                  <Summary
                    data={data}
                    search={search}
                    updateField={updateField}
                    dispatch={dispatch}
                    pending={pending}
                    currentSection={currentSection}
                    currentUser={currentUser}
                    isEditMode={isEditMode}
                    setCurrentSection={setCurrentSection}
                    members={members}
                    resourceLoading={resourceLoading}
                    expertises={expertises}
                  />
                </Ui.Layout.PaddedContent>
              </Ui.Layout.Content>
            </Ui.Layout.PageContentWrapper>
          </Ui.Layout.Form>
        </OrganizationContainer>
      </Ui.Layout.PageLayout>
    </>
  )
}

const Description = ({
  pending,
  history,
}) => {

  return (
    <Ui.Layout.PanelContainer>
      <Ui.Layout.Panel>
        <Ui.Button.BackButton onClick={history.goBack} />
        <Ui.BasicElement.H1Container>
          <Ui.Icon.IconH1>
            <Ui.Icon.IconHospitalBuilding />
          </Ui.Icon.IconH1>
          <Ui.BasicElement.H1>Nouvelle organisation</Ui.BasicElement.H1>
        </Ui.BasicElement.H1Container>
        <Ui.BasicElement.P light>
          Créez une organisation pour collaborer efficacement avec
          les médecins de votre établissement, service ou réseau.
          Tous les médecins d'une même organisation ont accès automatiquement
          à l'ensemble des téléexpertises et patients qu'elle administre.
        </Ui.BasicElement.P>
        <SubmitButton
          isFilled
          isGreen
          type="submit"
          disabled={pending}
        >
          {pending && <Ui.Global.Loader />}
          Créer l'organisation
        </SubmitButton>
      </Ui.Layout.Panel>
    </Ui.Layout.PanelContainer>
  )
}

const Summary = ({
  data,
  dispatch,
  updateField,
  currentSection,
  currentUser,
  members,
  search,
  pending,
  resourceLoading,
  expertises,
}) => {

  return (currentSection === 1) && (
    <Ui.Layout.Section>
      <Ui.BasicElement.H2>
        Informations générales
      </Ui.BasicElement.H2>
      <Ui.Layout.Row>
        <Ui.Form.TextInput
          htmlFor="name"
          label="Nom *"
          value={data.name.value}
          onChange={updateField('name')}
          error={data.name.errors[0]}
          autoFocus
          noSubmit
        />
      </Ui.Layout.Row>
      <Ui.BasicElement.H2>
        Administrateurs
      </Ui.BasicElement.H2>
      <Ui.Layout.BlockContainer>
        <Ui.SearchBar.SearchAndCheck
          name="managers"
          query={search.managers.value}
          TeleExpertise={Organization}
          resultSet={[currentUser, ...members]}
          list={[currentUser.id, ...data.managers.value]}
          loading={resourceLoading.managers}
          checkboxDisabled={manager => Number(manager.id) === Number(currentUser.id)}
          onToggle={(id, toggled, name = 'managers') =>
            dispatch(Organization.toggle({ value: id, toggled, name }))}
        />
        {data.members.errors[0] &&
          <Ui.Form.FieldErrorMessage>{data.members.errors[0]}</Ui.Form.FieldErrorMessage>
        }
      </Ui.Layout.BlockContainer>
      <Ui.BasicElement.H2>
        Médecins
      </Ui.BasicElement.H2>
      <Ui.Layout.BlockContainer>
        <Ui.SearchBar.SearchAndCheck
          name="members"
          query={search.members.value}
          TeleExpertise={Organization}
          resultSet={[currentUser, ...members.filter(uniqById)]}
          list={[currentUser.id, ...data.managers.value, ...data['members'].value]}
          loading={resourceLoading.members}
          checkboxDisabled={member => [currentUser.id, ...data.managers.value].includes(member.id)}
          onQueryChange={(e) => dispatch(Organization.setQuery({
            name: 'members',
            value: e.target.value
          }))}
          onToggle={(id, toggled, name = 'members') =>
            dispatch(Organization.toggle({ value: id, toggled, name }))}
        />
        {data.members.errors[0] &&
          <Ui.Form.FieldErrorMessage>{data.members.errors[0]}</Ui.Form.FieldErrorMessage>
        }
      </Ui.Layout.BlockContainer>
      <Ui.BasicElement.H2>
        Téléexpertises associées à l'organisation
      </Ui.BasicElement.H2>
      <Ui.Layout.BlockContainer>
        <Ui.SearchBar.SearchAndCheck
          name="expertises"
          query={search.expertises.value}
          TeleExpertise={Organization}
          resultToLabel={expertise => expertise.name}
          resultToValue={expertise => ({ type: expertise.type, id: expertise.id, })}
          resultSet={expertises.filter(uniqByIdAndType)}
          list={data.expertises.value}
          loading={search.expertises.loading}
          CheckboxIconComponent={Ui.SearchBar.ExpertiseCheckboxIcon}
        />
      </Ui.Layout.BlockContainer>
      <SubmitButtonMobile
        isFilled
        isGreen
        type="submit"
        disabled={pending}
      >
        {pending && <Ui.Global.Loader />}
        Créer l'organisation
      </SubmitButtonMobile>
    </Ui.Layout.Section >
  )
}

const OrganizationContainer = styled.div`
  ${Ui.BasicElement.H1} {
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
  }
  ${Ui.BasicElement.H2} {
    margin-bottom: 1rem;
  }
  ${Ui.Layout.Content} {
    @media only screen and (min-width: ${props => props.theme.device.desktop}) {
      & > :not(:first-child) {
        margin-top: 5rem;
      }
    }
    @media only screen and (max-width: ${props => props.theme.device.desktop}) {
      padding: 0;
    }
    ${Ui.Layout.ButtonGroup} {
      margin-top: .7rem;
    }
    ${Ui.Form.TextArea} {
      margin-bottom: 1rem;
    }
    ${Ui.Form.Input} {
      &:read-only {
        border-color: transparent;
        opacity: 1;
      }
    }
  }
  ${Ui.Button.Back} {
    flex: none;
  }
`

const SubmitButton = styled(props => <Ui.Button.BasicButton {...props} />)`
  @media only screen and (max-width: ${props => props.theme.device.desktop}) {
    display: none;
  }
`

const SubmitButtonMobile = styled(props => <Ui.Button.BasicButton {...props} />)`
  @media only screen and (min-width: ${props => props.theme.device.desktop}) {
    display: none;
  }
`

export default CreateOrganizationComponent;
