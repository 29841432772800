import React from 'react';
import { Link } from "react-router-dom";
import styled from 'styled-components/macro'
import * as Ui from '../Ui'
import { privateSitePrefix, authUrl } from '../../Util/Config'
export const List = ({ isVisible, children }) => {

  return (
    <ListContainer isVisible={isVisible}>{children}</ListContainer>
  )
}

export const TimeSlotList = ({ isVisible, children }) => {

  return (
    <TimeSlotListContainer>{children}</TimeSlotListContainer>
  )
}

export const Item = ({
  patient,
  onRemovePatient,
  isVisible,
  provided,
  pending,
}) => {

  return (
    <ListItemContainer>
      <Ui.Layout.FlexBox alignItems="center">
        <Ui.BasicElement.PatientIcon gender={patient.sex} />
        <IdentityDiagnostic>
          <Ui.Layout.FlexBox>
            <Link to={`${privateSitePrefix}/patients/${patient.id}`} title="Cliquez pour accéder à la fiche patient">
              {[patient.fullName,
              patient.fullBirthdate].filter(e => e).join(' - ')}
            </Link>
            <span>Responsable : Dr {patient.doctor.lastname}</span>
          </Ui.Layout.FlexBox>
          {patient.freeDiagnostic ? <CutRow>{patient.freeDiagnostic}</CutRow> : null }
        </IdentityDiagnostic>
        <Ui.Layout.ActionButtonsContainer>
          <Ui.Button.ActionButton
            type="button"
            onClick={onRemovePatient}
            disabled={pending}
          >
            <Ui.Icon.IconTrash />
          </Ui.Button.ActionButton>
          {!pending &&
            <MoveIcon
              {...provided.dragHandleProps}
              isVisible={isVisible}
            />
          }
        </Ui.Layout.ActionButtonsContainer>
      </Ui.Layout.FlexBox>
    </ListItemContainer >
  )
}

export const TimeSlotListItem = ({
  patient,
  onRemovePatient,
  onSendMail,
  canEdit,
  pending,
  teleExpertiseId,
  onEditPatient,
}) => {

  const isSOSMainTrappesId = ((~authUrl.indexOf('https://preprod-www') && Number(teleExpertiseId) === 31) ||
    ((~authUrl.indexOf('https://www') || ~authUrl.indexOf('localhost')) && Number(teleExpertiseId) === 5199))

  return (
    <TimeSlotListItemContainer>
      <Ui.Layout.FlexBox alignItems="center">
        <Ui.BasicElement.PatientIcon gender={patient.sex} />
        <IdentityDiagnostic>
          <Ui.Layout.FlexBox>
            <Link to={`${privateSitePrefix}/patients/${patient.id}`} title="Cliquez pour accéder à la fiche patient">
              {[patient.fullName,
              patient.fullBirthdate].filter(e => e).join(' - ')}
            </Link>
            <span>Responsable : Dr {patient.doctor.lastname}</span>
          </Ui.Layout.FlexBox>
          {patient.freeDiagnostic ? <CutRow>{patient.freeDiagnostic}</CutRow> : null }
        </IdentityDiagnostic>
        {canEdit &&
          <Ui.Layout.ActionButtonsContainer>
            <Ui.Button.ActionButton
              type="button"
              onClick={onEditPatient}
              disabled={pending}
            >
              <Ui.Icon.IconEdit />
            </Ui.Button.ActionButton>
            {isSOSMainTrappesId
              ? <Ui.Button.ActionButton
                type="button"
                title="Envoyer un mail au médecin responsable"
                onClick={onSendMail}
                disabled={pending}
              >
                <Ui.Icon.IconMail />
              </Ui.Button.ActionButton>
              : null
            }
            <Ui.Button.ActionButton
              type="button"
              onClick={onRemovePatient}
              disabled={pending}
            >
              <Ui.Icon.IconTrash />
            </Ui.Button.ActionButton>
          </Ui.Layout.ActionButtonsContainer>
        }
      </Ui.Layout.FlexBox>
    </TimeSlotListItemContainer >
  )
}

export const MoveIcon = styled(props => <Ui.Icon.MoveIcon {...props} />)`
    font-size: 1.125rem;
    color: ${props => props.theme.grey};
    margin-left: .3rem;
    display: ${props => props.isVisible ? 'block' : 'none'};
`

export const ListContainer = styled.div`
  display: ${props => props.isVisible ? 'block' : 'none'};
  margin-bottom: 2rem;
`

export const TimeSlotListContainer = styled.div`
  flex: 1;
  `

const IdentityDiagnostic = styled.div`
  @media only screen and (max-width: ${props => props.theme.device.desktop}) {
    ${Ui.Layout.FlexBox} {
      flex-direction: column;
    }
  }
`

export const ListItemContainer = styled(props => <Ui.Layout.CustomFieldContainer {...props} />)`
  margin-top: 1rem;
  padding: .4rem 1.3rem;
  font-size: .875rem;
  > ${Ui.Layout.FlexBox} {
    margin: .5rem 0;
  }
  ${Ui.Layout.ActionButtonsContainer} {
    align-self: flex-start;
  }
  ${Ui.BasicElement.PlainRoundIcon} {
    align-self: flex-start;
    margin-right: 1rem;
  }
  ${IdentityDiagnostic} {
    > div {
      a {
        flex: 1;
      }
      span {
        color: ${props => props.theme.grey};
      }
    }
  }
  div {
    &:nth-child(2) {
      flex: 1;
    }
    &:nth-child(3) {
      flex: initial;
      color: ${props => props.theme.grey};
    }
  }
  a {
    text-decoration: none;
    color: ${props => props.theme.darkBlue} !important;
  }
`

export const TimeSlotListItemContainer = styled.div`
  padding: .4rem 1.3rem;
  font-size: .875rem;
  > ${Ui.Layout.FlexBox} {
    margin: .5rem 0;
  }
  ${Ui.Layout.ActionButtonsContainer} {
    align-self: flex-start;
  }
  ${Ui.BasicElement.PlainRoundIcon} {
    align-self: flex-start;
    margin-right: 1rem;
  }
  ${IdentityDiagnostic} {
    > div {
      a {
        flex: 1;
      }
      span {
        color: ${props => props.theme.grey};
      }
    }
  }
  div {
    &:nth-child(2) {
      flex: 1;
    }
    &:nth-child(3) {
      flex: initial;
      color: ${props => props.theme.grey};
    }
  }
  a {
    text-decoration: none;
    color: ${props => props.theme.darkBlue} !important;
  }
`

const CutRow = styled.div`
  width: 95%;
  /* white-space: nowrap; */
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: .5rem;
`
