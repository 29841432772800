import { createSlice } from '@reduxjs/toolkit';
import { uniq } from '../Util/Object'
import { normalizeEmail } from '../Util/Format';

export const STEP_RPPS = 'rpps'
export const STEP_REGISTER = 'registration'

export const INITIAL_STATE = {
  step: STEP_RPPS,
  searchRpps: { value: '', loading: false },
  rppsData: [],
  data: {
    firstname: {
      value: '',
      errors: [],
      validationRules: ['notEmpty']
    },
    lastname: {
      value: '',
      errors: [],
      validationRules: ['notEmpty']
    },
    email: {
      value: '',
      errors: [],
      validationRules: ['notEmpty', 'email']
    },
    plainPassword: {
      value: '',
      errors: [],
      validationRules: ['notEmpty', 'password']
    },
    plainPasswordConfirmation: {
      value: '',
      errors: [],
      validationRules: ['notEmpty', 'password']
    },
    cellPhone: {
      value: '',
      errors: [],
      validationRules: ['notEmpty', 'cellPhone']
    },
    rpps: {
      value: '',
      errors: [],
      validationRules: ['rpps']
    },
    discipline: {
      value: '',
      errors: [],
      validationRules: []
    },
    city: {
      value: '',
      errors: [],
      validationRules: []
    },
  },
  pending: false,
  success: false,
  error: false,
  errorMessage: null
}

const slice = createSlice({
  name: 'registration',
  initialState: INITIAL_STATE,
  reducers: {
    updateStep: (state, { payload: step }) => ({
      ...state,
      step,
    }),
    fetchAllRppsRegistration: (state) => ({
      ...state,
    }),
    receivedAllRppsRegistration: (state, { payload }) => ({
      ...state,
      allRppsRegistration: payload,
    }),
    updateRppsQuery: (state, { payload: { query } }) => ({
      ...state,
      searchRpps: {
        value: query,
        loading: true
      },
    }),
    receivedRppsDatas: (state, { payload: rppsData }) => ({
      ...state,
      searchRpps: {
        ...state.searchRpps,
        loading: false
      },
      rppsData,
    }),
    updateFields: (state, { payload: { rpps = '', firstname = '', lastname = '', email = '', discipline = '', city = '' } }) => ({
      ...state,
      data: {
        ...state.data,
        firstname: { ...state.data.firstname, value: firstname },
        lastname: { ...state.data.lastname, value: lastname },
        email: { ...state.data.email, value: email },
        rpps: { ...state.data.rpps, value: rpps },
        discipline: { ...state.data.discipline, value: discipline },
        city: { ...state.data.city, value: city.filter(uniq).join(', ') },
      },
    }),
    updateField: (state, { payload: { name, value, errors = [] } }) => ({
      ...state,
      data: {
        ...state.data,
        [name]: {
          ...state.data[name],
          value: name === 'email' ? normalizeEmail(value) : value,
          errors,
        },
      },
      error: false,
      errorMessage: null,
    }),
    cleanPasswordErrors: (state) => ({
      ...state,
      data: {
        ...state.data,
        plainPasswordConfirmation: {
          ...state.data.plainPasswordConfirmation,
          errors: [],
        },
      },
    }),
    send: state => ({
      ...state,
      pending: true,
      error: false,
      success: false,
      data: Object
        .entries(state.data)
        .reduce((acc, [name, field]) => ({
          ...acc,
          [name]: { ...field, errors: [] },
        }), state.data),
    }),
    success: (state, action) => ({
      ...state,
      pending: false,
      error: false,
      success: true,
    }),
    invalidate: (state, action) => ({
      ...state,
      data: action.payload,
      pending: false,
      error: true,
      success: false,
    }),
    invalidateField: (state, { payload: { data, field } }) => ({
      ...state,
      data: {
        ...state.data,
        [field]: data[field]
      },
      pending: false,
      error: true,
      success: false,
    }),
    apiError: (state, action) => ({
      ...state,
      errorMessage: action.payload,
      pending: false,
      error: true,
      success: false,
    }),
    liveCheck: (state, { payload: { name } }) => {
      return ({
        ...state,
        data: {
          ...state.data,
          [name]: {
            ...state.data[name],
            errors: []
          }
        }
      })
    },
    validateDiscipline: (state) => ({
      ...state,
    }),
    validateRpps: (state) => ({
      ...state,
    }),
    clean: state => INITIAL_STATE,
  }
})

export const {
  updateStep,
  fetchAllRppsRegistration,
  receivedAllRppsRegistration,
  updateRppsQuery,
  receivedRppsDatas,
  send,
  success,
  invalidate,
  updateFields,
  updateField,
  cleanPasswordErrors,
  apiError,
  liveCheck,
  invalidateField,
  validateDiscipline,
  validateRpps,
  clean,
} = slice.actions

export const selectData = state => state.registration.data
export const selectSuccess = state => state.registration.success
export const selectEmail = state => state.registration.data.email.value
export const selectPassword = state => state.registration.data.plainPassword.value

export default slice.reducer
