import React, { useState } from 'react'
import styled from 'styled-components'
import * as Button from './Button'
import { normalizeName } from '../../Util/Format';
import { IconSave } from './Icon'

const DownloadableQrCodeComponent = ({ teleExpertiseName, children }) => {

  const onSaveCanvas = e => {
    e.preventDefault();
    let downloadLink = document.createElement('a');
    downloadLink.setAttribute('download',
      `${normalizeName(`QRcode_${teleExpertiseName.split(' ').join('_')}`)
        .replace(/[^a-zA-Z]+/, '_').replace(/\./g, '')}.png`);
    let canvas = document.getElementById('qrCodeCanvas');
    let dataURL = canvas.toDataURL('image/png');
    let url = dataURL.replace(/^data:image\/png/, 'data:application/octet-stream');
    downloadLink.setAttribute('href', url);
    downloadLink.click();
  }

  const [showSaveButton, setShowSaveButton] = useState(false);

  return (

    <QRCodeContainer
      onMouseEnter={() => setShowSaveButton(true)}
      onMouseLeave={() => setShowSaveButton(false)}
      title="Accéder au portail"
    >
      <ImagePreviewElement>
        {showSaveButton &&
          <DownloadButton
            $isFilled
            $isGreen
            onClick={onSaveCanvas}
          >
            <IconSave />
          </DownloadButton>
        }
        {children}
      </ImagePreviewElement>
    </QRCodeContainer >
  )
}

const QRCodeContainer = styled.span`
  cursor: pointer;
  width: auto;
  margin: 0 auto;
  margin-bottom: 1rem;
`

export const ImagePreviewElement = styled.span`
  width: 5.625rem;
  height: 5.625rem;
  position: relative;
  button {
    position: absolute;
    left: 50%;
    margin-left: -1.09375rem;
    bottom: -1rem;
    pointer-events: auto;
    &:hover {
      background-color: ${props => props.theme.darkGreen}
    }
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  };
`

const DownloadButton = styled(props => <Button.Button {...props} />)`
  width: 2.3125rem;
  height: 2.3125rem;
  padding: 0;
  font-weight: 600;
  &:hover {
    color: white;
  }
`

export default DownloadableQrCodeComponent;
