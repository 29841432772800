import * as OpinionList from '../State/OpinionList'
import * as Login from '../State/Login'
import * as Eff from 'redux-saga/effects'
import * as Api from '../Util/Api'
import { debounceTime } from '../Util/Date'

const expertiseType = 'opinion'

export function* fetch({
  payload: {
    page = 1,
  } = {},
} = {}) {
  const abortController = new AbortController();
  try {
    const rawFilters = yield Eff.select(OpinionList.selectFilters)
    const query = rawFilters.search
    if (query) {
      const delayMs = debounceTime(query)
      yield Eff.delay(delayMs)
    }
    const user = yield Eff.select(Login.selectUser)
    const filters = {
      ...rawFilters,
      statuses: rawFilters
        .status
        .filter(status => status.checked)
        .map(status => status.value),
      limit: Number(process.env.REACT_APP_DEFAULT_RESULT_LIMIT),
      offset: (page - 1) * process.env.REACT_APP_DEFAULT_RESULT_LIMIT,
      userId: user.id,
    }
    const { opinions, requests, keywordsNotFound, count } = yield Eff.call(Api.fetchTeleExpertises, filters, expertiseType, { signal: abortController.signal })
    const opinionsWithRequests = opinions.map(opinion => ({ ...opinion, requests: requests[opinion.id] || null }))

    if (count !== 'none') {
      yield Eff.put(OpinionList.receivedCountAll(count))
    }

    yield Eff.put(OpinionList.received({
      data: opinionsWithRequests,
      mode: page > 1
        ? OpinionList.MODE_RECEIVED_MORE
        : OpinionList.MODE_RECEIVED_FIRST,
      keywordsNotFound,
    }))
  } catch (error) {
    yield Eff.put(OpinionList.apiError(error.message))
  } finally {
    if (yield Eff.cancelled()) {
      abortController.abort();
    }
  }
}

export function* count() {
  try {
    const teleExpertiseCount = yield Eff.call(Api.teleExpertiseCount, 'opinions')

    yield Eff.put(OpinionList.receivedCount(teleExpertiseCount))
  } catch (error) {
    // @TODO handle errors correclty
  }
}

const OpinionListSagas = function* () {
  yield Eff.takeLatest([
    `${OpinionList.fetch}`,
    `${OpinionList.removeStatus}`,
    `${OpinionList.addStatus}`,
    `${OpinionList.changeDisplayBy}`,
    `${OpinionList.changeOrderBy}`,
  ], fetch)

  yield Eff.takeLatest(`${OpinionList.changeSearch}`, fetch)
  yield Eff.takeLatest(`${OpinionList.count}`, count)
}

export default OpinionListSagas;
