import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import styled, { css } from 'styled-components'
import Editable from '../../Util/Editable'
import * as IconUi from './Icon'
import { apiUrl } from '../../Util/Config'

const colorize = (props) =>
  props.$isPrimary
    ? props.theme.primary
    : props.theme.patientGreen
  ;

export const H1Container = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2rem;
  ${Editable.ViewBlock} {
    margin-bottom: 0;
  }
  @media only screen and (max-width: ${props => props.theme.device.desktop}) {
    margin-top: 1rem;
  }
  span {
    background-color: ${props => props.theme.primaryLight}
  }
`

export const H1 = styled.h1`
  color: ${props => props.theme.darkBlue};
  font-size: 2.25rem;
  line-height: 2.5rem;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  margin: 0;
  word-break: normal;
  overflow-wrap: anywhere;
  @media only screen and (min-width: ${props => props.theme.device.desktop}) {
    font-size: 2.5rem;
  }
`

export const H2 = styled.h2`
  color: ${props => props.theme.darkBlue};
  display: flex;
 // font-size: 2.25rem;
  /* line-height: 1.125rem; */
  align-items: center;
  /* padding-bottom: .5rem;
  padding-top: .5rem;
  margin-top: 0;
  margin-bottom: 0; */
  @media only screen and (min-width: ${props => props.theme.device.desktop}) {
    /* font-size: 2.5rem;
    line-height: 2.5rem; */
  }
`

export const H3 = styled.h3`
  color: ${props => props.isTitleWarning === true ? props.theme.cohorteRed : props.theme.grey};
  margin-bottom: ${props => props.mBottom || '1.1rem'};
  ${props => props.noMargin && css`
    margin: 0;
  `}
  font-size: .875rem;
`

export const H4 = styled.h4`
  color: ${props => props.theme.darkBlue};
  font-size: .8rem;
  font-weight: normal;
  font-style: italic;
  margin: 0;
  margin-bottom: 1.1rem;
  text-transform: capitalize;
`

export const Link = styled(props => <RouterLink {...props} />)`
  color: ${props => colorize(props)};
  text-decoration: none;
  font-style: normal;
  font-weight: 600;
`

export const P = styled.p`
  word-break: normal;
  overflow-wrap: anywhere;
  margin-bottom: 1.1rem;
  font-size: ${props => props.small
    ? '.625rem'
    : props.medium
      ? '.75rem'
      : props.big
        ? '.9375rem'
        : props.veryBig
          ? '1rem'
          : '.875rem'
  };
  color: ${props => props.dark
    ? props.theme.darkBlue
    : props.light
      ? props.theme.blueGreyLight
      : props.primary
        ? props.theme.primary
        : props.theme.darkBlue
  };
  line-height: 1.5rem;
  font-weight: ${props => props.bold ? '600' : 'normal'};
  font-style: ${props => props.italic ? 'italic' : 'normal'};
  text-transform: ${props => props.uppercase ? 'uppercase' : 'default'};
  text-align: ${props => props.center ? 'center' : 'left'};
  white-space: pre-wrap;
`

export const Ul = styled.ul``

export const Li = styled.li`
  font-size: .875rem;
  color: ${props => props.theme.darkBlue};
  line-height: 1.5rem;
`

export const Span = styled.span`
  text-decoration: none;
  margin: ${props => props.inline ? '0 0 0 .5rem;' : '0'};
  font-style: ${props => props.italic
    ? 'italic'
    : props.bold
      ? 'bold'
      : 'normal'
  };
  color: ${props => props.isPrimary ? props.theme.primary : props.theme.darkBlue};
  cursor: ${props => props.clickable ? 'pointer' : 'default'};
  font-weight: ${props => props.bold ? '600' : 'normal'};
`

export const Hr = styled.hr`
  width: 100%;
  margin-top: .5rem;
  border-top: 1px dotted ${props => props.theme.grey2};
  margin-left: 0;
`

export const Anchor = styled.a`
  display: block;
  position: relative;
  top: -7rem;
  display: hidden;
`
export const Figure = styled.img``

export const ReportImage = styled.div`
  background: url(${props => props.bgSrc}) no-repeat center;
  background-size: cover;
  width: 6.25rem;
  height: 6.25rem;
`

export const CollapsibleLink = styled(props => <P {...props} />).attrs({ bold: true, dark: true })`
  display: flex;
  margin-top: 1.4rem;
  color: ${props => props.theme.patientGreen};
  cursor: pointer;
  span {
    margin-right: .5rem;
  }
  span + span {
    font-size: .7rem;
  }
`

export const SubTitle = styled.div`
  font-size: 1.125rem;
  color: ${props => props.theme.patientGreen};
  text-transform: uppercase;
  font-weight: 600;
`

export const LinkNoDecoration = styled.a`
  font-weight: normal;
  text-decoration: none;
`

export const Table = styled.table`
  border: 1px solid ${props => props.theme.darkBlue};
  border-collapse: collapse;
  thead {

    padding: .5rem;
  }
  td, th {
    border: 1px solid ${props => props.theme.darkBlue};
    font-size: .875rem;
    padding: .5rem;
    color: ${props => props.theme.darkBlue};
    p {
      margin: 0;
    }
  }
`

export const AvatarPicture = styled.div`
  border-radius: 5000px;
  border-color: ${props => props.isPurple
    ? props.theme.staffPurple
    : props.isRed
      ? props.theme.cohorteRed
      : props.isBlue
        ? props.theme.avisBlue
        : 'inherit'
  };
  background: url(${props => props.bgSrc}) no-repeat center;
  background-size: cover;
  ${props => props.isBig
    ? css`
      width: 9rem;
      height: 9rem;
      flex-shrink: 0;
      display: flex;
      margin: 0 auto;
      margin-top: 1rem;
    `
    : css`
      width: 2.5rem;
      height: 2.5rem;
      flex-shrink: 0;
      margin-left: .5rem;
    `
  }
`

export const PlainRoundIcon = styled(props => <IconUi.ButtonIcon {...props} />)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.grey};
  background: ${props => props.theme.lightGrey};
  border: 0;
  border-radius: 5000px;
  width: 2.5rem;
  height: 2.5rem;
  margin-left: .5rem;
  flex-shrink: 0;
  opacity: ${props => props.disabled ? '.4' : '1'};
  + span {
    padding-left: 1rem;
  }
`

export const PatientIcon = ({
  gender = '',
  disabled = '',
}) =>
  <PlainRoundIcon
    gender={gender}
    disabled={disabled}
  >
    {gender === 'male'
      ? <IconUi.IconMan />
      : gender === 'female'
        ? <IconUi.IconWoman />
        : <IconUi.IconManWoman />
    }
  </PlainRoundIcon>

export const TeleExpertisePicture = ({
  value = {},
  isPublic,
  isBig = true,
}) => {

  const isDefaultProfilePicture = value.profilePicture &&
    ~value.profilePicture.indexOf('Avatar_default.png')

  const profilePicture = value.profilePicture
    ? isDefaultProfilePicture
      ? value.profilePicture
      : value.profilePicture.replace(/.*\//, '')
    : ''

  const picture = value.pictureFileName || profilePicture

  const pictureSrc = type => value.profilePicture && isDefaultProfilePicture
    ? `/${value.profilePicture}`
    : isPublic && value.pictureFileName
      ? `${apiUrl}/public/${type}s/picture/${value.pictureFileName}`
      : value.pictureFileName
        ? `${apiUrl}/${type}s/picture/${value.pictureFileName}`
        : isPublic
          ? `${apiUrl}/public/users/profile-picture/${profilePicture}`
          : `${apiUrl}/users/profile-picture/${profilePicture}`

  if (value.type === 'staff') {
    return picture
      ? <AvatarPicture
        alt="Avatar"
        isBig={isBig}
        isPurple
        bgSrc={pictureSrc(value.type)}>
      </AvatarPicture>
      : <ExpertisePicture
        isPurple={true}
      >
        <IconUi.IconMessage />
      </ExpertisePicture>
  }

  if (value.type === 'cohort') {
    return picture
      ? <AvatarPicture
        alt="Avatar"
        isBig={isBig}
        isRed
        bgSrc={pictureSrc(value.type)}>
      </AvatarPicture>
      : <ExpertisePicture
        isRed={true}
      >
        <IconUi.IconMessage />
      </ExpertisePicture>
  }

  if (value.type === 'opinion') {
    return picture
      ? <AvatarPicture
        alt="Avatar"
        isBig={isBig}
        isBlue
        bgSrc={pictureSrc(value.type)}>
      </AvatarPicture>
      : Number(value.thePersonal)
        ? <AvatarPicture
          alt="Avatar"
          isBig
          isBlue
          bgSrc={`/Avatar_default.png`}>
        </AvatarPicture>
        : <ExpertisePicture
          isBlue={true}
        >
          <IconUi.IconAvis />
        </ExpertisePicture>
  }

  return (
    <ExpertisePicture isGrey={true} />
  )
}

export const OrganizationPicture = ({ value = {} }) => {

  return value?.logo
    ? <AvatarPicture
      alt="Organization"
      bgSrc={`${apiUrl}/organizations/logo/${value.logo}`}>
    </AvatarPicture>
    : <CheckboxIcon isGreen>
      <IconUi.IconHospitalBuilding />
    </CheckboxIcon>

}
export const CheckboxIcon = styled(props => <PlainRoundIcon {...props} />)`
  color: white;
  cursor: auto;
  font-size: 1.4rem;
  background: ${props => props.isPurple
    ? props.theme.staffPurple
    : props.isRed
      ? props.theme.cohorteRed
      : props.isBlue
        ? props.theme.avisBlue
        : props.theme.patientGreen
  };
  &:hover {
    color: white;
  }
`

export const ExpertisePicture = styled(props => <PlainRoundIcon {...props} />)`
  background: white;
  cursor: auto;
  display: flex;
  margin: 0 auto;
  width: 9rem;
  height: 9rem;
  margin-top: 1rem;
  i {
    font-size: 3.5rem;
  }
  font-size: 1.4rem;
  color: ${props => props.isPurple
    ? props.theme.staffPurple
    : props.isRed
      ? props.theme.cohorteRed
      : props.isBlue
        ? props.theme.avisBlue
        : props.isGrey
          ? props.theme.mediumGrey
          : props.theme.patientGreen
  };
  border: 1px solid;
  border-color: ${props => props.isPurple
    ? props.theme.staffPurple
    : props.isRed
      ? props.theme.cohorteRed
      : props.isBlue
        ? props.theme.avisBlue
        : props.isGrey
          ? props.theme.mediumGrey
          : props.theme.patientGreen
  };
`

export const WhiteBox = styled.div`
  box-shadow: 0 2px 5px 1px rgba(134, 174, 197, 0.24);
  display: flex;
  margin-top: 0;
  flex: 1;
  background-color: #FFF;
  border: 0;
  flex-grow: 0;
  min-height: 2.8125rem;
  border-radius: 12px;
  margin-bottom: 0 !important;
`

export const ViewTitleContainer = styled.div`
  p {
    margin-top: .3rem !important;
  }
  &:hover {
    cursor: text;
    background-color: ${props => props.theme.LightGrey4};
  }
`

export const ContentBlock = styled.div`
  font-size: .875rem;
`
