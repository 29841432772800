import React from 'react';
import styled from 'styled-components'

export const DropdownMenu = styled.div`
  position: absolute;
  background: white;
  display: ${props => props.open ? 'block' : 'none'};
  min-width: 14rem;
  box-shadow: 0 2px 12px 0 rgba(33, 65, 82, 0.3);
  right: 0;
  top: 100%;
  z-index: 10;
`

export const DropdownContainer = styled.div`
  display: inline-flex;
  box-sizing: border-box;
  outline: none;
  z-index: 10;
  > div {
    display: block;
  }
`

export const DropdownItem = styled.a`
  text-decoration: none;
  border-bottom: 1px solid #e6eef3;
  padding: .25rem;
  display: flex;
  font-size: .875rem;
  align-items: center;
  color: ${props => props.theme.darkBlue};
  :hover {
    background-color: #E7EFF3;
    cursor: pointer;
  }
  > i {
    padding: .3rem 1.1rem;
    &::after {
      color: ${props => props.theme.grey};
      font-size: 1.3rem;
    }
  }
  & div > div span {
    font-size: .75rem;
    color: ${props => props.theme.patientGreen};
    font-weight: bold;
  }
`

export const SearchDropdownContainer = styled.div`
  max-height: 14rem;
  overflow: auto;
  padding-top: 3rem;
  z-index: 3;
  box-shadow: 0 2px 12px 0 rgba(33, 65, 82, 0.3);
  position: absolute;
  top: 50%;
  width: 100%;
  background: white;
  min-height: 3rem;
  border-radius: 8px;
  color: ${props => props.theme.darkBlue};
  /* overflow-y: auto; */
`

export const Dropdown = ({
  children,
  ...props
}) =>
  <DropdownContainer>
    {children}
  </DropdownContainer>

export const SearchDropdown = ({ children, ...props }) =>
  <SearchDropdownContainer {...props}>
    {children}
  </SearchDropdownContainer>
