import React, { useEffect } from 'react'
import { privateSitePrefix } from '../Util/Config'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import * as AccountValidation from '../State/AccountValidation';
import { useAlert } from 'react-alert'

const AccountValidationComponent = (props) => {
  const alert = useAlert();
  const dispatch = useDispatch()
  const history = useHistory()
  const { match: { params: { userCode } } } = props

  const errorMessage = useSelector(AccountValidation.selectErrorMessage)
  const validationLoading = useSelector(AccountValidation.selectValidationLoading)
  const success = useSelector(AccountValidation.selectSuccess)

  useEffect(() => {
    if (errorMessage) {
      alert.error(errorMessage, { timeout: 5000 })
      history.push(`${privateSitePrefix}/patients`)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessage, history])

  useEffect(() => {
    dispatch(AccountValidation.validateAccount({ userCode }))
    dispatch(AccountValidation.clean())
  }, [dispatch, userCode])

  useEffect(() => {
    alert.show('Votre compte à bien été validé')
    history.push(`${privateSitePrefix}/patients`)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, history, success])

  return (
    <div>
      {validationLoading && 'Loading...'}
    </div>
  )
}

export default AccountValidationComponent;
