import styled from 'styled-components/macro'
import * as Ui from '../Component/Ui'
import RegistrationForm from './Ui/RegistrationForm'

export const RegistrationTeleExpertiseContainer = styled.div`
 ${RegistrationForm.RegistrationContainer} {
    ${Ui.Form.DropdownItemLabel} {
      max-width: none;
    }
  }
  ${Ui.PortailLayout.Content} {
    @media only screen and (min-width: ${props => props.theme.device.desktop}) {
      margin-left: 0;
    }
  }
  ${Ui.BasicElement.H1} {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
  ${Ui.PortailLayout.Figure} {
    margin-left: 1rem;
  }
  ${Ui.PortailLayout.PortalContentContainer} {
    @media only screen and (min-width: ${props => props.theme.device.desktop}) {
      min-height: calc(100vh - 175px);
    }
  }
  ${Ui.PortailLayout.FooterContainer} {
    width: 100%;
    border-top: 1px dotted ${props => props.theme.mediumGrey};
    padding: 0;
    padding-right: 1rem;
    box-sizing: border-box;
    @media only screen and (min-width: ${props => props.theme.device.desktop}) {
      padding-left: 3rem;
    }
  }
`
