import React from 'react'
import styled from 'styled-components'
import * as IconUI from './Icon'
import * as LayoutUi from './Layout'

export const TagsContainer = styled.div`
  width: 100%;
  align-items: center;
  display: inline-flex;
  flex-wrap: wrap;

  &:hover {
    border-color: ${props => props.theme.primary};
  }
`

const TagSearchContainer = styled.div`
  margin-bottom: 2rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Input = styled.input`
  background-color: #FFF;
  border: 0;
  height: 2.8125rem;
  width: 100%;
  font-size: .875rem;
  border-radius: 12px;
  box-shadow: ${props => props.theme.boxShadow.thin};
  padding-left: 3.5rem;
  outline: 0;
  &::placeholder {
    color: ${props => props.theme.grey2};
    font-style: italic;
  }
`

export const CheckboxLabel = styled.label`
  display: inline-flex;
  align-items: center;
  cursor: ${props => props.disabled ? 'default' : 'pointer'};
  user-select: none;
  width: 100%;
  height: 1.78rem;
`

export const CheckboxInput = styled.input.attrs({ type: 'checkbox' })`
  display: none;
  &:checked + i {
    border-color: ${props => props.theme.patientGreen};
    color: #fff;
    &::after {
      content: '\\e923';
      font-family: 'ShareConfrere';
      font-size: .50rem;
      text-align: center;
      line-height: 1.3rem;
      display: block;
      background: ${props => props.theme.patientGreen};
    }
  }
`

export const CheckboxCheck = styled.i`
  display: inline-block;
  vertical-align: middle;
  background: ${props => props.disabled ? props.theme.grey2 : 'white'};
  width:  1.2rem;
  height: 1.2rem;
  font-style: normal;
  border-radius: 4px;
  border: 1px solid;
  border-color: ${props => props.disabled ? 'transparent' : props.theme.grey};
  position: relative;
  left: .3rem;
  &:hover {
    border-color: ${props => props.disabled ? props.theme.grey2 : props.theme.patientGreen};
  }
`

export const TagContainer = styled.div`
  position: relative;
  margin-top: .5rem;
  margin-bottom: .5rem;
  display: ${props => props.hidden ? 'none' : 'flex'};
  min-width: 3.8125rem;
  align-items: center;
  background: ${props => props.bgColor ? props.theme.tagColors[props.bgColor] : props.theme.grey2};
  font-weight: 700;
  font-size: 0.75rem;
  color: white;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  &:not(:last-child) {
    margin-right: 1.5rem;
  }
  &::after {
    content: ' ';
    height: 0;
    position: absolute;
    width: 0;
    border: 0.9rem solid transparent;
    left: 100%;
    top: 0px;
    border-left-color: ${props => props.bgColor ? props.theme.tagColors[props.bgColor] : props.theme.grey2};
  }
  & span {
    padding-left: .35rem;
    padding-right: .25rem;
  }
`

export const LabelsHeader = styled(props => <LayoutUi.FlexBox {...props} />)`
  h2 {
    flex: 1;
  }
`

const IconLabel = styled(props => <IconUI.IconLabel {...props} />)`
  position: absolute;
  left: 1.2rem;
  font-size: 1.25rem;
  color: ${props => props.theme.grey};
  border: 0;
`

export const TagBar = ({ placeholder, children, ...props }) =>
  <>
    <TagSearchContainer {...props}>
      <Input placeholder={placeholder} />
      <IconLabel />
    </TagSearchContainer>
  </>

export const Tag = ({ selectable, disabled, hidden, checked, onChange, children, bgColor, ...props }) =>
  <TagContainer bgColor={bgColor} hidden={hidden}>
    <CheckboxLabel disabled={disabled}>
      <CheckboxInput disabled={disabled} checked={checked} onChange={onChange} />
      {selectable &&
        <CheckboxCheck disabled={disabled} />
      }
      <span>{children}</span>
    </CheckboxLabel>
  </TagContainer>
