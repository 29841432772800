import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from "react-router-dom";
import { useAlert } from 'react-alert'
import { apiUrl } from '../Util/Config'
import * as DashboardAdmin from '../State/DashboardAdmin'
import * as Ui from './Ui'
import DatePicker from './Ui/DatePicker';
import styled from 'styled-components'
import LoadingBox from './Ui/LoadingBox'
import { dateToString } from '../Util/Format'

const DashboardAdminComponent = (props) => {
  const dispatch = useDispatch()
  const alert = useAlert()
  const history = useHistory();

  const currentUser = useSelector(state => state.login.currentUser)
  const {
    firstRange,
    secondRange,
    errorMessage,
  } = useSelector(state => state.dashboardAdmin)

  useEffect(() => {
    if (errorMessage)
      alert.error(errorMessage, { timeout: 5000 })
  }, [alert, errorMessage])

  useEffect(() => {
    dispatch(DashboardAdmin.fetch({ tableName: 'firstRange', dateType: 'startDate', value: new Date(new Date().setDate(1)) }))
    dispatch(DashboardAdmin.fetch({ tableName: 'secondRange', dateType: 'startDate', value: new Date(new Date().getTime() - (30 * 24 * 60 * 60 * 1000)) }))
  }, [dispatch])

  const onChangeDate = ({ tableName, dateType, value }) => {
    dispatch(DashboardAdmin.fetch({ tableName, dateType, value }))
  }

  return (
    <>
      <LoadingBox />
      <Ui.Layout.PageLayout currentUser={currentUser}>
        <DashboardAdminContainer>
          <Ui.Layout.PageContentWrapper>
            <Ui.Layout.PanelContainer>
              <Ui.Layout.Panel>
                <Ui.Button.BackButton onClick={history.goBack} />
                <Ui.Layout.TelexpertiseThumbnailContainer>
                  <Ui.Layout.TelexpertiseLogo>
                    <Ui.Icon.IconEye />
                  </Ui.Layout.TelexpertiseLogo>
                  <Ui.BasicElement.H3 noMargin>Dashboard Admin</Ui.BasicElement.H3>
                </Ui.Layout.TelexpertiseThumbnailContainer>
              </Ui.Layout.Panel>
            </Ui.Layout.PanelContainer>
            <Ui.Layout.Content hasPanel noPadding>
              <Ui.Layout.PaddedContent>
                <Ui.BasicElement.H2>Plage 1</Ui.BasicElement.H2>
                <DateSelectionRow>
                  <Ui.Layout.Column>Du :</Ui.Layout.Column>
                  <Ui.Layout.Column>
                    <Ui.BasicElement.WhiteBox onClick={e => e.stopPropagation()}>
                      <DatePickerBox>
                        <DatePicker
                          value={firstRange.startDate}
                          clearIcon={null}
                          calendarIcon="&#xe907;"
                          format="dd/MM/yyyy"
                          locale="fr-FR"
                          showLeadingZeros
                          onClick={e => e.stopPropagation()}
                          onChange={date => {
                            onChangeDate({ tableName: 'firstRange', dateType: 'startDate', value: date })
                          }}
                        />
                      </DatePickerBox>
                    </Ui.BasicElement.WhiteBox>
                  </Ui.Layout.Column>
                  <Ui.Layout.Column>
                    au :
                  </Ui.Layout.Column>
                  <Ui.Layout.Column>
                    <Ui.BasicElement.WhiteBox onClick={e => e.stopPropagation()}>
                      <DatePickerBox>
                        <DatePicker
                          value={firstRange.endDate}
                          clearIcon={null}
                          calendarIcon="&#xe907;"
                          minDate={firstRange.startDate}
                          format="dd/MM/yyyy"
                          locale="fr-FR"
                          showLeadingZeros
                          onClick={e => e.stopPropagation()}
                          onChange={date => {
                            onChangeDate({ tableName: 'firstRange', dateType: 'endDate', value: date })
                          }}
                        />
                      </DatePickerBox>
                    </Ui.BasicElement.WhiteBox>
                  </Ui.Layout.Column>
                </DateSelectionRow>
                <Ui.BasicElement.Table>
                  <thead>
                    <tr>
                      <th>Nb d'utilisateurs inscrits</th>
                      <th>Nb total de connexions</th>
                      <th>Nb patients créés</th>
                      <th>Nb avis créées (hors avis perso)</th>
                      <th>Nb staffs (RCP) créées</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{firstRange.data.registeredUserCount}</td>
                      <td>{firstRange.data.connectedUserCount}</td>
                      <td>{firstRange.data.createdPatientCount}</td>
                      <td>{firstRange.data.createdOpinionCount}</td>
                      <td>{firstRange.data.createdStaffCount}</td>
                    </tr>
                  </tbody>
                  <thead>
                    <tr>
                      <th>Nb compte validé</th>
                      <th>Nb abonnements PRO+</th>
                      <th>Nb réponses (Avis/RCP) rendus</th>
                      <th>Nb cohortes créées</th>
                      <th>Nb RCP réalisées</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{firstRange.data.accountValidated}</td>
                      <td>{firstRange.data.proPlusSubscription}</td>
                      <td>{firstRange.data.teleExpertiseCommentCount}</td>
                      <td>{firstRange.data.createdCohortCount}</td>
                      <td>{firstRange.data.staffSessionCount}</td>
                    </tr>
                  </tbody>
                </Ui.BasicElement.Table>
                <ExportContent>
                  <a rel="noopener noreferrer" download href={`${apiUrl}/admin/dashboard/kpi?export=1&startDate=${dateToString(firstRange.startDate, { separator: '-', reverse: true })}&endDate=${dateToString(firstRange.endDate, { separator: '-', reverse: true })}`}>
                    <Ui.Button.BasicButton
                      isGreen
                    >
                      <Ui.Icon.IconDocument />
                      Export plage 1
                    </Ui.Button.BasicButton>
                  </a>
                </ExportContent>
                <Ui.BasicElement.H2>Plage 2</Ui.BasicElement.H2>
                <DateSelectionRow>
                  <Ui.Layout.Column>Du :</Ui.Layout.Column>
                  <Ui.Layout.Column>
                    <Ui.BasicElement.WhiteBox onClick={e => e.stopPropagation()}>
                      <DatePickerBox>
                        <DatePicker
                          value={secondRange.startDate}
                          clearIcon={null}
                          calendarIcon="&#xe907;"
                          format="dd/MM/yyyy"
                          locale="fr-FR"
                          showLeadingZeros
                          onClick={e => e.stopPropagation()}
                          onChange={date => {
                            onChangeDate({ tableName: 'secondRange', dateType: 'startDate', value: date })
                          }}
                        />
                      </DatePickerBox>
                    </Ui.BasicElement.WhiteBox>
                  </Ui.Layout.Column>
                  <Ui.Layout.Column>
                    au :
                  </Ui.Layout.Column>
                  <Ui.Layout.Column>
                    <Ui.BasicElement.WhiteBox onClick={e => e.stopPropagation()}>
                      <DatePickerBox>
                        <DatePicker
                          value={secondRange.endDate}
                          clearIcon={null}
                          calendarIcon="&#xe907;"
                          minDate={secondRange.startDate}
                          format="dd/MM/yyyy"
                          locale="fr-FR"
                          showLeadingZeros
                          onClick={e => e.stopPropagation()}
                          onChange={date => {
                            onChangeDate({ tableName: 'secondRange', dateType: 'endDate', value: date })
                          }}
                        />
                      </DatePickerBox>
                    </Ui.BasicElement.WhiteBox>
                  </Ui.Layout.Column>
                </DateSelectionRow>
                <Ui.BasicElement.Table>
                  <thead>
                    <tr>
                      <th>Nb d'utilisateurs inscrits</th>
                      <th>Nb total de connexions</th>
                      <th>Nb patients créés</th>
                      <th>Nb avis créées (hors avis perso)</th>
                      <th>Nb staffs (RCP) créées</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{secondRange.data.registeredUserCount}</td>
                      <td>{secondRange.data.connectedUserCount}</td>
                      <td>{secondRange.data.createdPatientCount}</td>
                      <td>{secondRange.data.createdOpinionCount}</td>
                      <td>{secondRange.data.createdStaffCount}</td>
                    </tr>
                  </tbody>
                  <thead>
                    <tr>
                      <th>Nb compte validé</th>
                      <th>Nb abonnements PRO+</th>
                      <th>Nb réponses (Avis/RCP) rendus</th>
                      <th>Nb cohortes créées</th>
                      <th>Nb RCP réalisées</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{secondRange.data.accountValidated}</td>
                      <td>{secondRange.data.proPlusSubscription}</td>
                      <td>{secondRange.data.teleExpertiseCommentCount}</td>
                      <td>{secondRange.data.createdCohortCount}</td>
                      <td>{secondRange.data.staffSessionCount}</td>
                    </tr>
                  </tbody>
                </Ui.BasicElement.Table>
                <ExportContent>
                  <a rel="noopener noreferrer" download href={`${apiUrl}/admin/dashboard/kpi?export=1&startDate=${dateToString(secondRange.startDate, { separator: '-', reverse: true })}&endDate=${dateToString(secondRange.endDate, { separator: '-', reverse: true })}`}>
                    <Ui.Button.BasicButton
                      isGreen
                    >
                      <Ui.Icon.IconDocument />
                      Export plage 2
                    </Ui.Button.BasicButton>
                  </a>
                </ExportContent>
              </Ui.Layout.PaddedContent>
            </Ui.Layout.Content>
          </Ui.Layout.PageContentWrapper>
        </DashboardAdminContainer>
      </Ui.Layout.PageLayout>
    </>
  )
}

const DashboardAdminContainer = styled.div`
  ${Ui.Button.Back} {
    flex: 0;
  }
  ${Ui.BasicElement.H2} {
    margin-bottom: 0;
  }
  ${Ui.BasicElement.Table} {
    margin-top: 2rem;
    table, th, td {
      border: 1px solid;
    }
    tbody {
      td {
        text-align: center;
        padding: .5rem;
      }
    }
  }
  ${Ui.Layout.TelexpertiseThumbnailContainer} {
    margin-top: 1.5rem;
    ${Ui.BasicElement.H3} {
      margin-top: 0;
    }
  }
  @media only screen and (max-width: ${props => props.theme.device.desktop}) {
    margin: 1rem;
  }
`

const DateSelectionRow = styled(props => <Ui.Layout.Row {...props} />)`
  align-items: center;
  margin-top: .3rem;
  margin-bottom: .3rem;
  & > div:not(:last-child) {
    margin-right: .5rem;
  }

  ${Ui.Layout.Column} {
    max-width: 2rem;
  }
  ${Ui.Layout.Column}:nth-of-type(2), ${Ui.Layout.Column}:nth-of-type(4) {
    max-width: 10rem;
  }
`

const DatePickerBox = styled.div`
  width: 100%;
  padding: 0 1rem;
  display: flex;
  > div {
    width: 100%;
  }
`

const ExportContent = styled.div`
  margin-bottom: 1rem;

  a {
    text-decoration: none;
  }

`

export default DashboardAdminComponent;
