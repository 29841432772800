import React, { useEffect, useState } from 'react';
import qs from 'qs'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useAlert } from 'react-alert'
import { privateSitePrefix, apiUrl } from '../Util/Config'
import { KeywordsNotFoundMessage } from '../Util/Message'
import { fr } from '../Util/i18n';
import * as Menu from '../State/Menu'
import * as Login from '../State/Login'
import * as PatientList from '../State/PatientList'
import * as Ui from './Ui'
import * as Styled from './PatientList.styled'
import pluralize from '../Util/Pluralize'
import LoadingBox from './Ui/LoadingBox'

const PatientListComponent = (props) => {
  const dispatch = useDispatch()
  const alert = useAlert()
  const currentUser = useSelector(state => state.login.currentUser)
  const patients = useSelector(PatientList.selectPatients)
  const patientCountAll = useSelector(PatientList.selectCountAll)
  const filters = useSelector(PatientList.selectFilters)
  const patientCount = useSelector(PatientList.selectCount)
  const hasMore = useSelector(PatientList.selectHasMore)
  const loading = useSelector(PatientList.selectLoading)
  const errorMessage = useSelector(state => state.patientList.errorMessage)
  const checkedPatientIds = useSelector(PatientList.selectChecked)
  const bottomPanelOpen = useSelector(PatientList.selectHasCheckedItems)
  const allChecked = useSelector(PatientList.selectIsAllChecked)
  const keywordsNotFound = useSelector(PatientList.selectKeywordsNotFound)
  const recentTeleExpertises = useSelector(PatientList.selectRecentTeleExpertises)
  const user = useSelector(Login.selectUser)
  const [filterOpen, setFilterOpen] = useState(false)
  const nextPage = Math.floor(patients.length / Number(process.env.REACT_APP_DEFAULT_RESULT_LIMIT)) + 1
  const currentUserStatus = currentUser && currentUser.status

  const [currentUserLocal] = React.useState(
    localStorage.getItem('currentUserLocal') || ''
  );

  useEffect(() => {
    localStorage.setItem('currentUserLocal', currentUser.id);
  }, [currentUser.id]);

  useEffect(() => {
    if (
      !currentUserLocal &&
      currentUser &&
      'NON_VERIFIED' === currentUserStatus &&
      !currentUser.uploadPractitionerProof
    ) {
      alert.show(<div><Styled.UploadPractitionerProofLink to={`${privateSitePrefix}/profil?tab=1`}>Téléchargez</Styled.UploadPractitionerProofLink> votre justificatif professionnel pour valider votre compte et ajouter de nouveaux patients.</div>, { timeout: 10000 })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alert, currentUserLocal])

  useEffect(() => {
    dispatch(Menu.changeActive(Menu.PATIENTS))
    dispatch(PatientList.fetch())
    dispatch(PatientList.count())
    dispatch(PatientList.getRecentTeleExpertises())

    return () => dispatch(PatientList.clean())
  }, [dispatch])

  useEffect(() => {
    if (errorMessage)
      alert.error(errorMessage, { timeout: 5000 })
  }, [alert, errorMessage])

  useEffect(() => {
    const selectPendingUserRequest = props.location.search
    const pendingUserRequest = qs.parse(selectPendingUserRequest, { ignoreQueryPrefix: true }).ref

    if (pendingUserRequest !== undefined) {
      alert.error("Votre patient a bien été ajouté.", { timeout: 5000 })
    }
  }, [alert, props.location.search])

  const onToggleWaitingFilter = e => e.target.checked
    ? dispatch(PatientList.addStatus(PatientList.STATUS_WAITING))
    : dispatch(PatientList.removeStatus(PatientList.STATUS_WAITING))

  const onToggleDoneFilter = e => e.target.checked
    ? dispatch(PatientList.addStatus(PatientList.STATUS_DONE))
    : dispatch(PatientList.removeStatus(PatientList.STATUS_DONE))

  const onToggleSelectAllPatients = e => e.target.checked
    ? dispatch(PatientList.checkAll())
    : dispatch(PatientList.uncheckAll())

  const onClickRecentTeleExpertise = expertise =>
    expertise._canBeViewed
      ? () => history.push(`${fr[expertise.type].expertises}/${expertise.reference}`)
      : null

  const recentTeleExpertiseSublabel = expertise => expertise.thePersonal
    ? [expertise.ownerSpeciality, expertise.ownerCity].filter(e => e).join(', ')
    : `${expertise.ownerTitle} ${expertise.ownerFullname?.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase())}`

  const history = useHistory()

  return (
    <>
      <LoadingBox />
      <Ui.Layout.PageLayout currentUser={currentUser}>
        <Styled.PatientListContainer>
          <Ui.Layout.PageContentWrapper>
            <Ui.Layout.PanelContainer>
              <Ui.Layout.Panel noPadding>
                <Ui.Layout.DoctorThumbnailContainer>
                  <Ui.Layout.DoctorThumbnail src={user.profilePicture ? `${apiUrl}/users/profile-picture/${user.profilePicture}` : '/Avatar_default.png'} />
                  <Ui.Layout.DoctorIdentity>
                    <Ui.BasicElement.P>{user.username}</Ui.BasicElement.P>
                    <Ui.BasicElement.P bold dark>Discipline : {user.speciality}</Ui.BasicElement.P>
                  </Ui.Layout.DoctorIdentity>
                </Ui.Layout.DoctorThumbnailContainer>
                <Ui.Layout.PanelFilterContainer>
                  <Ui.Layout.PanelFilterMobileLiner>
                    <Ui.Layout.PanelFilterTitle>
                      {patientCountAll ? `${patientCountAll} ${pluralize('patient', patientCountAll)}` : `patients`}
                    </Ui.Layout.PanelFilterTitle>
                    {!filterOpen
                      ? (
                        <Ui.Button.SearchButton
                          onClick={e => setFilterOpen(!filterOpen)}
                        >
                          <span>Rechercher</span>
                          <Ui.Icon.IconSearch />
                        </Ui.Button.SearchButton>
                      )
                      : (
                        <Ui.Button.CloseButton
                          onClick={e => setFilterOpen(!filterOpen)}
                        >
                          <span>Fermer</span>
                          <Ui.Icon.IconClose />
                        </Ui.Button.CloseButton>
                      )
                    }
                  </Ui.Layout.PanelFilterMobileLiner>
                  <Ui.Layout.PanelFilterBox open={filterOpen}>
                    <Ui.Layout.PanelFilterRow>
                      <Ui.Icon.IconWait />
                      <Ui.Layout.PanelFilterCounter>
                        {patientCount.waiting}
                      </Ui.Layout.PanelFilterCounter>
                      <Ui.Layout.PanelFilterLabel>
                        {pluralize('Demande', patientCount.waiting)} <br />
                        <strong>En attente</strong>
                      </Ui.Layout.PanelFilterLabel>
                      <Ui.Form.Checkbox
                        checked={filters.status[0].checked}
                        onChange={onToggleWaitingFilter}
                      ></Ui.Form.Checkbox>
                    </Ui.Layout.PanelFilterRow>
                    <Ui.Layout.PanelFilterRow>
                      <Ui.Icon.IconDemandeTraitee />
                      <Ui.Layout.PanelFilterCounter>
                        {patientCount.done}
                      </Ui.Layout.PanelFilterCounter>
                      <Ui.Layout.PanelFilterLabel>
                        {pluralize('Demande', patientCount.done)} <br />
                        <strong>{pluralize('Répondue', patientCount.done)}</strong>
                      </Ui.Layout.PanelFilterLabel>
                      <Ui.Form.Checkbox
                        checked={filters.status[1].checked}
                        onChange={onToggleDoneFilter}
                      ></Ui.Form.Checkbox>
                    </Ui.Layout.PanelFilterRow>
                    {recentTeleExpertises.length === 0
                      ? null
                      : <Styled.RecentTeleExpertises>
                        <Ui.BasicElement.H2>Téléexpertises récentes</Ui.BasicElement.H2>
                        <Ui.Layout.FlexBox flow="column wrap" alignContent="inherit">
                          {recentTeleExpertises
                            .map((expertise, i) =>
                              <Ui.Form.ButtonLarge
                                key={i}
                                teleExpertiseType={expertise.type}
                                value={expertise.name}
                                canBeViewed={expertise._canBeViewed}
                                onClick={onClickRecentTeleExpertise(expertise)}
                                label={`${expertise.name} ${expertise.pendingRequestsNumber > 0 ? `(${expertise.pendingRequestsNumber})` : ''}`}
                                subLabel={recentTeleExpertiseSublabel(expertise)}
                                canAddPatient
                                isVisioconferencing={expertise.isVisioconferencing}
                                teleExpertiseId={expertise.reference}
                              >
                                {expertise.pictureFileName
                                  ? <Ui.BasicElement.TeleExpertisePicture
                                    value={{
                                      teleExpertiseId: expertise.id,
                                      type: expertise.type.replace(/s$/, 's'),
                                      pictureFileName: expertise.pictureFileName,
                                    }}
                                    isBig={false}
                                  />
                                  : < Ui.SearchBar.ExpertiseCheckboxIcon
                                    value={expertise}
                                  />
                                }
                              </Ui.Form.ButtonLarge>
                            )}
                        </Ui.Layout.FlexBox>
                      </Styled.RecentTeleExpertises>
                    }
                    <Ui.Layout.PanelFilterRowMobile>
                      <Ui.Select.Select
                        label="Afficher"
                        items={[
                          {
                            label: 'Mes patients',
                            value: PatientList.DISPLAY_MINE,
                            selected: PatientList.DISPLAY_MINE === filters.displayBy,
                          },
                          {
                            label: 'Tous les patients',
                            value: PatientList.DISPLAY_All,
                            selected: PatientList.DISPLAY_All === filters.displayBy,
                          },
                        ]}
                        value={filters.displayBy}
                        onChange={e => dispatch(PatientList.changeDisplayBy(e.target.value))}
                      />
                    </Ui.Layout.PanelFilterRowMobile>
                    <Ui.Layout.PanelFilterRowMobile>
                      <Ui.Select.Select
                        label="Trier par"
                        items={[
                          { label: 'Mise à jour', value: PatientList.ORDER_BY_LATEST, selected: false },
                          { label: 'Nom', value: PatientList.ORDER_BY_LASTNAME, selected: false },
                        ]}
                        value={filters.orderBy}
                        onChange={e => dispatch(PatientList.changeOrderBy(e.target.value))}
                      />
                    </Ui.Layout.PanelFilterRowMobile>
                    <Ui.Layout.PanelFilterRowMobile>
                      <Ui.SearchBar.SearchBar
                        placeholder="Rechercher un nom, un prénom ou un diagnostic ..."
                        value={filters.search}
                        onChange={e => dispatch(PatientList.changeSearch(e.target.value))}
                      />
                      <KeywordsNotFoundMessage
                        keywords={keywordsNotFound}
                        resourceType="patient" />
                    </Ui.Layout.PanelFilterRowMobile>
                    <Ui.Layout.PanelFilterRowMobile>
                      <Ui.Icon.IconUp onClick={e => setFilterOpen(!filterOpen)} />
                    </Ui.Layout.PanelFilterRowMobile>
                  </Ui.Layout.PanelFilterBox>
                </Ui.Layout.PanelFilterContainer>
              </Ui.Layout.Panel>
            </Ui.Layout.PanelContainer>
            <Ui.Layout.Content hasPanel>
              <Ui.Search.FilterContainer>
                <Ui.Search.FiltersAndSorting>
                  <Ui.Search.FilterSection>
                    <Ui.Select.Select
                      label="Afficher"
                      items={[
                        { label: 'Mes patients', value: PatientList.DISPLAY_MINE, selected: PatientList.DISPLAY_MINE === filters.displayBy },
                        { label: 'Tous les patients', value: PatientList.DISPLAY_All, selected: PatientList.DISPLAY_All === filters.displayBy },
                      ]}
                      onChange={e => dispatch(PatientList.changeDisplayBy(e.target.value))}
                    />
                    <Ui.Select.Select
                      label="Trier par"
                      items={[
                        { label: 'Mise à jour', value: PatientList.ORDER_BY_LATEST, selected: false },
                        { label: 'Nom', value: PatientList.ORDER_BY_LASTNAME, selected: false },
                      ]}
                      onChange={e => dispatch(PatientList.changeOrderBy(e.target.value))}
                    />
                    {/* @TODO: Implement cards / lined elements vision
                      <Ui.Icon.IconBlocs />
                      <Ui.Icon.IconLignes />
                    */}
                  </Ui.Search.FilterSection>
                  <div>
                    <Ui.Button.BasicButton
                      isFilled
                      isGreen
                      isLarge
                      as={Link}
                      to={`${privateSitePrefix}/nouveau/patient`}
                    >
                      Nouveau patient
                    </Ui.Button.BasicButton>
                  </div>
                </Ui.Search.FiltersAndSorting>
                <Ui.Search.FiltersAndSorting>
                  <Ui.SearchBar.SearchBar
                    placeholder="Rechercher par nom, diagnostic ou médecin responsable"
                    value={filters.search}
                    onChange={e => dispatch(PatientList.changeSearch(e.target.value))}
                  />
                </Ui.Search.FiltersAndSorting>
                <KeywordsNotFoundMessage
                  keywords={keywordsNotFound}
                  resourceType="patient" />
              </Ui.Search.FilterContainer>
              <Ui.Card.Container>
                <Ui.Search.MobileActionButtonGroup>
                  <Ui.Button.BasicButton
                    isFilled
                    isGreen
                    isLarge
                    as={Link}
                    to={`${privateSitePrefix}/nouveau/patient`}
                  >
                    Nouveau Patient
                  </Ui.Button.BasicButton>
                </Ui.Search.MobileActionButtonGroup>
                {(patients.length > 0
                  ? <Ui.Search.CardContainer>
                    {patients.map((patient, i) =>
                      <Ui.Card.PatientCard
                        key={`patient_${patient.id}`}
                        onCheck={e => e.target.checked
                          ? dispatch(PatientList.check(patient.id))
                          : dispatch(PatientList.uncheck(patient.id))
                        }
                        checked={-1 !== checkedPatientIds.indexOf(patient.id)}
                        to={`${privateSitePrefix}/patients/${patient.id}`}
                        currentUserId={currentUser.id}
                        {...patient}
                      />
                    )}
                  </Ui.Search.CardContainer>
                  : !loading &&
                  <Ui.Search.NoResult>
                    Vous n'avez pas encore de patient, <Ui.BasicElement.Link $isPrimary to={`${privateSitePrefix}/nouveau/patient`}>cliquez ici pour ajouter un patient</Ui.BasicElement.Link>
                  </Ui.Search.NoResult>
                )
                }
              </Ui.Card.Container>
              {hasMore && patients.length > 0 && (
                <Ui.Search.LoadMoreButtonContainer>
                  <Ui.Button.BasicButton
                    isFilled
                    isPrimary
                    isLarge
                    onClick={() => dispatch(PatientList.fetch({ page: nextPage }))}
                    disabled={loading}
                  >
                    {loading &&
                      <Ui.Global.Loader />
                    }
                    Voir plus de résultats
                  </Ui.Button.BasicButton>
                </Ui.Search.LoadMoreButtonContainer>
              )}
            </Ui.Layout.Content>
          </Ui.Layout.PageContentWrapper>
        </Styled.PatientListContainer>
      </Ui.Layout.PageLayout>
      <Ui.Search.BottomBarContainer isOpen={bottomPanelOpen}>
        <p><strong>{checkedPatientIds.length}</strong> patients sélectionnés</p>
        <div>
          {/* <Ui.Button.BasicButton isMedium isGreen isFilled>Exporter</Ui.Button.BasicButton> */}
        </div>
        <div>
          <Ui.Form.Checkbox
            checked={allChecked}
            onChange={onToggleSelectAllPatients}
          /> tout séléctionner
        </div>
      </Ui.Search.BottomBarContainer>
    </>
  )
}

export default PatientListComponent;
