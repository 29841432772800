import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import * as Login from '../State/Login'
import * as PortalPro from '../State/PortalPro';
import * as Ui from './Ui'
import { useAlert } from 'react-alert'
import { apiUrl, authUrl } from '../Util/Config'
import SEOTags from './SEOTags';

const ResetPasswordComponent = () => {
  const alert = useAlert()
  const dispatch = useDispatch()

  const email = useSelector(Login.selectEmail)
  const reset = useSelector(Login.selectReset)
  const resetting = useSelector(Login.selectResetting)
  const portalProPicture = useSelector(PortalPro.selectPicture)

  const { errorMessage } = useSelector(state => ({
    errored: state.login.errored,
    errorMessage: state.login.errorMessage,
  }))

  const handleCredentialChange = name => e =>
    dispatch(Login.updateCredential({
      name: 'email',
      value: e.target.value,
    }))

  const send = e => {
    e.preventDefault()

    dispatch(Login.resetPassword())
  }

  useEffect(() => {
    if (errorMessage)
      alert.show(errorMessage, { timeout: 5000 })
    return () => dispatch(Login.clean())
  }, [alert, dispatch, errorMessage])


  return (
    <>
      <SEOTags
        title="Rappel de mot de passe - Skemeet"
      />
      <ResetPasswordContainer>
        <Ui.PortailLayout.PageLayout
          content={
            <Ui.Form.FormContainer>
              {
                !reset
                  ? <form onSubmit={send} noValidate>
                    <Ui.BasicElement.H1>
                      Mot de passe oublié ?
                    </Ui.BasicElement.H1>
                    <Ui.Form.FormGroup>
                      <Ui.Form.TextInput
                        htmlFor="email"
                        name="email"
                        label="Email"
                        value={email.value}
                        onChange={handleCredentialChange('email')}
                        error={email.errors[0]}
                        autoFocus
                        noSubmit
                      />
                    </Ui.Form.FormGroup>
                    <Ui.Button.BasicButton
                      type="submit"
                      isGreen
                      isFilled
                      disabled={resetting || email.value === ''}
                    >
                      {resetting && <Ui.Global.Loader />}
                      Réinitialiser le mot de passe
                    </Ui.Button.BasicButton>
                  </form>
                  : <>
                    <Ui.BasicElement.P>Votre nouveau mot de passe vous a été envoyé par mail à l'adresse {email.value}</Ui.BasicElement.P>
                    <Ui.BasicElement.P>
                      <a href={`${authUrl}/connexion?email=${email.value}`}>Cliquez ici pour retourner vers la page de connexion</a>
                    </Ui.BasicElement.P>
                  </>
              }
            </Ui.Form.FormContainer>
          }
          portalProPicture={portalProPicture
            ? `${apiUrl}${portalProPicture}`
            : null
          }
        />
      </ResetPasswordContainer>
    </>
  )
}

const ResetPasswordContainer = styled.div`
  ${Ui.Form.FormGroup} {
    margin: 1.8rem 0;
  }
  button > div {
    margin-right: 1rem;
  }
  ${Ui.BasicElement.P} {
    font-size: 1.2rem;
    a {
      color: ${props => props.theme.patientGreen};
      text-decoration: none;
      font-weight: 600;
    }
  }
`

export default ResetPasswordComponent;
